import { Text, colors } from '@hm/ukie';
import { Box } from 'grid-styled';
import { prop } from 'ramda';
import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Control } from 'react-redux-form';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
} from 'react-stripe-elements';
import styled, { css } from 'styled-components';
import { Field, FieldInfo, Fieldset } from './Form';

const stripeElement = css`
  box-sizing: border-box;
  height: 23px;
  padding: 5px 8px;
  outline: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
  margin-bottom: 2px;

  &.StripeElement--focus,
  &.StripeElement:hover {
    border-width: 2px;
    margin-bottom: 1px;
  }

  &.StripeElement:not(.StripeElement--focus):not(.StripeElement--invalid) {
    border-color: ${({ theme }) => theme.colors.grey6};
  }

  &.StripeElement--focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &.StripeElement--invalid {
    border-color: ${({ theme }) => theme.colors.error};
    background: ${({ theme }) => theme.colors.red2};
  }
`;

const style: Dict<any> = {
  base: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    color: colors.defaultColor,
    '::placeholder': {
      color: colors.grey7,
      fontWeight: 400,
    },
  },
};

const CardNumber = styled(CardNumberElement).attrs({ style })`
  ${stripeElement};
`;

const CardExpiry = styled(CardExpiryElement).attrs({ style })`
  ${stripeElement};
`;

const CardCVC = styled(CardCVCElement).attrs({ style })`
  ${stripeElement};
  width: 44px;
`;

const PaymentFieldset: React.SFC<{ disabled?: boolean }> = ({ disabled }) => (
  <Fieldset disabled={disabled}>
    <Box mb={2}>
      <Field
        label={
          <F
            id="Payment.cardNumber"
            defaultMessage="Card number"
            tagName="div"
          />
        }
      >
        <Control.custom
          model=".cardNumber"
          component={CardNumber}
          mapProps={{ onChange: prop('onChange') }}
        />
      </Field>
    </Box>

    <Box my={2}>
      <Field
        label={
          <F id="Payment.cardExpiry" defaultMessage="Expires" tagName="div" />
        }
      >
        <Control.custom
          model=".cardExpiry"
          component={CardExpiry}
          mapProps={{ onChange: prop('onChange') }}
        />
      </Field>
    </Box>

    <Box my={2}>
      <Field
        label={
          <F
            id="Payment.cardCVC"
            defaultMessage="Security code"
            tagName="div"
          />
        }
      >
        <FieldInfo>
          <F
            id="Payment.cvcNote"
            defaultMessage="Group of 3 digits printed on the signature strip on the back of the card."
          >
            {text => <Text color="grey7">{text}</Text>}
          </F>
        </FieldInfo>
        <Box mt={2}>
          <Control.custom
            model=".cardCvc"
            component={CardCVC}
            mapProps={{ onChange: prop('onChange') }}
          />
        </Box>
      </Field>
    </Box>
  </Fieldset>
);

export default PaymentFieldset;
