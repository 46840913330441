import { Card } from '@hm/ukie';
import cx from 'classnames';
import { compose, isEmpty } from 'ramda';
import React from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceMonitor,
  DropTarget,
  DropTargetMonitor,
} from 'react-dnd';
import Histogram from '../../containers/Histogram';
import { Effect } from '../../models';
import { Category } from '../../reducers/categories';
import { Gradient } from '../../reducers/gradients';
import { Layer as ILayer } from '../../reducers/layers';
import { isComposite, isSingleOption } from '../../utils/layers';
import Categories from './Categories';
import Effects from './Effects';
import FoldedOptions from './FoldedOptions';
import Header from './Header';
import HistogramLoader from './HistogramLoader';
import ConnectedMetric from './MetricContainer';
import Options from './Options';

const METRIC = 'metric';

interface MetricSource {
  id: string;
  type: string;
  grouped: boolean;
}

const metricSource = {
  beginDrag: (props: Props) => ({
    id: props.metric.id,
    type: props.metric.type,
    grouped: props.grouped,
  }),
  endDrag: (props: Props, monitor: DragSourceMonitor) => {
    const dropResult = monitor.getDropResult() as MetricTarget;
    if (!dropResult) return;
    const sourceId = props.metric.id;
    const targetId = dropResult.id;

    props.grouped
      ? props.move(sourceId, targetId)
      : props.compose(sourceId, targetId);
  },
  isDragging: (props, monitor: DragSourceMonitor) => {
    return props.id === (monitor.getItem() as MetricTarget).id;
  },
};

interface MetricTarget {
  id: string;
}

const metricTarget = {
  drop(props: Props, monitor: DropTargetMonitor, component) {
    return {
      id: props.metric.id,
    };
  },
  hover(props: Props, monitor: DropTargetMonitor, component) {
    const sourceLayer = monitor.getItem() as MetricSource;
    const sourceId = sourceLayer.id;
    const targetId = props.metric.id;

    if (sourceId === targetId) return;
    if (props.grouped || sourceLayer.grouped) return;

    const hoverRect = component.decoratedComponentInstance.target.getBoundingClientRect();

    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverRect.top;

    // reorder when layer cross first or last fourth of height
    if (hoverClientY < hoverRect.height * 0.25) props.move(sourceId, targetId);
    if (hoverClientY > hoverRect.height * 0.75) {
      sourceLayer.grouped
        ? // operand always goes as `from` argument so we don’t move composite
          // layer in place of it’s operand
          props.move(sourceId, targetId)
        : props.move(targetId, sourceId);
    }
  },
  canDrop(props: Props, monitor: DropTargetMonitor) {
    const sourceLayer = monitor.getItem() as MetricSource;

    if (isComposite(sourceLayer) || isComposite(props.metric)) return false;
    if (sourceLayer.id === props.metric.id) return false;

    return true;
  },
};

interface Props {
  metric: ILayer;
  gradient: Gradient;
  effects: Effect[];
  categories: Category[];
  last?: boolean;
  grouped?: boolean;
  updateLayer(id: string, updates);
  updateManyOptions(updates);
  move(from: string, to: string);
  compose(first: string, second: string);
}

interface State {
  showHandle: boolean;
}

interface DnDProps {
  isDragging?: boolean;
  isOver?: boolean;
  canDrop?: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  connectDragPreview: ConnectDragPreview;
}

/**
 * Draws header, histogram and effects for given layer.
 */
export class Metric extends React.Component<Props & DnDProps, State> {
  state = { showHandle: false };
  target: HTMLDivElement;

  foldOptions = (fold: boolean) => {
    this.props.updateLayer(this.props.metric.id, { subsetsMinified: fold });
  };

  renderOptions() {
    const { metric, categories, effects, updateManyOptions } = this.props;

    const withoutSubsets = isSingleOption(categories) || isComposite(metric);
    if (withoutSubsets && isEmpty(effects)) return null;

    return (
      <Categories compact={metric.subsetsMinified} onChange={this.foldOptions}>
        {withoutSubsets ? null : (
          <Options
            categories={categories}
            updateManyOptions={updateManyOptions}
          />
        )}
        <Effects effects={effects} layerId={metric.id} />
      </Categories>
    );
  }

  renderFoldedOptions() {
    const { metric, categories, effects } = this.props;

    return (
      <Categories compact={metric.subsetsMinified} onChange={this.foldOptions}>
        {isSingleOption(categories) || isComposite(metric) ? null : (
          <FoldedOptions categories={categories} effects={effects} />
        )}
      </Categories>
    );
  }

  renderOperand = (id, i) => {
    const last =
      this.props.metric.operands && i === this.props.metric.operands.length - 1;

    return <ConnectedMetric id={id} key={id} last={last} grouped />;
  };

  onMouseEnter: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    this.setState({ showHandle: true });
  };

  onMouseLeave: React.MouseEventHandler<HTMLDivElement> = e =>
    this.setState({ showHandle: false });

  render() {
    const { metric, grouped } = this.props;

    return this.props.connectDragPreview(
      this.props.connectDropTarget(
        <div
          style={{ opacity: this.props.isDragging ? 0 : 1 }}
          ref={el => (this.target = el)}
        >
          {/* TODO: rewrite with Widget */}
          <Card
            hover={{ boxShadow: grouped ? 'none' : 2 }}
            boxShadow={grouped ? 'none' : 1}
            width={530}
            p={0}
            mx={0}
            mt={0}
            mb={grouped ? 0 : 2}
            className={cx('metric', {
              'is-selected':
                this.props.isOver && this.props.canDrop && !grouped,
              _group: grouped || isComposite(metric),
            })}
            borderRadius={grouped ? 0 : 'big'}
            borderColor="grey5"
            borderTop={grouped ? '2px solid' : null}
            onMouseOver={this.onMouseEnter}
            onMouseOut={this.onMouseLeave}
          >
            <Header
              metric={metric}
              gradient={this.props.gradient}
              updateLayer={this.props.updateLayer}
              connectDragSource={this.props.connectDragSource}
              showHandle={this.state.showHandle}
            />
            {metric.currentHistogram && metric.initialHistogram ? (
              <Histogram layerId={metric.id} />
            ) : (
              <HistogramLoader
                isCompact={metric.histogramMinified}
                progress={metric.loadingProgress}
                status={metric.loadingStatus}
              />
            )}
            {metric.subsetsMinified
              ? this.renderFoldedOptions()
              : this.renderOptions()}
            {metric.operands ? metric.operands.map(this.renderOperand) : null}
          </Card>
        </div>,
      ),
    );
  }
}

export default compose(
  DropTarget<Props>(METRIC, metricTarget, (connect, monitor) => ({
    isOver: monitor.isOver(),
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
  })),
  DragSource<Props>(METRIC, metricSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  })),
)(Metric);
