import React from 'react';
import { connect } from 'react-redux';
import { isValid } from 'react-redux-form';
import { isEmpty, path, isNil } from 'ramda';
import { RouteComponentProps } from 'react-router';
import { Spinner, FillFlex, Text } from '@hm/ukie';

import {
  publishLayer,
  getLayerUpload,
  updateLayer,
  resetLayer,
  initLayerImportForm,
} from '../../reducers/layerUploads';
import { getEnvironments as selectEnvironments } from '../../selectors/environments';
import { Environment } from '../../reducers/environments';
import { AppState } from '../../models';

import MetricConfig from './MetricConfig';
import IntlDocumentTitle from '../IntlDocumentTitle';

interface Params {
  uploadId: string;
}

type OwnProps = RouteComponentProps<Params>;

interface ProvidedProps {
  layer: any;
  environments: Dict<Environment>;
  model: any;
  isValid: boolean;
  onUpdate(id: string, updates: any): Promise<any>;
  onSubmit(id: string): Promise<any>;
  onReset(id: string): Promise<any>;
  onMount(id: string): void;
}

interface State {
  didError: boolean;
  isSubmitting: boolean;
}

type Props = ProvidedProps & OwnProps;

class MetricConfigContainer extends React.Component<Props, State> {
  state = {
    didError: false,
    isSubmitting: false,
  };

  componentWillMount() {
    this.props.onMount(this.props.match.params.uploadId);
  }

  componentDidCatch() {
    this.setState({ didError: true });
  }

  onSubmit: React.FormEventHandler<HTMLFontElement> = (layer: any) => {
    this.setState({ isSubmitting: true }, () =>
      this.props
        .onSubmit(this.props.layer.id)
        .then(() => this.setState({ isSubmitting: false }))
        .then(() => this.props.history.push('/'))
        .catch(() => this.setState({ didError: true })),
    );
  };

  render() {
    const { didError } = this.state;
    const { onSubmit, onMount, ...props } = this.props;

    const notReady =
      isNil(props.layer) ||
      isEmpty(props.environments) ||
      path(['layer', 'state'], props) === 'processing';

    return (
      <React.Fragment>
        {didError || notReady ? (
          <FillFlex justify="center" align="center">
            {didError ? (
              <Text.Header>Sorry, something went wrong.</Text.Header>
            ) : notReady ? (
              <Spinner size="big" />
            ) : null}
          </FillFlex>
        ) : (
          <MetricConfig
            onSubmit={this.onSubmit}
            isSubmitting={this.state.isSubmitting}
            {...props}
          />
        )}
        <IntlDocumentTitle titleId="createMetric.config" />{' '}
        {/* replacing this one throws an error*/}
      </React.Fragment>
    );
  }
}

export default connect(
  (state: AppState, props: OwnProps) => ({
    layer: getLayerUpload(state, props.match.params.uploadId),
    environments: selectEnvironments(state),
    isValid: isValid(state.forms.layerImport),
    model: state.layerImport,
  }),
  {
    onUpdate: updateLayer,
    onSubmit: publishLayer,
    onReset: resetLayer,
    onMount: initLayerImportForm,
  },
)(MetricConfigContainer);
