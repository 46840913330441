import React from 'react';
import DocumentTitle from 'react-document-title';
import { injectIntl, InjectedIntlProps, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { setTitle } from '../reducers/title';

const messages = defineMessages({
  default: {
    id: 'IntlDocumentTitle.default',
    defaultMessage: 'Chronotope',
  },
  explorer: {
    id: 'IntlDocumentTitle.explorer',
    defaultMessage: 'Choose or Create Project',
  },
  signIn: {
    id: 'IntlDocumentTitle.signIn',
    defaultMessage: 'Log in',
  },
  profile: {
    id: 'IntlDocumentTitle.profile',
    defaultMessage: 'Profile',
  },
  unsubscribe: {
    id: 'IntlDocumentTitle.unsubscribe',
    defaultMessage: 'Unsubscribe',
  },
  'createMetric.upload': {
    id: 'IntlDocumentTitle.createMetric.upload',
    defaultMessage: 'Create new Metric. Step 1: Upload',
  },
  'createMetric.config': {
    id: 'IntlDocumentTitle.createMetric.config',
    defaultMessage: 'Create new Metric. Step 2: Configure',
  },
  notFound: {
    id: 'IntlDocumentTitle.notFound',
    defaultMessage: 'Not found',
  },
  welcome: {
    id: 'IntlDocumentTitle.welcome',
    defaultMessage: 'Welcome',
  },
  signUp: {
    id: 'IntlDocumentTitle.signUp',
    defaultMessage: 'Sign up',
  },
});

export type Titles = keyof typeof messages;

interface OwnProps {
  titleId: Titles;
}

interface ProvidedProps {
  setTitle(titleId: string): void;
}

type Props = OwnProps & ProvidedProps & InjectedIntlProps;

class IntlDocumentTitle extends React.Component<Props> {
  getTitle() {
    return this.props.intl.formatMessage(messages[this.props.titleId]);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.titleId !== nextProps.titleId)
      this.props.setTitle(this.getTitle());
  }

  componentDidMount() {
    this.props.setTitle(this.getTitle());
  }

  render() {
    return <DocumentTitle title={this.getTitle()} />;
  }
}

export default connect<null, ProvidedProps, OwnProps>(null, { setTitle })(
  injectIntl(IntlDocumentTitle),
);
