import React from 'react';
import { Duration } from 'moment';
import 'moment-timezone';
import { last, values } from 'ramda';
import { ScaleLinear } from 'd3-scale';

import Notch from './TickNotch';
import Label from './TickLabels';
import { overlaps } from '../../utils/dimensions';
import { ApproximationType } from '../../reducers/currentProject';
import { MomentScale, TimelineTick, TickCollisions } from '../../models';

interface TickNode {
  tick: TimelineTick;
  rect: ClientRect;
}

interface Props {
  ticks: TimelineTick[];
  timezone: string;
  scale: MomentScale;
  y: number;
  sliceDuration: Duration;
  displayProgress: boolean;
  brushScale: ScaleLinear<number, number>;
  selected: number[];
  previousRange: number[];
  approximationType: ApproximationType;
  getCollisions?(collisions: TickCollisions[]);
}

/**
 * Renders timeline ticks.
 * Provides to the top component a list of label collisions (if such are present)
 * via an optional callback.
 *
 * NOTE: Decision to render spaces instead of actual Ticks comes from the fact that
 * later we need to show selected ranges and it’s easier to draw single
 * continuous line and then mark it with spaces rather than redraw exact svg
 * elements with selected style on top of existing non-active elements.
 */
class Ticks extends React.Component<Props, never> {
  tickNodes: { [key: string]: TickNode } = {};

  componentDidMount() {
    this.props.getCollisions(this.computeCollisions());
  }

  componentDidUpdate() {
    this.props.getCollisions(this.computeCollisions());
  }

  renderTick = (tick: TimelineTick) => {
    const { value, type } = tick;
    const { displayProgress, selected, previousRange, brushScale } = this.props;

    const brushStart = brushScale(selected[0]);
    const brushEnd = brushScale(selected[1]);
    const ghostEnd = previousRange && brushScale(last(previousRange));

    const x = this.props.scale(value);
    const key = `${tick.value.format()}-${tick.type}`;

    // XXX: Math.floor ensures pixel perfect rendering
    return (
      <g transform={`translate(${Math.floor(x)},0)`} key={key}>
        <Label
          tickValue={value.clone().tz(this.props.timezone)}
          sliceDuration={this.props.sliceDuration}
          tickType={type}
          approximationType={this.props.approximationType}
          getRef={node => this.labelRef(node, key, tick)}
        />
        <Notch
          tickType={type}
          isActive={displayProgress && x >= brushStart && x <= brushEnd}
          isGhost={ghostEnd && x > brushScale(brushEnd) && x < ghostEnd}
        />
      </g>
    );
  };

  labelRef = (node: SVGElement, key: string, tick: TimelineTick) => {
    // Ref is also invoked when the component gets unmounted
    if (node === null) {
      delete this.tickNodes[key];
      return;
    }

    this.tickNodes[key] = {
      tick,
      rect: node.getBoundingClientRect(),
    };
  };

  computeCollisions = () => {
    return values(this.tickNodes)
      .map((node, _, ary) => ({
        self: node.tick,
        collidingTicks: this.getNodeCollisions(node, ary),
      }))
      .filter(collision => collision.collidingTicks.length > 0);
  };

  getNodeCollisions = (current: TickNode, allNodes: TickNode[]) => {
    return allNodes
      .filter(node => overlaps(current.rect, node.rect, 8))
      .filter(node => current.tick !== node.tick)
      .map(node => node.tick);
  };

  render() {
    const { y, ticks } = this.props;

    return (
      <g transform={`translate (0, ${y})`}>{ticks.map(this.renderTick)}</g>
    );
  }
}

export default Ticks;
