import React from 'react';
import styled from 'styled-components';
import measureText from 'measure-text';
import { colors } from '@hm/ukie';

const FONT_SIZE = 10;
const BORDER_RADIUS = 2;
const ANGLE_W = 5;
const ANGLE_H = 3;
const PADDING = { top: 2, right: 8, bottom: 2, left: 8 };

interface Props extends React.SVGAttributes<SVGGElement> {
  text: string | number;
  color?: string;
  angle?: 'top' | 'right' | 'bottom' | 'left';
  getWidth?(width: number);
}

const Label = styled.text`
  font-size: ${FONT_SIZE}px;
  fill: ${({ theme }) => theme.colors.white};
`;

const ANGLE_PATH = `M0 0 L${ANGLE_W / 2} ${ANGLE_H} L-${ANGLE_W /
  2} ${ANGLE_H} 0 0Z`;

const getOrientationTransforms = (labelWidth: number, labelHeight: number) => ({
  top: {
    label: `translate(${-labelWidth / 2}, ${ANGLE_H})`,
    angle: '',
  },
  left: {
    label: `translate(${ANGLE_H}, ${-labelHeight / 2})`,
    angle: 'rotate(-90)',
  },
  right: {
    label: `translate(${-(labelWidth + ANGLE_H)}, ${-labelHeight / 2})`,
    angle: 'rotate(90)',
  },
  bottom: {
    label: `translate(${-labelWidth / 2}, -${ANGLE_H + labelHeight})`,
    angle: 'rotate(180)',
  },
});

const Tooltip: React.StatelessComponent<Props> = ({
  text,
  color,
  angle,
  getWidth,
  children,
  ...props
}) => {
  const textSize = measureText({
    text,
    fontFamily: 'Open Sans',
    lineHeight: 1,
    fontSize: `${FONT_SIZE}px`,
  });

  const labelWidth = textSize.width.value + PADDING.left + PADDING.right;
  const labelHeight = textSize.height.value + PADDING.top + PADDING.bottom;

  if (getWidth) getWidth(labelWidth);

  const orientationTransforms = angle
    ? getOrientationTransforms(labelWidth, labelHeight)[angle]
    : { label: '', angle: '' };

  return (
    <g {...props}>
      {angle ? (
        <path
          d={ANGLE_PATH}
          fill={color}
          transform={orientationTransforms.angle}
        />
      ) : null}
      <g transform={orientationTransforms.label}>
        <rect
          width={labelWidth}
          height={labelHeight}
          rx={BORDER_RADIUS}
          ry={BORDER_RADIUS}
          fill={color}
        />
        <Label
          transform={`translate(${PADDING.left}, ${textSize.height.value})`}
        >
          {text}
        </Label>
      </g>
    </g>
  );
};

// TODO: withTheme, remove color import
Tooltip.defaultProps = {
  text: '',
  color: colors.grey6,
};

export default styled(Tooltip)`
  pointer-events: none;
`;
