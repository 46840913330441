import React from 'react';
import { Scrollable } from '@hm/ukie';
import { List, ListProps } from 'react-virtualized';

interface Props extends ListProps {
  listRef?(el: List): void;
  scrollableRef?(el: any): void; // TODO: Scrollable interface
}

// Has non-standard 2px right padding
const TrackVertical = ({ style, ...rest }) => (
  <div {...rest} style={{ ...style, right: 2, top: 1, bottom: 1 }} />
);

export default class ScrollableList extends React.Component<Props> {
  List: List;

  componentDidMount() {
    const { rowCount } = this.props;
    if (rowCount > 0) this.resizeToActualHeight();
  }

  componentDidUpdate(prevProps: Props) {
    const { rowCount } = this.props;
    if (rowCount !== prevProps.rowCount && rowCount > 0) {
      this.resizeToActualHeight();
    }
  }

  /**
   * Required for scroll to click to work correctly
   */
  resizeToActualHeight = () => {
    this.List.measureAllRows();
    this.forceUpdate();
  };

  onScroll = ({ target }) => {
    const { scrollTop, scrollLeft } = target;
    this.List['Grid'].handleScrollEvent({ scrollTop, scrollLeft });
  };

  render() {
    const {
      width,
      height,
      style,
      listRef,
      scrollableRef,
      ...rest
    } = this.props;

    return (
      <Scrollable
        style={{ width, height }}
        onScroll={this.onScroll}
        getRef={scrollableRef}
        renderTrackVertical={TrackVertical}
      >
        <List
          width={width}
          height={height}
          style={{
            ...style,
            overflowX: 'unset',
            overflowY: 'unset',
          }}
          ref={instance => {
            if (listRef) listRef(instance);
            this.List = instance;
          }}
          {...rest}
        />
      </Scrollable>
    );
  }
}
