import React from 'react';
import { Motion, spring } from 'react-motion';
import { scaleLinear } from 'd3-scale';
import { clamp, apply, memoize } from 'ramda';
import { DraggableEventHandler } from 'react-draggable';

import VoidHistogram from './VoidHistogram';
import FilledHistogram from './FilledHistogram';
import ResizeHandle from './ResizeHandle';

import { Point as GradientPoint } from '../../reducers/gradients';

import { getVoidColor, getVoidRatio, getBinScale } from '../../utils/histogram';
import { percentFloat } from '../../utils/numberFormats';
import { fastSpring } from '../../utils/helpers';
import { MinMax, HistogramData } from '../../models';

export const SVG_WIDTH = 530;
export const PADDING = { bottom: 20, top: 16, left: 10, right: 10 };
export const RESIZE_HANDLE = 14;

export const INNER_WIDTH = SVG_WIDTH - PADDING.left - PADDING.right;
export const SHORT_HISTOGRAM = INNER_WIDTH / 2;
export const VOID_BASE = SHORT_HISTOGRAM - 120;

export const COMPACT_HEIGHT = 5;
export const MIN_HEIGHT = COMPACT_HEIGHT + PADDING.top + PADDING.bottom;
export const MAX_HEIGHT = 99 + PADDING.top + PADDING.bottom;
export const RESIZE_THRESHOLD = MIN_HEIGHT + 4;

interface Props {
  currentBins: HistogramData[];
  initialBins: HistogramData[];
  selected: MinMax;
  gradientPoints: GradientPoint[];
  borders: number[];
  voidValue: number;
  isCompact: boolean;
  showVoid: boolean;
  foldHistogram(toggle: boolean);
  onBrush(extent: MinMax);
  onBrushStart();
  onBrushEnd();
}

interface State {
  height?: number;
  resizing?: boolean;
}

/**
 * Top level layer histogram component. Controls resizing and rendering of it's
 * multiple views.
 *
 * Consists of FilledHistogram and optional VoidHistogram. (2nd level)
 * Each of them has Full and Compact versions. (3rd level)
 *
 * NOTE:
 * Data is 256 histogram bins. We visualize 255 because the first one is the void bin.
 * But we need all the 256 borders for correct brush and axis labels.
 *
 * NOTE: getScales is memoized. TODO: check if it really affects performance
 */
export default class Histogram extends React.Component<Props, State> {
  // XXX: memoize to pass it as prop and avoid unneeded rerenders
  getYScale = memoize((maxY: number, height: number) => {
    return scaleLinear<number>()
      .domain([0, maxY])
      .range([0, height]);
  });

  state = {
    resizing: false,
    height: this.props.isCompact ? MIN_HEIGHT : MAX_HEIGHT,
  };

  onResizeStart: DraggableEventHandler = () => {
    this.setState({ resizing: true });
  };

  onResizeDrag: DraggableEventHandler = (e, data) => {
    const { isCompact, foldHistogram } = this.props;
    const { height } = this.state;

    this.setState({
      height: clamp(MIN_HEIGHT, MAX_HEIGHT, height + data.deltaY),
    });

    if (!isCompact && height <= RESIZE_THRESHOLD) foldHistogram(true);
    if (isCompact && height > RESIZE_THRESHOLD) foldHistogram(false);
  };

  onResizeStop: DraggableEventHandler = () => {
    const { isCompact } = this.props;
    const { height } = this.state;

    // Snap compact histogram after point release
    if (isCompact && height !== MIN_HEIGHT) {
      this.setState({ height: MIN_HEIGHT });
    }

    this.setState({ resizing: false });
  };

  instantFoldToggle = () => {
    const shouldFold = !this.props.isCompact;
    this.props.foldHistogram(shouldFold);
    this.setState({
      height: shouldFold ? MIN_HEIGHT : MAX_HEIGHT,
    });
  };

  // XXX: two functions below are too peculiar
  getVoid1D = (mainWidth: number) => {
    const { currentBins, voidValue } = this.props;
    const barWidth = getBinScale(currentBins.length - 1, mainWidth).bandwidth();
    return voidValue / VOID_BASE * barWidth;
  };

  getMaxY = (isVoidVisible: boolean, mainWidth: number) => {
    const { currentBins, initialBins } = this.props;
    const maxBinY = apply(
      Math.max,
      currentBins.concat(initialBins).map(d => d.y),
    );

    return isVoidVisible
      ? Math.max(maxBinY, this.getVoid1D(mainWidth))
      : maxBinY;
  };

  render() {
    const { currentBins, showVoid, voidValue, gradientPoints } = this.props;

    const { height, resizing } = this.state;

    const voidRatio = getVoidRatio(currentBins, voidValue);

    const animatedProps = {
      heightTransition: resizing ? height : spring(height, fastSpring),
      voidTransition: spring(showVoid ? 1 : 0, fastSpring),
    };

    return (
      <Motion style={animatedProps}>
        {({ heightTransition, voidTransition }) => {
          const voidWidth = voidTransition * SHORT_HISTOGRAM;
          const mainWidth = INNER_WIDTH - voidWidth;
          const mainLeft = PADDING.left + voidWidth;
          const isVoidVisible = voidWidth > 0;

          const innerHeight = heightTransition - PADDING.top - PADDING.bottom;
          const showCompact = heightTransition <= RESIZE_THRESHOLD;

          const yScale = this.getYScale(
            this.getMaxY(isVoidVisible, mainWidth),
            innerHeight,
          );

          return (
            <svg
              height={heightTransition}
              width={SVG_WIDTH}
              onDoubleClick={this.instantFoldToggle}
            >
              <ResizeHandle
                onStart={this.onResizeStart}
                onDrag={this.onResizeDrag}
                onStop={this.onResizeStop}
                width={INNER_WIDTH}
                height={RESIZE_HANDLE}
                y={heightTransition - PADDING.bottom}
                x={PADDING.left}
              />
              {isVoidVisible ? (
                <VoidHistogram
                  width={voidWidth}
                  height={innerHeight}
                  value={voidValue}
                  dataHeight={yScale(this.getVoid1D(mainWidth))}
                  dataWidth={VOID_BASE * voidTransition}
                  isCompact={showCompact}
                  color={getVoidColor(gradientPoints)}
                  label={showVoid ? `void ${percentFloat(voidRatio)}` : null}
                  transform={`translate(${PADDING.left},${PADDING.top})`}
                />
              ) : null}
              <FilledHistogram
                borders={this.props.borders}
                selected={this.props.selected}
                currentBins={this.props.currentBins}
                initialBins={this.props.initialBins}
                gradientPoints={this.props.gradientPoints}
                onBrush={showVoid ? null : this.props.onBrush}
                onBrushStart={this.props.onBrushStart}
                onBrushEnd={this.props.onBrushEnd}
                yScale={yScale}
                isCompact={showCompact}
                label={
                  showVoid ? `filled ${percentFloat(1 - voidRatio)}` : null
                }
                height={innerHeight}
                width={mainWidth}
                transform={`translate(${mainLeft},${PADDING.top})`}
              />
            </svg>
          );
        }}
      </Motion>
    );
  }
}
