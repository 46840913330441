import moment from 'moment';
import { withPropsOnChange } from 'recompose';
import { snapIntervalToTime } from '../utils/time';

/** transforms `selectedIntervals` prop to be rounded by provided duration */
// TODO: provide better types
export default (duration: string) =>
  withPropsOnChange(['selectedIntervals'], ({ selectedIntervals, date }) => ({
    selectedIntervals: selectedIntervals.map(interval =>
      snapIntervalToTime(date, moment.duration(duration), interval),
    ),
  }));
