import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Text } from '@hm/ukie';

const TooManySlicesError: React.SFC = () => (
  <F
    id="GlobalAlert.tooManySlicesSelected"
    defaultMessage="The amount of selected slices is more than 366. Please, set time interval within this limit to continue."
  >
    {t => <Text lineHeight="text">{t}</Text>}
  </F>
);

export default TooManySlicesError;
