import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import styled from 'styled-components';
import { splitEvery } from 'ramda';
import { compose } from 'recompose';

import Title from './Title';
import Month from './Month';
import shouldCalendarUpdate from '../shouldCalendarUpdate';
import withPrettySelectedIntervals from '../withPrettySelectedIntervals';
import { SelectorProps } from '../Calendar/Selector';

const MonthsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Container = styled.div`
  margin-bottom: 72px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
`;

export interface OwnProps {
  date: moment.Moment;
  style?: any;
  getRowHeight(height: number): void;
}

type Props = OwnProps & SelectorProps;

/**
 * Component to select ranges by day.
 * Displays all 12 months and it’s dates.
 * Can be found under ‘Days’ tab on setup dialog.
 */
export class MonthsSelector extends React.Component<Props, never> {
  cacheRow = (el: HTMLDivElement) => {
    if (!el) return;
    this.props.getRowHeight(el.getBoundingClientRect().height);
  };

  render() {
    const { style, children, date, ...props } = this.props;

    return (
      <Container style={style}>
        <Title date={date} />
        <MonthsContainer>
          {splitEvery(
            3,
            Array.from(
              new DateRange(
                date.clone().startOf('year'),
                date.clone().endOf('year'),
              ).by('months'),
            ),
          ).map((row, i) => (
            <Row key={i} innerRef={this.cacheRow}>
              {row.map((month, m) => (
                <Month key={+month} date={month} {...props} />
              ))}
            </Row>
          ))}
        </MonthsContainer>
      </Container>
    );
  }
}

export default compose<Props, Props>(
  withPrettySelectedIntervals('P1D'),
  shouldCalendarUpdate('year'),
)(MonthsSelector);
