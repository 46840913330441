import React from 'react';
import { isNil } from 'ramda';
import { Progress, Text, Grid } from '@hm/ukie';
import { Box } from 'grid-styled';
import ValueWithUnits from '../ValueWithUnits';

interface Props {
  value: number;
  units?: string;
  description: string | React.ReactChild;
  min: number;
  max: number;
}

const ResourceStatus: React.SFC<Props> = ({
  value,
  description,
  units,
  min,
  max,
  children,
}) => (
  <Box width={1}>
    <Box mb={1}>
      <Grid align="baseline" mr={1}>
        <Text.Header>
          <ValueWithUnits
            value={!isNil(value) ? Math.max(max - value, 0) : null}
            units={units}
          />
        </Text.Header>
      </Grid>
      <Grid align="baseline">{description}</Grid>
    </Box>
    <Progress
      minLabel={<ValueWithUnits value={min} units={units} />}
      maxLabel={<ValueWithUnits value={max} units={units} />}
      value={!isNil(value) ? Math.min(value / max, 1) : 0}
    />
    {children}
  </Box>
);

ResourceStatus.defaultProps = {
  units: '',
};

export default ResourceStatus;
