import { connect } from 'react-redux';
import EditHeader from '../../components/Calendar/EditHeader';
import { getCalendarLabel } from '../../selectors/calendar';
import { updateLabel } from '../../actions/calendar';
import { AppState } from '../../models';

const mapStateToProps = (state: AppState) => ({
  label: getCalendarLabel(state),
});

const mapDispatchToProps = {
  onLabelUpdate: updateLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditHeader);
