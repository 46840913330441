import React from 'react';
import { Moment } from 'moment';
import styled from 'styled-components';
import shouldCalendarUpdate from '../shouldCalendarUpdate';

import Wave from '../Wave';
import { SubTitle } from '../Title';
import { CalendarProps } from '../Calendar';

const Container = styled.div`
  padding-bottom: 72px;
`;

const StyledTitle = SubTitle.extend`
  margin-bottom: 30px;
  margin-left: 18px;
`;

export interface OwnProps {
  height?: number;
  width?: number;
  date: Moment;
  style?: any;
}

type Props = OwnProps & CalendarProps;

export class PreciseSelector extends React.Component<Props, never> {
  static defaultProps = {
    selectedIntervals: [],
    availableIntervals: [],
  };

  render() {
    const { style, date, ...props } = this.props;

    return (
      <Container style={style}>
        <StyledTitle>{date.format('D MMMM, YYYY')}</StyledTitle>
        <Wave date={date} {...props} />
      </Container>
    );
  }
}

export default shouldCalendarUpdate('day')(PreciseSelector);
