import { types } from '../actions/gradients';
import { fulfill } from '../utils/promise';

export interface Point {
  color: string;
  value: number;
}

export interface Gradient {
  id: string;
  label: string;
  points: Point[];
}

export interface GradientsState {
  [index: string]: Gradient;
}

export const initialState: GradientsState = {};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case fulfill(types.LOAD_GRADIENTS):
      return action.payload.entities.gradient;

    default:
      return state;
  }
};
