// Currently supported slice durations
export default [
  'PT5M',
  'PT10M',
  'PT15M',
  'PT20M',
  'PT30M',
  'PT1H',
  'PT2H',
  'PT3H',
  'PT4H',
  'PT6H',
  'PT8H',
  'PT12H',
  'P1D',
  'P1M',
  'P2M',
  'P3M',
  'P4M',
  'P6M',
  'P1Y',
];
