import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'grid-styled';
import { Pictogram, Text } from '@hm/ukie';
import { UnmountClosed } from 'react-collapse';

const Container = Flex.extend`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Trigger = Box.extend`
  cursor: pointer;
`;

const Arrow = styled(Pictogram)`
  transition: transform 250ms ease-out;
  ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? undefined : 'transform: rotate(-90deg);'};
`;

interface Props {
  title:
    | string
    | ((onClick: React.MouseEventHandler<HTMLElement>) => JSX.Element);
  isOpen?: boolean;
  showTitleWhenOpen?: boolean;
  className?: string;
}

interface State {
  isOpen: boolean;
}

class Collapsible extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    showTitleWhenOpen: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = { isOpen: props.isOpen };
  }

  onClick: React.MouseEventHandler<HTMLDivElement> = e => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { title, className, children, showTitleWhenOpen } = this.props;
    const { isOpen } = this.state;
    const isCustomHeader = typeof title !== 'string';

    const showTitle = !isOpen || (isOpen && showTitleWhenOpen);

    return (
      <Container width="100%" className={className}>
        <Box mr={1}>
          <Arrow name="fold" onClick={this.onClick} isOpen={isOpen} />
        </Box>
        <Box flex={1}>
          {showTitle ? (
            <Trigger
              flex={1}
              onClick={isCustomHeader ? undefined : this.onClick}
            >
              {typeof title === 'string' ? (
                <Text.Important>{title}</Text.Important>
              ) : (
                title(this.onClick)
              )}
            </Trigger>
          ) : null}
          <UnmountClosed isOpened={isOpen} style={{ width: '100%' }}>
            {children}
          </UnmountClosed>
        </Box>
      </Container>
    );
  }
}

export default Collapsible;
