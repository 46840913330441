import React from 'react';
import { prop } from 'ramda';
import { FormattedMessage as F } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import { Button } from '@hm/ukie';

import { Form, Control, actions } from 'react-redux-form';
import { connect } from 'react-redux';
import { getPotentialEditors } from '../../selectors/users';
import { addEditors } from '../../actions/environments';
import { AppState, User } from '../../models';

import CrossButton from './CrossButton';
import EditorInput from './EditorInput';

const ControlBox = Box.extend`
  max-width: 280px;
`;

const Wrapper = Box.extend`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

interface OwnProps {
  id: string;
}

interface ProvidedProps {
  users: User[];
  editors: User[];
  onSubmit(environmentId: string, ids: string[]): any;
  onAddEditor(): void;
  onRemoveEditor(i: number): void;
  onReset(model: string): void;
}

interface State {
  isSubmiting: boolean;
}

export class EditorsForm extends React.Component<
  OwnProps & ProvidedProps,
  State
> {
  state = {
    isSubmiting: false,
  };

  onSubmit = (editors: User[]) => {
    this.setState({ isSubmiting: true }, () =>
      this.props.onSubmit(this.props.id, editors.map(prop('id'))).then(() => {
        this.props.onReset('editors');
        this.setState({ isSubmiting: false });
      }),
    );
  };

  render() {
    const { users, editors, onAddEditor, onRemoveEditor } = this.props;

    return (
      <Wrapper my={2} width="100%" disabled={this.state.isSubmiting}>
        <Form model="editors" onSubmit={this.onSubmit}>
          {editors.map((editor, i) => (
            <ControlBox my={1} key={editor.id}>
              <Control
                model={`.[${i}]`}
                component={EditorInput}
                controlProps={{
                  onClick: () => onRemoveEditor(i),
                  disabled: editors.length <= 1,
                  selectedItem: editor,
                  items: users.filter(u => !editors.includes(u)),
                }}
              />
            </ControlBox>
          ))}
          <CrossButton icon="plus" onClick={onAddEditor} />
          <Flex mt={1} justify="flex-end">
            <Button primary type="submit">
              <F id="EnvironmentInfo.submitEditors" defaultMessage="Share" />
            </Button>
          </Flex>
        </Form>
      </Wrapper>
    );
  }
}

export default connect(
  (state: AppState, ownProps: OwnProps) => ({
    users: getPotentialEditors(state, ownProps),
    editors: state.editors,
  }),
  {
    onSubmit: addEditors,
    onAddEditor: () => actions.push('editors', ''),
    onRemoveEditor: i => actions.remove('editors', i),
    onReset: actions.reset,
  },
)(EditorsForm);
