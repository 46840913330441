import { connect } from 'react-redux';
import Settings, {
  OwnProps,
  DispatchProps,
} from '../../components/Calendar/Settings';
import { selectAll, clearSelection } from '../../actions/calendar';

export default connect<any, DispatchProps, OwnProps>(null, {
  onSelectAll: selectAll,
  onClear: clearSelection,
})(Settings);
