import { getLayerById } from './layers';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { categorySchema } from '../schemas/layer';
import { AppState } from '../models';

const getEntities = (state: AppState) => state.categories.entities;

export const makeGetCategoriesByLayerId = () =>
  createSelector(
    getEntities,
    (state: AppState) => state.options.entities,
    getLayerById,
    (categories, options, layer) => {
      if (!layer.categories) return [];

      return layer.categories.map(categoryId => {
        const category = categories[categoryId];
        // const optionsToMerge = category.options;

        return denormalize(category, categorySchema, { options });
        // return {
        //   ...category,
        //   options: optionsToMerge.map(id => options[id]),
        // };
      });
    },
  );
