import React from 'react';
import { Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import { Text } from '@hm/ukie';

import IntlDocumentTitle from '../IntlDocumentTitle';
import Elements from '../StripeElements';

import Form from './SignUpConfirmationForm';

interface Props {
  name: string;
  signupToken: string;
  completeSignUp(tokens: { signupToken: string; stripeToken?: string }): void;
}

const SignUpConfirmation: React.SFC<Props> = ({
  signupToken,
  completeSignUp,
  name,
}) => (
  <Box width={250} mx="auto" my={2}>
    <F
      id="SignUpConfirmation.welcome"
      defaultMessage="{name}, welcome!"
      values={{ name: <Text.Title key="name">{name}</Text.Title> }}
    >
      {(...text) => <Text.ThinTitle is="div">{text}</Text.ThinTitle>}
    </F>
    <Elements>
      <Form signupToken={signupToken} submitTokens={completeSignUp} />
    </Elements>
    <IntlDocumentTitle titleId="signUp" />
  </Box>
);

export default SignUpConfirmation;
