import React from 'react';
import { isEmpty, prop } from 'ramda';
import { withState, withHandlers, compose } from 'recompose';
import { Flex, Box } from 'grid-styled';
import {
  Dialog,
  Button,
  Tabs,
  TabItemSmall,
  CheckboxToggle,
  Footer,
} from '@hm/ukie';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DateRange } from 'moment-range';

import messages from './messages';

import { SubTitle } from '../Title';
import Settings from '../../containers/Calendar/Settings';
import Status from '../../containers/Calendar/Status';
import Header from '../../containers/Calendar/Header';
import ShiftStatus from '../../containers/Calendar/ShiftStatus';
import SliceDurations from './SliceDurations';
import Wave from '../Wave';

import { ApproximationType } from '../../reducers/currentProject';
import { TooManySlicesError } from '../Calendar';
import FeatureToggle from '../FeatureToggle';

const StyledTabs = Tabs.extend`
  margin-top: 16px;
`;

export interface OwnProps {
  onBack(): void;
  onClose?(): void;
}

export interface StateProps {
  isValid: boolean;
  tooManySlices: boolean;
  selectedIntervals: DateRange[];
  availableIntervals: DateRange[];
  sliceDuration: string;
  availableSliceDurations: moment.Duration[];
  startTime: string;
}

export interface DispatchProps {
  onSubmit(): void;
  onApproximationType(approximationType: ApproximationType): void;
  onSliceDuration(duration: string): void;
  onSelectionClick(interval: DateRange, isInverted?: boolean): void;
  onSelection(interval: DateRange, isInverted?: boolean): void;
  onSelectionEnd(): void;
  onStartTimeChange(startTime: string): void;
}

interface EnhancedProps {
  rangeFilterEnabled: boolean;
  prevSliceDuration: string;
  onRangeFilter(): void;
  setRangeFilterEnabled(): void;
  setPrevSliceDuration(string);
}

type Props = Outer & EnhancedProps;
type Outer = StateProps & DispatchProps & OwnProps;

const NewApproximatedProject: React.SFC<Props> = ({
  onBack,
  onClose,
  onSubmit,
  isValid,
  tooManySlices,
  onApproximationType,
  selectedIntervals,
  availableIntervals,
  startTime,
  sliceDuration,
  availableSliceDurations,
  onSelectionClick,
  onSelection,
  onSelectionEnd,
  onStartTimeChange,
  onSliceDuration,
  rangeFilterEnabled,
  onRangeFilter,
}) => (
  <Dialog onBack={onBack} onClose={onClose} title={<Header />}>
    <StyledTabs centered selected="day" onClick={onApproximationType}>
      <TabItemSmall value="day">Day</TabItemSmall>
      <TabItemSmall value="week" disabled>
        Week
      </TabItemSmall>
      <TabItemSmall value="month" disabled>
        Month
      </TabItemSmall>
      <TabItemSmall value="year" disabled>
        Year
      </TabItemSmall>
    </StyledTabs>
    <Flex flex="1">
      <Flex flexDirection="column" align="center" flex="1" p="40px 0">
        <SubTitle>
          <FormattedMessage {...messages.dayTitle} />
        </SubTitle>
        {isEmpty(availableIntervals) ? null : (
          <Box m="auto 0">
            <FeatureToggle feature="startTime">
              {isEnabled =>
                isEnabled ? (
                  <Wave
                    date={availableIntervals[0].start}
                    selectedIntervals={selectedIntervals}
                    availableIntervals={availableIntervals}
                    onSelection={onSelection}
                    onSelectionClick={onSelectionClick}
                    onSelectionEnd={onSelectionEnd}
                    sliceDuration={sliceDuration}
                    withStartTime
                    startTime={startTime}
                    onStartTimeChange={onStartTimeChange}
                  />
                ) : (
                  <Wave
                    date={availableIntervals[0].start}
                    selectedIntervals={selectedIntervals}
                    availableIntervals={availableIntervals}
                    onSelection={onSelection}
                    onSelectionClick={onSelectionClick}
                    onSelectionEnd={onSelectionEnd}
                    sliceDuration={sliceDuration}
                  />
                )
              }
            </FeatureToggle>
          </Box>
        )}
      </Flex>
      <Settings info={messages.selectAllApproximated}>
        <CheckboxToggle checked={rangeFilterEnabled} onChange={onRangeFilter}>
          <FormattedMessage {...messages.filter} />
        </CheckboxToggle>

        <SliceDurations
          disabled={!rangeFilterEnabled}
          sliceDuration={sliceDuration}
          onSliceDuration={onSliceDuration}
          availableSliceDurations={availableSliceDurations}
        />

        <Box mt={2}>
          <FeatureToggle feature="startTime">
            <ShiftStatus />
          </FeatureToggle>
        </Box>
      </Settings>
    </Flex>
    <Footer
      info={<Status />}
      warning={tooManySlices ? <TooManySlicesError /> : null}
      actions={
        <Button primary onClick={onSubmit} disabled={!isValid || tooManySlices}>
          <FormattedMessage {...messages.create} />
        </Button>
      }
    />
  </Dialog>
);

export default compose<Props, Outer>(
  withState('rangeFilterEnabled', 'setRangeFilterEnabled', true),
  withState('prevSliceDuration', 'setPrevSliceDuration', prop('sliceDuration')),
  withHandlers({
    onRangeFilter: ({
      rangeFilterEnabled,
      setRangeFilterEnabled,

      setPrevSliceDuration,
      prevSliceDuration,

      sliceDuration,
      onSliceDuration,
    }) => () => {
      const nextValue = !rangeFilterEnabled;
      setRangeFilterEnabled(nextValue);

      if (nextValue) {
        onSliceDuration(prevSliceDuration);
      } else {
        onSliceDuration('P1D');
        setPrevSliceDuration(sliceDuration);
      }
    },
  }),
)(NewApproximatedProject);
