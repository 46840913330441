import React from 'react';
import {
  Card,
  Text,
  Flex,
  Box,
  IconButton,
  Bytes,
  Pictogram,
  colors,
  rotateCcw,
  PictogramProps,
} from '@hm/ukie';
import { injectIntl, InjectedIntlProps, defineMessages } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';
import D from '../IntlDate';
import { Dataset } from '../../reducers/datasets';
import styled from 'styled-components';

interface ReuseIconProps {
  isFetching: boolean;
}

const ReuseIcon = styled<ReuseIconProps & PictogramProps>(Pictogram)`
  ${({ isFetching }) =>
    isFetching ? `animation: ${rotateCcw} 1.5s linear infinite;` : ''};
`;

const messages = defineMessages({
  createdAt: { id: 'DatasetCard.createdAt', defaultMessage: 'Created at' },
  size: { id: 'DatasetCard.size', defaultMessage: 'Dataset size' },
  delete: { id: 'DatasetCard.delete', defaultMessage: 'Delete dataset' },
  reuse: { id: 'DatasetCard.reuse', defaultMessage: 'Reuse this dataset' },
});

interface Props extends RouteComponentProps<any> {
  dataset: Dataset;
  onDelete(id: string): Promise<any>;
  onReuse(id: string): Promise<any>;
}

interface State {
  isHovered: boolean;
  isFetching: boolean;
  reuseErrored: boolean;
}

class DatasetCard extends React.Component<Props & InjectedIntlProps, State> {
  state: State = { isHovered: false, isFetching: false, reuseErrored: null };

  onReuse = () => {
    this.setState({ isFetching: true, reuseErrored: false }, () => {
      this.props
        .onReuse(this.props.dataset.id)
        .then(({ value: { layerId } }) =>
          this.setState({ isFetching: false, reuseErrored: false }, () => {
            this.props.history.push(`/import/${layerId}`);
          }),
        )
        .catch(() => this.setState({ reuseErrored: true, isFetching: false }));
    });
  };

  render() {
    const {
      dataset,
      onDelete,
      intl: { formatMessage: f },
    } = this.props;

    return (
      <Card
        hover={{ boxShadow: 2 }}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        <Flex width="100%">
          <Box mr={1} width="calc(100% - 48px - 16px)">
            <Text
              fontSize={2}
              lineHeight="24px"
              truncate
              title={dataset.filename}
              is="div"
            >
              {dataset.filename}
            </Text>
          </Box>
          <Flex ml="auto">
            {this.state.isHovered ? (
              <>
                <IconButton
                  icon={
                    <ReuseIcon
                      isFetching={this.state.isFetching}
                      name="refresh"
                      color={this.state.reuseErrored ? colors.error : undefined}
                    />
                  }
                  onClick={this.onReuse}
                  title={f(messages.reuse)}
                />
                <Box ml="4px">
                  <IconButton
                    icon="bin"
                    onClick={() => onDelete(dataset.id)}
                    title={f(messages.delete)}
                  />
                </Box>
              </>
            ) : null}
          </Flex>
        </Flex>
        <Bytes
          value={dataset.size}
          mt={2}
          is="div"
          lineHeight="label"
          title={f(messages.size)}
        />
        <D value={dataset.createdAt} title={f(messages.createdAt)} />
      </Card>
    );
  }
}

export default injectIntl(withRouter(DatasetCard));
