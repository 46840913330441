import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { splitEvery } from 'ramda';
import { compose } from 'recompose';

import Month from './Month';
import Title from '../Title';
import shouldCalendarUpdate from '../shouldCalendarUpdate';
import withPrettySelectedIntervals from '../withPrettySelectedIntervals';

const Container = styled.div`
  padding-bottom: 44px;

  &:first-child {
    padding-top: 40px;
  }
`;

const Row = Flex.extend`
  justify-content: space-between;
  margin: 5px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledTitle = Title.extend`
  font-weight: 400;
`;

export interface OwnProps {
  date: moment.Moment;
  style?: any;
}

export interface ConnectedProps {
  availableIntervals: DateRange[];
  selectedIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
  onClick(date: moment.Moment): void;
}

type Props = OwnProps & ConnectedProps;

export class DaysNavigator extends React.Component<Props, never> {
  render() {
    const { date, onClick } = this.props;
    const rows = splitEvery(
      3,
      Array.from(
        new DateRange(
          date.clone().startOf('year'),
          date.clone().endOf('year'),
        ).by('month'),
      ),
    );

    return (
      <Container>
        <StyledTitle>{date.year()}</StyledTitle>
        <Flex flexDirection="column">
          {rows.map((row, r) => (
            <Row key={r}>
              {row.map(month => (
                <Month
                  key={+month}
                  date={month}
                  availableIntervals={this.props.availableIntervals}
                  selectedIntervals={this.props.selectedIntervals}
                  filteredOutIntervals={this.props.filteredOutIntervals}
                  onClick={onClick}
                />
              ))}
            </Row>
          ))}
        </Flex>
      </Container>
    );
  }
}

export default compose<Props, Props>(
  withPrettySelectedIntervals('P1D'),
  shouldCalendarUpdate('year'),
)(DaysNavigator);
