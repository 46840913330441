import { get } from '../api/rest';
import schema from '../schemas/gradients';

const LOAD_GRADIENTS = 'LOAD_GRADIENTS';

export const loadGradients = () => ({
  type: LOAD_GRADIENTS,
  payload: get('/gradients'),
  meta: { schema },
});

export const types = { LOAD_GRADIENTS };
