import { connect } from 'react-redux';
import { compose } from 'redux';
import { lifecycle } from 'recompose';

import {
  canSubmit,
  getApproximationFunction,
  getSliceType,
  getSliceDuration,
  isSliceTypeEnabled,
  isSliceTypeStatic,
  getCalendarLabel,
} from '../selectors/calendar';

import {
  submitLayer,
  initNewApproximatedLayer,
  reset,
  setFunction,
  setSliceType,
} from '../actions/calendar';
import { hideModal } from '../actions/modals';
import { AppState } from '../models';

import ApproximatedNewMetric, {
  OwnProps,
} from '../components/ApproximatedNewMetric';

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  isValid: canSubmit(state),
  approximationFunction: getApproximationFunction(state),
  sliceType: getSliceType(state),
  sliceDuration: getSliceDuration(state),
  isSliceTypeEnabled: isSliceTypeEnabled(state),
  isSliceTypeStatic: isSliceTypeStatic(state),
  label: getCalendarLabel(state),
});

const mapDispatchToProps = {
  onClose: hideModal,
  onSubmit: submitLayer,
  onMount: initNewApproximatedLayer,
  onUnmount: reset,
  onApproximationFunction: setFunction,
  onSliceType: setSliceType,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount(this: any) {
      /* tslint:disable-next-line:no-invalid-this */
      this.props.onMount();
    },
    componentWillUnmount(this: any) {
      /* tslint:disable-next-line:no-invalid-this */
      this.props.onUnmount();
    },
  }),
)(ApproximatedNewMetric);
