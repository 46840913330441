import { types } from '../actions/user';
import { CurrentUser, WelcomeProject } from '../models';
import { fulfill, pend } from '../utils/promise';

export interface UserInfo {
  accessToken: string;
  refreshToken: string;
  isLoggedIn: boolean;
  fetchingToken: boolean;
  info: CurrentUser;
  welcomeProjects: WelcomeProject[];
}

export const initialState: UserInfo = {
  isLoggedIn: false,
  fetchingToken: false,
  accessToken: null,
  refreshToken: null,
  info: null,
  welcomeProjects: [],
};

export default (state = initialState, action: any): UserInfo => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.LOG_IN):
      return {
        ...state,
        isLoggedIn: true,
        ...payload,
      };

    case fulfill(types.COMPLETE_SIGN_UP):
      return {
        ...state,
        isLoggedIn: true,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        welcomeProjects: payload.welcome,
      };

    case pend(types.REFRESH_ACCESS_TOKEN):
      return {
        ...state,
        fetchingToken: true,
      };

    case fulfill(types.REFRESH_ACCESS_TOKEN):
      return {
        ...state,
        fetchingToken: false,
        accessToken: payload,
      };

    case types.LOG_OUT:
      return initialState;

    case fulfill(types.GET_PROFILE):
      return { ...state, info: payload };

    case pend(types.UPDATE_USER):
    case fulfill(types.UPDATE_USERPIC):
      return {
        ...state,
        info: {
          ...state.info,
          ...payload,
        },
      };

    case fulfill(types.GET_WELCOME_PROJECTS):
      return {
        ...state,
        welcomeProjects: payload.welcome,
      };

    default:
      return state;
  }
};
