import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { findIndex, equals } from 'ramda';

export interface Props extends RouteProps {
  conditions: boolean[];
  fallbacks: React.ReactChild[];
}

/*
 * Route with conditions. If some of the conditions are not true
 * renders appropriate fallback
 */
const RouteIf: React.SFC<Props> = ({
  conditions,
  fallbacks,
  component: Component,
  render,
  ...rest
}) => {
  const fallbackIndex = findIndex(equals(false), conditions);

  return (
    <Route
      {...rest}
      render={props =>
        fallbackIndex === -1 ? (
          render ? (
            render(props)
          ) : (
            <Component {...props as any} />
          )
        ) : (
          fallbacks[fallbackIndex]
        )
      }
    />
  );
};

export default RouteIf;
