import React from 'react';
import { Dialog, Button, Footer, Text } from '@hm/ukie';
import { Flex } from 'grid-styled';
import { FormattedMessage } from 'react-intl';

import EditHeader from '../../containers/Calendar/EditHeader';
import InnerFooter from '../../containers/Calendar/InnerFooter';
import Status from '../../containers/Calendar/Status';
import Settings from '../../containers/Calendar/Settings';

import Scrollers from '../Calendar/Scrollers';
import DayRangeFilter from '../Calendar/DayRangeFilter';
import { RelativeFlex } from '../Relative';
import {
  messages,
  TooManySlicesError,
  AvailableTimeIntervals,
} from '../Calendar';

import { BaseLayer } from '../../reducers/baseLayers';

export interface StateProps {
  metrics: BaseLayer[];
  tooManySlices: boolean;
}

export interface DispatchProps {
  onClose(): void;
  onSubmit(): void;
}

export type Props = StateProps & DispatchProps;

interface State {
  currentSlide: string;
}

export default class EditProject extends React.Component<Props, State> {
  state: State = { currentSlide: null };

  onHide = () => this.setState({ currentSlide: null });

  onTimeIntervalsToggle = () => {
    this.setState({
      currentSlide: this.state.currentSlide ? null : 'timeIntervals',
    });
  };

  render() {
    const { metrics, onSubmit, onClose, tooManySlices } = this.props;
    const { currentSlide } = this.state;

    return (
      <Dialog onClose={onClose} title={<EditHeader />}>
        <Flex flex="1">
          <RelativeFlex flex="1" flexDirection="column">
            <Scrollers />
            <InnerFooter />
            <DayRangeFilter
              show={currentSlide === 'timeIntervals'}
              onClose={this.onHide}
            />
          </RelativeFlex>
          <Settings
            info={messages.selectAllRegular}
            currentSlide={currentSlide}
          >
            <FormattedMessage {...messages.availableTimeIntervals}>
              {t => (
                <Text fontSize={2} is="div" mb={2}>
                  {t}
                </Text>
              )}
            </FormattedMessage>
            {metrics.map(layer => (
              <AvailableTimeIntervals layer={layer} key={layer.id} />
            ))}
          </Settings>
        </Flex>
        <Footer
          info={<Status />}
          warning={tooManySlices ? <TooManySlicesError /> : null}
          actions={
            <Button primary onClick={onSubmit} disabled={tooManySlices}>
              <FormattedMessage {...messages.update} />
            </Button>
          }
        />
      </Dialog>
    );
  }
}
