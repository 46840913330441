import React from 'react';
import { Select2, Select2Props } from '@hm/ukie';
import { Control, ControlProps } from 'react-redux-form';

type ControlSelectProps = ControlProps<any> & Pick<Select2Props, 'items'>;

export const ControlSelect: React.SFC<ControlSelectProps> = ({
  items,
  ...props
}) => (
  <Control.select
    component={Select2}
    mapProps={{
      selectedItem: ({ modelValue }) => modelValue,
    }}
    controlProps={{ items }}
    {...props}
  />
);
