import { call, take, put, fork } from 'redux-saga/effects';
import { eventChannel, channel, buffers, Channel } from 'redux-saga';
import { volumer } from '@hm/volumer-api';

const OPEN = 'OPEN';
const CLOSE = 'CLOSE';
const ERROR = 'ERROR';
const MESSAGE = 'MESSAGE';

export interface Channels {
  open: Channel<Event>;
  close: Channel<CloseEvent>;
  error: Channel<Event>;
  frame: Channel<volumer.stream.Frame$Properties>;
  pong: Channel<volumer.types.Pong$Properties>;
  screenshotResponse: Channel<volumer.stream.ScreenshotResponse$Properties>;
  response: Channel<volumer.raycast.Response$Properties>[];
  addResponse(newChannel: Channel<volumer.raycast.Response$Properties>): void;
}

/**
 * Routes incoming socket messages into separate channels
 * XXX: This is due to the fact that when channel has multiple subscribers
 * messages are split between them rather than being accessible to everyone
 * Might become obsolete when redux-saga introduces multicast channels
 * https://github.com/redux-saga/redux-saga/issues/820
 */
export default function*(socket: WebSocket) {
  const socketChannel = getMonoChannel(socket);
  const responseChannels = [];

  const separateChannels: Channels = {
    open: yield call(channel, buffers.dropping(1)),
    close: yield call(channel, buffers.dropping(1)),
    error: yield call(channel, buffers.dropping(1)),
    frame: yield call(channel, buffers.sliding(5)),
    pong: yield call(channel, buffers.dropping(1)),
    screenshotResponse: yield call(channel, buffers.dropping(1)),
    // All other channels have only one subscriber, but this one
    // is needed everywhere
    response: responseChannels,
    addResponse: newChannel => responseChannels.push(newChannel),
  };

  yield fork(route, socketChannel, separateChannels);

  return separateChannels;
}

/**
 * Makes it possible to receive events from WebSocket in sagas just like
 * normal Redux actions
 */
const getMonoChannel = (socket: WebSocket) =>
  eventChannel(emit => {
    socket.onopen = (e: Event) => emit({ type: OPEN, payload: e });
    socket.onclose = (e: CloseEvent) => emit({ type: CLOSE, payload: e });
    socket.onerror = (e: Event) => emit({ type: ERROR, payload: e });
    socket.onmessage = (msg: { data: ArrayBuffer }) => {
      const rawMessage = new Uint8Array(msg.data);
      const decodedMessage = volumer.stream.ServerMessage.decode(rawMessage);
      emit({ type: MESSAGE, payload: decodedMessage });
    };

    return () => socket.close();
  });

function* route(monoChannel: Channel<any>, channels: Channels) {
  while (true) {
    const { type, payload } = yield take(monoChannel);

    switch (type) {
      case OPEN:
        yield put(channels.open, payload);
        break;

      case MESSAGE:
        const { frame, pong, response, screenshotResponse } = payload;
        if (frame) yield put(channels.frame, frame);
        if (pong) yield put(channels.pong, pong);
        if (screenshotResponse) {
          yield put(channels.screenshotResponse, screenshotResponse);
        }
        if (response) {
          for (const responseChan of channels.response) {
            yield put(responseChan, response);
          }
        }
        break;

      case CLOSE:
        yield put(channels.close, payload);
        break;

      case ERROR:
        yield put(channels.error, payload);
        break;

      default:
        break;
    }
  }
}
