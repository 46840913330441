import React from 'react';
import InteractiveMap, { InteractiveMapProps, Viewport } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_TOKEN } from '../../keys';
import { fitBounds } from 'viewport-mercator-project/dist/perspective-mercator-viewport';
import { Omit } from 'utility-types';

import Controls from './Controls';

export type State = Pick<Viewport, 'latitude' | 'longitude' | 'zoom'>;
export type ChildFunction = (viewport: State) => React.ReactNode;

export interface MapProps
  extends Omit<InteractiveMapProps, 'mapboxApiAccessToken'> {
  // https://github.com/uber-common/viewport-mercator-project/blob/master/docs/api-reference/web-mercator-utils.md#fitboundsopts
  initialBounds?: number[][];
  initialBoundsPadding?: number;
  children: React.ReactNode | ChildFunction;
}

/** Styled react-map-gl component with state handling */
export default class Map extends React.Component<MapProps, State> {
  static defaultProps = {
    width: 400,
    height: 400,
    initialBounds: [[0, 0], [50, 50]],
    fitBoundsPadding: 0,
  };

  state = fitBounds({
    width: this.props.width,
    height: this.props.height,
    bounds: this.props.initialBounds,
    padding: this.props.initialBoundsPadding,
  });

  onViewportChange = ({ latitude, longitude, zoom }) =>
    this.setState({ latitude, longitude, zoom });

  render() {
    const {
      children,
      initialBounds,
      initialBoundsPadding,
      width,
      height,
      ...props
    } = this.props;

    return (
      <InteractiveMap
        width={width}
        height={height}
        onViewportChange={this.onViewportChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/habidatum01/cjmc64tkk53fc2rmpc91si9x4"
        {...this.state}
        {...props}
      >
        {typeof children === 'function' ? children(this.state) : children}
        <Controls onViewportChange={this.onViewportChange} />
      </InteractiveMap>
    );
  }
}
