import { connect } from 'react-redux';
import Flip from '../components/Metric/Flip';
import { updateEffect, deleteEffect } from '../actions/effects';
import { getEffectById } from '../selectors/effects';

const mapStateToProps = (state, ownProps) => ({
  effect: getEffectById(state, ownProps),
});

const mapDispatchToProps = {
  onUpdate: updateEffect,
  onDelete: deleteEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Flip);
