import { Anomaly } from '../models';
import { fulfill } from '../utils/promise';
import { types as layerTypes } from '../actions/layers';
import { types as projectTypes } from '../actions/projects';

export interface LayerAnomaliesState {
  entities: { [index: string]: Anomaly };
}

export const initialState = {
  entities: {},
};

export default (state = initialState, action: any): LayerAnomaliesState => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(layerTypes.GET_LAYER):
    case fulfill(layerTypes.CREATE_LAYER):
    case fulfill(projectTypes.GET_PROJECT):
      return {
        ...state,
        entities: payload.entities.layerAnomalies,
      };

    default:
      return state;
  }
};
