import React from 'react';
import { isEmpty } from 'ramda';
import { connect } from 'react-redux';
import { Flex, Box, Text } from '@hm/ukie';

import {
  fetchBaseLayers,
  deleteBaseLayer,
  BaseLayer,
  updateBaseLayer,
} from '../../reducers/baseLayers';
import { getEnvironmentsWithSortedLayers } from '../../reducers/explorer';
import { Environment } from '../../reducers/environments';
import { AppState } from '../../models';

import Empty from './Empty';
import Tiles from './Tiles';
import Collapsible from '../Collapsible';
import { PollingMetricCard } from '../MetricCard';
import { getSelectedEnvironmentId } from '../../selectors/environments';

interface EnvironmentWithLayers extends Environment {
  layers: BaseLayer[];
}

interface OwnProps {
  selectedId: string;
  onSelect(id: string): void;
}

interface ProvidedProps {
  selectedEnvironmentId: string;
  onDelete(id: string): Promise<any>;
  environments: EnvironmentWithLayers[];
  fetchBaseLayers(): void;
  onChange(id: string, updates: Partial<BaseLayer>): Promise<any>;
}

type Props = OwnProps & ProvidedProps;

export class Metrics extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchBaseLayers();
  }

  render() {
    const {
      selectedEnvironmentId,
      environments,
      selectedId,
      onSelect,
      onDelete,
      onChange,
    } = this.props;

    const selectedEnvironment = environments.find(
      ({ id }) => id === selectedEnvironmentId,
    );

    return (
      <>
        {selectedEnvironment ? (
          <>
            <Text fontSize={3} mb={2} is="div">
              {selectedEnvironment.name}
            </Text>

            {!isEmpty(selectedEnvironment.layers) ? (
              <Tiles
                tiles={selectedEnvironment.layers}
                collectionId={selectedEnvironmentId}
                renderTile={(metric: BaseLayer) => (
                  <PollingMetricCard
                    metric={metric}
                    selected={metric.id === selectedId}
                    onClick={
                      metric.state !== 'failed'
                        ? () => onSelect(metric.id)
                        : () => ({})
                    }
                    onDelete={() => onDelete(metric.id)}
                    onRename={label => onChange(metric.id, { label })}
                  />
                )}
              />
            ) : (
              <Flex height="100%">
                <Text fontSize={3} fontWeight="semibold" m="auto" color="grey7">
                  <Empty />
                </Text>
              </Flex>
            )}
          </>
        ) : (
          environments.map((environment, i) => (
            <Box mb={3} key={environment.id}>
              <Collapsible
                isOpen={i === 0}
                title={onClick => (
                  <span onClick={onClick}>
                    {environment.name}{' '}
                    <Text.Important>
                      ({environment.layers.length})
                    </Text.Important>
                  </span>
                )}
              >
                {isEmpty(environment.layers) ? (
                  <Text
                    is="div"
                    color="grey7"
                    mx="auto"
                    mt={1}
                    textAlign="center"
                  >
                    <Empty />
                  </Text>
                ) : (
                  <Box pt={2}>
                    <Tiles
                      tiles={environment.layers}
                      collectionId={selectedEnvironmentId}
                      renderTile={(metric: BaseLayer) => (
                        <PollingMetricCard
                          metric={metric}
                          selected={metric.id === selectedId}
                          onClick={
                            metric.state !== 'failed'
                              ? () => onSelect(metric.id)
                              : () => ({})
                          }
                          onDelete={() => onDelete(metric.id)}
                          onRename={label => onChange(metric.id, { label })}
                        />
                      )}
                    />
                  </Box>
                )}
              </Collapsible>
            </Box>
          ))
        )}
      </>
    );
  }
}

export default connect(
  (state: AppState, ownProps: OwnProps) => ({
    selectedEnvironmentId: getSelectedEnvironmentId(state),
    environments: getEnvironmentsWithSortedLayers(
      state,
      // FIXME: move to selector
      getSelectedEnvironmentId(state),
    ),
  }),
  {
    fetchBaseLayers,
    onDelete: deleteBaseLayer,
    onChange: updateBaseLayer,
  },
)(Metrics);
