import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import LabelInput from './LabelInput';
import AutosizeInput from '../AutosizeInput';
import Title from '../Title';

const StyledTitle = Title.extend`
  flex-shrink: 0;
  margin: 0;
`;

const messages = defineMessages({
  title: {
    id: 'Calendar/Header.title',
    defaultMessage: 'Primary settings of',
  },
  project: {
    id: 'App.project',
    defaultMessage: 'project',
  },
});

interface Props {
  label: string;
  className?: string;
  onLabelUpdate(label: string): void;
}

const Header: React.SFC<Props> = ({ label, onLabelUpdate, className }) => {
  return (
    <div className={className}>
      <StyledTitle>
        <FormattedMessage {...messages.title} />
      </StyledTitle>
      <AutosizeInput>
        <LabelInput
          value={label}
          onChange={e => onLabelUpdate(e.currentTarget.value)}
        />
      </AutosizeInput>
      <StyledTitle>
        <FormattedMessage {...messages.project} />
      </StyledTitle>
    </div>
  );
};

export default styled(Header)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
