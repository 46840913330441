import React from 'react';
import { connect } from 'react-redux';
import { fetchBaseLayer } from '../../reducers/baseLayers';
import MetricCard, { Props } from './MetricCard';

interface ContainerProps {
  fetchBaseLayer(id: string): Promise<any>;
}

class PollingMetricCard extends React.Component<Props & ContainerProps> {
  timer: any;

  componentDidMount() {
    if (this.props.metric.state === 'uploading') {
      this.startPolling();
    }
  }

  componentWillUnmount() {
    this.clearPolling();
  }

  startPolling = () => {
    if (this.timer) return;
    this.timer = setTimeout(
      () =>
        this.props.fetchBaseLayer(this.props.metric.id).then(({ value }) => {
          if (value.state === 'uploading') {
            this.timer = null;
            this.startPolling();
          }
        }),
      5000 + Math.floor(Math.random() + 1500),
    );
  };

  clearPolling = () => clearInterval(this.timer);

  render() {
    const { fetchBaseLayer: _, ...props } = this.props;
    return <MetricCard {...props} />;
  }
}

export default connect((state: any, ownProps: Props) => ({}), {
  fetchBaseLayer,
})(PollingMetricCard);
