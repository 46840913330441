import React from 'react';
import { Flex } from 'grid-styled';
import { FormattedMessage } from 'react-intl';

import day from '../../styles/images/doad.gif';
import week from '../../styles/images/dow.gif';
import month from '../../styles/images/dom.gif';
import year from '../../styles/images/moy.gif';

import GifButton from '../GifButton';
import SlideIn, { Props as SlideInProps } from '../SlideIn';

import { ApproximationType } from '../../reducers/currentProject';
import messages from '../ApproximatedNewMetric/messages';

const gifs = { day, week, month, year };

interface Props extends Pick<SlideInProps, 'onClose' | 'show'> {
  approximationType: ApproximationType;
  onChange(type: ApproximationType): void;
}

const ApproximationTypeHelper: React.SFC<Props> = ({
  show,
  approximationType,
  onClose,
  onChange,
}) => (
  <SlideIn onClose={onClose} title="Approximation types" show={show}>
    <Flex justify="space-between" width="100%">
      {Object.keys(gifs).map(type => (
        <GifButton
          key={type}
          src={gifs[type]}
          onClick={() => onChange(type as ApproximationType)}
          selected={approximationType === type}
        >
          <FormattedMessage {...messages[type]} />
        </GifButton>
      ))}
    </Flex>
  </SlideIn>
);

export default ApproximationTypeHelper;
