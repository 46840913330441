import moment from 'moment';
import { DateRange } from 'moment-range';
import { isEmpty, compose, find, prop, either, head } from 'ramda';

import {
  someContains,
  toStartTimeDate,
  snapIntervalToTime,
  snapToTime,
  toHHmm,
} from '../utils/time';

import {
  types,
  calendarPrefix,
  dayRangeFilterPrefix,
} from '../actions/calendar';
import { ZoomLevel } from '../models';

import createSelectionReducer, {
  SelectionState,
  initialState as selectionInitialState,
} from './createSelectionReducer';

import {
  ProjectType,
  ApproximationType,
  initialApproximationSettings,
} from './currentProject';

export type ApproximationFunction = 'average' | 'sum';
export type SliceType = 'uniform' | 'unique';

const selectionReducer = createSelectionReducer(calendarPrefix);
const dayRangeFilterReducer = createSelectionReducer(dayRangeFilterPrefix);

export interface CalendarState {
  // UI state
  zoomLevel: ZoomLevel;
  lastZoomLevel: ZoomLevel;

  // time data
  availableIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
  filterPreview: DateRange[];

  selection: SelectionState;
  dayRangeFilter: SelectionState;

  // general project settings
  label: string;
  type: ProjectType;
  sliceDuration: string;

  // approximation project settings
  approximationType: ApproximationType;
  startTime: string;
  startAtWeekday: number;
  startAtMonthDay: number;
  startAtMonth: number;
  selectedWeekdays: number[];
  selectedMonthDays: number[];
  selectedMonths: number[];

  // approximated layer settings
  layerOriginId: string;
  isSliceTypeEnabled: boolean;
  isSliceTypeStatic: boolean;
  function: ApproximationFunction;
  sliceType: SliceType;

  // filters
  filterSettings: Dict<any[]>;
}

export const initialState: CalendarState = {
  zoomLevel: 'precise',
  lastZoomLevel: undefined,

  availableIntervals: [],
  filteredOutIntervals: [],
  filterPreview: [],

  label: 'Unnamed',
  type: 'regular',
  sliceDuration: 'PT5M',

  selection: selectionInitialState,
  dayRangeFilter: selectionInitialState,

  ...initialApproximationSettings,

  layerOriginId: null,
  isSliceTypeEnabled: true,
  isSliceTypeStatic: false,
  function: 'average',
  sliceType: 'unique',

  filterSettings: {},
};

/**
 * Calendar reducer
 *
 * You can think of it as form state which handles creating/editing of
 * projects. It holds only own values which then sent to API and cleaned up
 * on success.
 */

// TODO: extract various reducers to be composed into several specific-case
// reducers (new reg project, new apprx layer for regular, new apprx layer for
// apprx, etc). This thing is not scallable.
//
// Candidates:
//   * approximation settings (apprx type, slice type, aggrg function)
//   * shift settings for apprx project
export default (state = initialState, action: any): CalendarState => {
  const { type, payload } = action;

  switch (type) {
    case types.SELECT_SLICE_DURATION:
      const duration = moment.duration(payload);

      return {
        ...state,
        sliceDuration: payload,
        selection: {
          ...state.selection,
          selectedIntervals: state.selection.selectedIntervals.map(interval =>
            snapIntervalToTime(
              interval.start
                .clone()
                .startOf(duration.asDays() < 1 ? 'day' : 'year'),
              duration,
              interval,
            ),
          ),
        },
        ...(state.type === 'approximated'
          ? newStartTime(state, action.payload)
          : undefined),
      };

    case types.CHANGE_CALENDAR_ZOOM_LEVEL:
      return { ...state, zoomLevel: payload };

    case types.SELECTION:
      return {
        ...state,
        selection: selectionReducer(state.selection, action),
      };

    case types.SELECTION_END:
    case types.SELECTION_CLICK: // TODO: write test for it
      const nextSelection = selectionReducer(state.selection, action);

      return {
        ...state,
        selection: nextSelection,
        startTime:
          isEmpty(nextSelection.selectedIntervals) ||
          state.type !== 'approximated' ||
          someContains(
            nextSelection.selectedIntervals,
            toStartTimeDate(state.startTime),
          )
            ? state.startTime
            : startTimeReducer(
                nextSelection.selectedIntervals,
                state.startTime,
              ),
      };

    case types.UPDATE_LABEL:
      return { ...state, label: payload };

    case types.UPDATE_START_TIME:
      return { ...state, startTime: payload };

    case types.SET_APPROXIMATION_TYPE:
      return { ...state, approximationType: payload };

    case types.INIT:
      return { ...state, ...payload };

    case types.RESET:
      return {
        ...initialState,
        lastZoomLevel: state.zoomLevel,
      };

    case types.SET_FUNCTION:
      return { ...state, function: payload };

    case types.SET_SLICE_TYPE:
      return { ...state, sliceType: payload };

    case types.SET_LAYER_ORIGIN_ID:
      return { ...state, layerOriginId: payload };

    case types.CLEAR_SELECTION:
      return {
        ...state,
        selection: selectionInitialState,
      };

    case types.SET_FILTER_SETTINGS:
      return {
        ...state,
        filterSettings: timeFiltersReducer(state.filterSettings, action),
      };

    case types.SELECT_ALL:
      return {
        ...state,
        selection: {
          ...state.selection,
          selectedIntervals: state.availableIntervals,
        },
      };

    case types.SET_FILTERED_OUT_INTERVALS:
      return {
        ...state,
        filteredOutIntervals: payload,
      };

    case types.SET_FILTER_PREVIEW:
      return {
        ...state,
        filterPreview: payload,
      };

    case types.FILTER_LEAVE:
      return {
        ...state,
        filterPreview: [],
      };

    case types.DAY_RANGE_FILTER_SELECTION:
      return {
        ...state,
        dayRangeFilter: dayRangeFilterReducer(state.dayRangeFilter, action),
      };

    case types.DAY_RANGE_FILTER_SELECTION_END:
      const nextDayRangeFilterState = dayRangeFilterReducer(
        state.dayRangeFilter,
        action,
      );

      return {
        ...state,
        dayRangeFilter: nextDayRangeFilterState,
        filterSettings: {
          ...state.filterSettings,
          timeIntervals: nextDayRangeFilterState.selectedIntervals,
        },
      };

    default:
      return state;
  }
};

const timeFiltersReducer = (
  state: Dict<string[]>,
  action: any,
): Dict<string[]> => ({
  ...state,
  [action.payload.type]: action.payload.selectedSettings,
});

const startTimeReducer = (
  selectedIntervals: DateRange[],
  prevStartTime: string,
) => {
  const startTimeDate = toStartTimeDate(prevStartTime);

  return compose(
    (date: moment.Moment) => toHHmm(date.clone().utc()),
    prop('start'),
    either(find<any>(({ start }) => start.isAfter(startTimeDate)), head),
  )(selectedIntervals);
};

const newStartTime = (state: CalendarState, duration: string) => {
  const [startHours, startMinutes] = state.startTime.split(':');
  const approximationStart = state.availableIntervals[0].start;

  return {
    startTime: toHHmm(
      snapToTime(
        approximationStart,
        moment.duration(duration),
        approximationStart
          .clone()
          .hours(Number(startHours))
          .minutes(Number(startMinutes)),
      ),
    ),
  };
};
