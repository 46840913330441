import { takeLatest, put, select } from 'redux-saga/effects';

import {
  getSelectedIntervals,
  getCalendarLabel,
  getStartTime,
  getTimeFilters,
} from '../../selectors/calendar';

import { toAPIFilters } from '../../utils/helpers';

import { getCurrentProjectId, isApproximated } from '../../selectors/project';
import { SAVE_EDITS } from '../../actions/calendar';
import { normalizedUpdateProject } from '../../actions/projects';
import { hideModal } from '../../actions/modals';

export default function*() {
  yield takeLatest(SAVE_EDITS, function*() {
    yield put.resolve(
      normalizedUpdateProject(yield select(getCurrentProjectId), {
        selectedTimeIntervals: yield select(getSelectedIntervals),
        label: yield select(getCalendarLabel),
        // select approximation settings if needed
        ...((yield select(isApproximated))
          ? {
              startTime: yield select(getStartTime),
            }
          : undefined),
        timeFilters: toAPIFilters(yield select(getTimeFilters)),
      }),
    );

    yield put(hideModal());
  });
}
