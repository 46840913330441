export enum MouseButton {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right',
}

export enum Modifier {
  Q = 'KeyQ',
  W = 'KeyW',
  E = 'KeyE',
  R = 'KeyR',
  T = 'KeyT',
}

export const CLICK_THRESHOLD = 7;
