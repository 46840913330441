import { Titles } from '../components/IntlDocumentTitle';
import { AppState } from '../models';

const SET_DOCUMENT_TITLE = 'SET_DOCUMENT_TITLE';

export const setTitle = (titleId: Titles) => ({
  type: SET_DOCUMENT_TITLE,
  payload: titleId,
});

export const initialState = '';

export default (state = initialState, action) =>
  action.type === SET_DOCUMENT_TITLE ? action.payload : state;

export const getTitle = (state: AppState) => state.title;
