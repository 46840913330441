import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import ProjectMenu from '../components/ProjectMenu';

import {
  editProject,
  showCopyProject,
  confirmDeleteProject,
} from '../actions/modals';

import { updateCurrentProject } from '../actions/projects';
import { logOut } from '../actions/user';
import { AppState } from '../models';

const mapStateToProps = (state: AppState) => ({
  projectLabel: state.project.label,
});

const mapDispatchToProps = {
  logOut,
  push,
  onUpdate: updateCurrentProject,
  deleteProject: confirmDeleteProject,
  copyProject: showCopyProject,
  onEdit: editProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMenu);
