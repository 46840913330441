import React from 'react';
import 'moment-timezone';
import { Control, ControlProps, actions } from 'react-redux-form';
import DateTimeRangePicker from '../DateTimeRangePicker';
import { shiftTZ, unshiftTZ } from '../../utils/time';

interface Props extends ControlProps<any> {
  availableInterval: string;
  timezone: string;
  onUpdate(availableInterval: string): void;
}

// FIXME: convenient way to do this is to use `parser` and `formatter` but
// `parser` will dispatch onChange for the initial `modelValue` which breaks
// everything
const SelectedTimeInterval: React.SFC<Props> = ({
  availableInterval,
  onUpdate,
  timezone,
  ...props
}) => {
  const [minDate, maxDate] = availableInterval.split('/');

  return (
    <Control
      component={DateTimeRangePicker}
      mapProps={{
        invalid: ({ fieldValue }) => !fieldValue.pristine && !fieldValue.valid,
        value: ({ viewValue }) => viewValue.map(d => d && shiftTZ(d, timezone)),
      }}
      controlProps={{
        minDate: shiftTZ(minDate, timezone),
        maxDate: shiftTZ(maxDate, timezone),
      }}
      changeAction={(model, value) => {
        const val = value.map(d => d && unshiftTZ(d, timezone));

        const [start, end] = val;

        if (start && end) {
          onUpdate(`${start.toISOString()}/${end.toISOString()}`);
        }

        return actions.change(model, val);
      }}
      {...props}
    />
  );
};

export default SelectedTimeInterval;
