import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Button, Spinner, Text } from '@hm/ukie';
import PlainLink from '../PlainLink';
import { Flex } from 'grid-styled';
import { ApiError } from '../../models';

interface Props {
  canSubscribe: boolean;
  canUnsubscribe: boolean;
  cardValid: boolean;
  agreed: boolean;
  onSubscribe(): Promise<any>;
}

interface State {
  isSubmitting: boolean;
  error: ApiError;
}

export default class SubscribeActions extends React.Component<Props, State> {
  state = { isSubmitting: false, error: null };

  onSubscribe = () =>
    this.setState({ isSubmitting: true }, () =>
      this.props
        .onSubscribe()
        .then(() => this.setState({ isSubmitting: false }))
        .catch(({ error }) => this.setState({ isSubmitting: false, error })),
    );

  render() {
    const { canSubscribe, canUnsubscribe, cardValid, agreed } = this.props;
    const { isSubmitting, error } = this.state;

    return (
      <Flex my={1} justify="flex-end">
        {error && !isSubmitting ? (
          <Text color="error" mr={2}>
            {error.detail}
          </Text>
        ) : null}
        {isSubmitting ? (
          <Spinner size="small" />
        ) : canUnsubscribe ? (
          <PlainLink to="/unsubscribe">
            <Button>
              <F id="Plan.unsubscribe" defaultMessage="Unsubscribe" />
            </Button>
          </PlainLink>
        ) : canSubscribe ? (
          <Button
            primary
            onClick={this.onSubscribe}
            disabled={!cardValid || !agreed}
          >
            <F id="Plan.subscribe" defaultMessage="Subscribe" />
          </Button>
        ) : null}
      </Flex>
    );
  }
}
