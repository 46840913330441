import React from 'react';
import {
  Button,
  CheckboxToggle,
  IconButton,
  SimpleModal,
  Spinner,
  Submit,
  Text,
} from '@hm/ukie';
import { Box, Flex } from 'grid-styled';
import { FormattedMessage as F, FormattedTime } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApiError, AppState } from '../../models';
import { Plan, unsubscribe } from '../../reducers/billing';
import {
  getPlanType,
  getStripeSynchronized,
  getnextUpcomingInvoiceAt,
  hasOverage,
} from '../../reducers/billing/selectors';
import { fullDateTime } from '../../utils/formats';
import { planIsTrial } from '../../utils/helpers';
import IntlDocumentTitle from '../IntlDocumentTitle';
import Modal from '../Modal';
import Openable from '../Openable';
import PlainLink from '../PlainLink';

interface Props {
  type: Plan;
  nextUpcomingInvoiceAt: string;
  chargeOverage: boolean;
  stripeSynchronized: boolean;
  onUnsubscribe(deleteData: boolean): Promise<any>;
}

interface State {
  isSubmitting?: boolean;
  error?: ApiError;
  deleteData?: boolean;
}

export class Unsubscribe extends React.Component<
  Props & RouteComponentProps<any>,
  State
> {
  state: State = {
    isSubmitting: false,
    error: null,
    deleteData: false,
  };

  onUnsubscribe = () =>
    this.setState({ isSubmitting: true }, () =>
      this.props
        .onUnsubscribe(this.state.deleteData)
        .then(() => this.props.history.replace('/profile/plan'))
        .catch(({ error }: { error: ApiError }) =>
          this.setState({ error, isSubmitting: false }),
        ),
    );

  render() {
    const {
      type,
      nextUpcomingInvoiceAt,
      stripeSynchronized,
      chargeOverage,
    } = this.props;

    const { isSubmitting, error } = this.state;

    return (
      <Box p={3} style={{ height: '100%' }}>
        <Box>
          <PlainLink to="/profile/plan">
            <IconButton icon="arrowBack" />
          </PlainLink>
        </Box>

        <Openable>
          {({ open: showDialog, toggleOpen }) => (
            <Box width={340} mx="auto" style={{ whiteSpace: 'pre-wrap' }}>
              {planIsTrial(type) ? (
                <F
                  tagName="div"
                  id="Unsubscribe.accessTermination"
                  defaultMessage="After pressing the unsubscribe button {mode}. The access to the projects and data will be terminated. "
                />
              ) : (
                <React.Fragment>
                  <F
                    tagName="div"
                    id="Unsubscribe.unsubscribeInitiated"
                    defaultMessage="After pressing the button the unsubscription will be initiated. The access to Chronotope will be terminated after paid period expiration:"
                  />

                  <FormattedTime
                    value={nextUpcomingInvoiceAt}
                    {...fullDateTime}
                  >
                    {time => (
                      <Text.Title my={2} is="div">
                        {time}
                      </Text.Title>
                    )}
                  </FormattedTime>
                </React.Fragment>
              )}

              <Box my={2}>
                {chargeOverage ? (
                  <F
                    id="Unsubscribe.chargeOverage"
                    defaultMessage="Right after that you will be charged for all overage hours and this option will be unavailable until you’re subscribed. "
                  />
                ) : null}
                <F
                  id="Unsubscribe.keepingData"
                  defaultMessage="All materials will be archived for the next {preservationPeriod}. "
                  values={{
                    preservationPeriod: (
                      <Text fontWeight="semibold" lineHeight="text">
                        <F
                          id="Unsubscribe.sixMonths"
                          defaultMessage="6 months"
                        />
                      </Text>
                    ),
                  }}
                />

                <F
                  id="Unsubscribe.gladToSee"
                  defaultMessage="We will be glad to see you again any time."
                />

                <F
                  tagName="div"
                  id="Unsubscribe.thanks"
                  defaultMessage="Thanks for being with us!"
                />
              </Box>

              <Box my={3}>
                <CheckboxToggle
                  checked={this.state.deleteData}
                  onChange={() =>
                    this.setState({ deleteData: !this.state.deleteData })
                  }
                >
                  <F
                    id="Unsubscribe.removeImmediately"
                    defaultMessage="I would like my datasets, metrics, projects and environments be removed."
                  />
                </CheckboxToggle>
              </Box>

              <Modal show={showDialog} onHide={() => toggleOpen(false)}>
                <SimpleModal width={304}>
                  <F
                    tagName="div"
                    id="Unsubscribe.confirm"
                    defaultMessage="Are you sure you want to terminate the subscription?"
                  />

                  <Flex mt={3} justify="flex-end">
                    {isSubmitting ? (
                      <Spinner size="small" />
                    ) : (
                      <React.Fragment>
                        {error ? (
                          <Text color="error" mr={1} is="div">
                            {error.detail}
                          </Text>
                        ) : null}
                        <Box mr={1}>
                          <Button onClick={() => toggleOpen(false)}>
                            <F
                              id="Unsubscribe.cancel"
                              defaultMessage="Cancel"
                            />
                          </Button>
                        </Box>
                        <Submit onClick={this.onUnsubscribe}>
                          <F
                            id="Unsubscribe.unsubscribe"
                            defaultMessage="Unsubscribe"
                          />
                        </Submit>
                      </React.Fragment>
                    )}
                  </Flex>
                </SimpleModal>
              </Modal>

              <Flex justify="flex-end">
                <Button
                  disabled={!stripeSynchronized}
                  onClick={() => toggleOpen(true)}
                >
                  <F
                    id="Unsubscribe.unsubscribe"
                    defaultMessage="Unsubscribe"
                  />
                </Button>
              </Flex>
              <IntlDocumentTitle titleId="unsubscribe" />
            </Box>
          )}
        </Openable>
      </Box>
    );
  }
}

export default connect(
  (state: AppState) => ({
    type: getPlanType(state),
    nextUpcomingInvoiceAt: getnextUpcomingInvoiceAt(state),
    chargeOverage: hasOverage(state),
    stripeSynchronized: getStripeSynchronized(state),
  }),
  { onUnsubscribe: unsubscribe },
)(Unsubscribe);
