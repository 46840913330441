import React from 'react';
import { round } from '../../utils/helpers';
import { Anomaly, MomentScale } from '../../models';

// TODO: simpler? This is old layout copied from previous version
const ANGLE = 53 * Math.PI / 180;
const WIDTH = 50;
const HOVER_WIDTH = 6;

const x = 5;
const y = Math.sin(ANGLE) / Math.cos(ANGLE) * x;

interface Props {
  anomalies: Anomaly[];
  scale: MomentScale;
  height: number;
}

interface State {
  activeAnomaly?: Anomaly;
}

export default class Anomalies extends React.Component<Props, State> {
  state = { activeAnomaly: null };

  render() {
    const { anomalies, scale, height } = this.props;
    const { activeAnomaly } = this.state;

    return (
      <g>
        {anomalies.map((anomaly, i) => (
          <g key={anomaly.timestamp.format() + i}>
            <line
              className="timeline__marks-line"
              x1={scale(anomaly.timestamp)}
              y1="0"
              y2={height}
              x2={scale(anomaly.timestamp)}
            />
            <rect
              width={HOVER_WIDTH}
              fillOpacity="0"
              x={scale(anomaly.timestamp)}
              y="0"
              height={height}
              transform={`translate(${-HOVER_WIDTH / 2})`}
              onMouseMove={() => this.setState({ activeAnomaly: anomaly })}
              onMouseLeave={() => this.setState({ activeAnomaly: null })}
            />
          </g>
        ))}
        {activeAnomaly ? (
          <g
            transform={`translate(${scale(activeAnomaly.timestamp)}, -22)`}
            className="timeline__marks-bubble"
          >
            <rect
              width={WIDTH}
              height="17"
              y="0"
              rx="3"
              ry="3"
              transform={`translate(${-WIDTH / 2}, 0)`}
            />
            <path
              d={`M0 0 L${x} ${y} L${-x} ${y} 0 0Z`}
              transform="translate(0, 22) rotate(180)"
            />
            <text
              className="timeline__marks-text"
              dy="1.2em"
              textAnchor="middle"
            >
              {round(2, activeAnomaly.value)}
            </text>
          </g>
        ) : null}
      </g>
    );
  }
}
