import { Button, IconButton, RadioButtonGroup } from '@hm/ukie';
import { Box } from 'grid-styled';
import React from 'react';
import { Effect } from '../../models';

interface Props {
  effect: Effect;
  // FIXME: reselect prop
  id: string;
  onDelete(id: string);
  onUpdate(id: string, updates);
}

export default class Void extends React.Component<Props, never> {
  handleClick = name => {
    const { enabled, id } = this.props.effect;

    this.props.onUpdate(id, { enabled: !enabled });
  };

  onDelete = () => {
    this.props.onDelete(this.props.effect.id);
  };

  render() {
    const { enabled } = this.props.effect;
    const selected = enabled ? 'negative' : 'positive';

    return (
      <div className="metric-effect">
        <div className="metric-effect__title">
          <span className="metric-effect__text">Void</span>
        </div>
        <div className="metric-effect__actions">
          <RadioButtonGroup selected={selected} onClick={this.handleClick}>
            <Button icon="positive" value="positive" />
            <Button icon="negative" value="negative" />
          </RadioButtonGroup>
        </div>
        <Box ml={1}>
          <IconButton icon="bin" onClick={this.onDelete} />
        </Box>
      </div>
    );
  }
}
