import React from 'react';
import { scaleBand } from 'd3-scale';
import { range } from 'ramda';
import moment from 'moment';
import { DateRange } from 'moment-range';

import Slice from './Slice';
import WaveArea from '../WaveArea';
import { someContains, constructApproximationInterval } from '../../utils/time';

const defaultAvailableIntervals = constructApproximationInterval();

interface Props {
  date?: moment.Moment;
  selectedIntervals: DateRange[];
  availableIntervals?: DateRange[];
  filteredOutIntervals?: DateRange[];
  width?: number;
  height?: number;
}

const iterateOver = range(0, 24);

const SimpleWave: React.SFC<Props> = ({
  width,
  height,
  date,
  selectedIntervals,
  availableIntervals,
  filteredOutIntervals,
}) => {
  const scale = scaleBand()
    .domain(iterateOver.map(String))
    .range([0, width]);

  const hours = Array.from(
    new DateRange(date.clone().startOf('day'), date.clone().endOf('day')).by(
      'hour',
    ),
  );

  return (
    <svg width={width} height={height}>
      <g>
        {hours.map(hour => (
          <Slice
            key={+hour}
            x={scale(String(hour.hour()))}
            width={width / 24}
            height={height}
            isSelected={someContains(selectedIntervals, hour)}
            isDisabled={
              !someContains(availableIntervals, hour) ||
              someContains(filteredOutIntervals, hour)
            }
          />
        ))}
      </g>
      <WaveArea width={width} height={height} />
    </svg>
  );
};

SimpleWave.defaultProps = {
  date: defaultAvailableIntervals[0].start,
  availableIntervals: defaultAvailableIntervals,
  selectedIntervals: [],
  filteredOutIntervals: [],
  width: 36,
  height: 8,
};

export default SimpleWave;
