import React from 'react';
import DeckGL, { GeoJsonLayer } from 'deck.gl';
import { toRgbArray } from '@hm/ukie';
import Map, { MapProps } from '../Map';
import { ObjectFileLayer } from '../../reducers/gisImport';

interface ImportPreviewMapProps extends Pick<MapProps, 'initialBounds'> {
  layers: any[];
}

const ImportPreviewMap: React.SFC<ImportPreviewMapProps> = ({
  layers,
  ...props
}) => (
  <Map width={400 - 32} height={400 - 32} initialBoundsPadding={20} {...props}>
    {state => (
      <DeckGL
        {...state}
        layers={layers.filter(({ enabled }) => enabled === true).map(
          l =>
            new GeoJsonLayer({
              id: l.id,
              lineWidthScale: 20,
              lineWidthMinPixels: 2,
              getLineColor: toRgbArray(l.color),
              getFillColor: [...toRgbArray(l.color), 128],
              getRadius: 100,
              getLineWidth: 1,
              data: l.data.clampedGeometryPreview,
            }),
        )}
      />
    )}
  </Map>
);

ImportPreviewMap.defaultProps = {
  layers: [],
};

export default ImportPreviewMap;
