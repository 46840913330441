import { omit, uniq, evolve, merge, flip } from 'ramda';

import { types } from '../actions/layers';
import { fulfill, pend } from '../utils/promise';
import { types as projectTypes } from '../actions/projects';
import { types as effectTypes } from '../actions/effects';
import { HistogramData, MinMax } from '../models';

// TODO: convert to Enum
export type LayerStatus = 'loading' | 'loaded' | 'failed';

export interface Layer {
  id: string;
  label: string;
  description: string;
  realtime: boolean;
  // TODO: use Enum
  type: string;
  visible: boolean;
  subsetsMinified?: boolean;
  sliceDuration?: string;
  operands?: string[];
  availableTimeIntervals?: string[][];
  effects: string[];
  categories: string[];
  availableEffects: string[];
  anomalies: string[];
  gradientId: string;
  histogramMinified: boolean;
  histogramWindow: MinMax;
  initialHistogram: HistogramData[];
  currentHistogram: HistogramData[];
  absoluteValues?: MinMax;
  loadingStatus?: LayerStatus;
  loadingProgress?: number;
}

export interface LayersState {
  entities: { [index: string]: Layer };
  isFetching: boolean;
  didFetch: boolean;
}

export const initialState = {
  entities: {},
  isFetching: false,
  didFetch: false,
};

export default (
  state: LayersState = initialState,
  action: any,
): LayersState => {
  const { type, payload, meta } = action;

  switch (type) {
    case pend(types.GET_LAYER):
    case pend(types.CREATE_LAYER):
      return { ...state, isFetching: true };

    case fulfill(types.GET_LAYER):
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...// do we already have this layer?
          (state.entities[payload.result]
            ? // if so then them merge it with the one from payload
              {
                [payload.result]: {
                  ...state.entities[payload.result],
                  ...payload.entities.layers[payload.result],
                },
              }
            : // if not just merge everything together
              payload.entities.layers),
        },
      };

    case fulfill(types.COMPOSE_LAYERS):
    case fulfill(types.CREATE_LAYER):
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...payload.entities.layers,
        },
      };

    case fulfill(types.DELETE_LAYER):
      return { ...state, entities: omit([payload.id], state.entities) };

    case pend(types.UPDATE_LAYER):
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            ...payload.updates,
          },
        },
      };

    case types.SET_CURRENT_HISTOGRAM:
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            currentHistogram: payload.histogram,
          },
        },
      };

    case types.SET_INITIAL_HISTOGRAM:
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            initialHistogram: payload.histogram,
          },
        },
      };

    case fulfill(projectTypes.GET_PROJECT):
      if (!payload.entities.layers) return state;

      return {
        ...state,
        entities: payload.entities.layers,
        isFetching: false,
      };

    case fulfill(effectTypes.CREATE_EFFECT):
      return {
        ...state,
        entities: {
          ...state.entities,
          [meta.layerId]: {
            ...state.entities[meta.layerId],
            effects: uniq(
              state.entities[meta.layerId].effects.concat(payload.result),
            ),
          },
        },
      };

    case types.SET_LAYER_ABSOLUTE_VALUES:
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            absoluteValues: payload.absoluteValues,
          },
        },
      };

    case types.DRAG_HISTOGRAM:
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.layerId]: {
            ...state.entities[payload.layerId],
            histogramWindow: payload.histogramWindow,
          },
        },
      };

    case types.UPDATE_STATUS:
      return evolve(
        {
          entities: {
            [payload.layerId]: flip(merge)(payload),
          },
        },
        state,
      );

    default:
      return state;
  }
};
