import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Submit, Input, Text, ModalOverlay, Spinner } from '@hm/ukie';
import { LocalForm, Control, Errors } from 'react-redux-form';
import { Fieldset, FieldError, Field, FormActions, FormInfo } from '../Form';
import { Relative } from '../Relative';
import { isRequired, errorMessages } from '../../utils/validators';
import { ApiError } from '../../models';

interface Props {
  onSubmit(email: string): Promise<any>;
}

interface State {
  isSubmitting: boolean;
  didSubmit: boolean;
  email: string;
  error: ApiError;
}

export default class RequestPasswordResetForm extends React.Component<
  Props,
  State
> {
  state = { isSubmitting: false, didSubmit: false, email: '', error: null };

  email: HTMLInputElement;

  onSubmit = ({ email }, e) =>
    this.setState({ isSubmitting: true, email }, () =>
      this.props
        .onSubmit(email)
        .then(() => this.setState({ isSubmitting: false, didSubmit: true }))
        .catch(({ error }) => this.setState({ error, isSubmitting: false })),
    );

  render() {
    const { didSubmit, error } = this.state;

    return (
      <LocalForm
        onSubmit={this.onSubmit}
        initialState={{ email: '' }}
        {
          ...{ hideNativeErrors: true } as any /*😝*/
        }
      >
        <Relative my={3}>
          {didSubmit ? (
            <ModalOverlay>
              <F
                id="ResetPassword.sent"
                defaultMessage="Reset password instructions were successfully sent to {email}"
                values={{
                  email: <Text.Important>{this.state.email}</Text.Important>,
                }}
              />
            </ModalOverlay>
          ) : null}
          <Fieldset>
            <FormInfo>
              <F
                id="ResetPassword.info"
                defaultMessage="Reset password instructions were successfully sent to {email}."
                tagName="div"
              />
            </FormInfo>
            <Field
              label={<F id="email" defaultMessage="Email" tagName="div" />}
            >
              <Control.text
                type="email"
                component={Input}
                model=".email"
                onInvalid={e => e.preventDefault()}
                validators={{ isRequired }}
                getRef={el => (this.email = el)}
              />
              <Errors
                show={field => field.focus && field['submitFailed']}
                model=".email"
                messages={errorMessages.email}
                wrapper={props => <FieldError target={this.email} {...props} />}
              />
            </Field>
          </Fieldset>
        </Relative>
        {/* FIXME: fix styling*/}
        <FormActions style={{ visibility: didSubmit ? 'hidden' : 'visible' }}>
          {this.state.isSubmitting ? (
            <Spinner size="small" />
          ) : (
            <React.Fragment>
              {error ? (
                <Text color="error" is="div" mr={2}>
                  {error.detail}
                </Text>
              ) : null}
              <Submit>
                <F id="ResetPassword.submit" defaultMessage="Reset" />
              </Submit>
            </React.Fragment>
          )}
        </FormActions>
      </LocalForm>
    );
  }
}
