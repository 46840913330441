import { connect } from 'react-redux';

import { saveObject, deleteObject } from '../../actions/objects';
import { getFirstObject } from '../../selectors/selectedObjects';
import { AppState } from '../../models';

import FinalActions from './FinalActions';

const mapStateToProps = (state: AppState) => ({
  object: getFirstObject(state),
});

const mapDispatchToProps = {
  saveObject,
  deleteObject,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalActions);
