import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Control } from 'react-redux-form';
import { Select, Text } from '@hm/ukie';

import { Environment } from '../../reducers/environments';
import { blurOnEnter } from '../../utils/eventHelper';

import { Field } from '../Form';
import BoundingBoxCoordinates from './BoundingBoxCoordinates';

interface Props {
  environments: Dict<Environment>;
  selectedId: string;
  changeActionFor(field: string): any;
}

const SelectEnvironment: React.SFC<Props> = ({
  environments,
  selectedId,
  changeActionFor,
}) => {
  const selectedEnvironment = environments[selectedId];

  return (
    <React.Fragment>
      <Field label="Environment">
        <Control.select
          model=".environmentId"
          component={Select}
          style={{ display: 'flex' }}
          changeAction={changeActionFor('environmentId')}
          onKeyPress={blurOnEnter}
          controlProps={{ placeholder: 'Select an environment' }}
        >
          {Object.values(environments).map(environment => (
            <option
              key={environment.id}
              value={environment.id}
              label={environment.name}
            />
          ))}
        </Control.select>
      </Field>

      {selectedEnvironment ? (
        <React.Fragment>
          <Field label="Bounding box">
            <BoundingBoxCoordinates
              topLat={selectedEnvironment.geoBounds.cornerTopLeft.lat}
              rightLon={selectedEnvironment.geoBounds.cornerBottomRight.lon}
              bottomLat={selectedEnvironment.geoBounds.cornerBottomRight.lat}
              leftLon={selectedEnvironment.geoBounds.cornerTopLeft.lon}
            />
          </Field>

          <Field
            label={
              <F id="MetricConfig.cellSize" defaultMessage="Cell size (m)" />
            }
          >
            <Text.Important is="div">
              {selectedEnvironment.cellSize}
            </Text.Important>
          </Field>

          <Field
            label={
              <F
                id="MetricConfig.resolution"
                defaultMessage="Resolution (cells)"
              />
            }
          >
            <Text.Important is="div">
              {`${selectedEnvironment.resolution.x} 𝗑 ${
                selectedEnvironment.resolution.y
              }`}
            </Text.Important>
          </Field>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default SelectEnvironment;
