import { defineMessages } from 'react-intl';

export default defineMessages({
  addLayer: {
    id: 'NewMetricModal.addMetric',
    defaultMessage: 'Add Metric',
  },
  addAsApproximated: {
    id: 'NewMetricModal.addAsApproximated',
    defaultMessage: 'Add as approximated',
  },
  addAsRegular: {
    id: 'NewMetricModal.addAsRegular',
    defaultMessage: 'Add as regular',
  },
});
