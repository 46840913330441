import React from 'react';
import styled from 'styled-components';
import { flip, gt, always, cond, where, whereEq, anyPass } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { Input, Pictogram, Text, StateSelect, StateSelectItem } from '@hm/ukie';
import { Box } from 'grid-styled';
import { connect } from 'react-redux';

import FeatureToggle from '../FeatureToggle';
import { updateParams } from '../../actions/inspector';
import { MapObject, ObjectState } from '../../reducers/selectedObjects';
import { getType, getParams, getIsMinimized } from '../../selectors/inspector';
import { getSelectedObjects } from '../../selectors/selectedObjects';
import { AppState } from '../../models';

import {
  ObjectType,
  MarkerSubtype,
  newObjectLabel,
} from '../../constants/objects';
import messages from './messages';

const getDetails = cond([
  [
    where({ objectCount: flip(gt)(1) }),
    always(['multipleSelection', messages.multipleSelectionCount]),
  ],
  [
    anyPass([
      whereEq({ type: ObjectType.MARKER, objectCount: 0 }),
      whereEq({ type: ObjectType.MARKER, draft: true }),
    ]),
    always(['flag', messages.draftMarker]),
  ],
  [
    whereEq({ type: ObjectType.MARKER, subtype: MarkerSubtype.SPATIAL }),
    always(['spatial', messages.spatialMarker]),
  ],
  [
    whereEq({ type: ObjectType.MARKER, subtype: MarkerSubtype.TEMPORAL }),
    always(['temporal', messages.temporalMarker]),
  ],
  [
    whereEq({
      type: ObjectType.MARKER,
      subtype: MarkerSubtype.SPATIO_TEMPORAL,
    }),
    always(['spatioTemporal', messages.spatioTemporalMarker]),
  ],
  [
    whereEq({ type: ObjectType.POLYGON }),
    always(['polygon', messages.polygon]),
  ],
  [
    whereEq({ type: ObjectType.POLYLINE }),
    always(['polyline', messages.polyline]),
  ],
  [whereEq({ type: ObjectType.RADIUS }), always(['radius', messages.radius])],
  [whereEq({ type: ObjectType.ROAD }), always(['road', messages.road])],
]);

const getInputValue = (objectCount: number, params: Partial<MapObject>) =>
  objectCount > 1 ? 'Multiple selection' : params.label ? params.label : '';

const Body = styled.div`
  display: flex;
  width: 224px;
  line-height: 24px;

  & > *:not(:first-child) {
    margin-left: 6px;
  }
`;

const Title = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
`;

interface OwnProps {
  type: string;
  params: Partial<MapObject>;
  objectCount: number;
  isMinimized: boolean;
}

interface ProvidedProps {
  onUpdate(updates: Partial<MapObject>): void;
}

type Props = OwnProps & ProvidedProps;

interface InspectorStateSelectItem extends StateSelectItem {
  value: ObjectState;
}

const states: InspectorStateSelectItem[] = [
  { value: ObjectState.Enabled, icon: 'enable', label: 'Cut & Visible' },
  { value: ObjectState.Visible, icon: 'disable', label: 'Visible' },
  { value: ObjectState.Hidden, icon: 'disableHide', label: 'Hidden' },
  { value: ObjectState.Masked, icon: 'masked', label: 'Masked' },
];

const fallback: StateSelectItem = {
  value: 'unknown',
  icon: 'unknown',
  label: 'Unknown',
};

/**
 * TODO: stop disabling input when concierge supports providing lables
 * to objects on creation T1466
 */
export const Header: React.SFC<Props> = props => {
  const { objectCount, type, isMinimized, onUpdate, params } = props;
  const [icon, subscript] = getDetails({ ...params, objectCount, type });
  const inputValue = getInputValue(objectCount, params);

  return (
    <Body>
      <FeatureToggle feature="maskObjectState">
        {isEnabled => (
          <StateSelect
            size="medium"
            selectedItem={
              states.find(({ value }) => value === params.state) || fallback
            }
            onChange={({ value }) => onUpdate({ state: value })}
            items={states.filter(
              ({ value }) => (isEnabled ? true : value !== ObjectState.Masked),
            )}
          />
        )}
      </FeatureToggle>

      <Pictogram name={icon} size="medium" />
      <Title>
        {objectCount <= 1 ? (
          <Box>
            <Input
              titleFont
              placeholder={newObjectLabel[type]}
              value={inputValue}
              onChange={e => onUpdate({ label: e.currentTarget.value })}
              disabled={objectCount !== 1}
            />
          </Box>
        ) : (
          <Text.ThinTitle>{inputValue}</Text.ThinTitle>
        )}
        {!isMinimized ? (
          <FormattedMessage
            tagName="div"
            {...subscript}
            values={{ objectCount }}
          />
        ) : null}
      </Title>
    </Body>
  );
};

const mapStateToProps = (state: AppState) => ({
  objectCount: getSelectedObjects(state).length,
  type: getType(state),
  params: getParams(state),
  isMinimized: getIsMinimized(state),
});

const mapDispatchToProps = {
  onUpdate: updateParams,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
