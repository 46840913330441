import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Text } from '@hm/ukie';

import messages from './messages';
import { ApproximationType } from '../../reducers/currentProject';

const SemiStrong = styled.span`
  font-weight: 600;
`;

const weekdays = moment.weekdays();
const months = moment.months();

interface Props {
  approximationType: ApproximationType;
  startTime?: string;
  startAtWeekday?: number;
  startAtMonthDay?: number;
  startAtMonth?: number;
}

const ShiftStatus: React.SFC<Props> = ({
  approximationType,
  startTime,
  startAtWeekday,
  startAtMonthDay,
  startAtMonth,
}) => (
  <span>
    <FormattedMessage {...messages.startTime} />
    &nbsp;
    {startTime ? (
      <SemiStrong>
        {startTime}
        {approximationType === 'week' ? (
          <span>
            &nbsp;
            {weekdays[startAtWeekday] || weekdays[0]}
          </span>
        ) : approximationType === 'month' ? (
          <span>
            {` ${startAtMonthDay} `}
            <FormattedMessage {...messages.startAtMonthDay} />
          </span>
        ) : approximationType === 'year' ? (
          <span>
            {` 01 `}
            {months[startAtMonth - 1] || months[0]}
          </span>
        ) : null}
      </SemiStrong>
    ) : (
      <FormattedMessage {...messages.startTimeEmpty}>
        {t => (
          <Text color="grey7" is="p">
            {t}
          </Text>
        )}
      </FormattedMessage>
    )}
  </span>
);

export default ShiftStatus;
