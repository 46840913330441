import { delay } from 'redux-saga';
import { path, pathEq } from 'ramda';

import {
  take,
  race,
  all,
  select,
  put,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';

import { LOCATION_CHANGE } from 'react-router-redux';

import {
  types as projectTypes,
  getProject,
  openProject,
  viewProject,
  goToProjectView,
} from '../actions/projects';

import {
  connect as connectToSocket,
  disconnect as disconnectFromSocket,
} from '../actions/volumer';

import { loadGradients } from '../actions/gradients';
import { getObject } from '../actions/objects';
import { showAlert, hideAlert } from '../actions/alerts';
import { types as userTypes } from '../actions/user';
import { types as globalTypes } from '../actions/global';

import { getSelectedObjectIds } from '../selectors/project';
import { getGradientsList } from '../selectors/gradients';
import { getAlertText } from '../selectors/alerts';
import { getPreferedNodeUrl } from '../selectors/volumer';
import { getIsLoggedIn } from '../selectors/user';

import { pend } from '../utils/promise';
import { parseQs } from '../utils/helpers';
import { fetchHighlightTypes } from '../reducers/highlightTypes';
import { selectEnvironment } from '../actions/environments';

// TODO: make connected alert that responds to error codes
const ERRORS = {
  preparingAlert: 'Connected. Preparing server…',
  loadFailed:
    'Ooops... Could not connect to the server. Please try again later.',
  away: 'You were away for some time. Please refresh the page to continue',
  busy:
    'Ooops... Unfortunately all volumer nodes are busy. Please try again later.',
};

export default function*() {
  yield takeLatest(LOCATION_CHANGE, function*(action: any) {
    try {
      const {
        payload: { pathname },
      } = action;

      if (!pathname.match(/^\/projects\//) || !(yield select(getIsLoggedIn)))
        return null;

      const [originalId, mode] = pathname.split('/').slice(2, 4);
      const originalProject = yield* loadProject(originalId);

      if (originalProject && originalProject.readOnly && mode !== 'view') {
        yield put(goToProjectView(originalId));
        return null;
      }

      const project =
        mode === 'view' ? yield* getProjectView(originalId) : originalProject;

      if (!project) return null;

      const success = yield* openProjectLoop(project.id);
      if (!success) return null;

      yield put(connectToSocket());

      // wait for project to be in ready state
      yield take(
        ({ type, payload }) =>
          type === projectTypes.PROJECT_STATUS &&
          pathEq(['project', 'state'], 'ready', payload),
      );

      // restore selected objects
      const selectedObjectIds = yield select(getSelectedObjectIds);
      for (const id of selectedObjectIds) yield put(getObject(id));
      yield put(selectEnvironment(project.environmentId));

      const { disconnectInactive } = yield race({
        disconnectInactive: take(userTypes.DISCONNECT_INACTIVE),
        otherReasons: take([
          userTypes.LOG_OUT,
          globalTypes.WINDOW_UNLOAD,
          pend(projectTypes.DELETE_CURRENT_PROJECT),
        ]),
      });

      if (disconnectInactive) {
        yield put(showAlert(ERRORS.away));
      }

      yield put(disconnectFromSocket());
    } finally {
      if (yield cancelled()) yield put(disconnectFromSocket());
    }
  });
}

/**
 * Loads project and whatever other resources we need
 * Note: put.resolve bubbles up errors so we can catch them
 */
function* loadProject(nextProjectId: string) {
  const gradientsList = yield select(getGradientsList);
  // FIXME: move to component to be tied to lifecycle

  try {
    const [project] = yield all([
      put.resolve(getProject(nextProjectId)),
      put(fetchHighlightTypes()),
      gradientsList.length === 0 ? put(loadGradients()) : null,
    ]);

    return project ? project.value : null;
  } catch (e) {
    yield put(showAlert(ERRORS.loadFailed));
    throw e;
  }
}

/**
 * Tries to open project and get Volumer socket URL
 * If Concierge asks to wait, it waits and attempts to reconnect in a couple of
 * seconds
 * If Concierge rejects, it terminates reconnect returns false
 */
function* openProjectLoop(nextProjectId) {
  while (true) {
    try {
      const query = parseQs();

      yield put.resolve(
        openProject(nextProjectId, {
          volumerNodeUrl: yield select(getPreferedNodeUrl),
          ...query,
        }),
      );

      yield* reconnectCleanup();
      return true;
    } catch (e) {
      const shouldReconnect = e.code === 2;
      if (shouldReconnect) {
        yield* reconnectDelay();
        continue;
      } else {
        yield put(showAlert(ERRORS.busy));
        return false;
      }
    }
  }
}

function* reconnectDelay() {
  yield put(showAlert(ERRORS.preparingAlert));
  yield delay(3000);
}

function* reconnectCleanup() {
  const currentAlert = yield select(getAlertText);
  if (currentAlert === ERRORS.preparingAlert) {
    yield put(hideAlert());
  }
}

function* getProjectView(id: string) {
  const view = yield put.resolve(viewProject(id));
  const viewId = path(['value', 'id'], view) as string;
  // FIXME: HACK, we should be able to use project from view fulfilled
  // but too many parts of the system watch GET_PROJECT and it will be
  // cumbersome
  return yield* loadProject(viewId);
}
