import React from 'react';
import { compose } from 'ramda';
import { Box } from 'grid-styled';
import {
  FormattedMessage as F,
  injectIntl,
  defineMessages,
  InjectedIntlProps,
} from 'react-intl';
import { connect } from 'react-redux';
import { RadioButtonGroup, Button } from '@hm/ukie';
import Track from './Track';
import SettingsRow from './SettingsRow';
import { updateCurrentProject } from '../actions/projects';
import { getIsRaw } from '../selectors/project';
import { AppState } from '../models';

const messages = defineMessages({
  no: {
    id: 'InterpolationType.no',
    defaultMessage: 'No interpolation',
  },
  trend: {
    id: 'InterpolationType.trend',
    defaultMessage: 'Trend interpolation',
  },
});

interface Props extends InjectedIntlProps {
  isRaw: boolean;
  onUpdate(updates: any): void;
}

export const InterpolationType: React.SFC<Props> = ({
  isRaw,
  onUpdate,
  intl,
  ...rest
}) => (
  <SettingsRow pin="interpolation">
    <Box flex="0 0 auto" mr={2}>
      <F id="Volumer.interpolationType" defaultMessage="Interpolation type" />
    </Box>
    <Box flex={1}>
      <Track
        data-metrics-event-name="interpolationToggle"
        data-metrics-category="Project"
      >
        <RadioButtonGroup
          selected={isRaw ? 'raw' : 'smooth'}
          onClick={mode => onUpdate({ legoModeEnabled: mode === 'raw' })}
        >
          <Button
            value="raw"
            icon="rawInterpolation"
            title={intl.formatMessage(messages.no)}
            date-metrics-interpolation-mode="raw"
          />
          <Button
            value="smooth"
            icon="smoothInterpolation"
            title={intl.formatMessage(messages.trend)}
            date-metrics-interpolation-mode="smooth"
          />
        </RadioButtonGroup>
      </Track>
    </Box>
  </SettingsRow>
);

export default compose(
  injectIntl,
  connect(
    (state: AppState) => ({
      isRaw: getIsRaw(state),
    }),
    {
      onUpdate: updateCurrentProject,
    },
  ),
)(InterpolationType);
