// https://phab.mathrioshka.ru/w/chronotope/concierge/billing/billing_data/

export enum SubscriptionStatus {
  Active = 'active',
  PastDue = 'past_due',
  Cancelled = 'canceled',
}

export enum Plan {
  TrialWithCard = 'trialWithCard',
  TrialWithoutCard = 'trialWithoutCard',
  Basic = 'basic',
  Other = 'other',
}

export interface BillingState {
  // flow hints
  canChangeMaxUsers: boolean;
  canChangeStorageLevel: boolean;
  canSubscribe: boolean;
  canToggleOverage: boolean;
  canUnsubscribe: boolean;

  // if this subscription will be canceled at period end
  cancelAtPeriodEnd: boolean;

  // payment source
  cardBrand: string;
  cardExpMonth: number;
  cardExpYear: number;
  cardLastErrorCode: string;
  cardLastErrorMessage: string;
  cardLastFour: number;
  cardValid: boolean;

  // plan cost
  effectiveMonthlyCostCent: number;
  effectiveOverageHourCostCent: number;

  oneTimeSetupFeeCent: number;
  oneTimeSetupFeeDescription: string;
  showOneTimeSetupFee: boolean;
  showOverageCost: boolean;

  // effective limits
  maxHours: number;
  maxSessions: number;

  // in gigabytes, needs rework to bytes
  maxStorage: number;
  maxUsers: number;

  // end of current billing period
  nextUpcomingInvoiceAt: string;
  overageActive: boolean;
  planDescription: string;
  planVersion: number;

  // plan page attribute show helpers
  showMaxUsers: boolean;
  showRenderHoursLimit: boolean;
  showRenderHoursUsage: boolean;
  showStorageLimit: boolean;
  showStorageUsage: boolean;
  // extended storage level, 1 = 100 Gb, 2 = 500 Gb, 3 = 1 Tb, 0 - no extended storage
  storageLevel: number;

  // if boolean, there is some processing being in action(or smth terribly failed), no morph actions allowed
  stripeSynchronized: boolean;
  subscriptionActive: boolean; // deprecated, will be removed further
  subscriptionLastErrorCode: string;
  subscriptionLastErrorMessage: string;

  // enum, `active`|`past_due`|`canceled`. there are more, but no applied in current flow
  subscriptionStatus: SubscriptionStatus;
  // plan type. may be `trialWithCard`, `trialWithoutCard`, `basic`, and some other further for custom users
  type: Plan;
  // if we need upgrade to newer version of plan
  upgradeRequired: boolean;
  // in hours
  usedHours: number;
  // in Gb, should be moved to bytes
  usedSpace: number;
  currency: string;
}
