import { propOr } from 'ramda';
import { schema } from 'normalizr';
import { schema as baseLayer } from '../reducers/baseLayers';
import moment from 'moment';
import 'moment-timezone';
import { DateRange } from 'moment-range';

const getAvailableTimeIntervals = (entity: any) =>
  propOr<string[][], any, string[][]>([], 'availableTimeIntervals', entity);

const getAvailableSliceDurations = (entity: any) =>
  propOr<string[], any, string[]>([], 'availableSliceDurations', entity);

const environmentSchema = new schema.Entity(
  'environments',
  { layers: [baseLayer] },
  {
    processStrategy: environment => ({
      ...environment,
      availableTimeIntervals: getAvailableTimeIntervals(environment).map(
        ([start, end]) =>
          new DateRange(
            moment.tz(start, environment.timezone),
            moment.tz(end, environment.timezone),
          ),
      ),
      availableSliceDurations: getAvailableSliceDurations(environment).map(d =>
        moment.duration(d),
      ),
      createdAt: environment.createdAt
        ? moment.tz(environment.createdAt, environment.timezone)
        : null,
    }),
  },
);

export default environmentSchema;

export const environmentsSchema = [environmentSchema];
