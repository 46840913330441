import {
  CounterSmart as Counter,
  IconButton,
  Pictogram,
  Slider,
  Text,
  colors,
} from '@hm/ukie';
import { volumer } from '@hm/volumer-api';
import { Box, Flex } from 'grid-styled';
import { transparentize } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  deleteEffect,
  trendBlurDrag,
  trendBlurStart,
  trendBlurStop,
  updateEffect,
} from '../../actions/effects';
import { AppState, Effect } from '../../models';
import { getEffectById } from '../../selectors/effects';

const StyledCounter = styled(Counter)`
  width: 52px;
`;

interface OwnProps {
  // used by reselect to find needed layer
  id: string;
  layerId: string;
}

interface ProvidedProps {
  effect: Effect;
  onUpdate(id: string, updates: any);
  onStart(message: volumer.event.BlurDragStart$Properties): void;
  onDrag(effectId: string, message: volumer.event.BlurDrag$Properties): void;
  onStop(message: volumer.event.BlurDragStop$Properties): void;
  onDelete(id: string): void;
}

type Props = OwnProps & ProvidedProps;

export class TrendBlur extends React.Component<Props, never> {
  handleToggleVisibility = () => {
    const { effect, onUpdate } = this.props;

    onUpdate(effect.id, { enabled: !effect.enabled });
  };

  updateSpace = (value: number) => {
    this.props.onDrag(this.props.effect.id, {
      layerId: this.props.layerId,
      spaceRadius: value,
      timeRadius: this.props.effect.settings.time,
    });
  };

  updateTime = (value: number) => {
    this.props.onDrag(this.props.effect.id, {
      layerId: this.props.layerId,
      spaceRadius: this.props.effect.settings.space,
      timeRadius: value,
    });
  };

  onDelete = () => {
    this.props.onDelete(this.props.effect.id);
  };

  render() {
    const {
      effect: {
        settings: {
          space,
          spaceRange: [sMin, sMax],
          time,
          timeRange: [tMin, tMax],
        },
        enabled,
      },
    } = this.props;

    return (
      <div className="metric-effect">
        <Flex flex="0 0 100px" mr={1} alignItems="center">
          <Box mr={1}>
            <IconButton
              icon={
                enabled ? (
                  <Pictogram name="eyeOpened" />
                ) : (
                  <Pictogram
                    name="eyeClosed"
                    color={transparentize(0.5, colors.grey1)}
                  />
                )
              }
              onClick={this.handleToggleVisibility}
            />
          </Box>
          {/* to better align with slider labels */}
          <Text lineHeight="inherit">Trend Blur</Text>
        </Flex>

        <div className="metric-effect__actions">
          <div className="slider-label">Space</div>
          <Box width={70}>
            <Slider
              min={sMin}
              max={sMax}
              values={[space]}
              onSliderDragStart={() =>
                this.props.onStart({ layerId: this.props.layerId })
              }
              onValuesUpdated={({ values }) => this.updateSpace(values[0])}
              onSliderDragEnd={() =>
                this.props.onStop({ layerId: this.props.layerId })
              }
            />
          </Box>
          <StyledCounter
            gte={sMin}
            lte={sMax}
            value={space}
            onUpdate={this.updateSpace}
            integersOnly
          />
          <div className="slider-label">Time</div>
          <Box width={70}>
            <Slider
              min={tMin}
              max={tMax}
              values={[time]}
              onSliderDragStart={() =>
                this.props.onStart({ layerId: this.props.layerId })
              }
              onValuesUpdated={({ values }) => this.updateTime(values[0])}
              onSliderDragEnd={() =>
                this.props.onStop({ layerId: this.props.layerId })
              }
            />
          </Box>
          <StyledCounter
            gte={tMin}
            lte={tMax}
            value={time}
            onUpdate={this.updateTime}
            integersOnly
          />
        </div>
        <Box ml={1}>
          <IconButton icon="bin" onClick={this.onDelete} />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  effect: getEffectById(state, ownProps),
});

const mapDispatchToProps = {
  onUpdate: updateEffect,
  onStart: trendBlurStart,
  onDrag: trendBlurDrag,
  onStop: trendBlurStop,
  onDelete: deleteEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendBlur);
