import { bisect } from 'd3-array';
import { parse } from 'query-string';
import moment from 'moment';
import { DateRange } from 'moment-range';
import { SpringHelperConfig } from 'react-motion';
import assign from 'lodash.assign';
import { Plan } from '../reducers/billing';

import {
  replace,
  flip,
  contains,
  complement,
  isNil,
  isEmpty,
  any,
  reduce,
  propEq,
  compose,
  map,
  filter,
  zipWith,
  toUpper,
  head,
  last,
  tail,
  max,
  concat,
  curry,
  Pred,
  equals,
} from 'ramda';

import { toDayRangeFilterInterval } from './time';
import { MomentScale, HistogramData, Role } from '../models';

/**
 * Wraps ordinal scale for easier interaction and keeps properties of original scale.
 */
// TODO: rewrite timeline to use basic scales not this wonder-who-knows-what
// Smekalochka level is of the limits.
export const wrapWithMoment = (scale: any) => {
  /** Wrapper for d3 scale */
  const wrappedScale = (value: moment.Moment) =>
    scale(value.toISOString()) || 0;

  // ordinal scales don’t have .invert() atm but this could change in future
  const invert = (coordinate: number) => {
    const domain = scale.domain();
    const range = scale.range();

    if (coordinate > last(range)) return moment.utc(last(domain));
    if (coordinate < head(range)) return moment.utc(head(domain));

    return moment.utc(domain[bisect(scale.range(), coordinate) - 1]);
  };

  return assign<MomentScale>(wrappedScale, scale, { invert });
};

export const transformHistogram = (data): HistogramData[] => {
  return zipWith<number, number, HistogramData>(
    (x, y) => ({ x, y }),
    data.x,
    data.y,
  );
};

export const isEven = (i: number) => i % 2 === 0;

export const capitalize = (str: string) => `${toUpper(str[0])}${str.slice(1)}`;

export const fastSpring: SpringHelperConfig = {
  stiffness: 240,
  damping: 26,
};

export const includesId = (id: string) => any(propEq('id', id));

export const upperFirst = (str: string) => concat(toUpper(str[0]), tail(str));

/** Rounds number with precision like `_.round` */
export const round = curry(
  /* tslint:disable-next-line */
  (n: number = 0, value: number) => Math.round(value * 10 ** n) / 10 ** n,
);

export const isRetina = () => window.devicePixelRatio > 1;

export const parseQs = () => parse(replace('?', '', window.location.search));

export const oneOf = flip(contains);

type CondTuple<State> = [Pred, (state: State) => any];

export const condAll = <State>(list: CondTuple<State>[]) => (state: State) =>
  compose<CondTuple<State>[], CondTuple<State>[], any[]>(
    map(([condition, output]: CondTuple<State>) => output(state)),
    filter(([condition, output]) => condition(state)),
  )(list);

export const isPresent = complement(isNil);

export const isNonEmpty = complement(isEmpty);

export const isApproximated = ({ type }: { type?: string }) =>
  type === 'approximated';

export const toAPIFilters = (filters: Dict<any[]>) =>
  Object.keys(filters).map(type => ({
    type,
    settings:
      type === 'timeIntervals'
        ? filters[type].map(toDayRangeFilterInterval)
        : filters[type],
  }));

export const fromAPIFilters = <T extends { type: string; settings: any[] }>(
  filters: T[],
) =>
  filters.reduce(
    (settings, f) => ({
      ...settings,
      [f.type]:
        f.type === 'timeIntervals'
          ? f.settings.map(i => {
              const [start, end] = i.split('/');

              return new DateRange(
                moment.utc(`1970-01-02T${start}:00Z`),
                moment.utc(
                  `1970-01-0${end === '00:00' ? '3' : '2'}T${end}:00Z`,
                ),
              );
            })
          : f.settings,
    }),
    {},
  );

export const isEditor = equals(Role.Editor);
export const isOwner = equals(Role.Owner);
export const maxValue = reduce<number, number>(max, -Infinity);

export const planIsTrial = plan =>
  plan === Plan.TrialWithCard || plan === Plan.TrialWithoutCard;

// https://stanko.github.io/react-rerender-in-component-did-mount/
export const rafStartAnimation = (cb: () => void) =>
  requestAnimationFrame(() => requestAnimationFrame(cb));

export const noop = () => undefined;

export const bytesToGigabytes = (bytes: number) => bytes / Math.pow(1024, 3);

export const downloadByURL = (url: string, fileName: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
