import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import UserSelect from '../UserSelect';
import { FormattedMessage } from 'react-intl';
import { countByDuration } from '../../utils/time';

interface Props {
  intervals: DateRange[];
  sliceDuration: moment.Duration;
  is?: string;
}

const SliceCount: React.SFC<Props> = ({ intervals, sliceDuration, is }) => {
  const Body = is;
  const count = Math.ceil(countByDuration(intervals, sliceDuration));

  return (
    <Body>
      <UserSelect>
        <FormattedMessage
          id="SliceCount.slices"
          defaultMessage="{slices, plural, one {# slice} other {# slices}}"
          values={{ slices: count }}
        />{' '}
        x {sliceDuration.humanize()}
      </UserSelect>
    </Body>
  );
};

SliceCount.defaultProps = {
  is: 'span',
};

export default SliceCount;
