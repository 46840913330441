import { select } from 'redux-saga/effects';
import { getViewportResolution } from '../../selectors/volumer';
import { Point, Resolution } from '../../models';

const normalize = (point: Point, viewport: Resolution) => ({
  x: point.x / viewport.width,
  y: point.y / viewport.height,
});

export default function*(point: Point) {
  const viewport = yield select(getViewportResolution);

  if (!viewport) {
    throw Error(`
      Failed to normalize pointer coordinates: Viewport (${viewport}) is invalid.
    `);
  }

  return normalize(point, viewport);
}
