import { IconButton, Pictogram, Select, Text, colors } from '@hm/ukie';
import { Box, Flex } from 'grid-styled';
import { transparentize } from 'polished';
import { isEmpty } from 'ramda';
import React from 'react';
import { Effect } from '../../models';
import { Layer } from '../../reducers/layers';

interface Props {
  effect: Effect;
  availableMetrics: Layer[];
  // FIXME: reselect props
  id: string;
  layerId: string;
  onUpdate(id: string, updates);
  onDelete(id: string);
}

export default class Patternize extends React.Component<Props, never> {
  handleChange = byId => {
    const { effect, onUpdate } = this.props;

    onUpdate(effect.id, {
      settings: { byId },
    });
  };

  handleToggleVisibility = () => {
    const { effect, onUpdate } = this.props;

    onUpdate(effect.id, { enabled: !effect.enabled });
  };

  remove = () => {
    this.props.onDelete(this.props.effect.id);
  };

  renderSelect(): string | JSX.Element {
    const { availableMetrics, effect } = this.props;

    const selectedLayer = availableMetrics.find(
      l => l.id === effect.settings.byId,
    );

    if (isEmpty(availableMetrics)) return null;
    const selected = selectedLayer ? effect.settings.byId : null;

    return (
      <div className="metric-effect__actions">
        {availableMetrics.length === 1 && selectedLayer ? (
          selectedLayer.label
        ) : (
          <Select onChange={this.handleChange} value={selected}>
            {availableMetrics.map(i => (
              <option value={i.id} label={i.label} key={i.id} />
            ))}
          </Select>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="metric-effect">
        <Flex flex="0 0 90px">
          <Box mr={1}>
            <IconButton
              icon={
                this.props.effect.enabled ? (
                  <Pictogram name="eyeOpened" />
                ) : (
                  <Pictogram
                    name="eyeClosed"
                    color={transparentize(0.5, colors.grey1)}
                  />
                )
              }
              onClick={this.handleToggleVisibility}
            />
          </Box>
          <Text mr={1} is="div">
            Patternize
          </Text>
        </Flex>
        {this.renderSelect()}
        <Box ml={1}>
          <IconButton icon="bin" onClick={this.remove} />
        </Box>
      </div>
    );
  }
}
