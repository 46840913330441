import React from 'react';
import { Moment } from 'moment';
import { DateRange } from 'moment-range';
import styled, { StyledComponentClass } from 'styled-components';
import { Flex } from 'grid-styled';
import { typography, Theme } from '@hm/ukie';
import { monthByWeek, someContains } from '../../utils/time';
import Day from './Day';
import { navigatorTitleColor, NavigatorTitleProps } from '../../styles/common';
import shouldCalendarUpdate from '../shouldCalendarUpdate';

const Container = styled.div`
  max-width: 34px;
  flex: 1;
  cursor: pointer;
`;

const MonthTitle = styled.div`
  font-size: ${typography.smallFont};
  color: ${({ theme }) => theme.colors.defaultColor};
  margin-bottom: 8px;
  ${navigatorTitleColor};
` as StyledComponentClass<NavigatorTitleProps, Theme>;

const Week = Flex.extend`
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1px;
  }
`;

const EmptyDay = styled.div`
  width: 4px;
  height: 3px;
`;

interface Props {
  date: Moment;
  availableIntervals: DateRange[];
  selectedIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
  onClick(date: Moment): void;
}

const Month: React.SFC<Props> = ({
  date,
  onClick,
  selectedIntervals,
  availableIntervals,
  filteredOutIntervals,
}) => (
  <Container onClick={() => onClick(date)}>
    <MonthTitle
      date={date}
      availableIntervals={availableIntervals}
      filteredOutIntervals={filteredOutIntervals}
    >
      {date.format('MMM')}
    </MonthTitle>
    <div>
      {monthByWeek(date).map((week, i) => (
        <Week key={i}>
          {week.map(
            day =>
              day.isSame(date, 'month') ? (
                <Day
                  key={day.valueOf()}
                  isSelected={someContains(selectedIntervals, day)}
                  isDisabled={
                    !someContains(availableIntervals, day) ||
                    someContains(filteredOutIntervals, day)
                  }
                />
              ) : (
                <EmptyDay key={day.valueOf()} />
              ),
          )}
        </Week>
      ))}
    </div>
  </Container>
);

export default shouldCalendarUpdate('month')(Month);
