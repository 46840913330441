import { get, post, patch } from '../api/rest';
import { fulfill } from '../utils/promise';

export const types = {
  GET: 'layerUploads/GET',
  UPDATE: 'layerUploads/UPDATE',
  PUBLISH_LAYER: 'layerUploads/PUBLISH',
  RESET: 'layerUploads/RESET',
  INIT: 'layerUploads/INIT_FORM',
};

export interface DatasetUpload {
  id: string;
  filename: string;
  layerId: string;
  size: number;
}

export const getUpload = (id: string) => ({
  type: types.GET,
  payload: get(`/import/layers/${id}`),
});

export const updateLayer = (id: string, updates: any) => ({
  type: types.UPDATE,
  payload: {
    ...patch(`/import/layers/${id}`, updates),
    data: { id, updates },
  },
});

export const resetLayer = (id: string) => ({
  type: types.RESET,
  payload: post(`/import/layers/${id}/reset`),
});

export const publishLayer = (id: string) => ({
  type: types.PUBLISH_LAYER,
  payload: post(`/import/layers/${id}/publish`),
});

export const initLayerImportForm = (id: string) => ({
  type: types.INIT,
  payload: id,
});

export const actions = {
  getUpload,
  updateLayer,
  resetLayer,
  publishLayer,
  initLayerImportForm,
};

export const initialState = {};

export default (state: Dict<any> = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.GET):
    case fulfill(types.UPDATE):
    case fulfill(types.RESET):
      return { ...state, [payload.id]: payload };

    default:
      return state;
  }
};

export const getLayerUpload = (state, id: string) => state.layerUploads[id];
export const getLayerUploadState = (state, id: string) =>
  getLayerUpload(state, id).state;
