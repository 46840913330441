import React from 'react';
import moment from 'moment';
import { FormattedMessage as F } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import { contains } from 'ramda';
import { Scrollable, Dialog, Footer, Button, Text, Submit } from '@hm/ukie';

import { PollingMetricCard } from '../MetricCard';
import Track from '../Track';
import NewLayer from '../../containers/NewLayer';
import messages from './messages';
import { BaseLayer } from '../../reducers/baseLayers';

export interface Props {
  isApproximatedProject: boolean;
  layers: BaseLayer[];
  selectedId: string;
  canAdd: boolean;
  currentSliceDuration: moment.Duration;
  addLayer(id: string): Promise<any>;
  onSelect(id: string);
  onDelete(id: string): Promise<any>;
  onUpdate(id: string, updates: any): any;
  onClose();
}

interface State {
  currentPage?: number;
}

const Tiles = Box.extend`
  flex: 1 1 100%;
  background: ${props => props.theme.colors.grey2};
`;

/**
 * TODO: extract common things with Explorer.tsx
 */
export default class NewMetricModal extends React.Component<Props, State> {
  static defaultProps = {
    thumbnails: [],
  };

  state = {
    currentPage: 0,
  };

  addAndClose = () => {
    this.props.addLayer(this.props.selectedId).then(() => this.props.onClose());
  };

  nextPage = () => this.setState({ currentPage: this.state.currentPage + 1 });
  prevPage = () => this.setState({ currentPage: this.state.currentPage - 1 });

  renderDashboard() {
    const {
      layers,
      isApproximatedProject,
      canAdd,
      currentSliceDuration,
      selectedId,
      onSelect,
      onDelete,
      onUpdate,
    } = this.props;

    return (
      <Dialog
        title={
          <F {...messages.addLayer}>{val => <Text.Title>{val}</Text.Title>}</F>
        }
        onClose={this.props.onClose}
      >
        <Flex flexDirection="column" flex="1 1 100%">
          <Tiles>
            <Scrollable>
              <Flex flexWrap="wrap" p={12}>
                {layers.map(layer => (
                  <Box p="4px" key={layer.id}>
                    <PollingMetricCard
                      m={0}
                      metric={layer}
                      disabled={
                        !contains(
                          currentSliceDuration.asMilliseconds(),
                          layer.sliceDurations.map(d => d.asMilliseconds()),
                        )
                      }
                      selected={selectedId === layer.id}
                      onClick={() => onSelect(layer.id)}
                      onDoubleClick={
                        isApproximatedProject ? this.nextPage : this.addAndClose
                      }
                      onDelete={() => onDelete(layer.id)}
                      onRename={label => onUpdate(layer.id, { label })}
                    />
                  </Box>
                ))}
              </Flex>
            </Scrollable>
          </Tiles>
        </Flex>
        <Footer
          actions={
            <React.Fragment>
              <Track
                data-metrics-event-name="metricCreate"
                data-metrics-category="Metric"
              >
                <Button onClick={this.nextPage} disabled={!canAdd}>
                  <F {...messages.addAsApproximated} />
                </Button>
              </Track>
              {isApproximatedProject ? null : (
                <Track
                  data-metrics-event-name="metricCreate"
                  data-metrics-category="Metric"
                >
                  <Box ml={1}>
                    <Submit onClick={this.addAndClose} disabled={!canAdd}>
                      <F {...messages.addAsRegular} />
                    </Submit>
                  </Box>
                </Track>
              )}
            </React.Fragment>
          }
        />
      </Dialog>
    );
  }

  render() {
    switch (this.state.currentPage) {
      case 0:
        return this.renderDashboard();
      case 1:
        return <NewLayer onBack={this.prevPage} />;
      default:
        return null;
    }
  }
}
