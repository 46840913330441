import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Box } from 'grid-styled';
import { Text } from '@hm/ukie';
import { BillingState } from '../../reducers/billing';

interface Props {
  billingInfo: BillingState;
}

const PlanDescription: React.SFC<Props> = ({
  billingInfo: {
    type,
    maxHours,
    maxSessions,
    showMaxUsers,
    maxUsers,
    maxStorage,
    showRenderHoursLimit,
    showStorageLimit,
    planDescription,
  },
}) => (
  <React.Fragment>
    <Text fontWeight="semibold" fontSize={2} mb={1} is="div">
      {planDescription}
    </Text>

    <Box my={2}>
      {showMaxUsers ? (
        <F
          tagName="div"
          id="Plan.users"
          defaultMessage="{users} users"
          values={{
            users: <Text.Important>{maxUsers}</Text.Important>,
          }}
        />
      ) : null}

      {showRenderHoursLimit ? (
        <F
          tagName="div"
          id="Plan.hoursMonthIncluded"
          defaultMessage="{hoursMonth} hours/month included"
          values={{
            hoursMonth: <Text.Important>{maxHours || '-'}</Text.Important>,
          }}
        />
      ) : null}

      {showStorageLimit ? (
        <F
          tagName="div"
          id="Plan.storage"
          defaultMessage="{storage} GB of data storage"
          values={{
            storage: <Text.Important>{maxStorage || '-'}</Text.Important>,
          }}
        />
      ) : null}
    </Box>
  </React.Fragment>
);

export default PlanDescription;
