import React from 'react';
import { connect } from 'react-redux';
import { Box } from 'grid-styled';

import InternetQuality from '../InternetQuality';
import InterpolationType from '../InterpolationType';
import TimeAxisScaling from '../TimeAxisScaling';
import { getActivePinsList } from '../../selectors/sidebar';
import { SidebarPin } from '../../reducers/sidebar';

const Container = Box.extend.attrs({ p: 2 })`
  width: 336px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.default};
  position: relative;
`;

interface Props {
  pinnedItems: SidebarPin[];
}

const pinComponents = {
  quality: <InternetQuality key="quality" />,
  scaling: <TimeAxisScaling key="scaling" />,
  interpolation: <InterpolationType key="interpolation" />,
};

export const DisplayControls: React.SFC<Props> = ({ pinnedItems }) =>
  pinnedItems.length > 0 ? (
    <Container>{pinnedItems.map(i => pinComponents[i])}</Container>
  ) : null;

export default connect(state => ({
  pinnedItems: getActivePinsList(state),
}))(DisplayControls);
