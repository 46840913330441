import { range, sum } from 'ramda';
import { scaleLinear, scaleBand } from 'd3-scale';
import { Point } from '../reducers/gradients';
import { HistogramData } from '../models';

/**
 * TODO: move scales where they belong
 * memoize if needed to avoid unneeded rerenders
 */

export const getVoidColor = (gradientPoints: Point[]) => {
  const colorIndex = Math.floor(gradientPoints.length / 2);
  return gradientPoints[colorIndex] && `#${gradientPoints[colorIndex].color}`;
};

export const getVoidRatio = (
  currentBins: HistogramData[],
  voidValue: number,
) => {
  const binsTotalY = sum(currentBins.map(d => d.y));
  return voidValue / (voidValue + binsTotalY);
};

export const getBinScale = (binsCount: number, width: number) => {
  return scaleBand<number>()
    .domain(range(0, binsCount))
    .range([0, width]);
};

export const getBorderScale = (bordersCount: number, width: number) => {
  return scaleLinear<number>()
    .domain([0, bordersCount - 1])
    .range([0, width]);
};

export const getBrushScale = (width: number) => {
  return scaleLinear<number>()
    .domain([0, 1])
    .range([0, width]);
};

// XXX: implicitly expects gradient stops to be in range 0..255
// and hex color values to be without #
export const getColorScale = (
  gradientPoints: { value: number; color: string }[],
  binsCount: number,
) => {
  return scaleLinear<string, number>()
    .domain(gradientPoints.map(d => d.value / 255 * binsCount))
    .range(gradientPoints.map(d => `#${d.color}`));
};

export const getBordersInverseScale = (borders: number[], width: number) => {
  const borderScale = getBorderScale(borders.length, width);

  return (x: number) => {
    const index = Math.round(borderScale.invert(x));
    return borders[index];
  };
};
