import { whereEq, anyPass, map } from 'ramda';
import { take, select } from 'redux-saga/effects';
import { types } from '../../actions/volumer';
import { getSelectedTool } from '../../selectors/tool';
import { getActiveModifier } from '../../selectors/volumer';
import {
  getSelectedEntities,
  isEditMode,
} from '../../selectors/selectedObjects';
import { Tool } from '../../constants/tools';
import { MouseButton, Modifier } from '../../constants/volumer';
import { objectTypeToTool } from '../../utils/volumerInteractions';
import { includesId } from '../../utils/helpers';
import getNormalizedCoord from './getNormalizedCoord';
import raycastGet, { Hit } from './raycastGet';
import { Pointer } from '../../models';

import moveCamera from './moveCamera';
import drag from './drag';
import { EventSender } from './getEventSender';
import { RaycastSender } from './getRaycastSender';

const interactions = [
  {
    event: drag,
    condition: anyPass(
      map(whereEq, [
        {
          modifier: null,
          tool: Tool.CURSOR,
          button: MouseButton.LEFT,
          isObjectHit: true,
          immutable: false,
        },
        {
          modifier: null,
          button: MouseButton.LEFT,
          toolTypeObjectHit: true,
          immutable: false,
        },
        {
          modifier: Modifier.Q,
          button: MouseButton.LEFT,
          isObjectHit: true,
          immutable: false,
        },
      ]),
    ),
  },
  {
    event: moveCamera('groundPan'),
    condition: anyPass(
      map(whereEq, [
        {
          modifier: null,
          tool: Tool.NAVIGATION,
          button: MouseButton.LEFT,
          ctrlKey: true,
        },
        { modifier: null, button: MouseButton.RIGHT, ctrlKey: true },
        { modifier: null, button: MouseButton.MIDDLE, ctrlKey: true },
        { modifier: Modifier.E, button: MouseButton.LEFT },
      ]),
    ),
  },
  {
    event: moveCamera('pan'),
    condition: anyPass(
      map(whereEq, [
        {
          modifier: null,
          tool: Tool.NAVIGATION,
          button: MouseButton.LEFT,
          shiftKey: true,
        },
        { modifier: null, button: MouseButton.RIGHT, shiftKey: true },
        { modifier: null, button: MouseButton.MIDDLE },
        { modifier: Modifier.W, button: MouseButton.LEFT },
      ]),
    ),
  },
  {
    event: moveCamera('rotate'),
    condition: anyPass(
      map(whereEq, [
        { modifier: null, tool: Tool.NAVIGATION, button: MouseButton.LEFT },
        { modifier: null, button: MouseButton.RIGHT },
        { modifier: Modifier.R, button: MouseButton.LEFT },
      ]),
    ),
  },
];

function* chooseEvent(firstHit: Hit, pointer: Pointer) {
  const tool = yield select(getSelectedTool);

  const currentState = {
    modifier: yield select(getActiveModifier),
    tool,
    button: pointer.button,
    shiftKey: pointer.shiftKey,
    ctrlKey: pointer.ctrlKey,
    isObjectHit: !!firstHit,
    toolTypeObjectHit: firstHit && objectTypeToTool[firstHit.type] === tool,
    immutable: (firstHit && firstHit.immutable) || false,
  };

  const found = interactions.find(({ condition }) => condition(currentState));
  return found ? found.event : null;
}

const fullStopWithEvent = whereEq({
  type: types.POINTER_UP,
  ongoingEvent: true,
});
const fullStop = whereEq({ type: types.POINTER_UP });
const eventStop = whereEq({ ongoingEvent: true, event: false });
const eventStart = whereEq({ ongoingEvent: false, event: true });
const eventTick = whereEq({
  ongoingEvent: true,
  event: true,
  eventChanged: false,
});
const eventChange = whereEq({
  ongoingEvent: true,
  event: true,
  eventChanged: true,
});
const allModifiersOff = whereEq({
  ongoingEvent: true,
  event: false,
  eventChanged: true,
});

function* getDraggableEntities(editMode: boolean, firstHit?: Hit) {
  if (!firstHit) return [];
  const selectedEntities = yield select(getSelectedEntities);

  return includesId(firstHit.id)(selectedEntities)
    ? selectedEntities
    : [firstHit];
}

/**
 * Handles multiphase interactions that follow this pattern:
 * startEvent -> event * N -> stopEvent
 * Allows switching between them without releasing the mouse button
 * Ensures they don't collide
 */
export default function*(sendEvent: EventSender, sendRaycast: RaycastSender) {
  while (true) {
    const { payload: pointer } = yield take(types.POINTER_DOWN);
    const editMode = yield select(isEditMode);
    const raycastTarget = editMode ? 'fragment' : 'object';
    const firstHit: Hit = yield* raycastGet(
      raycastTarget,
      pointer,
      sendRaycast,
    );

    const draggableEntities = yield* getDraggableEntities(editMode, firstHit);
    const domain = firstHit && firstHit.domain;

    let ongoingEvent = null;
    let prevCoord = null;

    while (true) {
      const { type, payload: pointer } = yield take([
        types.POINTER_MOVE,
        types.POINTER_UP,
      ]);

      const event = yield* chooseEvent(firstHit, pointer);
      const coord = yield* getNormalizedCoord(pointer);

      const state = {
        type,
        ongoingEvent: !!ongoingEvent,
        event: !!event,
        eventChanged: event !== ongoingEvent,
      };

      // XXX: not nice, but as simple as was possible
      if (fullStopWithEvent(state) || allModifiersOff(state)) {
        yield* ongoingEvent.stop(sendEvent, coord, draggableEntities, domain);
        break;
      } else if (fullStop(state)) {
        break;
      } else if (eventStart(state)) {
        yield* event.start(sendEvent, coord, draggableEntities, domain);
        ongoingEvent = event;
      } else if (eventChange(state)) {
        yield* ongoingEvent.stop(sendEvent, coord, draggableEntities, domain);
        yield* event.start(sendEvent, coord, draggableEntities, domain);
        ongoingEvent = event;
      } else if (eventStop(state)) {
        yield* ongoingEvent.stop(sendEvent, coord, draggableEntities, domain);
        ongoingEvent = null;
      } else if (eventTick(state)) {
        yield* event.tick(
          sendEvent,
          coord,
          prevCoord,
          draggableEntities,
          domain,
        );
      }

      prevCoord = coord;
    }
  }
}
