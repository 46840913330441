import { Option } from '../models';
import { fulfill } from '../utils/promise';

import { types as projectTypes } from '../actions/projects';
import { types as layerTypes } from '../actions/layers';

export interface Category {
  id: string;
  options: Option[];
  description?: string;
  icon?: string;
  label: string;
}

export interface CategoriesState {
  entities: { [index: string]: Category };
}

export const initialState: CategoriesState = {
  entities: {},
};

export default (
  state: CategoriesState = initialState,
  action: any,
): CategoriesState => {
  switch (action.type) {
    case fulfill(layerTypes.CREATE_LAYER):
    case fulfill(projectTypes.GET_PROJECT):
      if (!action.payload.entities.categories) return state;

      return {
        entities: {
          ...state.entities,
          ...action.payload.entities.categories,
        },
      };

    default:
      return state;
  }
};
