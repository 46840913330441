import React from 'react';
import styled from 'styled-components';
import { Moment, Duration } from 'moment';
import 'moment-timezone';
import { ScaleLinear, ScaleQuantize } from 'd3-scale';
import { equals } from 'ramda';
import { BrushCore } from '@hm/ukie';

import Handle from './Handle';
import HandleLabel from './HandleLabel';
import { getCollisionShifts } from '../../utils/dimensions';
import { ApproximationType } from '../../reducers/currentProject';

const DRAGGABLE_HEIGHT = 12;

const Track = styled.rect`
  fill-opacity: 0;
  cursor: ew-resize;
`;

interface Props {
  selected: [number, number];
  minExtent: number;
  sliceDuration: Duration;
  timezone: string;
  y: number;
  scale: ScaleLinear<number, number>;
  labelScale: ScaleQuantize<Moment>;
  slices: Moment[];
  approximationType: ApproximationType;
  boundaries: Element;
  onChange(extent: [number, number]): void;
  onStart(): void;
  onStop(): void;
}

interface State {
  labelShifts: { left: number; right: number };
}

/**
 * Draws brush for selecting ranges.
 */
export default class Brush extends React.Component<Props, State> {
  leftLabel: SVGElement;
  rightLabel: SVGElement;

  state: State = {
    labelShifts: { left: 0, right: 0 },
  };

  componentWillUpdate(nextProps: Props) {
    if (!equals(this.props.selected, nextProps.selected))
      this.shiftBrushLabels();
  }

  shiftBrushLabels() {
    if (!this.leftLabel || !this.rightLabel) return;

    const left = this.leftLabel.getBoundingClientRect();
    const right = this.rightLabel.getBoundingClientRect();

    const labelShifts = getCollisionShifts(
      { left, right },
      this.state.labelShifts,
      8,
    );

    this.setState({ labelShifts });
  }

  onDrag = (e: MouseEvent, data) => e.preventDefault();

  renderBody = () => (
    <Track y={this.props.y - DRAGGABLE_HEIGHT / 2} height={DRAGGABLE_HEIGHT} />
  );

  renderLeftHandle = () => (
    <g transform={`translate(0, ${this.props.y})`}>
      <Handle cursor="w-resize" />
      <HandleLabel
        slice={this.props
          .labelScale(this.props.selected[0])
          .tz(this.props.timezone)}
        sliceDuration={this.props.sliceDuration}
        approximationType={this.props.approximationType}
        transform={`translate(${this.state.labelShifts.left}, 0)`}
        getRef={el => (this.leftLabel = el)}
      />
    </g>
  );

  renderRightHandle = () => (
    <g transform={`translate(0, ${this.props.y})`}>
      <Handle cursor="e-resize" />
      <HandleLabel
        slice={this.props
          .labelScale(this.props.selected[1])
          .tz(this.props.timezone)}
        approximationType={this.props.approximationType}
        sliceDuration={this.props.sliceDuration}
        transform={`translate(${this.state.labelShifts.right}, 0)`}
        getRef={el => (this.rightLabel = el)}
      />
    </g>
  );

  render() {
    return (
      <BrushCore
        xScale={this.props.scale}
        minExtent={this.props.minExtent}
        selected={this.props.selected}
        boundaries={this.props.boundaries}
        onDrag={this.onDrag}
        onStart={this.props.onStart}
        onStop={this.props.onStop}
        onChange={this.props.onChange}
        leftHandleComponent={this.renderLeftHandle()}
        rightHandleComponent={this.renderRightHandle()}
        bodyComponent={this.renderBody()}
      />
    );
  }
}
