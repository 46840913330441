import React from 'react';
import { colors } from '@hm/ukie';
import { getBorderScale } from '../../utils/histogram';

interface Props {
  xTicks: number[];
  yTicks: number[];
  height: number;
  width: number;
  binsCount: number;
  yScale(value: number): number;
}

const Grid: React.SFC<Props> = ({
  xTicks,
  yTicks,
  binsCount,
  yScale,
  height,
  width,
}) => {
  const xScale = getBorderScale(binsCount, width);

  return (
    <g>
      {xTicks.map(verticalGridLine => (
        <line
          x1={xScale(verticalGridLine)}
          y1={0}
          x2={xScale(verticalGridLine)}
          y2={height}
          key={'v' + verticalGridLine}
          style={{
            stroke: colors.grey5,
            shapeRendering: 'crispEdges',
          }}
        />
      ))}
      {yTicks.map(horizontalGridLine => (
        <line
          x1={0}
          y1={yScale(horizontalGridLine)}
          x2={width}
          y2={yScale(horizontalGridLine)}
          key={'h' + horizontalGridLine}
          style={{
            stroke: colors.grey5,
            shapeRendering: 'crispEdges',
          }}
        />
      ))}
    </g>
  );
};

export default Grid;
