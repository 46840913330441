import React from 'react';
import styled from 'styled-components';
import { Stat } from '../../reducers/selectedObjects';
import StatLine from './StatLine';

export interface Props {
  title: string;
  stats: Stat[];
  truncate: boolean;
  layerGradients: Dict<string[]>;
  layerLabels: Dict<string>;
  formatFunction(value: number): string;
}

const Body = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 6px;
  }
`;

export default ({
  layerGradients,
  layerLabels,
  stats,
  title,
  ...rest,
}: Props) => (
  <Body>
    <div>{title}</div>
    {stats.map(stat => (
      <StatLine
        key={stat.layerId}
        layerGradient={layerGradients[stat.layerId]}
        layerLabel={layerLabels[stat.layerId]}
        stat={stat}
        {...rest}
      />
    ))}
  </Body>
);
