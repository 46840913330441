import moment from 'moment';
import { DateRange } from 'moment-range';
import { equals, omit, compose, apply, complement, map, join } from 'ramda';
import { shouldUpdate } from 'recompose';

/**
 * Performance HOC for calendar components.
 *
 * Checks if new selection must trigger rerender of calendar component. It’s
 * done by calculating intersection of selectedComponents and time interval
 * associated with calendar component. If intersection is the same then there
 * is no reason for update.
 */

const intersectionString = (intersectWith: DateRange[], interval: DateRange) =>
  compose(
    join(''),
    map<DateRange, string>(i => {
      const intersection = interval.intersect(i);
      return intersection ? intersection.toString() : '';
    }),
  )(intersectWith);

const differs = complement(equals);

const isRestDiffers = compose(apply(differs), map(omit(['selectedIntervals'])));

interface Props {
  selectedIntervals: DateRange[];
  filterPreview: DateRange[];
  date: moment.Moment;
}

export default (period: moment.unitOfTime.Base) =>
  shouldUpdate((props: Props, nextProps: Props) => {
    const { selectedIntervals, filterPreview, date } = props;

    const {
      selectedIntervals: nextSelectedIntervals,
      filterPreview: nextFilterPreview,
    } = nextProps;

    // const interval = date.range(period);
    const interval = new DateRange(
      date.clone().startOf(period),
      date.clone().endOf(period),
    );

    return (
      differs(
        intersectionString(selectedIntervals, interval),
        intersectionString(nextSelectedIntervals, interval),
      ) ||
      (filterPreview &&
        nextFilterPreview &&
        differs(
          intersectionString(filterPreview, interval),
          intersectionString(nextFilterPreview, interval),
        )) ||
      isRestDiffers([props, nextProps])
    );
  });
