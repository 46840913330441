import React from 'react';
import { ScaleLinear } from 'd3-scale';
import { head, last } from 'ramda';

interface Props {
  width: number;
  height: number;
  y: number;
  displayProgress?: boolean;
  brushScale?: ScaleLinear<number, number>;
  selected?: number[];
  previousRange?: number[];
}

const Track: React.StatelessComponent<Props> = ({
  width,
  height,
  y,
  displayProgress,
  brushScale,
  selected,
  previousRange,
}) => {
  return (
    <g transform={`translate(0, ${y})`}>
      <rect className="timeline__track-passive" height={height} width={width} />
      {previousRange ? (
        <rect
          className="timeline__track-ghost"
          height={height}
          x={brushScale(head(previousRange))}
          width={
            brushScale(last(previousRange)) - brushScale(head(previousRange))
          }
        />
      ) : null}
      {displayProgress ? (
        <rect
          className="timeline__track-active"
          height={height}
          x={brushScale(head(selected))}
          width={brushScale(last(selected)) - brushScale(head(selected))}
        />
      ) : null}
    </g>
  );
};

export default Track;
