import { put, take, select } from 'redux-saga/effects';
import { types } from '../../actions/tool';
import { deleteObject, updateObject } from '../../actions/objects';
import {
  getDraftObject,
  getObjectInEditMode,
} from '../../selectors/selectedObjects';
import { ObjectType } from '../../constants/objects';

export default function*() {
  while (true) {
    yield take(types.SELECT_TOOL);
    const draftObject = yield select(getDraftObject);
    const editedObject = yield select(getObjectInEditMode);

    if (draftObject && draftObject.type !== ObjectType.MARKER) {
      yield put(deleteObject(draftObject.id));
    }

    if (editedObject)
      yield put(updateObject(editedObject.id, { editMode: false }));
  }
}
