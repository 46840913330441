import mapValues from 'lodash.mapvalues';
import { values, is } from 'ramda';

type PrimitiveOrFunction<T> = {
  [P in keyof T]: T[P] | ((value: T[P], obj: T) => boolean)
};

interface Option<Condition, Output> {
  conditions: PrimitiveOrFunction<Condition>[];
  output: Output;
}

/**
 * Checks if object conforms with the provided reference
 */
export const conforms = <T>(reference: T, state: T) => {
  const testResults = mapValues(reference, (condition, key): boolean => {
    const actualValue = state[key];
    return is(Function, condition)
      ? condition(actualValue, state)
      : condition === actualValue;
  });

  return values(testResults).every(test => test === true);
};

/**
 * Returns action creator of the first interaction for which at least one
 * condition was satisfied by the provided state
 */
export const optionFromConditions = <O, C>(options: Option<O, C>[]) => (
  state: O,
) => {
  const chosen = options.find(({ conditions }) =>
    conditions.some(c => conforms(c, state)),
  );

  return chosen ? chosen.output : null;
};
