import React from 'react';
import IntlDocumentTitle from '../IntlDocumentTitle';
import { Flex, Box } from 'grid-styled';
import WelcomeSubscribed, { Props as SubProps } from './WelcomeSubscribed';
import WelcomeUnsubscribed, {
  Props as UnsubProps,
} from './WelcomeUnsubscribed';
import GlobalAlert from '../GlobalAlert';
import { GlobalAlerts } from '../../reducers/alertStack';

const Body = Flex.extend.attrs({
  pt: 3,
  align: 'center',
  flexDirection: 'column',
})`
  height: 100%;
  white-space: pre-line;
  text-align: center;
`;

interface OwnProps {
  subscriptionActive: boolean;
  isUserBillable: boolean;
}

export type Props = SubProps & UnsubProps & OwnProps;

const Welcome: React.SFC<Props> = ({
  name,
  subscriptionActive,
  plan,
  daysOfTrialLeft,
  projects,
  isUserBillable,
}) => (
  <Body>
    <Box flex="auto">
      {subscriptionActive || !isUserBillable ? (
        <WelcomeSubscribed
          name={name}
          plan={plan}
          daysOfTrialLeft={daysOfTrialLeft}
          projects={projects}
        />
      ) : (
        <WelcomeUnsubscribed name={name} />
      )}
    </Box>
    <GlobalAlert
      selected={[GlobalAlerts.InvoiceError, GlobalAlerts.NoHoursLeft]}
    />
    <IntlDocumentTitle titleId="welcome" />
  </Body>
);

export default Welcome;
