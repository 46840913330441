import { Fill, Icon } from '@hm/ukie';
import { Flex } from 'grid-styled';
import React from 'react';
import { TransitionMotion, spring } from 'react-motion';
import styled from 'styled-components';
import { fastSpring } from '../../utils/helpers';
import { SubTitle } from '../Title';

const Header = SubTitle.extend`
  margin: 0;
`;

const TopRight = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

export interface Props {
  title: string;
  show: boolean;
  className?: string;
  onClose(): void;
}

const getStyle = () => ({ translateX: spring(0, fastSpring) });
const willEnter = () => ({ translateX: -100 });
const willLeave = () => ({ translateX: spring(-100, fastSpring) });

const SlideIn: React.SFC<Props> = ({
  onClose,
  title,
  children,
  show,
  className,
}) => (
  <TransitionMotion
    styles={
      show
        ? [
            {
              key: 'slider',
              style: getStyle(),
            },
          ]
        : []
    }
    willEnter={willEnter}
    willLeave={willLeave}
  >
    {interpolatedStyles => (
      <div>
        {interpolatedStyles.map(({ key, style }) => (
          <Fill key={key}>
            <Flex
              flexDirection="column"
              align="center"
              flex="1"
              p="40px 36px"
              key={key}
              className={className}
              style={{ transform: `translateX(${style.translateX}%)` }}
            >
              <Header>{title}</Header>
              <TopRight onClick={onClose}>
                <Icon name="cross" />
              </TopRight>
              <Flex
                flexDirection="column"
                width="100%"
                justify="center"
                flex="1"
              >
                {children}
              </Flex>
            </Flex>
          </Fill>
        ))}
      </div>
    )}
  </TransitionMotion>
);

export default styled(SlideIn)`
  position: relative;
  background: white;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.grey5};
`;
