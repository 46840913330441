import { connect } from 'react-redux';
import {
  isTrial,
  getCardBrand,
  getCardLastFour,
  getCardExpYear,
  getCardExpMonth,
  getnextUpcomingInvoiceAt,
  getUsedHours,
  getMaxHours,
  getStripeSynchronized,
  changePaymentSource,
} from '../../reducers/billing';
import Payment from './Payment';
import { AppState } from '../../models';

export default connect(
  (state: AppState) => ({
    billingInfo: state.billing,
    trial: isTrial(state),
    usedHours: getUsedHours(state),
    maxHours: getMaxHours(state),
    cardBrand: getCardBrand(state),
    cardExpYear: getCardExpYear(state),
    cardExpMonth: getCardExpMonth(state),
    cardLastFour: getCardLastFour(state),
    nextInvoice: getnextUpcomingInvoiceAt(state),
    stripeSynchronized: getStripeSynchronized(state),
  }),
  {
    onChangePaymentSource: changePaymentSource,
  },
)(Payment);
