import { types } from '../actions/timeline';
import { types as projectTypes } from '../actions/projects';
import { fulfill } from '../utils/promise';
import { PlaybackBehaviour, PlaybackMode } from '../models';

export interface Timeline {
  isPlaying: boolean;
  speed: number;
  mode: PlaybackMode;
  behaviour: PlaybackBehaviour;
  timeWindow: [number, number];
}

export const initialState = {
  isPlaying: false,
  // TODO: we should persist speed, mode and behaviour for better ux
  speed: 2,
  mode: 'range' as PlaybackMode,
  behaviour: 'growth' as PlaybackBehaviour,
  timeWindow: [0, 1] as [number, number],
};

export default (state: Timeline = initialState, action: any): Timeline => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(projectTypes.GET_PROJECT):
      const { timeWindow } = payload.entities.projects[payload.result];
      return { ...state, timeWindow };

    case types.STOP_PLAYBACK:
      return { ...state, isPlaying: false };

    case types.START_PLAYBACK:
      return { ...state, isPlaying: true };

    case types.CHANGE_PLAYBACK_SPEED:
      return { ...state, speed: payload };

    case types.CHANGE_PLAYBACK_MODE:
      return { ...state, mode: payload };

    case types.CHANGE_PLAYBACK_BEHAVIOUR:
      return { ...state, behaviour: payload };

    case types.SELECT_RANGE:
      return { ...state, timeWindow: payload };

    case types.SELECT_SINGLE:
      const [start] = state.timeWindow;
      return { ...state, timeWindow: [start, payload] };

    default:
      return state;
  }
};
