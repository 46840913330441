import React from 'react';
import styled from 'styled-components';
import { Theme } from '@hm/ukie';
import { TickType } from '../../constants/timeline';

const GAP_HEIGHT = 2;

const Gap = styled.rect`
  fill: ${({ theme }) => theme.colors.grey2};
  shape-rendering: crispEdges;
`;

const intervalDivider = { y: -24, height: 26, width: 1 };

const lineParams = {
  [TickType.REGULAR]: { y: 2, height: 4, width: 1 },
  [TickType.DIVIDER]: { y: 2, height: 22, width: 1 },
  [TickType.SUPER_DIVIDER]: { y: 2, height: 48, width: 1 },
  [TickType.INTERVAL_START]: intervalDivider,
  [TickType.RANGE_END]: intervalDivider,
  [TickType.RANGE_START]: intervalDivider,
};

const gapParams = {
  [TickType.REGULAR]: { x: -2, width: 2 },
  [TickType.DIVIDER]: { x: -2, width: 2 },
  [TickType.SUPER_DIVIDER]: { x: -2, width: 2 },
  [TickType.INTERVAL_START]: { x: -16, width: 16 },
};

interface LineProps {
  isActive: boolean;
  isGhost: boolean;
  theme?: Theme;
}

const Line = styled.rect`
  shape-rendering: crispEdges;
  fill: ${(props: LineProps) =>
    props.isActive ? props.theme.colors.primary : props.theme.colors.grey5};
`;

interface Props extends React.SVGAttributes<SVGGElement> {
  isActive: boolean;
  isGhost: boolean;
  tickType: string;
}

const Notch: React.SFC<Props> = ({
  tickType,
  isActive,
  isGhost,
  children,
  ...rest
}) => {
  const gap = gapParams[tickType];
  const line = lineParams[tickType];

  return (
    <g {...rest}>
      <Line isActive={isActive} isGhost={isGhost} {...line} />
      {gap ? <Gap height={GAP_HEIGHT} {...gap} /> : null}
    </g>
  );
};

export default Notch;
