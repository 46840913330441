import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import { render } from 'react-dom';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import App from './components/App';

import './styles/index.styl';
import '@hm/ukie/dist/css/styles.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

import momentExt from './utils/momentExt';
momentExt(moment);

addLocaleData(en);

const root = document.getElementById('habidatum');
const app = <App />;

if (process.env.NODE_ENV !== 'production') {
  /* tslint:disable-next-line */
  const RedBox = require('redbox-react');

  try {
    render(app, root);
  } catch (e) {
    render(<RedBox error={e} />, root);
  }
} else {
  render(app, root);
}
