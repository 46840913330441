import { connect } from 'react-redux';
import ColorMenu from '../components/Metric/ColorMenu';
import { updateLayer } from '../actions/layers';
import { getGradientsList } from '../selectors/gradients';
import { AppState } from '../models';

const mapStateToProps = (state: AppState, { layerId, layerGradientId }) => ({
  gradients: getGradientsList(state),
  layerId,
  layerGradientId,
});

const mapDispatchToProps = {
  setLayerGradient: (layerId: string, gradientId: string) =>
    updateLayer(layerId, { gradientId }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorMenu);
