import { clamp } from 'ramda';

interface PairedElements<T> {
  left: T;
  right: T;
}

/**
 * Is element overlaps target with it’s left boundary but not the right
 */
export const overlapsOnLeft = (element: ClientRect, target: ClientRect) => {
  return target.left >= element.left && target.left < element.right;
};

/**
 * Is element overlaps target with it’s right boundary but not the left
 */
export const overlapsOnRight = (element: ClientRect, target: ClientRect) => {
  return target.right >= element.left && target.right < element.right;
};

/**
 * Is element’s boundaries are within target element boundaries
 */
export const isWithin = (element: ClientRect, target: ClientRect) => {
  return element.left >= target.left && element.right < target.right;
};

export const overlapsHorizontally = (
  a: ClientRect,
  b: ClientRect,
  margin = 0,
) => {
  return a.left - b.right < margin && b.left - a.right < margin;
};

export const overlapsVertically = (
  a: ClientRect,
  b: ClientRect,
  margin = 0,
) => {
  return a.top - b.bottom < margin && b.top - a.bottom < margin;
};

export const overlaps = (a: ClientRect, b: ClientRect, margin = 0) => {
  return overlapsVertically(a, b, margin) && overlapsHorizontally(a, b, margin);
};

/**
 * Computes X-axis translate values for two elements that simulate collision
 * between them and prevent overlapping.
 *
 * NOTE: returns negative left (translate in the negative direction)
 * and positive right (translate in the positive direction)
 */
export const getCollisionShifts = (
  rects: PairedElements<ClientRect>,
  activeShifts: PairedElements<number>,
  padding = 0,
) => {
  const { left: leftRect, right: rightRect } = rects;

  // Calculate overlap in pixels without already applied shifts
  // also add padding if provided
  const currentOverlap = leftRect.right - rightRect.left;
  const unshiftedOverlap =
    currentOverlap + (-activeShifts.left + activeShifts.right);
  const overlap = unshiftedOverlap + padding;

  // If one label is longer, we first compensate overflow with it's excess width
  // Example: overflow == 30, leftRect.width == 50, rightRect.width == 10
  // In this case left label will be shifted for full 30 pixels and right
  // will be untouched
  const sizeDifference = leftRect.width - rightRect.width;
  const leftExcessCorrection = clamp(0, Math.max(0, sizeDifference), overlap);
  const rightExcessCorrection = clamp(0, Math.max(0, -sizeDifference), overlap);

  // Rest of the overlap is distributed evenly between the elements
  const fairOverlap = Math.max(overlap - Math.abs(sizeDifference), 0);

  // Ceil and floor help avoid possible problems with odd numbers
  // like distributing 1 or 3px between two elements
  return {
    left: -(leftExcessCorrection + Math.ceil(fairOverlap / 2)),
    right: rightExcessCorrection + Math.floor(fairOverlap / 2),
  };
};
