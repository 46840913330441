import { volumer } from '@hm/volumer-api';
import { MapObject } from '../reducers/selectedObjects';

const MINIMIZE_INSPECTOR = 'inspector/MINIMIZE';
const UPDATE_PARAMETERS = 'inspector/UPDATE_PARAMETERS';
const TOGGLE_MENU = 'inspector/TOGGLE_MENU';
const TOGGLE_TRUNCATION = 'inspector/TOGGLE_TRUNCATION';
const TOGGLE_SHOW_MORE = 'inspector/TOGGLE_SHOW_MORE';
const EXPORT_OBJECT_INFO = 'inspector/EXPORT';

const RADIUS_UPDATE_START = 'inspector/RADIUS_UPDATE_START';
const RADIUS_UPDATE_DRAG = 'inspector/RADIUS_UPDATE_DRAG';
const RADIUS_UPDATE_STOP = 'inspector/RADIUS_UPDATE_STOP';

export const toggleMinimize = (minimize: boolean) => ({
  type: MINIMIZE_INSPECTOR,
  payload: minimize,
});

export const updateParams = (params: Partial<MapObject>) => ({
  type: UPDATE_PARAMETERS,
  payload: params,
});

export const radiusUpdateStart = (
  message: volumer.event.RadiusDragStart$Properties,
) => ({
  type: RADIUS_UPDATE_START,
  payload: { message },
});

export const radiusUpdateDrag = (
  message: volumer.event.RadiusDrag$Properties,
) => ({
  type: RADIUS_UPDATE_DRAG,
  payload: { message },
});

export const radiusUpdateStop = (
  message: volumer.event.RadiusDragStop$Properties,
) => ({
  type: RADIUS_UPDATE_STOP,
  payload: { message },
});

export const radiusChange = (objectId: string, radius: number) => dispatch => {
  dispatch(radiusUpdateDrag({ objectId, radius }));
  dispatch(radiusUpdateStop({ objectId }));
};

export const toggleMenu = (isOpen: boolean) => ({
  type: TOGGLE_MENU,
  payload: isOpen,
});

export const toggleTruncation = (trucate: boolean) => ({
  type: TOGGLE_TRUNCATION,
  payload: trucate,
});

export const toggleShowMore = (showMore: boolean) => ({
  type: TOGGLE_SHOW_MORE,
  payload: showMore,
});

export enum ExportFileType {
  GeoJson = 'geojson',
  ShapeFile = 'shapefile',
  Excel = 'excel',
}

export const exportObjectInfo = (fileType: ExportFileType) => ({
  type: EXPORT_OBJECT_INFO,
  payload: fileType,
});

export const types = {
  TOGGLE_SHOW_MORE,
  MINIMIZE_INSPECTOR,
  UPDATE_PARAMETERS,
  TOGGLE_MENU,
  TOGGLE_TRUNCATION,
  EXPORT_OBJECT_INFO,
  RADIUS_UPDATE_START,
  RADIUS_UPDATE_DRAG,
  RADIUS_UPDATE_STOP,
};
