import React from 'react';
import { connect } from 'react-redux';
import Profile, { Props } from './Profile';
import { getCurrentUser, isUserBillable } from '../../selectors/user';
import { updateUser, updatePassword, updateEmail } from '../../actions/user';
import { fetchBillingIfMissing } from '../../reducers/billing/actions';
import { isBillingFetched } from '../../reducers/billing/selectors';
import { AppState } from '../../models';
import { hasErrors } from '../../reducers/alertStack';

interface ContainerProps {
  onProfileGet(): Promise<any>;
  fetchBillingIfMissing(): Promise<any>;
}

class ProfileContainer extends React.Component<Props & ContainerProps> {
  componentDidMount() {
    this.props.fetchBillingIfMissing();
  }

  render() {
    const { onProfileGet, fetchBillingIfMissing: _, ...props } = this.props;

    return this.props.currentUser ? <Profile {...props} /> : null;
  }
}

export default connect(
  (state: AppState) => ({
    currentUser: getCurrentUser(state),
    userBillable: isUserBillable(state),
    billingFetched: isBillingFetched(state),
    hasErrors: hasErrors(state),
  }),
  {
    onUserEdit: updateUser,
    fetchBillingIfMissing,
    onPasswordUpdate: updatePassword,
    onEmailUpdate: updateEmail,
  },
)(ProfileContainer);
