import { takeLatest, put, select } from 'redux-saga/effects';

import {
  getCurrentEnvironmentId,
  getSelectedIntervals,
  getProjectLabel as getCurrentProjectLabel,
  getCurrentProjectType,
  getCurrentStartTime,
  isApproximated,
  getCurrentSliceDuration,
  getCurrentApproximationType,
  getTimeFilters,
} from '../../selectors/project';

import { getLastZoomLevel } from '../../selectors/calendar';

import { getCurrentEnvironment } from '../../selectors/environments';
import { getEnvironment } from '../../actions/environments';
import { INIT_EDIT_PROJECT, init } from '../../actions/calendar';
import {
  constructApproximationInterval,
  getNiceZoomLevel,
} from '../../utils/time';
import { fromAPIFilters } from '../../utils/helpers';
import { fetchBaseLayers } from '../../reducers/baseLayers';

export default function*() {
  yield takeLatest(INIT_EDIT_PROJECT, function*() {
    const currentEnvironmentId = yield select(getCurrentEnvironmentId);

    yield put.resolve(getEnvironment(currentEnvironmentId));
    yield put.resolve(fetchBaseLayers({ environmentId: currentEnvironmentId }));

    const { availableTimeIntervals, timezone } = yield select(
      getCurrentEnvironment,
    );
    const sliceDuration = yield select(getCurrentSliceDuration);
    const lastZoomLevel = yield select(getLastZoomLevel);
    const filterSettings = fromAPIFilters(yield select(getTimeFilters)) as any;

    yield put(
      init({
        type: yield select(getCurrentProjectType),
        sliceDuration,
        availableIntervals: (yield select(isApproximated))
          ? constructApproximationInterval(timezone)
          : availableTimeIntervals,
        // select approximation settings if needed
        ...((yield select(isApproximated))
          ? {
              approximationType: yield select(getCurrentApproximationType),
              startTime: yield select(getCurrentStartTime),
            }
          : {
              zoomLevel: lastZoomLevel
                ? lastZoomLevel
                : getNiceZoomLevel(sliceDuration),
            }),
        // FIXME: 😓
        filterSettings,
        selection: {
          selectedIntervals: yield select(getSelectedIntervals),
          isInverted: false,
          currentInterval: null,
        },
        dayRangeFilter: {
          selectedIntervals: filterSettings.timeIntervals,
          isInverted: false,
          currentInterval: null,
        },
        label: yield select(getCurrentProjectLabel),
      }),
    );
  });
}
