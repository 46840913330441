import { Marker } from 'react-map-gl';
import styled from 'styled-components';

interface Props {
  size?: number;
  latitude: number;
  longitude: number;
}

const StyledMarker = styled<Props>(Marker)`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red1};
  transform: translate(-50%, -50%);
`;

StyledMarker.defaultProps = {
  size: 4,
};

export default StyledMarker;
