import { dissoc, evolve, flip, merge } from 'ramda';
import { types } from '../actions/objects';
import { types as inspectorTypes } from '../actions/inspector';
import { types as projectTypes } from '../actions/projects';
import { fulfill, pend } from '../utils/promise';
import { GeoPoint } from '../models';

export interface Stat {
  layerId: string;
  currentTimeIntervalEnabledSubsets: number[];
  fullTimeIntervalEnabledSubsets: number[];
  currentTimeIntervalAllSubsets: number[];
  fullTimeIntervalAllSubsets: number[];
}

export interface Stats {
  value?: Stat[];
  min?: Stat[];
  max?: Stat[];
  average?: Stat[];
  median?: Stat[];
  mode?: Stat[];
  voidPercent?: Stat[];
  filledVoxelsCount?: Stat[];
  coverage?: Stat[];
  sum?: Stat[];
  sumPercent?: Stat[];
}

export interface Point extends GeoPoint {
  id?: string;
}

export enum ObjectState {
  Enabled = 'enabled',
  Visible = 'visible',
  Hidden = 'hidden',
  Masked = 'masked',
}

export interface MapObject {
  id: string;
  label: string;
  // TODO: write enum for type
  type: string;
  state: ObjectState;
  points: Point[];
  subtype: string;
  address?: string;
  time: number;
  datetime?: string;
  radius?: number;
  stats?: Stats;
  draft: boolean;
  selected: boolean;
  editMode: boolean;
  length?: number;
  perimeter?: number;
  area?: number;
  imported: true;
}

export type SelectedObjectsState = Dict<MapObject>;

export const initialState: SelectedObjectsState = {};

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.CREATE_OBJECT):
    case fulfill(types.SELECT_OBJECT):
    case fulfill(types.GET_OBJECT):
      return {
        ...state,
        [payload.id]: payload,
      };

    case pend(types.UPDATE_OBJECT):
    case fulfill(types.UPDATE_OBJECT):
    case fulfill(types.SYNC_OBJECT):
    case pend(types.SAVE_OBJECT):
      if (!state[payload.id]) return state;
      return {
        ...state,
        [payload.id]: { ...state[payload.id], ...payload },
      };

    case inspectorTypes.RADIUS_UPDATE_DRAG:
      return evolve(
        {
          [payload.message.objectId]: flip(merge)({
            radius: payload.message.radius,
          }),
        },
        state,
      );

    case fulfill(types.ADD_POINT_TO_OBJECT):
      if (!state[payload.mapObjectId]) return state;
      return {
        ...state,
        [payload.mapObjectId]: {
          ...state[payload.mapObjectId],
          points: state[payload.mapObjectId].points.concat(payload),
        },
      };

    case pend(types.DESELECT_OBJECT):
    case pend(types.DELETE_OBJECT):
      return dissoc(payload.id, state);

    case pend(projectTypes.OPEN_PROJECT):
      return initialState;

    default:
      return state;
  }
};
