import React from 'react';
import { Userpic } from '@hm/ukie';
import { connect } from 'react-redux';
import { getProfile } from '../actions/user';
import { getCurrentUser } from '../selectors/user';
import { AppState, CurrentUser as ICurrentUser } from '../models';

interface Props {
  currentUser: ICurrentUser;
  getProfile(): void;
}

class CurrentUser extends React.Component<Props> {
  componentWillMount() {
    if (!this.props.currentUser) this.props.getProfile();
  }

  render() {
    const { currentUser } = this.props;
    if (!currentUser) return null;

    return (
      <Userpic
        name={currentUser.name}
        image={currentUser.avatarUrl}
        color={`#${currentUser.defaultColor}`}
      />
    );
  }
}

export default connect(
  (state: AppState) => ({
    currentUser: getCurrentUser(state),
  }),
  {
    getProfile,
  },
)(CurrentUser);
