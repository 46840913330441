import React from 'react';
import { prop } from 'ramda';
import { Popover } from '@hm/ukie';
import { Option, Effect } from '../../models';
import { Category } from '../../reducers/categories';
import InfoPopover from './InfoPopover';
import styled from 'styled-components';
import { semibold } from '../../styles/common';

const Semibold = styled.span`
  ${semibold};
`;

interface Props {
  categories: Category[];
  effects: Effect[];
}

export default class FoldedOptions extends React.Component<Props, never> {
  renderCategory(category: Category, i: number, categories: Category[]) {
    const options = (category.options as Option[]).filter(prop('enabled'));

    // Brackets are semibold, but not numbers inside them
    return (
      <span key={i}>
        <Semibold>{category.label}</Semibold>
        <Semibold> (</Semibold>
        {options.length} options
        <Semibold>){i !== categories.length - 1 ? ', ' : ''}</Semibold>
      </span>
    );
  }

  render() {
    const { categories, effects } = this.props;

    const content = <InfoPopover categories={categories} effects={effects} />;

    return (
      <Popover
        interactionKind="hover"
        content={content}
        placement="left"
        hoverEnterDelay={1500}
      >
        <div className="metric-option__content">
          <div className="metric-option__row">
            <div className="metric-option__title">Filtering: </div>
            <div className="metric-option__col">
              {categories.map(this.renderCategory)}
            </div>
          </div>
          <div className="metric-option__row">
            <div className="metric-option__title">Effects: </div>
            <div className="metric-option__col">
              <span className="semibold">
                {effects
                  .filter(prop('enabled'))
                  .map(prop('label'))
                  .join(', ')}
              </span>
            </div>
          </div>
        </div>
      </Popover>
    );
  }
}
