import React from 'react';
import { Text, FillFlex } from '@hm/ukie';
import { FormattedMessage as F } from 'react-intl';
import IntlDocumentTitle from '../IntlDocumentTitle';

export default () => (
  <FillFlex justify="center" align="center">
    <F id="NotFound" defaultMessage="This page doesn’t exist">
      {t => <Text.Header>{t}</Text.Header>}
    </F>
    <IntlDocumentTitle titleId="notFound" />
  </FillFlex>
);
