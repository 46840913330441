import { schema } from 'normalizr';
import { evolve, filter } from 'ramda';
import { Dispatch } from 'react-redux';
import { types as layerTypes } from '../actions/layers';
import { types as projectTypes } from '../actions/projects';
import { goToHighlights } from '../actions/sidebar';
import { destroy, get, patch, post } from '../api/rest';
import { AppState } from '../models';
import { getLayerById } from '../selectors/layers';
import { fulfill, pend } from '../utils/promise';
import { Category } from './categories';
import {
  createActionTypes,
  createEntityReducer,
  createEntitySelectors,
  initialState,
  DestroyActionCreator,
} from './createEntityReducer';

const name = 'highlights';

export enum HighlightSubsets {
  All = 'all',
  Enabled = 'enabled',
}

export enum HighlightState {
  Initial = 'initial',
  Processing = 'processing',
  Ready = 'ready',
  Failed = 'failed',
}

export interface HighlightRegion {
  id: string;
  label: string;
  enabled: boolean;
  selected: boolean;
  visible: boolean;
  color: string;
}

export interface Highlight {
  id: string;
  typeId: string;
  metricId: string;
  typeLabel: string;
  label: string;
  enabled: boolean;
  selected: boolean;
  subsets: HighlightSubsets;
  regions: HighlightRegion[];
  visible: boolean;
  state: HighlightState;
  timeInterval: string;
  categories: Category[];
}

export const types = createActionTypes(name);

export const highlightSchema = new schema.Entity(name);

export const fetchHighlight = (id: string) => ({
  type: types.GET,
  payload: get(`/highlights/${id}`),
  meta: { schema: highlightSchema },
});

export const addHighlight = (metricId: string) => (
  dispatch: Dispatch<AppState>,
) => {
  dispatch({
    type: types.CREATE,
    payload: post(`/layers/${metricId}/highlights`),
    meta: { schema: highlightSchema },
  });

  dispatch(goToHighlights());
};

export const calculateHighlight = (id: string) => ({
  type: types.UPDATE,
  payload: post(`/highlights/${id}/calculate`),
  meta: { schema: highlightSchema },
});

export const updateHighlight = (
  id: string,
  updates: Partial<Highlight>,
  optimisticOnly = true,
) => ({
  type: types.UPDATE,
  payload: {
    ...patch(`/highlights/${id}`, updates),
    data: { id, updates },
  },
  meta: { optimistic: optimisticOnly, schema: highlightSchema },
});

export const deleteHighlight: DestroyActionCreator = id => ({
  type: types.DESTROY,
  payload: {
    ...destroy(`/highlights/${id}`),
    data: id,
  },
});

const entityReducer = createEntityReducer(name);

export default (state = initialState, action) => {
  const intermediateState = entityReducer(state, action);

  switch (action.type) {
    case fulfill(projectTypes.GET_PROJECT):
      return {
        ...intermediateState,
        entities: action.payload.entities.highlights || {},
      };

    case pend(layerTypes.DELETE_LAYER):
      return evolve(
        {
          entities: filter(({ metricId }) => metricId !== action.payload.id),
        },
        intermediateState,
      );

    default:
      return intermediateState;
  }
};

export const selectors = createEntitySelectors<Highlight>(name);

export const getHighlightMetricLabel = (state: AppState, id: string) =>
  getLayerById(state, { id: selectors.getEntityById(state, { id }).metricId })
    .label;

export const canCalculate = (appState: AppState, id: string) => {
  const { typeId, state } = selectors.getEntityById(appState, { id });
  return !!typeId && state === HighlightState.Initial;
};
