import { Dispatch } from 'redux';

import { get, destroy, put, post } from '../api/rest';
import { HistogramData, MinMax, AppState } from '../models';
import { LayerStatus } from '../reducers/layers';

import schema from '../schemas/layer';
import { schema as baseLayerSchema } from '../reducers/baseLayers';
import { updateLayersOrder } from './projects';
import { getIsFetching } from '../selectors/project';
import { getLayerById as getLayerByIdFromStore } from '../selectors/layers';

export const GET_LAYER = 'layers/GET_LAYER';
export const DELETE_LAYER = 'layers/DELETE_LAYER';
export const UPDATE_LAYER = 'layers/UPDATE_LAYER';
export const DRAG_HISTOGRAM = 'layers/DRAG_HISTOGRAM';
export const DRAG_HISTOGRAM_START = 'layers/DRAG_HISTOGRAM_START';
export const DRAG_HISTOGRAM_STOP = 'layers/DRAG_HISTOGRAM_STOP';
export const MOVE_LAYER = 'layers/MOVE_LAYER';
export const CREATE_LAYER = 'layers/CREATE_LAYER';
export const COMPOSE_LAYERS = 'layers/COMPOSE_LAYERS';
export const SET_CURRENT_HISTOGRAM = 'layers/SET_CURRENT_HISTOGRAM';
export const SET_INITIAL_HISTOGRAM = 'layers/SET_INITIAL_HISTOGRAM';
export const SET_LAYER_ABSOLUTE_VALUES = 'layers/SET_LAYER_ABSOLUTE_VALUES';
export const GET_BASE_LAYER = 'layers/GET_BASE_LAYER';
export const UPDATE_STATUS = 'layers/UPDATE_STATUS';

export const deleteLayer = (id: string) => dispatch => {
  return dispatch({
    type: DELETE_LAYER,
    payload: {
      ...destroy(`/layers/${id}`),
      data: { id },
    },
  }).then(({ value: { layersOrder, modifiedLayers } }) => {
    if (modifiedLayers) {
      modifiedLayers.forEach(modifiedId => dispatch(getLayerById(modifiedId)));
    }

    return dispatch(updateLayersOrder(layersOrder));
  });
};

export const getLayerById = (id: string) => ({
  type: GET_LAYER,
  payload: get(`/layers/${id}`),
  meta: { schema },
});

export const moveLayer = (id: string, toId: string) => (dispatch, getState) => {
  if (getIsFetching(getState())) return;

  return dispatch({
    type: MOVE_LAYER,
    payload: post(`/layers/${id}/move`, { toId }),
  });
};

export const updateLayer = (id: string, updates) => ({
  type: UPDATE_LAYER,
  payload: {
    ...put(`/layers/${id}`, updates),
    data: { id, updates },
  },
});

export const dragHistogramStart = (layerId: string) => ({
  type: DRAG_HISTOGRAM_START,
  payload: { layerId },
});

export const dragHistogramStop = (layerId: string) => ({
  type: DRAG_HISTOGRAM_STOP,
  payload: { layerId },
});

export const dragHistogram = (layerId: string, histogramWindow: MinMax) => ({
  type: DRAG_HISTOGRAM,
  payload: { layerId, histogramWindow },
});

export const persistLayerHistogram = (layerId: string) => (
  dispatch,
  getState,
) => {
  const { histogramWindow } = getLayerByIdFromStore(getState(), {
    id: layerId,
  });
  return dispatch(updateLayer(layerId, { histogramWindow }));
};

export const createLayer = (projectId: string, newLayer: any) => (
  dispatch: Dispatch<AppState>,
) => ({
  type: CREATE_LAYER,
  payload: post(`/projects/${projectId}/layers`, newLayer),
  meta: {
    schema,
    onSuccess: ({ layersOrder, layer }) => {
      dispatch(updateLayersOrder(layersOrder));
      return layer;
    },
  },
});

export const createRegularLayer = (originId: string) => (
  dispatch,
  getState,
) => {
  const projectId = getState().project.id;

  return dispatch({
    type: CREATE_LAYER,
    payload: post(`/projects/${projectId}/layers`, {
      type: 'regular',
      originId,
    }),
    meta: {
      schema,
      onSuccess: ({ layersOrder, layer }) => {
        dispatch(updateLayersOrder(layersOrder));
        return layer;
      },
    },
  });
};

export const composeLayers = (operand1: string, operand2: string) => (
  dispatch,
  getState,
) => {
  const projectId = getState().project.id;

  return dispatch({
    type: COMPOSE_LAYERS,
    payload: post(`/projects/${projectId}/layers`, {
      type: 'composite',
      operands: [operand1, operand2],
      compositionType: 'multiplication',
    }),
    meta: {
      schema,
      onSuccess: ({ layersOrder, layer }) => {
        dispatch(updateLayersOrder(layersOrder));
        return layer;
      },
    },
  });
};

export const setCurrentHistogram = (
  id: string,
  histogram: HistogramData[],
) => ({
  type: SET_CURRENT_HISTOGRAM,
  payload: { id, histogram },
});

export const setInitialHistogram = (
  id: string,
  histogram: HistogramData[],
) => ({
  type: SET_INITIAL_HISTOGRAM,
  payload: { id, histogram },
});

export const setAbsoluteValues = (id: string, absoluteValues: MinMax) => ({
  type: SET_LAYER_ABSOLUTE_VALUES,
  payload: { id, absoluteValues },
});

// FIXME remove in favour of reducers/baseLayers
export const getBaseLayer = (id: string) => ({
  type: GET_BASE_LAYER,
  payload: get(`/base_layers/${id}`),
  meta: { schema: baseLayerSchema, id },
});

interface LayerStatusUpdate {
  layerId: string;
  loadingProgress: number;
  loadingStatus: LayerStatus;
}

export const updateStatus = (info: LayerStatusUpdate) => ({
  type: UPDATE_STATUS,
  payload: info,
});

export const types = {
  GET_LAYER,
  DELETE_LAYER,
  UPDATE_LAYER,
  DRAG_HISTOGRAM,
  DRAG_HISTOGRAM_START,
  DRAG_HISTOGRAM_STOP,
  MOVE_LAYER,
  CREATE_LAYER,
  SET_CURRENT_HISTOGRAM,
  SET_INITIAL_HISTOGRAM,
  COMPOSE_LAYERS,
  SET_LAYER_ABSOLUTE_VALUES,
  UPDATE_STATUS,
};
