import React from 'react';
import styled from 'styled-components';
import { colors } from '@hm/ukie';

import VoidHistogramFull from './VoidHistogramFull';
import VoidHistogramCompact from './VoidHistogramCompact';
import HistogramSeparator from './HistogramSeparator';

interface Props {
  value: number;
  width: number;
  dataWidth: number;
  dataHeight: number;
  height: number;
  isCompact: boolean;
  color: string;
  label?: string;
  transform?: string;
}

const VoidLabel = styled.text`
  fill: ${colors.primary};
  font-weight: 600;
  font-size: 10px;
  text-anchor: middle;
`;

export default class VoidHistogram extends React.Component<Props, never> {
  render() {
    const { width, height, isCompact, label, color } = this.props;
    const commonProps = { width, height, color };

    return (
      <g transform={this.props.transform}>
        <VoidLabel
          children="void"
          dy="1.5em"
          transform={`translate(${width / 2},${height})`}
        />
        {isCompact ? (
          <VoidHistogramCompact {...commonProps} />
        ) : (
          <VoidHistogramFull
            {...commonProps}
            dataWidth={this.props.dataWidth}
            dataHeight={this.props.dataHeight}
            value={this.props.value}
          />
        )}
        {label ? <HistogramSeparator text={label} height={height} /> : null}
      </g>
    );
  }
}
