import { createSelector } from 'reselect';
import { mapObjIndexed } from 'ramda';
import { getSelectedTool } from './tool';
import { getFirstObject, getSelectedObjects } from './selectedObjects';
import { getDefaultObjectParamsForCurrentTool } from './defaultObjectParams';
import { creatorTools } from '../constants/tools';
import { AppState } from '../models';

export const getIsMinimized = (state: AppState) => state.inspector.isMinimized;
export const getIsMenuOpen = (state: AppState) => state.inspector.isMenuOpen;
export const getIsTruncated = (state: AppState) => state.inspector.isTruncated;
export const getShowMore = (state: AppState) => state.inspector.showMore;

export const shouldShow = createSelector(
  getSelectedObjects,
  getSelectedTool,
  (objects, tool) => creatorTools.includes(tool) || objects.length > 0,
);

export const getType = createSelector(
  getSelectedObjects,
  getSelectedTool,
  (objects, tool) =>
    objects.length > 1
      ? 'Multi'
      : objects.length === 1 ? objects[0].type : tool,
);

// TODO: make more declarative
const filterCommon = (a: any, b: any) =>
  mapObjIndexed((value, key) => (value === b[key] ? value : undefined))(a);

export const getParams = createSelector(
  getSelectedObjects,
  getDefaultObjectParamsForCurrentTool,
  (objects, defaults) =>
    objects.length > 0 ? objects.reduce(filterCommon, objects[0]) : defaults,
);

export const isDraft = createSelector(
  getFirstObject,
  obj => (obj ? obj.draft : false),
);
