import { any } from 'ramda';
import { createSelector } from 'reselect';
import { getLayerById } from './layers';
import { AppState, Effect } from '../models';

export const getEntities = (state: AppState) => state.effects.entities;
export const getEffectById = (state: AppState, { id }: { id: string }) =>
  state.effects.entities[id];

export const makeGetEffectsByLayerId = () =>
  createSelector(getEntities, getLayerById, (entities, metric) =>
    metric.effects.reduce((acc, id) => {
      return entities[id] ? acc.concat(entities[id]) : acc;
    }, []),
  );

const isEnabledVoid = (effect: Effect) =>
  effect.type === 'void' && effect.enabled;

export const makeHasLayerEnabledVoid = () =>
  createSelector(makeGetEffectsByLayerId(), any(isEnabledVoid));
