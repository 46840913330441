import { connect } from 'react-redux';
import { Status } from '../../components/Calendar';
import {
  getCalendarLabel,
  getSliceDuration,
  getSelectedIntervals,
} from '../../selectors/calendar';
import { AppState } from '../../models';

const mapStateToProps = (state: AppState) => ({
  title: getCalendarLabel(state),
  sliceDuration: getSliceDuration(state),
  selectedIntervals: getSelectedIntervals(state),
});

export default connect(mapStateToProps)(Status);
