import React from 'react';
import Overlay from 'react-overlays/lib/Overlay';
import { Flex, Box } from 'grid-styled';
import {
  FormattedMessage as F,
  injectIntl,
  InjectedIntlProps,
} from 'react-intl';
import { isEmpty } from 'ramda';

import {
  Card,
  IconButton,
  Input,
  Userpic,
  DropdownContainer,
  DropdownItem,
  Text,
  Separator,
} from '@hm/ukie';

import Track from '../Track';
import Modal from '../Modal';
import { isApproximated, isEditor } from '../../utils/helpers';
import HumanizedIntervals from '../HumanizedIntervals';
import SliceCount from '../SliceCount';
import messages from './messages';
import { ProjectPreview } from '../../reducers/projects';
import { approximatedNewMetricMessages } from '../ApproximatedNewMetric';
import CopyDialog from '../CopyDialog/CopyDialog';
import FeatureToggle from '../FeatureToggle';

export interface OwnProps {
  selected?: boolean;
  className?: string;
  onHide?(id: string): void;
  onClick?(): void;
  onDoubleClick?(): void;
  project: ProjectPreview;
  onRename?(id: string, label: string): void;
  onDuplicate?(id: string, label: string): Promise<any>;
  onDelete?(id: string): void;
}

type Props = OwnProps & InjectedIntlProps;

interface State {
  showMenuButton: boolean;
  showMenu: boolean;
  showCopy: boolean;
}

export class ProjectCard extends React.Component<Props, State> {
  menuButton: any;

  state = {
    showMenuButton: false,
    showMenu: false,
    showCopy: false,
  };

  onHide = () => this.setState({ showMenu: false, showCopy: false });

  onToggle = () => this.setState({ showMenu: !this.state.showMenu });

  onDuplicate = (id: string, label: string) =>
    this.props
      .onDuplicate(id, label)
      .then(() => this.setState({ showCopy: false }));

  render() {
    const {
      project,
      intl,
      className,
      onDelete,
      selected,
      onHide,
      onRename,
      onClick,
      onDoubleClick,
    } = this.props;

    const { showMenu } = this.state;
    const { id } = project;

    const canEdit =
      !project.readOnly &&
      (isEditor(project.currentAccessRole) ||
        (project.isAdmin && !project.currentlyOpenedBy));

    return (
      <Card
        selected={selected}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        className={className}
        hover={{ boxShadow: 2 }}
        style={{ lineHeight: '24px' }}
        m={0}
        onMouseEnter={() => this.setState({ showMenuButton: true })}
        onMouseLeave={() => this.setState({ showMenuButton: false })}
      >
        <Flex mb={2}>
          <Box width={216} mr={1}>
            {canEdit ? (
              <Input
                titleFont
                truncate
                value={project.label}
                onChange={e => onRename(id, e.currentTarget.value)}
              />
            ) : (
              <Text.Important>{project.label}</Text.Important>
            )}
          </Box>
          {this.state.showMenuButton || this.state.showMenu ? (
            <IconButton
              icon="burgerSlim"
              ref={el => (this.menuButton = el)}
              onClick={this.onToggle}
            />
          ) : null}
        </Flex>
        <Userpic
          name={project.owner.name}
          image={project.owner.avatarUrl}
          color={`#${project.owner.defaultColor}`}
        />

        {!isEmpty(project.editors) ? (
          <Flex>
            {project.editors.map(editor => (
              <Userpic
                key={editor.id}
                name={editor.name}
                color={`#${editor.defaultColor}`}
              />
            ))}
          </Flex>
        ) : null}

        <Box my={2}>
          <Box title={intl.formatMessage(messages.layerCountTooltip)}>
            <F
              {...messages.layerCount}
              values={{ count: project.layerCount }}
            />
          </Box>
          <Box title={intl.formatMessage(messages.selectedIntervalToolTip)}>
            <HumanizedIntervals
              intervals={project.filteredTimeIntervals}
              sliceDuration={project.sliceDuration}
            />
          </Box>
          <Box title={intl.formatMessage(messages.sliceCount)}>
            <SliceCount
              intervals={project.filteredTimeIntervals}
              sliceDuration={project.sliceDuration}
            />
          </Box>
          <Box title={intl.formatMessage(messages.type)}>
            <F {...messages[project.type]} />
          </Box>

          {isApproximated(project) ? (
            <Box title={intl.formatMessage(messages.approximationType)}>
              <F
                {...approximatedNewMetricMessages[project.approximationType]}
              />
            </Box>
          ) : null}
        </Box>

        <Box my={2}>
          <Box title={intl.formatMessage(messages.createdAt)}>
            {project.createdAt.format('D MMMM YYYY')}
          </Box>
        </Box>

        <Separator />

        <Box mt={15}>
          {project.currentlyOpenedBy ? (
            <Box mb={1}>
              <Userpic
                name={project.currentlyOpenedBy.name}
                image={project.currentlyOpenedBy.avatarUrl}
                color={`#${project.currentlyOpenedBy.defaultColor}`}
              />
            </Box>
          ) : null}

          <Box>
            <F
              {...messages.lastUpdate}
              values={{ updatedAt: project.updatedAt.fromNow() }}
            />
          </Box>
        </Box>
        <Overlay
          show={showMenu}
          placement="bottom"
          onHide={this.onHide}
          target={this.menuButton}
          rootClose
        >
          <DropdownContainer>
            <div className="dropdown-menu">
              {canEdit ? (
                <FeatureToggle feature="hideProject">
                  <DropdownItem onClick={() => onHide(id)}>
                    Hide project
                  </DropdownItem>
                </FeatureToggle>
              ) : null}
              <DropdownItem
                onClick={() =>
                  this.setState({ showCopy: true, showMenu: false })
                }
              >
                Save as…
              </DropdownItem>

              {canEdit ? <div className="dropdown-menu__delimiter" /> : null}

              {canEdit ? (
                <Track
                  data-metrics-event-name="projectDelete"
                  data-metrics-category="Project"
                >
                  <DropdownItem onClick={() => onDelete(id)}>
                    Remove project
                  </DropdownItem>
                </Track>
              ) : null}
            </div>
          </DropdownContainer>
        </Overlay>
        <Modal show={this.state.showCopy} onHide={this.onHide}>
          <CopyDialog
            id={project.id}
            onHide={this.onHide}
            onConfirm={this.onDuplicate}
            currentLabel={project.label}
          />
        </Modal>
      </Card>
    );
  }
}

export default injectIntl(ProjectCard);
