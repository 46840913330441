import { createSelector } from 'reselect';
import { values } from 'ramda';
import mapValues from 'lodash.mapvalues';
import { Gradient } from '../reducers/gradients';
import { AppState } from '../models';

export const getEntities = (state: AppState) => state.gradients;
export const getGradientsList = createSelector(
  (state: any) => state.gradients,
  gradients => values(gradients),
);

export const makeGetGradientByLayerId = () =>
  createSelector(
    getEntities,
    // XXX: makes webpack don’t whine about circular deps
    // getLayerById,
    (state, { id }) => state.layers.entities[id],
    (gradients, layer) => gradients[layer.gradientId],
  );

export const getGradientColors = createSelector(getEntities, gradients =>
  mapValues(gradients, (g: Gradient) => g.points.map(p => p.color)),
);
