import React from 'react';
import { Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';

const InvalidToken: React.SFC = props => (
  <Box my={3}>
    <F id="PasswordReset.invalidToken" defaultMessage="The link is invalid" />
  </Box>
);

export default InvalidToken;
