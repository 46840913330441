import { get } from '../api/rest';
import { createActionTypes } from '../reducers/createEntityReducer';
import { usersSchema as schema } from '../schemas/users';

export const types = createActionTypes('users');

export const fetchUsers = () => ({
  type: types.LIST,
  payload: get('/users'),
  meta: { schema },
});
