import styled from 'styled-components';
import { typography } from '@hm/ukie';

const Title = styled.div`
  font-size: ${typography.title};
  font-weight: 600;
  margin: 20px 0;

  &:first-child {
    margin-top: 0;
  }
`;

export const SubTitle = Title.extend`
  font-weight: 400;
`;

export const Lead = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: 20px 0;
`;

export const SemiBold = styled.span`
  font-weight: 600;
`;

export default Title;
