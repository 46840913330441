import { pluck } from 'ramda';
import { createSelector } from 'reselect';
import { AppState } from '../models';

export const getSelectedFragments = (state: AppState) =>
  Object.values(state.selectedFragments);

export const getSelectedFragmentIds = createSelector(
  getSelectedFragments,
  pluck('id'),
);
