import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { connect } from 'react-redux';
import { WhiteButton, Text, Box, Flex } from '@hm/ukie';
import { isNil, isEmpty } from 'ramda';

import {
  getFilteredEnvironments,
  getGroupedProjects,
} from '../../reducers/explorer';
import { getSelectedEnvironmentId } from '../../selectors/environments';
import { Environment } from '../../reducers/environments';
import { ProjectPreview } from '../../reducers/projects';
import {
  deleteProject,
  renameProject,
  cloneProject,
  goToProject,
} from '../../actions/projects';
import { AppState } from '../../models';

import Tiles from './Tiles';
import Empty from './Empty';
import Track from '../Track';
import ProjectCard from '../ProjectCard';
import Collapsible from '../Collapsible';
import EnvironmentTitle from './EnvironmentTitle';

interface Props {
  selectedProjectId: string;
  onAdd(id: string): void;
  onSelect(id: string): void;
}

interface ProvidedProps {
  selectedEnvironmentId: string;
  environments: Environment[];
  projects: Dict<ProjectPreview[]>;
  onOpen(id: string): void;
  onRename(id: string, label: string): void;
  onDuplicate(id: string, label: string): Promise<any>;
  onDelete(id: string): void;
}

const NewProjectButton = ({ onClick }) => (
  <Track
    data-metrics-category="Explorer"
    data-metrics-event-name="projectCreate"
  >
    <WhiteButton icon="plus" onClick={onClick}>
      <F id="Explorer.newProject" defaultMessage="Create new project" />
    </WhiteButton>
  </Track>
);

const Projects: React.SFC<Props & ProvidedProps> = ({
  environments,
  projects,
  onAdd,
  onSelect,
  onOpen,
  onRename,
  onDelete,
  onDuplicate,
  selectedProjectId,
  selectedEnvironmentId,
}) =>
  isEmpty(environments) ? (
    <Flex flexDirection="column" height="100%">
      <Text fontSize={3} fontWeight="semibold" m="auto" color="grey7">
        <span>There is no data in Chronotope</span>
      </Text>
    </Flex>
  ) : (
    <>
      {selectedEnvironmentId ? (
        <>
          <Text fontSize={3} mb={2} is="div">
            {environments.find(({ id }) => id === selectedEnvironmentId).name}
          </Text>
          <NewProjectButton onClick={() => onAdd(environments[0].id)} />

          {projects[selectedEnvironmentId] ? (
            <Tiles
              tiles={projects[selectedEnvironmentId]}
              collectionId={selectedEnvironmentId}
              renderTile={project => (
                <ProjectCard
                  project={project}
                  selected={selectedProjectId === project.id}
                  onClick={() => onSelect(project.id)}
                  onDoubleClick={() => onOpen(project.id)}
                  onRename={onRename}
                  onDuplicate={onDuplicate}
                  onDelete={onDelete}
                />
              )}
            />
          ) : (
            <Flex flexDirection="column" height="100%">
              <Text fontSize={3} fontWeight="semibold" m="auto" color="grey7">
                <Empty />
              </Text>
            </Flex>
          )}
        </>
      ) : (
        environments.map((environment, i) => (
          <Box mb={3} key={environment.id}>
            <Collapsible
              isOpen={i === 0}
              title={onClick => (
                <Flex>
                  <EnvironmentTitle
                    name={environment.name}
                    length={
                      projects[environment.id]
                        ? projects[environment.id].length
                        : 0
                    }
                    onClick={onClick}
                  />
                  <NewProjectButton onClick={() => onAdd(environment.id)} />
                </Flex>
              )}
            >
              {!isNil(projects[environment.id]) ? (
                <Tiles
                  tiles={projects[environment.id]}
                  renderTile={(project: ProjectPreview) => (
                    <ProjectCard
                      project={project}
                      selected={selectedProjectId === project.id}
                      onClick={() => onSelect(project.id)}
                      onDoubleClick={() => onOpen(project.id)}
                      onRename={onRename}
                      onDuplicate={onDuplicate}
                      onDelete={onDelete}
                    />
                  )}
                />
              ) : (
                <Text
                  is="div"
                  color="grey7"
                  mx="auto"
                  mt={1}
                  textAlign="center"
                >
                  <Empty />
                </Text>
              )}
            </Collapsible>
          </Box>
        ))
      )}
    </>
  );

export default connect(
  (state: AppState, ownProps: Props) => ({
    selectedEnvironmentId: getSelectedEnvironmentId(state),
    // FIXME: move to selector
    environments: getFilteredEnvironments(
      state,
      getSelectedEnvironmentId(state),
    ),
    projects: getGroupedProjects(state),
  }),
  {
    onOpen: goToProject,
    onRename: renameProject,
    onDelete: deleteProject,
    onDuplicate: cloneProject,
  },
)(Projects);
