import { takeEvery } from 'redux-saga/effects';
import { types } from '../../actions/projects';
import { EventSender } from './getEventSender';

export default function*(sendEvent: EventSender) {
  yield takeEvery(types.VERTICAL_SCALING_DRAG, drag, sendEvent);
  yield takeEvery(types.VERTICAL_SCALING_DRAG_START, startDrag, sendEvent);
  yield takeEvery(types.VERTICAL_SCALING_DRAG_STOP, stopDrag, sendEvent);
}

function* drag(sendEvent: EventSender, action: any) {
  const { payload: verticalScaling } = action;
  yield sendEvent('verticalScalingDrag', { verticalScaling });
}

function* startDrag(sendEvent: EventSender) {
  yield sendEvent('verticalScalingDragStart');
}

function* stopDrag(sendEvent: EventSender) {
  yield sendEvent('verticalScalingDragStop');
}
