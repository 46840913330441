import { defineMessages } from 'react-intl';

export default defineMessages({
  access: {
    id: 'Explorer/Environments.access',
    defaultMessage: 'Access',
  },
  parameters: {
    id: 'Explorer/Environments.parameters',
    defaultMessage: 'Parameters',
  },
  description: {
    id: 'Explorer/Environments.description',
    defaultMessage: 'Description',
  },
  accessStatus: {
    id: 'Explorer/Environments.startTime',
    defaultMessage: 'Access status',
  },
  owner: {
    id: 'Explorer/Environments.owner',
    defaultMessage: 'Owner',
  },
  canEdit: {
    id: 'Explorer/Environments.canEdit',
    defaultMessage: 'Can edit',
  },
  canView: {
    id: 'Explorer/Environments.canView',
    defaultMessage: 'Can view',
  },
  editors: {
    id: 'Explorer/Environments.editors',
    defaultMessage: 'Editors',
  },
  boundingBox: {
    id: 'Explorer/Environments.boundingBox',
    defaultMessage: 'Bounding box',
  },
  area: {
    id: 'Explorer/Environments.area',
    defaultMessage: 'Area',
  },
  country: {
    id: 'Explorer/Environments.country',
    defaultMessage: 'Country',
  },
  timezone: {
    id: 'Explorer/Environments.timezone',
    defaultMessage: 'Timezone',
  },
  cellSize: {
    id: 'Explorer/Environments.cellSize',
    defaultMessage: 'Cell size',
  },
  resolution: {
    id: 'Explorer/Environments.resolution',
    defaultMessage: 'Resolution',
  },
  amountOfLayers: {
    id: 'Explorer/Environments.amountOfMetrics',
    defaultMessage: 'Amount of Metrics',
  },
  totalTimeInterval: {
    id: 'Explorer/Environments.totalTimeInterval',
    defaultMessage: 'Total time interval',
  },
  layersSliceDurations: {
    id: 'Explorer/Environments.metricsSliceDurations',
    defaultMessage: 'Metrics’ slice durations',
  },
  dateOfCreation: {
    id: 'Explorer/Environments.dateOfCreation',
    defaultMessage: 'Date of creation',
  },
});
