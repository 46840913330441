import { createSelector } from 'reselect';
import { values, prop, omit } from 'ramda';
import { getEnvironmentById } from './environments';
import { AppState } from '../models';

export const getEntities = (state: AppState) => state.users.entities;

export const getUsers = createSelector(getEntities, values);

export const getPotentialEditors = createSelector(
  getEnvironmentById,
  getEntities,
  (env, users) =>
    values(omit(env.editors.map(e => String(prop('id', e))), users)),
);
