import React from 'react';
import { ScaleLinear } from 'd3-scale';
import { Point } from '../../reducers/gradients';
import { HistogramData, MinMax } from '../../models';

import CompactHistogram from './FilledHistogramCompact';
import FullHistogram from './FilledHistogramFull';
import HistogramSeparator from './HistogramSeparator';
import Ticks from './Ticks';

const TICK_POSITIONS = [0, 32, 64, 96, 128, 160, 192, 224, 255];

interface Props {
  width: number;
  height: number;
  isCompact: boolean;
  currentBins: HistogramData[];
  initialBins: HistogramData[];
  borders: number[];
  gradientPoints: Point[];
  selected: MinMax;
  label?: string;
  transform?: string;
  yScale: ScaleLinear<number, number>;
  onBrush?(extent: MinMax);
  onBrushStart?();
  onBrushEnd?();
}

export default class FilledHistogram extends React.Component<Props, never> {
  brushLabels: ClientRect[] = [];

  // XXX: We need to hide ticks that are overlapped by brush labels. To do this
  // we fetch ClientRects from Brush and then store it. This bounds then
  // passed to Ticks component which decides which tick to show.
  saveBrushLabelPositions = (brushLabels: ClientRect[]) => {
    this.brushLabels = brushLabels;
  };

  render() {
    const { height, width, isCompact, label } = this.props;

    const commonProps = {
      width,
      height,
      borders: this.props.borders,
      gradientPoints: this.props.gradientPoints,
      selected: this.props.selected,
      currentBins: this.props.currentBins,
      onBrush: this.props.onBrush,
      onBrushStart: this.props.onBrushStart,
      onBrushEnd: this.props.onBrushEnd,
      saveBrushLabelPositions: this.saveBrushLabelPositions,
    };

    return (
      <g transform={this.props.transform}>
        <Ticks
          ticks={TICK_POSITIONS}
          binBorders={this.props.borders}
          width={width}
          overlapWith={this.brushLabels}
          transform={`translate(0,${height})`}
        />
        {isCompact ? (
          <CompactHistogram {...commonProps} />
        ) : (
          <FullHistogram
            initialBins={this.props.initialBins}
            yScale={this.props.yScale}
            ticks={TICK_POSITIONS}
            {...commonProps}
          />
        )}
        {label ? <HistogramSeparator text={label} height={height} /> : null}
      </g>
    );
  }
}
