import React from 'react';
import styled from 'styled-components';

const HEIGHT = 14;

const Ruler = styled.line`
  stroke-width: 1;
  stroke: ${({ theme }) => theme.colors.grey5};
  shape-rendering: crispEdges;
`;

const Label = styled.div`
  font-size: 10px;
  line-height: 1.15em;
  width: fit-content;
  font-weight: 600;
  color: white;
  background: ${({ theme }) => theme.colors.grey5};
  padding: 0 2px 1px 2px;
`;

interface Props {
  text: string;
  height: number;
  transform?: string;
}

const HistogramSeparator: React.SFC<Props> = props => {
  const { text, transform, height } = props;

  return (
    <g transform={transform}>
      <foreignObject
        width={100}
        height={HEIGHT}
        transform={`translate(0,${-HEIGHT})`}
      >
        <Label>{text}</Label>
      </foreignObject>
      <Ruler y2={height + HEIGHT} transform={`translate(0,${-HEIGHT})`} />
    </g>
  );
};

export default HistogramSeparator;
