import { connect } from 'react-redux';
import { deleteObject } from '../../actions/objects';

import {
  toggleMinimize,
  toggleMenu,
  toggleTruncation,
  exportObjectInfo,
} from '../../actions/inspector';

import {
  getIsMinimized,
  getIsMenuOpen,
  getIsTruncated,
} from '../../selectors/inspector';
import { getSelectedObjects } from '../../selectors/selectedObjects';
import { AppState } from '../../models';

import WindowButtons from './WindowButtons';

const mapStateToProps = (state: AppState) => ({
  objects: getSelectedObjects(state),
  isMinimized: getIsMinimized(state),
  isMenuOpen: getIsMenuOpen(state),
  isTruncated: getIsTruncated(state),
});

const mapDispatchToProps = {
  deleteObject,
  toggleMinimize,
  toggleMenu,
  toggleTruncation,
  onExport: exportObjectInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(WindowButtons);
