import React from 'react';
import { Route } from 'react-router';
import { last, compose, filter, split, cond, equals, test } from 'ramda';
import { Flex } from 'grid-styled';
import { Footer, Tabs, TabItem, Spinner } from '@hm/ukie';
import { FormattedMessage as F } from 'react-intl';
import { Switch, RouteComponentProps } from 'react-router-dom';

import Payment from '../Payment';
import NotFound from '../NotFound';
import GlobalAlert from '../GlobalAlert';
import Account from './Account';
import PlanContainer from '../Plan';
import PlainLink from '../PlainLink';
import IntlDocumentTitle from '../IntlDocumentTitle';
import RouteIf from '../RouteIf';
import { CurrentUser, PasswordUpdate, EmailUpdate } from '../../models';
import { GlobalAlerts } from '../../reducers/alertStack';

// ballsy
// FIXME 👀  maybe use NavLink
const getTab = compose<string, string[], string[], string, string>(
  cond([
    [test(/(profile|email)/), () => 'profile'],
    [equals('plan'), () => 'plan'],
    [equals('payment'), () => 'payment'],
  ]),
  last,
  filter(Boolean),
  split('/'),
);

export interface ProvidedProps {
  currentUser: CurrentUser;
  userBillable: boolean;
  billingFetched: boolean;
  hasErrors: boolean;
  onUserEdit(user: Partial<CurrentUser>): void;
  onPasswordUpdate(form: PasswordUpdate): Promise<any>;
  onEmailUpdate(form: EmailUpdate): Promise<any>;
}

export type Props = ProvidedProps & RouteComponentProps<any>;

const Profile: React.SFC<Props> = ({
  location,
  userBillable,
  currentUser,
  onUserEdit,
  onPasswordUpdate,
  billingFetched,
  onEmailUpdate,
  hasErrors,
}) => {
  const tab = getTab(location.pathname);
  if (!tab) return <NotFound />;

  return (
    <Flex flexDirection="column" style={{ height: '100%' }}>
      <Flex flex="1 0 40px" is="nav" justify="flex-end" flexDirection="column">
        {userBillable ? (
          <Tabs selected={getTab(location.pathname)} centered>
            <TabItem value="profile">
              <F id="Profile.account" defaultMessage="Account">
                {text => <PlainLink to={`/profile`}>{text}</PlainLink>}
              </F>
            </TabItem>
            <TabItem value="plan">
              <F id="Profile.planTab" defaultMessage="Plan">
                {text => <PlainLink to={'/profile/plan'}>{text}</PlainLink>}
              </F>
            </TabItem>
            <TabItem value="payment">
              <F id="Profile.paymentTab" defaultMessage="Payment">
                {text => <PlainLink to={'/profile/payment'}>{text}</PlainLink>}
              </F>
            </TabItem>
          </Tabs>
        ) : null}
      </Flex>

      <Flex
        flex="1 1 100%"
        pt={3}
        width="100%"
        style={{ overflowY: 'auto' }}
        justify="center"
      >
        <Switch>
          <RouteIf
            conditions={[userBillable, billingFetched]}
            fallbacks={[
              <NotFound key="not-found" />,
              <Spinner key="spinner" />,
            ]}
            path={'/profile/plan'}
            render={props =>
              billingFetched ? <PlanContainer {...props} /> : <Spinner />
            }
          />
          <RouteIf
            conditions={[userBillable, billingFetched]}
            fallbacks={[
              <NotFound key="not-found" />,
              <Spinner key="spinner" />,
            ]}
            path={'/profile/payment'}
            component={Payment}
          />
          <Route
            path={'/profile'}
            render={props => (
              <Account
                user={currentUser}
                onUserEdit={onUserEdit}
                onPasswordUpdate={onPasswordUpdate}
                onEmailUpdate={onEmailUpdate}
                {...props}
              />
            )}
          />
        </Switch>
      </Flex>

      {hasErrors ? (
        <Footer
          warning={
            <GlobalAlert
              selected={[
                GlobalAlerts.InactiveWithoutPayment,
                GlobalAlerts.InactiveWithPayment,
                GlobalAlerts.InvoiceError,
                GlobalAlerts.NoHoursLeft,
              ]}
            />
          }
        />
      ) : null}

      <IntlDocumentTitle titleId="profile" />
    </Flex>
  );
};

export default Profile;
