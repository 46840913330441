import { Scrollable, WhiteButton } from '@hm/ukie';
import React from 'react';
import { Flex, Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import Metric from '../Metric';
import Track from '../Track';

interface Props {
  layerIds: string[];
  canAddLayer: boolean;
  onLayerAdd(): void;
}

const MetricPanel: React.SFC<Props> = ({
  layerIds,
  onLayerAdd,
  canAddLayer,
}) => (
  <Flex bg="grey2" flex={1} flexDirection="column">
    <Box m={2} flex="none">
      <Track data-metrics-event-name="metricAdd" data-metrics-category="Metric">
        <WhiteButton icon="plus" onClick={onLayerAdd} disabled={!canAddLayer}>
          <F id="MetricPanel.add" defaultMessage="Add Metric" />
        </WhiteButton>
      </Track>
    </Box>

    <Box flex={1}>
      <Scrollable style={{ height: '100%' }}>
        <Box pb={2} px={2}>
          {layerIds.map(id => <Metric id={id} key={id} />)}
        </Box>
      </Scrollable>
    </Box>
  </Flex>
);

MetricPanel.defaultProps = {
  canAddLayer: true,
  layerIds: [],
};

export default MetricPanel;
