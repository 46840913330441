import { connect } from 'react-redux';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { hideModal } from '../actions/modals';
import { confirmDeleteProject } from '../actions/projects';
import { AppState } from '../models';

// TODO: this should be more fluid with sagas:
// every action can be confirmed with modal. To make action confirmable we add
// some meta info to action. After dispatch this action is catched by saga
// which dispatches action to show dialog. After user clicks on confirmation
// button saga continues, dispatches needed method and after everything is done
// closes modal. This will allow us to dispatch any confirmation action from
// anywhere without thinking about who needs to hold 'show' state and without
// dealing with promises in components
// (e.g. this.props.onClick().then(() => this.props.onHide()))
export default connect<any, any, any>(
  (state: AppState) => ({
    title: 'Delete this project?',
    confirmTitle: 'Delete',
  }),
  {
    onCancel: hideModal,
    onConfirm: confirmDeleteProject,
  },
)(ConfirmationDialog);
