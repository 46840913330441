import React from 'react';
import styled from 'styled-components';
import { Moment, Duration } from 'moment';
import { colors } from '@hm/ukie';
import { getTooltipLabel, getApproximatedLabel } from './labels';
import { ApproximationType } from '../../reducers/currentProject';

const FIRST_LINE = 1.5;
const LINE_HEIGHT = 1.2;

const HandleText = styled.text`
  font-size: 10px;
  font-weight: 600;
  fill: ${colors.primary};
`;

interface Props extends React.SVGAttributes<SVGGElement> {
  slice: Moment;
  sliceDuration: Duration;
  approximationType: ApproximationType;
  getRef?(ref: SVGTextElement);
}

const HandleLabel: React.SFC<Props> = props => {
  const {
    slice,
    sliceDuration,
    approximationType,
    getRef,
    children,
    ...rest
  } = props;

  const labelLines = approximationType
    ? getApproximatedLabel(slice, approximationType)
    : getTooltipLabel(slice, sliceDuration);

  return (
    <g {...rest}>
      {labelLines.map((line, i) => (
        <HandleText
          key={line}
          dy={`${FIRST_LINE + LINE_HEIGHT * i}em`}
          textAnchor="middle"
          innerRef={getRef}
        >
          {line}
        </HandleText>
      ))}
    </g>
  );
};

export default HandleLabel;
