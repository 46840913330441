import {
  takeLatest,
  select,
  put,
  all,
  fork,
  race,
  take,
} from 'redux-saga/effects';
import { head, last, isEmpty } from 'ramda';
import { DateRange } from 'moment-range';

import { getSelectedEnvironment } from '../../selectors/environments';
import { toISOInterval, parseIntervalWithTZ } from '../../utils/time';
import { toAPIFilters } from '../../utils/helpers';

import {
  getSliceDuration,
  getTimeFilters,
  getAvailableIntervals,
  getZoomLevel,
} from '../../selectors/calendar';

import {
  getFilteredOutIntervals,
  setFilteredOutIntervals,
  types,
  setFilterPreivew,
} from '../../actions/calendar';

export default function*() {
  yield all([fork(filteredOutIntervals), fork(filterPreview)]);
}

function* filteredOutIntervals() {
  yield takeLatest(
    [
      types.SET_FILTER_SETTINGS,
      types.SELECT_SLICE_DURATION,
      types.DAY_RANGE_FILTER_SELECTION_END,
      types.INIT_EDIT_PROJECT,
    ],
    fetchFilteredOutIntervals,
  );
}

function* fetchFilteredOutIntervals(action: any) {
  if (action.type === types.INIT_EDIT_PROJECT) {
    yield take(types.INIT);
  }

  const { id, timezone } = yield select(getSelectedEnvironment);
  const selectedSettings: Dict<any[]> = yield select(getTimeFilters);
  const availableIntervals: DateRange[] = yield select(getAvailableIntervals);

  if (isEmpty(selectedSettings) || isEmpty(availableIntervals)) return false;

  const interval = toISOInterval(
    new DateRange(head(availableIntervals).start, last(availableIntervals).end),
  );

  const response = yield put.resolve(
    getFilteredOutIntervals(
      id,
      interval,
      yield select(getSliceDuration),
      toAPIFilters(selectedSettings),
    ),
  );

  yield put(
    setFilteredOutIntervals(
      response.value.filteredOutIntervals.map(i =>
        parseIntervalWithTZ(i, timezone),
      ),
    ),
  );
}

function* filterPreview() {
  yield takeLatest(types.FILTER_HOVER, function*(action: any) {
    const zoomLevel = yield select(getZoomLevel);
    const sliceDuration = yield select(getSliceDuration);
    const { type, setting } = action.payload;

    // certain filters make no sense at these zoom levels
    if (
      (zoomLevel === 'year' || zoomLevel === 'month') &&
      (type === 'timeIntervals' || type === 'timesOfDay')
    )
      return false;

    const availableIntervals: DateRange[] = yield select(getAvailableIntervals);
    const { timezone, id } = yield select(getSelectedEnvironment);

    const interval = toISOInterval(
      new DateRange(
        head(availableIntervals).start,
        last(availableIntervals).end,
      ),
    );

    const { response } = yield race({
      response: put.resolve(
        getFilteredOutIntervals(id, interval, sliceDuration, [
          { type, settings: [setting] },
        ]),
      ),
      cancel: take(types.FILTER_LEAVE),
    });

    if (response) {
      yield put(
        setFilterPreivew(
          response.value.filteredOutIntervals.map(i =>
            parseIntervalWithTZ(i, timezone),
          ),
        ),
      );
    }
  });
}
