import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { findLastIndex } from 'ramda';
import { Flex } from 'grid-styled';
import { List } from 'react-virtualized';

import { computeSelectorDates } from '../../selectors/calendar';
import Selector from '../../containers/Calendar/Selector';
import Navigator from '../../containers/Calendar/Navigator';
import { AppState } from '../../models';

interface Props {
  selectorDates: moment.Moment[];
}

interface State {
  rowHeight: number;
}

class Scrollers extends React.Component<Props, State> {
  selector: List;
  scrollable: any;

  state = { rowHeight: 0 };

  setRowHeight = (rowHeight: number) => {
    this.setState({ rowHeight });
  };

  onDateClick = (date: moment.Moment, innerRow = 0) => {
    // find index of appropriate date from dates array
    const row = findLastIndex(
      d => date.isSameOrAfter(d),
      this.props.selectorDates,
    );

    if (row < 0) return;

    // get offset for row inside found date (e.g. month inside year)
    const innerRowOffset = innerRow * this.state.rowHeight;

    this.scrollable.scrollTop(
      this.selector.getOffsetForRow({ index: row }) + innerRowOffset,
    );
  };

  render() {
    return (
      <Flex flex="1">
        <Navigator onClick={this.onDateClick} />
        <Selector
          listRef={el => (this.selector = el)}
          scrollableRef={el => (this.scrollable = el)}
          getRowHeight={this.setRowHeight}
        />
      </Flex>
    );
  }
}

export default connect((state: AppState) => ({
  selectorDates: computeSelectorDates(state),
}))(Scrollers);
