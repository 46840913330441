import { createForms } from 'react-redux-form';
import nanoid from 'nanoid';
import { AppState } from '../models';

export const initialLoginState = {
  email: '',
  password: '',
};

export const initialSignUpState = {
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  agreedToTermsOfUse: false,
  agreedToReceiveMarketingEmails: true,
  cardNumber: '',
  cardExpiry: '',
  cardCvc: '',
  withCard: true,
};

export const initialEditorsState = [{ id: nanoid() }];

export const layerImportInitialState = {};
export const willCreateEnvironment = (state: AppState) =>
  state.layerImport.createEnvironment;

export default createForms({
  login: initialLoginState,
  signup: initialSignUpState,
  editors: initialEditorsState,
  layerImport: layerImportInitialState,
});
