import { connect } from 'react-redux';
import { AppState } from '../../models';
import {
  getCurrentSliceDuration,
  getFilteredTimeIntervals,
} from '../../selectors/project';
import SliceCount from './SliceCount';

export default connect((state: AppState) => ({
  sliceDuration: getCurrentSliceDuration(state),
  intervals: getFilteredTimeIntervals(state),
}))(SliceCount);
