import { connect } from 'react-redux';
import { compose } from 'redux';
import { branch, renderComponent } from 'recompose';

import { isApproximated } from '../selectors/project';
import { AppState } from '../models';

import EditRegularProject from '../components/EditRegularProject';
import EditApproximatedProject from '../components/EditApproximatedProject';

const Empty = () => null;

export default compose(
  connect((state: AppState) => ({
    isApproximated: isApproximated(state),
  })),
  branch<{ isApproximated: true }>(
    props => props.isApproximated,
    renderComponent(EditApproximatedProject),
    renderComponent(EditRegularProject),
  ),
)(Empty);
