import styled from 'styled-components';

export default styled.line.attrs({
  x1: 0,
  x2: props => props.x,
  y1: props => props.y,
  y2: props => props.y,
})`
  stroke: ${({ theme }) => theme.colors.grey5};
  stroke-width: 1;
  shape-rendering: crispEdges;
`;
