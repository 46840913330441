import { Action } from 'redux';
import { take, cancel, fork, put, actionChannel } from 'redux-saga/effects';
import { buffers, Channel } from 'redux-saga';
import { volumer } from '@hm/volumer-api';
import initObject from './initObject';
import getNormalizedCoord from './getNormalizedCoord';
import { types } from '../../actions/volumer';
import {
  addPointToObject,
  saveObject,
  types as objectTypes,
} from '../../actions/objects';
import { MapObject } from '../../reducers/selectedObjects';
import { canSaveObject } from '../../utils/volumerInteractions';
import { xyThreshold } from '../../utils/eventHelper';
import { pend } from '../../utils/promise';
import { CLICK_THRESHOLD } from '../../constants/volumer';
import { Tool } from '../../constants/tools';
import { Pointer } from '../../models';
import { RaycastSender } from './getRaycastSender';
import { EventSender } from './getEventSender';

const createShape = (type: string) =>
  function*(
    initialPointer: Pointer,
    sendRaycast: RaycastSender,
    sendEvent: EventSender,
  ) {
    const { mapHit } = yield* sendRaycast(initialPointer, [
      volumer.raycast.Type.MAP,
    ]);
    if (!mapHit) return null;

    const clickQueue = yield actionChannel(
      types.CLICK,
      buffers.dropping<Action>(5),
    );
    const doubleClickQueue = yield actionChannel(
      types.DOUBLE_CLICK,
      buffers.dropping<Action>(1),
    );

    const shape = yield* initObject(type, mapHit);

    const addPointTask = yield fork(addPoint, sendRaycast, shape, clickQueue);
    const addPointAndSaveTask = yield fork(
      finish,
      sendRaycast,
      shape,
      doubleClickQueue,
    );
    const visualFeedbackTask = yield fork(visualFeedback, shape, sendEvent);

    while (true) {
      const {
        payload: { id },
      } = yield take([
        pend(objectTypes.SAVE_OBJECT),
        pend(objectTypes.DELETE_OBJECT),
      ]);

      if (id !== shape.id) continue;

      yield cancel(addPointTask);
      yield cancel(addPointAndSaveTask);
      yield cancel(visualFeedbackTask);
      break;
    }
  };

function* addPoint(
  sendRaycast: RaycastSender,
  shape: MapObject,
  clickChannel: Channel<Action>,
) {
  let previousPointer = null;
  while (true) {
    const { payload: pointer } = yield take(clickChannel);
    const { mapHit } = yield* sendRaycast(pointer, [volumer.raycast.Type.MAP]);
    if (!mapHit) continue;

    // XXX: when we try to finish shape with a double click it creates two points
    // almost in the same spot because of the two regular click events
    const tooCloseToPrevious = previousPointer
      ? xyThreshold(pointer, previousPointer, CLICK_THRESHOLD)
      : false;

    if (tooCloseToPrevious) continue;

    yield put(addPointToObject(shape.id, mapHit.position.geo));
    previousPointer = pointer;
  }
}

function* finish(
  sendRaycast: RaycastSender,
  shape: MapObject,
  doubleClickChannel: Channel<Action>,
) {
  while (true) {
    const { payload: pointer } = yield take(doubleClickChannel);
    const { mapHit } = yield* sendRaycast(pointer, [volumer.raycast.Type.MAP]);
    if (!mapHit) continue;

    if (canSaveObject(shape)) {
      yield put(saveObject(shape));
    }
  }
}

function* visualFeedback(shape: MapObject, sendEvent: EventSender) {
  while (true) {
    const { payload: pointer } = yield take(types.POINTER_MOVE);
    const coord = yield* getNormalizedCoord(pointer);
    yield sendEvent('visualFeedback', { coord, objectId: shape.id });
  }
}

export const createPolyline = createShape(Tool.POLYLINE);
export const createPolygon = createShape(Tool.POLYGON);
export const createRoad = createShape(Tool.ROAD);
