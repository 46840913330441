import { merge } from 'ramda';
import { volumer } from '@hm/volumer-api';
import { patch } from '../api/rest';

const { EDGE, POINT } = volumer.types.ObjectType;

// XXX: not super nice
const fragmentEndpoint = {
  [EDGE]: 'edges',
  [POINT]: 'points',
};

const SELECT_FRAGMENT = 'fragments/SELECT_FRAGMENT';
const DESELECT_FRAGMENT = 'fragments/DESELECT_FRAGMENT';

export const selectFragment = (id: string, type: number) => ({
  type: SELECT_FRAGMENT,
  payload: patch(`/${fragmentEndpoint[type]}/${id}`, { editSelected: true }),
  meta: {
    onSuccess: merge({ type }),
  },
});

export const deselectFragment = (id: string, type: number) => ({
  type: DESELECT_FRAGMENT,
  payload: {
    ...patch(`/${fragmentEndpoint[type]}/${id}`, { editSelected: false }),
    data: { id },
  },
});

export const types = {
  SELECT_FRAGMENT,
  DESELECT_FRAGMENT,
};
