import { types } from '../actions/compass';
import { fulfill } from '../utils/promise';

export interface CompassState {
  tilt: number;
  rotate: number;
}

export const initialState = {
  tilt: 0,
  rotate: 0,
};

export default (
  state: CompassState = initialState,
  action: any,
): CompassState => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.RESET_CAMERA):
      return {
        tilt: initialState.tilt,
        rotate: initialState.rotate,
      };

    case types.UPDATE_COMPASS:
      return {
        tilt: payload.tilt,
        rotate: payload.rotate,
      };

    default:
      return state;
  }
};
