import { Box } from 'grid-styled';
import tos from '../../tosAndPrivacy.html';

const TOS = Box.extend.attrs({
  dangerouslySetInnerHTML: { __html: tos },
})`
  max-height: 208px;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.grey2};
`;

TOS.defaultProps = { width: '100%', p: 1 };

export default TOS;
