import { defineMessages } from 'react-intl';

export default defineMessages({
  multipleSelectionCount: {
    id: 'Inspector/Header.multipleSelectionCount',
    defaultMessage: '{objectCount, plural, one {# object} other {# objects}}',
  },
  draftMarker: {
    id: 'Inspector/Header.draftMarker',
    defaultMessage: 'Draft marker',
  },
  spatialMarker: {
    id: 'Inspector/Header.spatialMarker',
    defaultMessage: 'Spatial marker',
  },
  temporalMarker: {
    id: 'Inspector/Header.temporalMarker',
    defaultMessage: 'Temporal marker',
  },
  spatioTemporalMarker: {
    id: 'Inspector/Header.spatioTemporalMarker',
    defaultMessage: 'Spatio-temporal marker',
  },
  polygon: {
    id: 'Inspector/Header.polygon',
    defaultMessage: 'Polygon',
  },
  polyline: {
    id: 'Inspector/Header.polyline',
    defaultMessage: 'Polyline',
  },
  radius: {
    id: 'Inspector/Header.radius',
    defaultMessage: 'Radius',
  },
  road: {
    id: 'Inspector/Header.road',
    defaultMessage: 'Road',
  },
  enabled: {
    id: 'Inspector.enabled',
    defaultMessage: 'Enabled',
  },
  visible: {
    id: 'Inspector.visible',
    defaultMessage: 'Visible',
  },
  hidden: {
    id: 'Inspector.hidden',
    defaultMessage: 'Hidden',
  },
  unknown: {
    id: 'Inspector.unknown',
    defaultMessage: 'Undefined',
  },
});
