import { merge, mergeWith } from 'ramda';
import { fulfill, pend } from '../utils/promise';
import { Option } from '../models';

import { types } from '../actions/options';
import { types as projectTypes } from '../actions/projects';
import { types as layerTypes } from '../actions/layers';

export interface OptionsState {
  entities: { [index: string]: Option };
}

export const initialState: OptionsState = {
  entities: {},
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.UPDATE_OPTION):
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: payload,
        },
      };

    case fulfill(layerTypes.CREATE_LAYER):
    case fulfill(projectTypes.GET_PROJECT):
      if (!payload.entities.options) return state;

      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities.options,
        },
      };

    case pend(types.UPDATE_MANY_OPTIONS):
      return {
        entities: mergeWith(merge, state.entities, payload),
      };

    default:
      return state;
  }
};
