import { Moment, Duration, unitOfTime } from 'moment';
import { getDurationType } from '../../utils/time';
import { ApproximationType } from '../../reducers/currentProject';

type Format = string | ((m: Moment) => string);

export const getLabel = (formatsTable: Dict<Format[]>) => (
  m: Moment,
  duration: Duration,
) => {
  const durationType = getDurationType(duration);

  return formatsTable[durationType]
    .map(format => (format instanceof Function ? format(m) : format))
    .filter(value => typeof value === 'string')
    .map((template: string) => m.format(template));
};

const startOf = (unit: unitOfTime.StartOf, template: string) => (m: Moment) =>
  m
    .clone()
    .startOf(unit)
    .isSame(m)
    ? template
    : null;

const startOfDay = startOf('day', 'D MMM');
const startOfMonth = startOf('month', 'MMM');
const startOfYear = startOf('year', 'YYYY');

export const getFullLabel = getLabel({
  PTM: ['HH-mm', 'D MMM', 'YYYY'],
  PTH: ['HH-mm', 'D MMM', 'YYYY'],
  PD: ['D', 'MMM', 'YYYY'],
  PM: ['MMM', 'YYYY'],
  PY: ['YYYY'],
});

export const getTooltipLabel = getLabel({
  PTM: ['HH-mm', 'D MMM YYYY'],
  PTH: ['HH-mm', 'D MMM YYYY'],
  PD: ['D MMM', 'YYYY'],
  PM: ['MMM', 'YYYY'],
  PY: ['YYYY'],
});

export const getShortLabel = getLabel({
  PTM: ['HH-mm', startOfDay, startOfMonth],
  PTH: ['HH-mm', startOfDay, startOfYear],
  PD: ['D', startOfMonth, startOfYear],
  PM: ['MMM', startOfYear],
  PY: ['YYYY'],
});

const approximatedLabelFormats = {
  day: 'HH-mm',
  week: 'ddd',
  month: 'D',
  year: 'MMM',
};

export const getApproximatedLabel = (m: Moment, type: ApproximationType) => [
  m.format(approximatedLabelFormats[type]),
];
