import { stringify } from 'query-string';
import { get } from '../api/rest';

const SEARCH = 'SEARCH';

// TODO: add API call
export const search = (query: string) => (dispatch, getState) => {
  const id = getState().project.id;

  dispatch({
    type: SEARCH,
    payload: get(
      `/objects/search?${stringify({
        query,
        types: [],
        containerType: 'project',
        containerIds: [id],
      })}`,
    ),
    meta: { debounce: 500 },
  });
};

export const types = { SEARCH };
