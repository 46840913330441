import { createSelector } from 'reselect';
import moment from 'moment';
import { isEmpty, defaultTo } from 'ramda';
import { planIsTrial } from '../../utils/helpers';
import { AppState } from '../../models';

export const isBillingFetched = (state: AppState) => !isEmpty(state.billing);
export const getBilling = (state: AppState) => state.billing;

// TODO: check what’s needed
export const getPlanType = (state: AppState) => state.billing.type;
export const getMaxHours = (state: AppState) => state.billing.maxHours;
export const getUsedHours = (state: AppState) => state.billing.usedHours;
export const getCardBrand = (state: AppState) => state.billing.cardBrand;
export const getCardExpMonth = (state: AppState) => state.billing.cardExpMonth;
export const getCardExpYear = (state: AppState) => state.billing.cardExpYear;
export const getCardLastFour = (state: AppState) => state.billing.cardLastFour;
export const getnextUpcomingInvoiceAt = (state: AppState) =>
  state.billing.nextUpcomingInvoiceAt;
export const getStripeSynchronized = (state: AppState) =>
  state.billing.stripeSynchronized;
export const getSubscriptionActive = (state: AppState) =>
  state.billing.subscriptionActive;
export const getIsCardValid = (state: AppState) => state.billing.cardValid;

export const isTrial = (state: AppState) => planIsTrial(getPlanType(state));
export const getSubscriptionCost = (state: AppState) =>
  defaultTo(null, state.billing.effectiveMonthlyCostCent / 100);

export const getSubscriptionLastErrorCode = (state: AppState) =>
  state.billing.subscriptionLastErrorCode;
export const getSubscriptionLastErrorMessage = (state: AppState) =>
  state.billing.subscriptionLastErrorMessage;

export const hasOverage = createSelector(
  getUsedHours,
  getMaxHours,
  (used, max) => used > max,
);

export const getDaysTillInvoice = createSelector(
  getnextUpcomingInvoiceAt,
  nextInvoice => Math.max(0, Math.ceil(moment(nextInvoice).diff(moment(), 'days', true))),
);
