import { actions } from 'react-redux-form';
import { post, get, patch } from '../api/rest';
import {
  CurrentUser,
  PasswordUpdate,
  EmailUpdate,
  PasswordReset,
  PasswordConfirmation,
  ApiError,
} from '../models';

const LOG_IN = 'user/LOG_IN';
const LOG_OUT = 'user/LOG_OUT';
const SIGN_UP = 'user/SIGN_UP';
const REFRESH_ACCESS_TOKEN = 'user/REFRESH_ACCESS_TOKEN';
const DISCONNECT_INACTIVE = 'user/DISCONNECT_INACTIVE';
const GET_PROFILE = 'user/GET_PROFILE';
const UPDATE_USER = 'user/UPDATE';
const PASSWORD_RESET = 'user/PASSWORD_RESET';
const REQUEST_PASSWORD_RESET = 'user/REQUEST_PASSWORD_RESET';
const UPDATE_PASSWORD = 'user/UPDATE_PASSWORD';
const UPDATE_EMAIL = 'user/UPDATE_EMAIL';
const CONFIRM_EMAIL_UPDATE = 'user/CONFIRM_EMAIL_UPDATE';
const GET_WELCOME_PROJECTS = 'user/GET_WELCOME_PROJECTS';
const UPDATE_USERPIC = 'user/UPDATE_USERPIC';
const CHECK_TOKEN = 'user/CHECK_TOKEN';
const COMPLETE_SIGN_UP = 'user/COMPLETE_SIGN_UP';

export const logIn = (email: string, password: string) => dispatch => {
  const promise = dispatch({
    type: LOG_IN,
    payload: post('/users/login', { email, password }),
    meta: { authenticate: false },
  }).catch(({ error }: { error: ApiError }) =>
    Promise.reject({ [error.code]: error.detail }),
  );

  return dispatch(actions.submit('login', promise));
};

export const logOut = () => ({
  type: LOG_OUT,
});

export interface SignUpDetails extends PasswordConfirmation {
  name: string;
  email: string;
  stripeToken?: string;
  withCard: boolean;
  agreedToTermsOfUse: boolean;
}

export const signUp = (details: SignUpDetails) => ({
  type: SIGN_UP,
  payload: post('/users/signup', details),
  meta: { authenticate: false },
});

export const refreshAccessToken = (refreshToken: string) => ({
  type: REFRESH_ACCESS_TOKEN,
  payload: post('/user/refresh_token', { refreshToken }),
  meta: { authenticate: false },
});

export const disconnectInactive = () => ({
  type: DISCONNECT_INACTIVE,
});

export const getProfile = () => ({
  type: GET_PROFILE,
  payload: get('/user'),
});

export const updateUser = (updates: Partial<CurrentUser>) => ({
  type: UPDATE_USER,
  payload: { ...patch('/user', updates), data: updates },
});

export const toggleAutoQuality = (autoQualityEnabled: boolean) =>
  updateUser({ autoQualityEnabled });

export const requestPasswordReset = (email: string) => ({
  type: REQUEST_PASSWORD_RESET,
  payload: post('/users/password_reset', { email }),
  meta: { authenticate: false },
});

export const resetPassword = (form: PasswordReset) => ({
  type: PASSWORD_RESET,
  payload: post('/users/password', form),
  meta: { authenticate: false },
});

export const updatePassword = (form: PasswordUpdate) => ({
  type: UPDATE_PASSWORD,
  payload: post('/user/password', form),
});

export const updateEmail = (form: EmailUpdate) => ({
  type: UPDATE_EMAIL,
  payload: post('/user/update_email', form),
});

export const confirmEmail = (token: string) => ({
  type: CONFIRM_EMAIL_UPDATE,
  payload: post('/user/email', { token }),
});

export const getWelcomeProjects = () => ({
  type: GET_WELCOME_PROJECTS,
  payload: get('/user/welcome'),
});

export const updateUserpic = (form: any) => ({
  type: UPDATE_USERPIC,
  payload: post('/user/avatar', undefined, {
    headers: {},
    body: form,
  }),
});

export const checkToken = (token: string) => ({
  type: CHECK_TOKEN,
  payload: get(`/users/signup_confirm?token=${token}`),
  meta: { authenticate: false },
});

export const completeSignUp = ({
  signupToken,
  stripeToken,
}: {
  signupToken: string;
  stripeToken?: string;
}) => ({
  type: COMPLETE_SIGN_UP,
  payload: post('/users/signup_confirm', { signupToken, stripeToken }),
  meta: { authenticate: false },
});

export const types = {
  LOG_IN,
  LOG_OUT,
  SIGN_UP,
  REFRESH_ACCESS_TOKEN,
  DISCONNECT_INACTIVE,
  GET_PROFILE,
  UPDATE_USER,
  PASSWORD_RESET,
  REQUEST_PASSWORD_RESET,
  UPDATE_PASSWORD,
  UPDATE_EMAIL,
  CONFIRM_EMAIL_UPDATE,
  GET_WELCOME_PROJECTS,
  UPDATE_USERPIC,
  CHECK_TOKEN,
  COMPLETE_SIGN_UP,
};
