import { assoc } from 'ramda';
import { types as projectTypes } from '../actions/projects';
import { types } from '../actions/sidebar';
import { pend } from '../utils/promise';

export enum SidebarTab {
  Metrics = 'metrics',
  Settings = 'settings',
  Highlights = 'highlights',
}

export interface SidebarState {
  tab: SidebarTab;
  pins: {
    quality: boolean;
    scaling: boolean;
    interpolation: boolean;
  };
}

export const initialState: SidebarState = {
  tab: SidebarTab.Metrics,
  pins: {
    quality: false,
    scaling: false,
    interpolation: false,
  },
};

export type SidebarPin = keyof typeof initialState.pins;

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.CHANGE_TAB:
      return { ...state, tab: payload };

    case types.TOGGLE_PIN:
      return {
        ...state,
        pins: assoc(payload.pin, payload.enabled, state.pins),
      };

    case pend(projectTypes.OPEN_PROJECT):
    case pend(projectTypes.VIEW_PROJECT):
      return initialState;

    default:
      return state;
  }
};
