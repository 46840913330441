import React from 'react';
import { FormattedDate } from 'react-intl';

interface Props {
  interval: string | [Date, Date];
  timezone?: string;
}

const displayProps = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};

const TimeInterval: React.SFC<Props> = ({ interval, timezone }) => {
  const [start, end] = Array.isArray(interval) ? interval : interval.split('/');

  return (
    <span>
      <FormattedDate value={start} timeZone={timezone} {...displayProps} />
      {` – `}
      <FormattedDate value={end} timeZone={timezone} {...displayProps} />
    </span>
  );
};

export default TimeInterval;
