import { select, take, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { equals } from 'ramda';
import { types } from '../../actions/timeline';
import {
  computeVisibleTimeWindow,
  getIsPlaying,
} from '../../selectors/timeline';
import { EventSender } from './getEventSender';

/**
 * Every time timeline selected window changes saga sends new values to Volumer
 * (just like if Volumer was a React component connected to the store)
 */
export default function*(sendEvent: EventSender) {
  yield takeEvery(types.START_DRAG_SELECT, startDrag, sendEvent);
  yield takeEvery(types.STOP_DRAG_SELECT, stopDrag, sendEvent);

  while (true) {
    const previousTimeWindow = yield select(computeVisibleTimeWindow);

    const isPlaying = yield select(getIsPlaying);
    if (isPlaying) yield delay(60); // TODO: make actual throttling

    yield take('*');

    const newTimeWindow = yield select(computeVisibleTimeWindow);

    if (!equals(previousTimeWindow, newTimeWindow)) {
      const [x, y] = newTimeWindow;
      yield* sendEvent('timelineDrag', { range: { x, y } });
    }
  }
}

function* startDrag(sendEvent: EventSender) {
  yield* sendEvent('timelineDragStart', {});
}

function* stopDrag(sendEvent: EventSender) {
  yield* sendEvent('timelineDragStop', {});
}
