import { Button, IconButton, RadioButtonGroup } from '@hm/ukie';
import React from 'react';
import { Effect } from '../../models';

interface Props {
  effect: Effect;
  onDelete(id: string);
  onUpdate(id: string, updates);
}

export default class Flip extends React.Component<Props, never> {
  handleClick = name => {
    const { id, enabled } = this.props.effect;

    this.props.onUpdate(id, { enabled: !enabled });
  };

  onDelete = () => {
    this.props.onDelete(this.props.effect.id);
  };

  render() {
    const { enabled } = this.props.effect;
    const selected = enabled ? 'bottom' : 'top';

    return (
      <div className="metric-effect">
        <div className="metric-effect__title">
          <span className="metric-effect__text">Flip</span>
        </div>
        <div className="metric-effect__actions">
          <RadioButtonGroup selected={selected} onClick={this.handleClick}>
            <Button icon="mirrorTop" value="top" />
            <Button icon="mirrorBottom" value="bottom" />
          </RadioButtonGroup>
        </div>
        <div className="metric-effect__remove">
          <IconButton icon="bin" onClick={this.onDelete} />
        </div>
      </div>
    );
  }
}
