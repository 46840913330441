import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Icon, Counter, Text, IconButton } from '@hm/ukie';
import { Flex, Box } from 'grid-styled';

import { blurOnEnter } from '../../utils/eventHelper';
import BoundingBoxCoordinates from './BoundingBoxCoordinates';
import { Field, FieldInfo, CustomControlText } from '../Form';

interface Props {
  layer: any;
  onReset(): void;
  changeActionFor(field: string): (model, value) => Dict<any>;
}

const CreateEnvironment: React.SFC<Props> = ({
  changeActionFor,
  layer,
  onReset,
}) => (
  <React.Fragment>
    <Field
      label={
        <F
          id="MetricConfig.environmentName"
          defaultMessage="Environment name"
        />
      }
    >
      <CustomControlText
        model=".environmentName"
        changeAction={changeActionFor('environmentName')}
        controlProps={{ titleFont: true }}
        onKeyPress={blurOnEnter}
        style={{ marginTop: '8px' }}
      />
    </Field>

    <Field
      label={
        <F id="MetricConfig.selectedBounds" defaultMessage="Selected borders" />
      }
    >
      <Flex align="center">
        <Box flex="0 0 auto" mr={1}>
          <Icon name="geoBounds" />
        </Box>
        <Box>
          <Flex>
            <F id="MetricConfig.latLon" defaultMessage="Lat, Lon">
              {text => (
                <Box is="span" mr={2}>
                  {text}
                </Box>
              )}
            </F>
            <CustomControlText
              component={Counter}
              controlProps={{ noControls: true }}
              model=".selectedTopLat"
              style={{ width: 76, marginRight: 8 }}
              changeAction={changeActionFor('selectedTopLat')}
              onKeyPress={blurOnEnter}
            />
            <CustomControlText
              component={Counter}
              controlProps={{ noControls: true }}
              style={{ width: 76 }}
              model=".selectedRightLon"
              changeAction={changeActionFor('selectedRightLon')}
              onKeyPress={blurOnEnter}
            />
          </Flex>

          <Flex>
            <F id="MetricConfig.latLon" defaultMessage="Lat, Lon">
              {text => (
                <Box is="span" mr={2}>
                  {text}
                </Box>
              )}
            </F>
            <CustomControlText
              component={Counter}
              controlProps={{ noControls: true }}
              model=".selectedBottomLat"
              style={{ width: 76, marginRight: 8 }}
              changeAction={changeActionFor('selectedBottomLat')}
              onKeyPress={blurOnEnter}
            />
            <CustomControlText
              component={Counter}
              controlProps={{ noControls: true }}
              model=".selectedLeftLon"
              style={{ width: 76 }}
              changeAction={changeActionFor('selectedLeftLon')}
              onKeyPress={blurOnEnter}
            />
          </Flex>
        </Box>

        <Box ml={2}>
          <IconButton icon="refresh" onClick={onReset} />
        </Box>
      </Flex>
    </Field>

    <Field
      label={
        <F
          id="MetricConfig.environmentBounds"
          defaultMessage="Environment borders"
        />
      }
    >
      <BoundingBoxCoordinates
        topLat={layer.environmentTopLat}
        rightLon={layer.environmentRightLon}
        bottomLat={layer.environmentBottomLat}
        leftLon={layer.environmentLeftLon}
      />
    </Field>

    <Field
      label={<F id="MetricConfig.cellSize" defaultMessage="Cell size (m)" />}
    >
      <CustomControlText
        component={Counter}
        controlProps={{ noControls: true }}
        style={{ width: 76 }}
        model=".cellSize"
        changeAction={changeActionFor('cellSize')}
        onKeyPress={blurOnEnter}
      />
    </Field>

    <Field
      label={
        <F id="MetricConfig.resolution" defaultMessage="Resolution (cells)" />
      }
    >
      <Text.Important is="div">
        {`${layer.resolution.x} 𝗑 ${layer.resolution.y}`}
      </Text.Important>
      <F
        id="MetricConfig.resolutionInfo"
        defaultMessage="Resolution limit is 300×300"
      >
        {text => <FieldInfo>{text}</FieldInfo>}
      </F>
    </Field>
  </React.Fragment>
);

export default CreateEnvironment;
