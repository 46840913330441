import React from 'react';
import styled from 'styled-components';
import { where, anyPass, equals, complement } from 'ramda';

import { connect } from 'react-redux';
import { getType, isDraft } from '../../selectors/inspector';
import { getSelectedObjects } from '../../selectors/selectedObjects';
import { AppState } from '../../models';

import { ObjectType } from '../../constants/objects';
import { oneOf, condAll } from '../../utils/helpers';

import SubtypeControl from './SubtypeControlContainer';
import WidthControl, { PresetControl } from './WidthControl';

interface Props {
  type: string;
  draft: boolean;
  objectCount: number;
}

const Body = styled.div`
  margin-top: 16px;

  &:not(:last-child) {
    // XXX: hack, does not belog here, but higher in component chain
    margin-bottom: 16px;
  }

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const getComponents = condAll<Props>([
  [
    anyPass([
      where({
        type: oneOf([ObjectType.MARKER, ObjectType.ROAD]),
        draft: equals(true),
      }),
      where({
        type: oneOf([ObjectType.MARKER, ObjectType.ROAD]),
        objectCount: equals(0),
      }),
    ]),
    () => <SubtypeControl key="subtype-control" />,
  ],
  [
    where({
      type: oneOf([ObjectType.POLYLINE, ObjectType.RADIUS, ObjectType.ROAD]),
      objectCount: complement(equals)(0),
    }),
    () => <WidthControl key="width-control" />,
  ],
  [
    where({
      type: oneOf([ObjectType.POLYLINE, ObjectType.RADIUS, ObjectType.ROAD]),
      objectCount: equals(0),
    }),
    () => <PresetControl key="preset-control" />,
  ],
]);

const ObjectParams: React.SFC<Props> = props => {
  const components = getComponents(props);
  if (components.length === 0) return null;

  return <Body>{components}</Body>;
};

const mapStateToProps = (state: AppState) => ({
  type: getType(state),
  draft: isDraft(state),
  objectCount: getSelectedObjects(state).length,
});

export default connect(mapStateToProps)(ObjectParams);
