import { takeEvery } from 'redux-saga/effects';
import { FSA } from 'flux-standard-action';
import { types } from '../../actions/volumer';
import { types as abrTypes } from '../../reducers/abr';
import { EventSender } from './getEventSender';

export default function*(send: EventSender) {
  yield takeEvery<FSA<any>>(types.UPDATE_QUALITY_START, function*(action) {
    yield send('lowresQualityDragStart', action.payload.message);
  });

  yield takeEvery<FSA<any>>(
    [types.UPDATE_QUALITY_DRAG, abrTypes.UPDATE_QUALITY],
    function*(action) {
      yield send('lowresQualityDrag', action.payload.message);
    },
  );

  yield takeEvery<FSA<any>>(types.UPDATE_QUALITY_STOP, function*(action) {
    yield send('lowresQualityDragStop', action.payload.message);
  });
}
