import styled from 'styled-components';

const TICK_HEIGHT = 8;

interface Props extends React.SVGProps<SVGLineElement> {
  x: number;
  y: number;
  dimmed?: boolean;
}

export default styled.line.attrs({
  x1: props => props.x,
  x2: props => props.x,
  y1: props => props.y,
  y2: props => (props.y as number) + TICK_HEIGHT,
})`
  stroke: ${({ theme }) => theme.colors.grey5};
  stroke-width: 1;
  shape-rendering: crispEdges;
  opacity: ${({ dimmed }: Props) => (dimmed ? '0.4' : '1')};
`;
