import React from 'react';
import styled from 'styled-components';

import { MouseButton, CLICK_THRESHOLD } from '../../constants/volumer';
import { xyThreshold } from '../../utils/eventHelper';
import { Pointer, Resolution } from '../../models';
import { Tool } from '../../constants/tools';

import cursorMarker from '../../styles/cursors/cursor-marker.svg';
import cursorNavigation from '../../styles/cursors/cursor-navigation.svg';
import cursorSelect from '../../styles/cursors/cursor-select.svg';
import cursorPolygon from '../../styles/cursors/cursor-polygon.svg';
import cursorPolyline from '../../styles/cursors/cursor-polyline.svg';
import cursorRadius from '../../styles/cursors/cursor-radius.svg';

const isTouchEvent = (
  e: MouseEvent | TouchEvent | WheelEvent,
): e is TouchEvent => window['TouchEvent'] && e instanceof TouchEvent;

const MIN_DELTA_VALUE = 1.2;
const STANDARD_WHEEL_DELTA = 40;
const browserButtonToString = [
  MouseButton.LEFT,
  MouseButton.MIDDLE,
  MouseButton.RIGHT,
];

const hotkeyBlacklist = ['INPUT', 'SELECT', 'TEXTAREA'];

const cursors = {
  [Tool.NAVIGATION]: cursorNavigation,
  [Tool.CURSOR]: cursorSelect,
  [Tool.MARKER]: cursorMarker,
  [Tool.POLYGON]: cursorPolygon,
  [Tool.POLYLINE]: cursorPolyline,
  [Tool.RADIUS]: cursorRadius,
};

const VolumerImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: url(${(props: { cursor: string }) => props.cursor}) 0 0, pointer;
`;

export interface StateProps {
  selectedTool: string;
  currentFrame: string;
  isRendering: boolean;
}

export interface DispatchProps {
  onPointerDown(pointer: Pointer);
  onPointerMove(pointer: Pointer);
  onPointerUp(pointer: Pointer);
  onClick(pointer: Pointer);
  onDoubleClick(pointer: Pointer);
  onWheel(delta: number, pointer: Pointer);
  onResize(viewport: Resolution);
  onKeyDown(key: string);
  onKeyUp(key: string);
  beforeWindowUnload(): void;
  onClose(): void;
}

export type Props = StateProps & DispatchProps;

type ReactMouseOrTouch =
  | React.MouseEvent<HTMLImageElement>
  | React.TouchEvent<HTMLImageElement>;

/**
 * Renders and resizes Volumer image
 * Dispatches pointer events that make sense for the application
 * so it acts like a browser behaviour normalizer in a way
 */
export default class Volumer extends React.Component<Props> {
  volumer: HTMLElement;

  // Gives mousemove event a reliable button property
  // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
  button: string;

  // Starting point of mouse movement with button down
  initialPointer: Pointer;

  componentDidMount() {
    // Binding these to document allows us not to break
    // camera movements if pointer goes over other UI elements
    document.addEventListener('mousemove', this.onPointerMove);
    document.addEventListener('touchmove', this.onPointerMove);
    document.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('beforeunload', () => {
      this.props.beforeWindowUnload();

      // This will prevent confirmation dialog from opening
      return undefined;
    });

    // We send intital viewport size which is only known when
    // the component with viewport is mounted
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onPointerMove);
    document.removeEventListener('touchmove', this.onPointerMove);
    document.removeEventListener('keydown', this.onKeyDown);

    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('beforeunload', this.props.beforeWindowUnload);
    this.props.onClose();
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (this.blockKeyEvent()) return;
    document.addEventListener('keyup', this.onKeyUp);
    this.props.onKeyDown(e.code);
  };

  onKeyUp = (e: KeyboardEvent) => {
    this.props.onKeyUp(e.code);
    document.removeEventListener('keyup', this.onKeyUp);
  };

  blockKeyEvent = () => {
    const {
      tagName,
      isContentEditable,
    } = document.activeElement as HTMLElement;
    return hotkeyBlacklist.includes(tagName) || isContentEditable;
  };

  onPointerDown = (e: ReactMouseOrTouch) => {
    // We use nativeEvent for CONSISTENCY ONLY with pointer move and up
    // which are bound to document so we can't get a React event there
    const pointer = this.getPointer(e.nativeEvent as MouseEvent | TouchEvent);

    this.props.onPointerDown(pointer);

    if (e.nativeEvent instanceof MouseEvent) {
      this.button = browserButtonToString[e.nativeEvent.button];
    }

    this.initialPointer = pointer;
    // Same reason as for bounding move events to document
    document.addEventListener('mouseup', this.onPointerUp);
    document.addEventListener('touchend', this.onPointerUp);
  };

  onPointerMove = (e: MouseEvent | TouchEvent) => {
    this.props.onPointerMove(this.getPointer(e, this.button));
  };

  onPointerUp = (e: MouseEvent | TouchEvent) => {
    const pointer = this.getPointer(e);
    this.props.onPointerUp(pointer);

    this.button = null;
    document.removeEventListener('mouseup', this.onPointerUp);
    document.removeEventListener('touchend', this.onPointerUp);
  };

  onClick = (e: ReactMouseOrTouch) => {
    const pointer = this.getPointer(e.nativeEvent);
    const wasClick = xyThreshold(pointer, this.initialPointer, CLICK_THRESHOLD);
    if (wasClick) this.props.onClick(pointer);
  };

  onDoubleClick = (e: React.MouseEvent<HTMLImageElement>) => {
    this.props.onDoubleClick(this.getPointer(e.nativeEvent as MouseEvent));
  };

  onWheel = (e: React.WheelEvent<HTMLImageElement>) => {
    e.preventDefault();
    const deltaValue = this.transformWheelDelta(e.deltaY);
    const delta = e.deltaY < 0 ? deltaValue : -deltaValue;
    this.props.onWheel(delta, this.getPointer(e.nativeEvent as WheelEvent));
  };

  onContextMenu = (e: React.MouseEvent<HTMLImageElement>) => e.preventDefault();

  onWindowResize = () => {
    const { width, height } = this.volumer.getBoundingClientRect();

    this.props.onResize({
      width: Math.round(width),
      height: Math.round(height),
    });
  };

  /**
   * Maps wheel deltas from 0..N
   * to logSTD(MIN)..logSTD(N)
   * This makes zooming experience on touchpads/trackpads better
   *
   * STD or STANDARD_WHEEL_DELTA - is any wheel delta for which you want the resulting
   * zoom value to be equal to 1. This might be Chrome standard delta for mouse
   * if we favor Chrome. But because it is a log function values for other browser
   * won't be wildly different
   *
   * MIN or MIN_DELTA_VALUE is needed to make sure even smallest delta results
   * some minimal zoom
   */
  transformWheelDelta = (delta: number) => {
    const deltaSafe = Math.max(Math.abs(delta), MIN_DELTA_VALUE);
    return Math.log(deltaSafe) / Math.log(STANDARD_WHEEL_DELTA);
  };

  getPointer = (
    e: MouseEvent | TouchEvent | WheelEvent,
    button?: string,
  ): Pointer => {
    const { left, top } = this.volumer.getBoundingClientRect();

    return {
      x: (isTouchEvent(e) ? e.touches[0].clientX : e.clientX) - left,
      y: (isTouchEvent(e) ? e.touches[0].clientY : e.clientY) - top,
      multipleTouches: isTouchEvent(e) ? e.touches.length > 1 : false,
      button:
        button !== undefined
          ? button
          : e instanceof MouseEvent
            ? browserButtonToString[e.button]
            : null,
      altKey: e.altKey,
      shiftKey: e.shiftKey,
      ctrlKey: e.ctrlKey,
      offCanvas: e.target !== this.volumer,
    };
  };

  render() {
    const { currentFrame, selectedTool } = this.props;

    return (
      <VolumerImage
        cursor={cursors[selectedTool]}
        onMouseDown={this.onPointerDown}
        onTouchStart={this.onPointerDown}
        onWheel={this.onWheel}
        onClick={this.onClick}
        onDoubleClick={this.onDoubleClick}
        onContextMenu={this.onContextMenu}
        innerRef={el => (this.volumer = el)}
        src={currentFrame}
        draggable={false}
        data-test-id="visualization"
      />
    );
  }
}
