import React from 'react';
import { Flex } from 'grid-styled';
import { OnOffButton } from '@hm/ukie';
import { connect } from 'react-redux';
import { getPins } from '../selectors/sidebar';
import { SidebarPin } from '../reducers/sidebar';
import { togglePin as togglePinAction } from '../actions/sidebar';

const Container = Flex.extend`
  width: 100%;
  align-items: flex-end;

  &:not(:first-child) {
    padding-top: 12px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
    padding-bottom: 12px;
  }
`;

interface OwnProps {
  pin: SidebarPin;
}

interface StateProps {
  pinned: boolean;
}

interface DispatchProps {
  togglePin(pin: SidebarPin, pinned: boolean): void;
}

type Props = OwnProps & StateProps & DispatchProps;

const SettingsRow: React.SFC<Props> = ({
  pin,
  pinned,
  togglePin,
  children,
}) => (
  <Container>
    {children}
    <Flex flex={1} ml={1} justify="flex-end">
      <OnOffButton
        icon="pin"
        selected={pinned}
        onClick={() => togglePin(pin, !pinned)}
      />
    </Flex>
  </Container>
);

export default connect<StateProps, DispatchProps, OwnProps>(
  (state, ownProps) => ({
    pinned: getPins(state)[ownProps.pin],
  }),
  { togglePin: togglePinAction },
)(SettingsRow);
