import React from 'react';
import styled from 'styled-components';
import { RouteProps, Redirect } from 'react-router';
import { Flex, Box } from 'grid-styled';
import SignUpForm from './SignUpForm';

const Body = Flex.extend.attrs({
  align: 'center',
  flexDirection: 'column',
})`
  user-select: auto;
`;

// TODO: make component in uikit
const P = styled.p`
  text-align: center;
  line-height: 1.4;
`;

interface User {
  name: string;
  email: string;
  inviteToken: string;
}

interface State {
  user?: User;
  fail?: boolean;
}

class InvitedPage extends React.Component<RouteProps, State> {
  state = {
    user: null,
    fail: false,
  };

  componentDidMount() {
    const { location } = this.props;
    const inviteToken = location.search.match(/\?token\=(.*)/)[1];

    fetch(`/api/v1/users/invite${location.search}`).then(
      response =>
        response.ok
          ? response
              .json()
              .then(user => this.setState({ user: { ...user, inviteToken } }))
          : this.setState({ fail: true }),
    );
  }

  render() {
    const { user, fail } = this.state;

    if (fail) return <Redirect to="/login" />;
    if (!user || !user.name) return null;

    return (
      <Body>
        <Box mt={3}>
          <P>
            Hello, <strong>{user.name}</strong>
            <br />
            We glad to invite you to Chronotope analytical platform!
            <br />
            Complete your registration and start to work.
            <br />
          </P>
        </Box>
        <Box mt={2}>
          <SignUpForm {...user} />
        </Box>
      </Body>
    );
  }
}

export default InvitedPage;
