import { take, select } from 'redux-saga/effects';
import { types } from '../../actions/volumer';
import { getViewportResolution } from '../../selectors/volumer';
import { Resolution } from '../../models';
import { EventSender } from './getEventSender';

const getEventPayload = (viewport: Resolution) => ({
  size: {
    x: viewport.width,
    y: viewport.height,
  },
});

export default function*(sendEvent: EventSender) {
  const initialViewport = yield select(getViewportResolution);
  if (initialViewport) {
    yield sendEvent('resize', getEventPayload(initialViewport));
  }

  while (true) {
    const { payload: viewport } = yield take(types.RESIZE);
    yield sendEvent('resize', getEventPayload(viewport));
  }
}
