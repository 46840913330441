import { createSelector } from 'reselect';
import { values } from 'ramda';
import { AppState } from '../models';

export const getProjectEntities = (state: AppState) => state.projects.entities;
export const getProjectIds = (state: AppState) => state.projects.ids;

export const getProjectsList = createSelector(getProjectEntities, entities =>
  values(entities),
);

export const getProjects = (state: AppState) => state.projects.entities;

export const getProjectById = (state: AppState, props: { id: string }) =>
  state.projects.entities[props.id];
