import { cond, where, isNil, equals, isEmpty, complement } from 'ramda';
import { select, put } from 'redux-saga/effects';
import { FSA } from 'flux-standard-action';

import { selectObject, deselectObject } from '../../actions/objects';
import { selectFragment, deselectFragment } from '../../actions/fragments';
import { getSelectedFragments } from '../../selectors/selectedFragments';
import { getSelectedObjects } from '../../selectors/selectedObjects';
import raycastGet, { Target } from './raycastGet';
import { includesId } from '../../utils/helpers';
import { Pointer, AppState } from '../../models';
import { RaycastSender } from './getRaycastSender';

const notEmpty = complement(isEmpty);
const notNil = complement(isNil);

const selectionPattern = cond([
  [where({ entity: isNil, selectedEntities: notEmpty }), deselectAll],
  [
    where({ entity: notNil, shiftKey: equals(true), ctrlKey: equals(false) }),
    toggleSelect,
  ],
  [
    where({ entity: notNil, ctrlKey: equals(true), shiftKey: equals(false) }),
    addToSelection,
  ],
  [where({ entity: notNil }), selectSingleObject],
]);

type EntitySelectionCreator = (
  type: Target,
  selectEntity: (...args) => FSA<any, any>,
  deselectEntity: (...args) => FSA<any>,
  getSelectedEntities: (s: AppState) => any,
) => (pointer: Pointer, sendRaycast: RaycastSender) => IterableIterator<any>;

const createEntitiesSelection: EntitySelectionCreator = (
  type,
  selectEntity,
  deselectEntity,
  getSelectedEntities,
) =>
  function*(pointer, sendRaycast) {
    const selectedEntities = yield select(getSelectedEntities as any);
    const entity = yield* raycastGet(type, pointer, sendRaycast);

    const pattern = selectionPattern({
      entity,
      selectedEntities,
      ctrlKey: pointer.ctrlKey,
      shiftKey: pointer.shiftKey,
      selectEntity,
      deselectEntity,
    });

    if (pattern) yield pattern;
  };

function* deselectAll({ selectedEntities, deselectEntity }) {
  for (const { id, type } of selectedEntities) {
    yield put(deselectEntity(id, type));
  }
}

function* selectSingleObject({
  entity,
  selectedEntities,
  selectEntity,
  deselectEntity,
}) {
  const toDeselect = selectedEntities
    .filter(({ id }) => id !== entity.id)
    .map(({ id, type }) => put(deselectEntity(id, type)));

  // XXX: for some reason all() didn't work here, investigate
  // But it worked almost in the same situation in selectDraggedObject
  yield toDeselect;

  if (!includesId(entity.id)(selectedEntities)) {
    yield put(selectEntity(entity.id, entity.type));
  }
}

function* addToSelection({ entity, selectEntity }) {
  yield put(selectEntity(entity.id, entity.type));
}

function* toggleSelect({
  entity,
  selectedEntities,
  selectEntity,
  deselectEntity,
}) {
  if (!includesId(entity.id)(selectedEntities)) {
    yield put(selectEntity(entity.id, entity.type));
  } else {
    yield put(deselectEntity(entity.id, entity.type));
  }
}

export const objectsSelection = createEntitiesSelection(
  'object',
  selectObject,
  deselectObject,
  getSelectedObjects,
);

export const fragmentsSelection = createEntitiesSelection(
  'fragment',
  selectFragment,
  deselectFragment,
  getSelectedFragments,
);
