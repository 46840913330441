import { take, select, put } from 'redux-saga/effects';
import { createObject, types as objectTypes } from '../../actions/objects';
import {
  getCurrentProjectId,
  getObjectRadiusExtent,
} from '../../selectors/project';
import { getDefaultObjectParamsForTool } from '../../selectors/defaultObjectParams';
import { fulfill } from '../../utils/promise';
import { objectHasRadius } from '../../utils/volumerInteractions';

export default function* initObject(
  type: string,
  hit,
  more?: { [index: string]: any },
) {
  const { min: minRadius } = yield select(getObjectRadiusExtent);
  const defaultParams = yield select(getDefaultObjectParamsForTool(type));

  const properties = {
    label: defaultParams.label,
    state: 'visible',
    type,
    radius: objectHasRadius(type) ? minRadius : undefined,
    points: [hit.position.geo],
    ...defaultParams,
    ...more,
  };

  const projectId = yield select(getCurrentProjectId);
  yield put(createObject(projectId, properties));

  const { payload: newObject } = yield take(fulfill(objectTypes.CREATE_OBJECT));
  return newObject;
}
