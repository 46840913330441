import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
}

const Body = styled.div`
  display: flex;
`;

const Title = styled.div`
  flex: 0 0 136px;
  margin-right: 8px;
`;

const Content = styled.div`
  user-select: auto;
  font-weight: 600;
`;

/**
 * Section of inspector that has a title and can be folded
 */
const Row: React.SFC<Props> = ({ children, title }) => {
  return (
    <Body>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Body>
  );
};

export default Row;
