import moment from 'moment';
import { DateRange } from 'moment-range';
import { evolve, dissoc, without, merge, flip } from 'ramda';
import { types } from '../actions/projects';
import { fulfill, pend } from '../utils/promise';
import { Role, User } from '../models';

export interface ProjectsState {
  entities: Dict<ProjectPreview>;
  ids: string[];
  isFetching: boolean;
}

export interface ProjectPreview {
  id: string;
  environmentId: string;
  type: string;
  label: string;
  approximationType: string;
  filteredTimeIntervals: DateRange[];
  sliceDuration: moment.Duration;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  layerCount: number;
  notes: string;
  tags: any[];
  owner: User;
  editors: User[];
  currentAccessRole: Role;
  isAdmin: boolean;
  currentlyOpenedBy: User;
  readOnly: boolean;
}

export const initialState: ProjectsState = {
  entities: {},
  ids: [],
  isFetching: false,
};

export default (state = initialState, action: any): ProjectsState => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.FETCH_PROJECTS):
      return {
        ...state,
        entities: payload.entities.projects,
        ids: payload.result,
      };

    case pend(types.DELETE_PROJECT):
      return {
        ...state,
        entities: dissoc(payload, state.entities),
        ids: without([payload], state.ids),
      };

    case pend(types.RENAME_PROJECT):
    case pend(types.OPTIMISTIC_UPDATE_PROJECT):
      return evolve(
        {
          entities: {
            [payload.id]: flip(merge)(payload.updates),
          },
        },
        state,
      );

    case fulfill(types.COPY_PROJECT):
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities.projects,
        },
      };

    default:
      return state;
  }
};
