import React from 'react';
import {
  FormattedMessage as F,
  injectIntl,
  InjectedIntlProps,
  defineMessages,
} from 'react-intl';
import { Input, SimpleModal, Submit, Text } from '@hm/ukie';
import { Fieldset, FieldInfo, FormActions, Field } from '../Form';

const messages = defineMessages({
  copy: { id: 'Project.copyPostfix', defaultMessage: 'Copy' },
});

interface Props {
  id: string;
  currentLabel: string;
  onConfirm(id: string, label: string): Promise<any>;
  onHide(): void;
}

interface State {
  label: string;
  clean: boolean;
  isSubmitting: boolean;
}

class CopyDialog extends React.Component<Props & InjectedIntlProps, State> {
  state = {
    label: `${this.props.currentLabel} ${this.props.intl.formatMessage(
      messages.copy,
    )}`,
    clean: true,
    isSubmitting: false,
  };

  onRef = (el: HTMLInputElement) => {
    if (!this.state.clean || !el) return;
    el.select();
    this.setState({ clean: false });
  };

  onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      label: e.currentTarget.value,
    });
  };

  onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    this.setState(
      { isSubmitting: true },
      () => this.props.onConfirm(this.props.id, this.state.label),
      // FIXME: updating unmounted component. Maybe pass request props from
      // parent
      // .then(() => this.setState({ isSubmitting: false })),
    );
  };

  render() {
    return (
      <SimpleModal
        title={<F id="Project.saveAsTitle" defaultMessage="Save as" />}
        onHide={this.props.onHide}
      >
        <form onSubmit={this.onSubmit}>
          <Fieldset>
            <Field>
              <Input
                value={this.state.label}
                onChange={this.onChange}
                innerRef={this.onRef}
                autoFocus
              />
            </Field>
            <FieldInfo>
              <F
                id="Project.saveAsInfo"
                defaultMessage="The current project will stay intact. After saving you’ll continue to work in a new instance."
              >
                {text => (
                  <Text color="grey7" lineHeight="text">
                    {text}
                  </Text>
                )}
              </F>
            </FieldInfo>
          </Fieldset>
          <FormActions>
            <Submit disabled={this.state.isSubmitting}>
              <F id="Project.saveAsSubmit" defaultMessage="Save" />
            </Submit>
          </FormActions>
        </form>
      </SimpleModal>
    );
  }
}

export default injectIntl(CopyDialog);
