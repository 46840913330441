import React from 'react';
import nanoid from 'nanoid';
import { SVGShadowFilter } from '@hm/ukie';

import SVGTooltip from '../SVGTooltip';
import Mark from './Mark';

interface Props extends React.SVGAttributes<SVGGElement> {
  y: number;
  x: number;
  height: number;
  histogramWidth: number;
  xLabel: string;
  yLabel: string;
  color: string;
}

export default class FilledHistogramTooltip extends React.Component<
  Props,
  never
> {
  yTooltipWidth: number;

  shadowId = nanoid();

  saveYTooltipWidth = (yTooltipWidth: number) =>
    (this.yTooltipWidth = yTooltipWidth);

  render() {
    const {
      height,
      histogramWidth,
      y,
      x,
      color,
      xLabel,
      yLabel,
      children,
      ...rest
    } = this.props;
    const yTooltipDirection =
      x + this.yTooltipWidth / 2 > histogramWidth ? 'right' : 'left';

    return (
      <g transform={`translate(${x},0)`} {...rest}>
        <Mark height={height} y={y} color={color} />
        <SVGTooltip
          angle="top"
          text={xLabel}
          color={color}
          transform={`translate(0,${height})`}
        />
        <SVGTooltip
          angle={yTooltipDirection}
          text={yLabel}
          color={color}
          transform={`translate(0,${y})`}
          filter={`url(#${this.shadowId})`}
          getWidth={this.saveYTooltipWidth}
        />
        <SVGShadowFilter id={this.shadowId} blur={1} dx={-1} dy={1} />
      </g>
    );
  }
}
