import React from 'react';
import { isNil } from 'ramda';

interface RenderProps {
  open: boolean;
  toggleOpen(open?: boolean): void;
}

interface Props {
  children(props: RenderProps): React.ReactElement<any>;
}

interface State {
  open: boolean;
}

class Openable extends React.Component<Props, State> {
  state = {
    open: false,
  };

  toggleOpen = (open?: boolean) =>
    this.setState({ open: isNil(open) ? !this.state.open : open });

  render() {
    return this.props.children({
      open: this.state.open,
      toggleOpen: this.toggleOpen,
    });
  }
}

export default Openable;
