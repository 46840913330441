import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@hm/ukie';

import { getActive, GlobalAlerts } from '../../reducers/alertStack';
import alerts from './alerts';
import { AppState } from '../../models';
import { fetchBillingIfMissing } from '../../reducers/billing';

interface OwnProps {
  selected: GlobalAlerts[];
}

interface StateProps {
  activeAlert: GlobalAlerts;
}

interface DispatchProps {
  fetchBillingIfMissing(): void;
}

type Props = StateProps & OwnProps & DispatchProps;

class GlobalAlert extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchBillingIfMissing();
  }

  render() {
    const { selected, activeAlert } = this.props;

    if (!selected.includes(activeAlert)) return null;
    const message = alerts[activeAlert];

    return (
      <Text is="div" lineHeight="text">
        {message}
      </Text>
    );
  }
}

export default connect(
  (state: AppState, props: OwnProps) => ({
    activeAlert: getActive(state),
  }),
  { fetchBillingIfMissing },
)(GlobalAlert);
