import { schema } from 'normalizr';

import createEntityReducer, {
  createActionTypes,
  createEntitySelectors,
  State,
  DestroyActionCreator,
} from '../createEntityReducer';
import { get, destroy, post } from '../../api/rest';

const prefix = 'datasets';
const URL = '/import/uploads';

export const datasetSchema = new schema.Entity(prefix);

export const types = {
  ...createActionTypes(prefix),
  REUSE: 'datasets/REUSE',
};

export const fetchDatasets = () => ({
  type: types.LIST,
  payload: get(URL),
  meta: { schema: [datasetSchema] },
});

export const deleteDataset: DestroyActionCreator = id => ({
  type: types.DESTROY,
  payload: {
    ...destroy(`${URL}/${id}`),
    data: id,
  },
});

export const reuseDataset = (id: string) => ({
  type: types.REUSE,
  payload: post(`${URL}/${id}/reuse`),
});

export default createEntityReducer(prefix);

export interface Dataset {
  id: string;
  filename: string;
  size: number;
  createdAt: string;
}

export type DatasetsState = State<Dataset>;

export const selectors = createEntitySelectors(prefix);
