/*
 * This file holds all global interfaces used in app.
 * This interfaces describe data handled by app and it’s state.
 * Component specific interfaces should not be added here.
 *
 * TODO: maybe we should extract interfaces to appropriate reducer files?
 */

import { ScaleLinear } from 'd3-scale';
import { Moment } from 'moment';

import { AbrState } from './reducers/abr';
import { AlertsState } from './reducers/alerts';
import { BaseLayersState } from './reducers/baseLayers';
import { BillingState } from './reducers/billing';
import { CalendarState } from './reducers/calendar';
import { CategoriesState } from './reducers/categories';
import { CompassState } from './reducers/compass';
import { DatasetsState } from './reducers/datasets';
import { DefaultObjectParamsState } from './reducers/defaultObjectParams';
import { DevState } from './reducers/dev';
import { EffectsState } from './reducers/effects';
import { EnvironmentsState } from './reducers/environments';
import { ExplorerState } from './reducers/explorer';
import { FeaturesState } from './reducers/features';
import { GradientsState } from './reducers/gradients';
import { InspectorState } from './reducers/inspector';
import { LayerAnomaliesState } from './reducers/layerAnomalies';
import { LayersState } from './reducers/layers';
import { ModalsState } from './reducers/modals';
import { OptionsState } from './reducers/options';
import { Project } from './reducers/currentProject';
import { ProjectsState } from './reducers/projects';
import { Search } from './reducers/search';
import { SelectedFragmentsState } from './reducers/selectedFragments';
import { SelectedObjectsState } from './reducers/selectedObjects';
import { SidebarState } from './reducers/sidebar';
import { State as EntityState } from './reducers/createEntityReducer';
import { Timeline } from './reducers/timeline';
import { UserInfo } from './reducers/user';
import { Volumer } from './reducers/volumer';
import { WorkspacesState } from './reducers/workspaces';

export interface AppState {
  abr: AbrState;
  alerts: AlertsState;
  baseLayers: BaseLayersState;
  billing: BillingState;
  calendar: CalendarState;
  categories: CategoriesState;
  compass: CompassState;
  datasets: DatasetsState;
  defaultObjectParams: DefaultObjectParamsState;
  dev: DevState;
  editors: User[];
  effects: EffectsState;
  environments: EnvironmentsState;
  explorer: ExplorerState;
  features: FeaturesState;
  forms: any; // TODO: dig up real thing
  gradients: GradientsState;
  inspector: InspectorState;
  layerAnomalies: LayerAnomaliesState;
  layerImport: any;
  layers: LayersState;
  login: Dict<any>;
  modals: ModalsState;
  options: OptionsState;
  project: Project;
  projects: ProjectsState;
  search: Search;
  selectedFragments: SelectedFragmentsState;
  selectedObjects: SelectedObjectsState;
  sidebar: SidebarState;
  signup: Dict<any>;
  timeline: Timeline;
  title: string;
  tool: string;
  user: UserInfo;
  users: EntityState<User>;
  volumer: Volumer;
  workspaces: WorkspacesState;
}

export type CameraMode = '2D' | '3D';

export type PlaybackMode = 'single' | 'range';

export type PlaybackBehaviour = 'steps' | 'growth';

export interface Anomaly {
  id: string;
  type: string;
  description: string;
  value: number;
  timestamp: Moment;
}

export interface GeoPoint {
  lat: number;
  lon: number;
}

export interface Point {
  x: number;
  y: number;
}

export interface GeoBounds {
  cornerTopLeft: GeoPoint;
  cornerBottomRight: GeoPoint;
}

export interface Resolution {
  width: number;
  height: number;
}

export interface HistogramData {
  x: number;
  y: number;
}

export interface Pointer extends Point {
  multipleTouches?: boolean;
  button?: string;
  altKey: boolean;
  shiftKey: boolean;
  ctrlKey: boolean;
  offCanvas: boolean;
}

export interface GrabbedObject {
  id: string;
  type: number;
}

export interface MomentScale {
  (value: Moment): number;
  range(): number[];
  domain(): any[];
  invert(coordinate: number): Moment;
  selectRange(range: Moment[]): Promise<any>;
}

export interface LinearScales {
  x?: ScaleLinear<number, number>;
  y?: ScaleLinear<number, number>;
}

export interface MinMax {
  min: number;
  max: number;
}

export interface LayerAnomaly {
  id?: string;
  type: string;
  value: number;
  timestamp: string;
  description?: string;
}

export interface Effect {
  id: string;
  label: string;
  /** What kind of effect is this */
  type: string;
  /** Is effect enabled */
  enabled: boolean;
  /** Object of effect settings. This fields is dynamic and corresponds with type of Effect */
  settings: any;
  /** Which layer this effect belongs to */
  layerId: string;
}

export interface DaysOfSignificance {
  name: string;
  description: string;
  /** Array of ISO dates */
  dates: string[];
  id: string;
}

export interface Option {
  id: string;
  label: string;
  description?: string;
  icon?: string;
  enabled: boolean;
}

export interface TimelineTick {
  value: Moment;
  type: string;
}

export interface TickCollisions {
  self: TimelineTick;
  collidingTicks: TimelineTick[];
}

export type ZoomLevel = 'year' | 'month' | 'day' | 'precise';

export enum Role {
  Owner = 'owner',
  Editor = 'editor',
}

export interface User {
  id: string;
  username?: string;
  name?: string;
  email: string;
  avatarUrl?: string;
  defaultColor: string;
}

export type SubscriptionStatus = 'active' | 'past_due' | 'canceled';

export interface UserStorage {
  limited: boolean;
  capacity: number;
  used: number;
}

export interface UserWorkspace {
  admin: boolean;
  dmser: boolean;
  owner: boolean;
  billing: {
    enabled: boolean;
    status: SubscriptionStatus;
    renderAllowed: boolean;
  };
  storage: UserStorage;
}

export interface CurrentUser extends User {
  agreedToReceiveMarketingEmails: boolean;
  emailConfirmed: boolean;
  info: string;
  stripeToken: string;
  workspace: UserWorkspace;
  autoQualityEnabled: boolean;
}

export interface WelcomeProject {
  projectId: string;
  environmentName: string;
  welcomeImageUrl: string;
}

export interface SignIn {
  email: string;
  password: string;
}

export interface PasswordConfirmation {
  password: string;
  passwordConfirmation: string;
}

export interface PasswordUpdate extends PasswordConfirmation {
  oldPassword: string;
}

export interface PasswordReset extends PasswordConfirmation {
  token: string;
}

export interface EmailUpdate {
  password: string;
  email: string;
}

export enum AbrDecision {
  idle = 'idle',
  scaleUp = 'scaleUp',
  scaleDown = 'scaleDown',
}

export interface ApiError {
  status: number;
  code: string;
  detail?: string;
}

export interface StripeError {
  code: string;
  message: string;
  param: string;
  type: string;
}

export interface ApiStripeError extends ApiError {
  stripeError: StripeError;
}
