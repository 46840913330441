export enum Tool {
  NAVIGATION = 'navigation',
  CURSOR = 'cursor',
  MARKER = 'marker',
  ROAD = 'road',
  POLYLINE = 'polyline',
  POLYGON = 'polygon',
  RADIUS = 'radius',
}

export const creatorTools = [
  Tool.MARKER,
  Tool.ROAD,
  Tool.POLYLINE,
  Tool.POLYGON,
  Tool.RADIUS,
];
