import React from 'react';
import { Flex, Box } from 'grid-styled';
import { connect } from 'react-redux';
import { Scrollable, Absolute } from '@hm/ukie';
import Idle from 'react-idle';

import { RelativeFlex } from './Relative';
import Sidebar from './Sidebar';
import Inspector from './Inspector';
import DisplayControls from './DisplayControls';

import ObjectUpload from './ObjectUpload';
import Timeline from '../containers/TimelineContainer';
import Compass from '../containers/CompassContainer';
import Volumer from './Volumer';
import ProjectMenu from '../containers/ProjectMenuContainer';
import CurrentModal from '../containers/CurrentModal';
import Tools from './Tools';
import TimelineActions from '../containers/TimelineActions';
import Alert from '../containers/Alert';
import ProjectTitle from './ProjectTitle';

import { disconnectInactive } from '../actions/user';
import { shouldShow } from '../selectors/inspector';
import { AppState } from '../models';

const DISCONNECT_TIMEOUT = 15 * 60 * 1000;

const Top = RelativeFlex.extend`
  background: ${({ theme }) => theme.colors.volumer};
  height: 100%;
`;

Top.displayName = 'LayoutTop';

interface Props {
  showInspector: boolean;
  onIdle(): void;
}

export const Project: React.SFC<Props> = ({ showInspector, onIdle }) => (
  <div className="layout">
    <div className="layout__left">
      <Top>
        <ObjectUpload>
          <React.Fragment>
            <Volumer />

            <Flex m={2} flex="1" style={{ position: 'absolute' }}>
              <Box flex="0 0 auto" mr={1}>
                <Box mb={2}>
                  <ProjectMenu />
                </Box>
                <Tools />
              </Box>

              {showInspector ? (
                <Box flex="0 0 auto" mr={1}>
                  <Inspector />
                </Box>
              ) : null}
              <Box flex="0 1 300px" ml="auto">
                <DisplayControls />
              </Box>
            </Flex>

            <Absolute bottom={16} right={16}>
              <Compass />
            </Absolute>
          </React.Fragment>
        </ObjectUpload>
      </Top>

      <div className="layout__bottom">
        <div className="layout__bottom-inner">
          <div className="layout__bottom-left">
            <TimelineActions />
          </div>
          <div className="layout__bottom-right">
            <Scrollable>
              <Timeline />
            </Scrollable>
          </div>
        </div>
      </div>
    </div>
    <Sidebar />
    <CurrentModal />
    <Alert />
    <ProjectTitle />
    <Idle
      timeout={DISCONNECT_TIMEOUT}
      onChange={({ idle }) => idle && onIdle()}
    />
  </div>
);

export default connect(
  (state: AppState) => ({
    showInspector: shouldShow(state),
  }),
  { onIdle: disconnectInactive },
)(Project);
