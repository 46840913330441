export const SHOW_ALERT = 'alerts/show';
export const HIDE_ALERT = 'alerts/hide';

export const showAlert = (text: string) => ({
  type: SHOW_ALERT,
  payload: text,
});

export const hideAlert = () => ({
  type: HIDE_ALERT,
});

export const types = {
  SHOW_ALERT,
  HIDE_ALERT,
};
