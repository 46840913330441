import {
  BorderBox,
  Button,
  CheckboxToggle,
  FillFlex,
  Footer,
  RadioButtonGroup,
  Scrollable,
  Select as _Select,
  Submit,
  Text,
  ResizeWatcher,
} from '@hm/ukie';
import { Box, Flex } from 'grid-styled';
import moment from 'moment';
import { flip, gt, path } from 'ramda';
import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Control, Form, actions } from 'react-redux-form';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import sliceDurations from '../../constants/sliceDurations';
import { Environment } from '../../reducers/environments';
import { blurOnEnter } from '../../utils/eventHelper';
import { capitalize } from '../../utils/helpers';
import { prettyTimezone, tzNames } from '../../utils/time';
import { isLat, isLon, isRequired } from '../../utils/validators';
import Collapsible from '../Collapsible';
import { Field, CustomControlText, mapInvalidProp } from '../Form';
import Map, { Marker } from '../Map';
import TimeInterval from '../TimeInterval';
import BoundingBox from './BoundingBox';
import CreateEnvironment from './CreateEnvironment';
import SelectEnvironment from './SelectEnvironment';
import SelectedTimeInterval from './SelectedTimeInterval';
import FeatureToggle from '../FeatureToggle';

const CALCULATION_FUNCTIONS = ['concentration', 'average', 'sum'];

const StyledForm = Flex.withComponent('form').extend.attrs({
  id: 'metricForm',
})`
  flex-direction: column;
  height: 100%;
`;

const Select = styled(_Select)`
  display: flex;
`;

const asyncPersistValidateFor = (
  attr: string,
  id: string,
  onUpdate: (id: string, updates: any) => Promise<{ value: any; action: any }>,
) => (value, done) => {
  onUpdate(id, { [attr]: value }).then(({ value: res }) => {
    done(res[`is${capitalize(attr)}Valid`]);
  });
};

interface Params {
  uploadId: string;
}

interface Props {
  layer: any;
  environments: Dict<Environment>;
  isSubmitting?: boolean;
  model: any;
  isValid: boolean;
  onUpdate(id: string, updates: any): Promise<any>;
  onReset(id: string): void;
  onSubmit(layer: any): void;
}

export default class MetricConfig extends React.Component<
  Props & RouteComponentProps<Params>
> {
  renderOptionsFor = (field: string) => {
    const { layer } = this.props;

    return [
      <F
        id="MetricConfig.suggestedFields"
        defaultMessage="Suggested fields"
        key="suggested"
      >
        {(label: string) => <option value="suggested" disabled label={label} />}
      </F>,
      ...layer[`${field}Candidates`].map((f, i) => (
        <option value={f} key={i} label={f} />
      )),

      <F id="MetricConfig.otherFields" defaultMessage="Other fields" key="rest">
        {(label: string) => (
          <option value="rest" disabled key="rest" label={label} />
        )}
      </F>,
      ...layer.fields.map((f, i) => (
        <option value={f} key={`rest${i}`} label={f} />
      )),
    ];
  };

  render() {
    const {
      layer,
      onUpdate,
      environments,
      onSubmit,
      isSubmitting,
      onReset,
      isValid,
      model,
    } = this.props;

    const changeActionFor = (attr: string) => (name, value) => {
      onUpdate(layer.id, { [attr]: value });
      return actions.change(name, value);
    };

    const selectedEnvironment = environments[model.environmentId];

    const layerBounds = [
      [layer.environmentLeftLon, layer.environmentTopLat],
      [layer.environmentRightLon, layer.environmentBottomLat],
    ];

    const environmentBounds = [
      [
        path(['geoBounds', 'cornerBottomRight', 'lon'], selectedEnvironment),
        path(['geoBounds', 'cornerTopLeft', 'lat'], selectedEnvironment),
      ],
      [
        path(['geoBounds', 'cornerTopLeft', 'lon'], selectedEnvironment),
        path(['geoBounds', 'cornerBottomRight', 'lat'], selectedEnvironment),
      ],
    ];

    const selectEnvironment = !model.createEnvironment && selectedEnvironment;
    const shouldShowEnvironmentFields =
      model.createEnvironment || selectEnvironment;

    return (
      <FillFlex flexDirection="column">
        <Flex flex={1}>
          <Box flex={1}>
            {layer ? (
              <ResizeWatcher style={{ width: '100%', height: '100%' }}>
                {({ width, height }) => (
                  <Map
                    width={width}
                    height={height}
                    initialBounds={
                      model.createEnvironment
                        ? // show layer area when creating new environment
                          layerBounds
                        : // if there is selected environment show it's area
                          selectedEnvironment
                          ? environmentBounds
                          : layerBounds
                    }
                    initialBoundsPadding={40}
                  >
                    {layer.points.map(({ lat, lon }, i) => (
                      <Marker latitude={lat} longitude={lon} key={i} />
                    ))}

                    {model.createEnvironment ? (
                      <BoundingBox
                        topLat={layer.environmentTopLat}
                        rightLon={layer.environmentRightLon}
                        bottomLat={layer.environmentBottomLat}
                        leftLon={layer.environmentLeftLon}
                      />
                    ) : selectedEnvironment ? (
                      <BoundingBox
                        topLat={selectedEnvironment.geoBounds.cornerTopLeft.lat}
                        rightLon={
                          selectedEnvironment.geoBounds.cornerBottomRight.lon
                        }
                        bottomLat={
                          selectedEnvironment.geoBounds.cornerBottomRight.lat
                        }
                        leftLon={
                          selectedEnvironment.geoBounds.cornerTopLeft.lon
                        }
                      />
                    ) : null}
                  </Map>
                )}
              </ResizeWatcher>
            ) : null}
          </Box>

          <BorderBox
            borderLeft="1px solid"
            flex="0 0 auto"
            width={[0, 400, 400, 560]}
          >
            {layer ? (
              <Form
                model="layerImport"
                component={StyledForm}
                onSubmit={onSubmit}
                onChange={m => this.setState({ model: m })}
                validators={{
                  selectedTopLat: isLat,
                  selectedRightLon: isLon,
                  selectedBottomLat: isLat,
                  selectedLeftLon: isLon,
                  cellSize: flip(gt)(0),
                  label: isRequired,
                  selectedTimeInterval: val => val && val[0] && val[1],
                  ...(model.createEnvironment
                    ? { environmentName: isRequired }
                    : undefined),
                }}
              >
                <Scrollable>
                  <Box p={2} style={{ overflowY: 'auto' }}>
                    <Field>
                      <CustomControlText
                        controlProps={{ titleFont: true }}
                        placeholder="Metric name"
                        model=".label"
                        changeAction={changeActionFor('label')}
                        onKeyPress={blurOnEnter}
                      />
                    </Field>
                    <Collapsible title="Primary settings" isOpen>
                      <Box>
                        <Field
                          label={
                            <F
                              id="MetricConfig.sourceFile"
                              defaultMessage="Source file"
                            />
                          }
                        >
                          <Text.Important is="div">
                            {layer.filename}
                          </Text.Important>
                        </Field>
                        <Field label="Latitude">
                          <Control.select
                            component={Select}
                            model=".latitude"
                            mapProps={{ invalid: mapInvalidProp }}
                            asyncValidateOn="change"
                            asyncValidators={{
                              isValid: asyncPersistValidateFor(
                                'latitude',
                                layer.id,
                                onUpdate,
                              ),
                            }}
                            onKeyPress={blurOnEnter}
                          >
                            {this.renderOptionsFor('latitude')}
                          </Control.select>
                        </Field>

                        <Field
                          label={
                            <F
                              id="MetricConfig.longitude"
                              defaultMessage="Longitude"
                            />
                          }
                        >
                          <Control.select
                            component={Select}
                            model=".longitude"
                            mapProps={{ invalid: mapInvalidProp }}
                            asyncValidateOn="change"
                            asyncValidators={{
                              isValid: asyncPersistValidateFor(
                                'longitude',
                                layer.id,
                                onUpdate,
                              ),
                            }}
                            onKeyPress={blurOnEnter}
                          >
                            {this.renderOptionsFor('longitude')}
                          </Control.select>
                        </Field>

                        <FeatureToggle feature="staticLayer">
                          <Field>
                            <Control.checkbox
                              component={CheckboxToggle}
                              model=".timeBased"
                              controlProps={{ children: 'Time based Metric' }}
                              onKeyPress={blurOnEnter}
                            />
                          </Field>
                        </FeatureToggle>

                        <Field
                          label={
                            <F id="MetricConfig.time" defaultMessage="Time" />
                          }
                        >
                          <Control.select
                            component={Select}
                            model=".time"
                            mapProps={{ invalid: mapInvalidProp }}
                            asyncValidateOn="change"
                            asyncValidators={{
                              isValid: asyncPersistValidateFor(
                                'time',
                                layer.id,
                                onUpdate,
                              ),
                            }}
                            onKeyPress={blurOnEnter}
                          >
                            {this.renderOptionsFor('time')}
                          </Control.select>
                        </Field>

                        <Field
                          label={
                            <F
                              id="MetricConfig.calculation"
                              defaultMessage="Value calculation function"
                            />
                          }
                        >
                          <Control.select
                            model=".calculation"
                            component={Select}
                            changeAction={changeActionFor('calculation')}
                            onKeyPress={blurOnEnter}
                          >
                            {CALCULATION_FUNCTIONS.map(fn => (
                              <option
                                value={fn}
                                label={capitalize(fn)}
                                key={fn}
                              />
                            ))}
                          </Control.select>
                        </Field>

                        {/* TODO: enums */}
                        {layer.calculation !== 'concentration' ? (
                          <Field
                            label={
                              <F
                                id="MetricConfig.weight"
                                defaultMessage="Weight"
                              />
                            }
                          >
                            <Control.select
                              component={Select}
                              model=".weight"
                              mapProps={{ invalid: mapInvalidProp }}
                              asyncValidateOn="change"
                              asyncValidators={{
                                isValid: asyncPersistValidateFor(
                                  'weight',
                                  layer.id,
                                  onUpdate,
                                ),
                              }}
                              onKeyPress={blurOnEnter}
                            >
                              {this.renderOptionsFor('weight')}
                            </Control.select>
                          </Field>
                        ) : null}
                      </Box>
                    </Collapsible>

                    <Collapsible title="Space parameters" isOpen>
                      <Box pt={2}>
                        <Control.custom
                          model=".createEnvironment"
                          component={RadioButtonGroup}
                          mapProps={
                            {
                              selected: ({ modelValue }) =>
                                modelValue === true ? 'create' : 'select',
                              onClick: ({ onChange }) => val =>
                                onChange(val === 'create'),
                            } as any
                          }
                          changeAction={changeActionFor('createEnvironment')}
                        >
                          <Button value="select">
                            <F
                              id="MetricConfig.selectEnvironment"
                              defaultMessage="Select environment"
                            />
                          </Button>
                          <Button value="create">
                            <F
                              id="MetricConfig.createEnvironment"
                              defaultMessage="Create environment"
                            />
                          </Button>
                        </Control.custom>

                        {model.createEnvironment ? (
                          <CreateEnvironment
                            layer={layer}
                            changeActionFor={changeActionFor}
                            onReset={() => onReset(layer.id)}
                          />
                        ) : (
                          <SelectEnvironment
                            changeActionFor={changeActionFor}
                            environments={environments}
                            selectedId={model.environmentId}
                          />
                        )}
                      </Box>
                    </Collapsible>

                    {layer.timeBased ? (
                      <Collapsible title="Time parameters" isOpen>
                        <Box>
                          {shouldShowEnvironmentFields ? (
                            <Field
                              label={
                                <F
                                  id="MetricConfig.environmentTimezone"
                                  defaultMessage="Environment timezone"
                                />
                              }
                            >
                              {model.createEnvironment ? (
                                <Control.select
                                  component={Select}
                                  model=".environmentTimezone"
                                  changeAction={changeActionFor(
                                    'environmentTimezone',
                                  )}
                                  onKeyPress={blurOnEnter}
                                >
                                  {tzNames.map((timezone, i) => (
                                    <option
                                      value={timezone}
                                      key={i}
                                      label={prettyTimezone(timezone)}
                                    />
                                  ))}
                                </Control.select>
                              ) : (
                                <Text.Important is="div">
                                  {prettyTimezone(selectedEnvironment.timezone)}
                                </Text.Important>
                              )}
                            </Field>
                          ) : null}

                          <Field
                            label={
                              <F
                                id="MetricConfig.sliceDuration"
                                defaultMessage="Metric slice duration"
                              />
                            }
                          >
                            <Control.select
                              component={Select}
                              model=".sliceDuration"
                              changeAction={changeActionFor('sliceDuration')}
                              onKeyPress={blurOnEnter}
                            >
                              {sliceDurations.map(duration => (
                                <option
                                  value={duration}
                                  key={duration}
                                  label={moment.duration(duration).humanize()}
                                />
                              ))}
                            </Control.select>
                          </Field>

                          {shouldShowEnvironmentFields ? (
                            <Field
                              label={
                                <F
                                  id="MetricConfig.availableTimeInterval"
                                  defaultMessage="Available time interval"
                                />
                              }
                            >
                              <Text.Important is="div">
                                <TimeInterval
                                  interval={layer.availableTimeInterval}
                                  timezone={
                                    model.createEnvironment
                                      ? model.environmentTimezone
                                      : environments[model.environmentId]
                                          .timezone
                                  }
                                />
                              </Text.Important>
                            </Field>
                          ) : null}

                          {shouldShowEnvironmentFields ? (
                            <Field
                              label={
                                <F
                                  id="MetricConfig.selectedTimeInterval"
                                  defaultMessage="Selected time interval"
                                />
                              }
                            >
                              <SelectedTimeInterval
                                model=".selectedTimeInterval"
                                availableInterval={layer.availableTimeInterval}
                                onUpdate={selectedTimeInterval =>
                                  onUpdate(layer.id, { selectedTimeInterval })
                                }
                                timezone={
                                  model.createEnvironment
                                    ? layer.environmentTimezone
                                    : environments[model.environmentId].timezone
                                }
                              />
                            </Field>
                          ) : null}
                        </Box>
                      </Collapsible>
                    ) : null}
                  </Box>
                </Scrollable>
              </Form>
            ) : null}
          </BorderBox>
        </Flex>
        <Footer
          actions={
            <Submit form="metricForm" disabled={isSubmitting || !isValid}>
              <F id="MetricConfig.submit" defaultMessage="Import" />
            </Submit>
          }
        />
      </FillFlex>
    );
  }
}
