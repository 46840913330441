import { head, any, equals, not, pluck, find, compose, length } from 'ramda';
import { createSelector } from 'reselect';
import { AppState } from '../models';
import { MapObject } from '../reducers/selectedObjects';
import { getSelectedFragments } from './selectedFragments';

export const getSelectedObjects = (state: AppState): MapObject[] =>
  Object.values(state.selectedObjects);

export const getDraftObject = createSelector(
  getSelectedObjects,
  find((obj: MapObject) => obj.draft),
);

export const getObjectInEditMode = createSelector(
  getSelectedObjects,
  find((obj: MapObject) => obj.editMode),
);

export const getSelectedObjectIds = createSelector(
  getSelectedObjects,
  pluck('id'),
);

export const isEditMode = createSelector(
  getSelectedObjects,
  any((o: MapObject) => o.editMode),
);

export const getSelectedEntities = createSelector(
  getSelectedObjects,
  getSelectedFragments,
  isEditMode,
  (objects, fragments, editMode) => (editMode ? fragments : objects),
);

export const anyDraftObjects = createSelector(
  getSelectedObjects,
  any((o: MapObject) => o.draft),
);

export const areAnyObjectsSelected = createSelector(
  getSelectedObjectIds,
  compose(not, equals(0), length),
);

export const getFirstObject = createSelector<AppState, MapObject[], MapObject>(
  getSelectedObjects,
  head,
);
