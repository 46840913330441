import { connect } from 'react-redux';
import { showNewLayer } from '../../actions/modals';
import {
  getAvailableLayers,
  getIsFetching,
  getCurrentProjectId,
} from '../../selectors/project';
import { AppState } from '../../models';

import MetricPanel from './MetricPanel';

export default connect(
  (state: AppState) => ({
    layerIds: getAvailableLayers(state),
    canAddLayer: !getIsFetching(state) || Boolean(getCurrentProjectId(state)),
  }),
  { onLayerAdd: showNewLayer },
)(MetricPanel);
