import React from 'react';
import styled from 'styled-components';
import ZoomLevelSelector from '../ZoomLevelSelector';
import { ZoomLevel } from '../../models';

const Slider = styled(ZoomLevelSelector)`
  width: 144px;
  margin: 10px 20px 0 20px;
`;

interface Props {
  zoomLevel: ZoomLevel;
  className?: string;
  onZoomLevelChange(value: ZoomLevel): void;
}

const InnerFooter: React.SFC<Props> = ({
  zoomLevel,
  onZoomLevelChange,
  className,
}) => {
  return (
    <div className={className}>
      <Slider value={zoomLevel} onChange={onZoomLevelChange} />
    </div>
  );
};

export default styled(InnerFooter)`
  height: 44px;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.grey5};
  display: flex;
  align-items: center;
  padding: 0 40px;
  margin-top: auto;
`;
