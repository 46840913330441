import React from 'react';
import { FormattedMessage as F, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { GlobalAlerts } from '../../reducers/alertStack';

const common = defineMessages({
  billing: {
    id: 'GlobalAlert.billing',
    defaultMessage: 'Payment page',
  },
  plan: {
    id: 'GlobalAlert.plan',
    defaultMessage: 'Plan page',
  },
});

export const alerts: { [A in GlobalAlerts]: JSX.Element } = {
  invoiceError: (
    <F
      id="GlobalAlert.invoiceError"
      defaultMessage={`Unfortunately, the last invoice was unsuccessful and full access to Chronotope is unavailable.\nPlease, update your payment source on {page} to pay for the subscription.`}
      values={{
        page: (
          <Link to="/profile/payment" key="linkToBillingInvoice">
            {' '}
            <F {...common.billing} />{' '}
          </Link>
        ),
      }}
    />
  ),
  inactiveWithoutPayment: (
    <F
      id="GlobalAlert.inactiveWithoutPayment"
      defaultMessage={`Unfortunately, the full access to Chronotope is unavailable for unsubscribed users.\nPlease, update your payment source on {page} to pay for the subscription.`}
      values={{
        page: (
          <Link to="/profile/payment" key="linkToPayment">
            <F {...common.billing} />
          </Link>
        ),
      }}
    />
  ),
  inactiveWithPayment: (
    <F
      id="GlobalAlert.inactiveWithPayment"
      defaultMessage={`Unfortunately, the full access to Chronotope is unavailable for unsubscribed users.\nPlease, subscribe to Chronotope on the {page} to start working.`}
      values={{
        page: (
          <Link to="/profile/plan" key="linkToPlanInactive">
            <F {...common.plan} />
          </Link>
        ),
      }}
    />
  ),
  noHoursLeft: (
    <F
      id="GlobalAlert.noHours"
      defaultMessage={`The limit of month’s session hours have exceeded.\nEnlarge it in {page} to continue working with Chronotope`}
      values={{
        page: (
          <Link to="/profile/plan" key="linkToPlanHours">
            <F {...common.plan} />
          </Link>
        ),
      }}
    />
  ),
};

export default alerts;
