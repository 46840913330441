import { takeLatest, select, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import {
  getSliceDuration,
  getSelectedIntervals,
  getCalendarLabel,
  getType,
  getApproximationType,
  getStartTime,
  getTimeFilters,
} from '../../selectors/calendar';

import { getSelectedEnvironmentId } from '../../selectors/environments';
import { createProject } from '../../actions/environments';
import { SUBMIT_PROJECT } from '../../actions/calendar';
import { hideModal } from '../../actions/modals';
import { toUTCArray } from '../../utils/time';
import { toAPIFilters } from '../../utils/helpers';

export default function*() {
  yield takeLatest(SUBMIT_PROJECT, function*() {
    const newProjectType = yield select(getType);

    const { value: { id } } = yield put.resolve(
      createProject(yield select(getSelectedEnvironmentId), {
        type: newProjectType,
        sliceDuration: yield select(getSliceDuration),
        selectedTimeIntervals: (yield select(getSelectedIntervals)).map(
          toUTCArray,
        ),
        label: yield select(getCalendarLabel),
        ...(newProjectType === 'approximated'
          ? {
              approximationType: yield select(getApproximationType),
              startTime: yield select(getStartTime),
            }
          : undefined),
        timeFilters: toAPIFilters(yield select(getTimeFilters)),
      }),
    );

    yield put(hideModal());
    yield put(push(`/projects/${id}`));
  });
}
