import moment from 'moment';
import jwt_decode from 'jwt-decode';

const accessKey = 'accessToken';
const refreshKey = 'refreshToken';

export const isLoggedIn = () => {
  const refreshToken = getRefreshToken();

  return !!refreshToken && isTokenFresh(refreshToken);
};

export const getAccessToken = () => localStorage.getItem(accessKey);
export const setAccessToken = (token: string) =>
  localStorage.setItem(accessKey, token);

export const getRefreshToken = () => localStorage.getItem(refreshKey);
export const setRefreshToken = (token: string) =>
  localStorage.setItem(refreshKey, token);

export const saveTokens = (accessToken: string, refreshToken: string) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

export const clearTokens = () => {
  localStorage.removeItem(refreshKey);
  localStorage.removeItem(accessKey);
};

interface AccessToken {
  exp: number;
  iat: number;
  nbf: number;
  type: string;
  identity: string;
  session: string;
}

export const expiresAt = (token: string) =>
  // FIXME: this is insane
  process.env.ENV === 'storybook'
    ? moment().add(1, 'month')
    : moment.unix(jwt_decode<AccessToken>(token).exp);

export const isTokenFresh = (token: string, checkBefore = 0) => {
  return expiresAt(token).diff(moment(), 'ms') > checkBefore;
};

export const getUserId = () => {
  const token = getAccessToken();
  return token && jwt_decode<AccessToken>(token).identity;
};

export const getAuthHeader = () => `Bearer ${getAccessToken()}`;
