import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { getIsLoggedIn } from '../selectors/user';
import { AppState } from '../models';
import RouteIf from './RouteIf';

const AuthRoute: React.SFC<{ isLoggedIn: boolean } & RouteProps> = ({
  isLoggedIn,
  ...rest
}) => (
  <RouteIf
    conditions={[isLoggedIn]}
    fallbacks={[
      <Redirect
        key="logged"
        to={{
          pathname: '/login',
          state: { from: rest.location },
        }}
      />,
    ]}
    {...rest}
  />
);

// TODO: check if works
export default connect((state: AppState, ownProps: RouteProps) => ({
  isLoggedIn: getIsLoggedIn(state),
}))(AuthRoute);
