import { connect } from 'react-redux';
import {
  makeIsAbleToPatternize,
  getLayerById,
  getAbsoluteValuesById,
} from '../../selectors/layers';
import { createEffect, deleteEffect } from '../../actions/effects';
import { deleteLayer } from '../../actions/layers';
import { addHighlight } from '../../reducers/highlights';
import { makeGetEffectsByLayerId } from '../../selectors/effects';
import { AppState } from '../../models';

import Menu, { StateProps, DispatchProps } from './Menu';

interface OwnProps {
  id: string;
}

const makeMapStateToProps = () => {
  const isAbleToPatternize = makeIsAbleToPatternize();
  const getEffectsByLayerId = makeGetEffectsByLayerId();

  return (state: AppState, ownProps: OwnProps) => ({
    layer: getLayerById(state, ownProps),
    effects: getEffectsByLayerId(state, ownProps),
    isAbleToPatternize: isAbleToPatternize(state, { layerId: ownProps.id }),
    absoluteValues: getAbsoluteValuesById(state, ownProps),
  });
};

const mapDispatchToProps = {
  createEffect,
  removeLayer: deleteLayer,
  removeEffect: deleteEffect,
  onHighlightClick: addHighlight,
};

export default connect<StateProps, DispatchProps, any>(
  makeMapStateToProps,
  mapDispatchToProps,
)(Menu);
