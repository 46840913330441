import React from 'react';
import { Dialog, Footer, Submit, Text } from '@hm/ukie';
import { FormattedMessage } from 'react-intl';
import { Flex } from 'grid-styled';

import Header from '../../containers/Calendar/Header';
import Settings from '../../containers/Calendar/Settings';
import InnerFooter from '../../containers/Calendar/InnerFooter';
import Status from '../../containers/Calendar/Status';

import Scrollers from '../Calendar/Scrollers';
import DayRangeFilter from '../Calendar/DayRangeFilter';

import SliceDurations from './SliceDurations';
import { RelativeFlex } from '../Relative';

import { messages, TooManySlicesError } from '../Calendar';
import { Environment } from '../../reducers/environments';
import { BaseLayer } from '../../reducers/baseLayers';
import { AvailableTimeIntervals } from '../Calendar/AvailableTimeIntervals';

export interface StateProps {
  environment: Environment;
  metrics: BaseLayer[];
  tooManySlices: boolean;
  canCreate: boolean;
  sliceDuration: string;
  hasErrors?: boolean;
}

export interface DispatchProps {
  onSubmit(): void;
  onSliceDurationChange(sliceDuration: string): void;
}

export interface OwnProps {
  onClose?(): void;
  onBack?(): void;
}

interface State {
  currentSlide: string;
}

type Props = StateProps & DispatchProps & OwnProps;

export default class NewRegularProject extends React.Component<Props, State> {
  state: State = { currentSlide: null };

  onTimeIntervalsToggle = () => {
    this.setState({
      currentSlide: this.state.currentSlide ? null : 'timeIntervals',
    });
  };

  onHide = () => this.setState({ currentSlide: null });

  render() {
    const {
      environment,
      metrics,
      onBack,
      onSubmit,
      canCreate,
      tooManySlices,
      sliceDuration,
      onSliceDurationChange,
    } = this.props;

    const { currentSlide } = this.state;

    return (
      <Dialog title={<Header />} onBack={onBack}>
        <SliceDurations
          availableSliceDurations={environment.availableSliceDurations}
          selectedSliceDuration={sliceDuration}
          disabled={!!currentSlide}
          onChange={onSliceDurationChange}
        />
        <Flex flex="1">
          <RelativeFlex flex="1" flexDirection="column">
            <Scrollers />
            <InnerFooter />
            <DayRangeFilter
              show={currentSlide === 'timeIntervals'}
              onClose={this.onHide}
            />
          </RelativeFlex>
          <Settings
            info={messages.selectAllRegular}
            currentSlide={currentSlide}
          >
            <FormattedMessage {...messages.availableTimeIntervals}>
              {t => (
                <Text fontSize={2} mb={2} is="div">
                  {t}
                </Text>
              )}
            </FormattedMessage>
            {metrics.map(layer => (
              <AvailableTimeIntervals layer={layer} key={layer.id} />
            ))}
          </Settings>
        </Flex>
        <Footer
          disabled={!!currentSlide}
          info={<Status />}
          warning={tooManySlices ? <TooManySlicesError /> : null}
          actions={
            <Submit onClick={onSubmit} disabled={!canCreate || tooManySlices}>
              <FormattedMessage {...messages.create} />
            </Submit>
          }
        />
      </Dialog>
    );
  }
}
