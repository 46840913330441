import { volumer } from '@hm/volumer-api';
import { Pointer } from '../../models';
import { RaycastSender } from './getRaycastSender';

const { EDGE, POINT } = volumer.types.ObjectType;
const partialObjects = [EDGE, POINT];

export type Hit = volumer.types.Object$Properties &
  Pick<volumer.raycast.ObjectWithDomain$Properties, 'domain'>;

export type Target = 'object' | 'fragment';

/**
 * Returns an object that implements Hit interface (also exported)
 * Sorry no return types for generators in Typescript 2.3
 */
export default function*(
  target: Target,
  pointer: Pointer,
  sendRaycast: RaycastSender,
): IterableIterator<Hit> {
  const { objectHit } = yield* sendRaycast(pointer, [
    volumer.raycast.Type.OBJECT,
  ]);

  if (!objectHit) return null;

  const match = objectHit.objectWithDomain.find(({ object }) => {
    const isFragment = partialObjects.includes(object.type);

    return (
      (target === 'object' && !isFragment) ||
      (target === 'fragment' && isFragment)
    );
  });

  if (!match) return null;

  return { ...match.object, domain: match.domain };
}
