import { Action } from 'redux';
import { takeEvery, call } from 'redux-saga/effects';
import { keys } from 'ramda';
import { types as projectTypes } from '../actions/projects';
import { pend } from '../utils/promise';
import metrics from '../metrics';

const events = {
  [pend(projectTypes.RENAME_PROJECT)]: 'projectRename',
};

export default function*() {
  yield takeEvery<{ payload: any } & Action>(keys(events), function*(action) {
    yield call(metrics.api.track, events[action.type], {
      actionPayload: action.payload,
    });
  });
}
