import React from 'react';
import nanoid from 'nanoid';
import { colors } from '@hm/ukie';

import { Point } from '../../reducers/gradients';
import { getBinScale, getBrushScale } from '../../utils/histogram';
import { HistogramData, MinMax } from '../../models';

import Brush from './CompactBrush';
import LinearHeatmap from './LinearHeatmap';

const DATA_STYLE = {
  shapeRendering: 'crispEdges',
  fill: colors.grey5,
};

interface Props {
  currentBins: HistogramData[];
  gradientPoints: Point[];
  borders: number[];
  selected: MinMax;
  width: number;
  height: number;
  anomalies?: any[];
  transform?: string;
  onBrush?(selected: MinMax);
  onBrushStart?();
  onBrushEnd?();
  saveBrushLabelPositions(positions: ClientRect[]);
}

/**
 * Minimized view of filled layer histogram
 */
export default class FilledHistogramCompact extends React.Component<
  Props,
  never
> {
  static defaultProps = {
    anomalies: [],
  };

  clipPath = nanoid();
  canvas: SVGGElement;

  onBrush = (extent: [number, number]) => {
    const { onBrush } = this.props;
    if (onBrush) onBrush({ min: extent[0], max: extent[1] });
  };

  render() {
    const {
      currentBins,
      selected,
      width,
      height,
      anomalies,
      onBrushStart,
      onBrushEnd,
      saveBrushLabelPositions,
      transform,
      gradientPoints,
      borders,
    } = this.props;
    const { min, max } = selected;

    const heatmapProps = {
      bins: currentBins,
      width,
      height,
      style: DATA_STYLE,
    };

    const binsScale = getBinScale(currentBins.length, width);
    const brushScale = getBrushScale(width);

    return (
      <g transform={transform} ref={el => (this.canvas = el)}>
        <g>
          <LinearHeatmap {...heatmapProps} />
          <LinearHeatmap
            {...heatmapProps}
            gradientPoints={gradientPoints}
            clipPath={selected ? `url(#${this.clipPath})` : null}
          />
          {anomalies.map(anomaly => {
            const x = binsScale(anomaly.x);
            return (
              <line
                key={'anomaly' + anomaly.x}
                x1={x}
                x2={x}
                y2={height}
                style={{ stroke: colors.error }}
              />
            );
          })}
          {this.props.onBrush ? (
            <Brush
              borders={borders}
              width={width}
              minExtent={1 / currentBins.length}
              height={height}
              selected={[min, max]}
              saveLabelPositions={saveBrushLabelPositions}
              boundaries={this.canvas}
              onUpdate={this.onBrush}
              onStart={onBrushStart}
              onStop={onBrushEnd}
            />
          ) : null}
        </g>
        <clipPath id={`${this.clipPath}`}>
          <rect
            width={brushScale(max) - brushScale(min)}
            height={height}
            transform={`translate(${brushScale(min)}, 0)`}
          />
        </clipPath>
      </g>
    );
  }
}
