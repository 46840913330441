import React from 'react';
import { Flex, Box } from 'grid-styled';
import { Dialog, Button, Text } from '@hm/ukie';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

interface Props {
  environment: string;
  onBack?(): void;
  onClose?(): void;
  onRegular(): void;
  onApproximated(): void;
}

const ProjectType: React.SFC<Props> = ({
  environment,
  onBack,
  onClose,
  onRegular,
  onApproximated,
}) => (
  <Dialog
    title={
      <Text.Title>
        <FormattedMessage {...messages.selectType} />
      </Text.Title>
    }
    onBack={onBack}
    onClose={onClose}
  >
    <Flex flexDirection="column" p={3}>
      <Box mb={3} m="auto" style={{ lineHeight: 2 }}>
        <FormattedMessage
          {...messages.startNew}
          values={{
            environment: <Text.Important>{environment}</Text.Important>,
          }}
        />
      </Box>
      <Flex justify="center">
        <Flex flexDirection="column" mr={2} width={296} justify="space-between">
          <Box>
            <Box mb={2}>
              <Text.Important>Regular project</Text.Important>
            </Box>
            <FormattedMessage {...messages.regularDescription} />
          </Box>
          <Box mt={2}>
            <Button primary onClick={onRegular}>
              Create project
            </Button>
          </Box>
        </Flex>
        <Flex flexDirection="column" ml={2} width={296} justify="space-between">
          <Box mb={2}>
            <Text.Important>Approximated project</Text.Important>
          </Box>
          <FormattedMessage {...messages.approximatedDescription} />
          <Box mt={2}>
            <Button primary onClick={onApproximated}>
              Create project
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  </Dialog>
);

export default ProjectType;
