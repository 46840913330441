import { types } from './actions';
import { fulfill } from '../../utils/promise';
import { BillingState } from './types';

export const initialState: BillingState = {} as any;

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.GET_BILLING):
    case fulfill(types.CHANGE_PAYMENT_SOURCE):
    case fulfill(types.UNSUBSCRIBE):
    case fulfill(types.TOGGLE_OVERAGE):
    case fulfill(types.SUBSCRIBE):
      return { ...state, ...payload };

    default:
      return state;
  }
};
