import React from 'react';
import moment from 'moment';
import { MapObject } from '../../reducers/selectedObjects';
import { toHHmm, toDMY } from '../../utils/time';
import Row from './Row';

interface Props {
  object: MapObject;
  isApproximatedProject: boolean;
  timezone: string;
}

const RowTime: React.SFC<Props> = ({
  object,
  timezone,
  isApproximatedProject,
}) => {
  const time = moment.utc(object.datetime).tz(timezone);

  return (
    <Row title="Time">
      {`${toHHmm(time)}`}
      {isApproximatedProject ? null : ` ${toDMY(time)}`}
    </Row>
  );
};

export default RowTime;
