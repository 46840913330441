import { volumer } from '@hm/volumer-api';
import { AbrDecision, AppState } from '../models';

const TOGGLE_ACTIVE = 'abr/TOGGLE_ACTIVE';
const UPDATE_LAST_FRAME_TS = 'abr/UPDATE_LAST_FRAME_TS';
const UPDATE_DECISION = 'abr/UPDATE_DECISION';
const UPDATE_QUALITY = 'abr/UPDATE_QUALITY';

export const types = {
  TOGGLE_ACTIVE,
  UPDATE_LAST_FRAME_TS,
  UPDATE_DECISION,
  UPDATE_QUALITY,
};

export const toggleActive = (active: boolean) => ({
  type: TOGGLE_ACTIVE,
  payload: active,
});

export const updateLastFrameTs = (frameTs: number) => ({
  type: UPDATE_LAST_FRAME_TS,
  payload: frameTs,
});

export const updateDecision = (frameDelay: number) => ({
  type: UPDATE_DECISION,
  payload: frameDelay,
});

export const updateQuality = (
  message: volumer.event.LowresQualityDrag$Properties,
) => ({
  type: UPDATE_QUALITY,
  payload: { message },
});

const maxFrameDelaysSize = 100; // maximum number of frames to keep track of
const minFrameDelaysSize = 10; // minimum number of frames required to make a decision
const minFrameDelayToScaleDown = 50; // assuming 25 fps
const maxFrameDelayToScaleUp = 45;

export interface AbrState {
  active: boolean;
  lastFrameTs: number;
  frameDelays: number[];
  decision: AbrDecision;
}

export const initialState = {
  // prop to turn abr off after updating quality
  // in order to ignore frames of old qualitv
  active: true,

  // timestamp of last received frame
  lastFrameTs: null,

  // frame delays for a number of last frames
  frameDelays: [],

  decision: AbrDecision.idle,
};

// Calculates average excluding minimum and maximum element
const smartAverage = (array: number[]) =>
  (array.reduce((a, b) => a + b, 0) -
    Math.min.apply(null, array) -
    Math.max.apply(null, array)) /
  (array.length - 2);

// Trivial abr decision logic:
//    - if average frame delay is too high  - scaling down
//    - if average frame delay small enough - scaling up
//    - else                                - idle
const makeDecision = (averageFrameDelay: number) => {
  if (averageFrameDelay > minFrameDelayToScaleDown) {
    return AbrDecision.scaleDown;
  } else if (averageFrameDelay < maxFrameDelayToScaleUp) {
    return AbrDecision.scaleUp;
  }
  return AbrDecision.idle;
};

export default (state: AbrState = initialState, action: any): AbrState => {
  const { type, payload } = action;

  switch (type) {
    case types.TOGGLE_ACTIVE:
      return {
        ...state,
        active: payload,
        lastFrameTs: initialState.lastFrameTs,
        frameDelays: initialState.frameDelays,
        decision: initialState.decision,
      };

    case types.UPDATE_LAST_FRAME_TS:
      return { ...state, lastFrameTs: payload };

    case types.UPDATE_DECISION:
      const frameDelay = payload;
      const updatedFrameDelays = state.frameDelays
        .concat([frameDelay])
        .slice(-maxFrameDelaysSize);

      return {
        ...state,
        frameDelays: updatedFrameDelays,
        decision:
          updatedFrameDelays.length > minFrameDelaysSize
            ? makeDecision(smartAverage(updatedFrameDelays))
            : AbrDecision.idle,
      };

    default:
      return state;
  }
};

export const getDecision = (state: AppState) => state.abr.decision;
export const getLastFrameTs = (state: AppState) => state.abr.lastFrameTs;
export const isActive = (state: AppState) => state.abr.active;
