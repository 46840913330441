import { schema } from 'normalizr';
import {
  createEntityReducer,
  createActionTypes,
  createEntitySelectors,
} from './createEntityReducer';
import { get } from '../api/rest';

export interface HighlightType {
  id: string;
  name: string;
  label: string;
}

const prefix = 'highlightTypes';

const highlightTypeSchema = new schema.Entity(prefix);

const actionTypes = createActionTypes(prefix);

export const fetchHighlightTypes = () => ({
  type: actionTypes.LIST,
  payload: get('/highlight_types'),
  meta: { schema: [highlightTypeSchema] },
});

export const highlightTypeSelectors = createEntitySelectors<HighlightType>(
  prefix,
);

export default createEntityReducer(prefix);
