import { Channel } from 'redux-saga';
import { apply, select, takeEvery } from 'redux-saga/effects';
import { volumer } from '@hm/volumer-api';

import { types } from '../../actions/volumer';
import { encodeMessage } from '../../utils/volumerInteractions';
import { getViewportResolution } from '../../selectors/volumer';
import { downloadByURL } from '../../utils/helpers';

export default function*(
  socket: WebSocket,
  responseChannel: Channel<volumer.stream.ScreenshotResponse$Properties>,
) {
  yield takeEvery(types.TAKE_SCREENSHOT, requestScreenshot, socket);
  yield takeEvery(responseChannel, receiveScreenshot);
}

function* requestScreenshot(socket) {
  const { width, height } = yield select(getViewportResolution);

  const message = encodeMessage({
    screenshotRequest: {
      size: { x: width, y: height },
      encoderType: volumer.stream.EncoderType.PNG,
    },
  });

  yield apply(socket, socket.send, [message]);
}

// TODO: implement when Volumer is fixed
function* receiveScreenshot({ screenshotData }) {
  const blob = new Blob([screenshotData], { type: 'image/png' });
  const url = URL.createObjectURL(blob);

  downloadByURL(url, 'screenshot.png');
}
