import {
  compose,
  flip,
  gt,
  divide,
  replace,
  ifElse,
  isNil,
  always,
} from 'ramda';
import { format } from 'd3-format';

const prefixReplace = {
  k: 'K',
  G: 'B',
  T: 't',
  P: 'q',
  E: 'Q',
  Z: 's',
  Y: 'S',
};

const prefixPattern = new RegExp(`[${Object.keys(prefixReplace).join('')}]`);
const prefixReplacer = (w: string) => (prefixReplace[w] ? prefixReplace[w] : w);

const trailingZero = /\.0+\b/;

const nilDecorator = (formatFunction: (val: number) => string) =>
  ifElse(isNil, always('no'), formatFunction);

export const absoluteShort = nilDecorator(
  (val: number) =>
    Math.abs(val) < 1
      ? format('.3f')(val).replace(trailingZero, '')
      : format('.3s')(val)
          .replace(prefixPattern, prefixReplacer)
          .replace(trailingZero, ''),
);

export const absoluteInt = nilDecorator(
  compose(replace(trailingZero, ''), absoluteShort),
);

export const absoluteFull = nilDecorator(format(','));

export const percentFloat = nilDecorator(
  compose(replace(trailingZero, ''), format('.2%')),
);

export const percentFloatImplicit = nilDecorator(
  compose(replace('%', ''), percentFloat),
);

export const m = (val: number) => `${format(',.1f')(val)} m`;

export const km = (val: number) => `${format(',.3f')(val)} km`;

export const square = (formatted: string) => formatted + '²';

export const length = ifElse(gt(1000), m, compose(km, flip(divide)(1000)));

export const area = ifElse(
  gt(1e5),
  compose(square, m),
  compose(square, km, flip(divide)(1e6)),
);
