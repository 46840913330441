import { tail } from 'ramda';
import mapValues from 'lodash.mapvalues';
import { createSelector } from 'reselect';
import { getGradientColors } from './gradients';
import { getAvailableLayers } from './project';
import { Layer } from '../reducers/layers';
import { AppState } from '../models';

export const getEntities = (state: AppState) => state.layers.entities;
export const getLayerById = (state: AppState, { id }: { id: string }) =>
  state.layers.entities[id];
export const isLayersFetching = (state: AppState) => state.layers.isFetching;
export const didLayersFetch = (state: AppState) => state.layers.didFetch;

export const getLayers = createSelector(
  getEntities,
  getAvailableLayers,
  (entities, layersOrder: string[]) => layersOrder.map(id => entities[id]),
);

export const getLayersWithoutId = createSelector(
  getLayers,
  (state, { layerId }) => layerId,
  (layers: Layer[], layerId) => layers.filter(layer => layer.id !== layerId),
);

export const makeIsAbleToPatternize = () =>
  createSelector(getLayersWithoutId, (layers: Layer[]) => layers.length > 0);

export const getLayerLabels = createSelector(getEntities, layers =>
  mapValues(layers, (l: Layer) => l.label),
);

export const getLayerGradientColors = createSelector(
  getEntities,
  getGradientColors,
  (layers, gradientColors) =>
    mapValues(layers, (l: Layer) => gradientColors[l.gradientId]),
);

export const getAbsoluteValuesById = createSelector(
  getLayerById,
  layer => layer.absoluteValues,
);

const getInitialHistogramById = createSelector(
  getLayerById,
  layer => layer.initialHistogram,
);

const getCurrentHistogramById = createSelector(
  getLayerById,
  layer => layer.currentHistogram,
);

export const makeGetInitialBinsById = () =>
  createSelector(getInitialHistogramById, histogram => tail(histogram));

export const makeGetCurrentBinsById = () =>
  createSelector(getCurrentHistogramById, histogram => tail(histogram));

export const makeGetCurrentBordersById = () =>
  createSelector(getCurrentHistogramById, histogram => histogram.map(d => d.x));

export const makeGetCurrentVoidValueById = () =>
  createSelector(getCurrentHistogramById, histogram => histogram[0].y);
