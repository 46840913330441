import { connect } from 'react-redux';
import ObjectUpload from './ObjectUpload';
import { AppState } from '../../models';
import {
  getCurrentEnvironmentId,
  getCurrentProjectId,
} from '../../selectors/project';
import { applyGisImport } from '../../reducers/gisImport';

export default connect(
  (state: AppState) => ({
    environmentId: getCurrentEnvironmentId(state),
    projectId: getCurrentProjectId(state),
  }),
  {
    onSubmit: applyGisImport,
  },
)(ObjectUpload);
