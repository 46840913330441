import { path, values, filter, where, contains, sortBy, prop } from 'ramda';
import { createSelector } from 'reselect';
import { stringify } from 'query-string';
import createEntityReducer, {
  createActionTypes,
  createEntitySelectors,
  initialState,
  State,
  DestroyActionCreator,
} from '../createEntityReducer';
import { get, destroy, patch } from '../../api/rest';
import { types as environmentTypes } from '../../actions/environments';
import { fulfill } from '../../utils/promise';

import schema from './schema';
import BaseLayer from './types';
import { getCurrentEnvironmentId } from '../../selectors/project';
import { getSelectedEnvironmentId } from '../../selectors/environments';

const resource = 'baseLayers';
const endpoint = '/base_layers';

const reducer = createEntityReducer(resource);

export const selectors = createEntitySelectors<BaseLayer>(resource);

export const getBaseLayersForCurrentEnvironment = createSelector(
  selectors.getEntities,
  getCurrentEnvironmentId,
  (entities, environmentId) =>
    sortBy(
      prop('label'),
      filter(
        where({ environmentIds: contains(environmentId) }),
        values(entities),
      ),
    ),
);

export const getBaseLayersForSelectedEnvironment = createSelector(
  getSelectedEnvironmentId,
  selectors.getEntities,
  (selectedId, baseLayers) =>
    sortBy(
      prop('label'),
      filter(
        where({ environmentIds: contains(selectedId) }),
        values(baseLayers),
      ),
    ),
);

export const types = createActionTypes(resource);

export interface FetchBaseLayerListActionOpts {
  environmentId?: string;
  noCache?: boolean;
}

export type FetchBaseLayerListAction = (
  opts?: FetchBaseLayerListActionOpts,
) => any;

export const fetchBaseLayers: FetchBaseLayerListAction = ({
  environmentId,
  noCache,
} = {}) => ({
  type: types.LIST,
  payload: get(
    endpoint + (environmentId ? `?${stringify({ environmentId })}` : ''),
  ),
  meta: { schema: [schema], noCache },
});

export const fetchBaseLayer = (id: string) => ({
  type: types.GET,
  payload: get(`${endpoint}/${id}`),
  meta: { schema, noCache: true },
});

export const deleteBaseLayer: DestroyActionCreator = id => ({
  type: types.DESTROY,
  payload: {
    ...destroy(`${endpoint}/${id}`),
    data: id,
  },
});

export const updateBaseLayer = (id: string, updates: Partial<BaseLayer>) => ({
  type: types.UPDATE,
  payload: {
    ...patch(`/base_layers/${id}`, updates),
    data: { id, updates },
  },
  meta: { schema, debounce: 700, optimistic: true },
});

export type BaseLayersState = State<BaseLayer>;

export default (state: BaseLayersState = initialState, action) => {
  const baseLayers = path(['payload', 'entities', 'baseLayers'], action);

  return action.type === fulfill(environmentTypes.GET_ENVIRONMENT) && baseLayers
    ? { entities: baseLayers }
    : reducer(state, action);
};
