import { equals, prop } from 'ramda';
import { Channel } from 'redux-saga';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { volumer } from '@hm/volumer-api';

import { setFrame } from '../../actions/volumer';
import * as layers from '../../actions/layers';
import { updateCompass } from '../../actions/compass';
import { projectStatus } from '../../actions/projects';
import { isActive, updateLastFrameTs } from '../../reducers/abr';
import { getCurrentFrame } from '../../selectors/volumer';
import { getCompassState } from '../../selectors/compass';
import { transformHistogram } from '../../utils/helpers';
import { updateAbr, checkAbr } from './abr';
import { isAutoQualityEnabled } from '../../selectors/user';

type Histograms = Dict<volumer.types.Histogram$Properties>;

const makeHistogram = (hist: volumer.types.Histogram$Properties) => {
  return transformHistogram({ x: hist.xValue, y: hist.yValue });
};

export default function*(
  messageChannel: Channel<volumer.stream.Frame$Properties>,
) {
  while (true) {
    const frame = yield take(messageChannel);
    yield* processFrame(frame);
  }
}

function* processFrame(frame: volumer.stream.Frame$Properties) {
  const {
    frameType,
    frameData,
    layerInitialHistograms,
    layerCurrentHistograms,
    cameraState,
    info,
  } = frame;

  if (frameType !== volumer.stream.FrameType.SKIP) {
    yield fork(updateImage, frameData);

    const frameTs = Date.now();

    const abrEnabled = yield select(isAutoQualityEnabled);
    const abrActive = yield select(isActive);
    if (abrEnabled && abrActive) {
      yield call(updateAbr, frameType, frameTs);
    }

    yield put(updateLastFrameTs(frameTs));
  }

  if (cameraState) {
    yield fork(updateCameraState, cameraState);
  }

  yield fork(updateCurrentHistograms, layerCurrentHistograms);
  yield fork(updateInitialHistograms, layerInitialHistograms);
  yield fork(parseInfo, info);
}

function* updateImage(frameData: Uint8Array) {
  const blob = new Blob([frameData], { type: 'image/jpeg' });
  const url = URL.createObjectURL(blob);

  const currentFrame = yield select(getCurrentFrame);
  yield call(URL.revokeObjectURL, currentFrame);

  yield put(setFrame(url));
}

function* updateCurrentHistograms(histograms: Histograms) {
  for (const id of Object.keys(histograms)) {
    const histogram = histograms[id];

    yield put(layers.setCurrentHistogram(id, makeHistogram(histogram)));

    yield put(
      layers.setAbsoluteValues(id, {
        min: histogram.absoluteMinmax.x,
        max: histogram.absoluteMinmax.y,
      }),
    );
  }
}

function* updateInitialHistograms(histograms: Histograms) {
  for (const id of Object.keys(histograms)) {
    yield put(layers.setInitialHistogram(id, makeHistogram(histograms[id])));
  }
}

function* updateCameraState(cameraState: volumer.types.CameraState$Properties) {
  const oldCompass = yield select(getCompassState);
  const newCompass = {
    tilt: cameraState.rotation.x,
    rotate: cameraState.rotation.z,
  };

  if (!equals(oldCompass, newCompass)) {
    yield put(updateCompass(newCompass));
  }
}

function* parseInfo(info: string[]) {
  for (const i of info) {
    const message = JSON.parse(i);
    if (prop('layerId', message)) yield put(layers.updateStatus(message));
    if (prop('project', message)) yield put(projectStatus(message));
    if (prop('lowresQuality', message))
      yield call(checkAbr, message.lowresQuality);
  }
}
