import React from 'react';
import Modal from './Modal';
import NewMetricModal from './NewMetricModal';
import DeleteProjectDialog from '../containers/DeleteProjectDialog';
import CopyDialog from '../containers/CopyDialog';
import EditProject from '../containers/EditProject';

interface Props {
  currentModal: string;
  hideModal();
}

const modals = {
  NewMetricModal,
  DeleteProjectDialog,
  CopyDialog,
  EditProject,
};

const CurrentModal: React.SFC<Props> = ({ currentModal, hideModal }) => {
  const ModalComponent = modals[currentModal];

  if (!ModalComponent) return null;

  return (
    <Modal show onHide={hideModal}>
      <ModalComponent />
    </Modal>
  );
};

export default CurrentModal;
