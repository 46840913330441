import { fork, all } from 'redux-saga/effects';

import filters from './filters';
import newProject from './newProject';
import createProject from './createProject';
import editProject from './editProject';
import updateProject from './updateProject';
import newLayer from './newLayer';
import createLayer from './createLayer';

export default function*() {
  yield all([
    fork(filters),

    fork(newProject),
    fork(createProject),

    fork(editProject),
    fork(updateProject),

    fork(newLayer),
    fork(createLayer),
  ]);
}
