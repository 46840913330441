import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Flex, Box } from 'grid-styled';
import { colors, typography } from '@hm/ukie';
import { isEmpty } from 'ramda';

import { monthByWeek, someContains } from '../../utils/time';
import Day from './Day';
import shouldCalendarUpdate from '../shouldCalendarUpdate';
import { CalendarProps } from '../Calendar';

const Weekday = styled.div`
  color: ${colors.grey4};
  font-size: ${typography.smallFont}
  text-align: center;
  height: 20px;
  width: 24px;
`;

const FilterPreview = Weekday.extend`
  margin-top: 2px;
  background: ${colors.primary};
  height: 2px;
`;

const MonthTitle = styled.div`
  line-height: 1;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-left: 3px;
`;

const Week = Flex.extend`
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const Container = styled.div`
  margin-bottom: 56px;
`;

const EmptyDay = styled.div`
  width: 24px;
  height: 24px;
`;

interface Props extends CalendarProps {
  date: moment.Moment;
}

export class Month extends React.Component<Props, never> {
  static defaultProps = {
    availableIntervals: [],
    selectedIntervals: [],
    filteredOutIntervals: [],
    filterPreview: [],
  };

  renderDay = (day: moment.Moment, i: number, ary: moment.Moment[]) => {
    const {
      date,
      availableIntervals,
      selectedIntervals,
      filteredOutIntervals,
      filterPreview,
    } = this.props;

    if (!date.isSame(day, 'month')) return <EmptyDay key={day.valueOf()} />;

    const next = ary[i + 1] || day.clone().add(1, 'day');
    const prev = ary[i - 1] || day.clone().subtract(1, 'day');

    const isSelected =
      someContains(selectedIntervals, day) &&
      !someContains(filteredOutIntervals, day);

    const withFilter =
      !isEmpty(filterPreview) &&
      !someContains(filterPreview, day) &&
      someContains(availableIntervals, day);

    const props = {
      date: day,
      isSelected,
      isDisabled:
        !someContains(availableIntervals, day) ||
        someContains(filteredOutIntervals, day),
      first:
        isSelected &&
        (selectedIntervals.some(({ start }) => start.isSame(day)) ||
          someContains(filteredOutIntervals, prev)),
      last:
        isSelected &&
        (selectedIntervals.some(({ end }) => end.isSame(next)) ||
          someContains(filteredOutIntervals, next)),
      onSelectionClick: this.props.onSelectionClick,
      onSelection: this.props.onSelection,
      onSelectionEnd: this.props.onSelectionEnd,
      ownDuration: 'P1D',
    };

    return (
      <Box
        style={{ paddingBottom: withFilter ? 0 : '4px' }}
        key={day.valueOf()}
      >
        <Day {...props} />
        {withFilter ? <FilterPreview /> : null}
      </Box>
    );
  };

  render() {
    const { date } = this.props;

    const weekDates = monthByWeek(date);

    return (
      <Container>
        <MonthTitle>{date.format('MMMM')}</MonthTitle>
        <Flex justify="space-between">
          {moment
            .weekdaysMin(true)
            .map(weekday => <Weekday key={weekday}>{weekday}</Weekday>)}
        </Flex>
        <div>
          {weekDates.map((week, i) => (
            <Week key={i}>{week.map(this.renderDay)}</Week>
          ))}
        </div>
      </Container>
    );
  }
}

export default shouldCalendarUpdate('month')(Month);
