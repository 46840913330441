import React from 'react';
import Overlay from 'react-overlays/lib/Overlay';
import { DropdownContainer, Input } from '@hm/ukie';
import { Flex } from 'grid-styled';

import Track from './Track';
import { CurrentSelectedSlices } from './SliceCount';
import SelectedInterval from '../containers/SelectedInterval';
import BurgerButton from './BurgerButton';
import { Relative } from './Relative';
import PlainLink from './PlainLink';
import ListItem from './ListItem';

const StyledInput = Input.extend.attrs({ titleFont: true })`
  width: 200px;
`;

interface Props {
  projectLabel: string;
  onUpdate(updates);
  logOut(): void;
  push(route: string): void;
  onEdit();
  deleteProject();
  copyProject();
}

interface State {
  projectLabel?: string;
  show?: boolean;
}

export default class ProjectMenu extends React.Component<Props, State> {
  private overlayTarget;

  constructor(props) {
    super(props);

    this.state = {
      projectLabel: this.props.projectLabel,
      show: false,
    };
  }

  componentWillUnmount() {
    this.updateName();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.projectLabel === nextProps.projectLabel) return;

    this.setState({ projectLabel: nextProps.projectLabel });
  }

  handleNameKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) this.updateName();

    if (e.keyCode === 27)
      this.setState({
        projectLabel: this.props.projectLabel,
      });
  };

  handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ projectLabel: e.currentTarget.value });
  };

  updateName() {
    const { projectLabel: newName } = this.state;

    if (this.props.projectLabel === newName) return;

    this.props.onUpdate({ label: newName });
  }

  onHide = () => this.setState({ show: false });

  // FIXME: separate action-create-api-called from other stuff (modal, redirecting)
  onCopy = () => {
    this.onHide();
    this.props.copyProject();
  };

  logOut = () => {
    this.onHide();
    this.props.logOut();
    this.props.push('/login');
  };

  deleteProject = () => this.props.deleteProject().then(() => this.onHide());

  toggleProjectMenu = () => this.setState({ show: !this.state.show });

  render() {
    return (
      <Relative>
        <BurgerButton
          onClick={this.toggleProjectMenu}
          ref={el => (this.overlayTarget = el)}
        />
        <Overlay
          show={this.state.show}
          onHide={() => this.onHide()}
          placement="bottom"
          containerPadding={19}
          target={this.overlayTarget}
          rootClose
        >
          <DropdownContainer>
            <div className="dropdown-menu">
              <div className="dropdown-menu__item _no-select">
                <Flex>
                  <div className="flex-col flex-fit flex-center">
                    <label className="label-text">Name</label>
                  </div>
                  <div className="flex-col">
                    <StyledInput
                      value={this.state.projectLabel}
                      onChange={this.handleNameChange}
                      onKeyDown={this.handleNameKeyDown}
                    />
                  </div>
                </Flex>
              </div>
              <div className="dropdown-menu__item _no-select">
                <Flex>
                  <div className="flex-col flex-fit">
                    <div className="label-text">Range</div>
                  </div>
                  <div className="flex-col">
                    <div className="info-text">
                      <SelectedInterval />
                      <span className="info-link" onClick={this.props.onEdit}>
                        {' '}
                        Edit…
                      </span>
                    </div>
                    <div className="info-text dimmed">
                      <CurrentSelectedSlices />
                    </div>
                  </div>
                </Flex>
              </div>
              <div className="dropdown-menu__delimiter" />
              <Track
                element={ListItem}
                data-metrics-event-name="backToProjects"
                data-metrics-category="Project"
              >
                <PlainLink to="/">Back to projects</PlainLink>
              </Track>
              <Track
                element={ListItem}
                data-metrics-event-name="projectCopy"
                data-metrics-category="Project"
                onClick={this.onCopy}
              >
                Save as...
              </Track>
              <Track
                element={ListItem}
                onClick={this.props.deleteProject}
                data-metrics-event-name="projectDelete"
                data-metrics-category="Project"
              >
                Delete project…
              </Track>
              <div className="dropdown-menu__delimiter" />
              <ListItem onClick={this.logOut}>Log Out</ListItem>
            </div>
          </DropdownContainer>
        </Overlay>
      </Relative>
    );
  }
}
