import React from 'react';
import { ExpandableEllipsis } from '@hm/ukie';
import Subset from './Subset';
import { Category } from '../../reducers/categories';
import { Option } from '../../models';

interface Props {
  categories: Category[];
  updateManyOptions(updates);
}

const Options: React.SFC<Props> = ({ categories, updateManyOptions }) => {
  return (
    <div className="metric-option__content">
      {categories.map(category => (
        <div className="metric-option__row" key={category.id}>
          <ExpandableEllipsis
            className="metric-option__col _label"
            text={category.label}
          />
          <div className="metric-option__col">
            <Subset
              options={category.options as Option[]}
              updateManyOptions={updateManyOptions}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Options;
