import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { all } from 'ramda';
import { withHandlers } from 'recompose';
import styled from 'styled-components';
import {
  IconButton,
  DropdownItem,
  CheckboxToggle,
  DropdownContainer,
} from '@hm/ukie';
import Overlay from 'react-overlays/lib/Overlay';
import { MapObject } from '../../reducers/selectedObjects';
import { ExportFileType } from '../../actions/inspector';

const Body = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Item = IconButton.extend`
  &:not(:first-child) {
    margin-left: 8px;
  }
`;

const messages = defineMessages({
  removeObjects: {
    id: 'Inspector/WindowButtons.removeObjects',
    defaultMessage: 'Remove {count, plural, one {# object} other {# objects}}',
  },
  [ExportFileType.GeoJson]: {
    id: 'exportGeoJSON',
    defaultMessage:
      'Export {count, plural, one {# object} other {# objects}} as GeoJSON',
  },
  [ExportFileType.ShapeFile]: {
    id: 'exportShapefile',
    defaultMessage:
      'Export {count, plural, one {# object} other {# objects}} as Shapefile',
  },
});

interface OwnProps {
  objects: MapObject[];
  isMinimized: boolean;
  isMenuOpen: boolean;
  isTruncated: boolean;
}

interface ExternalProps {
  deleteObject(id: string);
  toggleMinimize(minimize: boolean);
  toggleMenu(isOpen: boolean);
  toggleTruncation(trucate: boolean);
}

interface ProvidedProps {
  onRemove();
  onMinimize();
  onToggleMenu();
  onHideMenu();
  onToggleTruncation();
  onExport?(fileType: ExportFileType): void;
}

type Props = OwnProps & ProvidedProps;

const isPermanent = (obj: MapObject) => !obj.draft;

const exportFileTypes = [ExportFileType.GeoJson, ExportFileType.ShapeFile];

/**
 * Top inspector controls that contain dropdown menu and window controls (minimize/close)
 */
class InspectorMenu extends React.Component<Props, never> {
  refs: {
    [string: string]: any;
    burger: any;
  };

  componentWillUnmount() {
    this.props.onHideMenu();
  }

  onExport = (fileType: ExportFileType) => {
    this.props.onExport(fileType);
    this.props.onHideMenu();
  };

  renderDropDown = () => {
    const { objects, onToggleTruncation, isTruncated, onRemove } = this.props;
    const showRemoveControl = objects.length > 0 && all(isPermanent)(objects);

    return (
      <DropdownContainer>
        <div className="dropdown-menu">
          <DropdownItem onClick={onToggleTruncation}>
            <CheckboxToggle checked={isTruncated} onChange={onToggleTruncation}>
              Enable truncation
            </CheckboxToggle>
          </DropdownItem>
          {exportFileTypes.map(fileType => (
            <DropdownItem
              key={fileType}
              onClick={() => this.onExport(fileType)}
            >
              <FormattedMessage
                {...messages[fileType]}
                values={{ count: objects.length }}
              />
            </DropdownItem>
          ))}

          {showRemoveControl ? (
            <div className="dropdown-menu__delimiter" />
          ) : null}
          {showRemoveControl ? (
            <DropdownItem onClick={onRemove}>
              <FormattedMessage
                {...messages.removeObjects}
                values={{ count: objects.length }}
              />
            </DropdownItem>
          ) : null}
        </div>
      </DropdownContainer>
    );
  };

  render() {
    const {
      isMinimized,
      isMenuOpen,
      onToggleMenu,
      onHideMenu,
      onMinimize,
    } = this.props;

    return (
      <Body>
        {/* tslint:disable-next-line:jsx-no-string-ref */}
        <Item icon="burgerSlim" ref="burger" onClick={onToggleMenu} />
        <Item
          icon={isMinimized ? 'windowMax' : 'windowMin'}
          onClick={onMinimize}
        />
        <Overlay
          show={isMenuOpen}
          placement="bottom"
          onHide={onHideMenu}
          target={this.refs.burger}
          rootClose
        >
          {this.renderDropDown()}
        </Overlay>
      </Body>
    );
  }
}

// TODO: remove recompose handlers
export default withHandlers<Props & ExternalProps, any>({
  onRemove: ({ deleteObject, toggleMenu, objects }) => () => {
    objects.forEach(({ id }) => deleteObject(id));
    toggleMenu(false); // XXX: hides menu when we click delete objects button
  },
  onMinimize: ({ toggleMinimize, isMinimized }) => () =>
    toggleMinimize(!isMinimized),
  onToggleMenu: ({ toggleMenu, isMenuOpen }) => () => toggleMenu(!isMenuOpen),
  onHideMenu: ({ toggleMenu }) => () => toggleMenu(false),
  onToggleTruncation: ({ toggleTruncation, isTruncated }) => () =>
    toggleTruncation(!isTruncated),
})(InspectorMenu);
