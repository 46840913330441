import React from 'react';
import {
  Avatar,
  CheckboxToggle,
  Input,
  LinkButton,
  SimpleModal,
  Text,
} from '@hm/ukie';
import { Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { CurrentUser, EmailUpdate, PasswordUpdate } from '../../models';
import {
  supportEmail,
  privacyPolicyPage,
  touPage,
} from '../../constants/links';
import EmailChangeForm from '../EmailChangeForm';
import EmailConfirmation from '../EmailConfirmation';
import Modal from '../Modal';
import PasswordChangeForm from '../PasswordChangeForm';
import UserpicForm from '../UserpicForm';

interface OwnProps {
  user: CurrentUser;
  onUserEdit(user: Partial<CurrentUser>): void;
  onPasswordUpdate(form: PasswordUpdate): Promise<any>;
  onEmailUpdate(form: EmailUpdate): Promise<any>;
}

type Props = OwnProps & RouteComponentProps<any>;

type Modals = 'userpic' | 'password' | 'email' | 'emailConfirm';

interface State {
  currentModal: Modals;
}

export default class Account extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentModal:
        props.location.pathname === '/profile/email' ? 'emailConfirm' : null,
    };
  }

  onShow = (currentModal: Modals) => this.setState({ currentModal });
  onHide = () => this.setState({ currentModal: null });

  render() {
    const { user, onUserEdit, onPasswordUpdate, onEmailUpdate } = this.props;
    const { currentModal } = this.state;

    return (
      <Box width={256}>
        <Avatar
          image={user.avatarUrl}
          name={user.name}
          color={user.defaultColor}
          onEdit={() => this.onShow('userpic')}
        />
        <Box my={3}>
          <Box my={1} style={{ height: 24 }}>
            <Text.Important>{user.email}</Text.Important>
          </Box>
          <Box my={1}>
            <Input
              defaultValue={user.name}
              onChange={e => onUserEdit({ name: e.currentTarget.value })}
            />
          </Box>
        </Box>
        <Box my={2}>
          <LinkButton onClick={() => this.onShow('email')}>
            Change email
          </LinkButton>
        </Box>
        <Box my={2}>
          <LinkButton onClick={() => this.onShow('password')}>
            Change password
          </LinkButton>
        </Box>

        <Box my={3}>
          <CheckboxToggle
            checked={user.agreedToReceiveMarketingEmails}
            onChange={() =>
              onUserEdit({
                agreedToReceiveMarketingEmails: !user.agreedToReceiveMarketingEmails,
              })
            }
          >
            <F
              id="Unsubscribe.continueEmails"
              defaultMessage="I would like to receive emails about product updates, upcoming events and other relevant news"
            />
          </CheckboxToggle>
        </Box>

        <Box my={2}>
          <F
            id="Profile.supportEmail"
            defaultMessage="If you need support or encounter an issue, please reach us at {email}."
            values={{
              email: (
                <a href={`mailto:${supportEmail}`}>
                  <Text fontWeight="semibold">support@chronotope.io</Text>
                </a>
              ),
            }}
          />
        </Box>
        <Box my={2}>
          <F
            id="Profile.rights"
            defaultMessage="If you would like to exercise your rights related to your personal information, please refer to our {privacyPolicy} for guidelines."
            values={{
              privacyPolicy: (
                <F id="Profile.privacyPolicy" defaultMessage="Privacy Policy">
                  {t => (
                    <a href={privacyPolicyPage} target="_blank">
                      {t}
                    </a>
                  )}
                </F>
              ),
            }}
          />
        </Box>
        <Box my={2}>
          <F
            id="Profile.masterAgreement"
            defaultMessage="The use of Chronotope is governed by our {agreement}."
            values={{
              agreement: (
                <F
                  id="Profile.softwareAsAService"
                  defaultMessage="Master Software-as-a-Service Agreement"
                >
                  {t => (
                    <a href={touPage} target="_blank">
                      {t}
                    </a>
                  )}
                </F>
              ),
            }}
          />
        </Box>

        <Modal show={currentModal === 'password'} onHide={this.onHide}>
          <SimpleModal
            width={312}
            title={
              <F id="Profile.changePassword" defaultMessage="Change password" />
            }
            onHide={this.onHide}
          >
            <PasswordChangeForm onSubmit={onPasswordUpdate} />
          </SimpleModal>
        </Modal>

        <Modal show={currentModal === 'email'} onHide={this.onHide}>
          <SimpleModal
            width={296}
            title={<F id="Profile.changeEmail" defaultMessage="Change email" />}
            onHide={this.onHide}
          >
            <EmailChangeForm onSubmit={onEmailUpdate} user={user} />
          </SimpleModal>
        </Modal>

        <Modal show={currentModal === 'emailConfirm'} onHide={this.onHide}>
          <SimpleModal
            width={296}
            title={<F id="Profile.changeEmail" defaultMessage="Change email" />}
            onHide={this.onHide}
          >
            <EmailConfirmation />
          </SimpleModal>
        </Modal>

        <Modal show={currentModal === 'userpic'} onHide={this.onHide}>
          <SimpleModal
            title={
              <F id="Profile.changeUserpic" defaultMessage="Change userpic" />
            }
            width={352}
            onHide={this.onHide}
            wrapper={Box}
          >
            <UserpicForm onDone={this.onHide} />
          </SimpleModal>
        </Modal>
      </Box>
    );
  }
}
