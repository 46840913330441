import { defineMessages } from 'react-intl';

// TODO: unify with messages defined in `utils/validators`
const messages = defineMessages({
  AUTHENTICATION_7: {
    id: 'Errors.AUTHENTICATION_7',
    defaultMessage:
      'This email was already registered.\nConfirm your registration by clicking the link from confirmation letter or {linkToLogin} to Chronotope.',
  },
});

export default messages;
