import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import createEntityReducer from './createEntityReducer';
import { types as userTypes } from '../actions/user';

import abr from './abr';
import alerts from './alerts';
import baseLayers from './baseLayers';
import billing from './billing';
import calendar from './calendar';
import categories from './categories';
import compass from './compass';
import datasets from './datasets';
import defaultObjectParams from './defaultObjectParams';
import dev from './dev';
import effects from './effects';
import environments from './environments';
import explorer from './explorer';
import features from './features';
import forms from './forms';
import gradients from './gradients';
import highlightTypes from './highlightTypes';
import highlights from './highlights';
import inspector from './inspector';
import layerAnomalies from './layerAnomalies';
import layerUploads from './layerUploads';
import layers from './layers';
import modals from './modals';
import options from './options';
import project from './currentProject';
import projects from './projects';
import search from './search';
import selectedFragments from './selectedFragments';
import selectedObjects from './selectedObjects';
import sidebar from './sidebar';
import timeline from './timeline';
import title from './title';
import tool from './tool';
import user from './user';
import volumer from './volumer';
import workspaces from './workspaces';

import { AppState } from '../models';

const appReducer = combineReducers<AppState>({
  ...forms,
  abr,
  alerts,
  baseLayers,
  billing,
  calendar,
  categories,
  compass,
  datasets,
  defaultObjectParams,
  dev,
  effects,
  environments,
  explorer,
  features,
  gradients,
  highlightTypes,
  highlights,
  inspector,
  layerAnomalies,
  layerUploads,
  layers,
  modals,
  options,
  project,
  projects,
  routing: routerReducer,
  search,
  selectedFragments,
  selectedObjects,
  sidebar,
  timeline,
  title,
  tool,
  user,
  users: createEntityReducer('users'),
  volumer,
  workspaces,
});

export default (state: AppState, action: any) => {
  if (action.type === userTypes.LOG_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};
