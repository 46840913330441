import React from 'react';
import moment from 'moment';
import { Flex } from 'grid-styled';
import { RadioSquare, Text, BorderFlex } from '@hm/ukie';
import { capitalize } from '../../utils/helpers';
import withDisabled from '../withDisabled';

const availableDurations: Dict<string[]> = {
  minutes: ['PT5M', 'PT10M', 'PT15M', 'PT20M', 'PT30M'],
  hours: ['PT1H', 'PT2H', 'PT3H', 'PT4H', 'PT6H', 'PT8H', 'PT12H'],
  days: ['P1D'],
  months: ['P1M', 'P2M', 'P3M', 'P4M', 'P6M'],
  years: ['P1Y'],
};

const CheckboxGroup = Flex.extend`
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`;

const Body = BorderFlex.extend`
  min-height: 40px;
  ${withDisabled};
`;

export interface Props {
  className?: string;
  disabled?: boolean;
  availableSliceDurations: moment.Duration[];
  selectedSliceDuration: string;
  onChange(duration: string): void;
}

const SliceDurations: React.SFC<Props> = ({
  availableSliceDurations,
  selectedSliceDuration,
  onChange,
  className,
}) => (
  <Body
    align="center"
    justify="center"
    borderBottom="1px solid"
    className={className}
  >
    <Text fontSize="14px" mr={2}>
      Slice Duration:
    </Text>
    {Object.keys(availableDurations).map((group, g) => (
      <Flex align="center" key={g}>
        <Text fontSize="14px" mr={12}>
          {capitalize(group)}
        </Text>
        <CheckboxGroup mr={40}>
          {availableDurations[group].map(duration => (
            <RadioSquare
              value={moment
                .duration(duration)
                .as(group as moment.unitOfTime.Base)}
              name="sliceDuration"
              // FIXME: remove map and .toISOString()
              disabled={
                !availableSliceDurations
                  .map(d => d.toISOString())
                  .includes(duration)
              }
              checked={duration === selectedSliceDuration}
              onChange={() => onChange(duration)}
              key={duration}
            />
          ))}
        </CheckboxGroup>
      </Flex>
    ))}
  </Body>
);

export default SliceDurations;
