import React from 'react';
import { Point } from '../../reducers/gradients';
import { getBinScale, getColorScale } from '../../utils/histogram';
import { HistogramData } from '../../models';

interface Props {
  bins: HistogramData[];
  width: number;
  height: number;
  yScale(value: number): number;
  gradientPoints?: Point[];
  style?: any;
  clipPath?: string;
}

class HistogramBars extends React.Component<Props, never> {
  render() {
    const { width, height, bins, style, yScale, gradientPoints } = this.props;

    const binScale = getBinScale(bins.length, width);
    const colorScale =
      gradientPoints && getColorScale(gradientPoints, bins.length);

    return (
      <g clipPath={this.props.clipPath}>
        {bins.map(({ y }, i) => {
          const barHeight = yScale(y);
          const barStyle = colorScale
            ? { ...style, fill: colorScale(i) }
            : style;

          return (
            <rect
              key={`${i}-${y}`}
              width={binScale.bandwidth()}
              height={barHeight}
              transform={`translate(${binScale(i)},${height - barHeight})`}
              style={barStyle}
            />
          );
        })}
      </g>
    );
  }
}

export default HistogramBars;
