import React from 'react';
import { WhiteButton, RadioButtonGroup } from '@hm/ukie';
import { CameraMode } from '../models';

const Button = WhiteButton.extend`
  width: 30px;
`;

const WIDTH = 62;
const HEIGHT = 62;

interface Props {
  tilt: number;
  rotate: number;
  mode: CameraMode;
  resetCamera();
  changeMode(mode: CameraMode);
}

/**
 * Compass component. Displays camera position and shows controls for changing
 * camera mode.
 *
 * NOTE:
 * Tilt and Rotate are in degrees
 * 0 degrees tilt means that the camera planes are parallel the to map plane
 * 90 degrees tilt means they are perpendicular to the map plane
 *
 * ALSO NOTE:
 * Changing the mode to 2D/3D does not affect tilt/rotate values
 * This just does not feel like a presentational component's concern
 */
const Compass: React.SFC<Props> = props => {
  const { tilt, rotate, mode, resetCamera, changeMode } = props;
  const unitTilt = Math.cos(tilt * Math.PI / 180);
  const radius = WIDTH / 2 - 1;
  const center = [radius + 1, radius + 1];

  return (
    <div className="compass-controls">
      <WhiteButton onClick={resetCamera}>Reset</WhiteButton>
      <svg width={WIDTH} height={HEIGHT} className="compass">
        <circle
          r={radius}
          className="compass__body"
          transform={`translate(${center})`}
        />
        <circle
          r={radius}
          className="compass__shadow"
          transform={`translate(${center})scale(1,${unitTilt})`}
        />
        <line
          y2={-radius}
          className="compass__arrow"
          transform={`translate(${center})scale(1,${unitTilt})rotate(${rotate})`}
        />
      </svg>
      <RadioButtonGroup onClick={changeMode} selected={mode}>
        <Button value="2D">2D</Button>
        <Button value="3D">3D</Button>
      </RadioButtonGroup>
    </div>
  );
};

Compass.displayName = 'Compass';

export default Compass;
