import { MetricsElement } from 'react-metrics';
import { path } from 'ramda';
import { connect } from 'react-redux';

/**
 * Connected version of MetricsElement which receives current user info
 */
interface Props {
  element?: string | JSX.Element | React.ComponentType;
  [index: string]: any;
}

export default connect<any, any, Props, Props>(
  state => ({
    ['data-metrics-userid']: path(['user', 'info', 'id'], state),
  }),
  null,
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ['data-metrics-merge-pagedefaults']: true,
    element: 'span',
    ...ownProps,
  }),
)(MetricsElement);
