import React from 'react';
import { isNil } from 'ramda';
import { FormattedNumber } from 'react-intl';

interface Props {
  value: number;
  units: string;
}

const ValueWithUnits: React.SFC<Props> = ({ value, units }) => (
  <React.Fragment>
    {!isNil(value) ? (
      <FormattedNumber value={value} maximumFractionDigits={1} />
    ) : (
      '-'
    )}{' '}
    <span>{units}</span>
  </React.Fragment>
);

export default ValueWithUnits;
