import { connect } from 'react-redux';
import ShiftStatus from '../../components/Calendar/ShiftStatus';
import {
  getApproximationType,
  computeStartTime,
  getStartAtWeekday,
  getStartAtMonthDay,
  getStartAtMonth,
} from '../../selectors/calendar';

import { AppState } from '../../models';

const mapStateToProps = (state: AppState) => ({
  approximationType: getApproximationType(state),
  startTime: computeStartTime(state),
  startAtWeekday: getStartAtWeekday(state),
  startAtMonthDay: getStartAtMonthDay(state),
  startAtMonth: getStartAtMonth(state),
});

export default connect(mapStateToProps)(ShiftStatus);
