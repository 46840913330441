import { prop, compose, propOr, groupBy, values } from 'ramda';
import { createSelector } from 'reselect';
import { AppState } from '../models';
import { minDuration } from '../utils/time';
import { getCurrentEnvironmentId } from './project';
import { AvailableTimeFilter } from '../reducers/environments';

export const getSelectedEnvironmentId = (state: AppState) =>
  state.environments.selectedId;
export const getEnvironments = (state: AppState) => state.environments.entities;
export const getEnvironmensList = (state: AppState) =>
  values(state.environments.entities);

export const getEnvironmentById = (state: AppState, { id }: { id: string }) =>
  state.environments.entities[id];

export const getSelectedEnvironment = (state: AppState) =>
  getEnvironments(state)[getSelectedEnvironmentId(state)];

export const getCurrentEnvironment = createSelector(
  getCurrentEnvironmentId,
  getEnvironments,
  (currentId, entities) => entities[currentId],
);

export const getCurrentAvailableIntervals = createSelector(
  getSelectedEnvironment,
  environment => environment.availableTimeIntervals,
);

// TODO: better name
export const getMinSliceDuration = createSelector(
  getSelectedEnvironment,
  compose(minDuration, prop('availableSliceDurations')),
);

export const getAvailableSliceDurations = createSelector(
  getSelectedEnvironment,
  prop('availableSliceDurations'),
);

export const getAvailableGroupedFilters = createSelector(
  getSelectedEnvironment,
  environment =>
    groupBy<AvailableTimeFilter>(
      prop('group'),
      propOr([], 'availableTimeFilters', environment),
    ),
);

export const getIds = (state: AppState) => Object.keys(getEnvironments(state));

export const getSelectedOrAllIds = (state: AppState, selectedId: string) =>
  selectedId ? [selectedId] : getIds(state);
