import { connect } from 'react-redux';
import { hideModal } from '../actions/modals';
import { AppState } from '../models';
import CurrentModal from '../components/CurrentModal';

export default connect(
  (state: AppState) => ({
    currentModal: state.modals.currentModal,
  }),
  { hideModal },
)(CurrentModal);
