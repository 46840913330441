import React from 'react';
import styled from 'styled-components';
import { whereEq, propSatisfies, without, where, equals, any } from 'ramda';

import Placeholder from './Placeholder';
import Time from './Time';
import Address from './Address';
import Stats from './Stats';
import Row from './Row';

import { MarkerSubtype } from '../../constants/objects';
import { MapObject } from '../../reducers/selectedObjects';
import { condAll, isNonEmpty, isPresent } from '../../utils/helpers';
import { length, area } from '../../utils/numberFormats';

/**
 * TODO: react-intl, render prop for Row
 */

interface Props {
  objectCount: number;
  object: MapObject;
  selectedTool: string;
  isApproximatedProject: boolean;
  timezone: string;
  truncate: boolean;
  showMore: boolean;
  layerLabels: { [layerId: string]: string };
  layerGradients: { [layerId: string]: string[] };
  toggleShowMore(showMore: boolean): void;
}

const Body = styled.div`
  margin-top: 16px;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const hasSpace = (obj: MapObject) =>
  [MarkerSubtype.SPATIAL, MarkerSubtype.SPATIO_TEMPORAL].includes(obj.subtype);

const hasTime = (obj: MapObject) =>
  [MarkerSubtype.TEMPORAL, MarkerSubtype.SPATIO_TEMPORAL].includes(obj.subtype);

const hasStats = (obj: MapObject) =>
  obj.stats && any(isNonEmpty)(Object.values(obj.stats));

const getComponents = condAll<Props>([
  [
    // XXX: maybe it doesn't belong here
    whereEq({ objectCount: 0 }),
    ({ selectedTool }) => <Placeholder key="placeholder" tool={selectedTool} />,
  ],
  [
    where({ objectCount: equals(1), object: hasSpace }),
    ({ object }) => (
      <Address key="address" {...object.points[0]} address={object.address} />
    ),
  ],
  [
    where({
      objectCount: equals(1),
      object: propSatisfies(isPresent, 'length'),
    }),
    ({ object }) => (
      <Row key="length" title="Length">
        {length(object.length)}
      </Row>
    ),
  ],
  [
    where({
      objectCount: equals(1),
      object: propSatisfies(isPresent, 'perimeter'),
    }),
    ({ object }) => (
      <Row key="perimeter" title="Perimeter">
        {length(object.perimeter)}
      </Row>
    ),
  ],
  [
    where({ objectCount: equals(1), object: propSatisfies(isPresent, 'area') }),
    ({ object }) => (
      <Row key="area" title="Area">
        {area(object.area)}
      </Row>
    ),
  ],
  [
    where({ objectCount: equals(1), object: hasTime }),
    ({ object, timezone, isApproximatedProject }) => (
      <Time
        key="time"
        object={object}
        timezone={timezone}
        isApproximatedProject={isApproximatedProject}
      />
    ),
  ],
  [
    where({ objectCount: equals(1), object: hasStats }),
    ({
      object,
      truncate,
      showMore,
      layerGradients,
      layerLabels,
      toggleShowMore,
    }) => (
      <Stats
        key="stats"
        stats={object.stats}
        hasTime={hasTime(object)}
        truncate={truncate}
        showMore={showMore}
        toggleShowMore={toggleShowMore}
        layerGradients={layerGradients}
        layerLabels={layerLabels}
      />
    ),
  ],
]);

const Metrics: React.SFC<Props> = props => {
  const components = without([null], getComponents(props));
  if (components.length === 0) return null;

  return <Body>{components}</Body>;
};

export default Metrics;
