import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import { scaleBand, ScaleBand, scaleOrdinal, ScaleOrdinal } from 'd3-scale';
import { bisectRight } from 'd3-array';
import { clamp, head, last, groupWith, eqBy, invoker, isEmpty } from 'ramda';
import styled from 'styled-components';
import { withPropsOnChange } from 'recompose';
import { DraggableEventHandler } from 'react-draggable';
import { colors } from '@hm/ukie';

import WaveArea from '../WaveArea';
import Label from './Label';
import Axis from './Axis';
import Tick from './Tick';
import Slice from '../Slice';
import Slider from './Slider';

import { isEven } from '../../utils/helpers';
import {
  someContains,
  toStartTimeDate,
  toHHmm,
  getHours,
  getMinutes,
} from '../../utils/time';

import { CalendarProps } from '../Calendar';

const TICK_HEIGHT = 8;
const START_TIME_PADDING = 18 + 8;
const INDICATOR_GAP = 10;
const INDICATOR_HEIGHT = 4;

const PADDINGS = {
  regular: { top: 0, right: 12, bottom: 33, left: 12 },
  approximated: {
    top: 23 + START_TIME_PADDING,
    right: 50,
    bottom: 33,
    left: 50,
  },
};

// static data structs for scale domains
const hours = Array.from(
  new DateRange(moment().startOf('day'), moment().endOf('day')).by('hours'),
);

const hourRange = new DateRange(
  moment().startOf('hour'),
  moment().endOf('hour'),
);

const labels = [...hours, hours[hours.length - 1].clone().add(1, 'hour')];

const evenLabels = labels.filter((d, i) => isEven(i));

const ticks = labels.slice(1, labels.length - 1);

const Canvas = styled.g`
  cursor: ${({ isDragging }: { isDragging: boolean }) =>
    isDragging ? 'ew-resize' : 'default'};
`;

interface OwnProps {
  chartHeight?: number;
  chartWidth?: number;
  date: moment.Moment;
  startTime?: string;
  className?: string;
  // FIXME: configure paddings. When using approximated Wave we can have slider
  // not shown when selection is empty but still have bigger paddings for
  // layout consistency. Good candidate to refactor approximated part out into
  // another component
  withStartTime?: boolean;
  onStartTimeChange?(startTime: string): void;
}

interface ProvidedProps {
  width: number;
  height: number;
  paddings: { [k in 'top' | 'right' | 'bottom' | 'left']: number };
  dates: moment.Moment[];
  hourScale: ScaleBand<string>;
  scale: ScaleOrdinal<string, number>;
  sliceWidth: number;
}

type Props = CalendarProps & OwnProps & ProvidedProps;

interface State {
  isDragging: boolean;
}

/**
 * Wave selector components.
 * Allows user to input time ranges by dragging over slices.
 * `startTime` prop enables setting of start time property for approximation
 * settings.
 *
 * TODO: it’s not clear how to pass desired chartHeight because startTime renders
 * Tooltip which needs space for it’s body and margin between it and top of the wave
 */
export class Wave extends React.Component<Props, State> {
  static defaultProps = {
    selectedIntervals: [],
    availableIntervals: [],
    filteredOutIntervals: [],
    filterPreview: [],
    sliceDuration: 'PT1H',
    chartWidth: 616,
    chartHeight: 76,
    startTime: '',
  };

  state: State = {
    isDragging: false,
  };

  body: SVGGElement;

  getBodyRef = (el: SVGGElement) => (this.body = el);

  onStart: DraggableEventHandler = () => this.setState({ isDragging: true });
  onStop: DraggableEventHandler = () => this.setState({ isDragging: false });

  onDrag: DraggableEventHandler = (e: DragEvent, data) => {
    const { scale, paddings, selectedIntervals } = this.props;

    const { left } = this.body.getBoundingClientRect();
    const clientX = e.clientX - left - paddings.left / 2;

    const startTime = scale.domain()[bisectRight(scale.range(), clientX)];

    const isOutsideSelectedIntervals = !someContains(
      selectedIntervals,
      toStartTimeDate(startTime),
    );
    const isSame = startTime === this.props.startTime;
    if (isOutsideSelectedIntervals || isSame) return;

    this.props.onStartTimeChange(startTime);
  };

  getTickOffset = (tick: moment.Moment) => {
    const { hourScale } = this.props;

    const offset = (hourScale.step() - hourScale.bandwidth()) / 2;

    return hourScale(getHours(tick)) - offset;
  };

  render() {
    const {
      width,
      height,
      chartWidth,
      chartHeight,
      paddings,
      sliceDuration,
      onSelection,
      onSelectionClick,
      onSelectionEnd,
      selectedIntervals,
      availableIntervals,
      filteredOutIntervals,
      filterPreview,
      dates,
      className,
      hourScale,
      startTime,
      sliceWidth,
      scale,
    } = this.props;

    // Group slices by hour
    // FIXME: 'invoker' types are totally wrong
    const grouped = groupWith(eqBy(invoker(0 as any, 'hour')), dates);

    const commonProps = {
      onSelection,
      onSelectionClick,
      onSelectionEnd,
      ownDuration: sliceDuration,
      height: chartHeight,
    };

    return (
      <svg height={height} width={width} className={className}>
        <Canvas
          isDragging={this.state.isDragging}
          innerRef={this.getBodyRef}
          transform={`translate(${paddings.left}, ${paddings.top})`}
        >
          {grouped.map(hour => (
            <g key={toHHmm(head(hour))}>
              {hour.map(slice => (
                <Slice
                  date={slice}
                  key={slice.valueOf()}
                  x={scale(toHHmm(slice))}
                  width={sliceWidth}
                  isSelected={
                    someContains(selectedIntervals, slice) &&
                    !someContains(filteredOutIntervals, slice)
                  }
                  isDisabled={
                    !someContains(availableIntervals, slice) ||
                    someContains(filteredOutIntervals, slice)
                  }
                  {...commonProps}
                />
              ))}
            </g>
          ))}
          {!isEmpty(filterPreview)
            ? dates.map(
                (slice, i) =>
                  !someContains(filterPreview, slice) ? (
                    <rect
                      height={INDICATOR_HEIGHT}
                      x={scale(toHHmm(slice))}
                      y={chartHeight + 3}
                      fill={colors.primary}
                      width={
                        dates[i + 1] &&
                        !someContains(filterPreview, dates[i + 1])
                          ? scale(toHHmm(dates[i + 1])) - scale(toHHmm(slice))
                          : sliceWidth
                      }
                      key={`${slice.toString()}i`}
                    />
                  ) : null,
              )
            : null}
          <Axis x={chartWidth} y={chartHeight + INDICATOR_GAP} />
          <g>
            {ticks.map((tick, i) => (
              <Tick
                x={this.getTickOffset(tick)}
                y={chartHeight + INDICATOR_GAP}
                dimmed={isEven(i)}
                key={tick.valueOf()}
              />
            ))}
          </g>
          <g>
            {evenLabels.map((label, i, ary) => (
              <Label
                x={
                  i === ary.length - 1
                    ? // align last label with the end of last band
                      hourScale(getHours(last(hours))) + hourScale.bandwidth()
                    : hourScale(getHours(label))
                }
                y={chartHeight + INDICATOR_GAP + TICK_HEIGHT}
                key={label.valueOf()}
              >
                {toHHmm(label)}
              </Label>
            ))}
          </g>
          <WaveArea width={chartWidth} height={chartHeight + INDICATOR_GAP} />
        </Canvas>
        {startTime ? (
          <Slider
            x={scale(startTime) + paddings.left}
            text={`Start time – ${startTime}`}
            height={chartHeight + INDICATOR_GAP + paddings.top}
            onStart={this.onStart}
            onDrag={this.onDrag}
            onStop={this.onStop}
          />
        ) : null}
      </svg>
    );
  }
}

export default withPropsOnChange<ProvidedProps, OwnProps & CalendarProps>(
  ['date', 'chartWidth', 'chartHeight', 'sliceDuration', 'withStartTime'],
  ({
    date,
    sliceDuration,
    withStartTime,
    chartWidth = Wave.defaultProps.chartWidth,
    chartHeight = Wave.defaultProps.chartHeight,
  }) => {
    const paddings = withStartTime ? PADDINGS.approximated : PADDINGS.regular;

    const width = chartWidth + paddings.left + paddings.right;
    const height = chartHeight + paddings.top + paddings.bottom;

    const duration = moment.duration(sliceDuration);

    // Scale that divides width into hour bands
    const hourScale = scaleBand()
      .domain(hours.map(getHours))
      .rangeRound([0, chartWidth])
      .paddingInner(0.1);

    // Scale that divides hour band into slices
    const minutesScale = scaleBand()
      .domain(
        Array.from(
          hourRange.by('minutes', {
            step: duration.asMinutes(),
          }),
        ).map(getMinutes),
      )
      .range([0, hourScale.bandwidth()])
      .paddingInner(0.2);

    // Check if we have enough space. Prevent slices from glueing tohether
    minutesScale.round(
      Math.floor(minutesScale.step()) > Math.floor(minutesScale.bandwidth()),
    );

    // Calculate array of dates to visualize
    const dates =
      duration.asHours() >= 1
        ? Array.from(
            new DateRange(
              date.clone().startOf('day'),
              date.clone().endOf('day'),
            ).by('hours', {
              step: clamp(1, 24, duration.asHours()),
            }),
          )
        : Array.from(
            new DateRange(
              date.clone().startOf('day'),
              date.clone().endOf('day'),
            ).by('minutes', {
              step: duration.asMinutes(),
            }),
          );

    // We’re using bandScale to display slices but we want innerPadding to be
    // exactly the same no matter how many slices there are. To do this we
    // calculate slice width based on where previous slice would end if we would
    // draw regular hour slices.
    const [first, second] = dates;

    // Width = position of slice 1 hour before - scale offset
    const target = second ? second.clone().subtract(1, 'hour') : last(hours);

    const sliceWidth =
      duration.asHours() >= 1
        ? hourScale(getHours(target)) +
          hourScale.bandwidth() -
          hourScale(getHours(first))
        : minutesScale.bandwidth();

    const scale = scaleOrdinal<string, number>()
      .domain(dates.map(toHHmm))
      .range(
        dates.map(
          slice =>
            duration.asHours() >= 1
              ? hourScale(getHours(slice))
              : hourScale(getHours(slice)) + minutesScale(getMinutes(slice)),
        ),
      );

    return {
      dates,
      hourScale,
      scale,
      width,
      height,
      paddings,
      sliceWidth,
    };
  },
)(Wave);
