import React from 'react';
import { volumer } from '@hm/volumer-api';
import { Flex, Box } from 'grid-styled';
import { CounterSmart, Slider, Text } from '@hm/ukie';
import { propOr } from 'ramda';

import { connect } from 'react-redux';
import { getFirstObject } from '../../selectors/selectedObjects';
import { getType, getParams } from '../../selectors/inspector';
import { getObjectRadiusExtent } from '../../selectors/project';
import {
  radiusUpdateStart,
  radiusUpdateDrag,
  radiusUpdateStop,
  radiusChange,
  updateParams,
} from '../../actions/inspector';
import { AppState } from '../../models';

import { ObjectType } from '../../constants/objects';

interface Props {
  objectId: string;
  type: string;
  radius: number;
  min: number;
  max: number;
  onUpdateStart(message: volumer.event.RadiusDragStart$Properties): void;
  onUpdateDrag(message: volumer.event.RadiusDrag$Properties): void;
  onUpdateStop(message: volumer.event.RadiusDragStop$Properties): void;
  onRadiusChange(objectId: string, radius: number): void;
}

/**
 * Slider that controls radius of Radiuses and width of Polylines
 */
export const WidthControl: React.SFC<Props> = ({
  objectId,
  max,
  min,
  onUpdateStart,
  onUpdateDrag,
  onUpdateStop,
  onRadiusChange,
  type,
  radius,
}) => (
  <Flex>
    <Text mr={2} is="div">
      {type === ObjectType.RADIUS ? 'Radius' : 'Width'}
    </Text>
    <Flex width={120} alignItems="center" mr={2}>
      <Box flex={1}>
        <Slider
          onSliderDragStart={() => onUpdateStart({ objectId })}
          onValuesUpdated={({ values }) =>
            onUpdateDrag({ objectId, radius: values[0] })
          }
          onSliderDragEnd={() => onUpdateStop({ objectId })}
          values={[radius]}
          min={min}
          max={max}
        />
      </Box>
    </Flex>
    <Box width={56}>
      <CounterSmart
        onUpdate={value => onRadiusChange(objectId, value)}
        integersOnly
        noControls
        min={min}
        max={max}
        value={radius}
      />
    </Box>
  </Flex>
);

WidthControl.displayName = 'Inspector/WidthControl';

const mapStateToProps = (state: AppState) => ({
  type: getType(state),
  radius: getParams(state).radius,
  // FIXME: this is the case when we see inspector but no object yet
  objectId: propOr('', 'id', getFirstObject(state)),
  ...getObjectRadiusExtent(state),
});

const mapDispatchToProps = {
  onUpdateStart: radiusUpdateStart,
  onUpdateDrag: radiusUpdateDrag,
  onUpdateStop: radiusUpdateStop,
  onRadiusChange: radiusChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(WidthControl);

export const PresetControl = connect(mapStateToProps, {
  onUpdateDrag: ({ radius }: volumer.event.RadiusDrag$Properties) =>
    updateParams({ radius }),
})(WidthControl);
