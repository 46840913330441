import React from 'react';
import { Effect, Option } from '../../models';
import styled from 'styled-components';
import { prop } from 'ramda';
import { Category } from '../../reducers/categories';
import { smallCaps, semibold } from '../../styles/common';

const TooltipContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-flow: column;
  max-width: 490px;
`;

const InfoRow = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const RowLabel = styled.div`
  ${smallCaps} line-height: 13.8px;
  width: 65px;
  margin-right: 12px;
`;

const RowContent = styled.div`
  ${semibold};
`;

interface Props {
  categories: Category[];
  effects: Effect[];
}

const InfoPopover: React.SFC<Props> = ({ categories, effects }) => {
  return (
    <TooltipContainer>
      {categories.map((category, i) => (
        <InfoRow>
          <RowLabel>{category.label}</RowLabel>
          <RowContent>
            {(category.options as Option[])
              .filter(prop('enabled'))
              .map(prop('label'))
              .join(', ')}
          </RowContent>
        </InfoRow>
      ))}
      <InfoRow>
        <RowLabel>Effects</RowLabel>
        <RowContent>
          {effects
            .filter(prop('enabled'))
            .map(prop('label'))
            .join(', ')}
        </RowContent>
      </InfoRow>
    </TooltipContainer>
  );
};

export default InfoPopover;
