import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { connect } from 'react-redux';
import { Box } from 'grid-styled';
import { Scrollable, Text } from '@hm/ukie';

import {
  deleteEditor,
  updateEnvironment,
  deleteEnvironment,
} from '../../actions/environments';
import Environment, { Props as EnvironmentProps } from './Environment';
import { getEnvironmentById } from '../../selectors/environments';
import { AppState } from '../../models';

const Wrapper = Box.extend`
  height: 100%;
  background: ${props => props.theme.colors.white};
  line-height: 2em; // XXX: experimental
  border-left: solid 1px ${props => props.theme.colors.grey5};
  overflow-y: auto;
  overflow-x: hidden;
  user-select: auto;
`;

interface OwnProps {
  selectedEnvironmentId?: string;
}

type Props = OwnProps & EnvironmentProps;

export const EnvironmentInfo: React.SFC<Props> = ({
  selectedEnvironmentId,
  ...props
}) => (
  <Wrapper flex="0 0 auto" width={[320, 1 / 4, 1 / 4, 560]}>
    <Scrollable>
      <Box p={2}>
        {props.environment ? (
          <Environment {...props} />
        ) : (
          <Text.Disclaimer>
            <F
              id="Explorer/Environments.selectSomething"
              defaultMessage="Select an environment or project to see information about it"
            />
          </Text.Disclaimer>
        )}
      </Box>
    </Scrollable>
  </Wrapper>
);

export default connect(
  (state: AppState, ownProps: OwnProps) => ({
    environment: getEnvironmentById(state, {
      id: ownProps.selectedEnvironmentId,
    }) as any, // TODO: fix with proper Environment interface
  }),
  {
    onEditorDelete: deleteEditor,
    onUpdate: updateEnvironment,
    deleteEnvironment,
  },
)(EnvironmentInfo);
