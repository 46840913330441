import { Text } from '@hm/ukie';
import React from 'react';
import styled from 'styled-components';

interface Props {
  isLast: boolean;
}

const SliderTick = styled(({ isLast, ...props }: Props) => (
  <Text fontSize={0} {...props} />
))`
  cursor: pointer;

  transform: ${({ isLast }: Props) =>
    `translate(${isLast ? '-100%' : 0}, -28px)`};
`;

export default SliderTick;
