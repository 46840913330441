import { connect } from 'react-redux';
import CalendarInnerFooter from '../../components/Calendar/InnerFooter';
import { getZoomLevel } from '../../selectors/calendar';
import { changeZoomLevel } from '../../actions/calendar';
import { AppState } from '../../models';

const mapStateToProps = (state: AppState) => ({
  zoomLevel: getZoomLevel(state),
});

const mapDispatchToProps = {
  onZoomLevelChange: changeZoomLevel,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  CalendarInnerFooter,
);
