import { put, take, select } from 'redux-saga/effects';
import { getDraftObject } from '../../selectors/selectedObjects';
import { selectObject, deselectObject } from '../../actions/objects';
import { types } from './drag';

/**
 * Temporary selects dragged object if draft object exists
 */
export default function*() {
  while (true) {
    const { payload: objectIds } = yield take(types.START_DRAG);
    const draftObject = yield select(getDraftObject);

    const [firstObjectId] = objectIds;
    const onlyObjectDragged = objectIds.length === 1;
    const notDraft = draftObject && draftObject.id !== firstObjectId;

    if (onlyObjectDragged && notDraft) {
      yield take(types.STOP_DRAG);
      yield put.resolve(selectObject(draftObject.id));
      yield put(deselectObject(firstObjectId));
    }
  }
}
