import React from 'react';
import {
  FormattedMessage as F,
  injectIntl,
  InjectedIntlProps,
  defineMessages,
} from 'react-intl';
import { LocalForm, Control, Errors } from 'react-redux-form';
import { Submit, Text, Input, Password, ModalOverlay } from '@hm/ukie';
import { Field, FieldError, Fieldset, FormActions } from '../Form';
import { Relative } from '../Relative';
import { EmailUpdate, CurrentUser } from '../../models';
import { isRequired, isEmail, errorMessages } from '../../utils/validators';

const messages = defineMessages({
  newEmailPlaceholder: {
    id: 'Profile.newEmailPlaceholder',
    defaultMessage: 'New email',
  },
});

interface Props {
  user: CurrentUser;
  onSubmit(form: EmailUpdate): Promise<any>;
}

interface State {
  isSubmitting: boolean;
  didSubmit: boolean;
  didError: boolean;
}

class EmailChangeForm extends React.Component<
  Props & InjectedIntlProps,
  State
> {
  state = {
    isSubmitting: false,
    didSubmit: false,
    didError: false,
  };

  email: HTMLInputElement;
  password: HTMLInputElement;

  onSubmit = (form: EmailUpdate) => {
    this.setState({ isSubmitting: true }, () =>
      this.props
        .onSubmit(form)
        .then(r => {
          this.setState({
            isSubmitting: false,
            didSubmit: true,
            didError: false,
          });
        })
        .catch(() =>
          this.setState({
            isSubmitting: false,
            didSubmit: true,
            didError: true,
          }),
        ),
    );
  };

  render() {
    return (
      <LocalForm
        model="emailChange"
        onSubmit={this.onSubmit}
        initialState={{ email: '', password: '' }}
        validators={{
          email: { isRequired, isEmail },
          password: { isRequired },
        }}
        {
          ...{ hideNativeErrors: true } as any /*😝*/
        }
      >
        <Relative>
          {this.state.didSubmit ? (
            <ModalOverlay>
              {this.state.didError ? (
                <F
                  id="Profile.emailChangeRequestFailed"
                  defaultMessage="Incorrect password. Please try again."
                />
              ) : (
                <F
                  id="Profile.emailChangeRequestSent"
                  defaultMessage={`Your email has been changed successfully.
                    Follow a link in your inbox to confirm this change`}
                />
              )}
            </ModalOverlay>
          ) : null}
          <Fieldset>
            <Field
              label={
                <F
                  id="Profile.currentEmail"
                  defaultMessage="Current email"
                  tagName="div"
                />
              }
            >
              <Text.Important>{this.props.user.email}</Text.Important>
            </Field>

            <Field
              label={<F id="Profile.newEmail" defaultMessage="New Email" />}
            >
              <Control.text
                model=".email"
                mapProps={{
                  invalid: ({ fieldValue }) =>
                    !fieldValue.valid && !fieldValue.pristine,
                }}
                placeholder={this.props.intl.formatMessage(
                  messages.newEmailPlaceholder,
                )}
                component={Input}
                getRef={(el => (this.email = el)) as any}
                type="email"
              />
              <Errors
                show={field => field.focus && field.submitted}
                model=".email"
                messages={errorMessages.email}
                wrapper={props => <FieldError target={this.email} {...props} />}
              />
            </Field>

            <Field
              label={<F id="Profile.password" defaultMessage="Password" />}
            >
              <Control.text
                model=".password"
                mapProps={{
                  invalid: ({ fieldValue }) =>
                    !fieldValue.valid && !fieldValue.pristine,
                }}
                component={Password}
                getRef={(el => (this.password = el)) as any}
              />
              <Errors
                show={field => field.focus && !field.pristine}
                model=".password"
                messages={errorMessages.password}
                wrapper={props => (
                  <FieldError target={this.password} {...props} />
                )}
              />
            </Field>
          </Fieldset>
          <FormActions>
            <Submit disabled={this.state.isSubmitting}>
              <F id="Profile.submitEmail" defaultMessage="Save" />
            </Submit>
          </FormActions>
        </Relative>
      </LocalForm>
    );
  }
}

export default injectIntl(EmailChangeForm);
