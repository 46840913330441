import React from 'react';
import { Select2, SelectItem, Userpic, Select2Props, Text } from '@hm/ukie';
import { Flex, Box } from 'grid-styled';
import { Workspace } from '../../reducers/workspaces';

export interface Props {
  workspaces: Workspace[];
  currentWorkspaceId: string;
  onSelect(id: string): Promise<any>;
}

export const WorkspaceSelector: React.SFC<Props> = ({
  workspaces,
  currentWorkspaceId,
  onSelect,
}) => {
  const items = workspaces.map(w => ({ value: w.id, label: w.label, ...w }));

  return (
    <Select2
      items={items}
      selectedItem={items.find(({ id }) => id === currentWorkspaceId)}
      onChange={({ id }) => onSelect(id)}
      renderItem={(workspace, selectedItem) => (
        <Flex py={1}>
          <Box flex="0 0 auto" mr={1}>
            <Userpic image={workspace.avatarUrl} name={workspace.label} />
          </Box>
          <Text
            truncate
            fontWeight={
              selectedItem.id === workspace.id ? 'semibold' : 'normal'
            }
          >
            {workspace.label}
          </Text>
        </Flex>
      )}
    />
  );
};
export default WorkspaceSelector;
