import { FSA } from 'flux-standard-action';
import { assoc, dissoc, keys, map, merge, path, values } from 'ramda';
import { fulfill, pend, reject } from '../utils/promise';
import { API_REQUEST } from '../api/rest';

// Actions

export const types = {
  LIST: 'LIST',
  GET: 'GET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DESTROY: 'DESTROY',
};

export type DestroyActionCreator = (
  id: string,
) => FSA<{
  [API_REQUEST]: any;
  data: string;
}>;

export type EntityActions = typeof types;

export const createActionTypes = (namespace: string): EntityActions =>
  map(action => `${namespace}/${action}`, types);

// Reducer

export interface State<T> {
  entities: Dict<T>;
  isFetching: boolean;
}

export const initialState: State<any> = {
  entities: {},
  isFetching: false,
};

// TODO: split isFetching with combineReducers
export const createEntityReducer = (namespace: string) => (
  state = initialState,
  action: any,
) => {
  const { type, payload, meta } = action;
  const actionTypes = createActionTypes(namespace);

  switch (type) {
    case pend(actionTypes.LIST):
      return { ...state, isFetching: true };

    case fulfill(actionTypes.LIST):
      return {
        ...state,
        isFetching: false,
        entities: {
          ...state.entities,
          ...payload.entities[namespace],
        },
      };

    case reject(actionTypes.LIST):
      return { ...state, isFetching: false };

    case fulfill(actionTypes.GET):
    case fulfill(actionTypes.CREATE):
    case fulfill(actionTypes.UPDATE):
      return {
        ...state,
        isFetching: false,
        entities:
          meta && meta.optimistic
            ? state.entities
            : assoc(
                payload.result,
                path([namespace, payload.result], payload.entities),
                state.entities,
              ),
      };

    case pend(actionTypes.UPDATE):
      return payload && payload.id
        ? {
            ...state,
            isFetching: true,
            entities: assoc(
              payload.id,
              merge(state.entities[payload.id], payload.updates),
              state.entities,
            ),
          }
        : { ...state, isFetching: true };

    case pend(actionTypes.DESTROY):
      return {
        ...state,
        isFetching: true,
        entities: dissoc(payload, state.entities),
      };

    case fulfill(actionTypes.DESTROY):
      return { ...state, isFetching: false };

    default:
      return state;
  }
};

export default createEntityReducer;

// Selectors
// TODO: add reselect where needed
export const createEntitySelectors = <Entity>(namespace: string) => ({
  getEntities: <S>(state: S): Dict<Entity> => state[namespace].entities,
  getEntityById: <S>(state: S, { id }): Entity => state[namespace].entities[id],
  getList: <S>(state: S): Entity[] => values(state[namespace].entities),
  getIds: <S>(state: S) => keys(state[namespace].entities),
  getIsFetching: <S>(state: S): boolean => state[namespace].isFetching,
});
