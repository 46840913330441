import { Pictogram } from '@hm/ukie';
import styled from 'styled-components';

const Handle = styled(Pictogram).attrs({ name: 'handle' })`
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

export default Handle;
