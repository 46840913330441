import { connect } from 'react-redux';
import { hideModal } from '../actions/modals';
import { copyProject } from '../actions/projects';
import { getProjectLabel, getCurrentProjectId } from '../selectors/project';
import { AppState } from '../models';
import CopyDialog from '../components/CopyDialog';

const mapStateToProps = (state: AppState) => ({
  id: getCurrentProjectId(state),
  currentLabel: getProjectLabel(state),
});

const mapDispatchToProps = {
  onConfirm: copyProject,
  onHide: hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyDialog);
