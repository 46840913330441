import { takeLatest, take, put, select } from 'redux-saga/effects';

import moment from 'moment';

import { getLayerOriginId, getLastZoomLevel } from '../../selectors/calendar';
import { getCurrentSliceDuration } from '../../selectors/project';
import { INIT_NEW_APPROXIMATED_LAYER, init } from '../../actions/calendar';

import { GET_BASE_LAYER, getBaseLayer } from '../../actions/layers';

import { fulfill } from '../../utils/promise';
import { minDuration, getNiceZoomLevel } from '../../utils/time';

export default function*() {
  yield takeLatest(INIT_NEW_APPROXIMATED_LAYER, function*() {
    const originId = yield select(getLayerOriginId);

    yield put(getBaseLayer(originId));

    const { payload: { entities, result } } = yield take(
      fulfill(GET_BASE_LAYER),
    );
    const baseLayer = entities.baseLayers[result];

    const currentSliceDuration = yield select(getCurrentSliceDuration);
    const lastZoomLevel = yield select(getLastZoomLevel);
    const minLayerSliceDuration = minDuration(
      baseLayer.sliceDurations.map(moment.duration),
    );
    const isSliceTypeStatic = minLayerSliceDuration > currentSliceDuration;

    yield put(
      init({
        availableIntervals: baseLayer.availableTimeIntervals,
        sliceDuration: currentSliceDuration,
        zoomLevel: lastZoomLevel
          ? lastZoomLevel
          : getNiceZoomLevel(currentSliceDuration),
        sliceType: isSliceTypeStatic ? 'uniform' : 'unique',
        isSliceTypeEnabled: currentSliceDuration.asDays() < 1,
        label: baseLayer.label,
        isSliceTypeStatic,
      }),
    );
  });
}
