import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, RadioButtonGroup, Text } from '@hm/ukie';
import { Box } from 'grid-styled';

import { Lead, SemiBold } from '../Title';
import { SliceType } from '../../reducers/calendar';
import messages from './messages';

interface Props {
  sliceType: SliceType;
  isStatic: boolean;
  onSliceType(sliceType: SliceType): void;
}

const SliceTypeControl: React.SFC<Props> = ({
  sliceType,
  onSliceType,
  isStatic,
}) => (
  <div>
    {isStatic ? (
      <Lead>
        <FormattedMessage {...messages.sliceType} />{' '}
        <SemiBold>
          <FormattedMessage {...messages.uniform} />
        </SemiBold>
      </Lead>
    ) : (
      <div>
        <Lead>
          <FormattedMessage {...messages.sliceType} />
        </Lead>
        <RadioButtonGroup onClick={onSliceType} selected={sliceType}>
          <Button value="uniform">
            <FormattedMessage {...messages.uniform} />
          </Button>
          <Button value="unique">
            <FormattedMessage {...messages.unique} />
          </Button>
        </RadioButtonGroup>
      </div>
    )}
    <Box my="12px" width={2 / 3}>
      <FormattedMessage
        {...(sliceType === 'unique'
          ? messages.sliceTypeUnique
          : messages.sliceTypeUniform)}
      >
        {t => (
          <Text color="grey7" lineHeight="text">
            {t}
          </Text>
        )}
      </FormattedMessage>
    </Box>
  </div>
);

export default SliceTypeControl;
