import { Action } from 'redux';
import { clamp } from 'ramda';
import haversine from 'haversine';
import {
  take,
  cancel,
  fork,
  select,
  put,
  actionChannel,
} from 'redux-saga/effects';
import { buffers, Channel } from 'redux-saga';
import { volumer } from '@hm/volumer-api';
import initObject from './initObject';
import getNormalizedCoord from './getNormalizedCoord';
import { types } from '../../actions/volumer';
import {
  saveObject,
  updateObject,
  types as objectTypes,
} from '../../actions/objects';
import { MapObject } from '../../reducers/selectedObjects';
import { getObjectRadiusExtent } from '../../selectors/project';
import { CLICK_THRESHOLD } from '../../constants/volumer';
import { Tool } from '../../constants/tools';
import { pend } from '../../utils/promise';
import { xyThreshold } from '../../utils/eventHelper';
import { Pointer } from '../../models';
import { RaycastSender } from './getRaycastSender';
import { EventSender } from './getEventSender';

export default function*(
  initialPointer: Pointer,
  sendRaycast: RaycastSender,
  sendEvent: EventSender,
) {
  const { mapHit } = yield* sendRaycast(initialPointer, [
    volumer.raycast.Type.MAP,
  ]);
  if (!mapHit) return null;

  // Record actions not to miss any
  const clickQueue = yield actionChannel(
    types.CLICK,
    buffers.sliding<Action>(5),
  );
  const doubleClickQueue = yield actionChannel(
    types.DOUBLE_CLICK,
    buffers.sliding<Action>(5),
  );

  const radiusObject = yield* initObject(Tool.RADIUS, mapHit);

  const expandTask = yield fork(
    expand,
    radiusObject,
    initialPointer,
    sendRaycast,
    sendEvent,
  );

  const finishExpandTask = yield fork(
    finishExpand,
    sendRaycast,
    radiusObject,
    initialPointer,
    clickQueue,
  );

  const defaultRadiusTask = yield fork(
    createDefaultRadius,
    radiusObject,
    initialPointer,
    doubleClickQueue,
  );

  while (true) {
    const {
      payload: { id },
    } = yield take([
      pend(objectTypes.SAVE_OBJECT),
      pend(objectTypes.DELETE_OBJECT),
    ]);

    if (id !== radiusObject.id) continue;

    yield cancel(expandTask);
    yield cancel(finishExpandTask);
    yield cancel(defaultRadiusTask);
    break;
  }
}

function* expand(
  radiusObject: MapObject,
  initialPointer: Pointer,
  sendRaycast: RaycastSender,
  sendEvent: EventSender,
) {
  while (true) {
    const { payload: pointer } = yield take(types.POINTER_MOVE);

    if (pointer.offCanvas) continue;

    const { mapHit } = yield* sendRaycast(pointer, [volumer.raycast.Type.MAP]);
    if (!mapHit) continue;

    yield sendEvent('visualFeedback', {
      coord: yield* getNormalizedCoord(pointer),
      objectId: radiusObject.id,
    });
  }
}

function* finishExpand(
  sendRaycast: RaycastSender,
  radiusObject: MapObject,
  initialPointer: Pointer,
  clickChannel: Channel<Action>,
) {
  while (true) {
    const { payload: pointer } = yield take(clickChannel);
    if (pointer.offCanvas) continue;

    const tooCloseToCenter = xyThreshold(
      pointer,
      initialPointer,
      CLICK_THRESHOLD,
    );
    if (tooCloseToCenter) return null;

    const { mapHit } = yield* sendRaycast(pointer, [volumer.raycast.Type.MAP]);
    if (!mapHit) continue;

    const { min: minRadius, max: maxRadius } = yield select(
      getObjectRadiusExtent,
    );
    const center = radiusObject.points[0];
    const radius = haversine(
      { latitude: center.lat, longitude: center.lon },
      { latitude: mapHit.position.geo.lat, longitude: mapHit.position.geo.lon },
      { unit: 'meter' },
    );

    yield put(
      updateObject(radiusObject.id, {
        radius: Math.floor(clamp(minRadius, maxRadius, radius)),
      }),
    );

    yield put(saveObject(radiusObject));
  }
}

function* createDefaultRadius(
  radiusObject: MapObject,
  initialPointer: Pointer,
  doubleClickChannel: Channel<Action>,
) {
  while (true) {
    const { payload: pointer } = yield take(doubleClickChannel);
    const closeEnoughToCenter = xyThreshold(
      pointer,
      initialPointer,
      CLICK_THRESHOLD,
    );
    if (!closeEnoughToCenter) continue;

    yield put(
      updateObject(radiusObject.id, {
        setDefaultRadius: true,
      }),
    );

    yield put(saveObject(radiusObject));
  }
}
