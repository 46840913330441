import { MapObject } from '../reducers/selectedObjects';

const UPDATE = 'defaultObjectParams/UPDATE';

export const updateDefaultObjectParams = (
  tool: string,
  params: Partial<MapObject>,
) => ({
  type: UPDATE,
  payload: { tool, params },
});

export const types = {
  UPDATE,
};
