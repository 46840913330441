import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  getProjectLabel,
  getCurrentProjectId,
  getProjectOwner,
  getProjectEdirors,
} from '../../selectors/project';

import { getIsLoggedIn, getCurrentUser } from '../../selectors/user';
import { getProfile } from '../../actions/user';
import { renameProject } from '../../actions/projects';
import { getTitle } from '../../reducers/title';
import { AppState } from '../../models';

import Header from './Header';

export default withRouter(
  connect(
    // HACK: `withRouter` and `ownProps: any` are needed only to bypass SCU in
    // connect because Route components inside need history updates
    (state: AppState, ownProps: any) => ({
      title: getTitle(state),
      projectTitle: getProjectLabel(state),
      id: getCurrentProjectId(state),
      owner: getProjectOwner(state),
      editors: getProjectEdirors(state),
      isLoggedIn: getIsLoggedIn(state),
      currentUser: getCurrentUser(state),
    }),
    {
      onRename: renameProject,
      getProfile,
    },
  )(Header),
);
