import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Submit, Password, FillFlex } from '@hm/ukie';
import { LocalForm, Control, Errors } from 'react-redux-form';

import { FieldError, Field, FormActions, Fieldset, FormInfo } from '../Form';
import { Relative } from '../Relative';
import {
  errorMessages,
  isRequired,
  passwordsMatch,
  longEnough,
} from '../../utils/validators';
import { PasswordConfirmation, PasswordReset } from '../../models';

const Overlay = FillFlex.extend`
  background: ${({ theme }) => theme.colors.white};
`;

export interface OwnProps {
  token: string;
}

export interface ProvidedProps {
  onSubmit(form: PasswordReset): Promise<any>;
}

interface State {
  isSubmitting: boolean;
  didSubmit: boolean;
}

type Props = OwnProps & ProvidedProps;

export default class PasswordResetForm extends React.Component<Props, State> {
  state = { isSubmitting: false, didSubmit: false };

  password: HTMLInputElement;
  passwordConfirmation: HTMLInputElement;

  onSubmit = (passwordReset: PasswordConfirmation) => {
    this.setState({ isSubmitting: true }, () =>
      this.props
        .onSubmit({ ...passwordReset, token: this.props.token })
        .then(() => this.setState({ isSubmitting: false, didSubmit: true })),
    );
  };

  render() {
    const { didSubmit, isSubmitting } = this.state;

    return (
      <LocalForm
        model="passwordReset"
        onSubmit={this.onSubmit}
        initialState={{ password: '', passwordConfirmation: '' }}
        validators={{
          '': { passwordsMatch },
          password: { isRequired, passwordLength: longEnough(8) },
          passwordConfirmation: { isRequired },
        }}
      >
        <Relative>
          {didSubmit ? (
            <Overlay p={3}>
              <FormInfo>
                <F
                  id="ResetPassword.success"
                  defaultMessage={`Your password has been changed successfully.
                    Use it to enter Chronotope.`}
                />
              </FormInfo>
            </Overlay>
          ) : null}
          <Fieldset>
            <Field
              label={
                <F
                  id="SignUp.password"
                  defaultMessage="Password"
                  tagName="div"
                />
              }
            >
              <Control.text
                component={Password}
                model=".password"
                mapProps={{
                  invalid: ({ fieldValue }) =>
                    !fieldValue.valid && !fieldValue.pristine,
                }}
                getRef={(el => (this.password = el)) as any}
              />
              <Errors
                show={field => field.focus && !field.pristine}
                model=".password"
                messages={errorMessages.password}
                wrapper={props => (
                  <FieldError target={this.password} {...props} />
                )}
              />
            </Field>

            <Field
              label={
                <F
                  id="SignUp.confirmPassword"
                  defaultMessage="Confirm password"
                  tagName="div"
                />
              }
            >
              <Control.text
                component={Password}
                model=".passwordConfirmation"
                mapProps={{
                  invalid: ({ fieldValue }) =>
                    !fieldValue.valid && !fieldValue.pristine,
                }}
                getRef={(el => (this.passwordConfirmation = el)) as any}
              />
              <Errors
                show={field => field.focus && !field.pristine}
                model=".passwordConfirmation"
                messages={errorMessages.passwordConfirmation}
                wrapper={props => (
                  <FieldError target={this.passwordConfirmation} {...props} />
                )}
              />
              <Errors
                model="passwordReset"
                show={{ submitFailed: true } as any}
                messages={errorMessages.form}
                wrapper={props => (
                  <FieldError target={this.passwordConfirmation} {...props} />
                )}
              />
            </Field>
          </Fieldset>

          <FormActions style={{ visibility: didSubmit ? 'hidden' : 'visible' }}>
            <Submit disabled={isSubmitting}>
              <F id="ResetPassword.update" defaultMessage="Save" />
            </Submit>
          </FormActions>
        </Relative>
      </LocalForm>
    );
  }
}
