import React from 'react';
import { Box } from 'grid-styled';
import { IDateRangePickerProps } from '@blueprintjs/datetime';
import { Popover, Grid } from '@hm/ukie';

import DateRangePicker from './DateRangePicker';
import TimePicker from './TimePicker';
import Interval from './Interval';

const Wrapper = Box.extend.attrs({ p: 2 })`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.default};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};

  & > ${DateRangePicker} {
    margin-bottom: 8px;
  }
`;

const FlexGrid = Grid.extend`
  display: inline-flex;
  justify-content: center;
`;

interface Props extends IDateRangePickerProps {
  invalid?: boolean;
}

interface State {
  show: boolean;
}

// XXX: https://github.com/gpbl/react-day-picker/issues/473
// This is the place of suffering
export default class DateTimeRangePicker extends React.Component<Props, State> {
  state = { show: false };
  target: any;

  onToggle = () => this.setState({ show: !this.state.show });

  render() {
    const { value, onChange, invalid, ...props } = this.props;
    const start = value && value[0];
    const end = value && value[1];

    return (
      <Popover
        placement="left"
        content={
          <Wrapper>
            <DateRangePicker
              {...props}
              value={value}
              onChange={onChange}
              dayPickerProps={{
                onDayClick: day => day.setHours(0),
              }}
            />
            <FlexGrid width={1 / 2}>
              <TimePicker
                value={start}
                onChange={(time: Date) => onChange && onChange([time, end])}
              />
            </FlexGrid>
            <FlexGrid width={1 / 2}>
              <TimePicker
                value={end}
                onChange={(time: Date) => onChange && onChange([start, time])}
              />
            </FlexGrid>
          </Wrapper>
        }
      >
        <Interval
          value={value}
          ref={el => (this.target = el)}
          onClick={this.onToggle}
          invalid={invalid}
        />
      </Popover>
    );
  }
}
