import { take } from 'redux-saga/effects';
import { types } from '../../actions/volumer';
import getNormalizedCoord from './getNormalizedCoord';
import { EventSender } from './getEventSender';

export default function*(sendEvent: EventSender) {
  while (true) {
    const {
      payload: { pointer, delta },
    } = yield take(types.WHEEL);

    const coord = yield* getNormalizedCoord(pointer);
    yield sendEvent('zoom', { delta, coord });
  }
}
