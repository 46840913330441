import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { branch, renderComponent } from 'recompose';
import { prop } from 'ramda';

import { isApproximated } from '../selectors/project';
import { AppState } from '../models';

import ApproximatedNewLayer from './ApproximatedNewLayer';
import RegularNewLayer from './RegularNewLayer';

interface Props {
  isApproximated?: boolean;
}

interface OwnProps {
  onBack?(): void;
}

const Empty: React.SFC<OwnProps & Props> = () => null;

export default compose(
  connect((state: AppState, ownProps: OwnProps) => ({
    isApproximated: isApproximated(state),
  })),
  branch(
    prop('isApproximated'),
    renderComponent(ApproximatedNewLayer),
    renderComponent(RegularNewLayer),
  ),
)(Empty);
