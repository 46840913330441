import { connect } from 'react-redux';
import Alert from '../components/Alert';
import { AppState } from '../models';
import { getIsAlertShown, getAlertText } from '../selectors/alerts';

const mapStateToProps = (state: AppState) => ({
  text: getAlertText(state),
  show: getIsAlertShown(state),
});

export default connect(mapStateToProps)(Alert);
