import { connect } from 'react-redux';
import { lifecycle } from 'recompose';
import { initEdit, reset, saveEdits } from '../../actions/calendar';
import { hideModal } from '../../actions/modals';
import { AppState } from '../../models';
import { isTooManySlicesSelected } from '../../selectors/calendar';
import { getBaseLayersForCurrentEnvironment } from '../../reducers/baseLayers';

import EditRegularProject from './EditRegularProject';

const mapDispatchToProps = {
  onClose: hideModal,
  onMount: initEdit,
  onUnmount: reset,
  onSubmit: saveEdits,
};

export default connect(
  (state: AppState) => ({
    tooManySlices: isTooManySlicesSelected(state),
    metrics: getBaseLayersForCurrentEnvironment(state),
  }),
  mapDispatchToProps,
)(
  lifecycle({
    componentDidMount(this: any) {
      /* tslint:disable-next-line:no-invalid-this */
      this.props.onMount();
    },
    componentWillUnmount(this: any) {
      /* tslint:disable-next-line:no-invalid-this */
      this.props.onUnmount();
    },
  })(EditRegularProject),
);
