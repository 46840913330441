import React from 'react';
import { Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import { Fill, Text } from '@hm/ukie';
import IntlDocumentTitle from '../IntlDocumentTitle';

const CheckEmailPage: React.SFC = () => (
  <Fill>
    <Box mt={4} mx="auto" width="fit-content">
      <F
        id="SignUp.confirmationLetterSent"
        defaultMessage="The confirmation letter was sent to your email."
        tagName="div"
      >
        {text => <Text fontSize={2}>{text}</Text>}
      </F>
      <br />
      <F
        id="SignUp.checkIt"
        defaultMessage="Check it to start working with Chronotope."
        tagName="div"
      >
        {text => <Text fontSize={2}>{text}</Text>}
      </F>
    </Box>
    <IntlDocumentTitle titleId="signUp" />
  </Fill>
);

export default CheckEmailPage;
