import React from 'react';
import { connect } from 'react-redux';

import {
  getLayerOriginId,
  canAddSelectedLayer,
} from '../../selectors/calendar';
import {
  isApproximated,
  getCurrentEnvironmentId,
  getCurrentSliceDuration,
} from '../../selectors/project';

import {
  getBaseLayersForCurrentEnvironment,
  deleteBaseLayer,
  fetchBaseLayers,
  FetchBaseLayerListAction,
  updateBaseLayer,
} from '../../reducers/baseLayers';
import { hideModal } from '../../actions/modals';
import { createRegularLayer } from '../../actions/layers';
import { setLayerOriginId } from '../../actions/calendar';
import { AppState } from '../../models';

import NewMetricModal, { Props } from './NewMetricModal';

interface ContainerProps {
  fetchBaseLayers: FetchBaseLayerListAction;
  environmentId: string;
}

class NewMetricModalContainer extends React.Component<Props & ContainerProps> {
  componentWillMount() {
    this.props.fetchBaseLayers({ environmentId: this.props.environmentId });
  }

  render() {
    const { fetchBaseLayers: _, ...props } = this.props;
    return <NewMetricModal {...props} />;
  }
}

const mapStateToProps = (state: AppState) => ({
  layers: getBaseLayersForCurrentEnvironment(state),
  environmentId: getCurrentEnvironmentId(state),
  selectedId: getLayerOriginId(state),
  isApproximatedProject: isApproximated(state),
  canAdd: canAddSelectedLayer(state),
  currentSliceDuration: getCurrentSliceDuration(state),
});

const mapDispatchToProps = {
  addLayer: createRegularLayer,
  onClose: hideModal,
  fetchBaseLayers,
  onSelect: setLayerOriginId,
  onDelete: deleteBaseLayer,
  onUpdate: updateBaseLayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  NewMetricModalContainer,
);
