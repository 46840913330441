import React from 'react';
import { connect } from 'react-redux';
import { getSubscriptionActive } from '../../reducers/billing/selectors';
import { FormattedMessage as F } from 'react-intl';
import { Box } from 'grid-styled';
import { Text } from '@hm/ukie';
import { LogoMono } from '../Logo';

export const PageUnavailable: React.SFC = ({ children }) => (
  <Box width={240} mx="auto" mt={3}>
    <LogoMono />
    <F
      id="PageUnavailable.subscriptionRequied"
      defaultMessage="Subscription required"
    >
      {text => (
        <Text fontSize={3} fontWeight="semibold" is="h2" my={2}>
          {text}
        </Text>
      )}
    </F>
    {children}
  </Box>
);

export default connect(state => ({
  subscribed: getSubscriptionActive(state),
  billable: false,
}))(PageUnavailable);
