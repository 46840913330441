import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import { Button, Submit, Userpic } from '@hm/ukie';
import { LocalForm, Control } from 'react-redux-form';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import { Fieldset, FormActions } from '../Form';
import { updateUserpic } from '../../actions/user';
import { getCurrentUser } from '../../selectors/user';
import { AppState, CurrentUser } from '../../models';
import { isRequired } from '../../utils/validators';

interface OwnProps {
  onDone?(): void;
}

interface ProvidedProps {
  currentUser: CurrentUser;
  onSubmit(form: any): any;
}

type Props = ProvidedProps & OwnProps;

interface State {
  isSubmitting: boolean;
  error: any;
}

const Error = Box.extend.attrs({ px: 3, py: 1 })`
  background: ${({ theme }) => theme.colors.warning};
`;

export class UserpicForm extends React.Component<Props, State> {
  state = { isSubmitting: false, error: null };
  fileInput: HTMLInputElement;

  // event is Event in lib typings but it’s target is EventTarget and FormData
  // expects HTMLFormElement
  onSubmit = (form: any, event: any) => {
    const { onDone } = this.props;

    const formData = new FormData();
    formData.append('avatar', form.file[0], form.file[0].name);

    this.setState({ isSubmitting: true }, () => {
      this.props
        .onSubmit(formData)
        .then(() =>
          this.setState(
            { isSubmitting: false, error: null },
            () => onDone && onDone(),
          ),
        )
        .catch(({ error }) => this.setState({ isSubmitting: false, error }));
    });
  };

  render() {
    const { currentUser } = this.props;

    return (
      <LocalForm
        model="userpic"
        validators={{ file: { isRequired } }}
        initialState={{ file: currentUser.avatarUrl }}
        onSubmit={this.onSubmit}
        {...{ encType: 'multipart/form-data' }}
      >
        <Box px={3}>
          <Fieldset>
            <Flex flexDirection="column" align="center">
              <Control.custom
                model=".file"
                component={Userpic}
                controlProps={{
                  size: 216,
                  name: currentUser.name,
                }}
                mapProps={{
                  image: ({ modelValue }) =>
                    typeof modelValue === 'string' || isNil(modelValue)
                      ? modelValue
                      : URL.createObjectURL(modelValue[0]),
                }}
              />
              <Box mt={2}>
                <Button onClick={() => this.fileInput.click()}>
                  Upload new picture
                </Button>
              </Box>
              <Control.file
                model=".file"
                style={{ display: 'none' }}
                getRef={el => (this.fileInput = el)}
                accept="image/jpeg,image/png"
              />
            </Flex>
          </Fieldset>
        </Box>
        {this.state.error ? <Error>{this.state.error}</Error> : null}
        <FormActions px={3} pb={3}>
          <Control.button
            component={Submit}
            model="userpic"
            controlProps={{
              children: <F id="Profile.submitUserpic" defaultMessage="Save" />,
              disabled: this.state.isSubmitting,
            }}
            disabled={{ valid: false }}
          />
        </FormActions>
      </LocalForm>
    );
  }
}

export default connect(
  (state: AppState, props: OwnProps) => ({
    currentUser: getCurrentUser(state),
  }),
  { onSubmit: updateUserpic },
)(UserpicForm);
