import moment from 'moment';
import { createSelector } from 'reselect';
import {
  prop,
  compose,
  invoker,
  descend,
  ascend,
  comparator,
  toLower,
  equals,
  sortWith,
  groupBy,
} from 'ramda';

import { ProjectPreview } from '../reducers/projects';
import {
  BaseLayer,
  selectors as baseLayerSelectors,
} from '../reducers/baseLayers';
import { getProjectsList } from '../selectors/projects';
import {
  getEnvironments,
  getEnvironmensList,
  getSelectedOrAllIds,
} from '../selectors/environments';
import { AppState } from '../models';

export const types = {
  SORT: 'explorer/SORT',
};

export const sortByField = (field: SortValue) => ({
  type: types.SORT,
  payload: field,
});

export type SortValue = 'name' | 'changes' | 'access';

export interface ExplorerState {
  sortValue: SortValue;
}

export const initialState: ExplorerState = {
  sortValue: 'changes',
};

export default (state = initialState, action): ExplorerState =>
  action.type === types.SORT ? { ...state, sortValue: action.payload } : state;

export const getSortValue = (state: AppState) => state.explorer.sortValue;

const getLabel = compose(toLower, prop('label'));
const isEditor = compose(equals('editor'), prop('currentAccessRole'));
const getUpdatedAt = compose(
  invoker(0 as any, 'valueOf' as any),
  moment,
  prop('updatedAt'),
);

const sorting = {
  name: [ascend(getLabel)],
  changes: [descend(getUpdatedAt)],
  access: [comparator((a, b) => isEditor(a) && !isEditor(b)), ascend(getLabel)],
};

export const getSortedProjects = createSelector(
  getProjectsList,
  getSortValue,
  (projects, sortValue) =>
    sortWith<ProjectPreview>(sorting[sortValue] as any, projects),
);

export const getSortedLayers = createSelector(
  baseLayerSelectors.getList,
  getSortValue,
  (layers, sortValue) => sortWith<BaseLayer>(sorting[sortValue] as any, layers),
);

export const getFilteredEnvironments = createSelector(
  getEnvironmensList,
  getSelectedOrAllIds,
  (environments, ids) => environments.filter(({ id }) => ids.includes(id)),
);

export const getGroupedProjects = createSelector(
  getSortedProjects,
  groupBy(prop('environmentId')),
);

export const getEnvironmentsWithSortedLayers = createSelector(
  getSortedLayers,
  getEnvironments,
  getSelectedOrAllIds,
  (layers, environments, idsToGroupBy) =>
    idsToGroupBy.map(id => ({
      ...environments[id],
      layers: layers.filter(({ environmentIds }) =>
        environmentIds.includes(id),
      ),
    })),
);
