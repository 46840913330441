export interface DevState {
  xRay: boolean;
}

export const initialState = {
  xRay: false,
};

export const TOGGLE_XRAY = 'dev/TOGGLE_XRAY';

export const toggleXray = () => ({
  type: TOGGLE_XRAY,
});

export const getIsXrayEnabled = state => state.dev.xRay;

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_XRAY:
      return { ...state, xRay: !state.xRay };

    default:
      return state;
  }
};
