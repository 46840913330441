import { put, select } from 'redux-saga/effects';
import { volumer } from '@hm/volumer-api';
import {
  getLastFrameTs,
  getDecision,
  isActive,
  toggleActive,
  updateDecision,
  updateQuality,
} from '../../reducers/abr';
import { getFixedProjectQuality } from '../../selectors/project';
import { AbrDecision } from '../../models';

export function* checkAbr(frameQuality: number) {
  const active = yield select(isActive);
  const currentQuality = yield select(getFixedProjectQuality);

  if (!active && frameQuality === currentQuality) {
    yield put(toggleActive(true));
  }
}

export function* updateAbr(
  frameType: volumer.stream.FrameType,
  frameTs: number,
) {
  const lastFrameTs = yield select(getLastFrameTs);
  const frameDelay = lastFrameTs ? frameTs - lastFrameTs : null;

  if (!frameDelay) return null;

  yield put(updateDecision(frameDelay));
  const decision = yield select(getDecision);
  const currentQuality = yield select(getFixedProjectQuality);
  const newQuality =
    decision === AbrDecision.scaleUp
      ? parseFloat(Math.min(currentQuality + 0.1, 1.0).toFixed(1))
      : decision === AbrDecision.scaleDown
        ? parseFloat(Math.max(currentQuality - 0.1, 0.1).toFixed(1))
        : currentQuality;

  if (newQuality !== currentQuality) {
    yield put(updateQuality({ quality: newQuality }));
    yield put(toggleActive(false));
  }
}
