import { schema } from 'normalizr';
import moment from 'moment';
import { DateRange } from 'moment-range';

export default new schema.Entity(
  'baseLayers',
  {},
  {
    processStrategy: layer => ({
      ...layer,
      sliceDurations: layer.sliceDurations.map(d => moment.duration(d)),
      availableTimeIntervals: layer.availableTimeIntervals.map(
        ([start, end]) =>
          new DateRange(
            moment.tz(start, layer.timezone),
            moment.tz(end, layer.timezone),
          ),
      ),
      createdAt: new Date(layer.createdAt),
    }),
  },
);
