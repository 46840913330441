import React from 'react';
import { connect } from 'react-redux';

import { highlightTypeSelectors } from '../../reducers/highlightTypes';

import {
  Highlight,
  selectors,
  fetchHighlight,
  updateHighlight,
  calculateHighlight,
  deleteHighlight,
  getHighlightMetricLabel,
  canCalculate,
  HighlightState,
} from '../../reducers/highlights';

import { AppState } from '../../models';

import HighlightWidget, { Props, OwnProps } from './HighlightWidget';
import { getTimezone } from '../../selectors/project';

interface ContainerProps {
  fetchHighlight(id: string): Promise<any>;
}

class PollingHighlightWidget extends React.Component<Props & ContainerProps> {
  timer: any;

  componentDidMount() {
    this.checkHighlightState();
  }

  componentDidUpdate() {
    this.checkHighlightState();
  }

  checkHighlightState() {
    if (this.props.highlight.state === HighlightState.Processing) {
      this.startPolling();
    }
  }

  componentWillUnmount() {
    this.clearPolling();
  }

  startPolling = () => {
    if (this.timer) return;
    this.timer = setTimeout(
      () =>
        this.props
          .fetchHighlight(this.props.id)
          .then(({ value }: { value: Highlight }) => {
            if (value.state === HighlightState.Processing) {
              this.timer = null;
              this.startPolling();
            }
          }),
      5000 + Math.floor(Math.random() + 1500),
    );
  };

  clearPolling = () => clearInterval(this.timer);

  render() {
    const { fetchHighlight: _, ...props } = this.props;
    return <HighlightWidget {...props} />;
  }
}

export default connect(
  (state: AppState, props: OwnProps) => ({
    highlight: selectors.getEntityById(state, props),
    highlightTypes: highlightTypeSelectors.getList(state),
    metricLabel: getHighlightMetricLabel(state, props.id),
    canSubmit: canCalculate(state, props.id),
    projectTimezone: getTimezone(state),
  }),
  {
    fetchHighlight,
    onSubmit: calculateHighlight,
    onUpdate: updateHighlight,
    onDelete: deleteHighlight,
  },
)(PollingHighlightWidget);
