import React from 'react';
import { BorderBox } from '@hm/ukie';
import { Box } from 'grid-styled';
import { selection } from '../../styles/common';

import WithCard from './WithCard';
import WithoutCard from './WithoutCard';

const Btn = BorderBox.extend.attrs({
  is: 'button',
  type: 'button',
  role: 'button',
  py: 1,
  px: 2,
  borderRadius: 4,
  width: 248,
  bg: 'white',
  border: '1px solid',
})`
  outline: none;
  ${selection}
`;

interface Props {
  withCard: boolean;
  onChange(value: boolean): void;
}

const TrialOptions: React.SFC<Props> = ({ withCard, onChange }) => (
  <Box my={2}>
    <Btn selected={withCard} onClick={() => onChange(true)}>
      <WithCard />
    </Btn>
    <Btn selected={!withCard} onClick={() => onChange(false)} ml={2}>
      <WithoutCard />
    </Btn>
  </Box>
);

export default TrialOptions;
