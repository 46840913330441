import { Pictogram, Text } from '@hm/ukie';
import { Box, Flex } from 'grid-styled';
import React from 'react';
import Modal from './Modal';

interface Props {
  text: string;
  show: boolean;
  onClose?(): void;
}

const Container = Flex.extend`
  z-index: 10000;
  max-width: 600px;
  position: absolute;
  top: 0;

  border-radius: ${({ theme }) =>
    `0 0 ${theme.radius.big} ${theme.radius.big}`};
`;

const Alert: React.SFC<Props> = ({ text, show, onClose }) => (
  <Modal show={show} onHide={() => {}} keyboard={false}>
    <Container bg="warning" py={2} pl={80} pr={onClose ? 2 : 80}>
      <Text fontWeight="semibold">{text}</Text>
      {onClose ? (
        <Box
          alignSelf="flex-end"
          ml={80 - 24 - 16}
          style={{ cursor: 'pointer' }}
        >
          <Pictogram name="cross" onClick={onClose} />
        </Box>
      ) : null}
    </Container>
  </Modal>
);

export default Alert;
