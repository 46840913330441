import moment from 'moment';
import { DateRange } from 'moment-range';
import { range } from 'ramda';
import { types } from '../actions/projects';
import { types as layerTypes } from '../actions/layers';
import { types as volumerTypes } from '../actions/volumer';
import { types as abrTypes } from '../reducers/abr';
import { fulfill, pend } from '../utils/promise';
import { CameraMode, User } from '../models';
import { AvailableTimeFilter } from '../reducers/environments';

export interface ProjectFilter extends AvailableTimeFilter {
  settings: string[];
}

export type ProjectType = 'regular' | 'approximated';
export type ApproximationType = 'day' | 'week' | 'month' | 'year' | '';

// TODO: replace the one in models
export interface Project {
  id: string;
  label: string;
  type: ProjectType;
  realtime: boolean;
  sliceDuration: moment.Duration;
  timeWindow: [number, number];
  selectedTimeIntervals: DateRange[];
  filteredTimeIntervals: DateRange[];
  selectedObjectIds: string[];
  layersOrder: string[];
  objectRadiusMin: number;
  objectRadiusMax: number;
  isFetching: boolean;
  verticalScaling: number;
  timezone: string;
  environmentId: string;
  cameraMode: CameraMode;
  timeFilters: ProjectFilter[];
  showHiddenObjects: boolean;
  owner: User;
  editors: User[];
  streamerLowresQuality: number;
  legoModeEnabled: boolean;
  objectsHighlightEnabled: boolean;

  approximationType: ApproximationType;
  startTime: string;
  startAtWeekday: number;
  startAtMonthDay: number;
  startAtMonth: number;
  selectedWeekdays: number[];
  selectedMonthDays: number[];
  selectedMonths: number[];
}

export const initialApproximationSettings = {
  approximationType: 'day' as ApproximationType,
  startTime: '',
  startAtWeekday: 1,
  startAtMonthDay: 1,
  startAtMonth: 1,
  selectedWeekdays: range(1, 8),
  selectedMonthDays: range(1, 32),
  selectedMonths: range(1, 13),
};

// FIXME: there are places in app that depends on project object. Because of that
// we have some empty initialState which used only to render empty Welcome page
// when there is no project opened
export const initialState: Project = {
  id: null,
  label: '',
  type: 'regular' as ProjectType,
  realtime: false,
  sliceDuration: moment.duration('P5M'),
  timeWindow: [0, 1],
  selectedTimeIntervals: [],
  filteredTimeIntervals: [],
  selectedObjectIds: [],
  layersOrder: [],
  objectRadiusMin: 0,
  objectRadiusMax: 0,
  isFetching: false,
  verticalScaling: 0,
  timezone: null,
  environmentId: null,
  cameraMode: null,
  timeFilters: [],
  showHiddenObjects: false,
  editors: [],
  owner: null,
  streamerLowresQuality: 0,
  legoModeEnabled: false,
  objectsHighlightEnabled: true,
  ...initialApproximationSettings,
};

// TODO: merge with ./projects.ts
export default (state: Project = initialState, action: any): Project => {
  const { type, payload } = action;

  switch (type) {
    case pend(types.GET_PROJECT):
      return {
        ...state,
        isFetching: true,
      };

    case fulfill(types.NORMALIZED_UPDATE_PROJECT):
    case fulfill(types.GET_PROJECT):
      return {
        ...payload.entities.projects[payload.result],
        isFetching: false,
      };

    // NORMALIZED_UPDATE_PROJECT would have normalized project and
    // UPDATE_PROJECT would have optimistic values from `data`
    case pend(types.RENAME_PROJECT):
    case pend(types.OPTIMISTIC_UPDATE_PROJECT):
      if (payload.id !== state.id) return state;
      return { ...state, ...payload.updates, isFetching: true };

    case fulfill(types.RENAME_PROJECT):
    case fulfill(types.OPTIMISTIC_UPDATE_PROJECT):
      return { ...state, isFetching: false };

    case pend(types.DELETE_CURRENT_PROJECT):
      return initialState;

    case types.UPDATE_LAYERS_ORDER:
      return { ...state, layersOrder: payload };

    case types.VERTICAL_SCALING_DRAG:
      return { ...state, verticalScaling: payload };

    case pend(layerTypes.MOVE_LAYER):
      return { ...state, isFetching: true };

    case fulfill(layerTypes.MOVE_LAYER):
      return {
        ...state,
        layersOrder: payload.layersOrder,
        isFetching: false,
      };

    case volumerTypes.UPDATE_QUALITY_DRAG:
    case abrTypes.UPDATE_QUALITY:
      return { ...state, streamerLowresQuality: payload.message.quality };

    default:
      return state;
  }
};
