import { is, map } from 'ramda';
import { types } from '../actions/defaultObjectParams';
import { types as projectTypes } from '../actions/projects';
import { MapObject } from './selectedObjects';
import { Tool } from '../constants/tools';
import { MarkerSubtype, RoadSubtype } from '../constants/objects';
import { fulfill } from '../utils/promise';

export interface DefaultObjectParamsState {
  [tool: string]: Partial<MapObject>;
}

export const initialState: DefaultObjectParamsState = {
  [Tool.RADIUS]: { state: 'visible', radius: 0 },
  [Tool.POLYGON]: { state: 'visible' },
  [Tool.MARKER]: { state: 'visible', subtype: MarkerSubtype.SPATIO_TEMPORAL },
  [Tool.ROAD]: { state: 'visible', radius: 0, subtype: RoadSubtype.DRIVING },
  [Tool.POLYLINE]: { state: 'visible', radius: 0 },
};

const adjustDefaultRadius = (newRadiusValue: number) => (
  params: Partial<MapObject>,
) =>
  is(Number, params.radius) ? { ...params, radius: newRadiusValue } : params;

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.UPDATE:
      return {
        ...state,
        [payload.tool]: {
          ...state[payload.tool],
          ...payload.params,
        },
      };

    case fulfill(projectTypes.GET_PROJECT):
      const { objectRadiusMin } = payload;
      if (!objectRadiusMin) return state;

      return map(adjustDefaultRadius(objectRadiusMin), state);

    default:
      return state;
  }
};
