import { takeEvery } from 'redux-saga/effects';
import { types } from '../../actions/layers';
import { EventSender } from './getEventSender';

export default function*(sendEvent: EventSender) {
  yield takeEvery(types.DRAG_HISTOGRAM_START, startDrag, sendEvent);
  yield takeEvery(types.DRAG_HISTOGRAM_STOP, stopDrag, sendEvent);
  yield takeEvery(types.DRAG_HISTOGRAM, drag, sendEvent);
}

function* drag(sendEvent: EventSender, action: any) {
  const {
    layerId,
    histogramWindow: { min, max },
  } = action.payload;
  yield* sendEvent('histogramDrag', {
    layerId,
    window: { x: min, y: max },
  });
}

function* startDrag(sendEvent: EventSender, action: any) {
  const { layerId } = action.payload;
  yield* sendEvent('histogramDragStart', { layerId });
}

function* stopDrag(sendEvent: EventSender, action: any) {
  const { layerId } = action.payload;
  yield* sendEvent('histogramDragStop', { layerId });
}
