import {
  Button,
  Icon,
  IconButton,
  Input,
  Pictogram,
  RadioButtonGroup,
  Select,
  Spinner,
  Text,
  Widget,
  WidgetSection,
  colors,
} from '@hm/ukie';
import { Box, Flex } from 'grid-styled';
import { transparentize } from 'polished';
import { pluck } from 'ramda';
import React from 'react';
import { FormattedMessage as F } from 'react-intl';

import { HighlightType } from '../../reducers/highlightTypes';
import {
  Highlight,
  HighlightState,
  HighlightSubsets,
} from '../../reducers/highlights';
import Collapsible from '../Collapsible';
import TimeInterval from '../TimeInterval';

export interface OwnProps {
  id: string;
}

export interface StateProps {
  highlight: Highlight;
  highlightTypes: HighlightType[];
  metricLabel: string;
  canSubmit: boolean;
  projectTimezone: string;
}

export interface DispatchProps {
  onUpdate(
    id: string,
    updates: Partial<Highlight>,
    optimisticOnly?: boolean,
  ): Promise<any>;
  onSubmit(id: string): Promise<any>;
  onDelete(id: string): Promise<any>;
}

export type Props = OwnProps & StateProps & DispatchProps;

const HighlightWidget: React.SFC<Props> = ({
  highlight,
  highlightTypes,
  metricLabel,
  canSubmit,
  projectTimezone,
  onUpdate,
  onSubmit,
  onDelete,
}) => (
  <Widget
    header={
      <Flex width="100%">
        {highlight.state === HighlightState.Ready ? (
          <React.Fragment>
            <Box mr={1}>
              <IconButton
                onClick={() =>
                  onUpdate(highlight.id, { visible: !highlight.visible })
                }
                icon={
                  highlight.visible ? (
                    <Pictogram
                      name="eyeOpened"
                      size="medium"
                      width={16}
                      height={16}
                    />
                  ) : (
                    <Pictogram
                      name="eyeClosed"
                      color={transparentize(0.5, colors.grey1)}
                      size="medium"
                      width={16}
                      height={16}
                    />
                  )
                }
              />
            </Box>
            <Box mr={1}>
              <IconButton
                onClick={() =>
                  onUpdate(highlight.id, { enabled: !highlight.enabled })
                }
                icon={
                  highlight.enabled ? (
                    <Pictogram name="enable" width={16} height={16} />
                  ) : (
                    <Pictogram name="disableHide" width={16} height={16} />
                  )
                }
              />
            </Box>
          </React.Fragment>
        ) : null}
        <Box flex={1}>
          <Input
            titleFont
            value={highlight.label}
            onChange={e =>
              onUpdate(highlight.id, { label: e.currentTarget.value })
            }
          />
        </Box>
        {/* will be replaced with menu when there are more available actions */}
        <Box alignSelf="end" ml={1}>
          <IconButton icon="bin" onClick={() => onDelete(highlight.id)} />
        </Box>
      </Flex>
    }
  >
    <WidgetSection>
      {highlight.state === HighlightState.Processing ? (
        <Flex justifyContent="center">
          <Spinner size="small" />
        </Flex>
      ) : highlight.state === HighlightState.Failed ? (
        <Flex justifyContent="center">
          <Icon width={24} height={24} name="warning" color="error" />
        </Flex>
      ) : (
        <Collapsible
          title={highlight.typeLabel || 'Highlight info'}
          showTitleWhenOpen={false}
          isOpen
        >
          <Flex>
            <Box width={1 / 3} flex="0 0 auto">
              <F
                id="HighlightWidget.metricLabel"
                defaultMessage="Source Metric"
              >
                {t => <Text>{t}</Text>}
              </F>
            </Box>
            <Text fontWeight="semibold" is="div">
              {metricLabel}
            </Text>
          </Flex>

          {highlight.categories.length > 0 ? (
            <Flex mt={1}>
              <Box width={1 / 3} flex="0 0 auto">
                <F
                  id="HighlightWidget.subsets"
                  defaultMessage="Selected Subsets"
                >
                  {t => <Text>{t}</Text>}
                </F>
              </Box>
              <Box>
                {highlight.state === HighlightState.Initial ? (
                  <Box mb={1}>
                    <RadioButtonGroup
                      selected={highlight.subsets}
                      onClick={(subsets: HighlightSubsets) =>
                        onUpdate(highlight.id, { subsets }, false)
                      }
                    >
                      <Button value="all">
                        <F
                          id="HighlightWidget.subsets.all"
                          defaultMessage="All"
                        />
                      </Button>
                      <Button value="selected">
                        <F
                          id="HighlightWidget.subsets.selected"
                          defaultMessage="Selected"
                        />
                      </Button>
                    </RadioButtonGroup>
                  </Box>
                ) : null}
                {highlight.categories.map(category => (
                  <Box key={category.id}>
                    <Text fontWeight="semibold">{category.label}</Text>:{' '}
                    <Text lineHeight="text">
                      {pluck('label')(category.options).join(', ')}
                    </Text>
                  </Box>
                ))}
              </Box>
            </Flex>
          ) : null}

          <Flex mt={1}>
            <Box width={1 / 3}>
              <F
                id="HighlightWidget.timeInterval"
                defaultMessage="Time interval"
              >
                {t => <Text>{t}</Text>}
              </F>
            </Box>

            <Text fontWeight="semibold">
              <TimeInterval
                interval={highlight.timeInterval}
                timezone={projectTimezone}
              />
            </Text>
          </Flex>

          <Flex mt={1}>
            <Box width={1 / 3}>
              <F
                id="HighlightWidget.highlightType"
                defaultMessage="Highlight Type"
              >
                {t => <Text>{t}</Text>}
              </F>
            </Box>
            <Box>
              {highlight.state === HighlightState.Initial ? (
                <Select
                  value={highlight.typeId || highlightTypes[0].id}
                  onChange={typeId => onUpdate(highlight.id, { typeId })}
                  style={{ width: '100%' }}
                >
                  {highlightTypes.map(highlightType => (
                    <option
                      key={highlightType.id}
                      label={highlightType.label}
                      value={highlightType.id}
                    />
                  ))}
                </Select>
              ) : (
                <Text fontWeight="semibold">{highlight.typeLabel}</Text>
              )}
            </Box>
          </Flex>

          {highlight.state === HighlightState.Ready ? (
            <Flex mt={1}>
              <Box width={1 / 3}>
                <F
                  id="HighlightWidget.regions"
                  defaultMessage="Number of regions"
                >
                  {t => <Text>{t}</Text>}
                </F>
              </Box>
              <Box>
                <F
                  id="HighlightWidget.numberOfRegions"
                  defaultMessage="{regions, plural, =0 {No regions} one {# region} other {# regions}}"
                  values={{ regions: highlight.regions.length }}
                >
                  {(...t) => <Text fontWeight="semibold">{t}</Text>}
                </F>
              </Box>
            </Flex>
          ) : null}

          {highlight.state === HighlightState.Initial ? (
            <Flex justifyContent="flex-end" mt={2}>
              <Button
                primary
                disabled={!canSubmit}
                onClick={() => onSubmit(highlight.id)}
              >
                <F id="HighlightWidget.calculate" defaultMessage="Calculate" />
              </Button>
            </Flex>
          ) : null}
        </Collapsible>
      )}
    </WidgetSection>
  </Widget>
);

HighlightWidget.defaultProps = {
  highlightTypes: [],
};

export default HighlightWidget;
