import { DateRange } from 'moment-range';
import { mergeIntervals, invertSelection } from '../utils/time';

export interface SelectionState {
  currentInterval: DateRange;
  isInverted: boolean;
  selectedIntervals: DateRange[];
}

export const initialState: SelectionState = {
  selectedIntervals: [],
  currentInterval: null,
  isInverted: false,
};

const TIME_INTERVAL_SELECTION = 'TIME_INTERVAL_SELECTION';
const TIME_INTERVAL_SELECTION_END = 'TIME_INTERVAL_SELECTION_END';
const TIME_INTERVAL_SELECTION_CLICK = 'TIME_INTERVAL_SELECTION_CLICK';

export const createSelectionActionType = (prefix: string) =>
  `${prefix}/${TIME_INTERVAL_SELECTION}`;

export const createSelectionActionCreator = (prefix: string) => (
  interval: DateRange,
  isInverted = false,
) => ({
  type: `${prefix}/${TIME_INTERVAL_SELECTION}`,
  payload: { interval, isInverted },
});

export const createSelectionEndActionCreator = (prefix: string) => (
  apply = true,
) => ({
  type: `${prefix}/${TIME_INTERVAL_SELECTION_END}`,
  payload: { apply },
});

export const createSelectionEndActionType = (prefix: string) =>
  `${prefix}/${TIME_INTERVAL_SELECTION_END}`;

export const createSelectionClickActionType = (prefix: string) =>
  `${prefix}/${TIME_INTERVAL_SELECTION_CLICK}`;

export const createSelectionClickActionCreator = (prefix: string) => (
  interval: DateRange,
  isInverted = false,
) => ({
  type: `${prefix}/${TIME_INTERVAL_SELECTION_CLICK}`,
  payload: { interval, isInverted },
});

/** Creates namespaced reducer to use with calendar selection */
export default (prefix = 'selection') => (
  state = initialState,
  action,
): SelectionState => {
  switch (action.type) {
    case createSelectionActionType(prefix):
      return {
        ...state,
        currentInterval: action.payload.interval,
        isInverted: action.payload.isInverted,
      };

    case createSelectionEndActionType(prefix):
      const newSelectedIntervals = state.isInverted
        ? invertSelection(state.selectedIntervals, state.currentInterval)
        : mergeIntervals(
            [...state.selectedIntervals, state.currentInterval].sort(),
          );

      return {
        ...state,
        isInverted: false,
        currentInterval: null,
        selectedIntervals: action.payload.apply
          ? newSelectedIntervals
          : state.selectedIntervals,
      };

    case createSelectionClickActionType(prefix):
      return {
        ...state,
        selectedIntervals: action.payload.isInverted
          ? invertSelection(state.selectedIntervals, action.payload.interval)
          : mergeIntervals(
              [...state.selectedIntervals, action.payload.interval].sort(),
            ),
      };

    default:
      return state;
  }
};
