import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import { head, last, isEmpty } from 'ramda';
import { conforms } from '../utils/conditions';
import UserSelect from './UserSelect';

/**
 * TODO: simplify
 */

interface Props {
  selectedIntervals: DateRange[];
  sliceDuration: moment.Duration;
  isApproximated?: boolean;
  timezone: string;
}

interface Condition {
  isApproximated: boolean | Function;
  sliceDuration?: moment.Duration | Function;
  sameDay?: boolean | Function;
  fullDay?: boolean | Function;
}

interface Display {
  condition: Condition;
  getDisplay(start: moment.Moment, end: moment.Moment): string;
}

const dateFull = 'D MMM Y';
const dateShort = 'D MMM';
const time = 'H:mm';
const full = `${dateFull} ${time}`;

const lessThanDay = (duration: moment.Duration): boolean =>
  duration.asDays() < 1;

const isMidnight = m => m.hour() === 0 && m.minute() === 0;

const endTimeFormat = (m: moment.Moment) =>
  isMidnight(m) ? '24:00' : m.format(time);

const displays: Display[] = [
  {
    condition: {
      isApproximated: false,
      sliceDuration: lessThanDay,
      sameDay: true,
    },
    getDisplay: (start, end) => `${start.format(full)} – ${end.format(time)}`,
  },
  {
    condition: {
      isApproximated: false,
      sliceDuration: lessThanDay,
      sameDay: false,
    },
    getDisplay: (start, end) => `${start.format(full)} – ${end.format(full)}`,
  },
  {
    condition: { isApproximated: false },
    getDisplay: (start, end) =>
      `${start.format(dateFull)} – ${end.format(dateFull)}`,
  },
  {
    condition: {
      isApproximated: true,
      sliceDuration: lessThanDay,
      sameDay: true,
    },
    getDisplay: (start, end) => `${start.format(time)} – ${endTimeFormat(end)}`,
  },
  {
    condition: {
      isApproximated: true,
      sliceDuration: lessThanDay,
      sameDay: false,
    },
    getDisplay: (start, end) => {
      const length = Math.ceil(end.diff(start, 'days', true));
      const finalDay = isMidnight(end) ? length : length + 1;
      return `Day 1, ${start.format(time)} – Day ${finalDay}, ${endTimeFormat(
        end,
      )}`;
    },
  },
  {
    condition: { isApproximated: true },
    getDisplay: (start, end) =>
      `${start.format(dateShort)} – ${end.format(dateShort)}`,
  },
];

/**
 * Displays total start and end times for list of time intervals
 */
const SelectedInterval: React.SFC<Props> = props => {
  const { selectedIntervals, isApproximated, sliceDuration, timezone } = props;

  if (isEmpty(selectedIntervals)) return null;

  const start = head(selectedIntervals).start.tz(timezone);
  const end = last(selectedIntervals).end.tz(timezone);

  const display = displays.find(({ condition }) => {
    const oneDay = end.diff(start, 'days') === 1;
    const preciseDay = oneDay && isMidnight(start) && isMidnight(end);

    return conforms<Condition>(condition, {
      isApproximated,
      sliceDuration,
      sameDay: start.isSame(end, 'days') || (isApproximated && preciseDay),
    });
  });

  if (!display) throw Error('No appropriate display for selected interval');

  return <UserSelect>{display.getDisplay(start, end)}</UserSelect>;
};

SelectedInterval.defaultProps = {
  isApproximated: false,
};

export default SelectedInterval;
