import { SidebarTab, SidebarPin } from '../reducers/sidebar';

const CHANGE_TAB = 'sidebar/CHANGE_TAB';
const TOGGLE_PIN = 'sidebar/TOGGLE_PIN';

export const changeTab = (tab: SidebarTab) => ({
  type: CHANGE_TAB,
  payload: tab,
});

export const togglePin = (pin: SidebarPin, enabled: boolean) => ({
  type: TOGGLE_PIN,
  payload: { pin, enabled },
});

export const goToHighlights = () => changeTab(SidebarTab.Highlights);

export const types = {
  CHANGE_TAB,
  TOGGLE_PIN,
};
