import React from 'react';
import { withStateHandlers, withHandlers, compose } from 'recompose';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import Overlay from 'react-overlays/lib/Overlay';
import { ModeButton } from '@hm/ukie';

interface OwnProps {
  text: string;
}

interface State {
  show: boolean;
}

interface StateUpdaters {
  toggleShow(show: boolean): void;
}

interface ProvidedHandlers {
  onCopy(): void;
}

type Props = State & StateUpdaters & OwnProps & ProvidedHandlers;

const Message = styled.div`
  position: absolute;
  background: white;
  width: 56px;
  padding: 4px;
  border-radius: 2px; // TODO: add size to @hm/ukie/radius
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transform: translate(0, 4px);
`;

const InlineModeButton = ModeButton.extend`
  display: inline;
  margin-left: 4px;

  &:active use {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

Message.defaultProps = {
  children: 'Copied to clipboard!',
};

const Copy: React.SFC<Props> = ({ show, onCopy, text }) => {
  let target = null;

  return (
    <span>
      <CopyToClipboard text={text} onCopy={onCopy}>
        <InlineModeButton ref={button => (target = button)} icon="copy" />
      </CopyToClipboard>
      <Overlay
        show={show}
        target={() => target}
        shouldUpdatePosition
        placement="bottom"
      >
        <Message />
      </Overlay>
    </span>
  );
};

// TODO: remove recompose
export default compose<Props, OwnProps>(
  withStateHandlers<State, any, OwnProps>(
    { show: false },
    { toggleShow: () => (show: boolean) => ({ show }) },
  ),
  withHandlers<State & StateUpdaters & OwnProps, ProvidedHandlers>({
    onCopy: ({ toggleShow }) => () => {
      toggleShow(true);
      setTimeout(() => toggleShow(false), 1500);
    },
  }),
)(Copy);
