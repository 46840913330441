import React from 'react';
import { values, without } from 'ramda';
import {
  BigButton,
  ModeButton,
  AvailableIcons,
  colors,
  Toolbar,
} from '@hm/ukie';
import { injectIntl, InjectedIntlProps } from 'react-intl';

import { Relative } from '../Relative';
import messages from './messages';
import { Tool } from '../../constants/tools';

const BigModeButton = ModeButton.extend`
  width: 32px;
  height: 32px;
  background: ${({ selected }) => (selected ? colors.primary : colors.white)};

  & path {
    fill: ${({ selected }) => (selected ? colors.white : colors.grey1)};
  }

  &:focus,
  &:hover:not(:disabled) {
    background: ${({ selected }) => (selected ? colors.blue2 : colors.grey3)};

    & path {
      fill: ${({ selected }) =>
        selected ? colors.white : colors.defaultColor};
    }
  }
`;

interface OwnProps {
  selectedTool: string;
  projectId: string;
  showHiddenObjects: boolean;
  objectsHighlightEnabled: boolean;
  updateProject(id: string, update: any): void;
  selectTool(tool: string): void;
  takeScreenshot(): void;
}

type Props = OwnProps & InjectedIntlProps;

const icons: { [tool: string]: AvailableIcons } = {
  [Tool.NAVIGATION]: 'move',
  [Tool.CURSOR]: 'cursor',
  [Tool.MARKER]: 'flag',
  [Tool.POLYLINE]: 'polyline',
  [Tool.POLYGON]: 'polygon',
  [Tool.RADIUS]: 'radius',
  [Tool.ROAD]: 'road',
};

// XXX: Road is temporary unavailable on Concierge (03.07.2017)
const shapeTools = without(
  [Tool.NAVIGATION, Tool.CURSOR, Tool.ROAD],
  values(Tool),
);

const Tools: React.SFC<Props> = ({
  selectTool,
  selectedTool,
  takeScreenshot,
  showHiddenObjects,
  objectsHighlightEnabled,
  updateProject,
  projectId,
  intl,
}) => (
  <Relative>
    <Toolbar>
      <BigButton
        icon={icons[Tool.NAVIGATION]}
        primary={selectedTool === Tool.NAVIGATION}
        title={intl.formatMessage(messages.navigation)}
        onClick={() => selectTool(Tool.NAVIGATION)}
      />
      <BigButton
        icon={icons[Tool.CURSOR]}
        primary={selectedTool === Tool.CURSOR}
        onClick={() => selectTool(Tool.CURSOR)}
        title={intl.formatMessage(messages.cursor)}
      />
    </Toolbar>

    <Toolbar>
      {shapeTools.map(tool => (
        <BigButton
          key={tool}
          icon={icons[tool]}
          primary={tool === selectedTool}
          onClick={() => selectTool(tool)}
          title={intl.formatMessage(messages[tool])}
        />
      ))}
    </Toolbar>

    <Toolbar>
      <BigModeButton
        icon="showHideObjects"
        title={intl.formatMessage(messages.toggleHiddenObjects)}
        selected={showHiddenObjects}
        onClick={() =>
          updateProject(projectId, { showHiddenObjects: !showHiddenObjects })
        }
      />

      <BigModeButton
        icon="highlight"
        title={intl.formatMessage(messages.highlight)}
        selected={objectsHighlightEnabled}
        onClick={() =>
          updateProject(projectId, {
            objectsHighlightEnabled: !objectsHighlightEnabled,
          })
        }
      />
    </Toolbar>

    <Toolbar>
      <BigButton
        icon="screenshot"
        title={intl.formatMessage(messages.screenshot)}
        selected={false}
        onClick={takeScreenshot}
      />
    </Toolbar>
  </Relative>
);

export default injectIntl(Tools);
