import React from 'react';
import { max, reduce, prop } from 'ramda';
import { getBinScale, getColorScale } from '../../utils/histogram';
import { Point } from '../../reducers/gradients';
import { HistogramData } from '../../models';

interface Props {
  bins: HistogramData[];
  width: number;
  height: number;
  style?: any;
  gradientPoints?: Point[];
  clipPath?: string;
}

export default class LinearHeatmap extends React.Component<Props, never> {
  render() {
    const { bins, width, height, clipPath, gradientPoints, style } = this.props;

    const xScale = getBinScale(bins.length, width);
    const colorScale =
      gradientPoints && getColorScale(gradientPoints, bins.length);

    const maxY = reduce(max, 0, bins.map(prop('y'))) as number;

    return (
      <g clipPath={clipPath}>
        {bins.map((d, i) => (
          <rect
            style={{ ...style, fill: colorScale ? colorScale(i) : style.fill }}
            height={height}
            width={xScale.bandwidth()}
            key={i}
            opacity={Math.sqrt(d.y) / Math.sqrt(maxY / 1.2)}
            transform={`translate(${xScale(i)})`}
          />
        ))}
      </g>
    );
  }
}
