import React from 'react';
import moment from 'moment';
import { typography } from '@hm/ukie';
import styled from 'styled-components';

const StyledDiv = styled.div`
  font-size: ${typography.title};
  margin-bottom: 14px;
  padding-left: 4px;
`;

const Title: React.SFC<{ date: moment.Moment }> = ({ date }) => (
  <StyledDiv>{date.year()}</StyledDiv>
);

export default Title;
