import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getIsLoggedIn } from '../selectors/user';
import { logOut } from '../actions/user';
import { AppState } from '../models';

interface Props {
  isLoggedIn: boolean;
  logOut(): void;
}

class Logout extends React.Component<Props, never> {
  componentDidMount() {
    this.props.logOut();
  }

  render() {
    return this.props.isLoggedIn ? null : <Redirect to="/" />;
  }
}

export default connect(
  (state: AppState) => ({ isLoggedIn: getIsLoggedIn(state) }),
  { logOut },
)(Logout);
