import { withHandlers, compose } from 'recompose';
import moment from 'moment';
import { DateRange } from 'moment-range';
import {
  DragSource,
  DropTarget,
  ConnectDragSource,
  ConnectDropTarget,
  DragSourceMonitor,
  DropTargetMonitor,
} from 'react-dnd';
import { addDuration } from '../utils/time';

export const SLICE = 'slice';

/** Data structure for source-target data stream */
interface SliceDndSelectionProps {
  date: moment.Moment;
  isInverted: boolean;
}

/** Props that are injected into component by DragSource */
export interface SelectionStartProps {
  connectDragSource?: ConnectDragSource;
  isSelecting?: boolean;
}

/** Props that are injected into component by DropTarget */
export interface SelectionEndProps {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
}

export type SliceSelectorProps = SelectionStartProps & SelectionEndProps;

/** Props that are required by slice selection */
export interface SlicerData {
  date: moment.Moment;
  isSelected: boolean;
  ownDuration: string;
}

/** selection event handlers */
export interface SliceEventProps {
  onSelectionClick(selection: DateRange, isInverted?: boolean): void;
  onSelection(selection: DateRange, isInverted?: boolean): void;
  onSelectionEnd(apply?: boolean): void;
}

export type SlicerProps = SlicerData & SliceEventProps;

/** dnd function for start of selection node */
const source = {
  beginDrag: ({ date, isSelected }: SlicerProps) => ({
    date,
    isInverted: isSelected,
  }),
  endDrag: (
    { date, onSelectionEnd }: SlicerProps,
    monitor: DragSourceMonitor,
  ) => {
    const dropTarget = monitor.getDropResult() as SliceDndSelectionProps;
    const endedOnSameDate = dropTarget && date.isSame(dropTarget.date);
    onSelectionEnd(!endedOnSameDate);
  },
  isDragging: ({ date }: SlicerProps, monitor: DragSourceMonitor) =>
    date.isSame((monitor.getItem() as SliceDndSelectionProps).date),
};

/** dnd function for end of selection node */
const target = {
  drop: ({ date }: SlicerProps) => ({ date }),
  hover: (
    { onSelection, date, ownDuration }: SlicerProps,
    monitor: DropTargetMonitor,
  ) => {
    const {
      date: startDate,
      isInverted,
    } = monitor.getItem() as SliceDndSelectionProps;

    const [start, end] = [date, startDate].sort((a: any, b: any) => a - b);

    // we’re adding one slice to make selection inclusive
    onSelection(
      // we should add not slice duration but component duration
      new DateRange(start, addDuration(moment.duration(ownDuration), end)),
      isInverted,
    );
  },
};

/**
 * HOC that enables slice selection for component.
 * Wraps component with dnd target and source fns and asserts that needed
 * props are present in the donor component.
 */
export default <P extends SlicerProps>(component: React.ComponentType<P>) =>
  compose<P, P & SliceSelectorProps>(
    DropTarget(SLICE, target, (connect, monitor) => ({
      isOver: monitor.isOver(),
      connectDropTarget: connect.dropTarget(),
    })),
    DragSource(SLICE, source, (connect, monitor) => ({
      isSelecting: monitor.isDragging(),
      connectDragSource: connect.dragSource(),
    })),
    withHandlers({
      onClick: ({ date, onSelectionClick, ownDuration, isSelected }) => () =>
        onSelectionClick(
          new DateRange(date, addDuration(moment.duration(ownDuration), date)),
          isSelected,
        ),
    }),
  )(component);
