import moment from 'moment';
import { DateRange } from 'moment-range';
import { createSelector } from 'reselect';
import { head, last } from 'ramda';
import {
  sliceBordersFromIntervals,
  countByDuration,
  toISOInterval,
} from '../utils/time';
import { AppState } from '../models';

export const getTimeWindow = (state: AppState) => state.project.timeWindow;

export const getCurrentSliceDuration = (state: AppState): moment.Duration =>
  state.project.sliceDuration;

export const getFilteredTimeIntervals = (state: AppState): DateRange[] =>
  state.project.filteredTimeIntervals;

export const getSelectedSlices = createSelector(
  getFilteredTimeIntervals,
  getCurrentSliceDuration,
  (intervals, duration) =>
    sliceBordersFromIntervals((m: moment.Moment) => m.clone().add(duration))(
      intervals,
    ),
);

export const getObjectRadiusExtent = (state: AppState) => ({
  min: state.project.objectRadiusMin,
  max: state.project.objectRadiusMax,
});
export const getLayersOrder = (state: AppState) => state.project.layersOrder;
export const getIsFetching = (state: AppState) => state.project.isFetching;

export const getAvailableLayers = createSelector(
  getLayersOrder,
  (state: AppState) => state.layers.entities,
  (layersOrder, layerEntities) =>
    layersOrder.reduce((acc, id) => {
      if (layerEntities[id]) acc.push(id);
      return acc;
    }, []),
);

export const getNormalizedSliceWidth = (state: AppState) => {
  const selectedTimeIntervals = getFilteredTimeIntervals(state);
  const sliceDuration = getCurrentSliceDuration(state);
  return 1 / countByDuration(selectedTimeIntervals, sliceDuration);
};

export const isApproximated = (state: AppState) =>
  state.project.type === 'approximated';
export const getCurrentProjectType = (state: AppState) => state.project.type;
export const getVerticalScaling = (state: AppState) =>
  state.project.verticalScaling;
export const getCurrentEnvironmentId = (state: AppState) =>
  state.project.environmentId;
export const getTimezone = (state: AppState) => state.project.timezone;
export const getCameraMode = (state: AppState) => state.project.cameraMode;
export const getCurrentProjectId = (state: AppState) => state.project.id;
export const getSelectedObjectIds = (state: AppState) =>
  state.project.selectedObjectIds;
export const getProjectLabel = (state: AppState) => state.project.label;
export const getCurrentApproximationType = (state: AppState) =>
  state.project.approximationType;
export const getCurrentStartTime = (state: AppState) => state.project.startTime;
export const getTimeFilters = (state: AppState) => state.project.timeFilters;
export const getSelectedIntervals = (state: AppState) =>
  state.project.selectedTimeIntervals;
export const getShowHiddenObject = (state: AppState) =>
  state.project.showHiddenObjects;
export const getApproximationType = (state: AppState) =>
  state.project.approximationType;
export const getProjectOwner = (state: AppState) => state.project.owner;
export const getProjectEdirors = (state: AppState) => state.project.editors;
export const getProjectQuality = (state: AppState) =>
  state.project.streamerLowresQuality;
export const getFixedProjectQuality = (state: AppState) =>
  parseFloat(state.project.streamerLowresQuality.toFixed(1));
export const getIsRaw = (state: AppState) => state.project.legoModeEnabled;
export const getHighlightObjects = (state: AppState) =>
  state.project.objectsHighlightEnabled;

export const getTotalInterval = createSelector(
  getSelectedIntervals,
  intervals =>
    toISOInterval(new DateRange(head(intervals).start, last(intervals).end)),
);
