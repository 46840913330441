import React from 'react';
import { Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import { Link } from 'react-router-dom';
import { Text } from '@hm/ukie';

export interface Props {
  name: string;
}

const WelcomeUnsubscribed: React.SFC<Props> = ({ name }) => (
  <React.Fragment>
    <F
      id="Welcome.signedIn"
      defaultMessage="{name}, you’ve been successfully signed in!"
      values={{ name: <Text.Important>{name}</Text.Important> }}
      tagName="div"
    />

    <Box mt={3} mb={1}>
      <Text.Header>
        <F id="Welcome.notSubscribed" defaultMessage="You are not subscribed" />
      </Text.Header>
    </Box>
    <F
      id="Welcome.unfortunately"
      defaultMessage="Unfortunately, you can’t start working with Chronotope because you’re not subscribed."
      tagName="div"
    />
    <F
      id="Welcome.pleaseSubscribe"
      defaultMessage="Please, click on the button below and explore data in all its glory!"
      tagName="div"
    />

    <Box mt={4}>
      <Link to="/subscribe">
        <F id="Welcome.subscribe" defaultMessage="Subscribe" />
      </Link>
    </Box>
    <Box mt={2}>
      <Link to="/profile">
        <F id="Welcome.openAccount" defaultMessage="Open your account" />
      </Link>
    </Box>
  </React.Fragment>
);

export default WelcomeUnsubscribed;
