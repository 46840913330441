import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import { Icon, Text } from '@hm/ukie';

interface Props {
  topLat: number;
  rightLon: number;
  bottomLat: number;
  leftLon: number;
}

const BoundingBoxCoordinates: React.SFC<Props> = ({
  topLat,
  leftLon,
  bottomLat,
  rightLon,
}) => (
  <Flex align="center">
    <Icon name="geoBounds" />
    <Box ml={1}>
      <div>
        <F id="MetricConfig.latLon" defaultMessage="Lat, Lon">
          {text => (
            <Box is="span" mr={2}>
              {text}
            </Box>
          )}
        </F>
        <Text.Important>
          {topLat}
          {', '}
          {rightLon}
        </Text.Important>
      </div>
      <div>
        <F id="MetricConfig.latLon" defaultMessage="Lat, Lon">
          {text => (
            <Box is="span" mr={2}>
              {text}
            </Box>
          )}
        </F>
        <Text.Important>
          {bottomLat}
          {', '}
          {leftLon}
        </Text.Important>
      </div>
    </Box>
  </Flex>
);

export default BoundingBoxCoordinates;
