import { Scrollable } from '@hm/ukie';
import { Box } from 'grid-styled';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../models';
import { selectors } from '../../reducers/highlights';
import HighlightWidget from '../HighlightWidget';

interface Props {
  ids: string[];
}

const Highlights: React.SFC<Props> = ({ ids }) => (
  <Box flex={1} bg="grey2">
    <Scrollable style={{ height: '100%' }}>
      <Box p={2}>{ids.map(id => <HighlightWidget key={id} id={id} />)}</Box>
    </Scrollable>
  </Box>
);

Highlights.defaultProps = {
  ids: [],
};

export default connect((state: AppState) => ({
  ids: selectors.getIds(state),
}))(Highlights);
