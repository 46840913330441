import React from 'react';
import styled from 'styled-components';
import { typography } from '@hm/ukie';
import { calendarCell } from '../../styles/common';
import withSliceSelection, {
  SlicerProps,
  SliceSelectorProps,
} from '../withSliceSelection';

interface Props
  extends SlicerProps,
    SliceSelectorProps,
    React.HTMLProps<HTMLDivElement> {
  isDisabled: boolean;
  first: boolean;
  last: boolean;
}

const Day: React.SFC<Props> = ({
  date,
  className,
  connectDragSource,
  connectDropTarget,
  onClick,
}) =>
  connectDropTarget(
    connectDragSource(
      <div className={className} onClick={onClick}>
        {date.date()}
      </div>,
    ),
  );

export default styled(withSliceSelection(Day))`
  font-size: ${typography.smallFont};
  width: 24px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  ${calendarCell};
`;
