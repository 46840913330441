import React from 'react';
import { Flex, Box } from 'grid-styled';
import { Link, Route, Switch, RouteProps } from 'react-router-dom';
import { Input, Text, Userpic } from '@hm/ukie';
import { FormattedMessage as F } from 'react-intl';
import { isEmpty } from 'ramda';

import { CurrentUser as ICurrentUser, User } from '../../models';

import Help from './Help';
import PlainLink from '../PlainLink';
import DevMenu from '../DevMenu';
import Logo from '../Logo';
import CurrentUser from '../CurrentUser';
import WorkspaceSelector from '../WorkspaceSelector';
import FeatureToggle from '../FeatureToggle';

const Body = Flex.extend.attrs({ justify: 'space-between' })`
  flex-shrink: 0;
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

const TitleBox = Box.extend.attrs({ m: 2 })`
  line-height: 2;
  height: 24px;
  min-width: 248px;
  max-width: 304px;
`;

const UserBox = Box.extend`
  & > ${Userpic} {
    vertical-align: top;
  }
`;

export interface Props {
  projectTitle?: string;
  title: string;
  id?: string;
  owner?: User;
  editors?: User[];
  isLoggedIn?: boolean;
  currentUser?: ICurrentUser;
  onRename?(id: string, title: string): void;
}

const Header: React.SFC<Props & RouteProps> = ({
  projectTitle,
  title,
  id,
  onRename,
  currentUser,
  isLoggedIn,
  owner,
  editors,
  location,
}) => (
  <Body>
    <Flex mx={2} my="auto" width={168}>
      <PlainLink to="/">
        <Logo height={33} width={140} />
      </PlainLink>
      <FeatureToggle feature="devMenu">
        <Box ml={1} my="auto">
          <DevMenu />
        </Box>
      </FeatureToggle>
    </Flex>

    <Switch>
      <Route
        path="/projects/:id"
        render={() => (
          <Flex flex="1" justify="center" align="baseline">
            <TitleBox flex="2 1 304px">
              <Input
                titleFont
                value={projectTitle}
                onChange={e => onRename(id, e.currentTarget.value)}
                truncate
              />
            </TitleBox>

            <TitleBox flex="1 1 248px">
              {owner ? (
                <Flex>
                  <F id="Header.owner" defaultMessage="Owner">
                    {text => <Text.Small>{text}</Text.Small>}
                  </F>

                  <UserBox mx={1}>
                    <Userpic
                      name={owner.name}
                      image={owner.avatarUrl}
                      color={`#${owner.defaultColor}`}
                    />
                  </UserBox>

                  {!isEmpty(editors) ? (
                    <Box ml={1}>
                      <F id="Header.editors" defaultMessage="Editors">
                        {text => <Text.Small>{text}</Text.Small>}
                      </F>
                    </Box>
                  ) : null}

                  {editors.map(editor => (
                    <UserBox ml={1} key={editor.id}>
                      <Userpic
                        name={editor.name}
                        image={editor.avatarUrl}
                        color={`#${editor.defaultColor}`}
                      />
                    </UserBox>
                  ))}
                </Flex>
              ) : null}
            </TitleBox>
          </Flex>
        )}
      />

      <Route
        path="*"
        render={() => (
          <Flex flex="1" justify="center" align="center">
            <Text.Title>{title}</Text.Title>
          </Flex>
        )}
      />
    </Switch>

    <Flex width={168} m={2} justify="flex-end" align="center">
      {isLoggedIn ? (
        <FeatureToggle feature="workspaces">
          <Box mr={2} width={200}>
            <WorkspaceSelector />
          </Box>
        </FeatureToggle>
      ) : null}
      <Box mr={1}>
        {isLoggedIn ? (
          <F id="logout" defaultMessage="Logout">
            {text => <Link to="/logout">{text}</Link>}
          </F>
        ) : location.pathname !== '/login' ? (
          <F id="login" defaultMessage="Log in">
            {text => <Link to="/login">{text}</Link>}
          </F>
        ) : (
          <F id="signup" defaultMessage="Sign up">
            {text => <Link to="/signup">{text}</Link>}
          </F>
        )}
      </Box>
      <Box ml={2}>
        <Help user={currentUser} />
      </Box>
      {isLoggedIn ? (
        <Box ml={2}>
          <PlainLink to="/profile">
            <CurrentUser />
          </PlainLink>
        </Box>
      ) : null}
    </Flex>
  </Body>
);

Header.defaultProps = {
  editors: [],
};

export default Header;
