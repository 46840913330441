import styled from 'styled-components';

interface Props {
  truncate: boolean;
}

export default styled.span`
  margin-left: 4px;
  word-wrap: break-word;
  overflow: hidden;
  ${(props: Props) =>
    props.truncate
      ? `text-overflow: ellipsis;
       white-space: nowrap;`
      : 'text-overflow: clip;'};
`;
