import React from 'react';
import { Box } from 'grid-styled';
import { Text } from '@hm/ukie';
import TimeInterval from '../TimeInterval';
import { BaseLayer } from '../../reducers/baseLayers';

interface Props {
  layer: BaseLayer;
}

export const AvailableTimeIntervals: React.SFC<Props> = ({
  layer: { id, label, availableTimeIntervals, timezone },
}) => (
  <Box key={id} mb={2}>
    <Text>{label}</Text>
    <Text fontWeight="semibold" is="div">
      {availableTimeIntervals.map((interval, i) => (
        <TimeInterval
          key={i}
          interval={[interval.start.toDate(), interval.end.toDate()]}
          timezone={timezone}
        />
      ))}
    </Text>
  </Box>
);

export default AvailableTimeIntervals;
