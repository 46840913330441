import { Duration, Moment } from 'moment';
import { DateRange } from 'moment-range';
import { dissoc } from 'ramda';

import { types } from '../actions/environments';
import { types as projectTypes } from '../actions/projects';
import { fulfill, pend } from '../utils/promise';
import { User, GeoBounds, DaysOfSignificance } from '../models';

export interface AvailableTimeFilter {
  type: string;
  group: string;
  availableSettings: string[];
}

interface Resolution {
  x: number;
  y: number;
}

export interface Environment {
  id: string;
  name: string;
  description: string;
  timezone?: string;
  maxZoom: number;
  readOnly: boolean;
  tileUrlFormat: string;
  resolution: Resolution;
  geoBounds: GeoBounds;
  layerIds: string[];
  daysOfSignificance: DaysOfSignificance[];
  availableSliceDurations: Duration[];
  availableTimeIntervals: DateRange[];
  approximatedOnly: boolean;
  availableTimeFilters: AvailableTimeFilter[];
  owner: User;
  editors: User[];
  area: number;
  country: string;
  cellSize: number;
  currentAccessRole: string;
  isAdmin?: boolean;
  layerCount: number;
  createdAt: Moment;
  notes?: string;
  tags?: string[];
}

export interface EnvironmentsState {
  selectedId: string;
  entities: { [index: string]: Environment };
}

export const initialState: EnvironmentsState = {
  selectedId: null,
  entities: {},
};

// TODO: rewrite with entityReducer
export default (
  state: EnvironmentsState = initialState,
  action: any,
): EnvironmentsState => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.LOAD_ENVIRONMENTS_LIST):
      return {
        ...state,
        entities: payload.entities.environments || state.entities,
      };

    case types.SELECT_ENVIRONMENT:
      return { ...state, selectedId: payload };

    case fulfill(types.GET_ENVIRONMENT):
    case fulfill(types.DELETE_EDITOR):
    case fulfill(types.ADD_EDITORS):
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities.environments,
        },
      };

    // TODO: add fulfill case
    case pend(types.UPDATE):
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            ...payload.updates,
          },
        },
      };

    // TODO: should handle reject
    case pend(types.DELETE_ENVIRONMENT):
      return {
        ...state,
        entities: dissoc(payload.id, state.entities),
      };

    case fulfill(projectTypes.GET_PROJECT):
      return state.selectedId
        ? state
        : {
            ...state,
            selectedId: payload.entities.projects[payload.result].environmentId,
          };

    default:
      return state;
  }
};
