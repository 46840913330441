import React from 'react';
import { Elements } from 'react-stripe-elements';

export default props => (
  <Elements
    {...props}
    fonts={[
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans',
      },
    ]}
  />
);
