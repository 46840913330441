import { createSelector } from 'reselect';
import { getCameraMode, getNormalizedSliceWidth } from '../selectors/project';
import { AppState } from '../models';

export const getTimeWindow = (state: AppState) => state.timeline.timeWindow;
export const getBehaviour = (state: AppState) => state.timeline.behaviour;
export const getIsPlaying = (state: AppState) => state.timeline.isPlaying;
export const getSpeed = (state: AppState) => state.timeline.speed;

export const computeSpeedMs = createSelector(getSpeed, speed => speed / 1000);

export const computeMode = createSelector(
  getCameraMode,
  (state: AppState) => state.timeline.mode,
  (cameraMode, currentTimelineMode) =>
    cameraMode === '2D' ? 'single' : currentTimelineMode,
);

export const computeVisibleTimeWindow = createSelector(
  getTimeWindow,
  computeMode,
  getNormalizedSliceWidth,
  ([start, end], mode, normSliceWidth) => {
    return mode === 'single' ? [end - normSliceWidth, end] : [start, end];
  },
);

export const computeCanPlay = createSelector(
  getTimeWindow,
  getNormalizedSliceWidth,
  getBehaviour,
  computeMode,
  ([start, end], sliceWidth, behaviour, mode) => {
    const isSmall = end - start <= sliceWidth * 2;
    const isLarge = end - start >= 1 - sliceWidth * 2;

    if (mode === 'range' && behaviour === 'steps' && isLarge) return false;
    if (mode === 'range' && behaviour === 'growth' && isSmall) return false;
    return true;
  },
);
