import {
  call,
  takeEvery,
  fork,
  select,
  put,
  take,
  cancel,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { selectRange, selectSingle, types } from '../actions/timeline';
import {
  getTimeWindow,
  computeMode,
  getBehaviour,
  computeSpeedMs,
} from '../selectors/timeline';
import {
  getNormalizedSliceWidth,
  getTimeWindow as getPersistedTimeWindow,
} from '../selectors/project';
import stopPlayer from './stopPlayer';

export default function*() {
  while (true) {
    yield take(types.START_PLAYBACK);
    const rafChannel = yield call(getRafChannel);
    const playbackTask = yield takeEvery(rafChannel, tick);
    const playbackInterruptTasks = yield fork(stopPlayer);

    yield take(types.STOP_PLAYBACK);
    yield cancel(playbackTask);
    yield cancel(playbackInterruptTasks);
  }
}

function* tick(delta: number) {
  const timeWindow = yield select(getTimeWindow);
  const persistedTimeWindow = yield select(getPersistedTimeWindow);
  const normSliceWidth = yield select(getNormalizedSliceWidth);
  const mode = yield select(computeMode);
  const behaviour = yield select(getBehaviour);
  const speed = yield select(computeSpeedMs);

  const progress = delta * speed * normSliceWidth;

  if (mode === 'single') {
    yield put(selectSingle(single(timeWindow[1], progress)));
  } else if (behaviour === 'steps') {
    yield put(selectRange(stepFrame(timeWindow, progress)));
  } else {
    yield put(
      selectRange(
        growthFrame(timeWindow, progress, persistedTimeWindow, normSliceWidth),
      ),
    );
  }
}

export const getRafChannel = () =>
  eventChannel(emit => {
    let previousTimestamp = null;
    let raf = null;

    const step = (timestamp: number) => {
      if (typeof previousTimestamp === 'number') {
        emit(timestamp - previousTimestamp);
      }

      previousTimestamp = timestamp;
      raf = requestAnimationFrame(step);
    };

    raf = requestAnimationFrame(step);
    return () => cancelAnimationFrame(raf);
  });

const single = (end: number, progress: number) => {
  const newEnd = end + progress;
  return newEnd <= 1 ? newEnd : 0;
};

const stepFrame = (
  timeWindow: [number, number],
  delta: number,
): [number, number] => {
  const [start, end] = timeWindow;
  const extent = end - start;
  const newEnd = end + delta;

  return newEnd <= 1 ? [newEnd - extent, newEnd] : [0, extent];
};

const growthFrame = (
  initialTimeWindow: [number, number],
  delta: number,
  growthWindow: [number, number],
  normSliceWidth: number,
): [number, number] => {
  const [start, end] = initialTimeWindow;
  const [, max] = growthWindow;
  const newEnd = end + delta;

  return newEnd <= max ? [start, newEnd] : [start, start + normSliceWidth];
};
