import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { LocalForm } from 'react-redux-form';
import { Submit, Spinner, Text } from '@hm/ukie';
import { isStripeComplete } from '../../utils/validators';

import PaymentFieldset from '../PaymentFieldset';
import { FormActions } from '../Form';
import { ApiStripeError, StripeError, ApiError } from '../../models';

const isApiStripeError = (
  error: ApiError | ApiStripeError | StripeError,
): error is ApiStripeError => !!(error as ApiStripeError).stripeError;

const isStripeError = (error: ApiError | StripeError): error is StripeError =>
  !!(error as StripeError).message;

interface Props extends Partial<ReactStripeElements.InjectedStripeProps> {
  onSuccess(token: string): Promise<any>;
  onClose(): void;
}

interface State {
  isValid: boolean;
  isSubmitting: boolean;
  error: ApiError | ApiStripeError | StripeError;
}

class ChangePaymentForm extends React.Component<Props, State> {
  state: State = { isValid: false, isSubmitting: false, error: null };

  onUpdate = (isValid: boolean) => this.setState({ isValid });

  onSubmit = (form: any) =>
    this.setState({ isSubmitting: true }, () =>
      this.props.stripe
        .createToken()
        .then(({ token }) =>
          this.props
            .onSuccess(token.id)
            .then(() =>
              this.setState({ isSubmitting: false }, () =>
                this.props.onClose(),
              ),
            )
            .catch(({ error }: { error: ApiStripeError }) =>
              this.setState({ isSubmitting: false, error }),
            ),
        )
        .catch(({ error }: { error: StripeError }) => this.setState({ error })),
    );

  render() {
    const { isSubmitting, error } = this.state;

    return (
      <LocalForm
        onSubmit={this.onSubmit}
        onUpdate={({ $form }) => this.onUpdate($form.valid)}
        validators={{
          cardNumber: isStripeComplete,
          cardExpiry: isStripeComplete,
          cardCvc: isStripeComplete,
        }}
      >
        <PaymentFieldset disabled={isSubmitting} />
        {error ? (
          <React.Fragment>
            <Text color="error" id="div" my={2}>
              {isApiStripeError(error)
                ? error.stripeError.message
                : isStripeError(error) ? error.message : error.detail}
            </Text>
          </React.Fragment>
        ) : null}
        <FormActions>
          {isSubmitting ? (
            <Spinner size="small" />
          ) : (
            <Submit disabled={!this.state.isValid}>
              <F id="Payments.paymentSourceSubmit" defaultMessage="Save" />
            </Submit>
          )}
        </FormActions>
      </LocalForm>
    );
  }
}

export default injectStripe(ChangePaymentForm);
