import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage as F } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Box } from 'grid-styled';
import { parse } from 'query-string';
import { Spinner } from '@hm/ukie';
import { confirmEmail } from '../../actions/user';

interface TokenParams {
  token: string;
}

interface ProvidedProps {
  confirmEmail(token: string): Promise<any>;
}

type OwnProps = RouteComponentProps<TokenParams>;
type Props = ProvidedProps & OwnProps;

interface State {
  isFetching: boolean;
  didSubmit: boolean;
  isValid: boolean;
}

class EmailConfirmation extends React.Component<Props, State> {
  state = { isFetching: false, isValid: false, didSubmit: false };

  componentDidMount() {
    const { token } = parse(this.props.location.search.replace('?', ''));

    this.props
      .confirmEmail(token)
      .then(() =>
        this.setState({ isFetching: false, isValid: true, didSubmit: true }),
      )
      .catch(() =>
        this.setState({ isFetching: false, isValid: false, didSubmit: true }),
      );
  }

  render() {
    if (this.state.isFetching) return <Spinner />;
    if (!this.state.didSubmit) return null;

    return (
      <Box my={3}>
        {this.state.isValid ? (
          <F
            id="Profile.emailConfirmed"
            defaultMessage="Your email has been successfully updated."
          />
        ) : (
          <F
            id="Profile.invalidEmailToken"
            defaultMessage="This link is invalid"
          />
        )}
      </Box>
    );
  }
}

export default withRouter(
  connect<{}, ProvidedProps, OwnProps>(null, { confirmEmail })(
    EmailConfirmation,
  ),
);
