import { schema } from 'normalizr';
import { pick } from 'ramda';
import {
  createEntityReducer,
  createActionTypes,
  createEntitySelectors,
  State,
} from './createEntityReducer';
import { get, post } from '../api/rest';

export interface Workspace {
  id: string;
  label: string;
  avatarUrl: string;
  description: string;
}

const name = 'workspaces';

export const workspaceSchema = new schema.Entity(name);

export const types = {
  ...pick(['LIST'], createActionTypes(name)),
  SELECT: `${name}/SELECT`,
};

export const fetchWorkspaces = () => ({
  type: types.LIST,
  payload: get('/workspaces'),
  meta: { schema: [workspaceSchema] },
});

export const selectWorkspace = (id: string) => ({
  type: types.SELECT,
  payload: post('/workspaces', { id }),
});

export const selectors = createEntitySelectors(name);

export type WorkspacesState = State<Workspace>;

export default createEntityReducer(name);
