import React from 'react';
import { isEmpty } from 'ramda';
import { DateRange } from 'moment-range';
import { Flex, Box } from 'grid-styled';
import { FormattedMessage } from 'react-intl';

import { Dialog, Tabs, TabItemSmall, Button, Footer } from '@hm/ukie';

import { SubTitle } from '../Title';
import EditHeader from '../../containers/Calendar/EditHeader';
import Settings from '../../containers/Calendar/Settings';
import ShiftStatus from '../../containers/Calendar/ShiftStatus';
import Wave from '../Wave';
import Status from '../../containers/Calendar/Status';
import messages from '../NewApproximatedProject/messages';
import { TooManySlicesError } from '../Calendar';
import FeatureToggle from '../FeatureToggle';

interface OwnProps {
  onClose(): void;
}

interface StateProps {
  isValid: boolean;
  tooManySlices: boolean;
  selectedIntervals: DateRange[];
  availableIntervals: DateRange[];
  sliceDuration: string;
  startTime: string;
}

interface DispatchProps {
  onClose(): void;
  onSubmit(): void;
  onSelectionClick(interval: DateRange, isInverted?: boolean): void;
  onSelection(interval: DateRange, isInverted?: boolean): void;
  onSelectionEnd(): void;
  onStartTimeChange(startTime: string): void;
}

type Props = OwnProps & StateProps & DispatchProps;

const EditApproximatedProject: React.SFC<Props> = ({
  onClose,
  tooManySlices,
  availableIntervals,
  selectedIntervals,
  sliceDuration,
  onSelection,
  onSelectionClick,
  onSelectionEnd,
  onStartTimeChange,
  startTime,
  isValid,
  onSubmit,
}) => (
  <Dialog onClose={onClose} title={<EditHeader />}>
    <Box pt={2}>
      <Tabs centered selected="day">
        <TabItemSmall value="day">Day</TabItemSmall>
        <TabItemSmall value="week" disabled>
          Week
        </TabItemSmall>
        <TabItemSmall value="month" disabled>
          Month
        </TabItemSmall>
        <TabItemSmall value="year" disabled>
          Year
        </TabItemSmall>
      </Tabs>
    </Box>
    <Flex flex="1">
      <Flex flexDirection="column" align="center" flex="1" p="40px 0">
        <SubTitle>
          <FormattedMessage {...messages.dayTitle} />
        </SubTitle>
        {isEmpty(availableIntervals) ? null : (
          <Box m="auto 0">
            <FeatureToggle feature="startTime">
              {isEnabled =>
                isEnabled ? (
                  <Wave
                    date={availableIntervals[0].start}
                    selectedIntervals={selectedIntervals}
                    availableIntervals={availableIntervals}
                    onSelection={onSelection}
                    onSelectionClick={onSelectionClick}
                    onSelectionEnd={onSelectionEnd}
                    sliceDuration={sliceDuration}
                    withStartTime
                    startTime={startTime}
                    onStartTimeChange={onStartTimeChange}
                  />
                ) : (
                  <Wave
                    date={availableIntervals[0].start}
                    selectedIntervals={selectedIntervals}
                    availableIntervals={availableIntervals}
                    onSelection={onSelection}
                    onSelectionClick={onSelectionClick}
                    onSelectionEnd={onSelectionEnd}
                    sliceDuration={sliceDuration}
                  />
                )
              }
            </FeatureToggle>
          </Box>
        )}
      </Flex>

      <Settings info={messages.selectAllApproximated}>
        <FeatureToggle feature="startTime">
          <ShiftStatus />
        </FeatureToggle>
      </Settings>
    </Flex>
    <Footer
      info={<Status />}
      warning={tooManySlices ? <TooManySlicesError /> : null}
      actions={
        <Button primary onClick={onSubmit} disabled={!isValid || tooManySlices}>
          <FormattedMessage {...messages.edit} />
        </Button>
      }
    />
  </Dialog>
);

export default EditApproximatedProject;
