import { select } from 'redux-saga/effects';
import { volumer } from '@hm/volumer-api';
import initObject from './initObject';
import { getDefaultObjectParamsForTool } from '../../selectors/defaultObjectParams';
import { ObjectType, MarkerSubtype } from '../../constants/objects';
import { Tool } from '../../constants/tools';
import { Pointer } from '../../models';
import { RaycastSender } from './getRaycastSender';

const { MAP, VOLUME } = volumer.raycast.Type;

export default function*(pointer: Pointer, sendRaycast: RaycastSender) {
  const { subtype } = yield select(getDefaultObjectParamsForTool(Tool.MARKER));
  const priorities =
    subtype === MarkerSubtype.SPATIAL ? [MAP, VOLUME] : [VOLUME, MAP];

  const { mapHit, volumeHit } = yield* sendRaycast(pointer, priorities);
  const hit = mapHit || volumeHit;
  if (!hit) return null;

  yield* initObject(ObjectType.MARKER, hit, { time: hit.position.time });
}
