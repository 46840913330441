import React from 'react';
import { isEmpty, isNil } from 'ramda';
import { FormattedMessage as F } from 'react-intl';
import { Flex, Box, CommonProps, FlexProps } from 'grid-styled';
import { Text } from '@hm/ukie';
import Truncate from '../Truncate';

interface Props extends Partial<CommonProps & FlexProps> {
  nameComponent: string | JSX.Element;
}

export const Value: React.SFC = ({ children }) => (
  <Text.Important>
    {isEmpty(children) || isNil(children) ? (
      <F id="no" defaultMessage="no" />
    ) : (
      children
    )}
  </Text.Important>
);

const Row: React.SFC<Props> = ({ nameComponent, children, ...rest }) => (
  <Flex {...rest as any}>
    <Truncate flex="0 1 168px" mr={1}>
      {nameComponent}
    </Truncate>
    <Box flex="1 1 168px" ml={1}>
      {children}
    </Box>
  </Flex>
);

export default Row;
