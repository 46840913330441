import styled from 'styled-components';
import { radius } from '@hm/ukie';
import Confirmation from './Confirmation';

const ConfirmationDialog = styled(Confirmation)`
  border-radius: ${radius.big};
  width: 268px;
  height: 67px;
  box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.35);
  z-index: 10000;
`;

export default ConfirmationDialog;
