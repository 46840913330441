import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { path } from 'ramda';
import thunkMiddleware from 'redux-thunk';
import createThrottle from 'redux-throttle';
import promiseMiddleware from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import createRavenMiddleware from 'raven-for-redux';
import { routerMiddleware } from 'react-router-redux';

import history from './history';
import Raven from '../config/raven';
import authMiddleware from './middlewares/auth';
import normalizrMiddleware from './middlewares/normalizr';
import apiMiddleware from './middlewares/api';
import { getUserId } from './utils/auth';

import rootSaga from './sagas';
import { types as volumerTypes } from './actions/volumer';
import { types as abrTypes } from './reducers/abr';
import rootReducer from './reducers';
import { fetchFeatures } from './reducers/features';
import { supportedFeatures } from './components/FeatureToggle';
import { getIsLoggedIn } from './selectors/user';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  createThrottle(),
  thunkMiddleware,
  apiMiddleware(`${process.env.APP_DOMAIN || ''}/api/v1`),
  promiseMiddleware(),
  normalizrMiddleware(),
  sagaMiddleware,
  authMiddleware,
  routerMiddleware(history),
];

if (process.env.NODE_ENV === 'production') {
  middlewares.push(
    createRavenMiddleware(Raven, {
      getUserContext: state => ({
        id: getUserId(),
        email: path(['user', 'info', 'email'], state),
        username: path(['user', 'info', 'name'], state),
      }),
    }),
  );
}

const composeEnhancers = composeWithDevTools({
  actionsBlacklist: [
    volumerTypes.SET_FRAME,
    volumerTypes.POINTER_MOVE,
    volumerTypes.RESIZE,
    abrTypes.UPDATE_LAST_FRAME_TS,
  ],
  maxAge: process.env.NODE_ENV === 'production' ? 50 : 500,
});

export default (preloadedState?) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  if (getIsLoggedIn(store.getState())) {
    store.dispatch(fetchFeatures(supportedFeatures));
  }

  sagaMiddleware.run(rootSaga);

  return store;
};
