import { put, takeEvery, fork, select } from 'redux-saga/effects';

import { types as modalsTypes } from '../actions/modals';
import { types as projectTypes } from '../actions/projects';
import { types as timelineTypes, stopPlayback } from '../actions/timeline';
import { types as userTypes } from '../actions/user';
import { types as volumerTypes } from '../actions/volumer';

import { getIsPlaying } from '../selectors/timeline';
import { getCameraMode } from '../selectors/project';
import { pend } from '../utils/promise';

export default function*() {
  yield takeEvery(
    [
      modalsTypes.SHOW_MODAL,
      pend(projectTypes.DELETE_CURRENT_PROJECT),
      timelineTypes.CHANGE_PLAYBACK_MODE,
      timelineTypes.CHANGE_PLAYBACK_BEHAVIOUR,
      userTypes.DISCONNECT_INACTIVE,
      volumerTypes.DISCONNECT,
    ],
    stopIfPlaying,
  );

  yield takeEvery(
    pend(projectTypes.OPTIMISTIC_UPDATE_PROJECT),
    checkIfCameraModeChanged,
  );
}

function* checkIfCameraModeChanged(action) {
  const { payload: { cameraMode } } = action;
  const currentMode = yield select(getCameraMode);
  if (cameraMode && cameraMode !== currentMode) {
    yield fork(stopIfPlaying);
  }
}

function* stopIfPlaying() {
  if (yield select(getIsPlaying)) yield put(stopPlayback());
}
