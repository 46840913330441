import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import {
  Dropzone,
  Button,
  Submit,
  FillFlex,
  Text,
  Progress,
  Footer,
  OList,
  UList,
} from '@hm/ukie';
import { Link, RouteComponentProps } from 'react-router-dom';
import _Dropzone from 'react-dropzone';
import { getAccessToken } from '../../utils/auth';
import IntlDocumentTitle from '../IntlDocumentTitle';
import PlainLink from '../PlainLink';
import GlobalAlert from '../GlobalAlert';
import ValueWithUnits from '../ValueWithUnits';
import { bytesToGigabytes } from '../../utils/helpers';
import { GlobalAlerts } from '../../reducers/alertStack';

// TODO: receive via API
const maxFileSize = 200; // 200Mb

interface State {
  file: File;
  progress: number;
  isUploading: boolean;
  error: boolean;
}

export interface Props extends RouteComponentProps<any> {
  hasErrors: boolean;
  usedStorage: number;
  maxStorage: number;
  hasStorageLimit: boolean;
}

class MetricUpload extends React.Component<Props, State> {
  xhr: XMLHttpRequest;

  state = {
    file: undefined,
    progress: 0,
    isUploading: false,
    error: false,
  };

  onDone = (xhr: XMLHttpRequest, e: Event) => {
    this.setState({ isUploading: false, file: undefined, progress: 0 }, () => {
      const { layerId } = JSON.parse(xhr.responseText);
      this.props.history.push(`/import/${layerId}`);
    });
  };

  onDrop: _Dropzone.DropFilesEventHandler = (accepted, rejected) => {
    const [file] = accepted;
    if (!file) return;

    this.setState({
      file,
      error: false,
      isUploading: false,
      progress: 0,
    });
  };

  onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    const { file } = this.state;

    if (!file) return;

    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', file.name.split('.').pop());

    this.xhr = new XMLHttpRequest();

    this.xhr.open('POST', '/api/v1/import/uploads');
    this.xhr.setRequestHeader('Authorization', `Bearer ${getAccessToken()}`);
    this.xhr.addEventListener('loadstart', () =>
      this.setState({ isUploading: true }),
    );

    this.xhr.addEventListener('load', evt => {
      if (this.xhr.status < 200 || this.xhr.status >= 300) {
        this.onError(evt);
      } else {
        this.onDone(this.xhr, evt);
      }
    });

    this.xhr.addEventListener('error', this.onError);

    this.xhr.upload.addEventListener('progress', p => {
      if (p.total === 0) return;

      this.setState({ progress: p.loaded / p.total * 100 });
    });

    this.xhr.send(formData);
  };

  isFileOverflow = file => {
    const { usedStorage, maxStorage, hasStorageLimit } = this.props;
    return hasStorageLimit && file
      ? usedStorage + file.size > maxStorage
      : false;
  };

  onError = (e: Event) => {
    this.setState({ isUploading: false, progress: 0, error: true });
  };

  onAbort = () => {
    if (this.xhr) this.xhr.abort();

    this.setState({
      file: undefined,
      error: false,
      isUploading: false,
      progress: 0,
    });
  };

  renderForm() {
    const { usedStorage, maxStorage, hasStorageLimit } = this.props;
    const { file, error } = this.state;
    const fileTooLarge = file ? file.size > maxFileSize * 1024 * 1024 : false;

    return (
      <form onSubmit={this.onSubmit}>
        <Flex flexDirection="column" justify="space-between">
          <Dropzone
            onDrop={this.onDrop}
            // XXX: DO NOT REMOVE , at the end of accept. It's for Windows users
            // Inside Dropzone this string will be converted into array
            // comma at the end adds '' element to it like ['text/csv', '']
            // And '' is the file type csv has on Windows
            // So it enabled drag and drop of csv for Windows
            accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,"
            file={file}
            disabled={hasStorageLimit && usedStorage >= maxStorage}
            error={error || fileTooLarge}
            rejectMessage={
              <F
                id="MetricUpload.rejected"
                defaultMessage="File format is wrong. Use csv-dataset to create new Metric from"
              />
            }
            errorMessage={
              fileTooLarge ? (
                <F
                  id="MetricUpload.tooLarge"
                  defaultMessage="File size is too large. Use dataset with max size {maxFileSize} MB to create new Metric from. "
                  values={{ maxFileSize }}
                />
              ) : (
                <F
                  id="MetricUpload.failed"
                  defaultMessage="The dataset uploading has failed. Try it again or use another one"
                />
              )
            }
            placeholder={
              <F
                id="MetricUpload.placeholder"
                defaultMessage="To create a new Metric from your dataset, click here to choose a file or drag and drop it. Dataset should be in .csv format, max size {maxFileSize} MB."
                values={{ maxFileSize }}
              />
            }
          />
          {file ? (
            <Box mt={2} ml="auto">
              <Submit type="submit" disabled={this.isFileOverflow(file)}>
                <F id="MetricUpload.submit" defaultMessage="Upload" />
              </Submit>
            </Box>
          ) : null}
        </Flex>
      </form>
    );
  }

  renderProgress() {
    return (
      <Flex flexDirection="column" my={2} width={312}>
        <Box mb={1}>
          <Text.Important>{this.state.file.name}</Text.Important>
        </Box>
        <Progress value={Math.round(this.state.progress) / 100} />
        <Box mt={3} ml="auto">
          <Button onClick={this.onAbort}>Cancel</Button>
        </Box>
      </Flex>
    );
  }

  render() {
    const { usedStorage, maxStorage, hasStorageLimit, hasErrors } = this.props;

    return (
      <FillFlex flexDirection="column">
        <Box flex={1} mx="auto" mt={3} width={312}>
          {this.state.isUploading ? this.renderProgress() : this.renderForm()}

          <F
            id="MetricUpload.info"
            defaultMessage="Your dataset should have at least three columns with following data:"
          >
            {t => (
              <Text mt={2} is="div" lineHeight="text">
                {t}
              </Text>
            )}
          </F>
          <F
            id="MetricUpload.important"
            defaultMessage="Latitude, Longitude, Time"
          >
            {t => (
              <Text fontWeight="semibold" is="div">
                {t}
              </Text>
            )}
          </F>

          <OList my={2}>
            <F
              id="MetricUpload.coordinate"
              defaultMessage="Latitude and Longitude format: decimal coordinates. For example: 54.6675, 36.76554"
              tagName="li"
            />
            <li>
              <F id="MetricUpload.time" defaultMessage="Time format:" />
              <UList>
                <F
                  id="MetricUpload.timeUnix"
                  defaultMessage="UNIX time. For example: 1496113532"
                  tagName="li"
                />
                <F
                  id="MetricUpload.timeDate"
                  defaultMessage="Date strings. For example: 04/11/2017 12:16:02"
                  tagName="li"
                />
              </UList>
            </li>
          </OList>

          {hasStorageLimit ? (
            <Box mt={3}>
              <Progress
                value={
                  usedStorage && maxStorage
                    ? Math.min(usedStorage / maxStorage, 1)
                    : 0
                }
                minLabel={<ValueWithUnits value={0} units="GB" />}
                maxLabel={
                  maxStorage ? (
                    <ValueWithUnits
                      value={bytesToGigabytes(maxStorage)}
                      units="GB"
                    />
                  ) : (
                    '- GB'
                  )
                }
              />
            </Box>
          ) : null}
        </Box>

        <Footer
          warning={
            hasStorageLimit && usedStorage >= maxStorage ? (
              <F
                id="MetricUpload.storageFull"
                defaultMessage={`The available data storage have exceeded.
                Manage your data in Explorer or contact us`}
              />
            ) : this.isFileOverflow(this.state.file) ? (
              <F
                id="MetricUpload.smallerFile"
                defaultMessage={`This file can’t be uploaded because storage is not enough.
                Use smaller file or manage your data in {explorerPage}.`}
                values={{
                  explorerPage: (
                    <Link to="/">
                      <F id="MetricUpload.explorer" defaultMessage="Explorer" />
                    </Link>
                  ),
                }}
              />
            ) : hasErrors ? (
              <GlobalAlert selected={[GlobalAlerts.NoHoursLeft]} />
            ) : null
          }
          actions={
            <PlainLink to="/">
              <Button>
                <F id="MetricUpload.back" defaultMessage="Back" />
              </Button>
            </PlainLink>
          }
        />

        <IntlDocumentTitle titleId="createMetric.upload" />
      </FillFlex>
    );
  }
}

export default MetricUpload;
