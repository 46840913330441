import { DateRangePicker, Classes } from '@blueprintjs/datetime';
import styled from 'styled-components';

export default styled(DateRangePicker).attrs({
  allowSingleDayRange: true,
  shortcuts: false,
})`
  padding: 0 !important;
  font-size: ${({ theme }) => theme.typography.smallFont};

  & .DayPicker-Month {
    border-collapse: separate !important;
    border-spacing: 0 8px !important;
    padding: 0 !important;

    &:not(:last-child) {
      margin-right: 16px !important;
    }
  }

  & .${Classes.DATEPICKER_CAPTION} {
    border-bottom: none !important;
  }

  & .${Classes.DATEPICKER_CAPTION_SELECT}:not(:first-child) {
    margin-left: 8px;
  }

  &
    select.${Classes.DATEPICKER_MONTH_SELECT},
    select.${Classes.DATEPICKER_YEAR_SELECT} {
    background: ${({ theme }) => theme.colors.grey2} !important;
    border-radius: ${({ theme }) => theme.radius.default} !important;
    color: ${({ theme }) => theme.colors.grey1} !important;
    font-weight: 400 !important;
  }

  & .DayPicker-Weekday {
    color: ${({ theme }) => theme.colors.grey7} !important;
    font-weight: 400 !important;
  }

  & .DayPicker-Day {
    width: 24px !important;
    height: 24px !important;
    outline: none !important;

    &.DayPicker-Day--selected,
    &.DayPicker-Day--selected-range,
    &.DayPicker-Day--selected-range-start,
    &.DayPicker-Day--selected-range-end {
      background: ${({ theme }) => theme.colors.blue1} !important;
      color: ${({ theme }) => theme.colors.white};

      &:hover {
        background: ${({ theme }) => theme.colors.blue2} !important;
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
