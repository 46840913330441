import * as React from 'react';
import { Box } from 'grid-styled';
import { FillFlex } from '@hm/ukie';

import tou from '../../tou.html';

const TOU = FillFlex.extend.attrs({
  dangerouslySetInnerHTML: { __html: tou },
})`
overflow-y: auto;
`;

TOU.defaultProps = {
  width: '100%',
  p: 1,
  flexDirection: 'column',
  alignItems: 'center',
};

export default TOU;
