import React from 'react';
import { Dialog, Button, RadioButtonGroup, Footer } from '@hm/ukie';
import { FormattedMessage } from 'react-intl';
import { Flex } from 'grid-styled';

import { ApproximationFunction, SliceType } from '../../reducers/calendar';
import Scrollers from '../Calendar/Scrollers';
import Settings from '../../containers/Calendar/Settings';
import InnerFooter from '../../containers/Calendar/InnerFooter';
import Status from '../../containers/Calendar/Status';
import Title, { Lead } from '../Title';
import SliceTypeControl from './SliceTypeControl';

import messages from './messages';

export interface StateProps {
  isValid: boolean;
  approximationFunction: ApproximationFunction;
  sliceType: SliceType;
  sliceDuration: string;
  isSliceTypeEnabled: boolean;
  isSliceTypeStatic: boolean;
  label: string;
}

export interface DispatchProps {
  onApproximationFunction(fn: ApproximationFunction): void;
  onSliceType(sliceType: SliceType): void;
  onClose?(): void;
  onSubmit(): void;
}

export interface OwnProps {
  onBack?(): void;
}

export type Props = StateProps & DispatchProps & OwnProps;

const ApproximatedNewMetric: React.SFC<Props> = ({
  isValid,
  sliceDuration,
  sliceType,
  isSliceTypeEnabled,
  isSliceTypeStatic,
  approximationFunction,
  label,
  onSliceType,
  onApproximationFunction,
  onClose,
  onBack,
  onSubmit,
}) => (
  <Dialog
    title={
      <FormattedMessage
        id="NewApproximatedMetric.header"
        defaultMessage="Approximation settings of {label} Metric"
        values={{ label }}
      >
        {(...text) => <Title>{text}</Title>}
      </FormattedMessage>
    }
    onBack={onBack}
    onClose={onClose}
  >
    <Flex flex="1">
      <Flex flex="1" flexDirection="column">
        <Scrollers />
        <InnerFooter />
      </Flex>
      <Settings info={messages.selectAll}>
        <Lead>
          <FormattedMessage {...messages.aggregationFunction} />
        </Lead>
        <RadioButtonGroup
          onClick={onApproximationFunction}
          selected={approximationFunction}
        >
          <Button value="sum">
            <FormattedMessage {...messages.sum} />
          </Button>
          <Button value="average">
            <FormattedMessage {...messages.average} />
          </Button>
        </RadioButtonGroup>

        {isSliceTypeEnabled ? (
          <SliceTypeControl
            sliceType={sliceType}
            onSliceType={onSliceType}
            isStatic={isSliceTypeStatic}
          />
        ) : null}
      </Settings>
    </Flex>
    <Footer
      info={<Status />}
      actions={
        <Button primary onClick={onSubmit} disabled={!isValid}>
          <FormattedMessage {...messages.submit} />
        </Button>
      }
    />
  </Dialog>
);

export default ApproximatedNewMetric;
