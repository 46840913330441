import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import styled from 'styled-components';
import { Box } from 'grid-styled';
import { scaleBand, ScaleBand } from 'd3-scale';
import { last, clamp, isEmpty } from 'ramda';
import { colors } from '@hm/ukie';

import WaveArea from '../WaveArea';
import DateTitle from './DateTitle';
import Slice from '../Slice';
import { someContains } from '../../utils/time';
import shouldCalendarUpdate from '../shouldCalendarUpdate';
import { CalendarProps } from '../Calendar';

const INDICATOR_HEIGHT = 2;

const Container = styled(Box)`
  text-align: center;
  cursor: default;
`;

interface Props extends CalendarProps {
  date: moment.Moment;
  width?: number;
  height?: number;
}

export class MonthDay extends React.Component<Props, never> {
  static defaultProps = {
    availableIntervals: [],
    selectedIntervals: [],
    filteredOutIntervals: [],
    filterPreview: [],
    width: 72,
    height: 22,
  };

  sliceWidth(scale: ScaleBand<moment.Moment>, data: moment.Moment[]) {
    const [first, second] = data;

    // width = position of slice 1 hour before - scale offset
    const target = second
      ? second.clone().subtract(1, 'hour')
      : last(scale.domain());

    return scale(target) + scale.bandwidth() - scale(first);
  }

  render() {
    const {
      availableIntervals,
      selectedIntervals,
      filteredOutIntervals,
      filterPreview,
      onSelection,
      onSelectionEnd,
      onSelectionClick,
      sliceDuration,
      width,
      height,
      date,
    } = this.props;

    const slices = Array.from(
      new DateRange(date.clone().startOf('day'), date.clone().endOf('day')).by(
        'hour',
      ),
    );

    const data = Array.from(
      new DateRange(date.clone().startOf('day'), date.clone().endOf('day')).by(
        'hours',
        { step: clamp(1, 24, moment.duration(sliceDuration).asHours()) },
      ),
    );

    const scale = scaleBand<moment.Moment>()
      .domain(slices)
      .rangeRound([0, width])
      .paddingInner(0.2);

    const sliceWidth = this.sliceWidth(scale, data);

    return (
      <Container>
        <DateTitle>{date.date()}</DateTitle>
        <svg width={width} height={height}>
          {data.map(slice => (
            <Slice
              date={slice}
              x={scale(slice)}
              key={slice.valueOf()}
              height={height - 4}
              width={sliceWidth}
              ownDuration={sliceDuration}
              isSelected={
                someContains(selectedIntervals, slice) &&
                !someContains(filteredOutIntervals, slice)
              }
              isDisabled={
                !someContains(availableIntervals, slice) ||
                someContains(filteredOutIntervals, slice)
              }
              onSelection={onSelection}
              onSelectionEnd={onSelectionEnd}
              onSelectionClick={onSelectionClick}
            />
          ))}
          {!isEmpty(filterPreview)
            ? data
                .filter(
                  d =>
                    !someContains(filterPreview, d) &&
                    someContains(availableIntervals, d),
                )
                .map((slice, i) => (
                  <rect
                    height={INDICATOR_HEIGHT}
                    x={scale(slice)}
                    y={height - INDICATOR_HEIGHT}
                    fill={colors.primary}
                    width={
                      data[i + 1] ? sliceWidth + scale.bandwidth() : sliceWidth
                    }
                    key={`${slice.toString()}i`}
                  />
                ))
            : null}
          <WaveArea width={width} height={height} />
        </svg>
      </Container>
    );
  }
}

export default shouldCalendarUpdate('day')(MonthDay);
