import { connect } from 'react-redux';
import Patternize from '../components/Metric/Patternize';
import { getLayersWithoutId } from '../selectors/layers';
import { updateEffect, deleteEffect } from '../actions/effects';
import { getEffectById } from '../selectors/effects';
import { AppState } from '../models';

const mapStateToProps = (
  state: AppState,
  ownProps: { id: string; layerId: string },
) => ({
  effect: getEffectById(state, ownProps),
  availableMetrics: getLayersWithoutId(state, ownProps),
});

const mapDispatchToProps = {
  onUpdate: updateEffect,
  onDelete: deleteEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Patternize);
