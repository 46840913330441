import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { LocalForm, Control, Errors } from 'react-redux-form';
import { Password, Submit, ModalOverlay } from '@hm/ukie';
import { Relative } from '../Relative';
import { FieldError, Field, Fieldset, FormActions } from '../Form';
import {
  isRequired,
  longEnough,
  errorMessages,
  passwordsMatch,
} from '../../utils/validators';
import { PasswordUpdate } from '../../models';

interface Props {
  onSubmit(form: PasswordUpdate): Promise<any>;
}

interface State {
  isSubmitting: boolean;
  didSubmit: boolean;
  didError: boolean;
}

export default class PasswordChangeForm extends React.Component<Props, State> {
  state = { isSubmitting: false, didSubmit: false, didError: false };

  oldPassword: HTMLInputElement;
  password: HTMLInputElement;
  passwordConfirmation: HTMLInputElement;

  onSubmit = (form: any) => {
    this.setState({ isSubmitting: true }, () =>
      this.props
        .onSubmit(form)
        .then(() =>
          this.setState({
            isSubmitting: false,
            didSubmit: true,
            didError: false,
          }),
        )
        .catch(() =>
          this.setState({
            isSubmitting: false,
            didSubmit: true,
            didError: true,
          }),
        ),
    );
  };

  render() {
    return (
      <LocalForm
        model="passwordUpdate"
        onSubmit={this.onSubmit}
        initialState={{
          oldPassword: '',
          password: '',
          passwordConfirmation: '',
        }}
        validators={{
          '': { passwordsMatch },
          oldPassword: { isRequired },
          password: { isRequired, passwordLength: longEnough(8) },
          passwordConfirmation: { isRequired },
        }}
      >
        <Relative>
          {this.state.didSubmit ? (
            <ModalOverlay>
              {this.state.didError ? (
                <F
                  id="Profile.passwordUpdateFailes"
                  defaultMessage="Incorrect password. Please try again."
                />
              ) : (
                <F
                  id="Profile.passwordUpdated"
                  defaultMessage="Password have been changed successfully"
                />
              )}
            </ModalOverlay>
          ) : null}
          <Fieldset>
            <Field
              label={
                <F
                  id="Profile.currentPassword"
                  defaultMessage="Current password"
                />
              }
            >
              <Control.text
                component={Password}
                model=".oldPassword"
                mapProps={{
                  invalid: ({ fieldValue }) =>
                    !fieldValue.valid && !fieldValue.pristine,
                }}
                getRef={(el => (this.oldPassword = el)) as any}
              />
              <Errors
                show={field => field.focus && !field.pristine}
                model=".oldPassword"
                messages={errorMessages.password}
                wrapper={props => (
                  <FieldError target={this.password} {...props} />
                )}
              />
            </Field>

            <Field
              label={
                <F id="Profile.newPassword" defaultMessage="New password" />
              }
            >
              <Control.text
                component={Password}
                model=".password"
                mapProps={{
                  invalid: ({ fieldValue }) =>
                    !fieldValue.valid && !fieldValue.pristine,
                }}
                getRef={(el => (this.password = el)) as any}
              />
              <Errors
                show={field => field.focus && !field.pristine}
                model=".password"
                messages={errorMessages.password}
                wrapper={props => (
                  <FieldError target={this.password} {...props} />
                )}
              />
            </Field>

            <Field
              label={
                <F
                  id="Profile.passwordConfirmation"
                  defaultMessage="Confirm password"
                />
              }
            >
              <Control.text
                component={Password}
                model=".passwordConfirmation"
                mapProps={{
                  invalid: ({ fieldValue }) =>
                    !fieldValue.valid && !fieldValue.pristine,
                }}
                getRef={(el => (this.passwordConfirmation = el)) as any}
              />
              <Errors
                show={field => field.focus && !field.pristine}
                model=".passwordConfirmation"
                messages={errorMessages.password}
                wrapper={props => (
                  <FieldError target={this.passwordConfirmation} {...props} />
                )}
              />
              <Errors
                model="passwordUpdate"
                show={{ submitFailed: true } as any}
                messages={errorMessages.form}
                wrapper={props => (
                  <FieldError target={this.passwordConfirmation} {...props} />
                )}
              />
            </Field>
          </Fieldset>
          <FormActions>
            <Submit disabled={this.state.isSubmitting}>
              <F id="Profile.submitPassword" defaultMessage="Save" />
            </Submit>
          </FormActions>
        </Relative>
      </LocalForm>
    );
  }
}
