import { types } from '../actions/modals';
import { SHOW_ALERT } from '../actions/alerts';

export interface ModalsState {
  currentModal: string;
}

export const initialState: ModalsState = {
  currentModal: null,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case types.SHOW_MODAL:
      return { ...state, currentModal: payload };

    case SHOW_ALERT:
    case types.HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
};
