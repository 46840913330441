import React from 'react';
import { BigHandle, BrushCore, colors } from '@hm/ukie';
import { absoluteShort, absoluteFull } from '../../utils/numberFormats';
import { getCollisionShifts } from '../../utils/dimensions';
import { getBrushScale, getBordersInverseScale } from '../../utils/histogram';

const STROKE_WIDTH = 2;

const textStyle: React.CSSProperties = {
  fontSize: '10px',
  fontWeight: 600,
  fill: colors.primary,
  fontFamily: 'Open Sans, sans-serif',
};

const bodyStyle: React.CSSProperties = {
  fill: 'transparent',
  stroke: colors.primary,
  strokeWidth: STROKE_WIDTH,
  cursor: 'ew-resize',
};

interface Props {
  width: number;
  height: number;
  minExtent: number;
  selected: [number, number];
  borders: number[];
  boundaries: Element;
  saveLabelPositions?(labels: ClientRect[]);
  onStart?(e, data);
  onUpdate(selected: [number, number]);
  onStop?(e, data);
}

export default class Brush extends React.Component<Props, never> {
  labels = { left: null, right: null };
  xTranslates = { left: 0, right: 0 };

  componentDidMount() {
    this.updateLabels();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) this.updateLabels();
  }

  // Collects labels’ ClientRect and pass it to parent.
  // Needed to conditionally hide ticks under labels.
  updateLabels = () => {
    const { saveLabelPositions } = this.props;
    const { left: leftLabel, right: rightLabel } = this.labels;

    if (!saveLabelPositions || !leftLabel || !rightLabel) return;

    const left = leftLabel.getBoundingClientRect();
    const right = rightLabel.getBoundingClientRect();

    this.xTranslates = getCollisionShifts({ left, right }, this.xTranslates, 8);

    saveLabelPositions([left, right]);
  };

  renderHandle = (value: number, orientation: 'left' | 'right') => {
    const { height: brushHeight } = this.props;
    const handleHeight = this.getHandleHeight();
    const isLeft = orientation === 'left';

    // XXX: stopPropagation prevents tooltip from showing
    return (
      <g
        style={{ cursor: isLeft ? 'w-resize' : 'e-resize' }}
        onMouseMove={e => e.stopPropagation()}
      >
        <BigHandle
          height={handleHeight}
          y={handleHeight - STROKE_WIDTH}
          orientation={orientation}
        />
        <text
          ref={el => (this.labels[orientation] = el)}
          style={textStyle}
          dy="1.5em"
          textAnchor={isLeft ? 'start' : 'end'}
          y={brushHeight}
          transform={`translate(${this.xTranslates[orientation]},0)`}
        >
          <title>{absoluteFull(value)}</title>
          {absoluteShort(value)}
        </text>
      </g>
    );
  };

  renderBody = () => (
    <rect
      y={-STROKE_WIDTH / 2}
      rx={3}
      ry={3}
      style={bodyStyle}
      height={this.props.height + STROKE_WIDTH}
    />
  );

  onDrag = (e: MouseEvent, data) => e.preventDefault();

  getHandleHeight = () => this.props.height / 3;

  render() {
    const { borders, width, selected: [from, to] } = this.props;
    const xScale = getBrushScale(this.props.width);
    const labelScale = getBordersInverseScale(borders, width);

    const handleHeight = this.getHandleHeight();

    const topPadding = 8;
    const grabRect = {
      width: 24,
      height: handleHeight + topPadding * 2,
      top: handleHeight - STROKE_WIDTH - topPadding,
      inner: 2,
    };

    const leftLabel = this.renderHandle(labelScale(xScale(from)), 'left');
    const rightLabel = this.renderHandle(labelScale(xScale(to)), 'right');

    return (
      <BrushCore
        xScale={xScale}
        selected={this.props.selected}
        minExtent={this.props.minExtent}
        boundaries={this.props.boundaries}
        onStart={this.props.onStart}
        onStop={this.props.onStop}
        grabRect={grabRect}
        onDrag={this.onDrag}
        onChange={this.props.onUpdate}
        leftHandleComponent={leftLabel}
        rightHandleComponent={rightLabel}
        bodyComponent={this.renderBody()}
      />
    );
  }
}
