import React from 'react';
import { ToggleGroup, ToggleButton } from '@hm/ukie';
import { Option } from '../../models';
import { symmetricDifference } from 'ramda';

interface Props {
  options: Option[];
  updateManyOptions(updates);
}

export default class Subset extends React.Component<Props, never> {
  // TODO: move to action creator
  toggle = (values: string[]) => {
    const updates = symmetricDifference(values, this.selectedIds()).map(id => {
      const option = this.props.options.find(o => o.id === id);
      return { id, enabled: !option.enabled };
    });

    this.props.updateManyOptions(updates);
  };

  selectedIds() {
    return this.props.options
      .filter(option => option.enabled)
      .map(option => option.id);
  }

  render() {
    return (
      <ToggleGroup selected={this.selectedIds()} onClick={this.toggle} toggle>
        {this.props.options.map(({ id, label }) => (
          <ToggleButton key={id} value={id}>
            {label}
          </ToggleButton>
        ))}
      </ToggleGroup>
    );
  }
}
