import { takeLatest, select, put } from 'redux-saga/effects';
import { isUserFetched, isUserBillable } from '../selectors/user';
import { types, fetchBilling, isBillingFetched } from '../reducers/billing';
import { getProfile } from '../actions/user';

export default function*() {
  yield takeLatest(types.FETCH_BILLING_IF_MISSING, function*() {
    if (!(yield select(isUserFetched))) {
      yield put.resolve(getProfile());
    }

    if (!(yield select(isBillingFetched)) && (yield select(isUserBillable))) {
      yield put(fetchBilling());
    }
  });
}
