import React from 'react';
import styled from 'styled-components';
import { DateRange } from '@blueprintjs/datetime';
import TimeInterval from '../TimeInterval';

interface Props {
  value: DateRange;
  invalid?: boolean;
  theme?: any;
  className?: string;
  onClick?(): void;
}

const Body = styled.div`
  box-sizing: border-box;
  padding: 0 8px;
  background: ${({ theme, invalid }: any) =>
    invalid ? theme.colors.red3 : theme.colors.grey2};
  height: 24px;
  border-radius: ${({ theme }: any) => theme.radius.default};
  line-height: 24px;
  cursor: pointer;
`;

// TODO: add fold arrow
export default class Interval extends React.Component<Props> {
  render() {
    const { value: [start, end], ...props } = this.props;

    return (
      <Body {...props}>
        <TimeInterval interval={[start, end]} />
      </Body>
    );
  }
}
