import React from 'react';
import styled from 'styled-components';
import { shade } from 'polished';

import { sliceFill } from '../styles/common';
import withSliceSelection, {
  SlicerProps,
  SliceSelectorProps,
} from './withSliceSelection';

interface OwnProps {
  isSelected?: boolean;
  isDisabled?: boolean;
  isErrored?: boolean;
}

type Inner = Outer & SliceSelectorProps;
type Outer = OwnProps & React.SVGAttributes<SVGRectElement> & SlicerProps;

const Slice: React.SFC<Inner> = ({
  className,
  x,
  y,
  width,
  height,
  connectDropTarget,
  connectDragSource,
  onClick,
}) =>
  connectDropTarget(
    connectDragSource(
      <rect
        className={className}
        x={x}
        y={y}
        height={height}
        width={width}
        onClick={onClick}
      />,
    ),
  );

const hoverFill = (props: Outer) => shade(0.9, sliceFill(props));

export default styled<Outer>(withSliceSelection(Slice))`
  fill: ${sliceFill};
  shape-rendering: crispEdges;

  &:hover {
    fill: ${hoverFill};
  }
`;
