import { connect } from 'react-redux';
import { branch, renderNothing } from 'recompose';
import { compose } from 'ramda';

import { getSelectedTool } from '../../selectors/tool';
import { getCurrentFrame } from '../../selectors/volumer';
import { getCurrentProjectId } from '../../selectors/project';

import {
  pointerDown,
  pointerMove,
  pointerUp,
  click,
  doubleClick,
  wheel,
  resize,
  keyDown,
  keyUp,
  disconnect,
} from '../../actions/volumer';

import { beforeWindowUnload } from '../../actions/global';

import Volumer from './Volumer';
import { AppState } from '../../models';

const mapStateToProps = (state: AppState) => ({
  selectedTool: getSelectedTool(state),
  currentFrame: getCurrentFrame(state),
  isRendering: !!getCurrentProjectId(state) && getCurrentFrame(state),
});

const mapDispatchToProps = {
  onPointerDown: pointerDown,
  onPointerMove: pointerMove,
  onPointerUp: pointerUp,
  onClick: click,
  onDoubleClick: doubleClick,
  onWheel: wheel,
  onResize: resize,
  onKeyDown: keyDown,
  onKeyUp: keyUp,
  onClose: disconnect,
  beforeWindowUnload,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ isRendering }) => !isRendering, renderNothing),
)(Volumer);
