import schema, { environmentsSchema } from '../schemas/environment';
import { get, destroy, post, patch } from '../api/rest';
import { Environment } from '../reducers/environments';

export const LOAD_ENVIRONMENTS_LIST = 'environments/LOAD_ENVIRONMENTS_LIST';
export const SELECT_ENVIRONMENT = 'environments/SELECT_ENVIRONMENT';
export const CREATE_PROJECT = 'environments/CREATE_PROJECT';
export const GET_ENVIRONMENT = 'environments/GET_ENVIRONMENT';
export const FETCH_AND_SELECT_ENVIRONMENT =
  'environments/FETCH_AND_SELECT_ENVIRONMENT';
export const DELETE_EDITOR = 'environments/DELETE_EDITOR';
export const ADD_EDITORS = 'environments/ADD_EDITOR';
export const UPDATE = 'environments/UPDATE';
export const DELETE_ENVIRONMENT = 'environments/DELETE_ENVIRONMENT';

export const fetchEnvironments = (noCache?: boolean) => ({
  type: LOAD_ENVIRONMENTS_LIST,
  payload: get('/environments'),
  meta: { schema: environmentsSchema, noCache },
});

export const createProject = (id: string, newProject: any) => ({
  type: CREATE_PROJECT,
  payload: post(`/environments/${id}/projects`, newProject),
});

export const getEnvironment = (id: string) => ({
  type: GET_ENVIRONMENT,
  payload: get(`/environments/${id}`),
  meta: { schema },
});

export const selectEnvironment = (id: string) => ({
  type: SELECT_ENVIRONMENT,
  payload: id,
});

export const fetchAndSelectEnvironment = (id: string) => (
  dispatch,
  getState,
) => {
  return dispatch({
    type: FETCH_AND_SELECT_ENVIRONMENT,
    payload: Promise.all([dispatch(getEnvironment(id))]).then(() =>
      dispatch(selectEnvironment(id)),
    ),
  });
};

export const deleteEditor = (environmentId: string, id: string) => ({
  type: DELETE_EDITOR,
  payload: destroy(`/environments/${environmentId}/editors/${id}`),
  meta: { schema },
});

export const addEditors = (id: string, ids: string[]) => ({
  type: ADD_EDITORS,
  payload: post(`/environments/${id}/editors`, { ids }),
  meta: { schema },
});

export const updateEnvironment = (
  id: string,
  updates: Partial<Environment>,
) => ({
  type: UPDATE,
  payload: {
    ...patch(`/environments/${id}`, updates),
    data: { id, updates },
  },
});

export const deleteEnvironment = (id: string) => ({
  type: DELETE_ENVIRONMENT,
  payload: { ...destroy(`/environments/${id}`), data: { id } },
});

export const types = {
  LOAD_ENVIRONMENTS_LIST,
  SELECT_ENVIRONMENT,
  CREATE_PROJECT,
  GET_ENVIRONMENT,
  FETCH_AND_SELECT_ENVIRONMENT,
  DELETE_EDITOR,
  ADD_EDITORS,
  UPDATE,
  DELETE_ENVIRONMENT,
};
