import React from 'react';
import {
  FormattedMessage as F,
  FormattedDate as D,
  defineMessages,
  injectIntl,
  InjectedIntlProps,
} from 'react-intl';
import { Card, Text, CardProps, Separator, IconButton, Input } from '@hm/ukie';
import { Flex, Box } from 'grid-styled';
import { BaseLayer } from '../../reducers/baseLayers';
import HumanizedIntervals from '../HumanizedIntervals';
import SliceCount from '../SliceCount';

const messages = defineMessages({
  createdAt: {
    id: 'MetricCard.createdAt',
    defaultMessage: 'Time of creation',
  },
});

const ErrorFooter = Box.extend.attrs({ py: 1 })`
  background: ${({ theme }) => theme.colors.yellow1};
`;

export interface Props
  extends Pick<
      CardProps,
      'selected' | 'disabled' | 'onClick' | 'onDoubleClick'
    > {
  metric: BaseLayer;
  onRename?(label: string): Promise<any>;
  onDelete?(): Promise<any>;
}

interface State {
  isHovered: boolean;
}

class MetricCard extends React.Component<Props & InjectedIntlProps, State> {
  state = { isHovered: false };

  render() {
    const {
      metric,
      intl,
      onDelete,
      selected,
      onClick,
      disabled,
      onDoubleClick,
      onRename,
    } = this.props;

    const showDeleteButton =
      (this.state.isHovered || metric.state === 'failed') &&
      metric.belongToUs &&
      onDelete;

    return (
      <Card
        hover={{ boxShadow: 2 }}
        style={{ lineHeight: '24px', padding: 0 }}
        selected={selected}
        onClick={onClick}
        disabled={metric.state === 'uploading' || disabled}
        m={0}
        onDoubleClick={onDoubleClick}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
      >
        <Box p={selected ? 14 : 2}>
          <Flex mb={2}>
            <Box width={216} mr={1}>
              {metric.belongToUs ? (
                <Input
                  truncate
                  disabled={metric.state === 'failed'}
                  titleFont
                  value={metric.label}
                  title={metric.label}
                  onChange={e => onRename(e.currentTarget.value)}
                />
              ) : (
                <Text
                  fontSize={2}
                  lineHeight="24px"
                  truncate
                  is="div"
                  title={metric.label}
                >
                  {metric.label}
                </Text>
              )}
              {metric.state === 'uploading' ? (
                <Text.Disclaimer is="div">
                  <F
                    id="MetricCard.uploadingInfo"
                    defaultMessage="Importing…"
                  />
                </Text.Disclaimer>
              ) : null}
            </Box>
            {showDeleteButton ? (
              <IconButton icon="bin" onClick={onDelete} />
            ) : null}
          </Flex>

          <Box my="2">
            <HumanizedIntervals
              is="div"
              intervals={metric.availableTimeIntervals}
              sliceDuration={metric.sliceDurations[0]}
            />
            <SliceCount
              is="div"
              intervals={metric.availableTimeIntervals}
              sliceDuration={metric.sliceDurations[0]}
            />
          </Box>

          <Box my={2}>
            <div>{metric.timezone}</div>
          </Box>
          <Separator />
          <Box mt={2} title={intl.formatMessage(messages.createdAt)}>
            <D
              value={metric.createdAt}
              month="long"
              year="numeric"
              day="2-digit"
            />
          </Box>
        </Box>

        {metric.state === 'failed' ? (
          <ErrorFooter px={selected ? 14 : 2}>
            <F
              id="MetricCard.failedFooterMessage"
              defaultMessage="The Metric publishing has failed. Try again."
            />
          </ErrorFooter>
        ) : null}
      </Card>
    );
  }
}

export default injectIntl(MetricCard);
