import React from 'react';
import { where } from 'ramda';
import { Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import { Text, Submit } from '@hm/ukie';
import { LocalForm } from 'react-redux-form';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';

import { isStripeComplete } from '../../utils/validators';
import { WithCard } from '../TrialOptions';
import PaymentFields from '../PaymentFieldset';
import { FormActions } from '../Form';

interface OwnProps {
  signupToken: string;
  submitTokens(tokens: { signupToken: string; stripeToken: string });
}

type Props = OwnProps & Partial<ReactStripeElements.InjectedStripeProps>;

interface State {
  valid?: boolean;
  isSubmitting?: boolean;
}

class Form extends React.Component<Props, State> {
  state = {
    valid: false,
    isSubmitting: false,
  };

  onSubmit = form => {
    const { stripe, signupToken, submitTokens } = this.props;
    this.setState({ isSubmitting: true });

    stripe
      .createToken(form)
      .then(({ token }) =>
        submitTokens({ stripeToken: token.id, signupToken }),
      );
  };

  render() {
    return (
      <LocalForm
        onUpdate={form => this.setState({ valid: form.$form.valid })}
        validators={{
          '': where({
            cardNumber: isStripeComplete,
            cardExpiry: isStripeComplete,
            cardCvc: isStripeComplete,
          }),
        }}
        onSubmit={this.onSubmit}
      >
        <F
          id="SignUpConfirmation.infoWithCard"
          defaultMessage="You’ve been successfully registered in Chronotope and signed up for the trial. As required by the selected plan, add a payment source to continue:"
        >
          {text => <Text lineHeight="text">{text}</Text>}
        </F>
        <Box mt={3} mb={4}>
          <WithCard />
        </Box>
        <F id="SignUpConfirmation.payment" defaultMessage="Payment">
          {text => <Text.Important>{text}</Text.Important>}
        </F>
        <PaymentFields />
        <FormActions>
          <Submit disabled={!this.state.valid || this.state.isSubmitting}>
            <F id="SignUp.submit" defaultMessage="Register" />
          </Submit>
        </FormActions>
      </LocalForm>
    );
  }
}

export default injectStripe(Form);
