import React from 'react';
import { connect } from 'react-redux';

import { getProfile, getWelcomeProjects } from '../../actions/user';
import {
  getCurrentUserName,
  getWelcome,
  isUserFetched,
  isUserActive,
  isUserBillable,
} from '../../selectors/user';
import {
  getPlanType,
  getDaysTillInvoice,
  fetchBilling,
  Plan,
} from '../../reducers/billing';

import { AppState } from '../../models';
import Welcome, { Props } from './Welcome';

interface ContainerProps {
  subscriptionActive: boolean;
  plan: Plan;
  userFetched: boolean;
  name: string;
  getProfile(): void;
  getWelcomeProjects(): void;
  getBilling(): void;
}

export class WelcomeContainer extends React.Component<Props & ContainerProps> {
  componentDidMount() {
    if (!this.props.userFetched) {
      this.props.getProfile();
    }

    this.props.getWelcomeProjects();
  }

  render() {
    const {
      getProfile: _,
      getWelcomeProjects: __,
      getBilling,
      userFetched,
      ...rest
    } = this.props;

    return userFetched ? <Welcome {...rest} /> : null;
  }
}

export default connect(
  (state: AppState) => ({
    plan: getPlanType(state),
    name: getCurrentUserName(state),
    userFetched: isUserFetched(state),
    subscriptionActive: isUserActive(state),
    projects: getWelcome(state),
    daysOfTrialLeft: getDaysTillInvoice(state),
    isUserBillable: isUserBillable(state),
  }),
  {
    getProfile,
    getWelcomeProjects,
    getBilling: fetchBilling,
  },
)(WelcomeContainer);
