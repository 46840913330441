import styled, { StyledFunction } from 'styled-components';
import { radius } from '@hm/ukie';
import { sliceFill, SliceProps } from '../../styles/common';

const month: StyledFunction<SliceProps & React.HTMLProps<HTMLDivElement>> =
  styled.div;

export default month`
  height: 3px;
  width: 4px;
  background: ${sliceFill};
  border-radius: ${radius.default};

  &:not(:last-child) {
    margin-right: 1px;
  }
`;
