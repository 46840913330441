import { connect } from 'react-redux';
import Navigator, { OwnProps } from '../../components/Calendar/Navigator';

import {
  computeNavigatorDates,
  getZoomLevel,
  computeCalendarSelection,
  getAvailableIntervals,
  getFilteredOutIntervals,
} from '../../selectors/calendar';

import { AppState } from '../../models';

const mapStateToProps = (state: AppState, props: OwnProps) => ({
  dates: computeNavigatorDates(state),
  zoomLevel: getZoomLevel(state),
  selectedIntervals: computeCalendarSelection(state),
  availableIntervals: getAvailableIntervals(state),
  filteredOutIntervals: getFilteredOutIntervals(state),
});

export default connect(mapStateToProps)(Navigator);
