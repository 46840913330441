import { partialRight, contains } from 'ramda';
import { volumer } from '@hm/volumer-api';
import { ObjectType } from '../constants/objects';
import { MapObject } from '../reducers/selectedObjects';

export const objectTypeToTool = {
  [volumer.types.ObjectType.POLYGON]: ObjectType.POLYGON,
  [volumer.types.ObjectType.POLYLINE]: ObjectType.POLYLINE,
  [volumer.types.ObjectType.RADIUS]: ObjectType.RADIUS,
  [volumer.types.ObjectType.ROAD]: ObjectType.ROAD,
  [volumer.types.ObjectType.SPATIAL_MARKER]: ObjectType.MARKER,
  [volumer.types.ObjectType.TEMPORAL_MARKER]: ObjectType.MARKER,
  [volumer.types.ObjectType.FLIPPED_TEMPORAL_MARKER]: ObjectType.MARKER,
  [volumer.types.ObjectType.MARKER]: ObjectType.MARKER,
};

export const objectHasRadius = partialRight(contains, [
  ObjectType.POLYLINE,
  ObjectType.RADIUS,
  ObjectType.ROAD,
]);

const startPostfix = 'Start';
const stopPostfix = 'Stop';

/**
 * Get action type for the start of a continuous interaction
 */
export const start = (type: string) => type + startPostfix;

/**
 * Get action type for the end of a continuous interaction
 */
export const stop = (type: string) => type + stopPostfix;

/**
 * Encode message to be sent into Volumer socket
 */
export const encodeMessage = (message: any) => {
  const {
    stream: { ClientMessage },
  } = volumer;

  const error = ClientMessage.verify(message);
  if (error) throw Error(error);

  return ClientMessage.encode(ClientMessage.fromObject(message)).finish();
};

export const canSaveObject = ({ type, points }: MapObject) => {
  switch (type) {
    case ObjectType.POLYGON:
      return points.length > 2;
    case ObjectType.POLYLINE:
    case ObjectType.ROAD:
      return points.length > 1;
    default:
      return true;
  }
};
