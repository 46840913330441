import React from 'react';
import styled from 'styled-components';
import { Duration, Moment } from 'moment';
import 'moment-timezone';
import { getTooltipLabel, getApproximatedLabel } from './labels';
import { ApproximationType } from '../../reducers/currentProject';

interface Props extends React.SVGAttributes<SVGGElement> {
  slice: Moment;
  sliceDuration: Duration;
  approximationType: ApproximationType;
}

const Container = styled.div`
  font-size: 9px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.grey6};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  padding: 3px 4px;
  max-width: max-content;
  border-radius: ${({ theme }) => theme.radius.small};
  text-align: center;
  transition: opacity 300ms ease-in-out;
`;

const Line = styled.p`
  margin: 0;
`;

export default class Tooltip extends React.Component<Props, never> {
  render() {
    const {
      slice,
      sliceDuration,
      approximationType,
      children,
      ...rest,
    } = this.props;

    const label = approximationType
      ? getApproximatedLabel(slice, approximationType)
      : getTooltipLabel(slice, sliceDuration);

    return (
      <g {...rest}>
        <foreignObject width="100" height="100">
          <Container>
            {label.map(line => <Line key={line}>{line}</Line>)}
          </Container>
        </foreignObject>
      </g>
    );
  }
}
