import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Text } from '@hm/ukie';
import { Box } from 'grid-styled';

export const plan = {
  hours: 10,
  gb: 0.48828125, // 500mb
};

const WithoutCard: React.SFC = () => (
  <React.Fragment>
    <F id="TrialOptions.15days" defaultMessage="15 days trial">
      {text => (
        <Text fontWeight="bold" fontSize={2}>
          {text}
        </Text>
      )}
    </F>
    <F
      id="TrialOptions.paymentNotRequired"
      defaultMessage="Payment source isn't required"
    >
      {text => (
        <Text is="div" color="grey7">
          {text}
        </Text>
      )}
    </F>
    <Box mt={1}>
      <F
        id="TrialOptions.hours"
        values={{
          hours: (
            <Text.Title is="strong" key="value">
              {plan.hours}
            </Text.Title>
          ),
        }}
      >
        {(...text) => <Text.ThinTitle is="div">{text}</Text.ThinTitle>}
      </F>

      <F
        id="TrialOptions.storageMb"
        defaultMessage="{storage} Mb of data storage"
        values={{
          storage: (
            <Text.Title is="strong" key="value">
              {plan.gb * 1024}
            </Text.Title>
          ),
        }}
      >
        {(...text) => <Text.ThinTitle is="div">{text}</Text.ThinTitle>}
      </F>
    </Box>
  </React.Fragment>
);

export default WithoutCard;
