import { Point } from '../models';

/** Tests x and y distances between two points against a numeric threshold */
export const xyThreshold = (
  pointA: Point,
  pointB: Point,
  threshold: number,
): boolean => {
  const deltaX = Math.abs(pointA.x - pointB.x);
  const deltaY = Math.abs(pointA.y - pointB.y);
  return deltaX < threshold && deltaY < threshold ? true : false;
};

export const blurOnEnter: React.KeyboardEventHandler<
  HTMLInputElement | HTMLSelectElement
> = e => {
  if (e.key === 'Enter') {
    e.preventDefault();
    e.currentTarget.blur();
  }
};
