import { schema } from 'normalizr';
import moment from 'moment';
import { DateRange } from 'moment-range';
import 'moment-timezone';
import { propOr } from 'ramda';
import layerSchema from './layer';

const getSelectedTimeIntervals = (project: any) =>
  propOr<string[][], any, string[][]>([], 'selectedTimeIntervals', project);

const getFilteredTimeIntervals = (project: any) =>
  propOr<string[][], any, string[][]>([], 'filteredTimeIntervals', project);

const projectSchema = new schema.Entity(
  'projects',
  { layers: [layerSchema] },
  {
    processStrategy: project => ({
      ...project,
      sliceDuration: moment.duration(project.sliceDuration),
      selectedTimeIntervals: getSelectedTimeIntervals(project).map(
        range =>
          new DateRange(range.map(date => moment.tz(date, project.timezone))),
      ),
      filteredTimeIntervals: getFilteredTimeIntervals(project).map(
        range =>
          new DateRange(range.map(date => moment.tz(date, project.timezone))),
      ),
      updatedAt: moment(project.updatedAt),
      createdAt: moment(project.createdAt),
      isFetching: false,
    }),
  },
);

export default projectSchema;

export const projectsSchema = new schema.Array(projectSchema);
