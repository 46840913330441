import { put } from 'redux-saga/effects';
import { start, stop } from '../../utils/volumerInteractions';
import { Point } from '../../models';
import { EventSender } from './getEventSender';

type CameraEvents = 'groundPan' | 'pan' | 'rotate';

export default (event: CameraEvents) => ({
  *start(sendEvent: EventSender, coord: Point) {
    yield* sendEvent(start(event) as any, { coord });

    yield put({ type: `camera/START_${event.toUpperCase()}` });
  },

  *stop(sendEvent: EventSender, coord: Point) {
    yield* sendEvent(stop(event) as any, { coord });

    yield put({ type: `camera/STOP_${event.toUpperCase()}` });
  },

  *tick(sendEvent: EventSender, coord: Point, prevCoord: Point) {
    yield* sendEvent(event, { coord, prevCoord });
  },
});
