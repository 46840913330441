import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Box } from 'grid-styled';
import { Text } from '@hm/ukie';

export const plan = {
  hours: 20,
  gb: 2,
};

const WithCard: React.SFC = () => (
  <React.Fragment>
    <F id="TrialOptions.30days" defaultMessage="30 days trial">
      {text => (
        <Text fontWeight="bold" fontSize={2}>
          {text}
        </Text>
      )}
    </F>
    <F
      id="TrialOptions.paymentRequired"
      defaultMessage="Payment source is required"
    >
      {text => (
        <Text is="div" color="grey7">
          {text}
        </Text>
      )}
    </F>
    <Box mt={1}>
      <F
        id="TrialOptions.hours"
        defaultMessage="{hours} hours"
        values={{
          hours: (
            <Text.Title is="strong" key="value">
              {plan.hours}
            </Text.Title>
          ),
        }}
      >
        {(...text) => <Text.ThinTitle is="div">{text}</Text.ThinTitle>}
      </F>

      <F
        id="TrialOptions.storageGB"
        defaultMessage="{storage} GB of data storage"
        values={{
          storage: (
            <Text.Title is="strong" key="value">
              {plan.gb}
            </Text.Title>
          ),
        }}
      >
        {(...text) => <Text.ThinTitle is="div">{text}</Text.ThinTitle>}
      </F>
    </Box>
  </React.Fragment>
);

export default WithCard;
