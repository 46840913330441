import React from 'react';
import styled, { StyledComponentClass } from 'styled-components';
import { Flex, Box } from 'grid-styled';
import { Theme } from '@hm/ukie';
import { selection } from '../../styles/common';

const ImgInput = styled.input.attrs({
  type: 'image',
})`
  padding: 0;
  width: 200px;
  height: 336px;
`;

const Btn = styled.div`
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey5};

  ${selection};
` as StyledComponentClass<
  { disabled?: boolean } & React.HTMLProps<HTMLDivElement>,
  Theme
>;

interface Props {
  src: string;
  selected?: boolean;
  onClick?(): void;
}

const GifButton: React.SFC<Props> = ({ src, onClick, children, selected }) => (
  <Flex flexDirection="column" align="center" justify="space-between">
    <Box mb={12}>{children}</Box>
    <Btn onClick={onClick} selected={selected}>
      <ImgInput src={src} />
    </Btn>
  </Flex>
);

export default GifButton;
