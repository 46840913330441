import React from 'react';
import { Moment } from 'moment';
import { DateRange } from 'moment-range';
import styled, { StyledComponentClass } from 'styled-components';
import { typography, colors, radius, Theme } from '@hm/ukie';

import SimpleWave from './SimpleWave';
import { navigatorTitleColor, NavigatorTitleProps } from '../../styles/common';
import shouldCalendaraUpdate from '../shouldCalendarUpdate';

const Title = styled.div`
  font-size: ${typography.smallFont};
  ${navigatorTitleColor};
` as StyledComponentClass<NavigatorTitleProps, Theme>;

const Wrapper = styled.div`
  text-align: center;
  margin: 5px 0;
  border: ${({ focused }: { focused: boolean }) =>
    focused ? `1px solid ${colors.primary}` : 'none'};
  border-radius: ${radius.default};
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 9px;
  }
`;

interface Props {
  date: Moment;
  selectedIntervals: DateRange[];
  availableIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
  focused?: boolean;
  className?: string;
  onClick(date: Moment): void;
}

const Day: React.SFC<Props> = ({ focused, className, onClick, ...props }) => (
  <Wrapper
    focused={focused}
    className={className}
    onClick={() => onClick(props.date)}
  >
    <Title
      date={props.date}
      availableIntervals={props.availableIntervals}
      filteredOutIntervals={props.filteredOutIntervals}
    >
      {props.date.date()}
    </Title>
    <SimpleWave {...props} />
  </Wrapper>
);

export default shouldCalendaraUpdate('day')(Day);
