import React from 'react';
import { colors } from '@hm/ukie';

interface Props {
  x?: number;
  y?: number;
  height?: number;
  color?: string;
  style?: any;
}

const Mark: React.StatelessComponent<Props> = ({ x, y, height, color }) => {
  return (
    <g style={{ pointerEvents: 'none' }}>
      <line
        style={{
          stroke: color,
          opacity: 0.4,
        }}
        x1={x}
        y1={0}
        x2={x}
        y2={y}
      />
      <line
        style={{
          stroke: 'white',
          opacity: 0.4,
        }}
        x1={x}
        y1={y}
        x2={x}
        y2={height}
      />
    </g>
  );
};

Mark.defaultProps = {
  x: 0,
  y: 0,
  height: 0,
  color: colors['default-color'],
};

export default Mark;
