import { DateRange } from 'moment-range';
import { evolve } from 'ramda';
import { push } from 'react-router-redux';
import { getEnvironment } from '../actions/environments';
import { destroy, get, post, put } from '../api/rest';
import { CameraMode } from '../models';
import schema, { projectsSchema } from '../schemas/project';
import {
  getCurrentEnvironmentId,
  getVerticalScaling,
} from '../selectors/project';
import { toUTCArray } from '../utils/time';
import { hideModal } from './modals';

const DELETE_CURRENT_PROJECT = 'currentProject/DELETE_CURRENT';
const GET_PROJECT = 'currentProject/GET';
const OPTIMISTIC_UPDATE_PROJECT = 'currentProject/OPTIMISTIC_UPDATE';
const NORMALIZED_UPDATE_PROJECT = 'currentProject/NORMLALIZED_UPDATE';
const COPY_PROJECT = 'currentProject/COPY';
const UPDATE_LAYERS_ORDER = 'currentProject/UPDATE_LAYERS_ORDER';
const VERTICAL_SCALING_DRAG = 'currentProject/VERTICAL_SCALING_DRAG';
const VERTICAL_SCALING_DRAG_START =
  'currentProject/VERTICAL_SCALING_DRAG_START';
const VERTICAL_SCALING_DRAG_STOP = 'currentProject/VERTICAL_SCALING_DRAG_STOP';
const SHOW_HIDDEN_OBJECTS = 'currentProject/SHOW_HIDDEN_OBJECTS';
const DELETE_PROJECT = 'currentProject/DELETE';
const OPEN_PROJECT = 'currentProject/OPEN';
const VIEW_PROJECT = 'currentProject/VIEW';

const RENAME_PROJECT = 'projects/RENAME';
const FETCH_PROJECTS = 'projects/FETCH_LIST';
const PROJECT_STATUS = 'projects/STATUS';

/**
 * Gets project parameters from Concierge
 */
export const getProject = (id: string) => ({
  type: GET_PROJECT,
  payload: get(`/projects/${id}`),
  meta: { schema },
});

/**
 * POST that opens a session but also returns Volumer socket URL to connect to
 */
export const openProject = (id: string, options?: any) => ({
  type: OPEN_PROJECT,
  payload: post(`/projects/${id}/open`, { options }),
});

export const viewProject = (id: string) => ({
  type: VIEW_PROJECT,
  payload: post(`/projects/${id}/view`),
  meta: { schema },
});

export const confirmDeleteProject = () => (dispatch, getState) => {
  const id = getState().project.id;

  return (
    dispatch({
      type: DELETE_CURRENT_PROJECT,
      payload: destroy(`/projects/${id}`),
    })
      // project deletion requires confirmation dialog. After project is deleted we
      // can hide it and redirect user to index route
      .then(() => {
        dispatch(hideModal());
        dispatch(push('/'));
      })
  );
};

const transformUpdates = updates => {
  return evolve(
    {
      selectedTimeIntervals: (i: DateRange[]) => i.map(toUTCArray),
    },
    updates,
  );
};

export const optimisticUpdateProject = (id: string, updates: any) => ({
  type: OPTIMISTIC_UPDATE_PROJECT,
  payload: {
    ...put(`/projects/${id}`, transformUpdates(updates)),
    data: { id, updates },
  },
});

export const normalizedUpdateProject = (id: string, updates: any) => ({
  type: NORMALIZED_UPDATE_PROJECT,
  payload: put(`/projects/${id}`, transformUpdates(updates)),
  meta: { schema },
});

export const renameProject = (id: string, label: string) => ({
  type: RENAME_PROJECT,
  payload: {
    ...put(`/projects/${id}`, { label }),
    data: { id, updates: { label } },
  },
});

export const updateCurrentProject = updates => (dispatch, getState) => {
  const id = getState().project.id;
  if (!id) return;

  return dispatch(optimisticUpdateProject(id, updates));
};

export const copyProject = (id: string, label: string) => (
  dispatch,
  getState,
) =>
  dispatch({
    type: COPY_PROJECT,
    payload: post(`/projects/${id}/copy`, { label }),
    meta: { schema },
  }).then(({ value: { id: newId } }) => {
    dispatch(hideModal());
    dispatch(push(`/projects/${newId}`));
  });

// TODO: this is API call only. Replace thing above with it
export const cloneProject = (id: string, copyLabel: string) => ({
  type: COPY_PROJECT,
  payload: post(`/projects/${id}/copy`, { label: copyLabel }),
  meta: { schema },
});

export const deleteProject = (id: string) => ({
  type: DELETE_PROJECT,
  payload: {
    ...destroy(`/projects/${id}`),
    data: id,
  },
});

export const getCurrentEnvironment = () => (dispatch, getState) => {
  const environmentId = getCurrentEnvironmentId(getState());
  return dispatch(getEnvironment(environmentId));
};

export const updateLayersOrder = (layersOrder: string[]) => ({
  type: UPDATE_LAYERS_ORDER,
  payload: layersOrder,
});

export const persistTimeWindow = (timeWindow: [number, number]) =>
  updateCurrentProject({ timeWindow });

export const changeCameraMode = (cameraMode: CameraMode) =>
  updateCurrentProject({ cameraMode });

export const verticalScalingDrag = (value: number) => ({
  type: VERTICAL_SCALING_DRAG,
  payload: value,
});

export const verticalScalingDragStart = () => ({
  type: VERTICAL_SCALING_DRAG_START,
});

export const verticalScalingDragStop = () => ({
  type: VERTICAL_SCALING_DRAG_STOP,
});

export const persistVerticalScaling = () => (dispatch, getState) => {
  const verticalScaling = getVerticalScaling(getState());
  return dispatch(updateCurrentProject({ verticalScaling }));
};

export const showHiddenObjects = () => (dispatch, getState) => {
  const state = getState();
  const id = state.project.id;

  return dispatch({
    type: SHOW_HIDDEN_OBJECTS,
    payload: post(`/projects/${id}/unhide`),
  });
};

export const fetchProjects = () => ({
  type: FETCH_PROJECTS,
  payload: get('/projects'),
  meta: { schema: projectsSchema },
});

export const goToProject = (id: string) => push(`/projects/${id}`);

export const goToProjectView = (id: string) => push(`/projects/${id}/view`);

export const projectStatus = (message: any) => ({
  type: PROJECT_STATUS,
  payload: message,
});

export const types = {
  DELETE_CURRENT_PROJECT,
  GET_PROJECT,
  OPTIMISTIC_UPDATE_PROJECT,
  NORMALIZED_UPDATE_PROJECT,
  UPDATE_LAYERS_ORDER,
  VERTICAL_SCALING_DRAG,
  VERTICAL_SCALING_DRAG_START,
  VERTICAL_SCALING_DRAG_STOP,
  SHOW_HIDDEN_OBJECTS,
  DELETE_PROJECT,
  OPEN_PROJECT,
  VIEW_PROJECT,
  FETCH_PROJECTS,
  RENAME_PROJECT,
  COPY_PROJECT,
  PROJECT_STATUS,
};
