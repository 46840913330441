import { volumer } from '@hm/volumer-api';
import { post, put, destroy } from '../api/rest';
import schema from '../schemas/effect';

const DELETE_EFFECT = 'effects/DELETE';
const UPDATE_EFFECT = 'effects/UPDATE';
const CREATE_EFFECT = 'effects/CREATE';

const TREND_BLUR_START = 'effects/TREND_BLUR_START';
const TREND_BLUR_DRAG = 'effects/TREND_BLUR_DRAG';
const TREND_BLUR_STOP = 'effects/TREND_BLUR_STOP';

export const deleteEffect = (id: string) => ({
  type: DELETE_EFFECT,
  payload: {
    ...destroy(`/effects/${id}`),
    data: { id },
  },
});

export const updateEffect = (id: string, updates: any) => ({
  type: UPDATE_EFFECT,
  payload: {
    ...put(`/effects/${id}`, updates),
    data: { id, updates },
  },
  meta: { throttle: 70 },
});

export const createEffect = (
  layerId: string,
  type: string,
  initParams?: any,
) => ({
  type: CREATE_EFFECT,
  payload: post(`/layers/${layerId}/effects`, { type, ...initParams }),
  meta: { schema, layerId },
});

export const trendBlurStart = (
  message: volumer.event.BlurDragStart$Properties,
) => ({
  type: TREND_BLUR_START,
  payload: { message },
});

export const trendBlurDrag = (
  effectId: string,
  message: volumer.event.BlurDrag$Properties,
) => ({
  type: TREND_BLUR_DRAG,
  payload: { message, effectId },
});

export const trendBlurStop = (
  message: volumer.event.BlurDragStop$Properties,
) => ({
  type: TREND_BLUR_STOP,
  payload: { message },
});

export const types = {
  DELETE_EFFECT,
  UPDATE_EFFECT,
  CREATE_EFFECT,
  TREND_BLUR_START,
  TREND_BLUR_DRAG,
  TREND_BLUR_STOP,
};
