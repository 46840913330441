import React from 'react';
import { area } from 'd3-shape';
import { range } from 'ramda';
import styled from 'styled-components';

const RATIO = 0.35;

const renderArea = area<{ x: number; y: number }>()
  .x(d => d.x)
  .y1(d => d.y)
  .y0(0);

/** Component that renders curved area to mask wave selectors */
// FIXME: Property 'width' is optional in type 'SVGAttributes<SVGPathElement>
// & ThemeProps<any>' but required in type '{ width: any; height: any; }'
export default styled.path.attrs<React.SVGProps<SVGPathElement>>({
  /*
   * To get desired area we:
   * - map x values (from 0 to width) to angles from 0 to PI
   * - then take sin of it to get values from 0 to 1
   * - then take square of it to squash curve of the shape
   * - then shift along x axis by subtracting from 1
   * - and finally multiply by height * ratio to get height we want
   */
  d: ({ width, height }) =>
    renderArea(
      range(0, Number(width) + 1).map(x => ({
        x,
        y:
          (1 - Math.sin(Math.PI * x / Number(width)) ** 2) *
          Number(height) *
          RATIO,
      })),
    ),
})`
  fill: white;
`;
