import { types } from '../actions/alerts';

export interface AlertsState {
  text: string;
  show: boolean;
}

export const initialState: AlertsState = {
  text: '',
  show: false,
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case types.SHOW_ALERT:
      return {
        show: true,
        text: payload,
      };

    case types.HIDE_ALERT:
      return initialState;

    default:
      return state;
  }
};
