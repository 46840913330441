type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export const API_REQUEST = Symbol('Api request');

export interface ApiRequestProps {
  [API_REQUEST]: {
    url: string;
    options: any;
  };
}

const request = (
  method: Method,
  url: string,
  body: any = {},
  options?: any,
): ApiRequestProps => ({
  [API_REQUEST]: {
    url,
    options: {
      method,
      headers: { 'Content-Type': 'application/json' },
      ...(method === 'GET' ? undefined : { body: JSON.stringify(body) }),
      ...options,
    },
  },
});

export const get = (url: string, opts?: any) =>
  request('GET', url, undefined, opts);

export const post = (url: string, body?: any, opts?: any) =>
  request('POST', url, body, opts);

export const patch = (url: string, body?: any, opts?: any) =>
  request('PATCH', url, body, opts);

export const put = (url: string, body?: any, opts?: any) =>
  request('PUT', url, body, opts);

export const destroy = (url: string, body?: any, opts?: any) =>
  request('DELETE', url, body);
