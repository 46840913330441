import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isValid } from 'react-redux-form';

import SignInForm from './SignInForm';
import { getIsLoggedIn } from '../../selectors/user';
import { logIn, requestPasswordReset, resetPassword } from '../../actions/user';
import { AppState } from '../../models';

export default connect(
  (state: AppState) => ({
    isLoggedIn: getIsLoggedIn(state),
    isValid: isValid(state.forms.login),
  }),
  { logIn, onResetRequest: requestPasswordReset, onReset: resetPassword },
)(
  ({ isLoggedIn, ...props }: any) =>
    isLoggedIn ? <Redirect to="/" /> : <SignInForm {...props} />,
);
