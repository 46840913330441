import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import { Scrollable, Text } from '@hm/ukie';
import Track from '../Track';
import LinkButton from '../LinkButton';
import withDisabled from '../withDisabled';

// TODO: BorderFlex
const Container = Flex.extend`
  width: 340px;
  box-sizing: border-box;
  border-left: 1px solid ${({ theme }) => theme.colors.grey5};
  flex-direction: column;
  /* makes neighbour border overlap */
  margin-left: -1px;
`;

// TODO: suuuuure
const Content = styled.div`
  padding: 16px;
`;

// TODO: Flex
const Footer = styled.div`
  margin-top: auto;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  ${withDisabled};
`;

export interface OwnProps {
  className?: string;
  currentSlide?: string;
  info?: FormattedMessage.MessageDescriptor;
}

export interface DispatchProps {
  onSelectAll(): void;
  onClear(): void;
}

type Props = OwnProps & DispatchProps;

const RegularProjectSettings: React.SFC<Props> = ({
  className,
  onSelectAll,
  onClear,
  info,
  currentSlide,
  children,
}) => (
  <Container className={className}>
    <Scrollable>
      <Content>{children}</Content>
    </Scrollable>
    <Footer disabled={!!currentSlide}>
      <Flex flex="1">
        <Box width={1 / 2}>
          <Track
            data-metrics-event-name="selectAll"
            data-metrics-category="Calendar"
          >
            <LinkButton onClick={onSelectAll}>Select all slices</LinkButton>
          </Track>
          {info ? (
            <Box mt={1}>
              <FormattedMessage {...info}>
                {t => (
                  <Text color="grey7" lineHeight="text">
                    {t}
                  </Text>
                )}
              </FormattedMessage>
            </Box>
          ) : null}
        </Box>
        <Flex justify="flex-end" width={1 / 2}>
          <Track
            data-metrics-event-name="clearAll"
            data-metrics-category="Calendar"
          >
            <LinkButton onClick={onClear}>Clear selection</LinkButton>
          </Track>
        </Flex>
      </Flex>
    </Footer>
  </Container>
);

export default RegularProjectSettings;
