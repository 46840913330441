import { types } from '../actions/search';
import { types as projectTypes } from '../actions/projects';
import { pend, fulfill } from '../utils/promise';

export interface Search {
  results: SearchResult[];
  totalCount: number;
  isFetching: boolean;
  query: string;
}

export interface SearchResult {
  type: string;
  container: string;
  category: string;
  id: string;
  info: string;
  label: string;
}

export const initialState: Search = {
  results: [],
  isFetching: false,
  totalCount: null,
  query: null,
};

export default (state: Search = initialState, action: any): Search => {
  const { type, payload } = action;

  switch (type) {
    case pend(types.SEARCH):
      return {
        ...state,
        isFetching: true,
        query: payload,
      };

    case fulfill(types.SEARCH):
      return {
        ...state,
        results: payload.results,
        totalCount: payload.totalCount,
        isFetching: false,
      };

    case pend(projectTypes.DELETE_CURRENT_PROJECT):
      return initialState;

    default:
      return state;
  }
};
