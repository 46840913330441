import { anyPass, whereEq } from 'ramda';
import { take, select } from 'redux-saga/effects';
import { createPolyline, createPolygon, createRoad } from './createPolyshape';
import createMarker from './createMarker';
import createRadius from './createRadius';
import deselectAllObjects from './deselectAllObjects';
import raycastGet, { Hit } from './raycastGet';
import { exitEditMode } from './editMode';
import { objectsSelection, fragmentsSelection } from './entitiesSelection';
import { types } from '../../actions/volumer';
import { getSelectedTool } from '../../selectors/tool';
import { getActiveModifier } from '../../selectors/volumer';
import { isEditMode } from '../../selectors/selectedObjects';
import { Tool } from '../../constants/tools';
import { MouseButton, Modifier } from '../../constants/volumer';
import { Pointer } from '../../models';
import { EventSender } from './getEventSender';
import { RaycastSender } from './getRaycastSender';

const creationCondition = (tool: string) =>
  whereEq({ modifier: null, tool, button: MouseButton.LEFT });

const interactions = [
  {
    events: exitEditModeAndDeselect,
    condition: whereEq({
      editMode: true,
      button: MouseButton.LEFT,
      objectHit: false,
    }),
  },
  {
    events: fragmentsSelection,
    condition: whereEq({ editMode: true, button: MouseButton.LEFT }),
  },
  { events: createMarker, condition: creationCondition(Tool.MARKER) },
  { events: createPolygon, condition: creationCondition(Tool.POLYGON) },
  { events: createPolyline, condition: creationCondition(Tool.POLYLINE) },
  { events: createRadius, condition: creationCondition(Tool.RADIUS) },
  { events: createRoad, condition: creationCondition(Tool.ROAD) },
  {
    events: objectsSelection,
    condition: anyPass([
      whereEq({ modifier: Modifier.Q, button: MouseButton.LEFT }),
      whereEq({ modifier: null, tool: Tool.CURSOR, button: MouseButton.LEFT }),
    ]),
  },
];

function* exitEditModeAndDeselect() {
  yield* exitEditMode();
  yield* deselectAllObjects();
}

function* chooseEvent(pointer: Pointer, objectHit: Hit) {
  const currentState = {
    modifier: yield select(getActiveModifier),
    tool: yield select(getSelectedTool),
    button: pointer.button,
    shiftKey: pointer.shiftKey,
    ctrlKey: pointer.ctrlKey,
    objectHit: !!objectHit,
    editMode: yield select(isEditMode),
  };

  const found = interactions.find(({ condition, events }) =>
    condition(currentState),
  );
  return found ? found.events : null;
}

export default function*(sendEvent: EventSender, sendRaycast: RaycastSender) {
  // TODO: takeLatest logic is better?
  while (true) {
    const { payload: pointer } = yield take(types.CLICK);
    const object: Hit = yield* raycastGet('object', pointer, sendRaycast);
    const event = yield* chooseEvent(pointer, object);

    if (!event) continue;

    yield* event(pointer, sendRaycast, sendEvent);
  }
}
