import { PlaybackBehaviour, PlaybackMode } from '../models';

const START_PLAYBACK = 'timeline/START_PLAYBACK';
const STOP_PLAYBACK = 'timeline/STOP_PLAYBACK';
const STOP_DRAG_SELECT = 'timeline/STOP_DRAG_SELECT';
const START_DRAG_SELECT = 'timeline/START_DRAG_SELECT';
const CHANGE_PLAYBACK_SPEED = 'timeline/CHANGE_PLAYBACK_SPEED';
const CHANGE_PLAYBACK_MODE = 'timeline/CHANGE_PLAYBACK_MODE';
const CHANGE_PLAYBACK_BEHAVIOUR = 'timeline/CHANGE_PLAYBACK_BEHAVIOUR';
const SELECT_RANGE = 'timeline/SELECT_RANGE';
const SELECT_SINGLE = 'timeline/SELECT_SINGLE';

export const startPlayback = () => ({
  type: START_PLAYBACK,
});

export const stopPlayback = () => ({
  type: STOP_PLAYBACK,
});

export const stopDragSelect = () => ({
  type: STOP_DRAG_SELECT,
});

export const startDragSelect = () => ({
  type: START_DRAG_SELECT,
});

export const changeSpeed = (speed: number) => ({
  type: CHANGE_PLAYBACK_SPEED,
  payload: speed,
});

export const changeMode = (mode: PlaybackMode) => ({
  type: CHANGE_PLAYBACK_MODE,
  payload: mode,
});

export const changeBehaviour = (behaviour: PlaybackBehaviour) => ({
  type: CHANGE_PLAYBACK_BEHAVIOUR,
  payload: behaviour,
});

export const selectRange = (timeWindow: [number, number]) => ({
  type: SELECT_RANGE,
  payload: timeWindow,
});

export const selectSingle = (single: number) => ({
  type: SELECT_SINGLE,
  payload: single,
});

export const types = {
  START_PLAYBACK,
  STOP_PLAYBACK,
  STOP_DRAG_SELECT,
  START_DRAG_SELECT,
  CHANGE_PLAYBACK_SPEED,
  CHANGE_PLAYBACK_MODE,
  CHANGE_PLAYBACK_BEHAVIOUR,
  SELECT_RANGE,
  SELECT_SINGLE,
};
