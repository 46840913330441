import React from 'react';
import { Box, Flex } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import { Link } from 'react-router-dom';
import { Text } from '@hm/ukie';
import Image from '../Image';
import moscow from '../../styles/images/moscow.jpg';
import london from '../../styles/images/london.jpg';
import ny from '../../styles/images/ny.jpg';
import barcelona from '../../styles/images/barcelona.jpg';
import piter from '../../styles/images/piter.jpg';
import { planIsTrial } from '../../utils/helpers';
import { WelcomeProject } from '../../models';

const images = {
  moscow,
  london,
  ny,
  barcelona,
  piter,
};

const Project = Box.extend`
  border-radius: ${({ theme }) => theme.radius.big};
  overflow: hidden;
`;

export interface Props {
  name: string;
  plan: string;
  daysOfTrialLeft: number;
  projects: WelcomeProject[];
}

const WelcomeSubscribed: React.SFC<Props> = ({
  name,
  plan,
  daysOfTrialLeft,
  projects,
}) => (
  <React.Fragment>
    <F
      id="Welcome.success"
      defaultMessage="{name}, you’ve been successfully signed in!"
      tagName="div"
      values={{ name: <Text.Important>{name}</Text.Important> }}
    />
    <F
      id="Welcome.youStart"
      defaultMessage="You can start working with Chronotope or explore existing samples."
      tagName="div"
    />

    <Box mt={3}>
      <Text.Header>
        {planIsTrial(plan) ? (
          <F id="Plan.trial" defaultMessage="Chronotope Trial" />
        ) : (
          <F id="Plan.basic" defaultMessage="Chronotope Basic" />
        )}
      </Text.Header>
      {planIsTrial(plan) ? (
        <Box my="4px">
          <F
            id="Welcome.daysOfTrial"
            defaultMessage="{daysOfTrialLeft} days of trial remain"
            values={{ daysOfTrialLeft }}
          />
        </Box>
      ) : null}
    </Box>

    <Box mt={3}>
      <Link to="/profile">
        <F id="Welcome.openAccount" defaultMessage="Open your account" />
      </Link>
    </Box>

    <Flex mt={4}>
      {projects.map(({ projectId, environmentName, welcomeImageUrl }) => (
        <Box key={projectId}>
          <F
            id="Welcome.exploreEnv"
            defaultMessage="Explore {env}"
            values={{ env: environmentName }}
          >
            {text => <Text.Important>{text}</Text.Important>}
          </F>
          <Link to={`/projects/${projectId}`} target="_blank">
            <Project mx={2} width={184}>
              <Image src={images[welcomeImageUrl]} />
            </Project>
          </Link>
        </Box>
      ))}
    </Flex>

    <Box mt={2}>
      <Link to="/">
        <F id="Welcome.start" defaultMessage="Start work" />
      </Link>
    </Box>
  </React.Fragment>
);

export default WelcomeSubscribed;
