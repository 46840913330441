import React from 'react';
import { connect } from 'react-redux';
import Overlay from 'react-overlays/lib/Overlay';
import {
  IconButton,
  DropdownContainer,
  DropdownItem,
  CheckboxToggle,
  Input,
} from '@hm/ukie';
import { Box } from 'grid-styled';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { omit, assoc } from 'ramda';

import PlainLink from '../PlainLink';
import { isRetina } from '../../utils/helpers';
import { getIsXrayEnabled, toggleXray } from '../../reducers/dev';
import { AppState } from '../../models';

const featureLink = (params: any, name: string) =>
  '?' +
  stringify(
    params[name] === 'true' ? omit([name], params) : assoc(name, true, params),
  );

const Link = PlainLink.extend`
  &:hover {
    color: inherit;
  }
`;

type OwnProps = RouteComponentProps<any>;

interface ProvidedProps {
  isXrayEnabled: boolean;
  onXrayToggle(): void;
}

type Props = ProvidedProps & OwnProps;

interface State {
  isOpen: boolean;
}

export class DevMenu extends React.Component<Props, State> {
  state = { isOpen: false };
  button: HTMLButtonElement;

  onToggle = () =>
    this.setState({
      isOpen: !this.state.isOpen,
    });

  onColor: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (!e.currentTarget.checkValidity()) return;

    this.props.history.replace({
      search: stringify({
        ...this.parseParams(),
        backgroundColor: e.currentTarget.value,
      }),
    });
  };

  parseParams = () => parse(this.props.location.search.replace('?', ''));

  render() {
    const { onXrayToggle, isXrayEnabled } = this.props;
    const params = this.parseParams();

    return (
      <Box>
        <IconButton
          icon="settings"
          onClick={this.onToggle}
          ref={el => (this.button = el)}
        />
        <Overlay
          show={this.state.isOpen}
          onHide={() => this.setState({ isOpen: false })}
          target={this.button}
          placement="bottom"
          rootClose
        >
          <DropdownContainer>
            <div className="dropdown-menu">
              <DropdownItem onClick={onXrayToggle}>
                <CheckboxToggle checked={isXrayEnabled}>
                  💀 X-Ray
                </CheckboxToggle>
              </DropdownItem>
              {isRetina() ? (
                <DropdownItem>
                  <Link
                    replace
                    to={{
                      search: featureLink(params, 'retinaMode'),
                    }}
                  >
                    <CheckboxToggle checked={params.retinaMode === 'true'}>
                      🖥 Retina
                    </CheckboxToggle>
                  </Link>
                </DropdownItem>
              ) : null}
              <DropdownItem>
                <Link
                  to={{
                    search: featureLink(params, 'metrics_debug'),
                  }}
                >
                  <CheckboxToggle checked={params.metrics_debug === 'true'}>
                    📈 Show metrics debug
                  </CheckboxToggle>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <label>
                  Volumer background (AARRGGBB) #:
                  <Input
                    style={{ width: 56 }}
                    minLength={8}
                    maxLength={8}
                    defaultValue={params.backgroundColor}
                    onBlur={this.onColor}
                  />
                </label>
              </DropdownItem>
            </div>
          </DropdownContainer>
        </Overlay>
      </Box>
    );
  }
}

export default withRouter(
  connect(
    (state: AppState, props: OwnProps) => ({
      isXrayEnabled: getIsXrayEnabled(state),
    }),
    { onXrayToggle: toggleXray },
  )(DevMenu),
);
