import { connect } from 'react-redux';

import { subscribe, toggleOverage } from '../../reducers/billing';

import Plan from './Plan';
import { AppState } from '../../models';

export default connect(
  (state: AppState) => ({
    billingInfo: state.billing,
  }),
  {
    toggleOverage,
    onSubscribe: subscribe,
  },
)(Plan);
