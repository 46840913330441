import { connect } from 'react-redux';

import { getIsMinimized } from '../../selectors/inspector';
import { getSelectedObjects } from '../../selectors/selectedObjects';
import { AppState } from '../../models';

import Inspector from './Inspector';

const mapStateToProps = (state: AppState) => ({
  isMinimized: getIsMinimized(state),
  objects: getSelectedObjects(state),
});

export default connect(mapStateToProps)(Inspector);
