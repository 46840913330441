import React from 'react';
import { FormattedMessage as F, FormattedTime } from 'react-intl';
import { Text } from '@hm/ukie';
import { Box } from 'grid-styled';
import { fullDateTime } from '../../utils/formats';

interface Props {
  unsubscribeDate: string;
  subscriptionActive: boolean;
}

// FIXME: check when to show what and make everything more comprehendable
const UnsubscribeWarning: React.SFC<Props> = ({
  unsubscribeDate,
  subscriptionActive,
}) =>
  unsubscribeDate ? (
    <React.Fragment>
      <F
        id="Plan.unsubscribeRequest"
        defaultMessage="You’d sent the unsubscribe request. The access to the projects and data will be terminated"
      >
        {text => (
          <Text color="error" lineHeight="text">
            {text}
          </Text>
        )}
      </F>
      <Box my={1}>
        <Text fontWeight="semibold" color="error">
          <FormattedTime value={unsubscribeDate} {...fullDateTime} />
        </Text>
      </Box>
    </React.Fragment>
  ) : !subscriptionActive ? (
    <F
      id="Plan.subscriptionActiveFalse"
      defaultMessage="You are not subscribed to plan for now. Let’s start to use Chronotope and explore data in all it’s glory!"
    />
  ) : null;

export default UnsubscribeWarning;
