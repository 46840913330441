import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'NewApproximatedProject.create',
    defaultMessage: 'Create project',
  },
  edit: {
    id: 'EditApproximatedProject.create',
    defaultMessage: 'Save edits',
  },
  minutes: {
    id: 'NewApproximatedProject.minutes',
    defaultMessage: 'Minutes',
  },
  hours: {
    id: 'NewApproximatedProject.hours',
    defaultMessage: 'Hours',
  },
  filter: {
    id: 'NewApproximatedProject.filter',
    defaultMessage: 'Day range filter',
  },
  dayTitle: {
    id: 'NewApproximatedProject.dayTitle',
    defaultMessage: 'Approximated day',
  },
  startTime: {
    id: 'NewApproximatedProject.startTime',
    defaultMessage: 'Start time',
  },
  selectAllApproximated: {
    id: 'Calendar.selectAllApproximated',
    defaultMessage:
      'Select the whole available time interval.',
  },
});
