import { connect } from 'react-redux';
import Compass from '../components/Compass';
import { resetCamera } from '../actions/compass';
import { changeCameraMode } from '../actions/projects';
import { getCameraMode } from '../selectors/project';
import { getCompassTilt, getCompassRotate } from '../selectors/compass';
import { AppState } from '../models';

const mapStateToProps = (state: AppState) => ({
  tilt: getCompassTilt(state),
  rotate: getCompassRotate(state),
  mode: getCameraMode(state),
});

const mapDispatchToProps = {
  changeMode: changeCameraMode,
  resetCamera,
};

export default connect(mapStateToProps, mapDispatchToProps)(Compass);
