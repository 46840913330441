import { actions } from 'react-redux-form';
import { replace } from 'react-router-redux';
import { FSA } from 'flux-standard-action';
import { compose, pick, omit, evolve } from 'ramda';
import {
  takeLatest,
  takeEvery,
  put,
  take,
  fork,
  select,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { pend, fulfill } from '../utils/promise';
import {
  types,
  getUpload,
  getLayerUploadState,
  getLayerUpload,
} from '../reducers/layerUploads';
import { fetchEnvironments } from '../actions/environments';
import { fetchBaseLayers } from '../reducers/baseLayers';
import { willCreateEnvironment } from '../reducers/forms';

/**
 * Saga for Import functionality. Handles data dependencies and layer form nuances
 */
export default function*() {
  // Listen to init action. It’s telling us to start our saga
  yield takeLatest<FSA<string>>(types.INIT, function*({ payload }) {
    // Fetch needed layer object
    try {
      yield put.resolve(getUpload(payload));
    } catch {
      yield put(replace('/not-found'));
    }

    // Check if it’s still processing. If so wait for it to finish
    const layerState = yield select(getLayerUploadState, payload);

    if (layerState === 'processing') {
      // poll till it is not in processing state no more
      while ((yield select(getLayerUploadState, payload)) === 'processing') {
        yield delay(2000 + Math.ceil(Math.random() * 500));
        yield put.resolve(getUpload(payload));
      }
    }

    // We also need environments for this form
    yield put(fetchEnvironments());

    // Transform layer import object and put it into form state
    yield put.resolve(actions.merge(
      'layerImport',
      transformLayerForForm(yield select(getLayerUpload, payload)),
    ) as any);

    // Start looking at resets
    yield fork(reset);

    yield take(fulfill(types.PUBLISH_LAYER));

    // refetch fresh layers and environments
    yield put(fetchBaseLayers({ noCache: true }));
    if (yield select(willCreateEnvironment)) {
      yield put(fetchEnvironments(true));
    }
  });
}

/** Transforms layer import object into form state */
const transformLayerForForm = compose(
  evolve({
    selectedTimeInterval: (interval: string) =>
      interval.split('/').map(d => new Date(d)),
  }),
  omit(['points', 'lines', 'previewHeader', 'previewData']),
);

/** Form reset feature */
function* reset() {
  yield takeEvery(pend(types.RESET), function*(action: any) {
    const { payload } = yield take(fulfill(types.RESET));
    yield put.resolve(actions.merge(
      'layerImport',
      pick(
        [
          'selectedTopLat',
          'selectedBottomLat',
          'selectedLeftLon',
          'selectedRightLon',
          'cellSize',
        ],
        payload,
      ),
      // NOTE: put.resolve doesn’t accpet thunks but it should
    ) as any);
  });
}
