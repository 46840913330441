export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const showModal = (currentModal: string) => ({
  type: SHOW_MODAL,
  payload: currentModal,
});

export const showNewLayer = () => showModal('NewMetricModal');
export const confirmDeleteProject = () => showModal('DeleteProjectDialog');
export const showCopyProject = () => showModal('CopyDialog');
export const editProject = () => showModal('EditProject');

export const hideModal = () => ({ type: HIDE_MODAL });

export const types = {
  HIDE_MODAL,
  SHOW_MODAL,
};
