import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';

import { AppState } from '../../models';
import { getCurrentWorkspaceId } from '../../selectors/user';
import {
  selectors,
  fetchWorkspaces,
  selectWorkspace,
} from '../../reducers/workspaces';
import WorkspaceSelector, { Props } from './WorkspaceSelector';

interface ProvidedProps extends Props {
  fetchWorkspaces(): Promise<any>;
}

export class WorkspaceSelectorContainer extends React.Component<ProvidedProps> {
  componentDidMount() {
    if (isEmpty(this.props.workspaces)) this.props.fetchWorkspaces();
  }

  render() {
    const { fetchWorkspaces: _, ...props } = this.props;

    if (props.workspaces.length < 2) return null;

    return <WorkspaceSelector {...props} />;
  }
}

export default connect(
  (state: AppState) => ({
    workspaces: selectors.getList(state),
    currentWorkspaceId: getCurrentWorkspaceId(state),
  }),
  {
    fetchWorkspaces,
    onSelect: selectWorkspace,
  },
)(WorkspaceSelectorContainer);
