/**
 * Set of helper utils to use with [`redux-promise-middleware`](https://github.com/pburtchaell/redux-promise-middleware).
 *
 * Instead of writing interpolated string in reducers e.g.
 *
 * ```
 * case `${ACTION}_REJECTED`;
 * ```
 *
 * you can just use `fulfill(ACTION)`, `reject(ACTION)`, etc.
 */

export const fulfill = (actionType: string) => `${actionType}_FULFILLED`;
export const reject = (actionType: string) => `${actionType}_REJECTED`;
export const pend = (actionType: string) => `${actionType}_PENDING`;
