import React from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  checkToken as checkTokenAction,
  completeSignUp as completeSignUpAction,
} from '../../actions/user';
import { getIsLoggedIn } from '../../selectors/user';
import SignUpConfirmation from './SignUpConfirmation';

interface PromiseResponse {
  value: { withCard: boolean; name: string };
}

interface Props {
  checkToken(signupToken: string): Promise<PromiseResponse>;
  isLoggedIn: boolean;
  signupToken: string;
  completeSignUp({ signupToken: string });
}

interface State {
  withCard: boolean;
  name: string;
}

class SignUpConfirmationContainer extends React.Component<Props, State> {
  state = {
    withCard: null,
    name: '',
  };

  componentDidMount() {
    const { checkToken, signupToken, completeSignUp } = this.props;

    checkToken(signupToken).then(response => {
      const { withCard, name } = response.value;
      this.setState({ withCard, name });
      if (withCard === false) {
        completeSignUp({ signupToken });
      }
    });
  }

  render() {
    const { withCard, name } = this.state;
    const { isLoggedIn, completeSignUp, signupToken } = this.props;

    if (isLoggedIn) return <Redirect to="/welcome" />;

    if (!withCard) return null;

    return (
      <SignUpConfirmation
        name={name}
        signupToken={signupToken}
        completeSignUp={completeSignUp}
      />
    );
  }
}

export default connect(
  (state, { location }) => ({
    isLoggedIn: getIsLoggedIn(state),
    signupToken: qs.parse(location.search).token,
  }),
  {
    checkToken: checkTokenAction,
    completeSignUp: completeSignUpAction,
  },
)(SignUpConfirmationContainer);
