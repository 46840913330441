import { volumer } from '@hm/volumer-api';
import { Pointer, Resolution } from '../models';

const CONNECT = 'volumer/CONNECT';
const DISCONNECT = 'volumer/DISCONNECT';

const WHEEL = 'volumer/WHEEL';
const SET_FRAME = 'volumer/SET_FRAME';

const POINTER_DOWN = 'volumer/POINTER_DOWN';
const POINTER_MOVE = 'volumer/POINTER_MOVE';
const POINTER_UP = 'volumer/POINTER_UP';
const CLICK = 'volumer/CLICK';
const DOUBLE_CLICK = 'volumer/DOUBLE_CLICK';

const RESIZE = 'volumer/RESIZE';
const UPDATE_FRAME_RESOLUTION = 'volumer/UPDATE_FRAME_RESOLUTION';

const UPDATE_QUALITY_START = 'volumer/UPDATE_QUALITY_START';
const UPDATE_QUALITY_DRAG = 'volumer/UPDATE_QUALITY_DRAG';
const UPDATE_QUALITY_STOP = 'volumer/UPDATE_QUALITY_STOP';

const KEY_DOWN = 'volumer/KEY_DOWN';
const KEY_UP = 'volumer/KEY_UP';
const ENABLE_MODIFIER = 'volumer/ENABLE_MODIFIER';
const DISABLE_MODIFIER = 'volumer/DISABLE_MODIFIER';

const TAKE_SCREENSHOT = 'volumer/TAKE_SCREENSHOT';

export const connect = () => ({ type: CONNECT });

export const disconnect = () => ({ type: DISCONNECT });

export const setFrame = (frame: string) => ({
  type: SET_FRAME,
  payload: frame,
});

export const wheel = (delta: number, pointer: Pointer) => ({
  type: WHEEL,
  payload: { delta, pointer },
});

export const pointerDown = (pointer: Pointer) => ({
  type: POINTER_DOWN,
  payload: pointer,
});

export const pointerMove = (pointer: Pointer) => ({
  type: POINTER_MOVE,
  payload: pointer,
  meta: {
    throttle: { wait: 16, trailing: false },
  },
});

export const pointerUp = (pointer: Pointer) => ({
  type: POINTER_UP,
  payload: pointer,
});

export const click = (pointer: Pointer) => ({
  type: CLICK,
  payload: pointer,
});

export const doubleClick = (pointer: Pointer) => ({
  type: DOUBLE_CLICK,
  payload: pointer,
});

export const resize = (viewport: Resolution) => ({
  type: RESIZE,
  payload: viewport,
});

export const updateFrameResolution = (resolution: Resolution) => ({
  type: UPDATE_FRAME_RESOLUTION,
  payload: resolution,
});

export const updateQualityStart = (
  message: volumer.event.LowresQualityDragStart$Properties,
) => ({
  type: UPDATE_QUALITY_START,
  payload: { message },
});

export const updateQualityDrag = (
  message: volumer.event.LowresQualityDrag$Properties,
) => ({
  type: UPDATE_QUALITY_DRAG,
  payload: { message },
});

export const updateQualityStop = (
  message: volumer.event.LowresQualityDragStop$Properties,
) => ({
  type: UPDATE_QUALITY_STOP,
  payload: { message },
});

export const keyDown = (key: string) => ({
  type: KEY_DOWN,
  payload: key,
});

export const keyUp = (key: string) => ({
  type: KEY_UP,
  payload: key,
});

export const enableModifier = (modifier: string) => ({
  type: ENABLE_MODIFIER,
  payload: modifier,
});

export const disableModifier = (modifier: string) => ({
  type: DISABLE_MODIFIER,
  payload: modifier,
});

export const takeScreenshot = () => ({
  type: TAKE_SCREENSHOT,
});

export const types = {
  CONNECT,
  DISCONNECT,
  WHEEL,
  SET_FRAME,
  POINTER_DOWN,
  POINTER_MOVE,
  POINTER_UP,
  CLICK,
  DOUBLE_CLICK,
  RESIZE,
  UPDATE_FRAME_RESOLUTION,
  UPDATE_QUALITY_START,
  UPDATE_QUALITY_DRAG,
  UPDATE_QUALITY_STOP,
  KEY_DOWN,
  KEY_UP,
  ENABLE_MODIFIER,
  DISABLE_MODIFIER,
  TAKE_SCREENSHOT,
};
