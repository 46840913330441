import React from 'react';
import nanoid from 'nanoid';
import { ScaleLinear } from 'd3-scale';
import { clamp, range, apply } from 'ramda';
import styled from 'styled-components';
import { colors } from '@hm/ukie';

import Brush from './Brush';
import Tooltip from './FilledHistogramTooltip';
import HistogramBars from './HistogramBars';
import Mark from './Mark';
import Grid from './Grid';
import { Point } from '../../reducers/gradients';

import { bisectX } from '../../utils/layers';
import { absoluteShort } from '../../utils/numberFormats';
import { getBrushScale, getBinScale } from '../../utils/histogram';
import { MinMax, HistogramData } from '../../models';

const Background = styled.rect`
  fill: ${({ theme }) => theme.colors.volumer};
`;

const INITIAL_DATA_STYLE = {
  fill: colors.grey6,
  opacity: '0.6',
  shapeRendering: 'crispEdges',
};

const CURRENT_DATA_STYLE = {
  shapeRendering: 'crispEdges',
};

interface Props {
  currentBins: HistogramData[];
  initialBins: HistogramData[];
  gradientPoints: Point[];
  borders: number[];
  ticks: number[];
  selected: MinMax;
  width: number;
  height: number;
  anomalies?: any[];
  transform?: string;
  yScale: ScaleLinear<number, number>;
  saveBrushLabelPositions(positions: ClientRect[]);
  onBrush?(selected: MinMax);
  onBrushStart?();
  onBrushEnd?();
}

interface State {
  tooltipXPos?: number;
  isBrushing?: boolean;
}

/**
 * Expanded view of filled layer histogram
 */
export default class FilledHistogramFull extends React.Component<Props, State> {
  static defaultProps = {
    anomalies: [],
  };

  canvas: SVGRectElement;

  clipPath = nanoid();

  state = {
    tooltipXPos: null,
    isBrushing: false,
  };

  onMouseMove = (e: React.MouseEvent<SVGGElement>) => {
    // FIXME: a less than optimal solution
    const { left } = this.canvas.getBoundingClientRect();
    const { width } = this.props;
    const tooltipXPos = clamp(0, width, e.clientX - left);

    this.setState({ tooltipXPos });
  };

  onMouseLeave = (e: React.MouseEvent<SVGGElement>) => {
    this.setState({ tooltipXPos: null });
  };

  tooltipColor(x: number) {
    if (this.props.anomalies.some(anomaly => anomaly.x === x))
      return colors.error;
    return colors.grey6;
  }

  onBrushStart = () => {
    this.setState({ isBrushing: true });
    this.props.onBrushStart();
  };

  onBrush = (extent: [number, number]) => {
    const { onBrush } = this.props;
    if (onBrush) onBrush({ min: extent[0], max: extent[1] });
  };

  onBrushEnd = () => {
    this.setState({ isBrushing: false });
    this.props.onBrushEnd();
  };

  getYTicks = (start: number, end: number) => {
    const step = (end - start) / 3;
    return range(0, 4).map(i => start + step * i);
  };

  render() {
    const {
      currentBins,
      initialBins,
      ticks,
      selected: { min, max },
      width,
      height,
      anomalies,
      transform,
      yScale,
      gradientPoints,
      borders,
    } = this.props;

    const { tooltipXPos } = this.state;

    const barProps = { width, height, yScale };

    const brushScale = getBrushScale(width);
    const binScale = getBinScale(currentBins.length, width);

    const hoveredBin =
      tooltipXPos !== null
        ? currentBins[Math.floor(tooltipXPos / binScale.step())]
        : null;

    return (
      <g transform={transform}>
        <g onMouseMove={this.onMouseMove} onMouseLeave={this.onMouseLeave}>
          <Background
            width={width}
            height={height}
            innerRef={el => (this.canvas = el as SVGRectElement)}
          />
          <Grid
            xTicks={ticks}
            yTicks={apply(this.getYTicks, yScale.domain())}
            width={width}
            height={height}
            binsCount={borders.length}
            yScale={yScale}
          />
          <HistogramBars
            bins={initialBins}
            style={INITIAL_DATA_STYLE}
            {...barProps}
          />
          <HistogramBars
            bins={currentBins}
            style={INITIAL_DATA_STYLE}
            {...barProps}
          />
          <HistogramBars
            bins={currentBins}
            gradientPoints={gradientPoints}
            style={CURRENT_DATA_STYLE}
            clipPath={`url(#selectedPath.${this.clipPath})`}
            {...barProps}
          />
          {anomalies.map(anomaly => (
            <Mark
              key={'anomaly' + anomaly.x}
              x={binScale(bisectX(currentBins, anomaly.x).x)}
              y={yScale(bisectX(currentBins, anomaly.x).y)}
              height={height}
              color={colors.error}
            />
          ))}
          {this.props.onBrush ? (
            <Brush
              width={width}
              borders={borders}
              minExtent={1 / currentBins.length}
              height={height}
              selected={[min, max]}
              boundaries={this.canvas}
              saveLabelPositions={this.props.saveBrushLabelPositions}
              onStart={this.onBrushStart}
              onUpdate={this.onBrush}
              onStop={this.onBrushEnd}
            />
          ) : null}
        </g>
        {hoveredBin && !this.state.isBrushing ? (
          <Tooltip
            x={this.state.tooltipXPos}
            y={height - yScale(hoveredBin.y)}
            height={height}
            histogramWidth={width}
            xLabel={absoluteShort(hoveredBin.x)}
            yLabel={absoluteShort(hoveredBin.y)}
            color={this.tooltipColor(hoveredBin.x)}
          />
        ) : null}
        <defs>
          <clipPath id={`selectedPath.${this.clipPath}`}>
            <rect
              x={brushScale(min)}
              width={brushScale(max) - brushScale(min)}
              height={height}
            />
          </clipPath>
        </defs>
      </g>
    );
  }
}
