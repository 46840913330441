import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import { AutoSizer, List, ListProps, ListRowRenderer } from 'react-virtualized';
import { Box } from 'grid-styled';

import PreciseSelector from '../../components/PreciseSelector';
import DaysSelector from '../../components/DaysSelector';
import MonthsSelector from '../../components/MonthsSelector';
import YearsSelector from '../../components/YearsSelector';
import ScrollableList from './ScrollableList';
import { weeksInMonth, weeksInYear3Col } from '../../utils/time';

import { ZoomLevel } from '../../models';
import { SliceEventProps } from '../withSliceSelection';

export interface SelectorProps extends SliceEventProps {
  availableIntervals: DateRange[];
  selectedIntervals: DateRange[];
  filteredOutIntervals?: DateRange[];
  filterPreview?: DateRange[];
  sliceDuration: string;
}

const components = {
  precise: PreciseSelector,
  day: DaysSelector,
  month: MonthsSelector,
  year: YearsSelector,
};

export interface OwnProps extends Partial<ListProps> {
  className?: string;
  listRef?(el: List): void;
  scrollableRef?(el: any): void; // TODO: Scrollable interface
  getRowHeight(height: number): void;
}

// FIXME: when extending SelectorProps emitted type breakes conntainer because
// filteredOutIntervals and filterPreview is optional in SelectorProps and
// required here
interface ProvidedProps extends SliceEventProps {
  dates: moment.Moment[];
  zoomLevel: ZoomLevel;
  sliceDuration: string;
  selectedIntervals: DateRange[];
  availableIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
  filterPreview: DateRange[];
}

type Props = ProvidedProps & OwnProps;

export default class Selector extends React.Component<Props, never> {
  rowRenderer: ListRowRenderer = ({ key, index, style, parent, isVisible }) => {
    const {
      dates,
      zoomLevel,
      selectedIntervals,
      availableIntervals,
      filteredOutIntervals,
      filterPreview,
      sliceDuration,
      onSelection,
      onSelectionEnd,
      onSelectionClick,
      getRowHeight,
    } = this.props;

    const date = dates[index];

    const SelectorComponent = components[zoomLevel] as React.ComponentClass<
      any
    >;

    return (
      <SelectorComponent
        key={key}
        date={date}
        style={style}
        selectedIntervals={selectedIntervals}
        availableIntervals={availableIntervals}
        filteredOutIntervals={filteredOutIntervals}
        filterPreview={filterPreview}
        sliceDuration={sliceDuration}
        onSelection={onSelection}
        onSelectionEnd={onSelectionEnd}
        onSelectionClick={onSelectionClick}
        getRowHeight={getRowHeight}
      />
    );
  };

  /**
   * HACK: super bad hardcoded sizes, WILL BREAK if any of the components change
   * Why? This allows measureAllRows to work correctly and clicks on Navigator
   * to scroll the Selector exactly where we want it to scroll
   * TODO: find a way to do this reliably
   */
  getComponentHeights = () => ({
    precise: 237,
    day: ({ index }: { index: number }) => {
      const base = 99;
      const week = 36 + 48;
      return base + week * weeksInMonth(this.props.dates[index]);
    },
    month: ({ index }: { index: number }) => {
      const base = 404;
      const week = 30;
      return base + week * weeksInYear3Col(this.props.dates[index]);
    },
    year: 148,
  });

  render() {
    const {
      dates,
      availableIntervals,
      filterPreview,
      filteredOutIntervals,
      selectedIntervals,
      zoomLevel,
      ...props
    } = this.props;

    return (
      <Box flex="1">
        <AutoSizer>
          {({ width, height }) => (
            <ScrollableList
              width={width}
              height={height}
              rowHeight={this.getComponentHeights()[zoomLevel]}
              rowCount={dates.length}
              rowRenderer={this.rowRenderer}
              overscanRowCount={3}
              scrollToAlignment={zoomLevel === 'precise' ? 'center' : 'start'}
              style={{
                padding: '40px',
                outline: 'none',
              }}
              {...props}
            />
          )}
        </AutoSizer>
      </Box>
    );
  }
}
