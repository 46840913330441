import { defineMessages } from 'react-intl';

export default defineMessages({
  startTime: {
    id: 'Calendar.startTime',
    defaultMessage: 'Start time',
  },
  startTimeEmpty: {
    id: 'Calendar.startTimeEmpty',
    defaultMessage:
      'Choosing start time will be available after at least one time interval is selected',
  },
  startAtMonthDay: {
    id: 'Calendar.startAtMonthDay',
    defaultMessage: 'of Month',
  },
  selectAllLayer: {
    id: 'Calendar.selectAllMetric',
    defaultMessage:
      'Select the whole time interval available for the current Metric.',
  },
  selectAllRegular: {
    id: 'Calendar.selectAllRegular',
    defaultMessage:
      'Select the whole time interval available for all Metrics in this Environment.',
  },
  create: {
    id: 'Calendar.createProject',
    defaultMessage: 'Create project',
  },
  update: {
    id: 'Calendar.updateProject',
    defaultMessage: 'Save edits',
  },
  availableTimeIntervals: {
    id: 'Calendar.availableTimeIntervals',
    defaultMessage: 'Layers’ available time intervals',
  },
});

export const filterGroups = defineMessages({
  base: {
    id: 'Calendar.filters.groups.base',
    defaultMessage: 'Base filters',
  },
  environment: {
    id: 'Calendar.filters.groups.environment',
    defaultMessage: 'Metric filters',
  },
  user: {
    id: 'Calendar.filters.groups.user',
    defaultMessage: 'User filters',
  },
});
