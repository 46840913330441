import { take, put, select } from 'redux-saga/effects';
import { updateObject } from '../../actions/objects';
import { types as volumerTypes } from '../../actions/volumer';
import {
  getObjectInEditMode,
  getSelectedObjects,
} from '../../selectors/selectedObjects';
import { getSelectedTool } from '../../selectors/tool';
import { getActiveModifier } from '../../selectors/volumer';
import { Tool } from '../../constants/tools';
import { Modifier } from '../../constants/volumer';
import raycastGet from './raycastGet';
import { RaycastSender } from './getRaycastSender';
import { MapObject } from '../../reducers/selectedObjects';

export default function*(sendRaycast: RaycastSender) {
  while (true) {
    const { payload: pointer } = yield take(volumerTypes.DOUBLE_CLICK);
    const canEnter = yield* checkCanEnter();
    if (!canEnter) continue;

    const objectHit = yield raycastGet('object', pointer, sendRaycast);
    const selectedObjects: MapObject[] = yield select(getSelectedObjects);

    if (!objectHit || !selectedObjects.some(({ id }) => id === objectHit.id))
      continue;
    if (selectedObjects.some(({ imported }) => imported === true)) continue;

    yield put(updateObject(objectHit.id, { editMode: true }));
  }
}

export function* exitEditMode() {
  const editModeObject = yield select(getObjectInEditMode);
  yield put(updateObject(editModeObject.id, { editMode: false }));
}

function* checkCanEnter() {
  const tool = yield select(getSelectedTool);
  const modifier = yield select(getActiveModifier);
  return tool === Tool.CURSOR || modifier === Modifier.Q;
}
