import React from 'react';
import styled from 'styled-components';
import {
  FormattedMessage as F,
  injectIntl,
  InjectedIntlProps,
  defineMessages,
} from 'react-intl';
import { Box, Flex } from 'grid-styled';
import { Text, Icon } from '@hm/ukie';
import Row from './Row';
import { GeoPoint, GeoBounds as IGeoBounds } from '../../models';

const PaddedIcon = styled(Icon)`
  display: block;
  margin: 8px 0 8px 0;
`;

const FlexFullHeight = Flex.extend`
  height: 100%;
`;

const messages = defineMessages({
  latitude: {
    id: 'Environment.latitude',
    defaultMessage: 'Latitude',
  },
  longitude: {
    id: 'Environment.longitude',
    defaultMessage: 'Longitude',
  },
});

const LL: React.SFC<GeoPoint & InjectedIntlProps> = ({ lat, lon, intl }) => (
  <Box>
    <Text.Important title={intl.formatMessage(messages.latitude)}>
      {lat}
    </Text.Important>
    {', '}
    <Text.Important title={intl.formatMessage(messages.longitude)}>
      {lon}
    </Text.Important>
  </Box>
);

const LatLon = injectIntl(LL);

const GeoBounds: React.SFC<IGeoBounds> = ({
  cornerTopLeft,
  cornerBottomRight,
}) => (
  <Row
    nameComponent={
      <div>
        <F id="Explorer/Environments.boundingBox" defaultMessage="Bounding box">
          {text => <span>{text}</span>}
        </F>
        <PaddedIcon name="geoBounds" />
      </div>
    }
  >
    <FlexFullHeight flexDirection="column" justify="flex-end">
      <LatLon lat={cornerTopLeft.lat} lon={cornerBottomRight.lon} />
      <LatLon lat={cornerBottomRight.lat} lon={cornerTopLeft.lon} />
    </FlexFullHeight>
  </Row>
);

export default GeoBounds;
