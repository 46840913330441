import styled from 'styled-components';
import { Link } from 'react-router-dom';

/**
 * Link component with stripped styling. Use it when you need wrap something
 * unusual in Link but do not apply `a` styling to it (tab, picture, etc.)
 */
export default styled(Link)`
  border: none;
  text-decoration: none;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.defaultColor};
  }
`;
