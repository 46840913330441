import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { splitEvery } from 'ramda';
import { compose } from 'recompose';

import { someContains } from '../../utils/time';
import Title from '../Title';
import Month from './Month';

import shouldCalendaraUpdate from '../shouldCalendarUpdate';
import withPrettySelectedIntervals from '../withPrettySelectedIntervals';

const Container = styled.div`
  padding-bottom: 44px;

  &:only-child {
    padding-top: 40px;
  }
`;

const YearTitle = styled(Title)`
  margin-bottom: 20px;
`;

export interface OwnProps {
  date: moment.Moment;
  style?: any;
}

export interface ConnectedProps {
  availableIntervals: DateRange[];
  selectedIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
  onClick(date: moment.Moment, innerRow?: number): void;
}

type Props = OwnProps & ConnectedProps;

export class MonthsNavigator extends React.Component<Props, never> {
  render() {
    const {
      date,
      selectedIntervals,
      availableIntervals,
      filteredOutIntervals,
      onClick,
    } = this.props;

    const rows = splitEvery(
      3,
      Array.from(
        new DateRange(
          date.clone().startOf('year'),
          date.clone().endOf('year'),
        ).by('month'),
      ),
    );

    return (
      <Container style={this.props.style}>
        <YearTitle>{date.format('YYYY')}</YearTitle>
        <Flex flexDirection="column">
          {rows.map((row, r) => (
            <Flex justify="space-between" my={1} key={r}>
              {row.map(month => (
                <Month
                  date={month}
                  key={+month}
                  isSelected={someContains(selectedIntervals, month)}
                  isDisabled={
                    !someContains(availableIntervals, month) ||
                    someContains(filteredOutIntervals, month)
                  }
                  onClick={() => onClick(month, r)}
                />
              ))}
            </Flex>
          ))}
        </Flex>
      </Container>
    );
  }
}

export default compose<Props, Props>(
  withPrettySelectedIntervals('P1M'),
  shouldCalendaraUpdate('year'),
)(MonthsNavigator);
