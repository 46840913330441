import React from 'react';
import styled from 'styled-components';
import { Moment, Duration } from 'moment';
import { TickType as Type } from '../../constants/timeline';
import { getFullLabel, getShortLabel, getApproximatedLabel } from './labels';
import { ApproximationType } from '../../reducers/currentProject';

interface TextProps extends React.SVGAttributes<SVGTextElement> {
  getRef(ref: SVGTextElement);
}

const Text: React.SFC<TextProps> = ({ getRef, ...rest }) => (
  <text ref={getRef} {...rest} />
);

const PrimaryText = styled(Text)`
  font-size: 9px;
`;

const SecondaryText = styled.text`
  font-size: 9px;
  fill: #b3afae;
`;

const getLabel = (
  type: string,
  value: Moment,
  duration: Duration,
  approximationType: ApproximationType,
) =>
  approximationType
    ? getApproximatedLabel(value, approximationType)
    : [Type.RANGE_START, Type.RANGE_END, Type.INTERVAL_START].includes(type)
      ? getFullLabel(value, duration)
      : getShortLabel(value, duration);

const labelDx = {
  [Type.REGULAR]: 2,
  [Type.DIVIDER]: 2,
  [Type.SUPER_DIVIDER]: 2,
  [Type.INTERVAL_START]: 4,
  [Type.RANGE_END]: 4,
  [Type.RANGE_START]: 4,
};

const FIRST_LINE = 1.8;
const LINE_HEIGHT = 1.4;

interface Props extends React.SVGAttributes<SVGGElement> {
  tickValue: Moment;
  sliceDuration: Duration;
  tickType: string;
  approximationType: ApproximationType;
  getRef?(node: SVGElement);
}

const TickLabel: React.SFC<Props> = props => {
  const {
    tickType,
    tickValue,
    sliceDuration,
    approximationType,
    getRef,
    children,
    ...rest
  } = props;

  const [primaryLine, ...secondaryLines] = getLabel(
    tickType,
    tickValue,
    sliceDuration,
    approximationType,
  );

  const dx = `${labelDx[tickType]}px`;

  return (
    <g {...rest}>
      {secondaryLines.map((line, i) => (
        <SecondaryText
          key={line}
          dx={dx}
          dy={`${-(FIRST_LINE + LINE_HEIGHT * i)}em`}
        >
          {line}
        </SecondaryText>
      ))}
      <PrimaryText dx={dx} dy="-0.4em" getRef={getRef}>
        {primaryLine}
      </PrimaryText>
    </g>
  );
};

export default TickLabel;
