import React from 'react';
import styled from 'styled-components';
import nanoid from 'nanoid';
import { colors, SVGShadowFilter } from '@hm/ukie';

import SVGTooltip from '../SVGTooltip';
import { absoluteShort } from '../../utils/numberFormats';

const Background = styled.rect`
  fill: ${({ theme }) => theme.colors.volumer};
  shape-rendering: crispEdges;
`;

const Bar = styled.rect`
  fill: ${props => props.color};
  shape-rendering: crispEdges;
`;

const Underline = styled.line`
  stroke: ${colors.grey5};
  stroke-width: 1;
  shape-rendering: crispEdges;
`;

interface Props {
  width: number;
  height: number;
  dataWidth: number;
  // XXX: kind of strange to pass is data height, but passing a
  // scale and a fake 1D value to calculate it inside feels wrong as well
  dataHeight: number;
  value: number;
  color: string;
  transform?: string;
}

interface State {
  showTooltip: boolean;
}

export default class VoidHistogramFull extends React.Component<Props, State> {
  state = {
    showTooltip: false,
  };

  onMouseEnter = () => this.setState({ showTooltip: true });

  onMouseLeave = () => this.setState({ showTooltip: false });

  render() {
    const {
      width,
      dataWidth,
      dataHeight,
      height,
      color,
      transform,
    } = this.props;

    const padding = (width - dataWidth) / 2;
    const tooltipShadow = nanoid();

    return (
      <g
        transform={transform}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Background width={width} height={height} />
        <Underline x1={0} x2={width} y1={height} y2={height} />
        <Bar
          color={color}
          width={dataWidth}
          height={dataHeight}
          transform={`translate(${padding}, ${height - dataHeight})`}
        />
        {this.state.showTooltip ? (
          <SVGTooltip
            text={absoluteShort(this.props.value)}
            transform="translate(4, 2)"
            filter={`url(#${tooltipShadow})`}
          />
        ) : null}
        <SVGShadowFilter id={tooltipShadow} blur={1} dx={-1} dy={1} />
      </g>
    );
  }
}
