import { createSelector } from 'reselect';
import { pickBy, compose, equals } from 'ramda';
import { AppState } from '../models';

export const getSidebarTab = (state: AppState) => state.sidebar.tab;
export const getPins = (state: AppState) => state.sidebar.pins;
export const getActivePinsList = createSelector(
  getPins,
  compose(Object.keys, pickBy(equals(true))),
);
