import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { push } from 'react-router-redux';

import moment from 'moment';
import { API_REQUEST } from '../api/rest';
import {
  getAccessToken,
  getRefreshToken,
  expiresAt,
  isTokenFresh,
  getAuthHeader,
} from '../utils/auth';
import { refreshAccessToken, logOut } from '../actions/user';

import { AppState } from '../models';

/*
 * TODO: make this a saga, that can be yielded in other sagas without
 * dispatching actions. Like this:
 * ```
 * const response = yield* apiRequest(requestParams);
 * ```
 *
 * TODO: Make another thin saga to support sending API requests via actions
 well. Like this
 * ```
 * const action = yield take('*');
 * if (isApiAction(action)) yield* apiRequest(action.requestParams);
 * ```
 */

const CHECK_BEFORE_MS = 60000;

const isApiAction = (action: any) =>
  action.payload && action.payload[API_REQUEST];

const authenticateRequest = (options: any, token: string) => ({
  ...options,
  headers: {
    Authorization: getAuthHeader(),
    ...options.headers,
  },
});

const payloadToFetch = (baseUrl: string) => (
  action: any,
  token: string,
  authenticate = true,
) => {
  const { [API_REQUEST]: request, ...payload } = action.payload;
  const { url, options } = request;

  const fetchOptions =
    token && authenticate ? authenticateRequest(options, token) : options;

  const promise = fetch(`${baseUrl}${url}`, fetchOptions)
    .then(parseResponse)
    .then(action.meta && action.meta.onSuccess);

  // preserve payload if we see there is optimistic update data
  if (payload.data) {
    return { promise, ...payload };
  } else {
    return promise;
  }
};

const parseResponse = (response: Response) => {
  if (response.status === 204) return null;

  const contentType = response.headers.get('Content-Type');
  return contentType && contentType.match(/application\/json/i) !== null
    ? response.json().then(json => (response.ok ? json : Promise.reject(json)))
    : response.ok
      ? response
      : Promise.reject(response.body);
};

const updateInMs = (token: string) => {
  return (
    expiresAt(token).diff(moment(), 'ms') -
    CHECK_BEFORE_MS +
    Math.floor(Math.random() * 30000)
  );
};

let timerId: any;

const schedule = (dispatch: Dispatch<AppState>, accessToken: string) => {
  clearTimeout(timerId);

  timerId = setTimeout(() => {
    (dispatch(refreshAccessToken(getRefreshToken())) as any)
      .then(
        ({
          value: { access_token: newToken },
        }: {
          value: { access_token: string };
        }) => schedule(dispatch, newToken),
      )
      .catch(() => {
        dispatch(logOut());
        dispatch(push('/login'));
      });
  }, updateInMs(accessToken));
};

/**
 * Swagger API middleware. If it sees action with `API_REQUEST` property in
 * payload then it converts fetch args given in this prop to API request call.
 * It adds authentication header if it valid and attempts to refresh and
 * reapply given action if token is expired.
 *
 * 2018-02-12
 * TODO: seems like using cookies is simpler and more reliable way of handling
 * authorization. Alos writing in house API clients is not always the brightest
 * idea. At that point it feels like having something like
 * [redux-api-middleware](https://www.npmjs.com/package/redux-api-middleware)
 * is enough for this task.
 */
export default (baseUrl = '/') => {
  const actionTransform = payloadToFetch(baseUrl);

  return (({ dispatch, getState }: MiddlewareAPI<AppState>) => (
    next: Dispatch<AppState>,
  ) => (action: any) => {
    if (!isApiAction(action)) return next(action);

    const accessToken = getAccessToken();

    // FIXME: XXX: rewrite to be more comprehensible
    const skipAuth = action.meta && action.meta.authenticate === false;

    if (skipAuth || isTokenFresh(accessToken, CHECK_BEFORE_MS)) {
      return next({
        ...action,
        payload: actionTransform(action, accessToken, !skipAuth),
      });
    } else {
      return (dispatch(refreshAccessToken(getRefreshToken())) as any)
        .then(
          ({
            value: { access_token: newToken },
          }: {
            value: { access_token: string };
          }) => {
            schedule(dispatch, newToken);

            next({
              ...action,
              payload: actionTransform(action, newToken),
            });
          },
        )
        .catch(() => {
          dispatch(logOut());
          dispatch(push('/login'));
        });
    }
  }) as Middleware;
};
