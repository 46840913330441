import { last } from 'ramda';
import { AppState } from '../models';

export const getCurrentFrame = (state: AppState) => state.volumer.currentFrame;
export const getUrl = (state: AppState) => state.volumer.url;
export const getViewportResolution = (state: AppState) =>
  state.volumer.viewportResolution;
export const getActiveModifier = (state: AppState) =>
  last(state.volumer.modifiers) || null;
export const getAllModifiers = (state: AppState) => state.volumer.modifiers;
export const getPreferedNodeUrl = (state: AppState) =>
  state.volumer.preferedNode;
export const getUserSelectedQuality = (state: AppState) =>
  state.volumer.userSelectedQuality;
