import { css } from 'styled-components';
import { colors, radius, Theme } from '@hm/ukie';
import { where, cond, complement, always, T } from 'ramda';
import moment from 'moment';
import { DateRange } from 'moment-range';
import { someContains } from '../utils/time';

export const smallCaps = `
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 100;
  line-height: 24px;
`;

export const semibold = `font-weight: 600;`;

export const truncate = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const selectedBorder = css`
  border: 1px solid ${({ theme }) => theme.colors.blue1};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.blue1};
`;

export const hoveredBorder = css`
  border: 1px solid ${({ theme }) => theme.colors.grey5};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.grey5};
`;

export const selection = css`
  &:hover {
    ${({ selected }: { selected: boolean }) =>
      selected ? selectedBorder : hoveredBorder};
  }

  ${({ selected }: { selected: boolean }) => (selected ? selectedBorder : '')};
`;

export const noSelect = `
  cursor: default;
  user-select: none;
`;

interface CellProps {
  first?: boolean;
  last?: boolean;
  isDisabled: boolean;
  isSelected: boolean;
}

export const cellTextColor = (slice: SliceProps) =>
  cond([
    // selected
    [where({ isSelected: Boolean }), always(colors.white)],
    // unavailable
    [where({ isDisabled: Boolean }), always(colors.grey7)],
    // default
    [T, always(colors.defaultColor)],
  ])(slice);

const cellBackground = (props: SliceProps) =>
  cond([
    // selected available
    [
      where({ isSelected: Boolean, isDisabled: complement(Boolean) }),
      always(colors.primary),
    ],
    // selected unavailable
    [where({ isSelected: Boolean, isDisabled: Boolean }), always(colors.blue3)],
    // not selected
    [T, always(colors.white)],
  ])(props);

const cellHoverBackround = (props: SliceProps) =>
  cond([
    // selected available
    [
      where({ isSelected: Boolean, isDisabled: complement(Boolean) }),
      always(colors.blue2),
    ],
    // selected unavailable
    [where({ isSelected: Boolean, isDisabled: Boolean }), always(colors.blue4)],
    // unselected
    [T, always(colors.grey3)],
  ])(props);

export const calendarCell = css`
  background: ${(props: CellProps) => cellBackground(props)};
  color: ${(props: CellProps) => cellTextColor(props)};

  border-top-left-radius: ${({ first }: CellProps) => (first ? '4px' : '0')};
  border-bottom-left-radius: ${({ first }: CellProps) => (first ? '4px' : '0')};
  border-top-right-radius: ${({ last }: CellProps) => (last ? '4px' : '0')};
  border-bottom-right-radius: ${({ last }: CellProps) => (last ? '4px' : '0')};

  &:hover {
    background: ${cellHoverBackround};

    ${({ isSelected }: CellProps) =>
      isSelected ? undefined : `border-radius: ${radius.default};`};
  }
`;

export interface SliceProps {
  isSelected?: boolean;
  isDisabled?: boolean;
  isErrored?: boolean;
}

export const sliceFill = (props: SliceProps) =>
  cond([
    // selected
    [
      where({ isSelected: Boolean, isDisabled: complement(Boolean) }),
      always(colors.primary),
    ],
    // selected unavailable
    [where({ isSelected: Boolean, isDisabled: Boolean }), always(colors.blue3)],
    // not selected unavailable
    [where({ isDisabled: Boolean }), always(colors.grey3)],
    // error
    [where({ isErrored: Boolean }), always(colors.error)],
    // not selected available
    [T, always(colors.grey5)],
  ])(props);

export interface NavigatorTitleProps {
  date: moment.Moment;
  availableIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
  theme?: Theme;
}

export const navigatorTitleColor = css`
  color: ${({
    availableIntervals,
    filteredOutIntervals,
    date,
    theme,
  }: NavigatorTitleProps) =>
    someContains(availableIntervals, date) &&
    !someContains(filteredOutIntervals, date)
      ? theme.colors.defaultColor
      : theme.colors.grey7};
`;
