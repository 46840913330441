import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  updateLayer,
  dragHistogram,
  dragHistogramStop,
  dragHistogramStart,
  persistLayerHistogram,
} from '../actions/layers';
import {
  getLayerById,
  makeGetCurrentBinsById,
  makeGetCurrentVoidValueById,
  makeGetCurrentBordersById,
  makeGetInitialBinsById,
} from '../selectors/layers';
import { makeHasLayerEnabledVoid } from '../selectors/effects';
import { makeGetGradientByLayerId } from '../selectors/gradients';
import Histogram from '../components/Metric/Histogram';
import { AppState } from '../models';

const makeMapStateToProps = () => {
  const getGradientByLayerId = makeGetGradientByLayerId();
  const hasLayerEnabledVoid = makeHasLayerEnabledVoid();
  const getCurrentBinsById = makeGetCurrentBinsById();
  const getInitialBinsById = makeGetInitialBinsById();
  const getCurrentBordersById = makeGetCurrentBordersById();
  const getCurrentVoidValueById = makeGetCurrentVoidValueById();

  // XXX: export interface
  return (state: AppState, ownProps: any) => {
    const layer = getLayerById(state, { id: ownProps.layerId });

    return {
      currentBins: getCurrentBinsById(state, { id: ownProps.layerId }),
      initialBins: getInitialBinsById(state, { id: ownProps.layerId }),
      borders: getCurrentBordersById(state, { id: ownProps.layerId }),
      voidValue: getCurrentVoidValueById(state, { id: ownProps.layerId }),
      selected: layer.histogramWindow,
      isCompact: layer.histogramMinified,
      showVoid: hasLayerEnabledVoid(state, { id: ownProps.layerId }),
      gradientPoints: getGradientByLayerId(state, { id: ownProps.layerId })
        .points,
    };
  };
};

// TODO: recompose
const mapDispatchToProps = (dispatch: Dispatch<AppState>, ownProps: any) => ({
  foldHistogram: (histogramMinified: boolean) =>
    dispatch(updateLayer(ownProps.layerId, { histogramMinified })),
  onBrush: (selected: any) =>
    dispatch(dragHistogram(ownProps.layerId, selected)),
  onBrushStart: () => dispatch(dragHistogramStart(ownProps.layerId)),
  onBrushEnd: () => {
    dispatch(persistLayerHistogram(ownProps.layerId));
    dispatch(dragHistogramStop(ownProps.layerId));
  },
});

// FIXME: couldn’t infer types when passing mapStateToProps function
export default connect<any, any, any>(makeMapStateToProps, mapDispatchToProps)(
  Histogram,
);
