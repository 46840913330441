import React from 'react';
import { Flex, Box } from 'grid-styled';
import { AutosuggestInput, Text } from '@hm/ukie';

import CrossButton from './CrossButton';
import { truncate } from '../../styles/common';
import { User } from '../../models';
import { rafStartAnimation } from '../../utils/helpers';

// TODO: will be simple with `fullName`
const userToString = (u: User) => (u.id && u.name ? u.name : '');

const Email = Text.Disclaimer.extend`${truncate}`;

const UserName = Box.extend.attrs({ is: 'span', flex: '1 0 auto', mr: 1 })`
  max-width: max-content;
`;

const InputWrapper = Flex.extend`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface Props {
  disabled: boolean;
  selectedItem: User;
  items: User[];
  onClick(): void;
  onChange(u: User): void;
}

interface State {
  didMount: boolean;
}

export default class EditorInput extends React.Component<Props, State> {
  state = { didMount: false };

  componentDidMount() {
    rafStartAnimation(() => this.setState({ didMount: true }));
  }

  render() {
    const { disabled, onClick, selectedItem, items, onChange } = this.props;

    return (
      <InputWrapper>
        <CrossButton
          icon="plus"
          isActive={this.state.didMount}
          onClick={onClick}
          disabled={disabled}
        />
        <AutosuggestInput
          selectedItem={selectedItem}
          items={items}
          matchProps={['name', 'email']}
          itemToString={userToString}
          renderItem={user => (
            <Flex>
              <UserName>{userToString(user)}</UserName>
              <Email>{user.email}</Email>
            </Flex>
          )}
          onChange={onChange}
        />
      </InputWrapper>
    );
  }
}
