import React from 'react';
import { compose } from 'ramda';
import { withHandlers, branch, renderNothing } from 'recompose';
import { Flex, Box } from 'grid-styled';
import { Button, Submit } from '@hm/ukie';
import Track from '../Track';

import { MapObject } from '../../reducers/selectedObjects';
import { canSaveObject } from '../../utils/volumerInteractions';

interface OwnProps {
  object: MapObject;
}

interface ExternalProps {
  saveObject(obj: { id: string; type: string });
  deleteObject(id: string);
}

interface ProvidedProps {
  onSave();
  onDelete();
}

type Props = OwnProps & ProvidedProps;

/**
 * Inspector bottom controls that help delete existing
 * and save objects being created
 */
const FinalActions: React.SFC<Props> = ({ object, onSave, onDelete }) => (
  <Flex justify="flex-end" mt={2}>
    <Box mr={1}>
      <Button onClick={onDelete}>Cancel</Button>
    </Box>
    <Box mr={2}>
      <Track
        data-metrics-event-name="objectSave"
        data-metrics-category="MapObject"
      >
        <Submit disabled={!canSaveObject(object)} onClick={onSave}>
          Save
        </Submit>
      </Track>
    </Box>
  </Flex>
);

// TODO: remove recompose handlers
export default compose(
  withHandlers<Props & ExternalProps, any>({
    onSave: ({ saveObject, object }) => () => saveObject(object),
    onDelete: ({ deleteObject, object }) => () => deleteObject(object.id),
  }),
  // XXX: this is so implicit
  branch<Props & ExternalProps>(
    ({ object }) => !(object && object.draft),
    renderNothing,
  ),
)(FinalActions);
