import React from 'react';

interface Props {
  width: number;
  height: number;
  color: string;
  transform?: string;
}

const VoidHistogramCompact: React.SFC<Props> = props => (
  <rect
    style={{ fill: props.color }}
    width={props.width}
    height={props.height}
    transform={props.transform}
  />
);

export default VoidHistogramCompact;
