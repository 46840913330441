import React from 'react';
import moment from 'moment';
import { cond, always, where, T, complement } from 'ramda';
import styled from 'styled-components';
import { typography, Theme } from '@hm/ukie';

interface Props {
  date: moment.Moment;
  isSelected?: boolean;
  isDisabled?: boolean;
  className?: string;
  theme?: Theme;
  onClick(): void;
}

const truthy = Boolean;
const falsy = complement(Boolean);

const Month: React.SFC<Props> = ({ date, className, onClick }) => (
  <div className={className} onClick={onClick}>
    {date.format('MMM')}
  </div>
);

export default styled(Month)`
  font-size: ${typography.smallFont};
  color: ${({ isSelected, isDisabled, theme }) =>
    cond([
      [
        where({ isSelected: truthy, isDisabled: falsy }),
        always(theme.colors.primary),
      ],
      [
        where({ isDisabled: truthy, isSelected: falsy }),
        always(theme.colors.grey7),
      ],
      [
        where({ isDisabled: truthy, isSelected: truthy }),
        always(theme.colors.blue3),
      ],
      [T, always(theme.colors.defaultColor)],
    ])};
  cursor: pointer;
`;
