import styled from 'styled-components';

// TODO: move to @hm/ukie
const ListButton = styled.button`
  border: none;
  background: none;
  outline: none;
  padding: 0;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.grey1};
  padding: 8px 0 2px;
  height: 24px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export default ListButton;

export const SolidLinkButton = ListButton.extend`
  border-style: solid;
`;
