import { dissoc } from 'ramda';
import { types } from '../actions/fragments';
import { fulfill, pend } from '../utils/promise';

export interface Fragment {
  id: string;
  type: number; // Comes from Volumer
  pointIds: string[];
  mapObjectId: string;
  editSelected: boolean;
}

export type SelectedFragmentsState = { [id: string]: Fragment };

export const initialState: SelectedFragmentsState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.SELECT_FRAGMENT):
      return {
        ...state,
        [payload.id]: payload,
      };

    case pend(types.DESELECT_FRAGMENT):
      return dissoc(payload.id, state);

    default:
      return state;
  }
};
