import React from 'react';
import { clamp } from 'ramda';
import { DraggableCore, DraggableEventHandler } from 'react-draggable';
import { Moment, Duration } from 'moment';
import { ScaleLinear, ScaleQuantize } from 'd3-scale';
import Handle from './Handle';
import HandleLabel from './HandleLabel';
import { ApproximationType } from '../../reducers/currentProject';

interface Props {
  selected: number;
  sliceDuration: Duration;
  approximationType: ApproximationType;
  scale: ScaleLinear<number, number>;
  y: number;
  labelScale: ScaleQuantize<Moment>;
  timezone: string;
  boundaries: Element;
  onStart: DraggableEventHandler;
  onStop: DraggableEventHandler;
  onChange(selected: number);
}

export default class Slider extends React.Component<Props, never> {
  onChange: DraggableEventHandler = (e, data) => {
    const { scale, boundaries } = this.props;
    if (!boundaries) return;
    const [start, end] = scale.domain();

    const newSelected = clamp(
      start,
      end,
      scale.invert(e.clientX - boundaries.getBoundingClientRect().left),
    );

    this.props.onChange(newSelected);
  };

  render() {
    const { selected } = this.props;

    return (
      <DraggableCore
        onDrag={this.onChange}
        onStart={this.props.onStart}
        onStop={this.props.onStop}
      >
        <g
          transform={`translate(${this.props.scale(selected)}, ${this.props
            .y})`}
        >
          <Handle cursor="ew-resize" />
          <HandleLabel
            slice={this.props.labelScale(selected).tz(this.props.timezone)}
            sliceDuration={this.props.sliceDuration}
            approximationType={this.props.approximationType}
          />
        </g>
      </DraggableCore>
    );
  }
}
