import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Flex } from 'grid-styled';
import { DateRange } from 'moment-range';

import SlideIn from '../SlideIn';
import Wave from '../Wave';
import withTouchDnd from '../withTouchDnd';
import { AppState } from '../../models';

import { constructApproximationInterval } from '../../utils/time';
import { SliceEventProps } from '../withSliceSelection';
import {
  selectDayRangeFilter,
  selectDayRangeFilterEnd,
} from '../../actions/calendar';
import {
  getSliceDuration,
  computeDayRangeSelection,
} from '../../selectors/calendar';

interface OwnProps {
  show: boolean;
  onClose(): void;
}

interface ConnectedProps extends SliceEventProps {
  sliceDuration: string;
  selectedIntervals: DateRange[];
}

const availableIntervals = constructApproximationInterval('UTC');

class DayRangeFilter extends React.Component<OwnProps & ConnectedProps, never> {
  render() {
    const {
      show,
      onClose,
      sliceDuration,
      selectedIntervals,
      onSelection,
      onSelectionEnd,
      onSelectionClick,
    } = this.props;

    return (
      <SlideIn onClose={onClose} title="Day range filter" show={show}>
        <Flex justify="center" align="center">
          <Wave
            date={availableIntervals[0].start}
            sliceDuration={sliceDuration}
            onSelection={onSelection}
            onSelectionEnd={onSelectionEnd}
            onSelectionClick={onSelectionClick}
            selectedIntervals={selectedIntervals}
            availableIntervals={availableIntervals}
          />
        </Flex>
      </SlideIn>
    );
  }
}

export default compose(
  connect(
    (state: AppState, props: OwnProps) => ({
      selectedIntervals: computeDayRangeSelection(state),
      sliceDuration: getSliceDuration(state),
    }),
    {
      onSelection: selectDayRangeFilter,
      onSelectionEnd: selectDayRangeFilterEnd,
    },
  ),
  withTouchDnd,
)(DayRangeFilter);
