import { takeLatest, put, select, cancel } from 'redux-saga/effects';
import { head, prop } from 'ramda';

import { MapObject } from '../../reducers/selectedObjects';
import { types as layerTypes } from '../../actions/layers';
import { types as dragTypes } from '../../sagas/volumerSocketTasks/drag';
import { syncObject, types as objectTypes } from '../../actions/objects';
import { types as projectTypes } from '../../actions/projects';
import { getSelectedObjects } from '../../selectors/selectedObjects';
import { fulfill } from '../../utils/promise';

/**
 * Fetches object stats when we need it
 */
export default function*() {
  yield takeLatest(
    [
      dragTypes.STOP_DRAG,

      fulfill(objectTypes.ADD_POINT_TO_OBJECT),
      fulfill(objectTypes.CREATE_OBJECT),

      layerTypes.SET_CURRENT_HISTOGRAM,
      layerTypes.SET_INITIAL_HISTOGRAM,

      // TODO: layers presence and order inside inspector stats should depend
      // on layers in store. We do not want to fetch stats on order change or
      // deletion
      fulfill(layerTypes.DELETE_LAYER),
      fulfill(layerTypes.MOVE_LAYER),

      // TODO: when exactly should we refetch stats?
      fulfill(projectTypes.OPTIMISTIC_UPDATE_PROJECT),
    ],
    function*(action) {
      const selectedObjects: MapObject[] = yield select(getSelectedObjects);

      selectedObjects.length === 1
        ? yield put(syncObject(prop('id', head(selectedObjects))))
        : yield cancel();
    },
  );
}
