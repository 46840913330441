import { fork, all } from 'redux-saga/effects';

import handleFrame from './handleFrame';

import timelineSync from './timelineSync';
import histogramSync from './histogramSync';
import trendBlurSync from './trendBlurSync';
import verticalScalingSync from './verticalScalingSync';
import viewportResolutionSync from './viewportResolutionSync';
import lowresQualitySync from './lowresQualitySync';
import objectStatSync from './objectStatSync';

import keyDowns from './keyDowns';
import clicks from './clicks';
import pointerMovements from './pointerMovements';
import toolChangeEffects from './toolChangeEffects';

import zoom from './zoom';
import editMode from './editMode';
import takeScreenshot from './takeScreenshot';

import selectDraggedObject from './selectDraggedObject';
import reselectDraftObject from './reselectDraftObject';
import deselectForCreation from './deselectForCreation';
import objectRadius from './objectRadiusSync';

import getSendEvent from './getEventSender';
import getSendRaycast from './getRaycastSender';
import { Channels } from '../volumerSocketChannels';

export default function*(socket: WebSocket, channels: Channels) {
  const sendEvent = getSendEvent(socket);
  const sendRaycast = yield* getSendRaycast(socket, channels.addResponse);

  yield all([
    fork(handleFrame, channels.frame),
    // XXX: temporary disabled due to annoying warning
    // TODO: less annoying network problems warning
    // fork(pingPong, socket, channels.pong),

    // Passive tasks that do synchronization type of stuff
    fork(timelineSync, sendEvent),
    fork(histogramSync, sendEvent),
    fork(trendBlurSync, sendEvent),
    fork(verticalScalingSync, sendEvent),
    fork(lowresQualitySync, sendEvent),
    fork(viewportResolutionSync, sendEvent),
    fork(objectStatSync),

    // User actions with various effects depending on state
    fork(keyDowns),
    fork(clicks, sendEvent, sendRaycast),
    fork(pointerMovements, sendEvent, sendRaycast),
    fork(toolChangeEffects),

    // Always the same user actions
    fork(zoom, sendEvent), // Wheel
    fork(editMode, sendRaycast), // DoubleClick
    fork(takeScreenshot, socket, channels.screenshotResponse),

    // Other side effects
    fork(selectDraggedObject),
    fork(reselectDraftObject),
    fork(deselectForCreation),
    fork(objectRadius, sendEvent),
  ]);
}
