import { NavigationControl } from 'react-map-gl';
import styled from 'styled-components';

import minus from '@hm/ukie/dist/icons/minus.svg';
import plus from '@hm/ukie/dist/icons/plus.svg';

const Controls = styled(NavigationControl)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px 0 0 16px;
  box-shadow: none !important;
  border-radius: 0 !important;
  height: 24px;
  background: transparent !important;

  & .mapboxgl-ctrl-compass {
    display: none;
  }

  & .mapboxgl-ctrl-zoom-in,
  .mapboxgl-ctrl-zoom-out {
    outline: none;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: ${({ theme }) => theme.radius.default};
    background: ${({ theme }) => theme.colors.white};
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;

    &:hover {
      background-color: ${({ theme }) => theme.colors.grey3};
    }
  }

  & .mapboxgl-ctrl-zoom-in {
    margin-right: 8px;
    background-image: url(${plus}) !important;
  }

  & .mapboxgl-ctrl-zoom-out {
    background-image: url(${minus}) !important;
  }
`;

export default Controls;
