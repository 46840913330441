import { connect } from 'react-redux';
import {
  getType,
  getIsTruncated,
  getShowMore,
} from '../../selectors/inspector';
import {
  getSelectedObjects,
  getFirstObject,
} from '../../selectors/selectedObjects';
import { isApproximated, getTimezone } from '../../selectors/project';
import { getLayerLabels, getLayerGradientColors } from '../../selectors/layers';
import { toggleShowMore } from '../../actions/inspector';
import { AppState } from '../../models';

import Metrics from './Metrics';

const mapStateToProps = (state: AppState) => ({
  selectedTool: getType(state),
  objectCount: getSelectedObjects(state).length,
  object: getFirstObject(state),
  timezone: getTimezone(state),
  isApproximatedProject: isApproximated(state),
  truncate: getIsTruncated(state),
  showMore: getShowMore(state),
  layerLabels: getLayerLabels(state),
  layerGradients: getLayerGradientColors(state),
});

const mapDispatchToProps = {
  toggleShowMore,
};

export default connect(mapStateToProps, mapDispatchToProps)(Metrics);
