import { take, select, put } from 'redux-saga/effects';
import { deselectObject, types } from '../../actions/objects';
import { getSelectedObjectIds } from '../../selectors/selectedObjects';
import { fulfill } from '../../utils/promise';

export default function*() {
  while (true) {
    const { payload: newObject } = yield take(fulfill(types.CREATE_OBJECT));
    const selectedObjectIds = yield select(getSelectedObjectIds);
    for (const id of selectedObjectIds) {
      try {
        // XXX: because Concierge deletes draft objects 404 can be returned
        if (id !== newObject.id) yield put.resolve(deselectObject(id));
      } catch (e) {}
    }
  }
}
