import React from 'react';
import { ScaleLinear } from 'd3-scale';
import { Moment, Duration } from 'moment';
import { DateRange } from 'moment-range';
import { any, none, contains } from 'ramda';

import Ticks from './Ticks';
import { isSameIntervals } from '../../utils/time';
import generateTicks from '../../utils/timelineTickGenerator';
import { TickType } from '../../constants/timeline';
import { ApproximationType } from '../../reducers/currentProject';
import { MomentScale, TimelineTick, TickCollisions } from '../../models';

const tickImportance = {
  [TickType.REGULAR]: 0,
  [TickType.DIVIDER]: 1,
  [TickType.SUPER_DIVIDER]: 2,
  [TickType.INTERVAL_START]: 3,
  [TickType.RANGE_END]: 4,
  [TickType.RANGE_START]: 5,
};

const initialLod = {
  [TickType.REGULAR]: 0,
  [TickType.DIVIDER]: 0,
  [TickType.SUPER_DIVIDER]: 0,
  [TickType.INTERVAL_START]: 1,
};

const unevenCollision = (tick: TickCollisions) =>
  none(({ type }) => type === tick.self.type, tick.collidingTicks);

const anySameTypeCollisions = (typeExamined: string) => (
  tick: TickCollisions,
) =>
  tick.self.type === typeExamined
    ? any(({ type }) => type === tick.self.type)(tick.collidingTicks)
    : false;

const isMoreImportantThan = (a: TimelineTick) => (b: TimelineTick) =>
  tickImportance[b.type] >= tickImportance[a.type];

const computeExclusions = (collisions: TickCollisions[]) => {
  return collisions
    .filter(({ collidingTicks, self }) =>
      any(isMoreImportantThan(self))(collidingTicks),
    )
    .map(({ self }) => self.value);
};

interface Props {
  intervals: DateRange[];
  timezone: string;
  scale: MomentScale;
  y: number;
  sliceDuration: Duration;
  displayProgress: boolean;
  brushScale: ScaleLinear<number, number>;
  selected: number[];
  previousRange: number[];
  approximationType: ApproximationType;
}

interface State {
  ticks?: TimelineTick[];
  levelOfDetail?: { [tickType: string]: number };
  exclusions?: Moment[];
}

/**
 * Wrapper for vanilla Ticks that creates array of ticks to be displayed from
 * intervals and slice duration and manages overlapping labels
 */
export default class TicksAdaptable extends React.Component<Props, State> {
  collisions: TickCollisions[] = [];

  constructor(props: Props) {
    super(props);
    this.state = this.getStartState(props);
  }

  /**
   * Start over when intervals change
   */
  componentWillReceiveProps(nextProps: Props) {
    if (!isSameIntervals(this.props.intervals, nextProps.intervals)) {
      this.setState(this.getStartState(nextProps));
    }
  }

  /**
   * After mount compute collisions and adjust
   */
  componentDidMount() {
    this.updateTicks();
  }

  /**
   * After each recompute, check and further adjust if needed
   */
  componentDidUpdate() {
    this.updateTicks();
  }

  getStartState = ({ intervals, sliceDuration }: Props) => ({
    ticks: generateTicks(intervals, sliceDuration, initialLod),
    levelOfDetail: initialLod,
    exclusions: [],
  });

  updateTicks = () => {
    if (this.collisions.length <= 0) return;
    const { levelOfDetail: prevLevelOfDetail } = this.state;

    const newExclusions = computeExclusions(
      this.collisions.filter(unevenCollision),
    );
    const exclusions = this.state.exclusions.concat(newExclusions);

    const regularCollide = any(anySameTypeCollisions(TickType.REGULAR))(
      this.collisions,
    );
    const dividersCollide = any(anySameTypeCollisions(TickType.DIVIDER))(
      this.collisions,
    );
    const superDividersCollide = any(
      anySameTypeCollisions(TickType.SUPER_DIVIDER),
    )(this.collisions);
    const intervalStartsCollide = any(
      anySameTypeCollisions(TickType.INTERVAL_START),
    )(this.collisions);

    const levelOfDetail = {
      [TickType.REGULAR]:
        prevLevelOfDetail[TickType.REGULAR] + Number(regularCollide),
      [TickType.DIVIDER]:
        prevLevelOfDetail[TickType.DIVIDER] + Number(dividersCollide),
      [TickType.SUPER_DIVIDER]:
        prevLevelOfDetail[TickType.SUPER_DIVIDER] +
        Number(superDividersCollide),
      [TickType.INTERVAL_START]:
        prevLevelOfDetail[TickType.INTERVAL_START] +
        Number(intervalStartsCollide),
    };

    const ticks = generateTicks(
      this.props.intervals,
      this.props.sliceDuration,
      levelOfDetail,
    ).filter(({ value }) => !contains(value, exclusions));

    this.setState({ ticks, levelOfDetail, exclusions });
  };

  render() {
    const { intervals, ...rest } = this.props;

    return (
      <Ticks
        ticks={this.state.ticks}
        getCollisions={cols => (this.collisions = cols)}
        {...rest}
      />
    );
  }
}
