import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'ramda';

import messages from './messages';

const calculateYears = (duration: moment.Duration) => {
  const asMonths = Math.round(duration.asMonths());
  const years = Math.floor(asMonths / 12);
  const months = asMonths % 12;

  return (
    <span>
      {years > 0 ? (
        <FormattedMessage {...messages.totalYears} values={{ years }} />
      ) : null}

      {months > 0 ? (
        <>
          {' '}
          <FormattedMessage {...messages.totalMonths} values={{ months }} />
        </>
      ) : null}
    </span>
  );
};

const calculateDays = (duration: moment.Duration) => {
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();

  return (
    <span>
      {days > 0 ? (
        <FormattedMessage {...messages.days} values={{ days }} />
      ) : null}{' '}
      {hours > 0 ? (
        <FormattedMessage {...messages.hours} values={{ hours }} />
      ) : null}{' '}
      {minutes > 0 ? (
        <FormattedMessage {...messages.minutes} values={{ minutes }} />
      ) : null}
    </span>
  );
};

interface Props {
  intervals: DateRange[];
  sliceDuration: moment.Duration;
  is?: string;
}

const HumanizedIntervals: React.SFC<Props> = ({
  intervals,
  sliceDuration,
  is,
}) => {
  const Body = is;
  const total = moment.duration(
    intervals.reduce((acc, value) => acc + value.valueOf(), 0),
  );

  return !isEmpty(intervals) ? (
    <Body>
      {sliceDuration.asMonths() >= 1
        ? calculateYears(total)
        : calculateDays(total)}
    </Body>
  ) : (
    <FormattedMessage {...messages.empty} tagName={is} />
  );
};

HumanizedIntervals.defaultProps = {
  is: 'span',
};

export default HumanizedIntervals;
