import React from 'react';
import { prop } from 'ramda';
import cx from 'classnames';
import { Gradient } from '../../reducers/gradients';
import GradientPreview from '../GradientPreview';

interface Props {
  layerId: string;
  layerGradientId: string;
  gradients: Gradient[];
  setLayerGradient(layerId: string, gradientId: string);
}

const ColorMenu: React.SFC<Props> = props => {
  const { gradients, layerGradientId, layerId, setLayerGradient } = props;

  return (
    <div className="dropdown-menu _tiles">
      {gradients.map(({ id, points }) => (
        <GradientPreview
          key={id}
          className={cx('dropdown-menu__item', {
            'is-selected': layerGradientId === id,
          })}
          onClick={() => setLayerGradient(layerId, id)}
          colors={points.map(prop('color'))}
        />
      ))}
    </div>
  );
};

export default ColorMenu;
