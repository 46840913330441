import { takeLatest, put } from 'redux-saga/effects';
import { types } from '../reducers/billing';
import { fulfill } from '../utils/promise';
import { getProfile } from '../actions/user';

/**
 * Will refetch user info after subscribe and unsubscribe actions to reflect
 * access changes in the app.
 */
export default function*() {
  yield takeLatest(
    [fulfill(types.SUBSCRIBE), fulfill(types.UNSUBSCRIBE)],

    function*() {
      yield put.resolve(getProfile());
    },
  );
}
