import { omit, evolve, isNil } from 'ramda';
import { types } from '../actions/effects';
import { types as projectTypes } from '../actions/projects';
import { types as layerTypes } from '../actions/layers';
import { fulfill, pend } from '../utils/promise';

export interface Effect {
  id: string;
  label: string;
  type: string;
  enabled: boolean;
  settings: any;
}

export interface EffectsState {
  entities: Dict<Effect>;
}

export const initialState = {
  entities: {},
};

export default (
  state: EffectsState = initialState,
  action: any,
): EffectsState => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.CREATE_EFFECT):
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities.effects,
        },
      };

    case pend(types.UPDATE_EFFECT):
      const effect = state.entities[payload.id];

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...effect,
            ...payload.updates,
            settings: {
              ...effect.settings,
              ...payload.updates.settings,
            },
          },
        },
      };

    case types.TREND_BLUR_DRAG:
      const { spaceRadius, timeRadius } = payload.message;
      return evolve<any, { entities: any }>(
        {
          entities: {
            [payload.effectId]: {
              settings: {
                space: v => (isNil(spaceRadius) ? v : spaceRadius),
                time: v => (isNil(timeRadius) ? v : timeRadius),
              },
            },
          },
        },
        state,
      );

    case pend(types.DELETE_EFFECT):
      return {
        entities: omit([payload.id], state.entities),
      };

    case fulfill(layerTypes.CREATE_LAYER):
    case fulfill(layerTypes.GET_LAYER):
    case fulfill(projectTypes.GET_PROJECT):
      if (!payload.entities.effects) return state;
      return { entities: payload.entities.effects };

    default:
      return state;
  }
};
