import { get, post } from '../../api/rest';

export const types = {
  GET_BILLING: 'billing/GET_BILLING',
  TOGGLE_OVERAGE: 'billing/TOGGLE_OVERAGE',
  CHANGE_PAYMENT_SOURCE: 'billing/CHANGE_PAYMENT_SOURCE',
  SUBSCRIBE: 'billing/SUBSCRIBE',
  UNSUBSCRIBE: 'billing/UNSUBSCRIBE',
  FETCH_BILLING_IF_MISSING: 'billing/FETCH_BILLING_IF_MISSING',
};

export const fetchBilling = () => ({
  type: types.GET_BILLING,
  payload: get('/billing'),
});

export const toggleOverage = (overageActive: boolean) => ({
  type: types.TOGGLE_OVERAGE,
  payload: post('/billing/overage', { overageActive }),
});

export const changePaymentSource = (token: string) => ({
  type: types.CHANGE_PAYMENT_SOURCE,
  payload: post('/billing/source', { token }),
});

export const subscribe = () => ({
  type: types.SUBSCRIBE,
  payload: post('/billing/subscribe'),
});

export const unsubscribe = (deleteWorkspaceDataOnPeriodEnd: boolean) => ({
  type: types.UNSUBSCRIBE,
  payload: post('/billing/unsubscribe', { deleteWorkspaceDataOnPeriodEnd }),
});

export const fetchBillingIfMissing = () => ({
  type: types.FETCH_BILLING_IF_MISSING,
});
