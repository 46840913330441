import React from 'react';
import moment from 'moment';
import { FormattedMessage as F, FormattedNumber as N } from 'react-intl';
import { Box } from 'grid-styled';
import { Text, DashedLinkButton } from '@hm/ukie';
import { BillingState } from '../../reducers/billing';
import Elements from '../StripeElements';
import { toDMY } from '../../utils/time';
import ChangePaymentForm from './ChangePaymentForm';

interface Props {
  billingInfo: BillingState;
  trial: boolean;
  usedHours: number;
  maxHours: number;
  cardBrand: string;
  cardExpYear: number;
  cardExpMonth: number;
  cardLastFour: number;
  nextInvoice: string;
  stripeSynchronized: boolean;
  onChangePaymentSource(token: string): Promise<any>;
}

class Payment extends React.Component<Props, { paymentOpen: boolean }> {
  state = {
    paymentOpen: false,
  };

  render() {
    const { paymentOpen } = this.state;
    const {
      billingInfo: { cardValid, subscriptionActive },
      trial,
      usedHours,
      maxHours,
      cardLastFour,
      nextInvoice,
      cardBrand,
      cardExpMonth,
      cardExpYear,
      onChangePaymentSource,
    } = this.props;

    return (
      <Box width={256}>
        <Box mb={2}>
          <F id="Payment.paymentSource" defaultMessage="Payment source">
            {t => (
              <Text.Important mb={1} is="div">
                {t}
              </Text.Important>
            )}
          </F>

          {cardValid ? (
            <React.Fragment>
              <Box my={1}>{`${cardBrand} ... ${cardLastFour}`}</Box>
              <Text.Disclaimer is="div" my={1}>
                {moment([cardExpYear, cardExpMonth - 1]).format('MM/YY')}
              </Text.Disclaimer>
            </React.Fragment>
          ) : (
            <F
              id="Payment.noPayment"
              defaultMessage="There is no payment source"
            >
              {t => <Text.Disclaimer>{t}</Text.Disclaimer>}
            </F>
          )}
        </Box>

        <Box my={3}>
          <DashedLinkButton
            onClick={() => this.setState({ paymentOpen: !paymentOpen })}
          >
            <F
              id="Payment.updateSource"
              defaultMessage="Update payment source"
            />
          </DashedLinkButton>
        </Box>

        {paymentOpen ? (
          <Box my={3}>
            <Elements>
              <ChangePaymentForm
                onSuccess={onChangePaymentSource}
                onClose={() => this.setState({ paymentOpen: false })}
              />
            </Elements>
          </Box>
        ) : null}

        <Box my={1}>
          {trial ? (
            <F
              id="Payment.trialExpiration"
              defaultMessage="Trial period will expire {date} or after {trialLength} hours"
              values={{
                trialLength: (
                  <N
                    value={Math.max(maxHours - usedHours, 0)}
                    maximumFractionDigits={1}
                  >
                    {n => <Text.Important>{n}</Text.Important>}
                  </N>
                ),
                date: (
                  <Text.Important>{toDMY(moment(nextInvoice))}</Text.Important>
                ),
              }}
            />
          ) : subscriptionActive ? (
            <F
              id="Payment.nextInvoice"
              defaultMessage="Next invoice for {name} will be initiated {date}."
              values={{
                name:
                  usedHours > maxHours ? (
                    <F
                      id="Payment.overageHours"
                      defaultMessage="overage hours and plan subscription"
                    />
                  ) : (
                    <F
                      id="Payment.planSubscription"
                      defaultMessage="plan subscription"
                    />
                  ),
                date: (
                  <Text.Important>{toDMY(moment(nextInvoice))}</Text.Important>
                ),
              }}
            />
          ) : null}
        </Box>
      </Box>
    );
  }
}

export default Payment;
