import React from 'react';
import { compose } from 'ramda';
import { FormattedMessage as F } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import styled from 'styled-components';
import { Button, Scrollable, Fill } from '@hm/ukie';

const yellowBoxPadding = 16;

const YellowBox = Box.extend`
  position: absolute;
  width: 100%;
  max-width: 640px;
  top: 0;
  left: 0;
  right: 0;
  padding: ${yellowBoxPadding}px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.warning};
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
`;

interface Props {
  onCancel(): void;
  onConfirm(): void;
}

interface State {
  alertBoxHeight?: number;
}

// TODO: refactor styling done with JS if possible
class ConfirmDestructive extends React.Component<Props, State> {
  cancelButton = null;
  buttonGroup = null;
  fill = null;

  state = {
    alertBoxHeight: 0,
  };

  componentDidMount() {
    if (this.cancelButton) this.cancelButton.focus();
    this.updateAlertBoxHeight();
  }

  // XXX: This constrains growing of scrollable
  // to container height minus buttons group height
  // TODO: find a way to do this better
  updateAlertBoxHeight() {
    if (!this.fill || !this.buttonGroup) return;

    const boxHeight = this.fill.getBoundingClientRect().height;
    const buttonsHeight = this.buttonGroup.getBoundingClientRect().height;

    this.setState({
      alertBoxHeight: boxHeight - buttonsHeight - yellowBoxPadding * 2,
    });
  }

  render() {
    const { onCancel, onConfirm, children } = this.props;

    return (
      <Fill innerRef={el => (this.fill = el)}>
        <Backdrop onClick={onCancel} />
        <YellowBox>
          <Scrollable autoHeight autoHeightMax={this.state.alertBoxHeight}>
            {children}
          </Scrollable>
          <Flex
            innerRef={el => (this.buttonGroup = el)}
            justify="flex-end"
            pt={2}
          >
            <Box innerRef={el => el && (this.cancelButton = el)} mr={2}>
              <Button onClick={onCancel}>
                <F id="ConfirmDestructive.cancel" defaultMessage="Cancel" />
              </Button>
            </Box>
            <Button primary onClick={compose(onCancel, onConfirm)}>
              <F id="ConfirmDestructive.remove" defaultMessage="Remove" />
            </Button>
          </Flex>
        </YellowBox>
      </Fill>
    );
  }
}

export default ConfirmDestructive;
