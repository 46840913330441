import { post } from '../api/rest';
import { GeoJsonGeometryTypes } from 'geojson';

export interface BBox {
  leftLon: number;
  rightLon: number;
  topLat: number;
  bottomLat: number;
}

export interface ObjectFileUpload {
  id: string;
  workspaceId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  environmentId: string;
  environmentBoundingBox: BBox;
  layers: ObjectFileLayer[];
}

export interface ObjectFileLayer {
  id: string;
  name: string;
  type: string;
  objectCount: number;
  schema: ObjectImportSchema[];
  schemaPreview: Dict<any>[];
  clampedGeometryPreview: GeoJsonGeometryTypes;
  createdAt: string;
  updatedAt: string;
}

export interface ObjectImportSchema {
  property: string;
  type: string;
}

export interface GisImportLayerConfig {
  id: string;
  field: string;
  merge: boolean;
}

export interface GisImportApply {
  projectId: string;
  layers: GisImportLayerConfig[];
}

export const types = {
  APPLY: 'gisImport/apply',
};

export const applyGisImport = (id: string, form: GisImportApply) => ({
  type: types.APPLY,
  payload: post(`/gis_import/files/${id}/apply`, form),
});
