import { pluck } from 'ramda';
import { put } from 'redux-saga/effects';
import { EventSender } from './getEventSender';
import { Point } from '../../models';

const idsOf = pluck('id');

export const types = {
  START_DRAG: 'drag/START',
  STOP_DRAG: 'drag/STOP',
};

interface DraggableEntity {
  id: string;
  type: number;
  [index: string]: any;
}

export default {
  *start(
    sendEvent: EventSender,
    coord: Point,
    entities: DraggableEntity[],
    domain: number,
  ) {
    yield* sendEvent('dragStart', {
      coord,
      objectId: idsOf(entities),
      domain,
    });

    yield put({ type: types.START_DRAG, payload: entities });
  },

  *tick(
    sendEvent: EventSender,
    coord: Point,
    prevCoord: Point,
    entities: DraggableEntity[],
    domain: number,
  ) {
    yield* sendEvent('drag', {
      coord,
      prevCoord,
      objectId: idsOf(entities),
      domain,
    });
  },

  *stop(
    sendEvent: EventSender,
    coord: Point,
    entities: DraggableEntity[],
    domain: number,
  ) {
    yield* sendEvent('dragStop', {
      coord,
      objectId: idsOf(entities),
      domain,
    });

    yield put({ type: types.STOP_DRAG, payload: entities });
  },
};
