import { connect } from 'react-redux';
import SelectedInterval from '../components/SelectedInterval';
import {
  getFilteredTimeIntervals,
  isApproximated,
  getCurrentSliceDuration,
  getTimezone,
} from '../selectors/project';
import { AppState } from '../models';

export default connect((state: AppState) => ({
  selectedIntervals: getFilteredTimeIntervals(state),
  timezone: getTimezone(state),
  isApproximated: isApproximated(state),
  sliceDuration: getCurrentSliceDuration(state),
}))(SelectedInterval);
