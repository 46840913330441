import React from 'react';
import moment from 'moment';
import { Flex, Box } from 'grid-styled';
import { RadioSquare, Text } from '@hm/ukie';
import withDisabled from '../withDisabled';

const Container = Flex.extend`
  line-height: 2;
  ${withDisabled};
`;

const availableDurations = {
  minutes: ['PT5M', 'PT10M', 'PT15M', 'PT20M', 'PT30M'],
  hours: ['PT1H', 'PT2H', 'PT3H', 'PT4H', 'PT6H', 'PT8H', 'PT12H'],
};

interface Props {
  sliceDuration: string;
  availableSliceDurations: moment.Duration[];
  disabled?: boolean;
  onSliceDuration(duration: string): void;
}

const SliceDurations: React.SFC<Props> = ({
  sliceDuration,
  availableSliceDurations,
  disabled,
  onSliceDuration,
}) => (
  <Container flexDirection="column" disabled={disabled}>
    <Text is="div" fontWeight="semibold" mb={1} mt={2}>
      Slice duration
    </Text>

    <Flex my="4px">
      <Box width={56}>
        <label>Minutes</label>
      </Box>
      <Flex flex={1}>
        {availableDurations.minutes.map((duration, i) => (
          <Box mr="4px" key={`m${i}`}>
            <RadioSquare
              value={moment.duration(duration).asMinutes()}
              name="sliceDuration"
              checked={duration === sliceDuration}
              disabled={
                !availableSliceDurations
                  .map(d => d.toISOString())
                  .includes(duration)
              }
              onChange={() => onSliceDuration(duration)}
            />
          </Box>
        ))}
      </Flex>
    </Flex>

    <Flex my="4px">
      <Box width={56}>
        <label>Hours</label>
      </Box>
      <Flex flex={1}>
        {availableDurations.hours.map((duration, i) => (
          <Box key={`h${i}`} mr="4px">
            <RadioSquare
              value={moment.duration(duration).asHours()}
              name="sliceDuration"
              checked={duration === sliceDuration}
              // FIXME: remove map and .toISOString()
              disabled={
                !availableSliceDurations
                  .map(d => d.toISOString())
                  .includes(duration)
              }
              onChange={() => onSliceDuration(duration)}
            />
          </Box>
        ))}
      </Flex>
    </Flex>
  </Container>
);

export default SliceDurations;
