import styled, { css } from 'styled-components';

export interface Props {
  disabled?: boolean;
}

const withDisabled = css`
  transition: opacity 150ms ease-out;
  ${({ disabled }: Props) =>
    disabled
      ? `
    pointer-events: none;
    opacity: 0.5;
  `
      : ''};
`;

export const Disabled = styled.div`
  ${withDisabled};
`;

export default withDisabled;
