import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import { Text, Userpic, ModeButton, Grid, Input, IconButton } from '@hm/ukie';

import EditorsForm from './EditorsForm';
import Row, { Value } from './Row';
import GeoBounds from './GeoBounds';
import Collapsible from '../Collapsible';
import HumanizedIntervals from '../HumanizedIntervals';
import ConfirmDestructive from '../ConfirmDestructive';

import { toDMY } from '../../utils/time';
import messages from './messages';
import { Role } from '../../models';
import { Environment as IEnvironment } from '../../reducers/environments';
import FeatureToggle from '../FeatureToggle';

const accessMessage = {
  [Role.Editor]: messages.canEdit,
  [Role.Owner]: messages.owner,
};

const EditButton = ModeButton.extend`
  display: inline-flex;
  margin-left: 8px;
`;

export interface Props {
  environment: IEnvironment;
  onEditorDelete?(environmentId: string, id: string): void;
  onUpdate?(id: string, updates: Partial<IEnvironment>): any;
  deleteEnvironment?(id: string);
}

interface State {
  isEditing?: boolean;
  showDeleteDialog?: boolean;
}

export default class Environment extends React.Component<Props, State> {
  state = { isEditing: false, showDeleteDialog: false };

  onToggle = () => this.setState({ isEditing: !this.state.isEditing });

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.environment.id !== this.props.environment.id &&
      this.state.isEditing
    )
      this.setState({ isEditing: false });
  }

  renderAccess = () => {
    const {
      environment: { owner, editors, currentAccessRole, id },
    } = this.props;

    const { isEditing } = this.state;

    return (
      <FeatureToggle feature="accessControl">
        <Collapsible title={this.renderSubtitle(messages.access)} isOpen>
          <Box mt={1}>
            <Row nameComponent={<F {...messages.accessStatus} />}>
              <F {...accessMessage[currentAccessRole]}>
                {text => <Text fontWeight="semibold">{text}</Text>}
              </F>
            </Row>
            <Row mt={1} nameComponent={<F {...messages.owner} />}>
              <Box mt="-1px">
                <Userpic
                  name={owner.name}
                  image={owner.avatarUrl}
                  color={`#${owner.defaultColor}`}
                />
              </Box>
            </Row>
            <Row
              mt={1}
              nameComponent={
                <span>
                  <F {...messages.editors} />
                  <FeatureToggle feature="accessControl">
                    <EditButton
                      icon="pencil"
                      selected={isEditing}
                      onClick={this.onToggle}
                    />
                  </FeatureToggle>
                  ) : null}
                </span>
              }
            >
              <Box mb={-1} mr={-1}>
                {editors.map(e => (
                  <Grid mb={1} mr={1} key={e.id}>
                    <Userpic
                      name={e.name}
                      image={e.avatarUrl}
                      color={`#${e.defaultColor}`}
                      onDelete={
                        isEditing
                          ? () => this.props.onEditorDelete(id, e.id)
                          : undefined
                      }
                    />
                  </Grid>
                ))}
              </Box>
            </Row>
            {isEditing ? <EditorsForm id={id} /> : null}
          </Box>
        </Collapsible>
      </FeatureToggle>
    );
  };

  renderParameters = () => {
    const {
      environment: {
        geoBounds,
        area,
        country,
        timezone,
        cellSize,
        resolution,
        layerCount,
        availableTimeIntervals,
        availableSliceDurations,
      },
    } = this.props;

    return (
      <Collapsible title={this.renderSubtitle(messages.parameters)} isOpen>
        <Box mt={1}>
          <GeoBounds {...geoBounds} />
          {area ? (
            <Row nameComponent={<F {...messages.area} />}>
              <Value>{area}</Value>
            </Row>
          ) : null}
          {country ? (
            <Row nameComponent={<F {...messages.country} />}>
              <Value>{country}</Value>
            </Row>
          ) : null}
          <Row nameComponent={<F {...messages.timezone} />}>
            <Value>{timezone}</Value>
          </Row>
          {cellSize ? (
            <Row nameComponent={<F {...messages.cellSize} />}>
              <Value>{cellSize}</Value>
            </Row>
          ) : null}
          <Row nameComponent={<F {...messages.resolution} />}>
            <Value>{`${resolution.x} × ${resolution.y}`}</Value>
          </Row>
          <Row nameComponent={<F {...messages.amountOfLayers} />}>
            <Value>{layerCount}</Value>
          </Row>
          <Row nameComponent={<F {...messages.totalTimeInterval} />}>
            <Value>
              <HumanizedIntervals
                intervals={availableTimeIntervals}
                sliceDuration={availableSliceDurations[0]}
              />
            </Value>
          </Row>
          <Row nameComponent={<F {...messages.layersSliceDurations} />}>
            <Value>
              {availableSliceDurations.map(d => d.humanize()).join(', ')}
            </Value>
          </Row>
        </Box>
      </Collapsible>
    );
  };

  renderDescription = () => (
    <Collapsible title={this.renderSubtitle(messages.description)} isOpen>
      <Box mt={1}>
        <Row nameComponent={<F {...messages.dateOfCreation} />}>
          <Value>{toDMY(this.props.environment.createdAt)}</Value>
        </Row>
      </Box>
    </Collapsible>
  );

  renderSubtitle = (message: F.MessageDescriptor) => (
    onClick: (e: React.MouseEvent<HTMLSpanElement>) => void,
  ) => (
    <Text fontWeight="semibold">
      <F {...message}>{text => <span onClick={onClick}>{text}</span>}</F>
    </Text>
  );

  render() {
    const { environment, deleteEnvironment, onUpdate } = this.props;

    return (
      <Box>
        <Flex width="100%" align="center" justify="space-between">
          {environment.isAdmin || environment.currentAccessRole === 'owner' ? (
            <Box pl={1}>
              <Input
                titleFont
                truncate
                value={environment.name}
                onChange={e =>
                  onUpdate(environment.id, { name: e.currentTarget.value })
                }
              />
            </Box>
          ) : (
            <Text.ThinTitle pl={1} is="div">
              {this.props.environment.name}
            </Text.ThinTitle>
          )}
          <IconButton
            icon="bin"
            disabled={environment.readOnly}
            onClick={() => this.setState({ showDeleteDialog: true })}
          />
        </Flex>
        <Box mt={2}>
          {this.renderAccess()}
          {this.renderParameters()}
          {this.renderDescription()}
        </Box>
        {this.state.showDeleteDialog ? (
          <ConfirmDestructive
            onCancel={() => this.setState({ showDeleteDialog: false })}
            onConfirm={() => deleteEnvironment(environment.id)}
          >
            <F
              id="EnvironmentInfo.remove"
              defaultMessage="Remove the {env} environment?{br}This action will lead to removing all projects and layers within the environment"
              values={{
                env: (
                  <Text
                    key="env"
                    is="strong"
                    lineHeight="text"
                    fontWeight="semibold"
                  >
                    {environment.name}
                  </Text>
                ),
                br: <br key="br" />,
              }}
            >
              {(...elements) => (
                <Text is="p" lineHeight="text" m={0}>
                  {elements}
                </Text>
              )}
            </F>
          </ConfirmDestructive>
        ) : null}
      </Box>
    );
  }
}
