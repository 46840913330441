import React from 'react';
import { Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import { CheckboxToggle } from '@hm/ukie';

import PlanDescription from './PlanDescription';
import PlanCosts from './PlanCosts';
import PlanResources from './PlanResources';
import UnsubscribeWarning from './UnsubscribeWarning';
import SubscribeActions from './SubscribeActions';

import { BillingState } from '../../reducers/billing';
import { planIsTrial } from '../../utils/helpers';
import { pricingPolicyPage } from '../../constants/links';

interface Props {
  billingInfo: BillingState;
  unsubscribeDate: string;
  toggleOverage(enabled: boolean): void;
  onSubscribe(): Promise<any>;
}

class Plan extends React.Component<Props> {
  state = {
    agreed: false,
  };

  render() {
    const {
      billingInfo,
      billingInfo: {
        type,
        subscriptionActive,
        canSubscribe,
        canUnsubscribe,
        cancelAtPeriodEnd,
        nextUpcomingInvoiceAt,
        cardValid,
      },
      toggleOverage,
      onSubscribe,
    } = this.props;

    return (
      <Box width={256}>
        {!planIsTrial(type) ? (
          <Box my={3}>
            <UnsubscribeWarning
              unsubscribeDate={cancelAtPeriodEnd && nextUpcomingInvoiceAt}
              subscriptionActive={subscriptionActive}
            />
          </Box>
        ) : null}

        <PlanDescription billingInfo={billingInfo} />

        <PlanCosts billingInfo={billingInfo} />

        {subscriptionActive || (cancelAtPeriodEnd && nextUpcomingInvoiceAt) ? (
          <Box my={1}>
            <PlanResources
              billingInfo={billingInfo}
              toggleOverage={toggleOverage}
            />
          </Box>
        ) : null}

        {!subscriptionActive && canSubscribe ? (
          <Box mb={3}>
            <CheckboxToggle
              checked={this.state.agreed}
              disabled={!cardValid}
              onChange={() => this.setState({ agreed: !this.state.agreed })}
            >
              <F
                id="Plan.aware"
                defaultMessage="I am aware of Chronotope's {pricing}"
                values={{
                  pricing: (
                    <a href={pricingPolicyPage} target="_blank">
                      <F
                        id="Plan.pricingPolicy"
                        defaultMessage="pricing policy"
                      />
                    </a>
                  ),
                }}
              />
            </CheckboxToggle>
          </Box>
        ) : null}

        <SubscribeActions
          canSubscribe={canSubscribe}
          canUnsubscribe={canUnsubscribe}
          onSubscribe={onSubscribe}
          cardValid={cardValid}
          agreed={this.state.agreed}
        />
      </Box>
    );
  }
}

export default Plan;
