import { connect } from 'react-redux';
import { compose } from 'redux';
import { lifecycle } from 'recompose';

import withTouchDnd from '../withTouchDnd';

import { hideModal } from '../../actions/modals';
import {
  select,
  endSelection,
  selectClick,
  updateStartTime,
  reset,
  initEdit,
  saveEdits,
} from '../../actions/calendar';

import {
  getStartTime,
  getSliceDuration,
  isTooManySlicesSelected,
  computeCalendarSelection,
  getAvailableIntervals,
  canSubmit,
} from '../../selectors/calendar';

import { AppState } from '../../models';

import EditApproximatedProject from './EditApproximatedProject';

const mapStateToProps = (state: AppState) => ({
  selectedIntervals: computeCalendarSelection(state),
  tooManySlices: isTooManySlicesSelected(state),
  availableIntervals: getAvailableIntervals(state),
  sliceDuration: getSliceDuration(state),
  startTime: getStartTime(state),
  isValid: canSubmit(state),
});

const mapDispatchToProps = {
  onClose: hideModal,
  onSelection: select,
  onSelectionEnd: endSelection,
  onSelectionClick: selectClick,
  onStartTimeChange: updateStartTime,
  onMount: initEdit,
  onUnmount: reset,
  onSubmit: saveEdits,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount(this: any) {
      /* tslint:disable-next-line:no-invalid-this */
      this.props.onMount();
    },
    componentWillUnmount(this: any) {
      /* tslint:disable-next-line:no-invalid-this */
      this.props.onUnmount();
    },
  }),
  withTouchDnd,
)(EditApproximatedProject);
