import React from 'react';
import { Flex, Box } from 'grid-styled';
import Overlay from 'react-overlays/lib/Overlay';
import { Text, Tooltip, withDisabled } from '@hm/ukie';

const FieldBox = Box.extend.attrs({ my: 1 })`
  line-height: 24px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

interface FieldProps {
  label?: string | JSX.Element;
}

// TODO: maybe we don’t want two props? 🤔
export const Field: React.SFC<FieldProps> = ({ label, children }) => (
  <FieldBox>
    <label>
      {label}
      {children}
    </label>
  </FieldBox>
);

const FieldsetBox = Box.withComponent('fieldset').extend`
${withDisabled};
  border: none;
  padding: 0;
  line-height: 24px;
  margin: 32px 0;
`;

const Legend = Text.Important.withComponent('legend');

export const Fieldset: React.SFC<{
  title?: string | JSX.Element;
  disabled?: boolean;
}> = ({ title, children, disabled }) => (
  <FieldsetBox disabled={disabled}>
    {title ? (
      <Box mb={2}>
        <Legend>{title}</Legend>
      </Box>
    ) : null}
    {children}
  </FieldsetBox>
);

export const FormInfo = Box.extend.attrs({ mb: 2 })`
  white-space: pre-line;
`;

export const FormActions = Flex.extend.attrs({
  mt: 3,
  justify: 'flex-end',
})`
  & button + button {
    margin-left: 8px;
  }
`;

export const FieldInfo = Text.Disclaimer.extend`
  display: block;
  line-height: 1.4;
  white-space: pre-line;
`;

/* tslint:disable-next-line:ban-types */
export const FieldError: React.SFC<{ target: React.ReactNode | Function }> = ({
  target,
  children,
}) => {
  return (
    <Overlay show target={target}>
      <Tooltip>{children}</Tooltip>
    </Overlay>
  );
};
