import { schema } from 'normalizr';
import moment from 'moment';
import { highlightSchema } from '../reducers/highlights';

export const layerAnomalySchema = new schema.Entity(
  'layerAnomalies',
  {},
  {
    processStrategy: anomaly => ({
      ...anomaly,
      timestamp: moment.utc(anomaly.timestamp),
    }),
  },
);

export const optionsSchema = new schema.Entity('options');

export const categorySchema = new schema.Entity('categories', {
  options: [optionsSchema],
});

export const effectsSchema = new schema.Entity('effects');

export default new schema.Entity('layers', {
  categories: [categorySchema],
  effects: [effectsSchema],
  anomalies: [layerAnomalySchema],
  highlights: [highlightSchema],
});
