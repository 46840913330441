import { connect } from 'react-redux';
import Void from '../components/Metric/Void';
import { updateEffect, deleteEffect } from '../actions/effects';
import { getEffectById } from '../selectors/effects';
import { AppState } from '../models';

const mapStateToProps = (state: AppState, ownProps: { id: string }) => ({
  effect: getEffectById(state, ownProps),
});

const mapDispatchToProps = {
  onUpdate: updateEffect,
  onDelete: deleteEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Void);
