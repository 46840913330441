import { cond, always, equals, where, flip, contains, whereEq } from 'ramda';
import { take, select, put } from 'redux-saga/effects';
import {
  deleteObject,
  deselectObject,
  saveObject,
  updateObject,
} from '../../actions/objects';
import { enableModifier, disableModifier, types } from '../../actions/volumer';
import {
  getSelectedObjects,
  getDraftObject,
  getSelectedObjectIds,
  isEditMode,
  anyDraftObjects,
  areAnyObjectsSelected,
} from '../../selectors/selectedObjects';
import { exitEditMode } from './editMode';
import { getAllModifiers } from '../../selectors/volumer';
import { canSaveObject } from '../../utils/volumerInteractions';
import { Modifier } from '../../constants/volumer';

export default function*() {
  while (true) {
    const { payload: key } = yield take(types.KEY_DOWN);
    const state = {
      key,
      editMode: yield select(isEditMode),
      draftObject: yield select(anyDraftObjects),
      anyObjectsSelected: yield select(areAnyObjectsSelected),
    };

    const saga = getSaga(state);

    if (saga) yield* saga(state);
  }
}

const getSaga = cond([
  [
    where({
      key: flip(contains)(['Backspace', 'Delete']),
      editMode: equals(false),
    }),
    always(deleteObjectsHotkey),
  ],
  [whereEq({ key: 'Escape', draftObject: true }), always(discardDraftObject)],
  [whereEq({ key: 'Escape', editMode: true }), always(exitEditMode)],
  [
    whereEq({ key: 'Escape', anyObjectsSelected: true }),
    always(deselectObjectsHotkey),
  ],
  [whereEq({ key: 'Enter', draftObject: true }), always(saveObjectHotkey)],
  [whereEq({ key: 'KeyA' }), always(toggleEnabledHotkey)],
  [
    where({ key: flip(contains)(Object.values(Modifier)) }),
    always(modifierMonitor),
  ],
]);

function* deleteObjectsHotkey() {
  const selectedObjectIds = yield select(getSelectedObjectIds);
  for (const id of selectedObjectIds) {
    yield put(deleteObject(id));
  }
}

function* deselectObjectsHotkey() {
  const selectedObjectIds = yield select(getSelectedObjectIds);
  for (const id of selectedObjectIds) {
    yield put(deselectObject(id));
  }
}

function* discardDraftObject() {
  const draftObject = yield select(getDraftObject);
  yield put(deleteObject(draftObject.id));
}

function* saveObjectHotkey() {
  const selectedObjects = yield select(getSelectedObjects);
  for (const object of selectedObjects) {
    if (object.draft && canSaveObject(object)) {
      yield put(saveObject(object));
    }
  }
}

function* toggleEnabledHotkey() {
  const selectedObjects = yield select(getSelectedObjects);
  for (const { id, state } of selectedObjects) {
    yield put(
      updateObject(id, {
        state: state === 'enabled' ? 'visible' : 'enabled',
      }),
    );
  }
}

function* modifierMonitor({ key }: { key: string }) {
  const activeModifiers = yield select(getAllModifiers);
  const isAlreadyActive = activeModifiers.includes(key);
  if (isAlreadyActive) return null;

  yield put(enableModifier(key));

  const { payload: keyUp } = yield take(types.KEY_UP);
  if (keyUp === key) yield put(disableModifier(key));
}
