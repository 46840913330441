import { takeEvery } from 'redux-saga/effects';
import { FSA } from 'flux-standard-action';
import { types } from '../../actions/inspector';
import { EventSender } from './getEventSender';

export default function*(send: EventSender) {
  yield takeEvery<FSA<any>>(types.RADIUS_UPDATE_START, function*(action) {
    yield send('radiusDragStart', action.payload.message);
  });

  yield takeEvery<FSA<any>>(types.RADIUS_UPDATE_DRAG, function*(action) {
    yield send('radiusDrag', action.payload.message);
  });

  yield takeEvery<FSA<any>>(types.RADIUS_UPDATE_STOP, function*(action) {
    yield send('radiusDragStop', action.payload.message);
  });
}
