import React from 'react';
import { Button, colors } from '@hm/ukie';
import styled from 'styled-components';

export interface ConfirmationProps {
  title?: string;
  confirmTitle?: string;
  cancelTitle?: string;
  className?: string;
  style?: any;
  onConfirm?(e?: React.SyntheticEvent<HTMLElement>): void;
  onCancel?(e?: React.SyntheticEvent<HTMLElement>): void;
}

const Confirmation: React.SFC<ConfirmationProps> = ({
  title,
  confirmTitle,
  cancelTitle,
  className,
  style,
  onConfirm,
  onCancel,
}) => {
  return (
    <div className={className} style={style}>
      {title}
      <Button onClick={onCancel}>{cancelTitle}</Button>
      <Button primary onClick={onConfirm}>
        {confirmTitle}
      </Button>
    </div>
  );
};

Confirmation.defaultProps = {
  confirmTitle: 'Proceed',
  cancelTitle: 'Cancel',
  title: 'Are you sure?',
  /* tslint:disable:no-empty */
  onConfirm: () => {},
  onCancel: () => {},
  /* tslint:enable:no-empty */
};

export default styled(Confirmation)`
  background: ${colors.warning};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;
