import { select, put } from 'redux-saga/effects';
import { deselectObject } from '../../actions/objects';
import { getSelectedObjectIds } from '../../selectors/selectedObjects';

export default function*() {
  const selectedObjectIds = yield select(getSelectedObjectIds);
  for (const id of selectedObjectIds) {
    yield put(deselectObject(id));
  }
}
