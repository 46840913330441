import { connect } from 'react-redux';
import { getLayerById, getLayerLabels } from '../../selectors/layers';
import { makeGetEffectsByLayerId } from '../../selectors/effects';
import { moveLayer, updateLayer, composeLayers } from '../../actions/layers';
import { updateManyOptions } from '../../actions/options';
import { makeGetCategoriesByLayerId } from '../../selectors/categories';
import { makeGetGradientByLayerId } from '../../selectors/gradients';
import { AppState } from '../../models';

import Metric from './Metric';

const makeMapStateToProps = () => {
  const getEffectsByLayerId = makeGetEffectsByLayerId();
  const getCategoriesByLayerId = makeGetCategoriesByLayerId();
  const getGradientByLayerId = makeGetGradientByLayerId();

  // XXX: export interface
  return (
    state: AppState,
    ownProps: { id: string; last: boolean; grouped: boolean },
  ) => ({
    metric: getLayerById(state, ownProps),
    label: getLayerLabels(state),
    effects: getEffectsByLayerId(state, ownProps),
    categories: getCategoriesByLayerId(state, ownProps),
    gradient: getGradientByLayerId(state, ownProps),
  });
};

const mapDispatchToProps = {
  move: moveLayer,
  compose: composeLayers,
  updateLayer,
  updateManyOptions,
};

// FIXME: couldn’t infer types when passing mapStateToProps function
export default connect<any, any, any>(makeMapStateToProps, mapDispatchToProps)(
  Metric,
);
