import { TimePicker, Classes } from '@blueprintjs/datetime';
import styled from 'styled-components';

export default styled(TimePicker)`
  padding: 0;

  & .${Classes.TIMEPICKER_INPUT_ROW} {
    border: none !important;
    box-shadow: none !important;
  }

  & .${Classes.TIMEPICKER_INPUT} {
    box-sizing: border-box;
    height: 22px !important;
    border-radius: 0 !important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey5} !important;
    margin-bottom: 2px;

    &:focus,
    &:hover {
      border-width: 2px !important;
      margin-bottom: 1px;
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.blue1} !important;
    }
  }

  & .${Classes.TIMEPICKER_DIVIDER_TEXT}, & .${Classes.TIMEPICKER_INPUT} {
    color: ${({ theme }) => theme.colors.grey1} !important;
    box-shadow: none !important;
  }
`;
