import { colors } from '@hm/ukie';
import { Box } from 'grid-styled';

interface Props {
  selected?: boolean;
}

export default Box.extend.attrs({
  px: 2,
})`
  background: ${({ selected }: Props) =>
    selected ? colors.primary : colors.white};
  color: ${({ selected }: Props) =>
    selected ? colors.white : colors.defaultColor};
  height: 24px;
  line-height: 2;
  cursor: default;
  &:hover {
    background: ${({ selected }: Props) =>
      selected ? colors.blue2 : colors.grey3};
  }
`;
