import { bisector } from 'd3-array';
import { Layer } from '../reducers/layers';
import { Category } from '../reducers/categories';

export const bisectX = <TData extends { x: any }>(
  data: TData[],
  value: any,
): TData => {
  const i = bisector<any, TData>(d => d.x).left(data, value);

  return data[i];
};

export const isComposite = (layer: Layer | { type: string }) =>
  layer.type === 'composite';

export const isSingleOption = (categories: Category[]) => {
  return categories.length === 1 && categories[0].options.length === 1;
};
