import { defineMessages } from 'react-intl';

/* tslint:disable:max-line-length */
export default defineMessages({
  selectType: {
    id: 'Project.selectType',
    defaultMessage: 'Project type settings',
  },
  startNew: {
    id: 'Project.startNew',
    defaultMessage: 'Create a new project in the {environment} environment.',
  },
  regularDescription: {
    id: 'Project.regularDescription',
    defaultMessage: `Project with specific time range allows analyzing various data Metrics distributed over time. It can contain all types of Metrics.`,
  },
  approximatedDescription: {
    id: 'Project.approximatedDescription',
    defaultMessage: `Project with unified time format contains data Metrics approximated to an average or sum. Approximation allows analysing and comparing Metrics with different slice duration or time intervals. All types of Metrics can be added to the project after compulsory approximation.`,
  },
});
