import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage as F } from 'react-intl';
import { Slider } from '@hm/ukie';
import { scaleLinear } from 'd3-scale';
import { Box } from 'grid-styled';
import SliderTick from './SliderTick';
import SettingsRow from './SettingsRow';
import {
  verticalScalingDrag,
  verticalScalingDragStart,
  verticalScalingDragStop,
} from '../actions/projects';
import { getVerticalScaling, getCameraMode } from '../selectors/project';
import { AppState } from '../models';

interface Props {
  value: number;
  onDrag(value: number);
  onDragStart();
  onDragStop();
}

const scale = scaleLinear()
  .domain([0, 50, 100])
  .range([0.1, 1, 10]);

export const TimeAxisScaling: React.SFC<Props> = ({
  value,
  onDrag,
  onDragStart,
  onDragStop,
  ...rest
}) => (
  <SettingsRow pin="scaling">
    <Box flex="0 0 auto" mr={2}>
      <F id="Volumer.timeScaling" defaultMessage="Time axis scaling" />
    </Box>
    <Box flex="0 1 280px" mt={2} mr={3}>
      <Slider
        algorithm={{ getPosition: scale.invert, getValue: scale }}
        pitPoints={[0.1, 1, 10]}
        values={[value]}
        onSliderDragStart={onDragStart}
        onValuesUpdated={({ values }) => onDrag(values[0])}
        onSliderDragEnd={onDragStop}
        pitComponent={props => (
          <SliderTick isLast={props.children === 10} {...props} />
        )}
      />
    </Box>
  </SettingsRow>
);

export default connect(
  (state: AppState) => ({
    is3DMode: getCameraMode(state) === '3D',
    value: getVerticalScaling(state),
  }),
  {
    onDrag: verticalScalingDrag,
    onDragStart: verticalScalingDragStart,
    onDragStop: verticalScalingDragStop,
  },
)(
  ({ is3DMode, ...props }: any) =>
    is3DMode ? <TimeAxisScaling {...props} /> : null,
);
