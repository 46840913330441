import { connect } from 'react-redux';
import TimelineActions from '../components/TimelineActions';
import { AppState } from '../models';
import { editProject } from '../actions/modals';

import {
  changeBehaviour,
  changeMode,
  changeSpeed,
  startPlayback,
  stopPlayback,
} from '../actions/timeline';

import {
  computeMode,
  getIsPlaying,
  getBehaviour,
  getSpeed,
  computeCanPlay,
} from '../selectors/timeline';

import { getCameraMode } from '../selectors/project';

const mapStateToProps = (state: AppState) => ({
  mode: computeMode(state),
  speed: getSpeed(state),
  behaviour: getBehaviour(state),
  canPlay: computeCanPlay(state),
  isPlaying: getIsPlaying(state),
  disableRangeMode: getCameraMode(state) === '2D',
});

const mapDispatchToProps = {
  editProject,
  onModeChange: changeMode,
  onSpeedChange: changeSpeed,
  onBehaviourChange: changeBehaviour,
  start: startPlayback,
  stop: stopPlayback,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimelineActions);
