import React from 'react';
import { Control, ControlProps } from 'react-redux-form';
import { Input } from '@hm/ukie';

export const mapInvalidProp = ({ fieldValue }) =>
  !fieldValue.valid && !fieldValue.pristine;

export const CustomControlText: React.SFC<ControlProps<any>> = ({
  mapProps,
  ...props
}) => (
  <Control.text
    mapProps={{
      invalid: mapInvalidProp,
      ...mapProps,
    }}
    {...props}
  />
);

CustomControlText.defaultProps = {
  component: Input,
};
