import { types } from '../actions/inspector';

export interface InspectorState {
  isMinimized: boolean;
  isMenuOpen: boolean;
  isTruncated: boolean;
  showMore: boolean; // XXX: Temporary (until favourites mechanism in toolbar)
}

export const initialState: InspectorState = {
  isMinimized: false,
  isMenuOpen: false,
  isTruncated: true,
  showMore: false,
};

export default (
  state: InspectorState = initialState,
  action: any,
): InspectorState => {
  const { type, payload } = action;

  switch (type) {
    case types.MINIMIZE_INSPECTOR:
      return { ...state, isMinimized: payload };

    case types.TOGGLE_MENU:
      return { ...state, isMenuOpen: payload };

    case types.TOGGLE_TRUNCATION:
      return { ...state, isTruncated: payload };

    case types.TOGGLE_SHOW_MORE:
      return { ...state, showMore: payload };

    default:
      return state;
  }
};
