import React from 'react';
import { TransitionMotion, spring } from 'react-motion';
import { Box, Flex } from '@hm/ukie';

import { fastSpring } from '../../utils/helpers';

const maybeWillLeave = (shouldAnimate: boolean) => () =>
  shouldAnimate
    ? {
        opacity: spring(0, fastSpring),
      }
    : { opacity: 1 };

const initialStyle = { opacity: spring(1, fastSpring) };

interface Tileable {
  id: string;
  [index: string]: any;
}

interface TilesProps {
  tiles: Tileable[];
  /** Disables animation when prev !== next */
  collectionId?: any;
  renderTile(tile: any): React.ReactNode;
}

interface State {
  shouldAnimate: boolean;
  prevCollectionId: string;
}

class Tiles extends React.Component<TilesProps, State> {
  static defaultProps = { tiles: [] };

  readonly state: State = { shouldAnimate: true, prevCollectionId: null };

  static getDerivedStateFromProps(
    nextProps: TilesProps,
    prevState: State,
  ): Partial<State> {
    return {
      shouldAnimate: prevState.prevCollectionId === nextProps.collectionId,
      prevCollectionId: nextProps.collectionId,
    };
  }

  render() {
    const { tiles, renderTile } = this.props;

    return (
      <Box mt={2}>
        <TransitionMotion
          willLeave={maybeWillLeave(this.state.shouldAnimate)}
          styles={tiles.map(t => ({
            key: t.id,
            data: t,
            style: initialStyle,
          }))}
        >
          {interpolatingTiles => (
            <Flex flexWrap="wrap" m="-4px">
              {interpolatingTiles.map(tile => (
                <Box p="4px" key={tile.data.id} style={tile.style}>
                  {renderTile(tile.data)}
                </Box>
              ))}
            </Flex>
          )}
        </TransitionMotion>
      </Box>
    );
  }
}

export default Tiles;
