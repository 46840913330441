import moment from 'moment';
import 'moment-timezone';
/* tslint:disable-next-line */
import { Duration, Moment, unitOfTime } from 'moment';
import { DateRange } from 'moment-range';
import {
  compose,
  times,
  map,
  flatten,
  equals,
  init,
  isNil,
  last,
  splitEvery,
  unfold,
  sum,
  minBy,
  reduce,
  toPairs,
  until,
  curry,
  all,
  chain,
} from 'ramda';

import { maxValue } from './helpers';
import { ZoomLevel } from '../models';

/**
 * Returns nested array of month’s dates grouped by week
 */
export const monthByWeek = (date: Moment) => {
  return splitEvery(
    7,
    Array.from(
      new DateRange(
        date
          .clone()
          .startOf('month')
          .startOf('isoWeek'),
        date
          .clone()
          .endOf('month')
          .endOf('isoWeek'),
      ).by('day'),
    ),
  );
};

export const sliceBordersFromIntervals = (next: (prev: Moment) => Moment) => (
  intervals: DateRange[],
) =>
  flatten<Moment>(
    intervals.map(({ start, end }) =>
      unfold(
        step =>
          !isNil(step) && step.isSameOrBefore(end)
            ? [step.clone(), next(step)]
            : false,
        start,
      ),
    ),
  );

export const toUTCArray = ({ start, end }: DateRange) => [
  start.toISOString(),
  end.toISOString(),
];

export const toISOInterval = ({ start, end }: DateRange) =>
  `${start.toISOString()}/${end.toISOString()}`;

export const toUnitOfTimeBase = (
  input: unitOfTime.MomentConstructor,
): unitOfTime.Base => {
  switch (input) {
    case 'date':
      return 'day';
    case 'dates':
      return 'days';
    case 'D':
      return 'd';
    default:
      return input;
  }
};

/**
 * Returns numeric value contained in duration string
 * P15D => 15
 */
export const getDurationMagnitude = (d: Duration) =>
  parseInt(d.toISOString().match(/\d+/)[0]);

/**
 * Returns duration string without the numeric value
 * P15D => PD
 */
export const getDurationType = (d: Duration) =>
  d.toISOString().replace(/\d+/, '');

/**
 * Determines if slice duration can be divided by initially provided one
 */
export const isDivisibleBy = (divider: Duration) => (dividend: Duration) => {
  const isSameType = getDurationType(divider) === getDurationType(dividend);

  return isSameType
    ? getDurationMagnitude(dividend) % getDurationMagnitude(divider) === 0
    : dividend.asMilliseconds() >= divider.asMilliseconds();
};

export const overlapsWith = (
  intervals: DateRange[],
  date: Moment,
  duration: unitOfTime.Base | Duration,
) => {
  const end = moment.isDuration(duration)
    ? date.clone().add((duration as Duration).asSeconds(), 'seconds')
    : date.clone().add(1, duration as unitOfTime.Base);

  const targetInterval = new DateRange(date.clone(), end);

  return intervals.some(interval => interval.overlaps(targetInterval));
};

/**
 * transformer function that glues together adjacent or overlapping intervals
 */
export const mergeIntervals = (intervals: DateRange[]) =>
  intervals.reduce(
    (acc, value) => {
      const prevInterval = last(acc);

      // XXX clone of ranges’s .add but with support for adjacent intervals
      if (prevInterval.overlaps(value, { adjacent: true })) {
        return [
          ...init(acc),
          new DateRange(
            moment.min(prevInterval.start, value.start),
            moment.max(prevInterval.end, value.end),
          ),
        ];
      } else {
        return [...acc, value];
      }
    },
    [intervals[0]] as DateRange[],
  );

export const invertSelection = (intervals: DateRange[], invertBy: DateRange) =>
  chain(interval => subtractInterval(interval, invertBy), intervals);

/**
 * Clone of moment-range .subtract but we pass Moment object
 * instead of valueOf to range constructor
 */
// TODO: rewrite to be more awesome
export const subtractInterval = (from: DateRange, other: DateRange) => {
  const start = from.start.valueOf();
  const end = from.end.valueOf();
  const oStart = other.start.valueOf();
  const oEnd = other.end.valueOf();

  if (from.intersect(other) === null) {
    return [from];
  } else if (oStart <= start && start < end && end <= oEnd) {
    return [];
  } else if (oStart <= start && start < oEnd && oEnd < end) {
    return [new DateRange(other.end, from.end)];
  } else if (start < oStart && oStart < end && end <= oEnd) {
    return [new DateRange(from.start, other.start)];
  } else if (start < oStart && oStart < oEnd && oEnd < end) {
    return [
      new DateRange(from.start, other.start),
      new DateRange(other.end, from.end),
    ];
  } else if (start < oStart && oStart < end && oEnd < end) {
    return [
      new DateRange(from.start, other.start),
      new DateRange(other.start, from.end),
    ];
  }

  return [];
};

/** clone of moment’s internal `_data` object */
interface DurationObject {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  // FIXME: toPairs made me do it
  [index: string]: number;
}

/** Parses ISO8601 duration or moment.Duration into DurationObject */
export const toDurationObj = (
  duration: string | moment.Duration,
): DurationObject => {
  const d = typeof duration === 'string' ? moment.duration(duration) : duration;

  return {
    years: d.years(),
    months: d.months(),
    days: d.days(),
    hours: d.hours(),
    minutes: d.minutes(),
    seconds: d.seconds(),
    milliseconds: d.milliseconds(),
  };
};

/** Same as snapToTime but works for moment-range intervals */
export const snapIntervalToTime = (
  start: moment.Moment,
  duration: moment.Duration,
  interval: DateRange,
) =>
  new DateRange(
    snapToTime(start, duration, interval.start, 'left'),
    snapToTime(start, duration, interval.end, 'right'),
  );

/**
 * Given the start time and duration aligns provided date to be quantized by
 * that duration
 */
export const snapToTime = (
  start: moment.Moment,
  duration: moment.Duration,
  date: moment.Moment,
  // TODO: make two versions: for left and right
  snapTo: 'left' | 'right' = 'left',
) =>
  until(
    (d: moment.Moment) =>
      snapTo === 'left'
        ? // left condition is that calculated time will be in previous bin, that
          // is if we add one more duration it would be after provided date
          addDuration(duration, d).isAfter(date)
        : // right condition is that it would be the first date that is after
          // provided date
          d.isSameOrAfter(date),
    curry(addDuration)(duration),
  )(start);

/** Intelligently adds duration to date */
export const addDuration = (duration: moment.Duration, date: Moment) =>
  reduce(
    (acc: moment.Moment, value: [moment.unitOfTime.Base, number]) =>
      acc.add(value[1], value[0]),
    date.clone(),
    toPairs(toDurationObj(duration)),
  );

export const contains = (
  start: Moment,
  end: Moment,
  date: Moment,
  exclusive = true,
) =>
  start.valueOf() <= date.valueOf() &&
  (end.valueOf() > date.valueOf() ||
    (end.valueOf() === date.valueOf() && !exclusive));

export const someContains = (intervals: DateRange[], date: Moment) =>
  intervals.some(({ start, end }) => contains(start, end, date));

export const countByDuration = (
  ranges: DateRange[],
  duration: moment.Duration,
) => {
  return sum(ranges.map(range => range.valueOf())) / duration.asMilliseconds();
};

export const minDuration = reduce<moment.Duration, moment.Duration>(
  minBy(d => d.valueOf() as number),
  Infinity as any,
);

// FIXME: startTime should have TZ?
export const toStartTimeDate = (startTime: string) =>
  moment(`1970-01-02T${startTime}:00Z`);

export const constructApproximationInterval = (timezone = 'UTC') => [
  new DateRange(
    moment.tz('1970-01-02', timezone),
    moment.tz('1970-01-03', timezone),
  ),
];

export const toHHmm = (d: Moment) => d.format('HH:mm');
export const toDMY = (d: Moment) => d.format('D MMMM YYYY');
export const getHours = (d: Moment) => d.format('HH');
export const getMinutes = (d: Moment) => d.format('mm');
export const toDayRangeFilterInterval = (i: DateRange) =>
  `${toHHmm(i.start)}/${toHHmm(i.end)}`;

export const isSameRange = curry(
  (a: DateRange, b: DateRange) =>
    a.start.isSame(b.start) && a.end.isSame(b.end),
);

export const isSameIntervals = curry(
  (a: DateRange[], b: DateRange[]) =>
    a.length === b.length &&
    all(equals(true))(a.map((ai, i) => isSameRange(ai, b[i]))),
);

export const parseIntervalWithTZ = (interval: string, tz: string) =>
  new DateRange(interval.split('/').map(date => moment.tz(date, tz)));

export const getNiceZoomLevel = (sliceDuration: Duration): ZoomLevel =>
  sliceDuration.asMonths() >= 1
    ? 'year'
    : sliceDuration.asDays() >= 1
      ? 'month'
      : sliceDuration.asHours() >= 1 ? 'day' : 'precise';

/**
 * Returns the number of weeks in the month that the given date belongs to
 * (Weeks start from Monday)
 */
export const weeksInMonth = (date: Moment) => {
  const firstWeekStartDay = date
    .clone()
    .startOf('month')
    .isoWeekday();

  const firstWeekDaysCount = 8 - firstWeekStartDay;
  const restDaysCount = date.daysInMonth() - firstWeekDaysCount;

  return 1 + Math.ceil(restDaysCount / 7);
};

/**
 * Number of weeks in a year with 3 column (3 month in a row) layout
 */
export const weeksInYear3Col = (date: Moment) =>
  compose(sum, map(maxValue), splitEvery(3))(
    times(n => weeksInMonth(date.clone().month(n)), 12),
  );

export const tzNames = moment.tz.names();

export const prettyTimezone = (tz: string) =>
  `${tz.replace(/_/g, ' ')} (UTC ${moment.tz(tz).format('Z')})`;

export const ISOIntervalStringToDates = (interval: string) =>
  interval.split('/').map(d => new Date(d));

const ISO_WITHOUT_TZ = 'YYYY-MM-DDTHH:mm:ss';
/**
 * Makes new date with the same values but in different timezone.
 *
 * E.g. given `2018-02-07T11:41:09+03:00` and timezone 'America/New_York' makes
 * `2018-02-07T11:41:09-05:00`
 */
export const shiftTZ = (date: any, timezone: string) =>
  new Date(moment.tz(date, timezone).format(ISO_WITHOUT_TZ));

/**
 * Companion to `shiftTZ` doing everything in another direction
 */
export const unshiftTZ = (date: any, timezone: string) =>
  moment.tz(moment(date).format(ISO_WITHOUT_TZ), timezone).toDate();
