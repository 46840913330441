import { IconButton, Theme } from '@hm/ukie';

export interface CrossProps {
  isActive?: boolean;
  theme?: Theme;
}

export default IconButton.extend`
  margin-right: 8px;

  & svg {
    transition: transform 200ms ease-out;
  }

  & use {
    transition: fill 200ms ease-out;
  }

  ${({ isActive, theme }: CrossProps) =>
    isActive
      ? `
        & svg {
          transform: rotate(45deg);
        }

        & use {
          fill: ${theme.colors.primary};
        }
    `
      : null};
`;
