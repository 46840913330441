import React from 'react';
import styled from 'styled-components';
import { typography, Slider } from '@hm/ukie';
import { ZoomLevel } from '../models';

const labels = ['Year', 'Month', 'Day', 'Precise Time'];
const values: ZoomLevel[] = ['year', 'month', 'day', 'precise'];
const pitPoints = [0, 1, 2, 3];

const Tick = styled.div`
  text-align: center;
  transform: translate(-50%, -20px);
  font-size: ${typography.smallFont};
  cursor: pointer;
`;

const Pit: React.SFC<{ style?: React.CSSProperties }> = ({
  children,
  style,
}) => <Tick style={style}>{labels[+children]}</Tick>;

interface Props {
  value: ZoomLevel;
  className?: string;
  onChange(zoomLevel: ZoomLevel): void;
}

const ZoomLevelSelector: React.SFC<Props> = ({ value, onChange, ...props }) => (
  <Slider
    {...props}
    values={[values.indexOf(value)]}
    onChange={publicState => onChange(values[publicState.values[0]])}
    snap
    max={3}
    pitComponent={Pit}
    pitPoints={pitPoints}
  />
);

export default ZoomLevelSelector;
