import React from 'react';
import styled from 'styled-components';
import { Box } from 'grid-styled';
import { Icon, colors, Spinner, Text } from '@hm/ukie';
import { FormattedMessage as F } from 'react-intl';
import { MIN_HEIGHT, MAX_HEIGHT } from './Histogram';
import { RelativeFlex } from '../Relative';

interface Props {
  progress: number;
  status?: string;
  isCompact?: boolean;
}

interface State {
  showSpinner: boolean;
}

// FIXME: remove 4px hack
// http://stackoverflow.com/questions/8600393/there-is-a-4px-gap-below-canvas-video-audio-elements-in-html5
const Body = styled.div`
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;
  width: 530px;
  height: ${({ isCompact }: { isCompact: boolean }) =>
    isCompact ? MIN_HEIGHT + 4 : MAX_HEIGHT + 4}px;
  background: ${({ theme }) => theme.colors.volumer};
  justify-content: center;
  align-items: center;
`;

const Progress = Box.extend`
  height: 4px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radius.small};
`;

const Track = Box.extend`
  height: 4px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.small};
`;

const SpinnerContainer = RelativeFlex.extend.attrs({
  justify: 'center',
  width: 1,
})`
  overflow: hidden;
`;

export default class HistogramLoader extends React.Component<Props, State> {
  state = { showSpinner: false };

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.progress >= 1)
      setTimeout(() => this.setState({ showSpinner: true }), 1000);
  }

  render() {
    const { isCompact, progress = 0, status } = this.props;

    return (
      <Body isCompact={isCompact}>
        {status === 'failed' ? (
          <Icon name="warning" color={colors.error} />
        ) : this.state.showSpinner ? (
          <SpinnerContainer>
            <Spinner size="small" />
            <Box style={{ position: 'absolute' }} mt={3}>
              <F
                id="Metric.waitMessage"
                defaultMessage="Processing data. It might take a while."
              >
                {text => <Text.Disclaimer>{text}</Text.Disclaimer>}
              </F>
            </Box>
          </SpinnerContainer>
        ) : (
          <Track>
            <Progress style={{ width: `${progress * 100}%` }} />
          </Track>
        )}
      </Body>
    );
  }
}
