import { createSelector } from 'reselect';
import { AppState } from '../models';

export const getCompassTilt = (state: AppState) => state.compass.tilt;
export const getCompassRotate = (state: AppState) => state.compass.rotate;
export const getCompassState = createSelector(
  getCompassTilt,
  getCompassRotate,
  (tilt, rotate) => ({ tilt, rotate }),
);
