import { defineMessages } from 'react-intl';

export default defineMessages({
  totalYears: {
    id: 'Calendar/Status.totalYears',
    defaultMessage: ' {years, plural, =0 {} one {# year} other {# years}}',
  },
  totalMonths: {
    id: 'Calendar/Status.totalDuration',
    defaultMessage: ' {months, plural, =0 {} one {# month} other {# months}}',
  },
  // TODO: convert to something like 'common.count.days' eventually
  // NOTE: we need to keep in mind that '4 things' and 'things' (with and
  // without count) are different formats
  days: {
    id: 'Calendar/Status.days',
    defaultMessage: ' {days, plural, =0 {} one {# day} other {# days}}',
  },
  hours: {
    id: 'Calendar/Status.hours',
    defaultMessage: ' {hours, plural, =0 {} one {# hour} other {# hours}}',
  },
  minutes: {
    id: 'Calendar/Status.minutes',
    defaultMessage:
      ' {minutes, plural, =0 {} one {# minute} other {# minutes}}',
  },
  empty: {
    id: 'HumanizedIntervals.empty',
    defaultMessage: 'Empty',
  },
});
