import React from 'react';
import { Box } from 'grid-styled';
import { FormattedMessage as F, FormattedNumber as N } from 'react-intl';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Form, Control, Errors, isValid } from 'react-redux-form';
import { connect } from 'react-redux';
import {
  Password as _Password,
  Submit,
  CheckboxToggle,
  Input as _Input,
  Text,
  FillFlex,
  Footer,
} from '@hm/ukie';

import errorMessages from '../errorMessages';

import {
  isRequired,
  isEmail,
  longEnough,
  passwordsMatch,
  errorMessages as formMessages,
} from '../../utils/validators';

import { Field, Fieldset, FieldError } from '../Form';
import TrialOptions from '../TrialOptions';
import TOS from '../TOS';

import { plan as withCardPlan } from '../TrialOptions/WithCard';
import { plan as withoutCardPlan } from '../TrialOptions/WithoutCard';
import IntlDocumentTitle from '../IntlDocumentTitle';
import CheckEmailPage from './CheckEmailPage';
import Track from '../Track';
import { signUp } from '../../actions/user';
import { AppState, ApiError } from '../../models';
import { touPage, privacyPolicyPage } from '../../constants/links';

// TODO: bring back sanity
const Input = _Input.extend`
  max-width: 240px;
`;

const Password = _Password.extend`
  max-width: 240px;
`;

interface OwnProps {
  name: string;
  email: string;
  canSubmit: boolean;
  withCard: boolean;
  inviteToken: string;
  canShowMatchError: boolean;
  signUp(signUp: any): Promise<any>;
}

interface State {
  success: boolean;
  error: ApiError;
  isSubmitting: boolean;
}

type Props = OwnProps & RouteComponentProps<any>;

class SignUpForm extends React.Component<Props, State> {
  state = {
    isSubmitting: false,
    error: null,
    success: false,
  };

  password: HTMLInputElement;
  passwordConfirmation: HTMLInputElement;
  name: HTMLInputElement;

  onSubmit = async ({
    name,
    email,
    password,
    passwordConfirmation,
    agreedToTermsOfUse,
    agreedToReceiveMarketingEmails,
    withCard,
  }) => {
    this.setState({ isSubmitting: true }, () => {
      this.props
        .signUp({
          email,
          name,
          password,
          passwordConfirmation,
          agreedToTermsOfUse,
          agreedToReceiveMarketingEmails,
          withCard,
        })
        .then(() => {
          this.setState({ success: true, isSubmitting: false });
        })
        .catch(({ error }) => this.setState({ error, isSubmitting: false }));
    });
  };

  render() {
    const { canSubmit, withCard } = this.props;
    const { success, error } = this.state;
    const { hours, gb } = withCard ? withCardPlan : withoutCardPlan;

    if (success) return <CheckEmailPage />;

    return (
      <FillFlex flexDirection="column" flex={1} style={{ overflowY: 'auto' }}>
        <Box style={{ overflowY: 'auto' }} flex={1} width="100%">
          <Box width={512} mx="auto" mt={3}>
            <F id="SignUp.welcome" defaultMessage="Welcome to Chronotope!">
              {t => (
                <Text is="div" mb={1} fontSize={2}>
                  {t}
                </Text>
              )}
            </F>

            <F
              id="SignUp.subtitle"
              defaultMessage="Sign up to start your free trial."
            >
              {t => (
                <Text is="div" mb={2}>
                  {t}
                </Text>
              )}
            </F>

            <Form
              model="signup"
              onSubmit={this.onSubmit}
              {...{ id: 'signup' } as any}
              validators={{
                '': { passwordsMatch },
                name: { isRequired },
                password: { isRequired, passwordLength: longEnough(8) },
                email: { isRequired, isEmail },
                passwordConfirmation: { isRequired },
                agreedToTermsOfUse: { isRequired: Boolean },
              }}
            >
              <F id="SignUp.profile" defaultMessage="Profile">
                {t => (
                  <Text is="div" fontWeight="semibold" mb={2}>
                    {t}
                  </Text>
                )}
              </F>

              <Box mt={2} mb={3}>
                <Field
                  label={
                    <F
                      id="SignUp.name"
                      defaultMessage="Full name"
                      tagName="div"
                    />
                  }
                >
                  <Control.text
                    component={Input}
                    mapProps={{
                      invalid: ({ fieldValue }) =>
                        !fieldValue.valid && !fieldValue.pristine,
                    }}
                    model=".name"
                    getRef={(el => (this.name = el)) as any}
                  />
                  <Errors
                    show={({ focus, pristine }) => focus && !pristine}
                    model=".name"
                    messages={formMessages.name}
                    wrapper={props => (
                      <FieldError target={this.name} {...props} />
                    )}
                    component="div"
                  />
                </Field>

                <Field
                  label={
                    <F id="SignUp.email" defaultMessage="Email" tagName="div" />
                  }
                >
                  <Control.text
                    component={Input}
                    model=".email"
                    mapProps={{
                      invalid: ({ fieldValue }) =>
                        !fieldValue.valid && !fieldValue.pristine,
                    }}
                  />
                </Field>

                <Field
                  label={
                    <F
                      id="SignUp.password"
                      defaultMessage="Password"
                      tagName="div"
                    />
                  }
                >
                  <Control.text
                    component={Password}
                    model=".password"
                    mapProps={{
                      invalid: ({ fieldValue }) =>
                        !fieldValue.valid && !fieldValue.pristine,
                    }}
                    getRef={(el => (this.password = el)) as any}
                  />
                  <Errors
                    show={({ focus, pristine }) => focus && !pristine}
                    model=".password"
                    messages={formMessages.password}
                    wrapper={props => (
                      <FieldError target={this.password} {...props} />
                    )}
                    component="div"
                  />
                </Field>

                <Field
                  label={
                    <F
                      id="SignUp.confirmPassword"
                      defaultMessage="Confirm password"
                      tagName="div"
                    />
                  }
                >
                  <Control.text
                    component={Password}
                    model=".passwordConfirmation"
                    mapProps={{
                      invalid: ({ fieldValue }) =>
                        !fieldValue.valid && !fieldValue.pristine,
                    }}
                    getRef={(el => (this.passwordConfirmation = el)) as any}
                  />
                  <Errors
                    show={({ focus, pristine }) => focus && !pristine}
                    model=".passwordConfirmation"
                    messages={formMessages.passwordConfirmation}
                    component={props => (
                      <FieldError
                        target={this.passwordConfirmation}
                        {...props}
                      />
                    )}
                  />
                  {this.props.canShowMatchError ? (
                    <Errors
                      show={({ pristine }) => !pristine}
                      model="signup"
                      messages={formMessages.form}
                      wrapper={props => (
                        <FieldError
                          target={this.passwordConfirmation}
                          {...props}
                        />
                      )}
                    />
                  ) : null}
                </Field>
              </Box>

              <Box width={3 / 4}>
                <F
                  id="TrialOptions.trialInfo"
                  defaultMessage="You can get {trialWithCard} what requires payment source adding after e-mail confirmation. If you want to skip payment source adding, the trial period will be {trialWithoutCard}. Choose the plan to continue!"
                  values={{
                    trialWithCard: (
                      <Text
                        fontWeight="semibold"
                        lineHeight="text"
                        is="strong"
                        key="30"
                      >
                        30 days of trial
                      </Text>
                    ),
                    trialWithoutCard: (
                      <Text
                        fontWeight="semibold"
                        lineHeight="text"
                        is="strong"
                        key="15"
                      >
                        limited to 15 days
                      </Text>
                    ),
                  }}
                >
                  {t => <Text lineHeight="text">{t}</Text>}
                </F>
              </Box>

              <Control
                model=".withCard"
                component={TrialOptions}
                mapProps={{
                  withCard: ({ viewValue }) => viewValue,
                }}
              />

              <Box width={3 / 4} mt={3}>
                <Text lineHeight="text">
                  <F
                    id="TrialOptions.afterTrial"
                    defaultMessage="After trial expiration you’ll be automatically subscribed to Chronotope Basic and fees will be charged immediately:"
                  />
                </Text>
              </Box>

              <Box my={2}>
                <F
                  id="TrialOptions.monthlyCost"
                  tagName="div"
                  defaultMessage="Monthly cost — {cost}"
                  values={{
                    cost: (
                      <N style="currency" value={29} currency="USD">
                        {text => (
                          <Text.Important is="strong">{text}</Text.Important>
                        )}
                      </N>
                    ),
                  }}
                />

                <F
                  id="TrialOptions.overageCost"
                  tagName="div"
                  defaultMessage="Overage hour cost — {cost}/hour"
                  values={{
                    cost: (
                      <N style="currency" value={2.2} currency="USD">
                        {text => (
                          <Text.Important is="strong">{text}</Text.Important>
                        )}
                      </N>
                    ),
                  }}
                />
              </Box>

              <Fieldset>
                <F
                  tagName="div"
                  id="SignUp.planIncludes"
                  defaultMessage="The plan will include next capacities:"
                />

                <Text my={1} fontSize={2}>
                  <F
                    id="SignUp.includesStorage"
                    defaultMessage="{gb} of data storage"
                    values={{
                      gb: (
                        <Text fontWeight="semibold" fontSize="medium">
                          {gb < 1 ? `${gb * 1024} MB` : `${gb} GB`}
                        </Text>
                      ),
                    }}
                  />
                </Text>

                <Text is="div" fontSize="medium">
                  <F
                    id="SignUp.includesHours"
                    defaultMessage="{hours} per month"
                    values={{
                      hours: (
                        <Text fontWeight="semibold" fontSize="medium">
                          {hours} hrs
                        </Text>
                      ),
                    }}
                  />
                </Text>

                <Box my={3}>
                  <F
                    id="SignUp.afterRegistration"
                    defaultMessage="After registration, you can cancel the subscription anytime."
                  />
                </Box>

                <Text lineHeight="text" my={3}>
                  <F
                    id="SignUp.agreement"
                    defaultMessage="Chronotope is governed by the {agreement} and our {policy}:"
                    values={{
                      policy: (
                        <a href={privacyPolicyPage} target="_blank">
                          <F
                            id="Profile.privacyPolicy"
                            defaultMessage="Privacy Policy"
                          />
                        </a>
                      ),
                      agreement: (
                        <a href={touPage} target="_blank">
                          <F
                            id="Profile.softwareAsAService"
                            defaultMessage="Master Software-as-a-Service Agreement"
                          />
                        </a>
                      ),
                    }}
                  />
                </Text>

                {/*FIXME: deal with checkbox or link height*/}
                <TOS my={2} />

                <Control.checkbox
                  model=".agreedToTermsOfUse"
                  component={props => (
                    <CheckboxToggle {...props}>
                      <F
                        id="SignUp.understandAndAccept"
                        defaultMessage="I understand and accept the terms of Master Software-as-a-Service Agreement and Privacy Policy"
                      />
                    </CheckboxToggle>
                  )}
                />

                <Control.checkbox
                  model=".agreedToReceiveMarketingEmails"
                  component={props => (
                    <Box mt={1}>
                      <CheckboxToggle {...props}>
                        <F
                          id="SignUp.receiveEmails"
                          defaultMessage="I would like to receive emails notifying me about product update, upcoming events and other relevant news"
                        />
                      </CheckboxToggle>
                    </Box>
                  )}
                />
              </Fieldset>
            </Form>

            <IntlDocumentTitle titleId="signUp" />
          </Box>
        </Box>

        <Footer
          warning={
            error ? (
              errorMessages[error.code] ? (
                <F
                  {...errorMessages[error.code]}
                  values={{ linkToLogin: <Link to="/login">log in</Link> }}
                />
              ) : (
                error.detail || (
                  <F
                    id="SignUp.genericError"
                    defaultMessage="An error has occurred"
                  />
                )
              )
            ) : null
          }
          actions={
            <Track
              data-metrics-category="User"
              data-metrics-event-name="signupClick"
            >
              <Submit
                disabled={!canSubmit || this.state.isSubmitting}
                form="signup"
              >
                <F id="SignUp.submit" defaultMessage="Register" />
              </Submit>
            </Track>
          }
        />
      </FillFlex>
    );
  }
}

export default connect(
  (state: AppState, ownProps: any) => ({
    canSubmit: isValid(state.forms.signup),
    canShowMatchError:
      state.forms.signup.passwordConfirmation.focus &&
      state.forms.signup.passwordConfirmation.valid,
    withCard: state.forms.signup.withCard.value,
  }),
  { signUp },
)(SignUpForm);
