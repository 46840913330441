import { takeLatest, put, select } from 'redux-saga/effects';

import {
  getSelectedIntervals,
  getLayerOriginId,
  getApproximationFunction,
  getApproximationType,
  getSliceType,
} from '../../selectors/calendar';

import {
  getCurrentProjectId,
  getCurrentSliceDuration,
  isApproximated,
} from '../../selectors/project';

import { createLayer } from '../../actions/layers';
import { SUBMIT_LAYER, reset } from '../../actions/calendar';
import { hideModal } from '../../actions/modals';
import { toUTCArray } from '../../utils/time';

export default function*() {
  yield takeLatest(SUBMIT_LAYER, function*() {
    const val = yield put.resolve(createLayer(
      yield select(getCurrentProjectId),
      {
        originId: yield select(getLayerOriginId),
        type: 'approximated',
        selectedTimeIntervals: (yield select(getSelectedIntervals)).map(
          toUTCArray,
        ),
        function: yield select(getApproximationFunction),

        sliceType:
          (yield select(getCurrentSliceDuration)).asDays() < 1
            ? yield select(getSliceType)
            : 'uniform',

        // we don’t specify approximation type for approximated projects
        ...(yield select(isApproximated))
          ? undefined
          : { approximationType: yield select(getApproximationType) },
      },
    ) as any);

    // XXX: put.resolve gives us thunk result which we then dispatch as normal
    // action
    yield put(val);

    yield put(hideModal());
    yield put(reset());
  });
}
