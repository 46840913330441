import { without } from 'ramda';
import { types } from '../actions/volumer';
import { types as projectTypes } from '../actions/projects';
import { fulfill, pend } from '../utils/promise';
import { Resolution } from '../models';

export interface Volumer {
  url: string;
  preferedNode: string; // XXX: debug parameter from querystring
  currentFrame: string;
  viewportResolution: Resolution;
  modifiers: string[];
  userSelectedQuality: number;
}

export const initialState: Volumer = {
  url: null,
  currentFrame: null,
  viewportResolution: null,
  modifiers: [],
  preferedNode: null,
  userSelectedQuality: 0.45,
};

export default (state: Volumer = initialState, action: any): Volumer => {
  const { type, payload } = action;

  switch (type) {
    case pend(projectTypes.OPEN_PROJECT):
      return {
        ...state,
        currentFrame: null,
      };

    case fulfill(projectTypes.OPEN_PROJECT):
      return { ...state, url: payload.volumerUrl };

    case fulfill(projectTypes.GET_PROJECT):
      const project = payload.entities.projects[payload.result];
      return {
        ...state,
        userSelectedQuality: project.streamerLowresQuality,
      };

    case types.SET_FRAME:
      return { ...state, currentFrame: payload };

    case types.RESIZE:
      return { ...state, viewportResolution: payload };

    case types.ENABLE_MODIFIER:
      return { ...state, modifiers: state.modifiers.concat(payload) };

    case types.DISABLE_MODIFIER:
      return {
        ...state,
        modifiers: without([payload], state.modifiers),
      };

    case types.UPDATE_QUALITY_DRAG:
      return { ...state, userSelectedQuality: payload.message.quality };

    case types.DISCONNECT:
      return initialState;

    default:
      return state;
  }
};
