import React from 'react';
import { WhiteButton } from '@hm/ukie';

const BigButton = WhiteButton.extend`
  width: 32px;
  height: 32px;
  padding: 0;
`;

interface Props {
  onClick();
}

export default class BurgerButton extends React.Component<Props, never> {
  render() {
    return (
      <div className="project-menu dropdown-target">
        <BigButton icon="burger" onClick={this.props.onClick} />
      </div>
    );
  }
}
