import styled from 'styled-components';
import { colors } from '@hm/ukie';

interface Props {
  cursor?: string;
  color?: string;
}

const Handle = styled.circle`
  cursor: ${(props: Props) => props.cursor};
  fill: ${(props: Props) => props.color};
  stroke: white;
`;

Handle.defaultProps = {
  r: 5,
  cursor: 'default',
  color: colors.primary,
};

export default Handle;
