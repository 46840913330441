import React from 'react';
import {
  Dialog,
  Button,
  RadioButtonGroup,
  ModeButton,
  Footer,
  Submit,
} from '@hm/ukie';
import { FormattedMessage } from 'react-intl';
import { Flex } from 'grid-styled';
import { withStateHandlers } from 'recompose';

import { ApproximationFunction, SliceType } from '../../reducers/calendar';
import { ApproximationType } from '../../reducers/currentProject';
import Scrollers from '../Calendar/Scrollers';
import Settings from '../../containers/Calendar/Settings';
import InnerFooter from '../../containers/Calendar/InnerFooter';
import Status from '../../containers/Calendar/Status';
import ApproximationTypeHelper from '../Calendar/ApproximationTypeHelper';
import withDisabled from '../withDisabled';
import Title, { Lead } from '../Title';
import { RelativeFlex } from '../Relative';

import SliceTypeControl from '../ApproximatedNewMetric/SliceTypeControl';
import messages from '../ApproximatedNewMetric/messages';

const Toggle = ModeButton.extend`
  margin: 0 8px;
`;

const MutedPart = Flex.extend`
  ${withDisabled};
`;

export interface StoreProps {
  isValid: boolean;
  approximationType: ApproximationType;
  approximationFunction: ApproximationFunction;
  sliceType: SliceType;
  sliceDuration: string;
  isSliceTypeEnabled: boolean;
  isSliceTypeStatic: boolean;
}

export interface DispatchProps {
  onApproximationType(type: ApproximationType): void;
  onApproximationFunction(fn: ApproximationFunction): void;
  onSliceType(sliceType: SliceType): void;
  onClose?(): void;
  onSubmit(): void;
}

export interface OwnProps {
  onBack?(): void;
}

interface State {
  currentSlide: string;
}

interface Updaters {
  onApproximationSliderToggle(): void;
  hideSlide(): void;
}

type ProvidedProps = State & Updaters;

export type Props = StoreProps & DispatchProps & OwnProps & ProvidedProps;

const ApproximateIntoRegular: React.SFC<Props> = ({
  isValid,
  approximationType,
  sliceDuration,
  sliceType,
  isSliceTypeEnabled,
  isSliceTypeStatic,
  approximationFunction,
  currentSlide,
  hideSlide,
  onSliceType,
  onApproximationType,
  onApproximationFunction,
  onApproximationSliderToggle,
  onClose,
  onBack,
  onSubmit,
}) => (
  <Dialog
    title={
      <Title>
        <FormattedMessage {...messages.header} />
      </Title>
    }
    onBack={onBack}
    onClose={onClose}
  >
    <Flex flex="1">
      <RelativeFlex flex="1" flexDirection="column">
        <Scrollers />
        <InnerFooter />
        <ApproximationTypeHelper
          approximationType={approximationType}
          onChange={onApproximationType}
          onClose={hideSlide}
          show={currentSlide === 'approximationType'}
        />
      </RelativeFlex>

      <Settings info={messages.selectAll} currentSlide={currentSlide}>
        <Lead>
          <Flex>
            <FormattedMessage {...messages.approximationType} />
            <Toggle
              onClick={onApproximationSliderToggle}
              selected={currentSlide === 'approximationType'}
              icon="settings"
            />
          </Flex>
        </Lead>
        <RadioButtonGroup
          vertical
          onClick={onApproximationType}
          selected={approximationType}
        >
          <Button value="day">
            <FormattedMessage {...messages.day} />
          </Button>
          <Button value="week">
            <FormattedMessage {...messages.week} />
          </Button>
          <Button value="month">
            <FormattedMessage {...messages.month} />
          </Button>
          <Button value="year">
            <FormattedMessage {...messages.year} />
          </Button>
        </RadioButtonGroup>

        <MutedPart disabled={!!currentSlide} flexDirection="column">
          <Lead>
            <FormattedMessage {...messages.aggregationFunction} />
          </Lead>
          <RadioButtonGroup
            onClick={onApproximationFunction}
            selected={approximationFunction}
          >
            <Button value="sum">
              <FormattedMessage {...messages.sum} />
            </Button>
            <Button value="average">
              <FormattedMessage {...messages.average} />
            </Button>
          </RadioButtonGroup>

          {isSliceTypeEnabled ? (
            <SliceTypeControl
              sliceType={sliceType}
              onSliceType={onSliceType}
              isStatic={isSliceTypeStatic}
            />
          ) : null}
        </MutedPart>
      </Settings>
    </Flex>
    <Footer
      disabled={!!currentSlide}
      info={<Status />}
      actions={
        <Submit onClick={onSubmit} disabled={!isValid}>
          <FormattedMessage {...messages.submit} />
        </Submit>
      }
    />
  </Dialog>
);

// TODO: remove recompose handlers
export default withStateHandlers<any, any, any>(
  { currentSlide: '' },
  {
    onApproximationSliderToggle: ({ currentSlide }) => () => ({
      currentSlide: currentSlide ? '' : 'approximationType',
    }),
    hideSlide: () => () => ({ currentSlide: '' }),
  },
)(ApproximateIntoRegular);
