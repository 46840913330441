import React from 'react';
import { DraggableCore, DraggableEventHandler } from 'react-draggable';

interface Props {
  y: number;
  x: number;
  width: number;
  height: number;
  onStart: DraggableEventHandler;
  onDrag: DraggableEventHandler;
  onStop: DraggableEventHandler;
}

const ResizeHandle: React.SFC<Props> = ({
  onStart,
  onDrag,
  onStop,
  y,
  x,
  width,
  height,
}) => {
  return (
    <DraggableCore onStart={onStart} onDrag={onDrag} onStop={onStop}>
      <rect
        style={{
          fill: 'transparent',
          cursor: '-webkit-grab',
        }}
        width={width}
        y={y}
        x={x}
        height={height}
      />
    </DraggableCore>
  );
};

export default ResizeHandle;
