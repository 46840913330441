import React from 'react';
import styled from 'styled-components';
import { DashedLinkButton } from '@hm/ukie';

import { Stats, Stat } from '../../reducers/selectedObjects';
import { isPresent, isNonEmpty } from '../../utils/helpers';
import {
  absoluteShort,
  absoluteInt,
  percentFloatImplicit,
} from '../../utils/numberFormats';

import StatBlock from './StatBlock';

const Body = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const SHORT_LIST_ITEMS = 3;

const orderedList = [
  { key: 'value', title: 'Value', formatFunction: absoluteShort },
  { key: 'min', title: 'Minimum value', formatFunction: absoluteShort },
  { key: 'max', title: 'Maximum value', formatFunction: absoluteShort },
  { key: 'average', title: 'Average value', formatFunction: absoluteShort },
  { key: 'median', title: 'Median value', formatFunction: absoluteShort },
  { key: 'mode', title: 'Mode value', formatFunction: absoluteShort },
  {
    key: 'voidPercent',
    title: 'Void percentage, %',
    formatFunction: percentFloatImplicit,
  },
  {
    key: 'filledVoxelsCount',
    title: 'Number of filled voxels',
    formatFunction: absoluteInt,
  },
  {
    key: 'coverage',
    title: 'Voxels coverage, %',
    formatFunction: percentFloatImplicit,
  },
  { key: 'sum', title: 'Sum', formatFunction: absoluteShort },
  {
    key: 'sumPercent',
    title: 'Sum percentage of full dataset, %',
    formatFunction: percentFloatImplicit,
  },
];

interface Props {
  stats: Stats;
  truncate: boolean;
  showMore: boolean;
  layerGradients: { [layerId: string]: string[] };
  layerLabels: { [layerId: string]: string };
  toggleShowMore(more: boolean): void;
}

export default (props: Props) => {
  const { stats, showMore, toggleShowMore, ...passThrough } = props;

  const statsToShow = orderedList
    .filter(({ key }) => isPresent(stats[key]) && isNonEmpty(stats[key]))
    .map(({ key, ...rest }) => ({
      values: stats[key] as Stat[],
      ...rest,
    }));

  return (
    <Body>
      {statsToShow
        .slice(0, showMore ? undefined : SHORT_LIST_ITEMS)
        .map(({ values, ...rest }) => (
          <StatBlock
            key={rest.title}
            stats={values}
            {...rest}
            {...passThrough}
          />
        ))}
      {statsToShow.length > SHORT_LIST_ITEMS ? (
        <DashedLinkButton onClick={() => toggleShowMore(!showMore)}>
          {showMore ? 'Less stats' : 'More stats'}
        </DashedLinkButton>
      ) : null}
    </Body>
  );
};
