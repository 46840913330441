import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  disabled?: boolean;
}

const DateTitle: React.SFC<Props> = ({ disabled: _disabled, ...props }) => (
  <div {...props} />
);

export default styled(DateTitle)`
  opacity: ${props => (props.disabled ? 0.2 : 1)};
  margin-bottom: 5px;
`;
