import { Scrollable } from '@hm/ukie';
import { Box } from 'grid-styled';
import React from 'react';
import InternetQuality from '../InternetQuality';
import InterpolationType from '../InterpolationType';
import TimeAxisScaling from '../TimeAxisScaling';

const SettingsPanel = () => (
  <Box flex={1}>
    <Scrollable>
      <Box p={2}>
        <InternetQuality />
        <TimeAxisScaling />
        <InterpolationType />
      </Box>
    </Scrollable>
  </Box>
);

export default SettingsPanel;
