import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import { range } from 'ramda';
import { Box } from 'grid-styled';
import Year from './Year';
import { overlapsWith } from '../../utils/time';

export interface OwnProps {
  date: moment.Moment;
  style?: any;
  onClick(date: moment.Moment, innerRow?: number): void;
}

export interface ConnectedProps {
  availableIntervals: DateRange[];
  selectedIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
}

type Props = OwnProps & ConnectedProps;

export default class YearsNavigator extends React.Component<Props, never> {
  render() {
    const { date, onClick, ...props } = this.props;

    const years = range(0, 3).map(i => {
      const year = date.clone().add(i, 'year');

      return overlapsWith(this.props.availableIntervals, year, 'year') ? (
        <Year
          {...props}
          date={year}
          key={+year}
          onClick={() => onClick(year, i)}
        />
      ) : null;
    });

    return (
      <Box py={1} style={this.props.style}>
        {years}
      </Box>
    );
  }
}
