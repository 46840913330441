import { takeEvery, select, put } from 'redux-saga/effects';
import { FSA } from 'flux-standard-action';

import { types as inspectorTypes, ExportFileType } from '../actions/inspector';
import { getCurrentProjectId, getProjectLabel } from '../selectors/project';
import { get } from '../api/rest';
import { downloadByURL } from '../utils/helpers';

const fileExtensions = {
  [ExportFileType.GeoJson]: 'geojson',
  [ExportFileType.ShapeFile]: 'zip',
  [ExportFileType.Excel]: 'xslx',
};

export default function*() {
  // FIXME: dispatch FILE_DOWNLOAD with needed url
  yield takeEvery<FSA<ExportFileType>>(
    inspectorTypes.EXPORT_OBJECT_INFO,
    function*({ payload: fileType }) {
      const id = yield select(getCurrentProjectId);
      const label = yield select(getProjectLabel);
      try {
        const { value }: { value: Response } = yield put.resolve({
          type: 'FILE_DOWNLOAD',
          // FIXME: dispatch FILE_DOWNLOAD with needed url
          payload: get(
            `/projects/${id}/objectExport?export_format=${fileType}`,
          ),
        });

        value
          .blob()
          .then(blob => URL.createObjectURL(blob))
          .then(url =>
            downloadByURL(url, `${label} objects.${fileExtensions[fileType]}`),
          );
      } catch (e) {
        console.error(e);
      }
    },
  );
}
