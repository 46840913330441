import analytics from 'analytics.js';

export class GoogleAnalytics {
  name: string;
  options: any;
  userId: string;

  private loaded: boolean;
  private promise: Promise<any>;

  constructor(options = {}) {
    this.name = 'Google Analytics';
    this.loaded = false;
    this.options = options;
  }

  pageView(eventName: string, params: any) {
    return this.track(eventName, params);
  }

  user(userId) {
    return new Promise(resolve => {
      this.userId = userId;
      resolve({
        userId,
      });
    });
  }

  track(eventName: string, params: any) {
    return new Promise((resolve, reject) => {
      this._load()
        .then(() => {
          this._track(eventName, params);
          resolve({
            eventName,
            params,
          });
        })
        .catch(error => {
          console.error('GA: Failed to initialize', error);
          reject(error);
        });
    });
  }

  _track(eventName: string, params: any) {
    if (eventName === 'pageView') {
      analytics.page(params.category, params);
      return;
    }
    analytics.track(eventName, params);
  }

  _load() {
    return (
      this.promise ||
      (this.promise = new Promise(resolve => {
        if (this.loaded) {
          resolve();
        } else {
          analytics.once('ready', () => {
            this.loaded = true;
            resolve();
          });
          analytics.initialize({
            'Google Analytics': this.options,
          });
        }
      }))
    );
  }
}
