import { apply, take, call } from 'redux-saga/effects';
import { channel, buffers, Channel } from 'redux-saga';
import { volumer } from '@hm/volumer-api';
import uuid from 'uuid';
import { encodeMessage } from '../../utils/volumerInteractions';
import getNormalizedCoord from './getNormalizedCoord';
import { Pointer } from '../../models';

export type RaycastSender = (
  pointer: Pointer,
  priorities: volumer.raycast.Type[],
) => IterableIterator<any>;

export type ResponseSubscriber = <T = any>(channel: Channel<T>) => void;
/**
 * Takes a function that allows subscribing a new response channel.
 * Returns function that sends raycasts into provided WebSocket.
 * XXX: this is confusing and complicated but it should always make a new
 * channel due to the fact that redux-saga currently does not support
 * multicast channels and different raycast senders might clash because of it
 * see socket channel creation and routing to get a better idea about
 * this
 */
export default function*(
  socket: WebSocket,
  subscribeToResponses: ResponseSubscriber,
) {
  const responseChannel = yield call(channel, buffers.sliding(10));
  subscribeToResponses(responseChannel);

  return function*(pointer: Pointer, priorities: volumer.raycast.Type[]) {
    const requestId = uuid();
    const requestBinary = encodeMessage({
      raycast: {
        requestId,
        coord: yield* getNormalizedCoord(pointer),
        priority: priorities,
      },
    });

    yield apply(socket, socket.send, [requestBinary]);

    while (true) {
      const response = yield take(responseChannel);
      if (response && response.requestId === requestId) return response;
    }
  };
}
