import React from 'react';
import moment from 'moment';
import { typography } from '@hm/ukie';
import styled from 'styled-components';

interface Props {
  date: moment.Moment;
  className?: string;
}

const Title: React.SFC<Props> = ({ date, className }) => {
  return <div className={className}>{date.year()}</div>;
};

export default styled(Title)`
  font-size: ${typography.title};
  margin-bottom: 22px;
`;
