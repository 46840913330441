import React from 'react';
import { DraggableCore, DraggableEventHandler } from 'react-draggable';
import styled from 'styled-components';
import { colors } from '@hm/ukie';
import Tooltip from '../SVGTooltip';

const Line = styled.line`
  stroke: ${colors.primary};
  pointer-events: none;
`;

const Circle = styled.circle.attrs({
  r: 4,
})`
  fill: ${colors.primary};
`;

const HitCircle = styled.circle.attrs({
  r: 6,
})`
  fill: transparent;
  cursor: ew-resize;
`;

const DraggableTooltip = Tooltip.extend`
  pointer-events: auto;
  cursor: ew-resize;
`;

interface Props {
  x: number;
  height: number;
  text?: string;
  onStart?: DraggableEventHandler;
  onDrag?: DraggableEventHandler;
  onStop?: DraggableEventHandler;
}

const Slider: React.SFC<Props> = ({
  x,
  height,
  text,
  onStart,
  onDrag,
  onStop,
}) => (
  <DraggableCore onStart={onStart} onDrag={onDrag} onStop={onStop}>
    <g>
      <DraggableTooltip
        angle="bottom"
        text={text}
        transform={`translate(${x}, 18)`}
        color={colors.primary}
      />
      <Line x1={x} x2={x} y1={15} y2={height} />
      <Circle cx={x} cy={height} />
      <HitCircle cx={x} cy={height} />
    </g>
  </DraggableCore>
);

export default Slider;
