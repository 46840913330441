import React from 'react';
import { Tabs, TabItem, BorderFlex } from '@hm/ukie';
import { Flex, Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';

import { SidebarTab } from '../../reducers/sidebar';
import withHTML5Dnd from '../withHTML5Dnd';
import MetricPanelContainer from './MetricPanelContainer';
import SettingsPanel from './SettingsPanel';
import Highlights from './Highlights';
import FeatureToggle from '../FeatureToggle';

const tabComponents = {
  [SidebarTab.Settings]: <SettingsPanel />,
  [SidebarTab.Metrics]: <MetricPanelContainer />,
  [SidebarTab.Highlights]: <Highlights />,
};

const Body = Flex.extend.attrs({ flexDirection: 'column' })`
  flex: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface Props {
  selectedTab: SidebarTab;
  changeTab(tab: SidebarTab): void;
}

class Sidebar extends React.Component<Props> {
  render() {
    const { selectedTab, changeTab } = this.props;

    return (
      <BorderFlex borderLeft="1px solid" flexDirection="column" width={564}>
        <Box pt={2}>
          <FeatureToggle feature="highlights">
            {isEnabled =>
              isEnabled ? (
                <Tabs onClick={changeTab} selected={selectedTab}>
                  <TabItem key="metrics" value={SidebarTab.Metrics}>
                    <F id="Sidebar.tabMetricPanel" defaultMessage="Metrics" />
                  </TabItem>
                  <TabItem key="highlights" value={SidebarTab.Highlights}>
                    <F id="Sidebar.highlights" defaultMessage="Highlights" />
                  </TabItem>
                  <TabItem key="settings" value={SidebarTab.Settings}>
                    <F id="Sidebar.tabSettings" defaultMessage="Settings" />
                  </TabItem>
                </Tabs>
              ) : (
                <Tabs onClick={changeTab} selected={selectedTab}>
                  <TabItem key="metrics" value={SidebarTab.Metrics}>
                    <F id="Sidebar.tabMetricPanel" defaultMessage="Metrics" />
                  </TabItem>
                  <TabItem key="settings" value={SidebarTab.Settings}>
                    <F id="Sidebar.tabSettings" defaultMessage="Settings" />
                  </TabItem>
                </Tabs>
              )
            }
          </FeatureToggle>
        </Box>
        <Body>{tabComponents[selectedTab]}</Body>
      </BorderFlex>
    );
  }
}

export default withHTML5Dnd(Sidebar);
