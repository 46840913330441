import { defineMessages } from 'react-intl';

export default defineMessages({
  layerCount: {
    id: 'Project.metricsCount',
    defaultMessage:
      '{count, plural, =0 {No Metrics} one {One Metric} other {# Metrics}}',
  },
  lastUpdate: {
    id: 'Project.lastUpdate',
    defaultMessage: 'Last update: {updatedAt}',
  },
  layerCountTooltip: {
    id: 'Project.metricsCountTooltip',
    defaultMessage: 'Number of Metrics',
  },
  regular: {
    id: 'Project.regular',
    defaultMessage: 'Regular project',
  },
  approximated: {
    id: 'Project.approximated',
    defaultMessage: 'Approximated project',
  },
  autoupdated: {
    id: 'Project.autoupdated',
    defaultMessage: 'Autoupdated project',
  },
  type: {
    id: 'Project.type',
    defaultMessage: 'Project type',
  },
  selectedIntervalToolTip: {
    id: 'Project.selectedIntervalsTooltip',
    defaultMessage: 'Time range',
  },
  createdAt: {
    id: 'Project.createdAt',
    defaultMessage: 'Created at',
  },
  sliceCount: {
    id: 'Project.sliceCount',
    defaultMessage: 'Amount of slices in project',
  },
  approximationType: {
    id: 'Project.approximationType',
    defaultMessage: 'Approximation type',
  },
});
