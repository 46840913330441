import React from 'react';
import { connect } from 'react-redux';
import { volumer } from '@hm/volumer-api';
import { Box } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import { Slider, CheckboxToggle } from '@hm/ukie';
import { scaleLinear } from 'd3-scale';
import { getUserSelectedQuality } from '../selectors/volumer';
import { AppState } from '../models';
import SliderTick from './SliderTick';
import SettingsRow from './SettingsRow';

import {
  updateQualityStart,
  updateQualityDrag,
  updateQualityStop,
} from '../actions/volumer';
import { toggleAutoQuality } from '../actions/user';
import { isAutoQualityEnabled } from '../selectors/user';

const scale = scaleLinear().domain([0, 100]);

interface Props {
  value: number;
  auto: boolean;
  onStart(message: volumer.event.LowresQualityDragStart$Properties): void;
  onDrag(message: volumer.event.LowresQualityDrag$Properties): void;
  onStop(message: volumer.event.LowresQualityDragStop$Properties): void;
  toggleAuto(enabled: boolean): void;
}

const InternetQuality: React.SFC<Props> = ({
  value,
  auto,
  onStart,
  onDrag,
  onStop,
  toggleAuto,
  ...rest
}) => (
  <SettingsRow pin="quality">
    <Box flex="0 0 auto" mr={2}>
      <F id="Volumer.quality" defaultMessage="Visualization quality" />
    </Box>
    <Box flex="0 1 280px" mr={2}>
      <Slider
        disabled={auto}
        values={[scale.invert(value)]}
        onSliderDragStart={onStart as any}
        onValuesUpdated={({ values }) => onDrag({ quality: scale(values[0]) })}
        onSliderDragEnd={onStop as any}
        pitPoints={[0, 100]}
        pitComponent={({ children, ...restProps }) => (
          <SliderTick isLast={children === 100} {...restProps}>
            {children === 0 ? (
              <F id="Volumer.slow" defaultMessage="Low" />
            ) : (
              <F id="Volumer.fast" defaultMessage="High" />
            )}
          </SliderTick>
        )}
      />
    </Box>
    <Box flex="1 0 auto">
      <CheckboxToggle checked={auto} onChange={() => toggleAuto(!auto)}>
        <F id="InternetQuality.auto" defaultMessage="Auto" />
      </CheckboxToggle>
    </Box>
  </SettingsRow>
);

export default connect(
  (state: AppState) => ({
    auto: isAutoQualityEnabled(state),
    value: getUserSelectedQuality(state),
  }),
  {
    onStart: updateQualityStart,
    onDrag: updateQualityDrag,
    onStop: updateQualityStop,
    toggleAuto: toggleAutoQuality,
  },
)(InternetQuality);
