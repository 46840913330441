import React from 'react';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { ThemeProvider } from 'styled-components';
import { theme } from '@hm/ukie';
import { IntlProvider } from 'react-intl';
import { ConnectedRouter as Router } from 'react-router-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { metrics } from 'react-metrics';
import { merge } from 'ramda';

import metricsConfig from '../metrics';
import history from '../history';
import messages from '../translations/locales/en.json';
import configureStore from '../configureStore';
import { isLoggedIn, getRefreshToken, getAccessToken } from '../utils/auth';
import { parseQs } from '../utils/helpers';
import { initialState as initialVolumer } from '../reducers/volumer';
import { initialState as initialUser } from '../reducers/user';

import AuthRoute from './AuthRoute';
import MembersRoute from './MembersRoute';
import _Layout from './Layout';
import NotFound from './NotFound';
import Project from './Project';
import SignUp, { SignUpInvited } from './SignUpForm';
import SignUpConfirmation from './SignUpConfirmation';
import Profile from './Profile';
import Unsubscribe from './Unsubscribe';
import LayerConfig from './MetricConfig';
import MetricUpload from './MetricUpload';
import Welcome from './Welcome';
import TOU from './TOU';

import Explorer from './Explorer';
import SignIn from './SignInForm';
import Logout from '../containers/Logout';
import { STRIPE_PUBLIC_KEY } from '../keys';
import FeatureToggle from './FeatureToggle';

const query = parseQs();

const user = merge(initialUser, {
  isLoggedIn: true,
  accessToken: getAccessToken(),
  refreshToken: getRefreshToken(),
});

const volumer = merge(initialVolumer, {
  preferedNode: query.volumer_url,
});

const initialState = {
  user: isLoggedIn() ? user : undefined,
  volumer: query.volumer_url ? volumer : undefined,
};

const store = configureStore(initialState);

const Layout = metrics(metricsConfig, { useTrackBinding: false })(_Layout);

const App: React.SFC = () => (
  <IntlProvider messages={messages} locale="en">
    <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router history={history}>
            <Route>
              {routeProps => (
                <Layout {...routeProps}>
                  <FeatureToggle feature="datasetsTab">
                    {isEnabled => (
                      <Switch>
                        <Route path="/tou" component={TOU} />
                        <Route path="/invite" component={SignUpInvited} />
                        <Route path="/signup" component={SignUp} />
                        <Route
                          path="/signupConfirm"
                          component={SignUpConfirmation}
                        />
                        <Route path="/login" component={SignIn} />
                        <Route path="/logout" component={Logout} />
                        <Redirect
                          from="/profile/password"
                          exact
                          to={{
                            pathname: '/login/reset',
                            search: routeProps.location.search,
                            state: { from: routeProps.location },
                          }}
                        />
                        <AuthRoute
                          exact
                          path={`/(projects|metrics${
                            isEnabled ? '|datasets' : ''
                          })`}
                          component={Explorer}
                        />
                        <Redirect
                          from="/layers"
                          exact
                          to={{
                            pathname: '/metrics',
                            search: routeProps.location.search,
                            state: { from: routeProps.location },
                          }}
                        />
                        <Redirect exact path="/" to="/projects" />
                        <MembersRoute
                          path="/import/:uploadId"
                          component={LayerConfig}
                          key="metric"
                        />
                        <MembersRoute
                          path="/import"
                          exact
                          component={MetricUpload}
                          key="upload"
                        />
                        <AuthRoute path="/welcome" component={Welcome} />
                        <AuthRoute path="/profile" component={Profile} />
                        <MembersRoute
                          path="/unsubscribe"
                          component={Unsubscribe}
                        />
                        <MembersRoute
                          path="/projects/:projectId"
                          component={Project}
                        />
                        <Route path="*" component={NotFound} />
                      </Switch>
                    )}
                  </FeatureToggle>
                </Layout>
              )}
            </Route>
          </Router>
        </Provider>
      </ThemeProvider>
    </StripeProvider>
  </IntlProvider>
);

export default App;
