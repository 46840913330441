import { post, put } from '../api/rest';
import { omit } from 'ramda';

const UPDATE_OPTION = 'UPDATE_OPTION';
const UPDATE_MANY_OPTIONS = 'UPDATE_MANY_OPTIONS';

// from chronotope-api
interface OptionsUpdates extends Array<OptionsUpdatesInner> {}
interface OptionsUpdatesInner {
  /**
   * Option id
   */
  id?: string;
  /**
   * Enable or disable subset option
   */
  enabled?: boolean;
}

export const updateOption = (id: string, updates: any) => ({
  type: UPDATE_OPTION,
  payload: put(`/options/${id}`, updates),
});

export const updateManyOptions = (updates: OptionsUpdates) => {
  const data = updates.reduce((acc, value) => {
    acc[value.id] = omit(['id'], value);
    return acc;
  }, {});

  return {
    type: UPDATE_MANY_OPTIONS,
    payload: {
      ...post('/options', updates),
      data,
    },
  };
};

export const types = {
  UPDATE_OPTION,
  UPDATE_MANY_OPTIONS,
};
