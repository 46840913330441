import { createSelector } from 'reselect';
import {
  getSubscriptionLastErrorCode,
  getIsCardValid,
  isBillingFetched,
} from '../reducers/billing';
import {
  isUserBillable,
  isUserActive,
  isUserFetched,
  getRenderAllowed,
} from '../selectors/user';

export enum GlobalAlerts {
  InactiveWithoutPayment = 'inactiveWithoutPayment',
  InactiveWithPayment = 'inactiveWithPayment',
  NoHoursLeft = 'noHoursLeft',
  InvoiceError = 'invoiceError',
  // FIXME: does not belong here
}

export const getActive = createSelector(
  isUserBillable,
  getSubscriptionLastErrorCode,
  isUserActive,
  getRenderAllowed,
  getIsCardValid,
  isUserFetched,
  isBillingFetched,
  (
    userBillable,
    invoiceError,
    userActive,
    isRenderAllowed,
    isCardValid,
    userFetched,
    billingFetched,
  ) => {
    if (!userFetched) return null;
    if (userBillable && !userActive && !isCardValid)
      return GlobalAlerts.InactiveWithoutPayment;
    if (userBillable && !userActive && isCardValid)
      return GlobalAlerts.InactiveWithPayment;
    if (isRenderAllowed === false) return GlobalAlerts.NoHoursLeft;
    if (invoiceError) return GlobalAlerts.InvoiceError;
    return null;
  },
);

export const hasErrors = createSelector(getActive, Boolean);
