import React from 'react';
import Row from './Row';
import CopyToClipboard from './CopyToClipboard';

interface Props {
  lat: number;
  lon: number;
  address: string;
}

/**
 * Info and controls for Marker objects
 */
const Address: React.SFC<Props> = ({ lat, lon, address }) => {
  const latlong = `${lat.toFixed(5)} ${lon.toFixed(5)}`;

  return (
    <Row title="Address">
      <div>
        {address}
        <CopyToClipboard text={address} />
      </div>
      <div>
        {latlong}
        <CopyToClipboard text={latlong} />
      </div>
    </Row>
  );
};

export default Address;
