import React from 'react';
import { prop, pick } from 'ramda';
import {
  CheckboxToggle,
  Button,
  Submit,
  Box,
  Text,
  Scrollable,
  Indicator,
} from '@hm/ukie';
import { LocalForm, Control, isValid } from 'react-redux-form';

import { Disabled } from '../withDisabled';
import { FormActions, Field, Fieldset, ControlSelect } from '../Form';
import { isNonEmpty } from '../../utils/helpers';
import { isRequired } from '../../utils/validators';
import { GisImportApply, BBox } from '../../reducers/gisImport';
import Map from './Map';
import { ImportForm } from './ObjectUpload';

interface Props {
  form: ImportForm;
  projectId: string;
  bBox: BBox;
  uploadId: string;
  onChange(form: any): void;
  onClear(): void;
  onSubmit(id: string, form: GisImportApply): Promise<any>;
}

interface State {
  model: any;
  form: any;
  isSubmitting: boolean;
  error: any;
}

export default class ObjectUploadForm extends React.Component<Props, State> {
  state: State = {
    model: {},
    form: {},
    isSubmitting: false,
    error: null,
  };

  onChange = model => this.setState({ model });

  onUpdate = form => this.setState({ form });

  onSubmit = form => {
    this.setState({ isSubmitting: true }, () => {
      this.props
        .onSubmit(this.props.uploadId, {
          projectId: this.props.projectId,
          layers: form.layers
            .filter(prop('enabled'))
            .map(pick(['merge', 'field', 'id'])),
        })
        .catch(error => this.setState({ error, isSubmitting: false }))
        .then(() => this.props.onClear());
    });
  };

  render() {
    const { error, isSubmitting } = this.state;
    const { form, onChange, bBox } = this.props;
    const isValidForm = isValid(this.state.form);

    return (
      <LocalForm
        model="gisImport"
        onChange={onChange}
        onUpdate={this.onUpdate}
        onSubmit={this.onSubmit}
        initialState={form}
        validators={{
          'layers[].field': isRequired,
          layers: val => val && isNonEmpty(val.filter(prop('enabled'))),
        }}
      >
        <Scrollable style={{ minHeight: 250, maxHeight: 350 }}>
          {form.layers.map((layer, i) => (
            <Fieldset key={layer.id}>
              <Field>
                <Control.checkbox
                  model={`.layers[${i}].enabled`}
                  component={CheckboxToggle}
                >
                  {layer.data.name}
                  <Indicator ml={1} bg={layer.color} />
                </Control.checkbox>
              </Field>

              <Box pl={2}>
                <Disabled disabled={!layer.enabled}>
                  <Box my={1} width={200}>
                    <label htmlFor={`layer${layer.id}`}>Label field</label>
                    <ControlSelect
                      model={`.layers[${i}].field`}
                      name={`layer${layer.id}`}
                      items={layer.data.schema.map(prop('property'))}
                    />
                  </Box>

                  <Field>
                    <Control.checkbox
                      model={`.layers[${i}].merge`}
                      component={CheckboxToggle}
                    >
                      Merge shapes by this field
                    </Control.checkbox>
                  </Field>
                </Disabled>
              </Box>
            </Fieldset>
          ))}
        </Scrollable>

        <Box my={2}>
          <Map
            layers={form.layers}
            initialBounds={[
              [bBox.leftLon, bBox.topLat],
              [bBox.rightLon, bBox.bottomLat],
            ]}
          />
        </Box>

        <FormActions>
          {error ? (
            <Box justifySelf="flex-start">
              <Text color="error">
                {error.message || 'Something went wrong'}
              </Text>
            </Box>
          ) : null}

          <Button onClick={this.props.onClear}>Cancel</Button>
          <Submit disabled={!isValidForm || isSubmitting}>Import</Submit>
        </FormActions>
      </LocalForm>
    );
  }
}
