import { select, put } from 'redux-saga/effects';
import { updateObject } from '../actions/objects';
import { updateDefaultObjectParams } from '../actions/defaultObjectParams';
import { getType } from '../selectors/inspector';
import { getSelectedObjects } from '../selectors/selectedObjects';

export default function*(action) {
  const { payload } = action;
  const objects = yield select(getSelectedObjects);

  if (objects.length > 0) {
    for (const { id } of objects) {
      yield put(updateObject(id, payload));
    }
  } else {
    const type = yield select(getType);
    yield put(updateDefaultObjectParams(type, payload));
  }
}
