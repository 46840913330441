import { colors } from '@hm/ukie';
import { isEmpty, last } from 'ramda';
import React from 'react';
import { overlapsHorizontally } from '../../utils/dimensions';
import { getBorderScale } from '../../utils/histogram';
import { absoluteFull, absoluteShort } from '../../utils/numberFormats';

interface Props {
  width: number;
  ticks: number[];
  binBorders: number[];
  transform?: string;
  overlapWith?: ClientRect[];
}

interface State {
  without?: number[];
}

// TODO: use d3.scale.ticks inside component with ability to pass user defined
// ticks with prop
export default class Ticks extends React.Component<Props, State> {
  ticks: Dict<SVGTextElement> = {};

  constructor(props) {
    super(props);

    this.state = { without: [] };
  }

  componentDidMount() {
    if (this.props.overlapWith) this.filterTicks(this.props.overlapWith);
  }

  componentWillUpdate(nextProps) {
    if (this.props.overlapWith === nextProps.overlapWith) return;
    this.filterTicks(nextProps.overlapWith);
  }

  tickAnchor(value: number) {
    const { binBorders } = this.props;

    if (value === binBorders[0]) return 'start';
    if (value === last(binBorders)) return 'end';
    return 'middle';
  }

  filterTicks(overlapWith) {
    if (isEmpty(overlapWith)) return;

    const without = this.props.ticks.reduce((acc, tick) => {
      const tickElement = this.ticks[tick];

      if (!tickElement) return acc;

      const tickRect = tickElement.getBoundingClientRect();

      if (
        this.props.overlapWith.some(element =>
          overlapsHorizontally(tickRect, element),
        )
      )
        acc.push(tick);

      return acc;
    }, []);

    this.setState({ without });
  }

  render() {
    const { ticks, binBorders, transform, width } = this.props;
    const scale = getBorderScale(binBorders.length, width);

    return (
      <g transform={transform}>
        {ticks.map(tick => (
          <text
            dy="1.5em"
            textAnchor={this.tickAnchor(tick)}
            fontFamily="Open Sans, sans-serif"
            fontSize="10px"
            fill={colors.grey5}
            x={scale(tick)}
            style={{
              visibility: this.state.without.includes(tick)
                ? 'hidden'
                : 'visible',
            }}
            key={'tick' + tick}
            ref={el => (this.ticks[tick] = el)}
          >
            <title>{absoluteFull(binBorders[tick])}</title>
            {absoluteShort(binBorders[tick])}
          </text>
        ))}
      </g>
    );
  }
}
