import { all, put, take, select } from 'redux-saga/effects';
import {
  getSelectedEntities,
  isEditMode,
} from '../../selectors/selectedObjects';
import { selectObject, deselectObject } from '../../actions/objects';
import { selectFragment, deselectFragment } from '../../actions/fragments';
import { includesId } from '../../utils/helpers';
import { types } from './drag';

/**
 * Selects dragged object if it wasn't previously selected and deselects
 * everything else
 */
export default function*() {
  while (true) {
    const { payload: objects } = yield take(types.START_DRAG);
    if (objects.length !== 1) continue;

    const [onlyObject] = objects;

    const editMode = yield select(isEditMode);
    const selectedEntities = yield select(getSelectedEntities);
    const selectAction: any = editMode ? selectFragment : selectObject;
    const deselectAction = editMode ? deselectFragment : deselectObject;

    if (includesId(onlyObject.id)(selectedEntities)) continue;

    const toDeselect = selectedEntities.map(({ id, type }) =>
      put(deselectAction(id, type)),
    );

    yield all(toDeselect);
    yield put(selectAction(onlyObject.id, onlyObject.type));
  }
}
