export default (moment: any) => {
  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
    relativeTime: {
      s: '1 second',
      m: '1 minute',
      h: '1 hour',
      d: '1 day',
      M: '1 month',
      y: '1 year',
    },
  });
};
