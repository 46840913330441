import { omit } from 'ramda';
import { get, put, post, destroy } from '../api/rest';
import { ObjectType } from '../constants/objects';
import { GeoPoint } from '../models';

const GET_OBJECT = 'objects/GET_OBJECT';
const SYNC_OBJECT = 'objects/SYNC_OBJECT';
const FORGET_OBJECT = 'objects/FORGET_OBJECT';
const SELECT_OBJECT = 'objects/SELECT_OBJECT';
const DESELECT_OBJECT = 'objects/DESELECT_OBJECT';
const CREATE_OBJECT = 'objects/CREATE_OBJECT';
const SAVE_OBJECT = 'objects/SAVE_OBJECT';
const DELETE_OBJECT = 'objects/DELETE_OBJECT';
const UPDATE_OBJECT = 'objects/UPDATE_OBJECT';
const ADD_POINT_TO_OBJECT = 'objects/ADD_POINT_TO_OBJECT';

export const getObject = (id: string) => ({
  type: GET_OBJECT,
  payload: get(`/objects/${id}`),
});

/**
 * HACK: replacement for normal notify from Concierge
 */
export const syncObject = (id: string) => ({
  type: SYNC_OBJECT,
  payload: get(`/objects/${id}`),
});

export const createObject = (projectId: string, mapObject: any) => ({
  type: CREATE_OBJECT,
  payload: {
    ...post(`/projects/${projectId}/objects`, mapObject),
    data: mapObject,
  },
});

export const deleteObject = (id: string) => ({
  type: DELETE_OBJECT,
  payload: {
    ...destroy(`/objects/${id}`),
    data: { id },
  },
});

const optimisticOnlyProperties = [
  'label',
  'state',
  'subtype',
  'radius',
  'draft',
  'selected',
  'editMode',
];

/**
 * Optimistic only improves updates of properties like object radius
 */
export const updateObject = (id: string, updates: any) => ({
  type: UPDATE_OBJECT,
  payload: {
    ...put(`/objects/${id}`, updates),
    data: { id, ...updates },
  },
  meta: { onSuccess: omit(optimisticOnlyProperties) },
});

export const selectObject = (id: string) => ({
  type: SELECT_OBJECT,
  payload: put(`/objects/${id}`, { selected: true }),
});

export const deselectObject = (id: string) => ({
  type: DESELECT_OBJECT,
  payload: {
    ...put(`/objects/${id}`, { selected: false }),
    data: { id },
  },
});

export const forgetObject = (id: string) => ({
  type: FORGET_OBJECT,
  payload: { id },
});

export const saveObject = ({ id, type }: { id: string; type: string }) => {
  const updates = {
    draft: false,
    selected: true,
    state: type === ObjectType.MARKER ? 'visible' : 'enabled',
  };

  return {
    type: SAVE_OBJECT,
    payload: {
      ...put(`/objects/${id}`, updates),
      data: { id, ...updates },
    },
  };
};

export const addPointToObject = (id: string, point: GeoPoint) => ({
  type: ADD_POINT_TO_OBJECT,
  payload: {
    ...post(`/objects/${id}/points`, point),
    data: { id, point },
  },
});

export const types = {
  GET_OBJECT,
  SYNC_OBJECT,
  FORGET_OBJECT,
  SELECT_OBJECT,
  DESELECT_OBJECT,
  CREATE_OBJECT,
  SAVE_OBJECT,
  DELETE_OBJECT,
  UPDATE_OBJECT,
  ADD_POINT_TO_OBJECT,
};
