import { createSelector } from 'reselect';
import { getSelectedTool } from './tool';
import { AppState } from '../models';

export const getDefaultObjectParams = (state: AppState) =>
  state.defaultObjectParams;

export const getDefaultObjectParamsForTool = (tool: string) =>
  createSelector(getDefaultObjectParams, params => params[tool]);

export const getDefaultObjectParamsForCurrentTool = createSelector(
  getSelectedTool,
  getDefaultObjectParams,
  (tool, params) => params[tool],
);
