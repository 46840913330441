import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { range } from 'ramda';
import Year from './Year';
import { overlapsWith } from '../../utils/time';
import { CalendarProps } from '../Calendar';

const Row = styled.div`
  padding-bottom: 16px;
`;

export interface OwnProps {
  date: moment.Moment;
  style?: any;
  getRowHeight?(height: number): void;
}

type Props = OwnProps & CalendarProps;

export default class YearsSelector extends React.Component<Props, never> {
  cacheRow = (el: HTMLDivElement) => {
    if (!el) return;
    this.props.getRowHeight(el.getBoundingClientRect().height);
  };

  render() {
    const { date, style, ...props } = this.props;

    const years = range(0, 3).map(i => {
      const year = date.clone().add(i, 'year');

      return overlapsWith(this.props.availableIntervals, year, 'year') ? (
        <Year date={year} key={year.valueOf()} {...props} />
      ) : null;
    });

    return (
      <Row style={style} innerRef={this.cacheRow}>
        {years}
      </Row>
    );
  }
}
