import { takeEvery, select, put } from 'redux-saga/effects';
import { selectRange, types } from '../actions/timeline';
import { getTimeWindow } from '../selectors/timeline';
import { getNormalizedSliceWidth } from '../selectors/project';

export default function*() {
  yield takeEvery(types.CHANGE_PLAYBACK_MODE, adjustSingle);
}

function* adjustSingle() {
  const [start, end] = yield select(getTimeWindow);
  const normSliceWidth = yield select(getNormalizedSliceWidth);

  const isReversed = start > end;
  const isWideEnough = end - start >= normSliceWidth;

  if (isReversed && isWideEnough) {
    yield put(selectRange([end, start]));
  } else if (!isWideEnough) {
    yield put(selectRange([end, end + normSliceWidth]));
  }
}
