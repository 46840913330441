import React from 'react';
import { isNil } from 'ramda';
import H from 'history';
import { parse } from 'query-string';

import Form, { ProvidedProps } from './PasswordResetForm';
import InvalidToken from './InvalidToken';

interface Props extends ProvidedProps {
  location: H.Location;
}

interface State {
  isValid: boolean;
}

export class PasswordResetFormContainer extends React.Component<Props, State> {
  state = { isValid: null };

  componentDidMount() {
    fetch(`/api/v1/users/password${this.props.location.search}`).then(r =>
      this.setState({ isValid: r.ok }),
    );
  }

  render() {
    if (isNil(this.state.isValid)) return null;
    const { location, ...props } = this.props;

    const { token } = parse(location.search.replace('?', ''));

    return this.state.isValid ? (
      <Form {...props} token={token} />
    ) : (
      <InvalidToken />
    );
  }
}

export default PasswordResetFormContainer;
