import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { CheckboxToggle, DropdownItem } from '@hm/ukie';
import { isEmpty, propEq, find } from 'ramda';
import { Effect, MinMax } from '../../models';

import FeatureToggle from '../FeatureToggle';
import Track from '../Track';
import ListItem from '../ListItem';
import { Layer } from '../../reducers/layers';
import { isComposite } from '../../utils/layers';

export interface StateProps {
  layer: Layer;
  effects: Effect[];
  isAbleToPatternize: boolean;
  absoluteValues: MinMax;
}

export interface DispatchProps {
  removeLayer(id: string);
  createEffect(id: string, type: string, defaults?: any);
  removeEffect(id: string);
  onHighlightClick(metricId: string): void;
}

type Props = StateProps & DispatchProps;

const notices = {
  patternize: 'Add one more Metric and this effect will be available',
};

const effectLabels = {
  blur: 'Trend Blur',
  patternize: 'Patternize',
  void: 'Void',
  minmax: 'MinMax',
  flip: 'Flip',
};

export default class Menu extends React.Component<Props, never> {
  handleRemove = () => {
    this.props.removeLayer(this.props.layer.id);
  };

  onClick(type: string) {
    this.effectSelected(type)
      ? this.props.removeEffect(this.findEffect(type).id)
      : this.selectEffect(type);
  }

  selectEffect(type: string) {
    const { absoluteValues, createEffect, layer } = this.props;
    // FIXME: Concierge should set these defaults itself
    const defaults = type === 'minmax' ? { settings: absoluteValues } : {};

    createEffect(layer.id, type, defaults);
  }

  effectSelected(type: string) {
    return !!this.findEffect(type);
  }

  findEffect = (type: string) => find(propEq('type', type), this.props.effects);

  effectEnabled(type: string) {
    // FIXME: reason should come from API
    if (type === 'patternize') return this.props.isAbleToPatternize;

    return true;
  }

  effectLabel(effectId) {
    const title = effectLabels[effectId] || 'Effect';

    return this.effectEnabled(effectId) ? (
      <span>{title}</span>
    ) : (
      <span>
        {title}
        <span className="dropdown-menu__item-notice">{notices[effectId]}</span>
      </span>
    );
  }

  render() {
    const { layer, onHighlightClick } = this.props;

    return (
      <div className="dropdown-menu">
        {layer.availableEffects.map(effect => (
          <DropdownItem
            onClick={() => this.onClick(effect)}
            checkbox
            disabled={!this.effectEnabled(effect)}
            key={layer.id + effect}
          >
            <Track
              data-metrics-event-name="effectApply"
              data-metrics-category="Effect"
            >
              <CheckboxToggle checked={this.effectSelected(effect)}>
                {this.effectLabel(effect)}
              </CheckboxToggle>
            </Track>
          </DropdownItem>
        ))}

        {isEmpty(layer.availableEffects) ? null : (
          <div className="dropdown-menu__delimiter" />
        )}

        <FeatureToggle feature="highlights">
          {!isComposite(layer) ? (
            <Track
              data-metrics-event-name="addHighlight"
              data-metrics-category="Highlights"
            >
              <ListItem onClick={() => onHighlightClick(layer.id)}>
                <F
                  id="Metric.addHighlight"
                  defaultMessage="Add Metric Highlight"
                />
              </ListItem>
              {/* add <F> ? */}
            </Track>
          ) : null}
        </FeatureToggle>

        <Track
          data-metrics-event-name="metricRemove"
          data-metrics-category="Metric"
        >
          <ListItem onClick={this.handleRemove}>Remove Metric</ListItem>
          {/* add <F> ? */}
        </Track>
      </div>
    );
  }
}
