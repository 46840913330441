import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Box } from 'grid-styled';
import { Text, CheckboxToggle, Grid } from '@hm/ukie';
import ResourceStatus from './ResourceStatus';
import { BillingState } from '../../reducers/billing';

interface Props {
  billingInfo: BillingState;
  toggleOverage(enabled: boolean): void;
}

const OverageHours: React.SFC<{ value: number }> = ({ value }) => (
  <React.Fragment>
    <Grid align="baseline" mr={1}>
      <Text.Header>{`+ ${value} hrs`}</Text.Header>
    </Grid>
    <Grid align="baseline">
      <F
        id="Plan.overageHoursMonth"
        defaultMessage="Overage hours in this month"
      />
    </Grid>
  </React.Fragment>
);

const PlanResources: React.SFC<Props> = ({
  billingInfo: {
    maxHours,
    usedHours,
    maxStorage,
    usedSpace,
    overageActive,
    showStorageUsage,
    showRenderHoursUsage,
    canToggleOverage,
  },
  toggleOverage,
}) => (
  <React.Fragment>
    {showRenderHoursUsage ? (
      <Box my={3}>
        <ResourceStatus
          min={0}
          value={usedHours}
          max={maxHours}
          units="hrs"
          description={
            <F id="Plan.timeLeft" defaultMessage="Time left this month" />
          }
        >
          {canToggleOverage ? (
            <React.Fragment>
              <Box>
                <CheckboxToggle
                  checked={overageActive}
                  onChange={() => toggleOverage(!overageActive)}
                >
                  <F id="Plan.autoEnlarge" defaultMessage="Automatic enlarge" />
                </CheckboxToggle>
              </Box>
              {overageActive ? (
                <Box mt={1}>
                  <OverageHours value={Math.max(usedHours - maxHours, 0)} />
                </Box>
              ) : null}
            </React.Fragment>
          ) : null}
        </ResourceStatus>
      </Box>
    ) : null}

    {showStorageUsage ? (
      <Box my={3}>
        <ResourceStatus
          min={0}
          max={maxStorage}
          value={usedSpace}
          units="GB"
          description={
            <F
              id="Plan.availableStorage"
              defaultMessage="Available data storage"
            />
          }
        />
      </Box>
    ) : null}
  </React.Fragment>
);

export default PlanResources;
