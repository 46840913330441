import React from 'react';
import { connect } from 'react-redux';
import { getProfile } from '../../actions/user';
import { getUserStorage } from '../../selectors/user';
import MetricUpload, { Props as LayerUploadProps } from './MetricUpload';
import { UserStorage } from '../../models';
import { hasErrors } from '../../reducers/alertStack';

interface Props extends LayerUploadProps {
  storage: UserStorage;
  fetchUserData(): void;
}

class MetricUploadContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchUserData();
  }

  render() {
    const { fetchUserData, storage, ...rest } = this.props;

    return storage ? (
      <MetricUpload
        maxStorage={storage.capacity}
        usedStorage={storage.used}
        hasStorageLimit={storage.limited}
        {...rest}
      />
    ) : null;
  }
}

export default connect(
  state => ({
    storage: getUserStorage(state),
    hasErrors: hasErrors(state),
  }),
  { fetchUserData: getProfile },
)(MetricUploadContainer);
