import React from 'react';
import styled from 'styled-components';
import { radius, ScrollableShadowed } from '@hm/ukie';
import { MapObject } from '../../reducers/selectedObjects';

import Header from './Header';
import ObjectParams from './ObjectParams';
import Metrics from './MetricsContainer';
import WindowButtons from './WindowButtonsContainer';
import FinalActions from './FinalActionsContainer';

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;

  & > *:not(:first-child) {
    margin-left: 12px;
  }
`;

const Window = styled.div`
  position: relative; // Otherwise looses it's white background
  width: 328px;
  background: white;
  border-radius: ${radius.big};
  box-sizing: border-box;
  padding: 16px 0;
  user-select: text;
`;

const Null = () => <div />;

interface Props {
  isMinimized: boolean;
  objects: MapObject[];
}

/**
 * Widget for inspecting Volumer objects and updating their attributes
 */
const Inspector: React.SFC<Props> = ({ isMinimized, objects }) => (
  <Window>
    <Top>
      <Header />
      {objects.length > 0 ? <WindowButtons /> : null}
    </Top>
    {!isMinimized ? (
      <ScrollableShadowed
        autoHeight
        autoHeightMin={0}
        autoHeightMax={400}
        renderTrackHorizontal={Null}
        renderView={props => (
          <div {...props} style={{ ...props.style, padding: '0 16px' }} />
        )}
      >
        <ObjectParams />
        <Metrics />
      </ScrollableShadowed>
    ) : null}
    <FinalActions />
  </Window>
);

export default Inspector;
