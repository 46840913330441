import React from 'react';
import { Flex } from 'grid-styled';
import { absoluteFull } from '../../utils/numberFormats';

interface Props {
  values: number[];
  title: string;
  format(val: number): string;
}

const Value = Flex.extend`
  font-weight: 600;
`;

const Column = Flex.extend`
  & > ${Value}:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export default ({ values, format, title }: Props) => {
  const items = values.length === 0 ? [null] : values;

  return (
    <Column flexDirection="column" width={1 / 4}>
      {items.map(val => (
        <Value
          mr={1}
          justify="center"
          title={`${title}: ${absoluteFull(val)}`}
          key={val}
        >
          {format(val)}
        </Value>
      ))}
    </Column>
  );
};
