import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { Text, Scrollable, colors, BorderFlex, Selectable } from '@hm/ukie';
import { Box, Flex } from 'grid-styled';

import ListItem from '../ListItem';

import { Environment } from '../../reducers/environments';

const Body = BorderFlex.extend.attrs({
  borderRight: '1px solid',
  flexDirection: 'column',
})`
  flex: 0 0 320px;
  background: ${colors.white};

  & ${ListItem} {
    ${({ theme }) => theme.truncate};
  }
`;

const EnvironmentsHeader = Flex.extend`
  flex-shrink: 0;
  border-bottom: 1px solid ${props => props.theme.colors.grey5};
  height: 40px;
  line-height: 1.6;
`;

const List = Box.extend.attrs({
  py: 2,
})`
  flex: 0 1 100%;
`;

interface Props {
  environments: Dict<Environment>;
  selectedId: any;
  onSelect(s: any): void;
}

const EnvironmentList: React.SFC<Props> = ({
  environments,
  selectedId,
  onSelect,
}) => (
  <Body>
    <EnvironmentsHeader px={2} flexDirection="column" justify="flex-end">
      <F id="Explorer.environmentsTitle" defaultMessage="Environments">
        {text => <Text.Title>{text}</Text.Title>}
      </F>
    </EnvironmentsHeader>
    <List>
      <Selectable selected={selectedId}>
        {selected => (
          <Scrollable>
            <ListItem
              onClick={() => onSelect(null)}
              selected={selected === null}
              key="all"
            >
              <F
                id="Explorer.allEnvironments"
                defaultMessage="All environments"
              />
            </ListItem>
            {Object.keys(environments).map(id => (
              <ListItem
                onClick={() => onSelect(id)}
                selected={selected === id}
                key={id}
                title={environments[id].name}
              >
                {environments[id].name}
              </ListItem>
            ))}
          </Scrollable>
        )}
      </Selectable>
    </List>
  </Body>
);

export default EnvironmentList;
