import React from 'react';
import { Box } from 'grid-styled';
import moment from 'moment';
import { DateRange } from 'moment-range';
import {
  AutoSizer,
  ListProps,
  ListRowRenderer,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';

import PreciseNavigator from '../../components/PreciseNavigator';
import DaysNavigator from '../../components/DaysNavigator';
import MonthsNavigator from '../../components/MonthsNavigator';
import YearsNavigator from '../../components/YearsNavigator';
import { ZoomLevel } from '../../models';
import ScrollableList from './ScrollableList';

const components = {
  precise: PreciseNavigator,
  day: DaysNavigator,
  month: MonthsNavigator,
  year: YearsNavigator,
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
});

const Container = Box.extend`
  border-right: 1px solid ${({ theme }) => theme.colors.grey5};
`;

export interface OwnProps extends Partial<ListProps> {
  onClick?(date: moment.Moment): void;
}

export interface ProvidedProps {
  dates: moment.Moment[];
  zoomLevel: ZoomLevel;
  className?: string;
  availableIntervals: DateRange[];
  selectedIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
}

type Props = ProvidedProps & OwnProps;

export default class Navigator extends React.Component<Props, never> {
  componentWillReceiveProps(props: Props) {
    if (props.zoomLevel !== this.props.zoomLevel) cache.clearAll();
  }

  rowRenderer: ListRowRenderer = ({ key, index, style, parent }) => {
    const {
      selectedIntervals,
      availableIntervals,
      filteredOutIntervals,
      zoomLevel,
      dates,
      onClick,
    } = this.props;

    const NavigatorComponent = components[zoomLevel] as React.ComponentClass<
      any
    >;

    return (
      <CellMeasurer
        cache={cache}
        rowIndex={index}
        key={key}
        columnIndex={0}
        parent={parent as any}
      >
        <NavigatorComponent
          date={dates[index]}
          style={style}
          availableIntervals={availableIntervals}
          selectedIntervals={selectedIntervals}
          filteredOutIntervals={filteredOutIntervals}
          onClick={onClick}
        />
      </CellMeasurer>
    );
  };

  render() {
    const {
      className,
      dates,
      zoomLevel,
      availableIntervals,
      selectedIntervals,
      filteredOutIntervals,
      ...props
    } = this.props;

    return (
      <Container width={200} flex="0 0 auto" className={className}>
        <AutoSizer>
          {({ width, height }) => (
            <ScrollableList
              width={width}
              height={height}
              rowHeight={cache.rowHeight}
              rowCount={dates.length}
              rowRenderer={this.rowRenderer}
              deferredMeasurementCache={cache}
              overscanRowCount={3}
              scrollToAlignment="center"
              style={{
                padding: '0 36px',
                outline: 'none',
              }}
              {...props}
            />
          )}
        </AutoSizer>
      </Container>
    );
  }
}
