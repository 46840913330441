import { volumer } from '@hm/volumer-api';
import { apply, CallEffect } from 'redux-saga/effects';
import { encodeMessage } from '../../utils/volumerInteractions';

// TODO: think about how it can be untangled.
// Seems like all this is doing is delayed payload creation of message that
// would be send to the websocket API. If it’s so then it would be simpler to
// do this inside action creator and send UInt8Array insdie action payload.

export type VolumerEvent = keyof volumer.event.Notify$Properties;

export type EventSender = (
  name: VolumerEvent,
  payload?: any,
) => IterableIterator<CallEffect>;

export default (socket: WebSocket): EventSender => {
  return function*(eventName, payload = {}) {
    yield apply(socket, socket.send, [
      encodeMessage({
        event: {
          [eventName]: payload,
        },
      }),
    ]);
  };
};
