import React from 'react';
import { FormattedDate } from 'react-intl';
import { Text, TextProps } from '@hm/ukie';
import { Omit } from 'utility-types';

type IntlDateProps = FormattedDate.Props & TextProps;

export const IntlDate: React.SFC<IntlDateProps> = ({
  value,
  format,
  localeMatcher,
  weekday,
  era,
  year,
  month,
  day,
  hour,
  minute,
  second,
  timeZoneName,
  formatMatcher,
  hour12,
  timeZone,
  ...props
}) => {
  const dateProps = {
    value,
    format,
    localeMatcher,
    weekday,
    era,
    year,
    month,
    day,
    hour,
    minute,
    second,
    timeZoneName,
    formatMatcher,
    hour12,
    timeZone,
  };

  return (
    <FormattedDate {...dateProps}>
      {d => <Text {...props}>{d}</Text>}
    </FormattedDate>
  );
};

IntlDate.defaultProps = {
  month: 'long',
  year: 'numeric',
  day: '2-digit',
  color: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
};

export default IntlDate;
