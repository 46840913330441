import { Counter, IconButton, Pictogram, Text, colors } from '@hm/ukie';
import { Box, Flex } from 'grid-styled';
import { transparentize } from 'polished';
import { isNil, map } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { deleteEffect, updateEffect } from '../../actions/effects';
import { AppState, MinMax as MinMaxValues } from '../../models';
import { getEffectById } from '../../selectors/effects';
import { getAbsoluteValuesById } from '../../selectors/layers';
import { absoluteFull, absoluteShort } from '../../utils/numberFormats';
import withDisabled from '../withDisabled';

const StyledCounter = Counter.extend`
  width: 52px;
`;

const Label = styled.span`
  margin: 0 10px;
  color: ${colors.defaultColor};
  border-bottom: 1px dashed ${colors.defaultColor};
  cursor: default;

  &:first-child {
    margin-left: 0;
  }
`;

const Actions = styled.div`
  ${withDisabled} display: flex;
  align-items: center;
  justify-content: flex-start;
`;

interface ProvidedProps {
  label: string;
  enabled: boolean;
  values: MinMaxValues;
  extent: MinMaxValues | undefined;
}

interface ProvidedHandlers {
  onRemove(id: string);
  onUpdate(id: string, updates: any);
}

interface OwnProps {
  layerId: string;
  id: string;
}

type Props = ProvidedProps & ProvidedHandlers & OwnProps;

type CounterName = 'min' | 'max';

interface State {
  min?: string;
  max?: string;
}

class MinMax extends React.Component<Props, State> {
  state = { min: '', max: '' };

  constructor(props: Props) {
    super(props);
    this.state = map(String, props.values);
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState(map(String, nextProps.values));
  }

  onIncrement = (counter: CounterName) => (value: number) => {
    if (!this.isValid(counter, value)) return;

    this.props.onUpdate(this.props.id, {
      // Passing both min and max in onUpdate is important to preserve
      // not yet saved state of another input
      settings: { ...this.state, [counter]: value },
    });
  };

  onManualInput = (counter: CounterName) => (
    e: React.FormEvent<HTMLInputElement>,
  ) =>
    this.setState({
      [counter]: e.currentTarget.value,
    });

  onReset = (counter: CounterName) => () => {
    if (!this.props.extent) return;

    this.props.onUpdate(this.props.id, {
      settings: { ...this.state, [counter]: this.props.extent[counter] },
    });
  };

  onBlur = (counter: CounterName) => (
    e: React.FocusEvent<HTMLInputElement>,
  ) => {
    if (this.isEffectInvalid()) return;
    const value = parseFloat(this.state[counter]);

    if (this.isValid(counter, value)) {
      this.props.onUpdate(this.props.id, {
        settings: { ...this.state, [counter]: value },
      });
    } else {
      this.setState({ [counter]: this.props.values[counter].toString() });
    }
  };

  isValid = (counter: CounterName, value: number) => {
    const { extent } = this.props;
    if (!extent) return true;
    // So input not in error state while the layer is being loaded

    if (!Number.isFinite(value)) return false;

    return counter === 'min'
      ? value < extent.max && value >= extent.min
      : value <= extent.max && value > extent.min;
  };

  isEffectInvalid = () => {
    const min = parseFloat(this.state.min);
    const max = parseFloat(this.state.max);

    return this.isValid('min', min) && this.isValid('max', max) && min >= max;
  };

  toggleVisibility = () =>
    this.props.onUpdate(this.props.id, { enabled: !this.props.enabled });

  remove = () => this.props.onRemove(this.props.id);

  label = (prefix: string, value: number) =>
    !isNil(value) ? `${prefix} (${absoluteShort(value)})` : prefix;

  render() {
    const { min, max } = this.state;
    const { extent, label, enabled } = this.props;
    const extentMin = extent && extent.min;
    const extentMax = extent && extent.max;

    return (
      <div className="metric-effect metric-minmax">
        <Flex flex="0 0 90px">
          <Box mr={1}>
            <IconButton
              onClick={this.toggleVisibility}
              icon={
                enabled ? (
                  <Pictogram name="eyeOpened" />
                ) : (
                  <Pictogram
                    name="eyeClosed"
                    color={transparentize(0.5, colors.grey1)}
                  />
                )
              }
            />
          </Box>
          <Text>{label}</Text>
        </Flex>
        <Actions disabled={!extent}>
          <Label title={absoluteFull(extentMin)} onClick={this.onReset('min')}>
            {this.label('min', extentMin)}
          </Label>
          <StyledCounter
            value={min}
            invalid={
              !this.isValid('min', parseFloat(min)) || this.isEffectInvalid()
            }
            onChange={this.onManualInput('min')}
            onIncrement={this.onIncrement('min')}
            onBlur={this.onBlur('min')}
            noControls
          />
          <Label title={absoluteFull(extentMax)} onClick={this.onReset('max')}>
            {this.label('max', extentMax)}
          </Label>
          <StyledCounter
            value={max}
            invalid={
              !this.isValid('max', parseFloat(max)) || this.isEffectInvalid()
            }
            onChange={this.onManualInput('max')}
            onIncrement={this.onIncrement('max')}
            onBlur={this.onBlur('max')}
            noControls
          />
        </Actions>
        <div className="metric-effect__remove">
          <IconButton icon="bin" onClick={this.remove} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { enabled, label, settings } = getEffectById(state, ownProps);

  return {
    label,
    enabled,
    values: settings,
    extent: getAbsoluteValuesById(state, { id: ownProps.layerId }),
  };
};

const mapDispatchToProps = {
  onRemove: deleteEffect,
  onUpdate: updateEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(MinMax);
