import React from 'react';
import { splitEvery, isEmpty } from 'ramda';
import moment from 'moment';
import { DateRange } from 'moment-range';
import styled from 'styled-components';
import { compose } from 'recompose';
import { Flex, Box } from 'grid-styled';

import Title from './Title';
import Month from './Month';
import shouldCalendarUpdate from '../shouldCalendarUpdate';
import withPrettySelectedIntervals from '../withPrettySelectedIntervals';
import { someContains } from '../../utils/time';
import { CalendarProps } from '../Calendar';

const Container = styled.div`
  display: inline-block;
  cursor: default;

  &:not(:last-child) {
    margin-right: 64px;
  }
`;

const EmptyMonth = styled.div`
  width: 44px;
`;

const FilterPreview = EmptyMonth.extend`
  height: 2px;
  background: ${({ theme }) => theme.colors.primary};
  margin-top: 2px;
`;

interface OwnProps {
  date: moment.Moment;
  style?: any;
}

type Props = OwnProps & CalendarProps;

export class Year extends React.Component<Props, never> {
  static defaultProps: Partial<Props> = {
    availableIntervals: [],
    filteredOutIntervals: [],
    selectedIntervals: [],
    filterPreview: [],
  };

  renderMonth = (month: moment.Moment, i: number, ary: moment.Moment[]) => {
    const {
      availableIntervals,
      selectedIntervals,
      filteredOutIntervals,
      filterPreview,
      onSelection,
      onSelectionEnd,
      onSelectionClick,
      date,
    } = this.props;

    if (!month.isSame(date, 'year')) {
      return <EmptyMonth key={month.valueOf()} />;
    }

    const isSelected =
      someContains(selectedIntervals, month) &&
      !someContains(filteredOutIntervals, month);

    const next = ary[i + 1] || month.clone().add(1, 'month');
    const prev = ary[i - 1] || month.clone().subtract(1, 'month');

    const first =
      isSelected &&
      (selectedIntervals.some(({ start }) => start.isSame(month)) ||
        someContains(filteredOutIntervals, prev));

    const last =
      isSelected &&
      (selectedIntervals.some(({ end }) => end.isSame(next)) ||
        someContains(filteredOutIntervals, next));

    const withFilter =
      !isEmpty(filterPreview) &&
      !someContains(filterPreview, month) &&
      someContains(availableIntervals, month);

    return (
      <Box
        key={month.valueOf()}
        style={{ paddingBottom: withFilter ? 0 : '4px' }}
      >
        <Month
          date={month}
          isSelected={isSelected}
          isDisabled={
            !someContains(availableIntervals, month) ||
            someContains(filteredOutIntervals, month)
          }
          ownDuration="P1M"
          onSelection={onSelection}
          onSelectionEnd={onSelectionEnd}
          onSelectionClick={onSelectionClick}
          first={first}
          last={last}
        />
        {withFilter ? <FilterPreview /> : null}
      </Box>
    );
  };

  render() {
    const { date } = this.props;

    return (
      <Container style={this.props.style}>
        <Title date={date} />
        {splitEvery(
          4,
          Array.from(
            new DateRange(
              date.clone().startOf('year'),
              date.clone().endOf('year'),
            ).by('months'),
          ),
        ).map((row, i) => (
          <Flex key={i} mb={1}>
            {row.map(this.renderMonth)}
          </Flex>
        ))}
      </Container>
    );
  }
}

export default compose<Props, Props>(
  withPrettySelectedIntervals('P1M'),
  shouldCalendarUpdate('year'),
)(Year);
