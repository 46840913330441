import { connect } from 'react-redux';

import Selector, { OwnProps } from '../../components/Calendar/Selector';
import withTouchDnd from '../../components/withTouchDnd';

import {
  getZoomLevel,
  getSliceDuration,
  computeSelectorDates,
  computeCalendarSelection,
  getAvailableIntervals,
  getFilteredOutIntervals,
  getFilterPreview,
} from '../../selectors/calendar';

import { select, endSelection, selectClick } from '../../actions/calendar';

import { AppState } from '../../models';

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  dates: computeSelectorDates(state),
  zoomLevel: getZoomLevel(state),
  sliceDuration: getSliceDuration(state),
  availableIntervals: getAvailableIntervals(state),
  filteredOutIntervals: getFilteredOutIntervals(state),
  selectedIntervals: computeCalendarSelection(state),
  filterPreview: getFilterPreview(state),
});

const mapDispatchToProps = {
  onSelection: select,
  onSelectionEnd: endSelection,
  onSelectionClick: selectClick,
};

export default withTouchDnd(
  connect(mapStateToProps, mapDispatchToProps)(Selector),
);
