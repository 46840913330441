import { createSelector } from 'reselect';
import { lensPath, view } from 'ramda';
import { AppState, UserWorkspace } from '../models';
import { SubscriptionStatus } from '../reducers/billing';

export const isUserFetched = (state: AppState) => !!state.user.info;

export const getIsLoggedIn = (state: AppState) => state.user.isLoggedIn;
export const getCurrentUser = (state: AppState) => state.user.info;
export const getCurrentUserName = (state: AppState) =>
  state.user.info ? state.user.info.name : '';
export const getWelcome = (state: AppState) => state.user.welcomeProjects;

const userWorkspaceLens = lensPath(['user', 'info', 'workspace']);
export const getUserWorkspace = view<AppState, UserWorkspace>(
  userWorkspaceLens,
);

export const isUserActive = createSelector(
  getUserWorkspace,
  workspace =>
    workspace ? workspace.billing.status === SubscriptionStatus.Active : null,
);

export const isUserBillable = createSelector(
  getUserWorkspace,
  workspace =>
    workspace ? workspace.billing.enabled && workspace.owner : false,
);

const renderAllowedLens = lensPath(['billing', 'renderAllowed']);
export const getRenderAllowed = (state: AppState) =>
  view<UserWorkspace, boolean>(renderAllowedLens, getUserWorkspace(state));

export const getUserStorage = createSelector(
  getUserWorkspace,
  workspace => (workspace ? workspace.storage : null),
);

export const getCurrentWorkspaceId = (state: AppState) =>
  view<UserWorkspace, string>(lensPath(['id']), getUserWorkspace(state));

export const isAutoQualityEnabled = (state: AppState) =>
  state.user.info && state.user.info.autoQualityEnabled;
