import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import { FormattedMessage } from 'react-intl';
import { head, last, isEmpty } from 'ramda';
import { Text } from '@hm/ukie';

import HumanizedIntervals from '../HumanizedIntervals';
import SliceCount from '../SliceCount';
import { toHHmm } from '../../utils/time';

/** Displays number of intervals and their start and end times in HH:mm format */
export const SelectedIntervals: React.SFC<{
  selectedIntervals: DateRange[];
}> = ({ selectedIntervals }) => (
  <div>
    <FormattedMessage
      id="Calendar/Status.intervals"
      defaultMessage="{intervals, plural, one {# interval} other {# intervals}} selected"
      values={{ intervals: selectedIntervals.length }}
    />
    {!isEmpty(selectedIntervals) ? (
      <span>
        {', '}
        {toHHmm(head(selectedIntervals).start)} –{' '}
        {toHHmm(last(selectedIntervals).end)}
      </span>
    ) : null}
  </div>
);

export const TotalDuration: React.SFC<{
  sliceDuration: string;
  selectedIntervals: DateRange[];
}> = props => {
  const sliceDuration = moment.duration(props.sliceDuration);

  return (
    <span>
      {!isEmpty(props.selectedIntervals) ? (
        <HumanizedIntervals
          intervals={props.selectedIntervals}
          sliceDuration={sliceDuration}
        />
      ) : null}

      {isEmpty(props.selectedIntervals) ? null : ', '}

      <SliceCount
        intervals={props.selectedIntervals}
        sliceDuration={sliceDuration}
      />
    </span>
  );
};

interface Props {
  title: string;
  sliceDuration: string;
  selectedIntervals: DateRange[];
  className?: string;
}

const Status: React.SFC<Props> = ({
  title,
  selectedIntervals,
  sliceDuration,
  className,
}) => (
  <Text lineHeight="text" fontSize={0} is="div">
    <Text fontWeight="semibold" fontSize={0} is="div" lineHeight="text">
      {title}
    </Text>
    <SelectedIntervals selectedIntervals={selectedIntervals} />
    <TotalDuration
      selectedIntervals={selectedIntervals}
      sliceDuration={sliceDuration}
    />
  </Text>
);

export default Status;
