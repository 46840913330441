import { connect } from 'react-redux';
import { compose } from 'redux';
import { lifecycle } from 'recompose';
import {
  canSubmit,
  computeCalendarSelection,
  getSliceDuration,
  getAvailableIntervals,
  computeStartTime,
  isTooManySlicesSelected,
} from '../../selectors/calendar';
import { getAvailableSliceDurations } from '../../selectors/environments';

import {
  submitProject,
  select,
  endSelection,
  selectClick,
  initNewApproximated,
  reset,
  updateStartTime,
  selectSliceDuration,
  setApproximationType,
} from '../../actions/calendar';
import withTouchDnd from '../withTouchDnd';

import NewProject, { OwnProps } from './NewApproximatedProject';
import { AppState } from '../../models';

const mapStateToProps = (state: AppState, props: OwnProps) => ({
  isValid: canSubmit(state),
  tooManySlices: isTooManySlicesSelected(state),
  selectedIntervals: computeCalendarSelection(state),
  sliceDuration: getSliceDuration(state),
  availableSliceDurations: getAvailableSliceDurations(state),
  availableIntervals: getAvailableIntervals(state),
  startTime: computeStartTime(state),
});

const mapDispatchToProps = {
  onSubmit: submitProject,
  onSelection: select,
  onSelectionEnd: endSelection,
  onSelectionClick: selectClick,
  onMount: initNewApproximated,
  onUnmount: reset,
  onStartTimeChange: updateStartTime,
  onSliceDuration: selectSliceDuration,
  onApproxitionType: setApproximationType,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount(this: any) {
      /* tslint:disable-next-line:no-invalid-this */
      this.props.onMount();
    },
    componentWillUnmount(this: any) {
      /* tslint:disable-next-line:no-invalid-this */
      this.props.onUnmount();
    },
  }),
  withTouchDnd,
)(NewProject);
