import { takeEvery, put } from 'redux-saga/effects';
import { fulfill } from '../utils/promise';
import { fetchFeatures } from '../reducers/features';
import { types as userTypes } from '../actions/user';
import { types as workspacesTypes } from '../reducers/workspaces';
import { supportedFeatures } from '../components/FeatureToggle';

export default function*() {
  yield takeEvery(
    [fulfill(userTypes.LOG_IN), fulfill(workspacesTypes.SELECT)],
    function*() {
      yield put.resolve(fetchFeatures(supportedFeatures));
    },
  );
}
