import { DateRange } from 'moment-range';
import { post } from '../api/rest';

import {
  CalendarState,
  SliceType,
  ApproximationFunction,
} from '../reducers/calendar';

import {
  createSelectionActionType,
  createSelectionEndActionType,
  createSelectionClickActionType,
  createSelectionActionCreator,
  createSelectionEndActionCreator,
  createSelectionClickActionCreator,
} from '../reducers/createSelectionReducer';

import { ZoomLevel } from '../models';

export const SELECTOR_SCROLL = 'calendar/SELECTOR_SCROLL';
export const SELECT_SLICE_DURATION = 'calendar/SELECT_SLICE_DURATION';
export const CHANGE_CALENDAR_ZOOM_LEVEL = 'calendar/CHANGE_ZOOM_LEVEL';

export const calendarPrefix = 'calendar';
export const dayRangeFilterPrefix = `${calendarPrefix}/dayRangeFilter`;

export const SELECTION = createSelectionActionType(calendarPrefix);
export const SELECTION_END = createSelectionEndActionType(calendarPrefix);
export const SELECTION_CLICK = createSelectionClickActionType(calendarPrefix);

export const DAY_RANGE_FILTER_SELECTION = createSelectionActionType(
  dayRangeFilterPrefix,
);

export const DAY_RANGE_FILTER_SELECTION_END = createSelectionEndActionType(
  dayRangeFilterPrefix,
);

export const UPDATE_LABEL = 'calendar/UPDATE_LABEL';
export const UPDATE_START_TIME = 'calendar/UPDATE_START_TIME';
export const SET_LAYER_ORIGIN_ID = 'calendar/SET_LAYER_ORIGIN_ID';
export const SET_APPROXIMATION_TYPE = 'calendar/SET_APPROXIMATION_TYPE';

export const INIT = 'calendar/INIT';
export const RESET = 'calendar/RESET';
export const SUBMIT_PROJECT = 'calendar/SUBMIT_NEW_PROJECT';

export const INIT_NEW_APPROXIMATED_LAYER = 'calendar/NEW_APPROXIMATED_LAYER';
export const SUBMIT_LAYER = 'calendar/SUBMIT_APPROXIMATED_LAYER';

export const SELECT_ALL = 'calendar/SELECT_ALL';
export const CLEAR_SELECTION = 'calendar/CLEAR_SELECTION';

export const SET_FUNCTION = 'calendar/SET_FUNCTION';
export const SET_SLICE_TYPE = 'calendar/SET_SLICE_TYPE';

export const INIT_NEW_PROJECT = 'calendar/INIT_NEW_PROJECT';
export const INIT_EDIT_PROJECT = 'calendar/INIT_EDIT_PROJECT';

export const INIT_NEW_APPROXIMATED_PROJECT =
  'calendar/INIT_NEW_APPROXIMATED_PROJECT';

export const SAVE_EDITS = 'calendar/SAVE_EDITS';

export const GET_FILTERED_OUT_INTERVALS = 'calendar/GET_FILTERED_OUT_INTERVALS';
export const SET_FILTER_SETTINGS = 'calendar/SET_FILTER_SETTINGS';
export const SET_FILTERED_OUT_INTERVALS = 'calendar/SET_FILTERED_OUT_INTERVALS';

export const FILTER_HOVER = 'calendar/FILTER_HOVER';
export const SET_FILTER_PREVIEW = 'calendar/SET_FILTER_PREVIEW';
export const FILTER_LEAVE = 'calendar/FILTER_LEAVE';

export const updateSelectorScrollTop = (scrollTop: number) => ({
  type: SELECTOR_SCROLL,
  payload: scrollTop,
});

export const selectSliceDuration = (sliceDuration: string) => ({
  type: SELECT_SLICE_DURATION,
  payload: sliceDuration,
});

export const changeZoomLevel = (zoomLevel: ZoomLevel) => ({
  type: CHANGE_CALENDAR_ZOOM_LEVEL,
  payload: zoomLevel,
});

export const select = createSelectionActionCreator(calendarPrefix);
export const endSelection = createSelectionEndActionCreator(calendarPrefix);
export const selectClick = createSelectionClickActionCreator(calendarPrefix);

export const selectDayRangeFilter = createSelectionActionCreator(
  dayRangeFilterPrefix,
);
export const selectDayRangeFilterEnd = createSelectionEndActionCreator(
  dayRangeFilterPrefix,
);

export const updateLabel = (label: string) => ({
  type: UPDATE_LABEL,
  payload: label,
});

export const updateStartTime = (startTime: string) => ({
  type: UPDATE_START_TIME,
  payload: startTime,
});

export const setApproximationType = (approximationType: string) => ({
  type: SET_APPROXIMATION_TYPE,
  payload: approximationType,
});

export const init = (state: Partial<CalendarState>) => ({
  type: INIT,
  payload: state,
});

export const reset = () => ({ type: RESET });

export const submitProject = () => ({ type: SUBMIT_PROJECT });

export const submitLayer = () => ({ type: SUBMIT_LAYER });

export const selectAll = () => ({ type: SELECT_ALL });

export const clearSelection = () => ({ type: CLEAR_SELECTION });

export const setSliceType = (sliceType: SliceType) => ({
  type: SET_SLICE_TYPE,
  payload: sliceType,
});

export const setFunction = (approximationFunction: ApproximationFunction) => ({
  type: SET_FUNCTION,
  payload: approximationFunction,
});

export const setLayerOriginId = (originId: string) => ({
  type: SET_LAYER_ORIGIN_ID,
  payload: originId,
});

export const initNew = () => ({ type: INIT_NEW_PROJECT });

export const initEdit = () => ({ type: INIT_EDIT_PROJECT });

export const initNewApproximated = () => ({
  type: INIT_NEW_APPROXIMATED_PROJECT,
});

export const initNewApproximatedLayer = () => ({
  type: INIT_NEW_APPROXIMATED_LAYER,
});

export const saveEdits = () => ({ type: SAVE_EDITS });

export const setFilterSettings = (
  type: string,
  selectedSettings: string[],
) => ({
  type: SET_FILTER_SETTINGS,
  payload: { type, selectedSettings },
});

/** API request to fetch computed filtered intervals for calendar UI */
export const getFilteredOutIntervals = (
  environmentId: string,
  interval: string,
  sliceDuration: string,
  filters: { type: string; settings: string[] }[],
) => ({
  type: GET_FILTERED_OUT_INTERVALS,
  payload: post(`/environments/${environmentId}/filteredOutIntervals`, {
    interval,
    sliceDuration,
    filters,
  }),
});

export const setFilteredOutIntervals = (intervals: DateRange[]) => ({
  type: SET_FILTERED_OUT_INTERVALS,
  payload: intervals,
});

export const filterHover = (type: string, setting: string) => ({
  type: FILTER_HOVER,
  payload: { type, setting },
});

export const setFilterPreivew = (intervals: DateRange[]) => ({
  type: SET_FILTER_PREVIEW,
  payload: intervals,
});

export const filterLeave = () => ({ type: FILTER_LEAVE });

export const types = {
  SELECTOR_SCROLL,
  SELECT_SLICE_DURATION,
  CHANGE_CALENDAR_ZOOM_LEVEL,
  SELECTION,
  SELECTION_END,
  SELECTION_CLICK,
  DAY_RANGE_FILTER_SELECTION,
  DAY_RANGE_FILTER_SELECTION_END,
  UPDATE_LABEL,
  UPDATE_START_TIME,
  SET_APPROXIMATION_TYPE,
  INIT,
  RESET,
  SELECT_ALL,
  CLEAR_SELECTION,
  SET_SLICE_TYPE,
  SET_FUNCTION,
  SET_LAYER_ORIGIN_ID,
  INIT_NEW_PROJECT,
  INIT_EDIT_PROJECT,
  INIT_NEW_APPROXIMATED_PROJECT,
  SAVE_EDITS,
  SET_FILTER_SETTINGS,
  GET_FILTERED_OUT_INTERVALS,
  FILTER_HOVER,
  FILTER_LEAVE,
  SET_FILTERED_OUT_INTERVALS,
  SET_FILTER_PREVIEW,
};
