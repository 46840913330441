import { fork, all, takeEvery, takeLatest, call } from 'redux-saga/effects';

import { types as inspectorTypes } from '../actions/inspector';

import player from './player';
import restorePersistTimeWindow from './restorePersistTimeWindow';
import adjustTimelineSingle from './adjustTimelineSingle';
import enterProject from './enterProject';
import volumerSocket from './volumerSocket';
import calendar from './calendar';
import fileDownload from './fileDownload';
import inspectorParamsUpdate from './inspectorParamsUpdate';
import metrics from './metrics';
import layerImport from './layerImport';
import subscription from './subscription';
import billingInfo from './billingInfo';
import { fulfill } from '../utils/promise';
import { types as workspacesTypes } from '../reducers/workspaces';
import features from './features';

export default function*() {
  yield all([
    fork(player),
    fork(restorePersistTimeWindow),
    fork(adjustTimelineSingle),
    fork(enterProject),
    fork(volumerSocket),
    fork(calendar),
    takeEvery(inspectorTypes.UPDATE_PARAMETERS, inspectorParamsUpdate),
    fork(fileDownload),
    fork(metrics),
    fork(layerImport),
    fork(subscription),
    fork(billingInfo),
    fork(features),

    // switching workspaces
    takeLatest(fulfill(workspacesTypes.SELECT), function*() {
      yield call([window.location, 'reload']);
    }),
  ]);
}
