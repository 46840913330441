export const GOOGLE_ANALYTICS = 'UA-109626848-1';
export const SENTRY_DSN =
  process.env.NODE_ENV === 'production'
    ? 'https://850109dddb7a4748af4b7b3d4fe500be@sentry.mathrioshka.ru/2'
    : undefined;

export const STRIPE_PUBLIC_KEY =
  process.env.ENV === 'production'
    ? 'pk_live_ELR9JjXsSbzvex3TWPEAs1aK'
    : 'pk_test_rONfEGe6zSYCGbRELEZZLtVc';

export const INTERCOM_APP_ID =
  process.env.ENV === 'production' ? 'zss0ishc' : 'gt82zf11';

export const MAPBOX_TOKEN =
  'pk.eyJ1IjoiaGFiaWRhdHVtMDEiLCJhIjoiY2ptYzV2d3dyMDBzYjNrcGZja3F5eTdhZCJ9._sRmpvudclMPK-O1-ErLrg';
