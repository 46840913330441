import { connect } from 'react-redux';
import { getType, getParams } from '../../selectors/inspector';
import { updateParams } from '../../actions/inspector';
import { AppState } from '../../models';

import SubtypeControl from './SubtypeControl';

const mapStateToProps = (state: AppState) => ({
  type: getType(state),
  selectedSubtype: getParams(state).subtype,
});

const mapDispatchToProps = {
  updateParams,
};

// FIXME: compiler errors
export default connect(mapStateToProps, mapDispatchToProps)(
  SubtypeControl as any,
);
