import { takeLatest, put, select } from 'redux-saga/effects';

import {
  getCurrentAvailableIntervals,
  getSelectedEnvironmentId,
  getMinSliceDuration,
  getSelectedEnvironment,
} from '../../selectors/environments';

import { getEnvironment } from '../../actions/environments';

import {
  INIT_NEW_PROJECT,
  INIT_NEW_APPROXIMATED_PROJECT,
  init,
} from '../../actions/calendar';

import { initialState as initialSelectionState } from '../../reducers/createSelectionReducer';

import {
  constructApproximationInterval,
  getNiceZoomLevel,
} from '../../utils/time';
import { fetchBaseLayers } from '../../reducers/baseLayers';

export default function*() {
  yield takeLatest(INIT_NEW_PROJECT, initNew);
  yield takeLatest(INIT_NEW_APPROXIMATED_PROJECT, initNewApproximated);
}

function* initNew() {
  yield* fetchEnvironment();
  const environmentId = yield select(getSelectedEnvironmentId);

  yield put.resolve(
    fetchBaseLayers({
      environmentId,
    }),
  );

  const sliceDuration = yield select(getMinSliceDuration);

  yield put(
    init({
      availableIntervals: yield select(getCurrentAvailableIntervals),
      sliceDuration: sliceDuration.toISOString(),
      zoomLevel: getNiceZoomLevel(sliceDuration),
    }),
  );
}

function* initNewApproximated() {
  yield* fetchEnvironment();
  const sliceDuration = yield select(getMinSliceDuration);
  const { timezone } = yield select(getSelectedEnvironment);

  const availableIntervals = constructApproximationInterval(timezone);

  yield put(
    init({
      type: 'approximated',
      sliceDuration: sliceDuration.toISOString(),
      availableIntervals,
      // wave is preselected by default
      selection: {
        ...initialSelectionState,
        selectedIntervals: availableIntervals,
      },
      startTime: '00:00',
    }),
  );
}

function* fetchEnvironment() {
  if (!(yield select(getSelectedEnvironment))) {
    const id = yield select(getSelectedEnvironmentId);
    yield put.resolve(getEnvironment(id));
  }
}
