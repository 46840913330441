import moment from 'moment';
/* tslint:disable-next-line */
import { Moment, Duration, unitOfTime } from 'moment';
import { DateRange } from 'moment-range';
import { last, pipe, uniqBy, sort, find, isNil } from 'ramda';
import { TickType } from '../constants/timeline';
import {
  sliceBordersFromIntervals,
  toUnitOfTimeBase,
  getDurationType,
  isDivisibleBy,
} from './time';
import { TimelineTick } from '../models';

/**
 * XXX: to get a better idea of what this module is for, see jsdoc of the
 * default export function
 */

/**
 * Tick creator. Returns range start
 */
const getRangeStart = (
  sliceDuration: Duration,
  intervals: DateRange[],
): TimelineTick => ({
  value: intervals[0].start,
  type: TickType.RANGE_START,
});

/**
 * Tick creator. Returns range end
 */
const getRangeEnd = (
  sliceDuration: Duration,
  intervals: DateRange[],
): TimelineTick => ({
  value: last(intervals).end,
  type: TickType.RANGE_END,
});

/**
 * Tick creator. Returns an array of TimelineTick of type IntervalStart
 * levelOfDetail works by diluting them, leaving on the timeline
 * at first only every 2nd tick, then every 3rd, then every 4th and so on
 * until the timeline can fit them all
 */
const getIntervalStarts = (
  sliceDuration: Duration,
  intervals: DateRange[],
  levelOfDetail: number,
): TimelineTick[] => {
  return intervals
    .filter((range, i) => i % levelOfDetail === 0)
    .map(({ start }) => ({
      value: start,
      type: TickType.INTERVAL_START,
    }));
};

/**
 * Tick factory. Creates ticks that align nicely with multiples of a given number
 *
 * Example:
 * For interval 2001..2019 with args: duration = 'P1Y', multiple = 5, period = 'year'
 * Outputs moments on [2005, 2010, 2015]
 * Compare with the result of just adding a duration P5Y: [2006, 2011, 2016]
 */
const tickEvery = (multiple: number, unit: unitOfTime.MomentConstructor) => (
  m: Moment,
): Moment => {
  const current = m.get(unit);
  const toNext = multiple - current % multiple;
  return m
    .clone()
    .startOf(unit)
    .add(toNext, toUnitOfTimeBase(unit));
};

/**
 * Tick factory. Creates only those ticks that are contained in the provided list
 */
const onlyTicks = (
  list: number[],
  unit: unitOfTime.MomentConstructor,
  bump: unitOfTime.MomentConstructor,
) => (m: Moment): Moment => {
  const currentValue = m.get(unit);
  const nextValue = find((val: number): boolean => val > currentValue)(list);
  return isNil(nextValue)
    ? m
        .clone()
        .set(unit, list[0])
        .add(1, toUnitOfTimeBase(bump))
    : m.clone().add(nextValue - currentValue, toUnitOfTimeBase(unit));
};

const superDividersLevels = {
  PTM: [
    tickEvery(1, 'hour'),
    tickEvery(3, 'hours'),
    tickEvery(6, 'hours'),
    tickEvery(12, 'hours'),
    tickEvery(1, 'date'),
  ],
  PTH: [
    tickEvery(1, 'date'),
    onlyTicks([1, 5, 10, 15, 20, 25], 'date', 'months'),
    onlyTicks([15], 'date', 'months'),
    tickEvery(1, 'month'),
  ],
  PD: [
    tickEvery(1, 'month'),
    onlyTicks([0, 3, 6, 9], 'months', 'years'),
    onlyTicks([0, 6], 'months', 'years'),
    tickEvery(1, 'year'),
  ],
  PM: [tickEvery(1, 'year'), tickEvery(5, 'years'), tickEvery(10, 'years')],
  PY: [tickEvery(10, 'years')],
};

/**
 * Tick creator. Returns an array of TimelineTick of type SuperDivider
 */
const getSuperDividers = (
  sliceDuration: Duration,
  intervals: DateRange[],
  detailLevel: number,
): TimelineTick[] => {
  const selectedLevel = superDividersLevels[getDurationType(sliceDuration)];

  if (!selectedLevel) return [];

  return selectedLevel[detailLevel]
    ? sliceBordersFromIntervals(selectedLevel[detailLevel])(intervals).map(
        value => ({ value, type: TickType.SUPER_DIVIDER }),
      )
    : [];
};

const dividerLevels = {
  PT1M: tickEvery(10, 'minutes'),
  PT5M: tickEvery(30, 'minutes'),
  PT1H: tickEvery(6, 'hours'),
  PT2H: tickEvery(12, 'hours'),
  PT3H: tickEvery(12, 'hours'),
  P1D: onlyTicks([1, 5, 10, 15, 20, 25], 'date', 'months'),
  P1M: onlyTicks([0, 6], 'months', 'years'),
  P1Y: tickEvery(5, 'years'),
};

/**
 * Tick creator. Returns an array of TimelineTick of type Divider
 */
const getDividers = (
  sliceDuration: Duration,
  intervals: DateRange[],
  detailLevel: number,
): TimelineTick[] => {
  if (detailLevel > 0) return [];
  const divider = dividerLevels[sliceDuration.toISOString()];

  return divider
    ? sliceBordersFromIntervals(divider)(intervals).map(value => ({
        value,
        type: TickType.DIVIDER,
      }))
    : [];
};

interface RegularLevels {
  [index: string]: {
    duration: moment.Duration;
    factory(m: moment.Moment): moment.Moment;
  }[];
}

const regularLevels: RegularLevels = {
  PTM: [
    { duration: moment.duration('PT1M'), factory: tickEvery(1, 'minute') },
    { duration: moment.duration('PT5M'), factory: tickEvery(5, 'minutes') },
    { duration: moment.duration('PT10M'), factory: tickEvery(10, 'minutes') },
    { duration: moment.duration('PT15M'), factory: tickEvery(15, 'minutes') },
    { duration: moment.duration('PT20M'), factory: tickEvery(20, 'minutes') },
    { duration: moment.duration('PT30M'), factory: tickEvery(30, 'minutes') },
  ],
  PTH: [
    { duration: moment.duration('PT1H'), factory: tickEvery(1, 'hour') },
    { duration: moment.duration('PT3H'), factory: tickEvery(3, 'hours') },
    { duration: moment.duration('PT6H'), factory: tickEvery(6, 'hours') },
    { duration: moment.duration('PT12H'), factory: tickEvery(12, 'hours') },
  ],
  PD: [
    { duration: moment.duration('P1D'), factory: tickEvery(1, 'date') },
    {
      duration: moment.duration('P5D'),
      factory: onlyTicks([1, 5, 10, 15, 20, 25], 'date', 'months'),
    },
    {
      duration: moment.duration('P15D'),
      factory: onlyTicks([15], 'date', 'months'),
    },
  ],
  PM: [
    { duration: moment.duration('P1M'), factory: tickEvery(1, 'month') },
    {
      duration: moment.duration('P3M'),
      factory: onlyTicks([0, 3, 6, 9], 'months', 'years'),
    },
    {
      duration: moment.duration('P6M'),
      factory: onlyTicks([0, 5], 'months', 'years'),
    },
  ],
  PY: [
    { duration: moment.duration('P1Y'), factory: tickEvery(1, 'year') },
    { duration: moment.duration('P5Y'), factory: tickEvery(5, 'years') },
    { duration: moment.duration('P10Y'), factory: tickEvery(10, 'years') },
  ],
};

const durationCompatibleWith = (sliceDuration: Duration) => (
  duration: Duration,
) =>
  duration.asMilliseconds() >= sliceDuration.asMilliseconds() &&
  isDivisibleBy(sliceDuration)(duration);

/**
 * Tick creator. Returns an array of TimelineTick of type Regular
 */
const getRegular = (
  sliceDuration: Duration,
  intervals: DateRange[],
  detailLevel: number,
): TimelineTick[] => {
  const levelsAvailable = regularLevels[getDurationType(sliceDuration)].filter(
    (level: any) => durationCompatibleWith(sliceDuration)(level.duration),
  );

  if (!levelsAvailable || !levelsAvailable[detailLevel]) return [];

  const { factory } = levelsAvailable[detailLevel];

  return sliceBordersFromIntervals(factory)(intervals).map(value => ({
    value,
    type: TickType.REGULAR,
  }));
};

/**
 * Returns array of TimelineTick to be used with timeline/Ticks unique by date.
 *
 * Aggregates the results of calling more basic tick creators
 * (which create ticks of one specific type)
 *
 * Any of the types can be removed from the final result
 * which makes it easy to create new tick types and remove old
 */
const generateTicks = (
  intervals: DateRange[],
  sliceDuration: Duration,
  levelOfDetail: { [tickType: string]: number },
): TimelineTick[] => {
  const ticks = [
    getRangeStart(sliceDuration, intervals),
    getRangeEnd(sliceDuration, intervals),
    ...getIntervalStarts(
      sliceDuration,
      intervals,
      levelOfDetail[TickType.INTERVAL_START],
    ),
    ...getSuperDividers(
      sliceDuration,
      intervals,
      levelOfDetail[TickType.SUPER_DIVIDER],
    ),
    ...getDividers(sliceDuration, intervals, levelOfDetail[TickType.DIVIDER]),
    ...getRegular(sliceDuration, intervals, levelOfDetail[TickType.REGULAR]),
  ];

  return pipe<TimelineTick[], TimelineTick[], TimelineTick[]>(
    uniqBy(({ value }) => value.format()),
    sort(({ value: a }, { value: b }) => (a.isBefore(b) ? -1 : 1)),
  )(ticks);
};

export default generateTicks;
