import React from 'react';

interface Props {
  children: React.ReactChild;
}

interface State {
  width: number;
}

class AutosizeInput extends React.Component<Props, State> {
  sizer: HTMLDivElement;

  state = {
    width: 0,
  };

  componentDidMount() {
    if (!this.sizer) return;
    this.setState({ width: this.sizer.getBoundingClientRect().width });
  }

  componentDidUpdate() {
    if (!this.sizer) return;
    const { width } = this.sizer.getBoundingClientRect();

    if (width !== this.state.width) this.setState({ width });
  }

  render() {
    const { children } = this.props;
    const childInput = React.Children.only(children);

    return (
      <React.Fragment>
        {React.cloneElement(childInput, {
          style: { width: `${this.state.width}px` },
        })}
        <div
          ref={el => (this.sizer = el)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            visibility: 'hidden',
          }}
        >
          {React.cloneElement(childInput, {
            is: 'div',
            style: { margin: 0 },
            // Replace so React doesn't collapse spaces
            children: childInput.props.value.replace(/ /g, '\u00a0'),
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default AutosizeInput;
