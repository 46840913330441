import React from 'react';
import { Button, RadioButtonGroup, DropdownSelect } from '@hm/ukie';
import { FilteredSelectedSlices } from './SliceCount';
import { propEq } from 'ramda';
import { Flex } from 'grid-styled';
import { PlaybackMode, PlaybackBehaviour } from '../models';

const SlimButton = Button.extend`
  padding: 0;
`;

const DropdownButton = SlimButton.extend`
  margin-left: 4px;
`;

const timelineSpeedItems = [
  { section: 'Playback speed' },
  { id: '2', title: '2 slices/sec.', type: 'speed' },
  { id: '5', title: '5', type: 'speed' },
  { id: '10', title: '10', type: 'speed' },
];

const timelineBehaviourItems = [
  { section: 'Style' },
  { id: 'steps', title: 'Steps', type: 'behaviour' },
  { id: 'growth', title: 'Growth', type: 'behaviour' },
];

interface Props {
  mode: PlaybackMode;
  speed: number;
  behaviour: PlaybackBehaviour;
  isPlaying: boolean;
  disableRangeMode: boolean;
  canPlay: boolean;
  editProject();
  onModeChange(mode);
  onSpeedChange(speed);
  onBehaviourChange(behaviour);
  stop();
  start();
}

export default class TimelineActions extends React.Component<Props, never> {
  refs: {
    [index: string]: any;
    timelineMenu: any;
  };

  timelineMenu: any;

  getTimelineItems() {
    const { mode } = this.props;

    return mode === 'single'
      ? timelineSpeedItems
      : timelineSpeedItems.concat(timelineBehaviourItems);
  }

  handleTimelineDropdownChange = item => {
    const { onSpeedChange, onBehaviourChange } = this.props;

    if (item.type === 'behaviour') onBehaviourChange(item.id);
    if (item.type === 'speed') onSpeedChange(item.id);
  };

  toggleMenu = () => {
    this.timelineMenu.toggleMenu();
  };

  togglePlay = () =>
    this.props.isPlaying ? this.props.stop() : this.props.start();

  render() {
    const {
      speed,
      behaviour,
      mode,
      isPlaying,
      onModeChange,
      disableRangeMode,
    } = this.props;

    const selectedTimelineOptions = [
      timelineSpeedItems.find(propEq('id', String(speed))),
      timelineBehaviourItems.find(propEq('id', behaviour)),
    ];

    return (
      <div className="timeline-info">
        <div className="timeline-slices">
          <FilteredSelectedSlices />
          <span onClick={this.props.editProject} className="timeline-info-link">
            Edit…
          </span>
        </div>
        <Flex>
          <RadioButtonGroup selected={mode} onClick={onModeChange}>
            <Button value="range" disabled={disableRangeMode}>
              Range
            </Button>
            <Button value="single">Single</Button>
          </RadioButtonGroup>
          <Flex ml="4px">
            <SlimButton
              icon={isPlaying ? 'pause' : 'play'}
              disabled={!this.props.canPlay && !this.props.isPlaying}
              onClick={this.togglePlay}
            />
            <DropdownSelect
              ref={el => (this.timelineMenu = el)}
              items={this.getTimelineItems()}
              selected={selectedTimelineOptions}
              onChange={this.handleTimelineDropdownChange}
            >
              <DropdownButton icon="dropdown" onClick={this.toggleMenu} />
            </DropdownSelect>
          </Flex>
        </Flex>
      </div>
    );
  }
}
