import React from 'react';
import cx from 'classnames';
import { Effect } from '../../models';

import TrendBlur from './TrendBlur';
import Patternize from '../../containers/Patternize';
import MinMax from '../../components/Metric/MinMax';
import Flip from '../../containers/Flip';
import Void from '../../containers/Void';

interface Props {
  effects: Effect[];
  layerId: string;
}

const Effects: React.SFC<Props> = ({ effects, layerId }) => {
  const toRender = effects.map(effect => {
    switch (effect.type) {
      case 'patternize':
        return <Patternize layerId={layerId} id={effect.id} key={effect.id} />;
      case 'blur':
        return <TrendBlur id={effect.id} layerId={layerId} key={effect.id} />;
      case 'minmax':
        return <MinMax id={effect.id} layerId={layerId} key={effect.id} />;
      case 'flip':
        return <Flip id={effect.id} key={effect.id} />;
      case 'void':
        return <Void id={effect.id} key={effect.id} />;
      default:
        return null;
    }
  });

  return (
    <div
      className={cx('metric-option__content', { 'is-hidden': !effects.length })}
    >
      {toRender}
    </div>
  );
};

export default Effects;
