import {
  take,
  call,
  race,
  fork,
  put,
  cancel,
  select,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { connect, types as volumerTypes } from '../actions/volumer';
import { openProject, types as projectTypes } from '../actions/projects';
import { types as globalTypes } from '../actions/global';

import { getUrl, getPreferedNodeUrl } from '../selectors/volumer';
import { showAlert, hideAlert } from '../actions/alerts';
import { getCurrentProjectId } from '../selectors/project';
import { fulfill, reject } from '../utils/promise';

import createChannels, { Channels } from './volumerSocketChannels';
import tasks from './volumerSocketTasks';

const CLOSE_ABNORMAL = 1006;
const RECONNECT_INTERVAL = 5000;

/**
 * Manages WebSocket communication channel with Volumer
 */
export default function*() {
  while (true) {
    // Wait for connect action to start initialization
    yield take(volumerTypes.CONNECT);
    const socket = new WebSocket(yield select(getUrl));
    socket.binaryType = 'arraybuffer';
    const channels: Channels = yield* createChannels(socket);

    // On socket open start normal operations
    const { error, timeout } = yield race({
      open: take(channels.open),
      error: take(channels.error),
      timeout: call(delay, 5000), // XXX: testing delay
    });

    if (error || timeout) {
      yield fork(retryOpenProject);
      continue;
    }

    const backgroundTasks = yield fork(tasks, socket, channels);

    // Wait for some disruptive action to start shutdown process
    const { clientClosed, serverClosed, windowClosed } = yield race({
      clientClosed: take(volumerTypes.DISCONNECT),
      serverClosed: take(channels.close),
      windowClosed: take(globalTypes.WINDOW_UNLOAD),
    });

    yield cancel(backgroundTasks);

    if (clientClosed || windowClosed) socket.close();

    if (serverClosed && serverClosed.code === CLOSE_ABNORMAL) {
      yield fork(retryOpenProject);
      continue;
    }
  }
}

function* retryOpenProject() {
  // XXX: not reconnecting when Volumer URL is explicitly specified
  // is a desired behaviour for debugging
  if (yield select(getPreferedNodeUrl)) return null;

  yield put(
    showAlert('Connection to render server is lost. Trying to reconnect…'),
  );

  while (true) {
    yield put(openProject(yield select(getCurrentProjectId)));

    const { open } = yield race({
      open: take(fulfill(projectTypes.OPEN_PROJECT)),
      fail: take(reject(projectTypes.OPEN_PROJECT)),
    });

    if (open) {
      yield put(connect());
      yield put(hideAlert());
      return null;
    }

    yield delay(RECONNECT_INTERVAL);
  }
}
