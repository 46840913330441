import React from 'react';
import { connect } from 'react-redux';

import {
  getEnvironments,
  getSelectedEnvironmentId,
} from '../../selectors/environments';
import { getProjects } from '../../selectors/projects';
import { fetchUsers } from '../../actions/users';

import {
  fetchEnvironments,
  selectEnvironment,
} from '../../actions/environments';

import {
  fetchProjects,
  goToProject,
  goToProjectView,
} from '../../actions/projects';

import { getSortValue, sortByField } from '../../reducers/explorer';
import { selectors as baseLayerSelectors } from '../../reducers/baseLayers';
import { AppState } from '../../models';

import Explorer, {
  Props,
  StateProps,
  DispatchProps,
  OwnProps,
} from './Explorer';
import { hasErrors } from '../../reducers/alertStack';
import { withRouter } from 'react-router';

interface ContainerProps extends Props {
  fetchProjects(): void;
  fetchEnvironments(): void;
  fetchUsers?(): void;
}

class ExplorerContainer extends React.Component<ContainerProps> {
  componentDidMount() {
    this.props.fetchEnvironments();
    this.props.fetchProjects();
    this.props.fetchUsers();
  }

  render() {
    const {
      fetchEnvironments: _,
      fetchProjects: __,
      fetchUsers: ___,
      ...props
    } = this.props;

    return <Explorer {...props} />;
  }
}

export default withRouter(
  connect<StateProps, DispatchProps, OwnProps>(
    (state: AppState) => ({
      environments: getEnvironments(state),
      projects: getProjects(state),
      layers: baseLayerSelectors.getEntities(state),
      sortValue: getSortValue(state),
      hasErrors: hasErrors(state),
      selectedEnvironmentId: getSelectedEnvironmentId(state),
    }),
    {
      fetchEnvironments,
      fetchProjects,
      onEdit: goToProject,
      onView: goToProjectView,
      onEnvironmentSelect: selectEnvironment,
      fetchUsers,
      onSortBy: sortByField,
    },
  )(ExplorerContainer),
);
