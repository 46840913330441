import { Box } from 'grid-styled';
import { compose, join, map } from 'ramda';

interface Props {
  colors: string[];
}

const makeGradient = compose(join(', '), map(value => '#' + value));

const GradientPreview = Box.extend`
  flex: 0 0 14px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: linear-gradient(
    45deg,
    ${(props: Props) => makeGradient(props.colors)}
  );
`;

GradientPreview.displayName = 'GradientPreview';

GradientPreview.defaultProps = {
  colors: [],
};

export default GradientPreview;
