import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { typography } from '@hm/ukie';
import { calendarCell } from '../../styles/common';
import withSliceSelection, {
  SlicerProps,
  SliceSelectorProps,
} from '../withSliceSelection';

interface OwnProps {
  isSelected?: boolean;
  isDisabled?: boolean;
  date: moment.Moment;
  first?: boolean;
  last?: boolean;
}

type Props = OwnProps &
  SliceSelectorProps &
  SlicerProps &
  React.HTMLProps<HTMLDivElement>;

const Month: React.SFC<Props> = ({
  className,
  date,
  connectDragSource,
  connectDropTarget,
  onClick,
}) =>
  connectDropTarget(
    connectDragSource(
      <div className={className} onClick={onClick}>
        {date.format('MMM')}
      </div>,
    ),
  );

export default styled(withSliceSelection(Month))`
  ${calendarCell} width: 44px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${typography.smallFont};
`;
