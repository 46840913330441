import React from 'react';
import { Text } from '@hm/ukie';
import { Box } from 'grid-styled';

interface Props {
  name: string;
  length: number;
  onClick: React.MouseEventHandler<any>;
}

const Title: React.SFC<Props> = ({ name, onClick, length }) => (
  <Box mr={2} onClick={onClick}>
    {`${name} `}
    <Text.Important>({length})</Text.Important>
  </Box>
);

export default Title;
