import React from 'react';
import { RoundHandle, BrushCore, Extent, colors } from '@hm/ukie';
import { absoluteShort, absoluteFull } from '../../utils/numberFormats';
import { getCollisionShifts } from '../../utils/dimensions';
import { getBordersInverseScale, getBrushScale } from '../../utils/histogram';

const textStyle: React.CSSProperties = {
  fontSize: '10px',
  fontWeight: 600,
  fill: colors.primary,
  fontFamily: 'Open Sans, sans-serif',
};

interface Props {
  borders: number[];
  selected: Extent;
  minExtent: number;
  width: number;
  height: number;
  boundaries: Element;
  onUpdate: (selected: Extent) => any;
  saveLabelPositions?: (labels: ClientRect[]) => void;
  onStart();
  onStop();
}

export default class CompactBrush extends React.Component<Props, never> {
  labels = { left: null, right: null };
  xTranslates = { left: 0, right: 0 };

  // Collects labels’ ClientRect and pass it to parent.
  // Needed to conditionally hide ticks under labels.
  updateLabels() {
    const { saveLabelPositions } = this.props;
    if (!saveLabelPositions || !this.labels.left || !this.labels.right) return;

    const left = this.labels.left.getBoundingClientRect();
    const right = this.labels.right.getBoundingClientRect();

    this.xTranslates = getCollisionShifts({ left, right }, this.xTranslates, 8);

    saveLabelPositions([left, right]);
  }

  componentDidMount() {
    this.updateLabels();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) this.updateLabels();
  }

  renderHandle(type: 'left' | 'right', value: number) {
    return (
      <g>
        <RoundHandle
          chartHeight={this.props.height}
          style={{ cursor: type === 'right' ? 'e-resize' : 'w-resize' }}
        />
        <text
          ref={el => (this.labels[type] = el)}
          style={textStyle}
          dy="1.5em"
          textAnchor="middle"
          y={this.props.height}
          transform={`translate(${this.xTranslates[type]},0)`}
        >
          <title>{absoluteFull(value)}</title>
          {absoluteShort(value)}
        </text>
      </g>
    );
  }

  renderBody() {
    const { height } = this.props;

    return (
      <rect
        height={height}
        style={{ fill: 'transparent', cursor: 'ew-resize' }}
      />
    );
  }

  onDrag = (e: MouseEvent, data) => e.preventDefault();

  render() {
    const { width, borders, selected: [from, to] } = this.props;

    const xScale = getBrushScale(width);
    const labelScale = getBordersInverseScale(borders, width);

    return (
      <BrushCore
        xScale={xScale}
        selected={this.props.selected}
        minExtent={this.props.minExtent}
        grabRect={{ width: 24, height: 16, top: -5, inner: 0 }}
        boundaries={this.props.boundaries}
        onChange={this.props.onUpdate}
        onDrag={this.onDrag}
        onStart={this.props.onStart}
        onStop={this.props.onStop}
        leftHandleComponent={this.renderHandle(
          'left',
          labelScale(xScale(from)),
        )}
        rightHandleComponent={this.renderHandle(
          'right',
          labelScale(xScale(to)),
        )}
        bodyComponent={this.renderBody()}
      />
    );
  }
}
