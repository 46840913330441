import Raven from 'raven-js';
import { SENTRY_DSN } from '../src/keys';

export default Raven.config(SENTRY_DSN, {
  environment: process.env.ENV,
  release: process.env.RELEASE,
  tags: {
    nodeEnv: process.env.NODE_ENV,
  },
}).install();
