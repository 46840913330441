import React from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { transparentize } from 'polished';

const GeoBounds = styled.div`
  box-sizing: border-box;
  background: ${({ theme }) => transparentize(0.9, theme.colors.blue1)};
  border: 2px solid ${({ theme }) => transparentize(0.5, theme.colors.blue1)};
`;

// NOTE: implemented but unused corners for adjustable bounding box

// const corner = `
//   box-sizing: border-box;
//   border-radius: 3px;
//   position: absolute;
//   width: 24px;
//   height: 24px;
// `;

// TODO: use for selected bounding box
// const TopLeftCorner = styled.div`
//   /* prettier-ignore */
//   ${corner}
//   border-top: 6px solid ${({ theme }) => theme.colors.blue1};
//   border-left: 6px solid ${({ theme }) => theme.colors.blue1};
//   top: 0;
//   left: 0;
//   transform: translate(-4px, -4px);
// `;

// const TopRightCorner = styled.div`
//   /* prettier-ignore */
//   ${corner}
//   border-top: 6px solid ${({ theme }) => theme.colors.blue1};
//   border-right: 6px solid ${({ theme }) => theme.colors.blue1};
//   top: 0;
//   right: 0;
//   transform: translate(4px, -4px);
// `;

// const BottomLeftCorner = styled.div`
//   /* prettier-ignore */
//   ${corner}
//   border-bottom: 6px solid ${({ theme }) => theme.colors.blue1};
//   border-left: 6px solid ${({ theme }) => theme.colors.blue1};
//   bottom: 0;
//   left: 0;
//   transform: translate(-4px, 4px);
// `;

// const BottomRightCorner = styled.div`
//   /* prettier-ignore */
//   ${corner}
//   border-bottom: 6px solid ${({ theme }) => theme.colors.blue1};
//   border-right: 6px solid ${({ theme }) => theme.colors.blue1};
//   bottom: 0;
//   right: 0;
//   transform: translate(4px, 4px);
// `;

interface Props {
  topLat: number;
  rightLon: number;
  bottomLat: number;
  leftLon: number;
}

const BoundingBox: React.SFC<Props> = (
  { topLat, rightLon, bottomLat, leftLon },
  { viewport },
) => {
  const topRightProjection = viewport.project([rightLon, topLat]);
  const bottomLeftProjection = viewport.project([leftLon, bottomLat]);

  return (
    <GeoBounds
      style={{
        width: topRightProjection[0] - bottomLeftProjection[0],
        height: bottomLeftProjection[1] - topRightProjection[1],
        transform: `translate(${bottomLeftProjection[0]}px, ${
          topRightProjection[1]
        }px)`,
      }}
    />
  );
};

BoundingBox.contextTypes = {
  viewport: object,
};

export default BoundingBox;
