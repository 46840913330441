import { takeEvery } from 'redux-saga/effects';
import { FSA } from 'flux-standard-action';
import { types } from '../../actions/effects';
import { EventSender } from './getEventSender';

export default function*(send: EventSender) {
  yield takeEvery<FSA<any>>(types.TREND_BLUR_START, function*(action) {
    yield send('blurDragStart', action.payload.message);
  });

  yield takeEvery<FSA<any>>(types.TREND_BLUR_DRAG, function*(action) {
    yield send('blurDrag', action.payload.message);
  });

  yield takeEvery<FSA<any>>(types.TREND_BLUR_STOP, function*(action) {
    yield send('blurDragStop', action.payload.message);
  });
}
