import React from 'react';
import { isEmpty } from 'ramda';
import { Flex, Text } from '@hm/ukie';

import Tiles from './Tiles';
import { FormattedMessage as F } from 'react-intl';
import { Dataset } from '../../reducers/datasets';
import DatasetCard from '../DatasetCard';

export interface Props {
  datasets: Dataset[];
  isFetching: boolean;
  onDelete(id: string): Promise<any>;
  onReuse(id: string): Promise<any>;
}

export const Datasets: React.SFC<Props> = ({
  datasets = [],
  isFetching,
  onDelete,
  onReuse,
}) => (
  <Flex flexWrap="wrap" m="-4px" height="100%">
    {isEmpty(datasets) && !isFetching ? (
      <F id="Datasets.empty" defaultMessage="Datasets are empty">
        {t => (
          <Text fontSize={3} fontWeight="semibold" color="grey7" m="auto">
            {t}
          </Text>
        )}
      </F>
    ) : (
      <Tiles
        tiles={datasets}
        renderTile={(dataset: Dataset) => (
          <DatasetCard
            dataset={dataset}
            onDelete={onDelete}
            onReuse={onReuse}
          />
        )}
      />
    )}
  </Flex>
);

export default Datasets;
