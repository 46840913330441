import { connect } from 'react-redux';
import { selectTool } from '../../actions/tool';
import { takeScreenshot } from '../../actions/volumer';
import { optimisticUpdateProject } from '../../actions/projects';
import {
  getShowHiddenObject,
  getCurrentProjectId,
  getHighlightObjects,
} from '../../selectors/project';
import { AppState } from '../../models';

import Toolbar from './Tools';

const mapStateToProps = (state: AppState) => ({
  selectedTool: state.tool,
  showHiddenObjects: getShowHiddenObject(state),
  objectsHighlightEnabled: getHighlightObjects(state),
  projectId: getCurrentProjectId(state),
});

const mapDispatchToProps = {
  selectTool,
  takeScreenshot,
  updateProject: optimisticUpdateProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
