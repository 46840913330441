import React from 'react';
import styled from 'styled-components';
import {
  FormattedMessage as F,
  injectIntl,
  InjectedIntlProps,
} from 'react-intl';
import Intercom from 'react-intercom';
import Overlay from 'react-overlays/lib/Overlay';
import { DropdownContainer, IconButton } from '@hm/ukie';

import ListItem from '../ListItem';
import PlainLink from '../PlainLink';
import messages from './messages';

import { User } from '../../models';
import { INTERCOM_APP_ID } from '../../keys';

export const launcherClassName = 'contact-us-intercom-button';

interface OwnProps {
  user: User;
}

type Props = OwnProps & InjectedIntlProps;

interface State {
  menuOpen?: boolean;
}

class Help extends React.Component<Props, State> {
  menuButton: HTMLDivElement;

  state = {
    menuOpen: false,
  };

  onHide = () => this.setState({ menuOpen: false });

  onToggle = () => this.setState({ menuOpen: !this.state.menuOpen });

  render() {
    const { user, intl } = this.props;

    return (
      <div>
        <Overlay
          show={this.state.menuOpen}
          onHide={this.onHide}
          placement="bottom"
          target={this.menuButton}
          containerPadding={8}
          rootClose
        >
          <DropdownContainer>
            <div className="dropdown-menu">
              <PlainLink to="https://help.chronotope.io/" target="_blank">
                <ListItem onClick={this.onHide}>
                  <F {...messages.helpPage} />
                </ListItem>
              </PlainLink>
              <PlainLink
                to="https://help.chronotope.io/work-with-chronotope/shortcuts"
                target="_blank"
              >
                <ListItem onClick={this.onHide}>
                  <F {...messages.shortcuts} />
                </ListItem>
              </PlainLink>
              <PlainLink to="https://app.chronotope.io/tou" target="_blank">
                <ListItem onClick={this.onHide}>
                  <F {...messages.termsOfUse} />
                </ListItem>
              </PlainLink>
              <PlainLink
                to="https://privacy-policy.chronotope.io"
                target="_blank"
              >
                <ListItem onClick={this.onHide}>
                  <F {...messages.privacyPolicy} />
                </ListItem>
              </PlainLink>
              <div className="dropdown-menu__delimiter" />
              <ListItem className={launcherClassName} onClick={this.onHide}>
                <F {...messages.contactUs} />
              </ListItem>
            </div>
          </DropdownContainer>
        </Overlay>
        <div ref={el => (this.menuButton = el)}>
          <IconButton
            icon="help"
            title={intl.formatMessage(messages.help)}
            onClick={this.onToggle}
          />
        </div>
        <Intercom
          appID={INTERCOM_APP_ID}
          email={user && user.email}
          name={user && user.name}
          custom_launcher_selector={`.${launcherClassName}`}
          hide_default_launcher
        />
      </div>
    );
  }
}

export default injectIntl(Help);
