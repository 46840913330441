import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, RouteProps, Link } from 'react-router-dom';
import { Text } from '@hm/ukie';
import RouteIf from './RouteIf';
import UnavailablePage from './UnavailablePage';
import {
  getIsLoggedIn,
  isUserBillable,
  isUserFetched,
  isUserActive,
} from '../selectors/user';
import { getProfile } from '../actions/user';
import {
  getBilling,
  fetchBilling,
  isBillingFetched,
} from '../reducers/billing';

interface StateProps {
  userFetched: boolean;
  billingFetched: boolean;
  userBillable: boolean;
  loggedIn: boolean;
  userActive: boolean;
  paymentValid: boolean;
}

interface DispatchProps {
  fetchUser(): void;
  fetchBillingData(): void;
}

type Props = RouteProps & DispatchProps & StateProps;

// TODO: refactor, this component is a mess
class MembersRoute extends React.Component<Props> {
  componentDidMount() {
    this.syncData(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    this.syncData(nextProps);
  }

  // FIXME: this is ridiculous
  syncData = ({
    loggedIn,
    userFetched,
    billingFetched,
    userBillable,
  }: Props) => {
    if (!loggedIn) return;

    if (!userFetched) this.props.fetchUser();
    if (userFetched && userBillable && !billingFetched) {
      this.props.fetchBillingData();
    }
  };

  render() {
    const {
      userFetched,
      userBillable,
      billingFetched,
      loggedIn,
      userActive,
      paymentValid,
      ...rest
    } = this.props;

    // TODO: simplify, this logic is a mess
    const paymentIssue = !userActive && userBillable && !paymentValid;
    const loadingData = !userFetched || (userBillable && !billingFetched);

    return (
      <RouteIf
        // If all those conditions are true, we render the page, otherwise the related callback
        conditions={[loggedIn, !loadingData, userActive, !paymentIssue]}
        fallbacks={[
          <Redirect
            key="logged"
            to={{
              pathname: '/login',
              state: { from: rest.location },
            }}
          />,
          null,
          <UnavailablePage key="sub">
            <F
              id="UnavailablePage.subscriptionIssue"
              defaultMessage="Unfortunately, the page you’re looking for is unavailable for unsubscribed users. Please, subscribe to Chronotope on the Plan page to start working."
            >
              {(...text) => (
                <Text is="p" lineHeight="text" mb={3}>
                  {text}
                </Text>
              )}
            </F>
            <Link to="profile/plan">
              <F
                id="UnavailablePage.planPage"
                defaultMessage="Open the Plan page"
              />
            </Link>
          </UnavailablePage>,
          <UnavailablePage key="pay">
            <F
              id="UnavailablePage.paymentIssue"
              defaultMessage="Unfortunately, the page you’re looking for is unavailable for unsubscribed users. Please, update your payment source on the Payment page and after that subscribe to Chronotope on the Plan page to start working."
            >
              {(...text) => (
                <Text is="p" lineHeight="text" mb={3}>
                  {text}
                </Text>
              )}
            </F>
            <Link to="/profile/payment">
              <F
                id="UnavailablePage.paymentPage"
                defaultMessage="Open the Payment page"
              />
            </Link>
          </UnavailablePage>,
        ]}
        {...rest}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, RouteProps>(
  state => ({
    userFetched: isUserFetched(state),
    billingFetched: isBillingFetched(state),
    userBillable: isUserBillable(state),
    loggedIn: getIsLoggedIn(state),
    userActive: isUserActive(state),
    paymentValid: getBilling(state).cardValid,
  }),
  {
    fetchUser: getProfile,
    fetchBillingData: fetchBilling,
  },
)(MembersRoute);
