import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const messages = defineMessages({
  clickToCreate: {
    id: 'Inspector/Placeholder.clickToCreate',
    defaultMessage: 'Click on the visualization to create {tool}',
  },
});

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.grey7};
  box-sizing: border-box;
`;

interface Props {
  tool: string;
}

/**
 * TODO: tool name localization
 */
export default ({ tool }: Props) => (
  <Placeholder>
    <FormattedMessage {...messages.clickToCreate} values={{ tool: tool }} />
  </Placeholder>
);
