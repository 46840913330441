import React from 'react';
import { Flex } from 'grid-styled';
import { Stat } from '../../reducers/selectedObjects';

import GradientPreview from '../GradientPreview';
import LayerTitle from './LayerTitle';
import Column from './StatColumn';

const TITLE_WIDTH = 136;
const ShiftedGradient = GradientPreview.extend`
  margin-top: 2px;
`;

interface Props {
  hasTime: boolean;
  layerGradient: string[];
  layerLabel: string;
  truncate: boolean;
  stat: Stat;
  formatFunction(val: number): string;
}

// XXX: Tooltips are temporary, so no need to localize them
export default ({
  stat,
  hasTime,
  layerLabel,
  layerGradient,
  formatFunction,
  truncate,
}: Props) => (
  <Flex>
    <Flex width={TITLE_WIDTH} flex={`0 0 ${TITLE_WIDTH}px`} mr={1}>
      <ShiftedGradient colors={layerGradient} />
      <LayerTitle title={layerLabel} truncate={truncate}>
        {layerLabel}
      </LayerTitle>
    </Flex>
    <Flex flex="1">
      <Column
        values={stat.currentTimeIntervalEnabledSubsets}
        title={
          hasTime
            ? 'Selected time, enabled subsets'
            : 'Current time interval, enabled subsets'
        }
        format={formatFunction}
      />
      <Column
        values={stat.currentTimeIntervalAllSubsets}
        title={
          hasTime
            ? 'Selected time, all subsets'
            : 'Current time interval, all subsets'
        }
        format={formatFunction}
      />
      {!hasTime ? (
        <React.Fragment>
          <Column
            values={stat.fullTimeIntervalEnabledSubsets}
            title="Full time interval, enabled subsets"
            format={formatFunction}
          />
          <Column
            values={stat.fullTimeIntervalAllSubsets}
            title="Full time interval, all subsets"
            format={formatFunction}
          />
        </React.Fragment>
      ) : null}
    </Flex>
  </Flex>
);
