import { connect } from 'react-redux';
import { changeTab } from '../../actions/sidebar';
import { getSidebarTab } from '../../selectors/sidebar';
import { AppState } from '../../models';

import Sidebar from './Sidebar';

export default connect(
  (state: AppState) => ({
    selectedTab: getSidebarTab(state),
  }),
  { changeTab },
)(Sidebar);
