import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../models';
import {
  fetchDatasets,
  deleteDataset,
  selectors,
  reuseDataset,
} from '../../reducers/datasets';
import Datasets, { Props } from './Datasets';

interface ProvidedProps {
  fetchDatasets(): Promise<any>;
}

class DatasetsContainer extends React.Component<Props & ProvidedProps> {
  componentDidMount() {
    this.props.fetchDatasets();
  }

  render() {
    const { fetchDatasets: _, ...props } = this.props;
    return <Datasets {...props} />;
  }
}

export default connect(
  (state: AppState) => ({
    datasets: selectors.getList(state),
    isFetching: selectors.getIsFetching(state),
  }),
  {
    fetchDatasets,
    onDelete: deleteDataset,
    onReuse: reuseDataset,
  },
)(DatasetsContainer);
