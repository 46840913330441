import { Tool } from './tools';

export const ObjectType = {
  MARKER: Tool.MARKER,
  ROAD: Tool.ROAD,
  POLYLINE: Tool.POLYLINE,
  POLYGON: Tool.POLYGON,
  RADIUS: Tool.RADIUS,
};

export const MarkerSubtype = {
  SPATIAL: 'spatial',
  TEMPORAL: 'temporal',
  SPATIO_TEMPORAL: 'spatioTemporal',
};

export const RoadSubtype = {
  DRIVING: 'driving',
  WALKING: 'walking',
  TRANSIT: 'transit',
  BICYCLING: 'bicycling',
};

export const newObjectLabel = {
  [ObjectType.MARKER]: 'New marker',
  [ObjectType.ROAD]: 'New road',
  [ObjectType.POLYLINE]: 'New polyline',
  [ObjectType.RADIUS]: 'New radius',
  [ObjectType.POLYGON]: 'New polygon',
};
