import { FormattedMessage as F } from 'react-intl';
import React from 'react';

export const isRequired = (val: any) => val && val.length;
export const isEmail = (val: any) => val && val.match(/.+\@.+\.[a-zA-Z]{2,}/);

export const longEnough = (length: number) => (val: any) =>
  val && val.length >= length;

export const passwordsMatch = (fields: any) =>
  fields.password === fields.passwordConfirmation;

// FIXME: any because of this https://github.com/davidkpiano/react-redux-form/pull/1123
// TODO: move this out
export const errorMessages: any = {
  form: {
    passwordsMatch: 'Provided passwords don’t match.',
    VALIDATION_0: (
      <F
        id="Errors.VALIDATION_0"
        defaultMessage="Some form fields are invalid"
      />
    ),
    AUTHENTICATION_6: (
      <F
        id="Errors.AUTHENTICATION_6"
        defaultMessage="You’ve entered incorrect email or password. Please, try again."
      />
    ),
    AUTHENTICATION_8: (
      <F
        id="Errors.AUTHENTICATION_8"
        defaultMessage="Please, confirm your email address to access Chronotope."
      />
    ),
  },
  email: {
    isRequired: 'You must provide email.',
    isEmail: 'Invalid email format',
  },
  username: {
    isRequired: 'You must provide username.',
  },
  password: {
    isRequired: 'You must provide password.',
    passwordLength: 'Password must be at least 8 characters long.',
  },
  passwordConfirmation: {
    isRequired: 'You must confirm your password.',
  },
  name: {
    isRequired: 'You must provide a name',
  },
};

export const isLat = (val: any) => val && val >= -90 && val <= 90;
export const isLon = (val: any) => val && val >= -180 && val <= 180;
export const isStripeComplete = (val: any) => val && val.complete;
