import React from 'react';
import moment from 'moment';
import { DateRange } from 'moment-range';
import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { splitEvery } from 'ramda';
import { compose } from 'recompose';

import Title from '../Title';
import Day from './Day';
import shouldCalendaraUpdate from '../shouldCalendarUpdate';
import withPrettySelectedIntervals from '../withPrettySelectedIntervals';

const Container = styled.div`
  padding-bottom: 44px;

  &:first-child {
    padding-top: 40px;
  }
`;

const StyledTitle = Title.extend`
  margin-bottom: 20px;
  margin-left: 5px;
`;

export interface OwnProps {
  date: moment.Moment;
  style?: any;
}

export interface ConnectedProps {
  availableIntervals: DateRange[];
  selectedIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
  onClick(date: moment.Moment): void;
}

type Props = OwnProps & ConnectedProps;

export class PreciseNavigator extends React.Component<Props, never> {
  render() {
    const { date, onClick } = this.props;
    const dates = Array.from(
      new DateRange(
        date.clone().startOf('month'),
        date.clone().endOf('month'),
      ).by('day'),
    );

    return (
      <Container style={this.props.style}>
        <StyledTitle>{date.format('MMMM YYYY')}</StyledTitle>
        <Flex flexDirection="column">
          {splitEvery(3, dates).map((row, i) => (
            <Flex key={i}>
              {row.map(day => (
                <Day
                  key={+day}
                  selectedIntervals={this.props.selectedIntervals}
                  availableIntervals={this.props.availableIntervals}
                  filteredOutIntervals={this.props.filteredOutIntervals}
                  date={day}
                  onClick={onClick}
                />
              ))}
            </Flex>
          ))}
        </Flex>
      </Container>
    );
  }
}

export default compose<Props, Props>(
  withPrettySelectedIntervals('PT1H'),
  shouldCalendaraUpdate('month'),
)(PreciseNavigator);
