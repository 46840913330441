import styled, { StyledFunction } from 'styled-components';
import { sliceFill, SliceProps } from '../../styles/common';

const slice: StyledFunction<SliceProps & React.SVGProps<SVGRectElement>> =
  styled.rect;

export default slice`
  height: 9px;
  width: calc(36px / 24);
  fill: ${sliceFill};
`;
