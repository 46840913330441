import styled from 'styled-components';
import { Box } from 'grid-styled';
import { semibold } from '../../styles/common';

export default styled(Box)`
  width: 72px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey6};
  font-size: ${({ theme }) => theme.typography.defaultFont};
  margin-bottom: 28px;
  ${semibold};
`;
