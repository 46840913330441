import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'NewApproximatedMetric.header',
    defaultMessage: 'Project approximation settings of {metric} Metric',
  },
  submit: {
    id: 'NewApproximatedMetric.submit',
    defaultMessage: 'Add Metric',
  },
  selectAll: {
    id: 'NewApproximatedMetric.selectAll',
    defaultMessage: 'Select the whole time interval available for the current Metric.',
  },
  approximationSettings: {
    id: 'NewApproximatedMetric.approximationSettings',
    defaultMessage: 'Approximation Settings',
  },
  sliceTypeUniform: {
    id: 'NewApproximatedMetric.sliceType.uniform',
    defaultMessage:
      'All slices independent of their time stamps will be calculated together',
  },
  sliceTypeUnique: {
    id: 'NewApproximatedMetric.sliceType.unique',
    defaultMessage:
      'All slices with particular time stamp will be calculated together',
  },
  sliceType: {
    id: 'NewApproximatedMetric.sliceType',
    defaultMessage: 'Slice type',
  },
  aggregationFunction: {
    id: 'NewApproximatedMetric.aggregationFunction',
    defaultMessage: 'Aggregation function',
  },
  average: {
    id: 'NewApproximatedMetric.average',
    defaultMessage: 'Average',
  },
  sum: {
    id: 'NewApproximatedMetric.sum',
    defaultMessage: 'Sum',
  },
  unique: {
    id: 'NewApproximatedMetric.unique',
    defaultMessage: 'Unique',
  },
  uniform: {
    id: 'NewApproximatedMetric.uniform',
    defaultMessage: 'Uniform',
  },
  filters: {
    id: 'NewApproximatedMetric.filters',
    defaultMessage: 'Filters',
  },
  approximationType: {
    id: 'NewApproximatedMetric.approximationType',
    defaultMessage: 'Approximation type',
  },
  day: {
    id: 'NewApproximatedMetric.dayOfAllDays',
    defaultMessage: 'Day of all days',
  },
  week: {
    id: 'NewApproximatedMetric.dayOfWeek',
    defaultMessage: 'Day of week',
  },
  month: {
    id: 'NewApproximatedMetric.dayOfMonth',
    defaultMessage: 'Day of month',
  },
  year: {
    id: 'NewApproximatedMetric.monthOfYear',
    defaultMessage: 'Month of year',
  },
});
