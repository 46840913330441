import React from 'react';
import { Box } from 'grid-styled';
import { FormattedMessage as F, FormattedNumber as N } from 'react-intl';
import { Text } from '@hm/ukie';
import { planIsTrial } from '../../utils/helpers';
import { BillingState } from '../../reducers/billing';

// TODO: refactor
interface Props {
  billingInfo: BillingState;
}

const PlanCosts: React.SFC<Props> = ({
  billingInfo: {
    type,
    effectiveOverageHourCostCent,
    showOverageCost,
    effectiveMonthlyCostCent,
    oneTimeSetupFeeCent,
    oneTimeSetupFeeDescription,
    showOneTimeSetupFee,
    currency,
  },
}) => (
  <Box mt={3} mb={2}>
    <F
      id="Plan.monthlyCost"
      defaultMessage="Monthly cost — {cost}"
      values={{
        cost: effectiveMonthlyCostCent ? (
          <N
            key="cost"
            style="currency"
            currency="usd"
            value={effectiveMonthlyCostCent / 100}
          />
        ) : (
          '?'
        ),
      }}
    >
      {(...text) => <Text.ThinTitle is="div">{text}</Text.ThinTitle>}
    </F>

    {showOverageCost ? (
      <F
        id="Plan.overageCost"
        defaultMessage="Overage hour cost - {cost}/hour"
        tagName="div"
        values={{
          cost: effectiveOverageHourCostCent ? (
            <N
              style="currency"
              currency={currency || 'usd'}
              value={effectiveOverageHourCostCent / 100}
              useGrouping={false}
            >
              {n => <Text.Important>{n}</Text.Important>}
            </N>
          ) : (
            '?'
          ),
        }}
      />
    ) : null}

    {showOneTimeSetupFee ? (
      <Text.ThinTitle>
        {oneTimeSetupFeeDescription} —{' '}
        <N
          style="currency"
          value={oneTimeSetupFeeCent / 100}
          currency={currency || 'usd'}
          useGrouping={false}
        />
      </Text.ThinTitle>
    ) : null}

    {planIsTrial(type) ? (
      <F
        id="Plan.trialInfo"
        defaultMessage="After trial will expire you will be automatically subscribed to Chronotope Basic and fees will be charged immediately"
      >
        {text => (
          <Text.Disclaimer mt={1} mb={2} is="p" lineHeight="text">
            {text}
          </Text.Disclaimer>
        )}
      </F>
    ) : null}
  </Box>
);

export default PlanCosts;
