import Timeline from '../components/timeline';
import { connect } from 'react-redux';
import {
  stopPlayback,
  stopDragSelect,
  startDragSelect,
  selectRange,
  selectSingle,
} from '../actions/timeline';
import { getAnomalies } from '../selectors/layerAnomalies';
import {
  getApproximationType,
  getTimezone,
  getFilteredTimeIntervals,
  getSelectedSlices,
  getCurrentSliceDuration,
  getNormalizedSliceWidth,
  getTimeWindow as getPersistedTimeWindow,
} from '../selectors/project';
import {
  computeMode,
  getBehaviour,
  getSpeed,
  getIsPlaying,
  getTimeWindow,
} from '../selectors/timeline';
import { AppState } from '../models';

const mapStateToProps = (state: AppState) => ({
  intervals: getFilteredTimeIntervals(state),
  sliceBorders: getSelectedSlices(state),
  timezone: getTimezone(state),
  anomalies: getAnomalies(state),
  sliceDuration: getCurrentSliceDuration(state),
  normalizedSliceWidth: getNormalizedSliceWidth(state),
  selected: getTimeWindow(state),
  growthWindow: getPersistedTimeWindow(state),
  isPlaying: getIsPlaying(state),
  speed: getSpeed(state),
  behaviour: getBehaviour(state),
  mode: computeMode(state),
  approximationType: getApproximationType(state),
});

const mapDispatchToProps = {
  selectRange,
  selectSingle,
  onInterrupt: stopPlayback,
  onStop: stopDragSelect,
  onStart: startDragSelect,
};

// TOOD: make proper use of OwnProps
export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(
  Timeline,
);
