import React from 'react';
import BootstrapModal from 'react-overlays/lib/Modal';
import styled from 'styled-components';

export const modalStyle = `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  & > * {
    outline: none;
  }
`;

interface Props {
  show: boolean;
  keyboard?: boolean;
  onHide(): void;
}

const Modal: React.SFC<Props> = props => (
  <BootstrapModal
    backdropStyle={{
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }}
    container={document.getElementById('content')}
    {...props}
  />
);

export default styled(Modal)`
  ${modalStyle};
`;
