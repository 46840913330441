import { takeEvery, select, put } from 'redux-saga/effects';
import { selectRange, types } from '../actions/timeline';
import { persistTimeWindow } from '../actions/projects';
import {
  getBehaviour,
  computeMode,
  getTimeWindow,
} from '../selectors/timeline';
import { getTimeWindow as getPersistedTimeWindow } from '../selectors/project';
import { PlaybackBehaviour, PlaybackMode } from '../models';

export default function*() {
  yield takeEvery(types.STOP_DRAG_SELECT, persist);
  yield takeEvery(types.STOP_PLAYBACK, restoreOrPersist);
}

function* persist() {
  const visibleTimeWindow = yield select(getTimeWindow);
  // FIXME: thunk
  yield put(persistTimeWindow(visibleTimeWindow) as any);
}

function* restore() {
  const persistedTimeWindow = yield select(getPersistedTimeWindow);
  yield put(selectRange(persistedTimeWindow));
}

function* restoreOrPersist() {
  const behaviour: PlaybackBehaviour = yield select(getBehaviour);
  const mode: PlaybackMode = yield select(computeMode);

  if (mode === 'single') return null;

  if (behaviour === 'growth') {
    yield restore();
  } else {
    yield persist();
  }
}
