import React from 'react';
import { FormattedMessage as F } from 'react-intl';
import { RouteComponentProps, Route, Switch } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Flex } from 'grid-styled';
import { propOr, pathOr } from 'ramda';

import {
  Button,
  Select,
  Scrollable,
  Tabs,
  TabItem,
  FillFlex,
  Footer,
  Box,
} from '@hm/ukie';

import FeatureToggle from '../FeatureToggle';
import IntlDocumentTitle from '../IntlDocumentTitle';
import EnvironmentInfo from '../EnvironmentInfo';
import EnvironmentList from './EnvironmentList';
import Projects from './Projects';
import Metrics from './Metrics';
import Datasets from './DatasetsContainer';
import Modal from '../Modal';
import ProjectType from '../ProjectType';
import PlainLink from '../PlainLink';
import GlobalAlert from '../GlobalAlert';
import NewRegularProject from '../NewRegularProject';
import NewApproximatedProject from '../NewApproximatedProject';

import { Environment } from '../../reducers/environments';
import { ProjectPreview } from '../../reducers/projects';
import { BaseLayer } from '../../reducers/baseLayers';
import { SortValue } from '../../reducers/explorer';
import { GlobalAlerts } from '../../reducers/alertStack';

interface Params {
  environmentId: string;
}

export type OwnProps = RouteComponentProps<Params>;

export interface StateProps {
  projects: Dict<ProjectPreview>;
  layers: Dict<BaseLayer>;
  environments: Dict<Environment>;
  selectedEnvironmentId: string;
  sortValue: SortValue;
  hasErrors?: boolean;
}

export interface DispatchProps {
  onEnvironmentSelect(environmentId: string): void;
  onHide?(id: string): void;
  onView?(id: string): void;
  onEdit?(id: string): void;
  onSortBy(sortValue: string): void;
}

export type Props = OwnProps & StateProps & DispatchProps;

interface State {
  selectedProjectId: string;
  selectedLayerId: string;
  showModal: boolean;
  currentStep: number;
  nextType: 'regular' | 'approximated';
}

export default class Explorer extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    projects: {},
    environments: {},
  };

  state = {
    selectedProjectId: undefined,
    selectedLayerId: undefined,
    showModal: false,
    currentStep: 1,
    nextType: null,
  };

  onProjectSelect = (selectedProjectId: string) =>
    this.setState({ selectedProjectId });

  onLayerSelect = (selectedLayerId: string) =>
    this.setState({ selectedLayerId });

  onEnvironmentSelect = (id: string) =>
    this.setState({ selectedProjectId: undefined }, () =>
      this.props.onEnvironmentSelect(id),
    );

  onAdd = (id: string) =>
    this.setState(
      {
        showModal: true,
        currentStep: 1,
      },
      () => this.props.onEnvironmentSelect(id),
    );

  hideModal = () =>
    this.setState({
      showModal: false,
      currentStep: 1,
      nextType: null,
    });

  onBack = () => this.setState({ currentStep: this.state.currentStep - 1 });

  onApproximated = () =>
    this.setState({
      currentStep: this.state.currentStep + 1,
      nextType: 'approximated',
    });

  onRegular = () =>
    this.setState({
      currentStep: this.state.currentStep + 1,
      nextType: 'regular',
    });

  // TODO: move closer to to the /projects route: we only need this state under
  // /projects route and Projects component to show steps "carousel" of
  // creating new project.
  renderCurrentStep = () => {
    const currentEnvironment = this.props.environments[
      this.props.selectedEnvironmentId
    ];

    switch (this.state.currentStep) {
      case 1:
        return (
          <ProjectType
            environment={propOr('', 'name', currentEnvironment)}
            onClose={this.hideModal}
            onApproximated={this.onApproximated}
            onRegular={this.onRegular}
          />
        );

      case 2:
        return this.state.nextType === 'regular' ? (
          <NewRegularProject onBack={this.onBack} onClose={this.hideModal} />
        ) : (
          <NewApproximatedProject
            onBack={this.onBack}
            onClose={this.hideModal}
          />
        );

      default:
        return null;
    }
  };

  // TODO: kinda selector logic. If we would separate different parts of
  // Explorer into smaller `connect`ed components this definitely should be
  // a selector: "which environment should I render given this state?"
  getSelectedEnvironmentIdFromProject = () => {
    const { projects, selectedEnvironmentId } = this.props;
    const { selectedProjectId } = this.state;

    return (
      selectedEnvironmentId ||
      pathOr(null, [selectedProjectId, 'environmentId'], projects)
    );
  };

  getSelectedEnvironmentIdFromLayer = () => {
    const { layers, selectedEnvironmentId } = this.props;
    const { selectedLayerId } = this.state;

    return (
      selectedEnvironmentId ||
      pathOr(null, [selectedLayerId, 'environmentIds', '0'], layers)
    );
  };

  render() {
    const { selectedProjectId } = this.state;
    const {
      environments,
      projects,
      sortValue,
      onSortBy,
      hasErrors,
      location,
      selectedEnvironmentId,
    } = this.props;
    const selectedProject = projects[selectedProjectId];

    return (
      <FillFlex flexDirection="column">
        <Flex flex={1}>
          <EnvironmentList
            environments={environments}
            selectedId={selectedEnvironmentId}
            onSelect={this.onEnvironmentSelect}
          />
          <Flex flex={1} flexDirection="column">
            <Box pt={2}>
              <FeatureToggle feature="datasetsTab">
                {isEnabled => (
                  <Tabs selected={location.pathname}>
                    <TabItem value="/projects">
                      <PlainLink to="/projects">
                        <F id="Explorer.projects" defaultMessage="Projects" />
                      </PlainLink>
                    </TabItem>
                    <TabItem value="/metrics">
                      <PlainLink to="/metrics">
                        <F id="Explorer.metrics" defaultMessage="Metrics" />
                      </PlainLink>
                    </TabItem>
                    {isEnabled ? (
                      <TabItem value="/datasets">
                        <PlainLink to="/datasets">
                          <F id="Explorer.datasets" defaultMessage="Datasets" />
                        </PlainLink>
                      </TabItem>
                    ) : null}
                  </Tabs>
                )}
              </FeatureToggle>
            </Box>

            <Flex p={2} justify="space-between" align="center">
              <Box />
              <Box>
                Sort by{' '}
                <Select value={sortValue} onChange={onSortBy}>
                  <option value="name" label="Name" />
                  <option value="changes" label="Last changes" />
                </Select>
              </Box>
              <Box>
                <PlainLink to="/import">
                  <Button icon="plus">
                    <F
                      id="Explorer.addNewMetric"
                      defaultMessage="Create new Metric"
                    />
                  </Button>
                </PlainLink>
              </Box>
            </Flex>

            <Flex bg="grey2" flex={1}>
              <Scrollable>
                <Box p={2} height="100%">
                  <Switch>
                    <Route
                      path="/projects"
                      exact
                      render={props => (
                        <Projects
                          onAdd={this.onAdd}
                          onSelect={this.onProjectSelect}
                          selectedProjectId={this.state.selectedProjectId}
                        />
                      )}
                    />
                    <Route
                      path="/metrics"
                      render={props => (
                        <Metrics
                          selectedId={this.state.selectedLayerId}
                          onSelect={this.onLayerSelect}
                        />
                      )}
                    />
                    <Route path="/datasets">
                      <Datasets />
                    </Route>
                  </Switch>
                </Box>
              </Scrollable>
            </Flex>
          </Flex>

          <MediaQuery minWidth={960}>
            {/* TODO: make proper hiding when minimize button is implemented */}
            <Switch>
              <Route
                path="/projects"
                exact
                render={() => (
                  <EnvironmentInfo
                    selectedEnvironmentId={this.getSelectedEnvironmentIdFromProject()}
                  />
                )}
              />
              <Route
                path="/metrics"
                render={() => (
                  <EnvironmentInfo
                    selectedEnvironmentId={this.getSelectedEnvironmentIdFromLayer()}
                  />
                )}
              />
            </Switch>
          </MediaQuery>
        </Flex>
        {location.pathname !== '/metrics' || hasErrors ? (
          <Footer
            warning={
              hasErrors ? (
                <GlobalAlert
                  selected={[
                    GlobalAlerts.InvoiceError,
                    GlobalAlerts.NoHoursLeft,
                    GlobalAlerts.InactiveWithoutPayment,
                    GlobalAlerts.InactiveWithPayment,
                  ]}
                />
              ) : null
            }
            actions={
              <Route
                path="/projects"
                render={() => (
                  <React.Fragment>
                    <Box mr={1}>
                      <Button
                        onClick={() => this.props.onView(selectedProjectId)}
                        disabled={!selectedProject}
                      >
                        View
                      </Button>
                    </Box>
                    <Button
                      primary
                      disabled={!selectedProject || selectedProject.readOnly}
                      onClick={() => this.props.onEdit(selectedProjectId)}
                    >
                      Edit
                    </Button>
                  </React.Fragment>
                )}
              />
            }
          />
        ) : null}
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          {this.renderCurrentStep()}
        </Modal>
        <IntlDocumentTitle titleId="explorer" />
      </FillFlex>
    );
  }
}
