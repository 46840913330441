import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import LabelInput from './LabelInput';
import AutosizeInput from '../AutosizeInput';
import Title from '../Title';

const StyledTitle = Title.extend`
  flex-shrink: 0;
`;

const messages = defineMessages({
  edit: {
    id: 'EditProject.title',
    defaultMessage: 'project time interval editing',
  },
});

interface Props {
  label: string;
  className?: string;
  onLabelUpdate(label: string): void;
}

const EditHeader: React.SFC<Props> = ({ label, onLabelUpdate, className }) => {
  return (
    <div className={className}>
      <AutosizeInput>
        <LabelInput
          value={label}
          onChange={e => onLabelUpdate(e.currentTarget.value)}
        />
      </AutosizeInput>
      <FormattedMessage {...messages.edit}>
        {children => <StyledTitle>{children}</StyledTitle>}
      </FormattedMessage>
    </div>
  );
};

export default styled(EditHeader)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
