import React from 'react';
import { prop } from 'ramda';
import { RouteComponentProps } from 'react-router-dom';
import { Control, Form, Errors } from 'react-redux-form';
import { Submit, Input, Password, LinkButton, SimpleModal } from '@hm/ukie';
import { Box, Flex } from 'grid-styled';
import { FormattedMessage as F } from 'react-intl';
import IntlDocumentTitle from '../IntlDocumentTitle';
import Modal from '../Modal';

import RequestPasswordResetForm from '../RequestPasswordResetForm';
import PasswordResetForm from '../PasswordResetForm';
import { Field, FieldError, FormActions, Fieldset } from '../Form';
import { isRequired, isEmail, errorMessages } from '../../utils/validators';
import { SignIn, PasswordConfirmation } from '../../models';

interface ProvidedProps {
  isValid: boolean;
  logIn(login: string, password: string): Promise<any>;
  onResetRequest(email: string): Promise<any>;
  onReset(reset: PasswordConfirmation): Promise<any>;
}

type Props = ProvidedProps & RouteComponentProps<any>;

interface State {
  isRequestResetFormOpen: boolean;
  isResetFormOpen: boolean;
}

export default class SignInForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isRequestResetFormOpen: false,
      isResetFormOpen: props.location.pathname === '/login/reset',
    };
  }

  email: HTMLInputElement;
  password: HTMLInputElement;

  onResetOpen = () => this.setState({ isRequestResetFormOpen: true });
  onHide = () =>
    this.setState({ isRequestResetFormOpen: false, isResetFormOpen: false });

  toRoot = () => this.props.history.replace('/');

  onSubmit = (login: SignIn) => {
    const { logIn, history } = this.props;

    logIn(login.email, login.password).then(() => {
      const { from } = this.props.location.state || {
        from: { pathname: '/', search: '' },
      };
      history.push(from.pathname + from.search, from);
    });
  };

  render() {
    return (
      <Flex
        flexDirection="column"
        align="center"
        style={{ height: '100%', overflowY: 'auto' }}
      >
        <Box width={224} mt={3}>
          <Form
            model="login"
            onSubmit={this.onSubmit}
            validators={{
              email: { isRequired, isEmail },
              password: { isRequired },
            }}
          >
            <Fieldset>
              <Field label={<F id="SignIn.email" defaultMessage="Email" />}>
                <Control
                  model=".email"
                  component={Input}
                  mapProps={{
                    invalid: ({ fieldValue }) =>
                      !fieldValue.valid && !fieldValue.pristine,
                  }}
                  getRef={(el => (this.email = el)) as any}
                />
                <Errors
                  show={field => field.focus && !field.pristine}
                  model=".email"
                  messages={errorMessages.email}
                  wrapper={props => (
                    <FieldError target={this.email} {...props} />
                  )}
                />
              </Field>

              <Field
                label={<F id="SignIn.password" defaultMessage="Password" />}
              >
                <Control.text
                  component={Password}
                  model=".password"
                  mapProps={{
                    invalid: ({ fieldValue }) =>
                      !fieldValue.valid && !fieldValue.pristine,
                  }}
                  getRef={(el => (this.password = el)) as any}
                />
                <Errors
                  show={field => field.focus && !field.pristine}
                  model=".password"
                  messages={errorMessages.password}
                  wrapper={props => (
                    <FieldError target={this.password} {...props} />
                  )}
                />
              </Field>
            </Fieldset>
            <FormActions>
              <Submit disabled={!this.props.isValid}>
                <F id="SignIn.submit" defaultMessage="Log in" />
              </Submit>
            </FormActions>
            <Box mt={3}>
              <LinkButton onClick={this.onResetOpen}>
                <F
                  id="SignIn.resetPassword"
                  defaultMessage="Forgot the password?"
                />
              </LinkButton>
            </Box>
          </Form>
          <IntlDocumentTitle titleId="signIn" />
        </Box>
        <Errors
          model="login"
          show={prop('submitFailed')}
          messages={errorMessages.form}
          wrapper={({ children }) => (
            <Flex
              style={{ height: 40 }}
              flexDirection="column"
              align="center"
              width="100%"
              mt="auto"
              bg="warning"
            >
              {children}
            </Flex>
          )}
          component={({ children }) => <Box my="auto">{children}</Box>}
        />
        <Modal show={this.state.isRequestResetFormOpen} onHide={this.onHide}>
          <SimpleModal
            title="Forgot the password?"
            onHide={this.onHide}
            width={344}
          >
            <RequestPasswordResetForm onSubmit={this.props.onResetRequest} />
          </SimpleModal>
        </Modal>
        <Modal show={this.state.isResetFormOpen} onHide={this.toRoot}>
          <SimpleModal title="Forgot the password?" onHide={this.toRoot}>
            <PasswordResetForm
              onSubmit={this.props.onReset}
              location={this.props.location}
            />
          </SimpleModal>
        </Modal>
      </Flex>
    );
  }
}
