import React from 'react';
import { Box, Flex } from 'grid-styled';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getIsXrayEnabled } from '../reducers/dev';
import { AppState } from '../models';
import Header from './Header';
import FeatureToggle from './FeatureToggle';
import XRay from 'react-x-ray';

const Body = Flex.extend`
  width: 100%;
  height: 100%;
`;

const Children = Box.extend`
  position: relative;
  flex: 0 1 100%;
  overflow: hidden;
`;

const Layout: React.SFC<{ isXrayEnabled?: boolean }> = ({
  children,
  isXrayEnabled,
}) => {
  const content = (
    <Body flexDirection="column">
      <Header />
      <Children id="content">{children}</Children>
    </Body>
  );

  return (
    <FeatureToggle feature="devMenu">
      {isEnabled =>
        isEnabled ? (
          <XRay
            style={{ height: '100%' }}
            disabled={!isXrayEnabled}
            color="#2d89b7"
            backgroundColor="#c1e0ef"
          >
            {content}
          </XRay>
        ) : (
          content
        )
      }
    </FeatureToggle>
  );
};

// TODO: do not include x-ray in production
export default withRouter(
  connect((state: AppState, ownProps: any) => ({
    isXrayEnabled: getIsXrayEnabled(state),
  }))(Layout),
);
