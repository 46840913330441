import { defineMessages } from 'react-intl';

export default defineMessages({
  help: {
    id: 'Header.help',
    defaultMessage: 'Help',
  },
  helpPage: {
    id: 'Header.helpPage',
    defaultMessage: 'Help page',
  },
  shortcuts: {
    id: 'Header.shortcuts',
    defaultMessage: 'Shortcuts',
  },
  termsOfUse: {
    id: 'Header.termsOfUse',
    defaultMessage: 'Terms Of Use',
  },
  privacyPolicy: {
    id: 'Header.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  contactUs: {
    id: 'Header.contactUs',
    defaultMessage: 'Contact us',
  },
});
