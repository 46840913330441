import { Reducer } from 'redux';
import { pathOr } from 'ramda';
import { stringify } from 'query-string';
import { types as userTypes } from '../actions/user';
import { types as workspaceTypes } from './workspaces';
import { get } from '../api/rest';
import { fulfill, pend } from '../utils/promise';
import { AppState } from '../models';

export const types = {
  FETCH: 'features/FETCH',
  RESET: 'features/RESET',
};

export const fetchFeatures = (features: string[]) => ({
  type: types.FETCH,
  payload: get(`/features?${stringify({ features })}`),
});

export const resetFeatures = () => ({ type: types.RESET });

export interface FeaturesState {
  toggles: Dict<boolean>;
  isFetching: boolean;
}

export const initialState = {
  toggles: {},
  isFetching: false,
};

const reducer: Reducer<FeaturesState> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case pend(types.FETCH):
      return {
        ...state,
        isFetching: true,
      };

    case fulfill(types.FETCH):
      return {
        ...state,
        toggles: payload.data,
        isFetching: false,
      };

    case userTypes.LOG_OUT:
    case fulfill(workspaceTypes.SELECT):
    case types.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;

export const isFeatureEnabled = (state: AppState, feature: string): boolean =>
  pathOr(false, ['features', 'toggles', feature], state);
