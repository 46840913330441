import { colors } from '@hm/ukie';
import { scaleLinear, scalePoint, scaleQuantize } from 'd3-scale';
import { Duration, Moment } from 'moment';
import { DateRange } from 'moment-range';
import { clamp, isEmpty, memoize } from 'ramda';
import React from 'react';
import Idle from 'react-idle';
import { Anomaly, PlaybackBehaviour, PlaybackMode } from '../../models';
import { ApproximationType } from '../../reducers/currentProject';
import { wrapWithMoment } from '../../utils/helpers';
import Anomalies from './Anomalies';
import Brush from './Brush';
import Handle from './Handle';
import Slider from './Slider';
import Ticks from './TicksAdaptable';
import Tooltip from './Tooltip';
import Track from './Track';

const PLAYBACK_TIMEOUT = 60 * 1000;

const DEFAULT_HEIGHT = 105;
const NORMAL_WIDTH = 1000;
const TICK_HEIGHT = 2;
const PADDING_LEFT = 20;
const PADDING_RIGHT = 40;
const SLICE_WIDTH_MIN = 4;
// one approximated day fits nicely
const SLICE_WIDTH_MAX = SLICE_WIDTH_MIN * 7.7;

export interface Props {
  intervals: DateRange[];
  sliceBorders: Moment[];
  timezone: string;
  anomalies: Anomaly[];
  sliceDuration: Duration;
  normalizedSliceWidth: number;
  selected: [number, number];
  growthWindow: [number, number];
  isPlaying: boolean;
  speed: number;
  behaviour: PlaybackBehaviour;
  mode: PlaybackMode;
  approximationType: ApproximationType;
  selectRange(range: [number, number]);
  selectSingle(single: number);
  onStart();
  onInterrupt();
  onStop();
}

interface State {
  userX?: number;
  isBrushing?: boolean;
}

/**
 * Tool for displaying project slices and selecting intervals of them
 *
 * See https://mathrioshka.atlassian.net/wiki/display/HAB/Timeline for specs
 */
export default class Timeline extends React.Component<Props, State> {
  container: HTMLDivElement;
  canvas: SVGGElement;

  state = {
    userX: null,
    isBrushing: false,
  };

  getTimeScale = memoize((isoDates: string[], timelineWidth: number) =>
    wrapWithMoment(
      scalePoint()
        .domain(isoDates)
        .range([0, timelineWidth]),
    ),
  );

  getBrushScale = memoize((timelineWidth: number) =>
    scaleLinear<number>().range([0, timelineWidth]),
  );

  getTooltipScale = memoize((timelineWidth: number, labels: Moment[]) =>
    scaleQuantize<Moment>()
      .domain([0, timelineWidth])
      .range(labels),
  );

  getLabelScale = memoize((slices: Moment[]) =>
    scaleQuantize<Moment>().range(slices),
  );

  moveTooltip = (e: React.MouseEvent<SVGElement>) => {
    this.setState({ userX: this.positionOnScale(e.clientX) });
  };

  hideTooltip = (e: React.MouseEvent<SVGElement>) => {
    this.setState({ userX: null });
  };

  positionOnScale = (x: number) => {
    if (!this.container) return 0;
    return x - this.container.getBoundingClientRect().left - PADDING_LEFT;
  };

  onChangeRange = (selected: [number, number]) => {
    if (this.props.isPlaying) this.props.onInterrupt();
    this.props.selectRange(selected);
  };

  onChangeSingle = (single: number) => {
    if (this.props.isPlaying) this.props.onInterrupt();
    this.props.selectSingle(single);
  };

  getSlicePxWidth() {
    const width = NORMAL_WIDTH / (this.props.sliceBorders.length - 1);
    return clamp(SLICE_WIDTH_MIN, SLICE_WIDTH_MAX, width);
  }

  startBrushing = () => {
    this.setState({ isBrushing: true });
    this.props.onStart();
  };

  stopBrushing = () => {
    this.setState({ isBrushing: false });
    this.props.onStop();
  };

  render() {
    const {
      intervals,
      sliceBorders,
      timezone,
      isPlaying,
      sliceDuration,
      normalizedSliceWidth,
      selected,
      anomalies,
      mode,
      behaviour,
      growthWindow,
      approximationType,
      onInterrupt,
    } = this.props;

    const isRange = mode === 'range';
    const isGrowth = behaviour === 'growth';

    if (isEmpty(intervals)) return null;

    const timelineWidth = (sliceBorders.length - 1) * this.getSlicePxWidth();
    const containerWidth = timelineWidth + PADDING_LEFT + PADDING_RIGHT;

    const shadowRange = isRange && isGrowth && isPlaying ? growthWindow : null;

    const timeScale = this.getTimeScale(
      sliceBorders.map(d => d.toISOString()),
      timelineWidth,
    );
    const tooltipScale = this.getTooltipScale(timelineWidth, sliceBorders);
    const brushScale = this.getBrushScale(timelineWidth);
    const labelScale = this.getLabelScale(sliceBorders);
    const brushY = DEFAULT_HEIGHT / 2 + TICK_HEIGHT / 2;

    const controlProps = {
      timezone,
      labelScale,
      sliceDuration,
      approximationType,
      scale: brushScale,
      y: brushY,
      boundaries: this.canvas,
      onStart: this.startBrushing,
      onStop: this.stopBrushing,
    };

    return (
      <div className="timeline" ref={el => (this.container = el)}>
        <svg
          height={DEFAULT_HEIGHT}
          width={containerWidth}
          onMouseMove={this.moveTooltip}
          onMouseLeave={this.hideTooltip}
        >
          <g
            transform={`translate(${PADDING_LEFT})`}
            ref={el => (this.canvas = el)}
          >
            <Track
              width={timelineWidth}
              height={TICK_HEIGHT}
              y={DEFAULT_HEIGHT / 2}
              displayProgress={isRange}
              brushScale={brushScale}
              selected={selected}
              previousRange={shadowRange}
            />
            <Ticks
              intervals={intervals}
              timezone={timezone}
              scale={timeScale}
              y={DEFAULT_HEIGHT / 2}
              sliceDuration={sliceDuration}
              brushScale={brushScale}
              selected={selected}
              previousRange={shadowRange}
              displayProgress={isRange}
              approximationType={approximationType}
            />
            <Anomalies
              anomalies={anomalies}
              scale={timeScale}
              height={DEFAULT_HEIGHT}
            />
            {this.state.userX && !this.state.isBrushing ? (
              <Tooltip
                slice={tooltipScale(this.state.userX)
                  .clone()
                  .tz(timezone)}
                sliceDuration={sliceDuration}
                approximationType={approximationType}
                transform={`translate(${this.state.userX - 15}, 5)`}
              />
            ) : null}
            {shadowRange ? (
              <Handle
                transform={`translate(${brushScale(shadowRange[1]) -
                  4}, ${brushY})`}
                color={colors.grey5}
              />
            ) : null}
            {isRange ? (
              <Brush
                selected={selected}
                minExtent={normalizedSliceWidth}
                slices={sliceBorders}
                onChange={this.onChangeRange}
                {...controlProps}
              />
            ) : (
              <Slider
                selected={selected[1]}
                onChange={this.onChangeSingle}
                {...controlProps}
              />
            )}
          </g>
        </svg>
        {isPlaying ? (
          <Idle
            timeout={PLAYBACK_TIMEOUT}
            onChange={({ idle }) => idle && onInterrupt()}
          />
        ) : null}
      </div>
    );
  }
}
