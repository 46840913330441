import { defineMessages } from 'react-intl';

export default defineMessages({
  navigation: {
    id: 'Tools.move',
    defaultMessage: 'Navigation tool',
  },
  cursor: {
    id: 'Tools.cursor',
    defaultMessage: 'Select tool',
  },
  marker: {
    id: 'Tools.flag',
    defaultMessage: 'Marker',
  },
  polyline: {
    id: 'Tools.polyline',
    defaultMessage: 'Polyline',
  },
  polygon: {
    id: 'Tools.polygon',
    defaultMessage: 'Polygon',
  },
  radius: {
    id: 'Tools.radius',
    defaultMessage: 'Radius',
  },
  road: {
    id: 'Tools.road',
    defaultMessage: 'Road',
  },
  toggleHiddenObjects: {
    id: 'Tools.toggleHiddenObjects',
    defaultMessage: 'Show hidden objects',
  },
  screenshot: {
    id: 'Tools.screenshot',
    defaultMessage: 'Take screenshot',
  },
  highlight: {
    id: 'Tools.highlightObjects',
    defaultMessage: 'Highlight objects',
  },
});
