import moment from 'moment';
import { createSelector } from 'reselect';
import { head, last, isEmpty } from 'ramda';
import { DateRange } from 'moment-range';

import { isNonEmpty } from '../utils/helpers';
import {
  mergeIntervals,
  invertSelection,
  countByDuration,
} from '../utils/time';

import { AppState } from '../models';
import { SelectionState } from '../reducers/createSelectionReducer';
import { isLayersFetching } from './layers';
import { getBaseLayersForCurrentEnvironment } from '../reducers/baseLayers';

export const getZoomLevel = (state: AppState) => state.calendar.zoomLevel;
export const getLastZoomLevel = (state: AppState) =>
  state.calendar.lastZoomLevel;
export const getSliceDuration = (state: AppState) =>
  state.calendar.sliceDuration;
export const getSelectedIntervals = (state: AppState) =>
  state.calendar.selection.selectedIntervals;
export const getSelection = (state: AppState) =>
  state.calendar.selection.currentInterval;

export const getIsSelectionInverted = (state: AppState) =>
  state.calendar.selection.isInverted;

export const getCalendarLabel = (state: AppState) => state.calendar.label;
export const getAvailableIntervals = (state: AppState) =>
  state.calendar.availableIntervals;
export const getStartTime = (state: AppState) => state.calendar.startTime;
export const getType = (state: AppState) => state.calendar.type;
export const getApproximationType = (state: AppState) =>
  state.calendar.approximationType;
export const getStartAtWeekday = (state: AppState) =>
  state.calendar.startAtWeekday;
export const getStartAtMonthDay = (state: AppState) =>
  state.calendar.startAtMonthDay;
export const getStartAtMonth = (state: AppState) => state.calendar.startAtMonth;
export const getLayerOriginId = (state: AppState) =>
  state.calendar.layerOriginId;
export const getApproximationFunction = (state: AppState) =>
  state.calendar.function;
export const getSliceType = (state: AppState) => state.calendar.sliceType;
export const isSliceTypeEnabled = (state: AppState) =>
  state.calendar.isSliceTypeEnabled;
export const isSliceTypeStatic = (state: AppState) =>
  state.calendar.isSliceTypeStatic;
export const getTimeFilters = (state: AppState) =>
  state.calendar.filterSettings;
export const getFilteredOutIntervals = (state: AppState) =>
  state.calendar.filteredOutIntervals;
export const getFilterPreview = (state: AppState) =>
  state.calendar.filterPreview;

interface CalendarSelectorConfig {
  [index: string]: {
    unit: moment.unitOfTime.Diff;
    step?: number;
    duration?: string;
  };
}

// TODO: replace with withPropsOnChange from recompose since it’s UI logic
export const createComputeDates = (config: CalendarSelectorConfig) =>
  createSelector(
    getAvailableIntervals,
    getZoomLevel,
    (availableIntervals, zoomLevel) => {
      if (isEmpty(availableIntervals)) return [];

      const { unit, step } = config[zoomLevel];
      const { start } = head(availableIntervals);
      const { end } = last(availableIntervals);
      const isRound = end.isSame(end.clone().startOf(unit));

      return Array.from(
        new DateRange(
          start.clone().startOf(unit),
          end.clone().add(isRound ? 0 : 1, unit),
        ).by(unit, { step, exclusive: true }),
      );
    },
  );

const selectorConfig: CalendarSelectorConfig = {
  precise: { unit: 'day' },
  day: { unit: 'month' },
  month: { unit: 'year', duration: 'P1D' },
  year: { unit: 'year', step: 3, duration: 'P1M' },
};

const navigatorConfig: CalendarSelectorConfig = {
  precise: { unit: 'month', duration: 'PT1H' },
  day: { unit: 'year', duration: 'P1D' },
  month: { unit: 'year', duration: 'P1M' },
  year: { unit: 'year', step: 3, duration: 'P1M' },
};

export const computeSelectorDates = createComputeDates(selectorConfig);

export const computeNavigatorDates = createComputeDates(navigatorConfig);

// FIXME: make scalable somehow. Introduce selector creator or move this logic
// into HOC
const computeSelection = (
  selectedIntervals: DateRange[],
  isInverted: boolean,
  selection: DateRange,
) => {
  if (!selection) return selectedIntervals;

  return isInverted
    ? invertSelection(selectedIntervals, selection)
    : mergeIntervals([...selectedIntervals, selection].sort());
};

export const computeCalendarSelection = createSelector(
  getSelectedIntervals,
  getIsSelectionInverted,
  getSelection,
  (selectedIntervals, isInverted, selection) =>
    computeSelection(selectedIntervals, isInverted, selection),
);

export const computeDayRangeSelection = createSelector(
  (state: AppState) => state.calendar.dayRangeFilter,
  (dayRangeFilter: SelectionState) =>
    computeSelection(
      dayRangeFilter.selectedIntervals,
      dayRangeFilter.isInverted,
      dayRangeFilter.currentInterval,
    ),
);

export const canSubmit = createSelector(
  getSelectedIntervals,
  selectedIntervals => (isEmpty(selectedIntervals) ? false : true),
);

export const computeStartTime = createSelector(
  getSelectedIntervals,
  getStartTime,
  (selectedIntervals, startTime) =>
    isEmpty(selectedIntervals) ? undefined : startTime,
);

export const canAddSelectedLayer = createSelector(
  isLayersFetching,
  getLayerOriginId,
  getBaseLayersForCurrentEnvironment,
  (isFetching, originId, filteredBaseLayers) =>
    !isFetching && !!originId && isNonEmpty(filteredBaseLayers),
);

export const isTooManySlicesSelected = createSelector(
  getSelectedIntervals,
  getSliceDuration,
  (intervals, sliceDuration) =>
    Math.ceil(countByDuration(intervals, moment.duration(sliceDuration))) > 366,
);
