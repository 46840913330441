import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { DateRange } from 'moment-range';
import { splitEvery } from 'ramda';
import { typography } from '@hm/ukie';
import { compose } from 'recompose';

import Month from './Month';
import shouldCalendarUpdate from '../shouldCalendarUpdate';
import withPrettySelectedIntervals from '../withPrettySelectedIntervals';
import { someContains } from '../../utils/time';

const Container = styled.div`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 30px;
  }

  cursor: pointer;
`;

const Title = styled.div`
  font-size: ${typography.smallFont};
  width: 22px;
`;

const Months = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 0.5px; // XXX: makes center alignment better
`;

const Row = styled.div`
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 1px;
  }
`;

interface Props {
  date: moment.Moment;
  style?: any;
  availableIntervals: DateRange[];
  selectedIntervals: DateRange[];
  filteredOutIntervals: DateRange[];
  onClick(): void;
}

export class YearsNavigator extends React.Component<Props, never> {
  render() {
    const {
      date,
      selectedIntervals,
      availableIntervals,
      filteredOutIntervals,
    } = this.props;

    const rows = splitEvery(
      4,
      Array.from(
        new DateRange(
          date.clone().startOf('year'),
          date.clone().endOf('year'),
        ).by('month'),
      ),
    );

    return (
      <Container onClick={this.props.onClick}>
        <Title>{date.format('YYYY')}</Title>
        <Months>
          {rows.map((row, r) => (
            <Row key={r}>
              {row.map(month => (
                <Month
                  key={+month}
                  isSelected={someContains(selectedIntervals, month)}
                  isDisabled={
                    !someContains(availableIntervals, month) ||
                    someContains(filteredOutIntervals, month)
                  }
                />
              ))}
            </Row>
          ))}
        </Months>
      </Container>
    );
  }
}

export default compose<Props, Props>(
  withPrettySelectedIntervals('P1M'),
  shouldCalendarUpdate('year'),
)(YearsNavigator);
