import { always } from 'ramda';
import { createMetrics } from 'react-metrics';
import { GoogleAnalytics } from './utils/analytics';
import { GOOGLE_ANALYTICS } from './keys';

const noop = always;

export const mockVendor = {
  name: 'Mock Analytics',
  listen: noop,
  setRouteState: noop,
  useTrackBinding: noop,
  destroy: noop,
  get enabled() {
    return true;
  },
  api: {
    pageView: noop,
    track: noop,
  },
};

const vendors =
  process.env.ENV === 'production'
    ? [
        {
          name: 'Google Analytics',
          api: new GoogleAnalytics({
            trackingId: GOOGLE_ANALYTICS,
          }),
        },
      ]
    : mockVendor;

export const config = {
  vendors,
  pageViewEvent: 'pageLoad',
  pageDefaults: routeState => {
    const paths = routeState.pathname.substr(1).split('/');

    return {
      siteName: 'Chronotope',
      timestamp: new Date(),
      build: process.env.RELEASE,
      environment: process.env.ENV,
      category: !paths[0] ? 'root' : paths[0],
    };
  },
  debug:
    // never log anything in tests and production
    !(
      process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'production'
    ) &&
    // otherwise look at this setting
    // TODO: add toggle in dev menu
    localStorage.getItem('debugMetrics') === 'enabled',
};

export default createMetrics(config);
