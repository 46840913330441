import { post } from '../api/rest';
import { CompassState } from '../reducers/compass';

const RESET_CAMERA = 'RESET_CAMERA';
const UPDATE_COMPASS = 'UPDATE_COMPASS';

export const resetCamera = () => (dispatch, getState) => {
  const projectId = getState().project.id;

  dispatch({
    type: RESET_CAMERA,
    payload: post(`/projects/${projectId}/reset_camera`),
  });
};

export const updateCompass = (compassState: CompassState) => ({
  type: UPDATE_COMPASS,
  payload: compassState,
});

export const types = {
  RESET_CAMERA,
  UPDATE_COMPASS,
};
