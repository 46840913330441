import React from 'react';
import { connect } from 'react-redux';
import { isFeatureEnabled } from '../../reducers/features';
import { AppState } from '../../models';

export const supportedFeatures = [
  'highlights',
  'workspaces',
  'abr',
  'devMenu',
  'maskObjectState',
  'datasetsTab',
  'geoImport',
];

interface OwnProps {
  feature: string;
  children: React.ReactNode | ((isEnabled: boolean) => React.ReactNode);
}

interface ProvidedProps {
  isEnabled: boolean;
}

export type Props = OwnProps & ProvidedProps;

// `any` most likely due to this https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051
const FeatureToggle: React.SFC<Props> = ({ isEnabled, children }) =>
  (typeof children === 'function'
    ? children(isEnabled)
    : isEnabled
      ? children
      : null) as any;

export default connect((state: AppState, props: OwnProps) => ({
  isEnabled: isFeatureEnabled(state, props.feature),
}))(FeatureToggle);
