import { Icon, IconProps } from '@hm/ukie';
import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';

interface Props {
  compact?: boolean;
  onChange?(toggle: boolean): void;
}

const FoldButton = styled(
  ({ isOpened, ...props }: { isOpened: boolean } & IconProps) => (
    <Icon name="fold" {...props} />
  ),
)`
  transition: transform 150ms ease-in-out;
  transform: rotate(${({ isOpened }) => (isOpened ? '0' : '-90deg')});
`;

const Categories: React.SFC<Props> = ({ compact, onChange, children }) => {
  return (
    <div className="metric-options">
      <div className={cx('metric-option', { 'is-closed': compact })}>
        <div
          className="metric-option__close"
          onClick={onChange && (() => onChange(!compact))}
        >
          <FoldButton name="fold" isOpened={!compact} />
        </div>
        <div className="metric-option__content-wrap">{children}</div>
      </div>
    </div>
  );
};

Categories.defaultProps = { compact: false };

export default Categories;
