import React from 'react';
import { Text } from '@hm/ukie';

const Message = Text.extend`
  & pre {
    font-family: monospace;
    display: inline;
  }
`;

const DragMessage = () => (
  <Message lineHeight="text" is="div" fontSize={2} color="white">
    To import map object, drag and drop file here. The file should be with a
    maximum size of 200 MB and in one of the next formats: <pre>.kml</pre>,{' '}
    <pre>.geojson</pre>, <pre>.mapinfo</pre> or shapefile in <pre>.zip</pre>{' '}
    archive.
  </Message>
);

export default DragMessage;
