import { Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { types } from '../actions/user';
import { fulfill } from '../utils/promise';
import { saveTokens, clearTokens, setAccessToken } from '../utils/auth';
import { types as workspacesTypes } from '../reducers/workspaces';

import { AppState } from '../models';

/**
 * Persists JWT tokens in localStorage and passes actions down further for
 * reducer consumption.
 */
export default (({ dispatch, getState }: MiddlewareAPI<AppState>) => (
  next: Dispatch<AppState>,
) => (action: any) => {
  const { type, payload } = action;

  switch (type) {
    case fulfill(types.LOG_IN):
    case fulfill(types.COMPLETE_SIGN_UP):
    case fulfill(workspacesTypes.SELECT):
      saveTokens(payload.accessToken, payload.refreshToken);
      break;

    case fulfill(types.REFRESH_ACCESS_TOKEN):
      setAccessToken(payload.access_token);
      break;

    case types.LOG_OUT:
      clearTokens();
      break;

    default:
      return next(action);
  }

  return next(action);
}) as Middleware;
