import React from 'react';
import { prop } from 'ramda';
import { Flex, Box } from 'grid-styled';
import { ConnectDragSource } from 'react-dnd';
import {
  DropdownContainer,
  Input,
  BorderFlex,
  IconButton,
  Pictogram,
} from '@hm/ukie';
import Overlay from 'react-overlays/lib/Overlay';
import { injectIntl, InjectedIntlProps, defineMessages } from 'react-intl';

import Menu from './MenuContainer';
import ColorMenu from '../../containers/ColorMenu';
import Handle from './Handle';
import Track from '../Track';
import { Layer } from '../../reducers/layers';
import { Gradient } from '../../reducers/gradients';
import GradientPreview from '../GradientPreview';

const messages = defineMessages({
  enable: {
    id: 'Metric.enable',
    defaultMessage: 'Toggle visibility',
  },
  gradient: {
    id: 'Metric.gradient',
    defaultMessage: 'Change metric/color gradient',
  },
});

interface OwnProps {
  metric: Layer;
  gradient: Gradient;
  connectDragSource: ConnectDragSource;
  showHandle: boolean;
  updateLayer(id: string, updates): void;
}

type Props = OwnProps & InjectedIntlProps;

interface State {
  showColorMenu?: boolean;
  showLayerMenu?: boolean;
}

class Header extends React.Component<Props, State> {
  colorButton: HTMLButtonElement;
  menuButton: HTMLButtonElement;

  state = {
    showLayerMenu: false,
    showColorMenu: false,
  };

  toggleVisibility = () => {
    this.props.updateLayer(this.props.metric.id, {
      visible: !this.props.metric.visible,
    });
  };

  toggleColorMenu = () => {
    this.setState({ showColorMenu: !this.state.showColorMenu });
  };

  toggleLayerMenu = () => {
    this.setState({ showLayerMenu: !this.state.showLayerMenu });
  };

  hideColorMenu = () => {
    this.setState({ showColorMenu: false });
  };

  hideLayerMenu = () => {
    this.setState({ showLayerMenu: false });
  };

  render() {
    const { metric, gradient, intl, updateLayer, showHandle } = this.props;

    return (
      <BorderFlex borderBottom="1px solid" p={2} style={{ lineHeight: 2 }}>
        {this.props.connectDragSource(
          <div style={{ visibility: showHandle ? 'visible' : 'hidden' }}>
            <Handle name="handle" />
          </div>,
        )}
        <Box mx={1}>
          <IconButton
            onClick={this.toggleVisibility}
            style={{ opacity: metric.visible ? 1 : 0.5 }}
            icon={
              metric.visible ? (
                <Pictogram
                  size="medium"
                  name="eyeOpened"
                  width={16}
                  height={16}
                />
              ) : (
                <Pictogram
                  size="medium"
                  name="eyeClosed"
                  width={16}
                  height={16}
                />
              )
            }
            title={intl.formatMessage(messages.enable)}
          />
        </Box>
        <Input
          titleFont
          value={metric.label}
          onChange={e =>
            updateLayer(metric.id, { label: e.currentTarget.value })
          }
        />
        <Flex flex="1 0 auto" justify="flex-end">
          <Box ml={2} mr={1}>
            <IconButton
              ref={el => (this.colorButton = el)}
              onClick={this.toggleColorMenu}
              title={intl.formatMessage(messages.gradient)}
            >
              {gradient ? (
                <GradientPreview colors={gradient.points.map(prop('color'))} />
              ) : null}
            </IconButton>
          </Box>
          <Track
            element="div"
            className="metric-header__action"
            data-metrics-event-name="metricMenu"
            data-metrics-category="Metric"
          >
            <IconButton
              icon="burgerSlim"
              ref={el => (this.menuButton = el)}
              onClick={this.toggleLayerMenu}
            />
          </Track>
        </Flex>
        <Overlay
          show={this.state.showColorMenu}
          onHide={this.hideColorMenu}
          placement="bottom"
          target={this.colorButton}
          rootClose
        >
          <DropdownContainer>
            <ColorMenu
              layerId={metric.id}
              layerGradientId={metric.gradientId}
            />
          </DropdownContainer>
        </Overlay>
        <Overlay
          show={this.state.showLayerMenu}
          onHide={this.hideLayerMenu}
          placement="bottom"
          target={this.menuButton}
          rootClose
        >
          <DropdownContainer>
            <Menu id={metric.id} />
          </DropdownContainer>
        </Overlay>
      </BorderFlex>
    );
  }
}

export default injectIntl(Header);
