import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import {
  Button,
  Absolute,
  Text,
  Card,
  Progress,
  Box,
  getContentColor,
} from '@hm/ukie';
import { Uploader } from '@navjobs/upload';

import { getAuthHeader } from '../../utils/auth';
import {
  GisImportApply,
  ObjectFileUpload,
  ObjectFileLayer,
} from '../../reducers/gisImport';

import FeatureToggle from '../FeatureToggle';
import DragMessage from './DragMessage';
import Form from './Form';

const Droparea = styled(Dropzone)`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface FormLayer {
  id: string;
  data: ObjectFileLayer;
  enabled: boolean;
  merge: boolean;
  field: string;
  color: string;
}

export interface ImportForm {
  layers: FormLayer[];
}

interface Props {
  projectId: string;
  environmentId: string;
  onSubmit(id: string, form: GisImportApply): Promise<any>;
}

interface State {
  showOverlay: boolean;
  response: ObjectFileUpload;
  form: ImportForm;
  error: any;
}

export default class ObjectUpload extends React.Component<Props, State> {
  state: State = {
    showOverlay: false,
    response: null,
    form: null,
    error: null,
  };

  onUpload = ({ response }: { response: ObjectFileUpload }) =>
    this.setState({
      showOverlay: true,
      response,
      form: {
        layers: response.layers.map(layer => ({
          id: layer.id,
          data: layer,
          enabled: true,
          merge: false,
          field: layer.schema[0].property,
          color: getContentColor(),
        })),
      },
    });

  onChange = (form: ImportForm) => this.setState({ form });

  onError = (error: any) =>
    this.setState({ response: null, error, showOverlay: true });

  onClear = () =>
    this.setState({
      form: null,
      response: null,
      error: null,
      showOverlay: false,
    });

  render() {
    const { response, showOverlay } = this.state;
    const { children, environmentId } = this.props;

    return (
      <FeatureToggle feature="geoImport">
        {isEnabled =>
          isEnabled ? (
            <Uploader
              request={{
                fileName: 'file',
                url: '/api/v1/gis_import/files',
                method: 'POST',
                headers: { Authorization: getAuthHeader() },
                fields: { environmentId },
              }}
              uploadOnSelection
              reset
              onComplete={this.onUpload}
              onError={this.onError}
            >
              {({ onFiles, progress, files }) => (
                <Droparea
                  disableClick
                  disablePreview
                  accept=".kml,.geojson,.mapinfo,.zip"
                  onDropAccepted={onFiles}
                  maxSize={200 * 1024 * 1024}
                  multiple={false}
                >
                  {({ isDragActive, draggedFiles }) => (
                    <React.Fragment>
                      {children}
                      {/* // FIXME: */}
                      {isDragActive || showOverlay || progress ? (
                        <Absolute
                          top={0}
                          right={0}
                          bottom={0}
                          left={0}
                          bg="overlayDark"
                          display="flex"
                          zIndex={10000}
                        >
                          <Box width={300} m="auto">
                            {isDragActive ? (
                              <>
                                <DragMessage />

                                {draggedFiles.length > 1 ? (
                                  <Text
                                    is="div"
                                    mt={2}
                                    lineHeight="text"
                                    color="warning"
                                  >
                                    Multiple uploads are not supported at the
                                    moment. Only one file will be uploaded.
                                  </Text>
                                ) : null}
                              </>
                            ) : this.state.error ? (
                              <>
                                <Text color="error" fontSize={2} is="div">
                                  Something went wrong.
                                </Text>
                                <Button onClick={this.onClear}>Cancel</Button>
                              </>
                            ) : response ? (
                              <Card width={400}>
                                <Text
                                  fontSize={3}
                                  fontWeight="600"
                                  textAlign="center"
                                  is="div"
                                >
                                  Configure
                                </Text>
                                <Form
                                  form={this.state.form}
                                  bBox={response.environmentBoundingBox}
                                  uploadId={response.id}
                                  onChange={this.onChange}
                                  projectId={this.props.projectId}
                                  onSubmit={this.props.onSubmit}
                                  onClear={this.onClear}
                                />
                              </Card>
                            ) : progress ? (
                              <>
                                <Text fontSize={2} color="white">
                                  {files[0].name}
                                </Text>
                                <Progress value={progress / 100} />
                              </>
                            ) : null}
                          </Box>
                        </Absolute>
                      ) : null}
                    </React.Fragment>
                  )}
                </Droparea>
              )}
            </Uploader>
          ) : (
            children
          )
        }
      </FeatureToggle>
    );
  }
}
