import React from 'react';
import { compose } from 'ramda';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { withHandlers } from 'recompose';
import { RadioButtonGroup, Button, Pictogram, AvailableIcons } from '@hm/ukie';
import { MapObject } from '../../reducers/selectedObjects';
import {
  ObjectType,
  MarkerSubtype,
  RoadSubtype,
} from '../../constants/objects';
import messages from './messages';

const subtypeIcons: {
  [type: string]: { [subtype: string]: AvailableIcons };
} = {
  [ObjectType.ROAD]: {
    [RoadSubtype.DRIVING]: 'car',
    [RoadSubtype.WALKING]: 'pedestrian',
    [RoadSubtype.TRANSIT]: 'bus',
    [RoadSubtype.BICYCLING]: 'bicycle',
  },
  [ObjectType.MARKER]: {
    [MarkerSubtype.SPATIAL]: 'spatial',
    [MarkerSubtype.SPATIO_TEMPORAL]: 'spatioTemporal',
    [MarkerSubtype.TEMPORAL]: 'temporal',
  },
};

const tooltips = {
  [MarkerSubtype.SPATIAL]: messages.spatialMarker,
  [MarkerSubtype.SPATIO_TEMPORAL]: messages.spatioTemporalMarker,
  [MarkerSubtype.TEMPORAL]: messages.temporalMarker,
};

interface OwnProps {
  type: string;
  selectedSubtype: string;
  updateSubtype?(subtype: string): void;
}

interface ExternalProps {
  updateParams(updates: Partial<MapObject>): void;
}

type Props = OwnProps & InjectedIntlProps;

const SubtypeControl: React.SFC<Props> = ({
  type,
  selectedSubtype,
  updateSubtype,
  intl,
}) => {
  const icons = subtypeIcons[type];
  if (!icons) return null;

  return (
    <RadioButtonGroup selected={selectedSubtype} onClick={updateSubtype}>
      {Object.keys(icons).map(subtype => (
        <Button
          icon={
            <Pictogram
              name={icons[subtype]}
              color={subtype === selectedSubtype ? 'white' : null}
              size="medium"
            />
          }
          value={subtype}
          title={intl.formatMessage(tooltips[subtype])}
          key={subtype}
        />
      ))}
    </RadioButtonGroup>
  );
};

// TODO: remove recompose handlers
export default compose(
  withHandlers<ExternalProps, any>({
    updateSubtype: ({ updateParams }) => (subtype: string) =>
      updateParams({ subtype }),
  }),
  injectIntl,
)(SubtypeControl);
