import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Flex, Box } from 'grid-styled';

import { monthByWeek } from '../../utils/time';
import Day from './Day';
import Title from './Title';
import Weekday from './Weekday';
import shouldCalendarUpdate from '../shouldCalendarUpdate';
import { CalendarProps } from '../Calendar';

const Container = styled.div`
  padding-bottom: 44px;
`;

const Week = Flex.extend`
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 36px;
  }
`;

// makes space-between allignment work
const EmptyDay = Box.extend`
  width: 72px;
`;

export interface OwnProps {
  date: moment.Moment;
  style?: any;
}

type Props = OwnProps & CalendarProps;

/**
 * Calendar that lets user select range by hour.
 * Dispalys all days of the month and 24 hour ‘wave’ under each day.
 * Can be found under ‘Hours’ tab on setup dialog.
 */
export class DaysSelector extends React.Component<Props, never> {
  render() {
    const { date, style, ...props } = this.props;

    return (
      <Container style={style}>
        <Title>{date.format('MMMM, YYYY')}</Title>
        <Flex justify="space-between">
          {moment
            .weekdays(true)
            .map((weekday, i) => <Weekday key={i}>{weekday}</Weekday>)}
        </Flex>
        <div>
          {monthByWeek(date).map((week, i) => (
            <Week key={i}>
              {week.map(
                day =>
                  day.isSame(date, 'month') ? (
                    <Day key={+day} date={day} {...props} />
                  ) : (
                    <EmptyDay key={+day} />
                  ),
              )}
            </Week>
          ))}
        </div>
      </Container>
    );
  }
}

export default shouldCalendarUpdate('month')(DaysSelector);
